import React from 'react';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';
import {
    Typography,
} from '../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';
import useStyles from './styles';

const PlaceHolderTexts = {
    facebook: 'https://facebook.com/user',
    instagram: 'https://instagram.com/user',
    twitter: 'https://twitter.com/user',
    youtube: 'https://youtube.com/user',
    vimeo: 'https://vimeo.com/user',
    podcast: 'https://yoursite.com/podcast',
    linkedin: 'https://linkedin.com/user',
    blog: 'https://yoursite.com/blog',
    iphone: 'https://apps.apple.com/app',
    ipad: 'https://apps.apple.com/app',
    android: 'https://play.google.com/app',
    tikTok: 'https://tiktok.com/@username',
    snapchat: 'https://snapchat.com/add/username',
    twitch: 'https://twitch.tv/username',
    discord: 'https://discord.gg/name',
    linktree: 'https://linktr.ee/user',
};

const SocialLinks = (props) => {
    const {
        pageTitle,
        input,
        setInput,
        updateEvents,
        isLoading,
        isManagedByFeed,
        hasValidationFieldError,
        errors,
        isManagedByChild,
    } = props;
    const classes = useStyles();

    function trim (target) {
        if (!target) {
            return '';
        }
        return target.trim();
    }

    return (
        <Grid item className={classes.mainContainer} justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={800}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                    data-intercom-target='event-sociallink-title'
                >
                    {pageTitle} Social Links
                </Typography>
                <Typography
                    // variant={'body1'}
                    // weight={'medium'}
                    block
                    style={{ marginBottom: 30 }}
                >
                    Add links to your popular social pages, communities and apps.
                </Typography>

                <Grid spacing={4} container>
                    <Grid item xs={4} >
                        <TextField
                            id='facebookPage'
                            label='Facebook Page'
                            name='facebook'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.facebook}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, facebook: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.facebook}
                            helperText={errors?.socialData?.facebook || PlaceHolderTexts.facebook}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id='instagram'
                            label='Instagram'
                            name='instagram'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.instagram}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, instagram: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.instagram}
                            helperText={errors?.socialData?.instagram || PlaceHolderTexts.instagram}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <TextField
                            id='twitter'
                            label='Twitter Tag'
                            name='twitter'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.twitter}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, twitter: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.twitter}
                            helperText={errors?.socialData?.twitter || PlaceHolderTexts.twitter}

                        />
                    </Grid>

                </Grid>

                <Grid spacing={4} container>

                    <Grid item xs={4} >
                        <TextField
                            id='youTube'
                            label='YouTube'
                            name='youtube'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.youtube}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, youtube: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.youtube}
                            helperText={errors?.socialData?.youtube || PlaceHolderTexts.youtube}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='vimeo'
                            label='Vimeo'
                            name='vimeo'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.vimeo}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, vimeo: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.vimeo}
                            helperText={errors?.socialData?.vimeo || PlaceHolderTexts.vimeo}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='podcast'
                            label='Podcast'
                            name='podcast'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.podcast}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, podcast: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.podcast}
                            helperText={errors?.socialData?.podcast || PlaceHolderTexts.podcast}
                        />
                    </Grid>
                </Grid>

                <Grid spacing={4} container>
                    <Grid item xs={4} >
                        <TextField
                            id='linkedin'
                            label='LinkedIn'
                            name='linkedin'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.linkedin}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, linkedin: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.linkedin}
                            helperText={errors?.socialData?.linkedin || PlaceHolderTexts.linkedin}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='blog'
                            label='Blog'
                            // onChange={handleChange}
                            name='blog'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.blog}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, blog: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.blog}
                            helperText={errors?.socialData?.blog || PlaceHolderTexts.blog}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='tikTok'
                            label='TikTok'
                            // onChange={handleChange}
                            name='tikTok'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.tikTok}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, tikTok: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.tikTok}
                            helperText={errors?.socialData?.tikTok || PlaceHolderTexts.tikTok}
                        />
                    </Grid>
                </Grid>

                <Grid spacing={4} container>

                    <Grid item xs={4} >
                        <TextField
                            id='iphone'
                            label='iPhone App'
                            // onChange={handleChange}
                            name='iphone'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.iphone}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, iphone: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.iphone}
                            helperText={errors?.socialData?.iphone || PlaceHolderTexts.iphone}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='ipad'
                            label='iPad App'
                            name='ipad'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.ipad}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, ipad: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.ipad}
                            helperText={errors?.socialData?.ipad || PlaceHolderTexts.ipad}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='android'
                            label='Android App'
                            name='android'
                            disabled={isManagedByChild}
                            fullWidth={true}
                            value={input.socialData.android}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, android: trim(e.target.value) },
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 35 }}

                            error={errors?.socialData?.android}
                            helperText={errors?.socialData?.android || PlaceHolderTexts.android}
                        />
                    </Grid>

                </Grid>

                <Grid spacing={4} container>
                    <Grid item xs={4}>
                        <TextField
                            id='snapchat'
                            label='Snapchat'
                            name='snapchat'
                            disabled={isManagedByChild}
                            fullWidth={true}

                            value={input.socialData.snapchat}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, snapchat: trim(e.target.value) },
                                });
                            }}

                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.snapchat}
                            helperText={errors?.socialData?.snapchat || PlaceHolderTexts.snapchat}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='twitch'
                            label='Twitch'
                            name='twitch'
                            disabled={isManagedByChild}
                            fullWidth={true}

                            value={input.socialData.twitch}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, twitch: trim(e.target.value) },
                                });
                            }}

                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.twitch}
                            helperText={errors?.socialData?.twitch || PlaceHolderTexts.twitch}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <TextField
                            id='discord'
                            label='Discord'
                            name='discord'
                            disabled={isManagedByChild}
                            fullWidth={true}

                            value={input.socialData.discord}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, discord: trim(e.target.value) },
                                });
                            }}

                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.discord}
                            helperText={errors?.socialData?.discord || PlaceHolderTexts.discord}
                        />
                    </Grid>
                </Grid>

                <Grid spacing={4} container>
                    <Grid item xs={4}>
                        <TextField
                            id='linktree'
                            label='Linktree'
                            name='linktree'
                            disabled={isManagedByChild}
                            fullWidth={true}

                            value={input.socialData.linktree}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    socialData: { ...input.socialData, linktree: trim(e.target.value) },
                                });
                            }}

                            variant='outlined'
                            style={{ marginBottom: 35 }}
                            error={errors?.socialData?.linktree}
                            helperText={errors?.socialData?.linktree || PlaceHolderTexts.linktree}
                        />
                    </Grid>
                </Grid>

                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        showProgress={isLoading}
                        label='Save'
                        disabled={isManagedByFeed || isManagedByChild || hasValidationFieldError || !isEmpty(errors?.socialData)}
                        onClick={() => {
                            console.log('Save event');
                            updateEvents();
                        }}
                        dataIntercomTarget='event-save-button'
                    />
                </Box>
            </Box>
        </Grid>
    );
};

SocialLinks.propsTypes = {
    input: PropTypes.object,
    setInput: PropTypes.func,
    updateEvents: PropTypes.func,
    pageTitle: PropTypes.string,
    isManagedByFeed: PropTypes.bool,
    isManagedByChild: PropTypes.bool,
    hasValidationFieldError: PropTypes.bool,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

SocialLinks.defaultProps = {
    input: {},
    setInput: () => {},
    updateEvents: () => {},
    pageTitle: '',
    isManagedByFeed: false,
    isManagedByChild: false,
    hasValidationFieldError: false,
    isLoading: false,
    errors: {},
};

export default SocialLinks;
