import { v4 as uuid } from 'uuid';

import {
    apiGetAPIKeys,
    apiAddAPIKey,
    apiEditAPIKey,
    apiDeleteAPIKey,
} from './api';

export async function getAPIKeys () {
    this.env.errorStore.action = 'getAPIKeys';
    this.observed.apikeysLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiGetAPIKeys({
        token: publisher.token,
        traceId: this.env.traceId,
        action: 'getAPIKeys',
    });

    console.log('API Keys: ', res);
    if (res.data && res.data.data) {
        this.apikeys = res.data.data;

        const actives = [];
        const inactives = [];

        this.apikeys.forEach(apikey => {
            if (apikey.status === 'inactive') {
                inactives.push(apikey);
            } else {
                actives.push(apikey);
            }
        });
        this.apikeys = actives.concat(inactives);

        const inactive = this.apikeys.filter((item) => (item.status === 'inactive'));
        this.hasInactive = (inactive.length > 0 || this.apikeys.length <= 1);

        this.observed.apikeys = uuid();
    } else {
        // handle error.
    }
    this.observed.apikeysLoading = false;
}

/*
 * https://testing-admin.ecal.com/developer/api-keys/generate-api-key
 * name as paramater
 */
export async function generateAPIKey (apikey) {
    this.env.errorStore.action = 'generateAPIKey';
    const publisher = this.env.userStore.publisher;

    await apiAddAPIKey({
        body: { appName: apikey.name },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
            action: 'generateAPIKey',
        },
    });

    this.env.history.push('/admin/developers/apikeys');
}

/*
 * https://testing-admin.ecal.com/developer/api-keys/secret-api-key?id=819429e66b5b53415a1116473a3964235913f318579b7
 */
export async function getAPIKeySecret (apikey) {
    this.env.errorStore.action = 'getAPIKeySecret';
    const publisher = this.env.userStore.publisher;

    await apiEditAPIKey({
        body: { ...apikey },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
            action: 'getAPIKeySecret',
        },
    });

    console.log('...secret generated..');
}

export async function activateAPIKey (apikeyId) {
    this.env.errorStore.action = 'activateAPIKey';
    this.observed.apikeysLoading = true;
    const publisher = this.env.userStore.publisher;

    await apiEditAPIKey({
        body: {
            id: apikeyId,
            status: 'active',
        },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
            action: 'activateAPIKey',
        },
    });

    this.getAPIKeys();
}

export async function deactivateAPIKey (apikeyId) {
    this.env.errorStore.action = 'deactivateAPIKey';
    this.observed.apikeysLoading = true;
    const publisher = this.env.userStore.publisher;

    await apiEditAPIKey({
        body: {
            id: apikeyId,
            status: 'inactive',
        },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
            action: 'deactivateAPIKey',
        },
    });

    this.getAPIKeys();
}

export async function deleteAPIKey (apikey) {
    this.env.errorStore.action = 'deleteAPIKey';
    this.observed.apikeysLoading = true;
    const publisher = this.env.userStore.publisher;
    await apiDeleteAPIKey({
        body: { ...apikey },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
            action: 'deleteAPIKey',
        },
    });
    this.getAPIKeys();
}
