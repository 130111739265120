import React from 'react';
import {
    Grid,
    Box,
} from '@material-ui/core';
import Widget from '../../components/Widget';
import Card from '../../commons/card/Card';
import { Typography } from '../../components/Wrappers';
import useButtonNavigation from './useButtonNavigation';
import ButtonDisplayImage from '../../commons/images/displays/ButtonDisplay.png';
import DisplayAdImage from '../../commons/images/displays/AdvDisplay.png';
import EventDisplayImage from '../../commons/images/displays/EventDisplay.png';
import useStyles from './buttons.styles';
import useProductTour from '../hooks/useProductTour';
function AddDisplay () {
    const classes = useStyles();
    const {
        handleAddButton,
        handleAddAd,
        handleAddEvent,
      } = useButtonNavigation();

    useProductTour();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Widget className={classes.widgetContainer}
                    title="Add Display"
                    buttonFlag={false}
                    inheritHeight disableWidgetMenu >
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'flex-start'}
                    >
                        <Typography variant='body2'>
                            Choose your way! Create a custom button, ad or event display. Add these to your website, apps, social media, email, printed material and more.
                        </Typography>
                    </Box>
                </Widget>
            </Grid>
            <Grid container spacing={6}>
            <Grid item md={4} xs={12}>
                <Card
                    cardImage={ButtonDisplayImage}
                    handleCreate={handleAddButton}
                    titleText={'Button Display'}
                    bodyDescription={'Create your own custom \'Sync to Calendar\' button for any digital channel.'}
                    buttonText={'Create'} />
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    cardImage={DisplayAdImage}
                    handleCreate={handleAddAd}
                    bodyDescription={'Upload your very own button or banner design, and customise the pop-up for high impact promotion.'}
                    titleText={'Ad Display'}
                    buttonText={'Create'} />
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    cardImage={EventDisplayImage}
                    bodyDescription={'Create a custom page for your website to display your events, complete with a \'Sync to Calendar\' button.'}
                    titleText={'Event Display'}
                    buttonText={'Create'}
                    handleCreate={handleAddEvent}
                    />
            </Grid>
        </Grid>
        </Grid>
    );
}

export default AddDisplay;
