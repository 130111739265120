import { useHistory } from 'react-router';

function useNavigation () {
    const history = useHistory();
    function gotoOptins () {
        history.push('/marketing/optins');
    }
    function gotoAccountsProfile () {
        history.push('/admin/settings');
    }

    function gotoVerifyAccount () {
        history.push('/verifyAccount');
    }

    function gotoSignUp () {
        history.push('/signup');
    }

    function gotoLogIn () {
        history.push('/login');
    }

    return {
        gotoOptins,
        gotoAccountsProfile,
        gotoVerifyAccount,
        gotoSignUp,
        gotoLogIn,
    };
}
export default useNavigation;
