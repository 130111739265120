import { useHistory } from 'react-router';

function useScheduleNavigation () {
    const history = useHistory();
    const handleAddSchedule = (e) => {
        e.preventDefault();
        history.push('/admin/schedules/add');
    };
    const handleManualEntrySchedule = (e) => {
        e.preventDefault();
        history.push('/admin/schedules/add/manual-entry');
    };
    const handleImportSchedule = (e) => {
        e.preventDefault();
        history.push('/admin/schedules/import-events');
    };
    const handleEventList = (e) => {
        e.preventDefault();
        history.push('/admin/events');
    };
    const handleAddEvent = (e, scheduleId) => {
        e.preventDefault();
        history.push(`/admin/schedules/${scheduleId}/events/add`);
    };
    return {
        handleAddSchedule,
        handleManualEntrySchedule,
        handleImportSchedule,
        handleEventList,
        handleAddEvent,
    };
}
export default useScheduleNavigation;
