import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import {
    ColorPicker,
} from 'material-ui-color';

import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

import {
    colorPickerValueToHex,
} from '../../../../utils';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

function InputHeader (props) {
    const classes = useStyles();

    const {
        designData,
        setDesignData,
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}>

                    <Grid className={classes.sectionContent} >

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Font Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.header.fontColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            header: {
                                                ...designData.header,
                                                // fontColour: '#' + value.hex,
                                                fontColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Background Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.header.backgroundColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            header: {
                                                ...designData.header,
                                                // backgroundColour: '#' + value.hex,
                                                backgroundColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Accent Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.header.accentColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            header: {
                                                ...designData.header,
                                                // accentColour: '#' + value.hex,
                                                accentColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Bottom Border Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.header.bottomBorderColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            header: {
                                                ...designData.header,
                                                // bottomBorderColour: '#' + value.hex,
                                                bottomBorderColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

InputHeader.propTypes = {
    setDesignData: PropTypes.func,
    designData: PropTypes.any,
};

InputHeader.defaultProps = {
    setDesignData: () => {},
    designData: {
        header: {

        },
    },
};

export default InputHeader;
