import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';

function StepDisplay (props) {
    const {
        activeStep,
        steps,
    } = props;

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('Steps: ', steps);
        console.log('Steps: ', activeStep);
    }, [steps]);

    function renderSteps () {
        return steps.map((label) => (
            <Step key={label}>
                <StepLabel>{label}</StepLabel>
            </Step>
        ));
    }

    return (
        <Stepper
            activeStep={activeStep}
            alternativeLabel>
            {renderSteps()}
        </Stepper>
      );
}

StepDisplay.propTypes = {
    activeStep: PropTypes.nubmer,
    steps: PropTypes.array,
};

StepDisplay.defaultProps = {
    activeStep: 0,
    steps: [],
};

export default StepDisplay;
