import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Grid,
} from '@material-ui/core';
import {
    Typography,
} from '../../../../components/Wrappers';

import SelectSearch from '../../../../commons/ecal/singleselectsearch';
import Timezones from '../../../../commons/util/Timezone.json';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

const INSTRUCTION = 'What default timezone would you like the events displayed in?';

function Timezone (props) {
    const classes = useStyles();

    const {
        optionData,
        setOptionData,
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRootNoBottomPad}>
                    <Grid className={classes.sectionTitle}>
                        <Typography
                            variant='h6'
                            weight='medium'
                            >
                           Timezone
                        </Typography>
                    </Grid>
                    <Grid className={classes.sectionDescription}>
                        <Typography block >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classes.sectionContent}
                        >
                            <Box
                                className={classes.inputBox}>

                                <SelectSearch
                                    showLabelAlways={true}
                                    label={'Select Timezone'}
                                    selected={optionData.timezone ? optionData.timezone : ['-']}
                                    onSelect={(data) => {
                                        setOptionData({
                                            ...optionData,
                                            timezone: data,
                                        });
                                    }}
                                    labelHeading=''
                                    helperText='Select Timezone'
                                    placeHolder='Select timezone'
                                    dataProvider={Timezones}
                                />
                            </Box>

                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

Timezone.propTypes = {
    setOptionData: PropTypes.func,
    optionData: PropTypes.any,
};

Timezone.defaultProps = {
    setOptionData: () => {},
    optionData: {},
};

export default Timezone;
