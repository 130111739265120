import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react';
import {
  Grid,
} from '@material-ui/core';

// styles
import { useStyles } from './signUp.styles';

import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

// components
import { Typography } from '../../components/Wrappers/Wrappers';
import SignUpForm from './signUpForm';
import { useUserStore, useEnvStore, useErrorStore } from '../../store/models';
import useEnvHistory from '../hooks/useEnvHistory';
import {
  getClientTimezone,
  isFreePlan,
  isFreeTrial,
  logInfo,
} from '../../utils';
import useFeatures from '../hooks/useFeatures';

import useAuthError from '../../store/models/user/errors/useAuthError';
import useGenericErrorDialog from '../../store/models/error/useGenericErrorDialog';

function OpenSigninSetup (props) {
  const classes = useStyles();
  const userStore = useUserStore();
  const envStore = useEnvStore();
  const errStore = useErrorStore();
  const [isLoading, setIsLoading] = useState(false);
  useEnvHistory();

  const {
    features,
  } = useFeatures();

  useAuthError();

  const {
    renderErrorDialog,
  } = useGenericErrorDialog({
    onCloseError: () => {
      setIsLoading(false);
      errStore.errors = {};
      errStore.genericErrors = {};
      errStore.observed.errors.generics = uuid();
    },
    shouldSignout: false,
  });

  function getPublisherName () {
    const user = userStore.user;

    if (user && user.orgName) {
      return user.orgName;
    }

    if (user && user.firstName && user.lastName) {
      return user.firstName + ' ' + user.lastName;
    }

    return '';
  }

  const [setupData, setSetupData] = useState({
    publisherName: getPublisherName(),
    timezone: getClientTimezone(),
  });

  useEffect(() => {
    if (userStore.user) {
      const envSettings = envStore.getEnvSettings();
      console.log('');
      console.log('');
      console.log('Env Settings: ', envSettings);

      console.log('User: ', userStore.user);
      console.log('Publisher: ', userStore.publisher);
      const user = userStore.user;
      setSetupData({
        ...setupData,
        publisherName: getPublisherName(),
        planSettings: envSettings,
        timezone: user.timezone || '',
        industry: user.industry || '',
        objective: user.objective || '',
      });
    }
  }, [userStore.user]);

  async function completeSetup (data) {
    console.log('User Info: ', userStore.user);
    console.log('New Publisher Data : ', data);
    setIsLoading(true);
    const params = {
      ...data,
      company: data.publisherName,
      firstName: userStore.user.firstName,
      lastName: userStore.user.lastName,
      email: userStore.user.email,
      token: userStore.user.token,
    };

    console.log('openSignupSetup.completeSetup() : ', params);
    await userStore.updateProfile({ user: params }, true);
    continueToPlan();
  }

  function continueToPlan () {
    const envSettings = envStore.getEnvSettings();
    if (envSettings && features.billingActive) {
       // do setup plan
       const plan = envSettings.plan;
       logInfo('will Continue to Plan? -> ', plan);
       if (!plan) {
        return;
       }
       if (!isFreeTrial(plan) && !isFreePlan(plan)) {
        envStore.history.push('/setup-plan');
       }
    }
  }

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <div className={classes.formDividerContainer}></div>
        <div className={classes.logotypeContainer}>
          <img src={EcalLogoImage} alt="logo" className={classes.logotypeImage} />
        </div>

        <div className={classes.formContainer}>
          <div className={classes.form}>
            <React.Fragment>
              <Grid item container className={classes.logoImageContainer}>
                <div id='logoImage' className={classes.logoImage}></div>
              </Grid>

              <SignUpForm
                isSetup={true}
                setupData={setupData}
                onSignUp={(data) => {
                  completeSetup(data);
                }}
                showLoadingCue={isLoading}
                />
            </React.Fragment>

          </div>
          <Typography color="primary" className={classes.copyright}>
            © 2010-{new Date().getFullYear()} | ECAL | HyperKu Pte Ltd | All rights reserved.
          </Typography>
        </div>
      </Grid>
      { renderErrorDialog() }
    </React.Fragment>
  );
}

export default observer(OpenSigninSetup);
