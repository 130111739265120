import { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { mapping } from './mapping';
import { useEventsStore } from './index';

function serializeAsRows (events) {
    if (!events) {
        return [];
    }
    return events.map(item => mapRow(item));
}

function mapRow (item) {
    trimQuickLinks(item);
    trimSocialLinks(item);
    // console.log('useEventsImport.trimSocialLinks', item);
    /* check the mapping */
    return Object.keys(mapping).reduce((prev, key) => {
        const column = mapping[key];
        return {
            ...prev,
            [column]: rowValue(item, key),
        };
    }, {});
}

function trimQuickLinks (item) {
    const quickLinklength = item.quicklinks.length;
    for (let i = 0; i < quickLinklength; i++) {
        item.quicklinks[i].url = item.quicklinks[i].url.trim();
    }
}

function trimSocialLinks (item) {
    for (const socialLink in item.social) {
        item.social[socialLink] = item.social[socialLink].trim();
    }
}

function rowValue (item, key) {
    if (key.indexOf('quickLinks') !== -1) {
        const paths = key.split('.');
        const index = paths[1];
        const type = paths[2];
        return item.quicklinks[index][type];
    }

    if (key === 'time.startDate' || key === 'time.endDate') {
        const dateValue = get(item, key, '');
        const dateParts = dateValue.split('-');
        const formatted = dateParts.reverse().join('/');
        return formatted;
    }

    return get(item, key, '');
}

function serializeIssues (source) {
    console.log('');
    console.log('');
    console.log('serializeIssues()', source);
    if (!source) {
        return;
    }
    const keys = Object.keys(source);
    console.log('Keys: ', keys);
    if (keys.length <= 0) {
        return {};
    }

    return keys.reduce((prev, key) => {
        const issues = mapIssues(source[key]);
        if (issues.length <= 0) {
            return prev;
        }
        return {
            ...prev,
            [key]: mapIssues(source[key]),
        };
    }, {});
}

function mapIssues (issues) {
    return issues.reduce((prev, name) => {
        if (name === 'time.timezone') {
            // exclude timezone
            return prev;
        }
        return [
            ...prev,
            mapping[name],
        ];
    }, []);
}

const validationIssue = {
    warnings: {
        '0': ['Event Name*', 'Start Date*', 'End Time*'],
    },

    errors: {
        '2': ['Start Time*'],
        '3': ['Location*', 'Start Date*', 'End Time*'],
    },
};

function useEventsImport () {
    const eventsStore = useEventsStore();

    const [events, setEvents] = useState([]);
    const [warnings, setWarnings] = useState({});
    const [errors, setErrors] = useState({});
    const [hasSpreadsheetErrors, setHasSpreadsheetErrors] = useState(false);

    useEffect(() => {
        console.log('');
        console.log('Has Spreadsheet Error: ', hasSpreadsheetErrors);
    }, [hasSpreadsheetErrors]);

    useEffect(() => {
        console.log('');
        console.log('Imported Data is updated...');
        console.log('Imported Events: ', eventsStore.importedEvents);

        if (isEmpty(eventsStore.importedEvents)) {
            return;
        }

        const errs = serializeIssues(eventsStore.importedEventsErrors);
        const warns = serializeIssues(eventsStore.importedEventsWarnings);

        console.log('Serialized Warnings: ', warns);
        console.log('Serialized Errors: ', errs);
        setEvents(serializeAsRows(eventsStore.importedEvents));
        setWarnings(warns);
        setErrors(errs);
        setHasSpreadsheetErrors(!isEmpty(errs));
    }, [eventsStore.observed.importedEvents]);

    useEffect(() => {
        const hasError = !isEmpty(validationIssue.errors);
        setErrors(validationIssue.errors);
        setWarnings(validationIssue.warnings);
        setHasSpreadsheetErrors(hasError);
    }, []);

    async function importEvents (data) {
        setWarnings({});
        setErrors({});
        await eventsStore.importEvents(data);
    }

    async function saveImportedEvents (data) {
        await eventsStore.saveImportedEvents(data);
    }

    return {
        events,
        errors,
        hasSpreadsheetErrors,
        warnings,
        importEvents,
        saveImportedEvents,
    };
}

export default useEventsImport;
