import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { scrollToTop } from '../../../utils';
import {
    useButtonsStore,
    useAPIKeyStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';

function useModuleList (props) {
    const {
        columns,
    } = props;
    const history = useHistory();
    const [visibleColumns, setVisibleColumns] = useState(columns);

    const apikeyStore = useAPIKeyStore();
    const buttonsStore = useButtonsStore();
    const [listData, setListData] = useState([]);
    const [progress, setProgress] = useState({
        buttons: false,
    });

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(false);
    const [pageInfo, setPageInfo] = useState(buttonsStore.pageInfo);
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeAPIKey, setActiveAPIKey] = useState('');

    useEffect(() => {
        if (buttonsStore.buttons) {
            setListData(buttonsStore.buttons);
            setPageInfo({
                ...pageInfo,
                ...buttonsStore.pageInfo,
            });
        }
        setProgress({ ...progress, buttons: false });
    }, [buttonsStore.observed.buttons]);

    useEffect(() => {
        if (apikeyStore.apikeys && apikeyStore.apikeys.length > 0) {
            setActiveAPIKey(apikeyStore.apikeys[0].id);
        }
    }, [apikeyStore.observed.apikeys]);

    function initStore () {
        setProgress({ ...progress, buttons: true });
        const type = 'ad';
        apikeyStore.getAPIKeys();
        buttonsStore.getButtons(type);
        scrollToTop();
    }

    useStoreModuleDependency({ initStore });

    function updateEditData (data) {
        buttonsStore.saveEditData(data);
    }

    function updatePageInfo (info) {
        buttonsStore.pageInfo = {
            ...pageInfo,
            ...info,
        };
        setPageInfo({
            ...buttonsStore.pageInfo,
        });
        setProgress({
            ...progress,
            buttons: true,
        });
        const type = 'ad';
        buttonsStore.getButtons(type);
    }

    function makeDraft () {
        setProgress({
            ...progress,
            buttons: true,
        });
         buttonsStore.makeWidgetsDraft(selectedRows, 'ad');
    }

    function makeLive () {
        setProgress({
            ...progress,
            buttons: true,
        });
        buttonsStore.makeWidgetsLive(selectedRows, 'ad');
    }

    function deleteSelection () {
        setProgress({
            ...progress,
            buttons: true,
        });
        buttonsStore.deleteWidgets(selectedRows, 'ad');
    }

    function deleteSingle (row) {
        setProgress({
            ...progress,
            buttons: true,
        });
        buttonsStore.deleteWidgets([row.id], 'ad');
    }

    function saveEditData (data) {
        console.log('button.useModuleList().saveEditData()', data);
        buttonsStore.saveEditData(data);
        history.push('/admin/displays/ad/edit/' + data.id);
    }

    return {
        listData,
        progress,
        updateEditData,

        deleteOpen,
        deleteTarget,
        setDeleteOpen,
        setDeleteTarget,
        pageInfo,
        updatePageInfo,

        selectedRows,
        setSelectedRows,

        visibleColumns,
        setVisibleColumns,
        makeDraft,
        makeLive,
        deleteSelection,
        deleteSingle,
        saveEditData,
        activeAPIKey,
    };
}

export default useModuleList;
