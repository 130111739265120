import React from 'react';
// import {
//     Grid,
// } from '@material-ui/core';
import Displays from './manageDisplays';

function ButtonsDisplay () {
    return (
        <Displays />
    );
}

export default ButtonsDisplay;
