import { makeObservable, observable, action } from 'mobx';
import { useStores } from '../useStores';

import {
    authGetPublisherInfo,
    searchPublishers,
    updateProfile,
    uploadPublisherAssets,
    generateSVG,
    uploadAssets,
    renewPublisher,
    autoRenewPublisher,
    getPublisherLogo,
    uploadProfileLogo,
    deletePublisherLogo,
    refreshToken,
    notifyForUserReady,
    // getIP,
    getCountryInfo,
    reloadSelectedPublisher,
    insertLead,
} from './actions';

import {
    selectPublisher,
    navigateInitPageByUser,
    manageCognitoPayload,
    processAuthUser,
    managePublisher,
    manageUserRole,
    userFromPayload,
    userIsPublisher,
    updateUserProfile,
    pushPublisherInfo,
    processAWSMarketplaceBilling,
} from './actions_manage_user';

import {
    login,
    changePassword,
    signUp,
    federatedSignIn,
    currentUser,
    signOut,
    completeUserSetup,
    verifyOTP,
    confirmSignIn,
    resendForOTP,
    forgotPassword,
    recoverPassword,
    confirmSignUp,
    resendSignupCode,
    updateUserAttrForProfile,
    requiredChangePasssword,
    createCognitoSession,
} from './amplify_actions';

import {
    paginate,
    resetPagingation,
    updatePageInfoFromSearch,
} from './action_publist_pagination';

import {
    expireWhenIdle,
    expireSession,
    TIME_60_MINUTES as expirationLimit,
    verifySessionExpired,
    refreshSession,
} from './action_user_sessions';

export default class UserStore {
    sessionLimit = expirationLimit;
    version = {}
    env = null

    publishersPageInfo = {
        showPagingation: false,
        page: 0,
        itemsPerPage: 10,
        totalCount: 0,
    };

    /* The Logged In user */

    /*
     * will now be used as the single source of truth
     */

     /* These are the non observables used in API requests */
    user = null; /* Always used as the loggedin User */
    userEmail = null;
    publisher = null; /* can be the logged in user or the selected publisher on the search */
    isSuperAdmin = false;
    superAdmin = null;
    publishers = null;
    userInfoLoaded = false;
    publisherLogo = null;
    publisherLogoEditable = null;
    /*
     * Used in the
     * dashboard
     * user profile
     */

    observed = {
        user: null,
        publisher: null,
        superAdmin: null,
        publishers: null,
        publisherLogo: null,
        publisherLogoEditable: null,
        userInfoLoaded: false,
        isSuperAdmin: false,
    };

    publisherAsset = [];
    calendarLogo = [];
    configured = true;
    cognitoUsername = '';
    qrCode = '';

    constructor () {
        makeObservable(this, {
            observed: observable,
            version: observable,

            qrCode: observable,
            publisherAsset: observable,
            calendarLogo: observable,

            /* actions not necessary will be removed soon */
            login: action,
            signUp: action,
            federatedSignIn: action,
            currentUser: action,
            signOut: action,
            completeUserSetup: action,
            verifyOTP: action,
            confirmSignIn: action,
            confirmSignUp: action,
            processAuthUser: action,
            resendForOTP: action,
            forgotPassword: action,
            recoverPassword: action,
            authGetPublisherInfo: action,
            updateProfile: action,
            changePassword: action,
            uploadPublisherAssets: action,
        });
    }

    refreshSession = refreshSession;
    expireSession = expireSession;
    verifySessionExpired = verifySessionExpired;
    expireWhenIdle = expireWhenIdle;

    /* Amplify Actions */
    login = login;
    signUp = signUp;
    federatedSignIn = federatedSignIn;
    currentUser = currentUser;
    completeUserSetup = completeUserSetup;
    changePassword = changePassword;
    signOut = signOut;
    verifyOTP = verifyOTP;
    resendForOTP = resendForOTP;
    confirmSignIn = confirmSignIn;
    confirmSignUp = confirmSignUp;
    processAuthUser = processAuthUser;
    forgotPassword = forgotPassword;
    recoverPassword = recoverPassword;
    resendSignupCode = resendSignupCode;

    updateUserAttrForProfile = updateUserAttrForProfile;
    requiredChangePasssword = requiredChangePasssword;
    createCognitoSession = createCognitoSession;

    /* API Ecal Service Actions */
    authGetPublisherInfo = authGetPublisherInfo;
    searchPublishers = searchPublishers;
    updateProfile = updateProfile;
    uploadPublisherAssets = uploadPublisherAssets;
    generateSVG = generateSVG;
    uploadAssets = uploadAssets;
    renewPublisher = renewPublisher;
    autoRenewPublisher = autoRenewPublisher;
    refreshToken = refreshToken;
    notifyForUserReady = notifyForUserReady;
    insertLead = insertLead;

    getPublisherLogo = getPublisherLogo;
    uploadProfileLogo = uploadProfileLogo;
    deletePublisherLogo = deletePublisherLogo;
    // getIP = getIP;
    getCountryInfo = getCountryInfo;

    /* Manage Users and Publisher */
    managePublisher = managePublisher;
    selectPublisher = selectPublisher;
    navigateInitPageByUser = navigateInitPageByUser;
    manageCognitoPayload = manageCognitoPayload;

    manageUserRole = manageUserRole;
    userFromPayload = userFromPayload;
    userIsPublisher = userIsPublisher;
    updateUserProfile = updateUserProfile;
    pushPublisherInfo = pushPublisherInfo;
    reloadSelectedPublisher = reloadSelectedPublisher;
    processAWSMarketplaceBilling = processAWSMarketplaceBilling;

    paginate = paginate;
    resetPagingation = resetPagingation;
    updatePageInfoFromSearch = updatePageInfoFromSearch;
}

export function useUserStore () {
    const { userStore } = useStores();
    return userStore;
}
