import React, { Component } from 'react';
import MapGL, { Popup, Source, Layer } from 'react-map-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
import { clusterLayer, clusterCountLayer, unclusteredCountPointLayer, unclusteredPointLayer } from './layers';
import PopupInfo from './PopUpInfo';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWNhbCIsImEiOiJjazZ2bHhqbzIwM2I5M25vOW81OWN0bGIwIn0.7vrpSc13Z_BLpldsekrP9Q'; // Set your mapbox token here

export default class Map extends Component {
  state = {
    viewport: {
      latitude: 28.613889,
      longitude: 77.208333,
      zoom: 0,
      bearing: 0,
      pitch: 0,
    },
    popupInfo: null,
    showPopUp: false,
  };

  // componentWillMount(){
  //   navigator.geolocation.getCurrentPosition( (position) => {
  //     let lat = position.coords.latitude;
  //     let long = position.coords.longitude;
  //     let viewport= {
  //       ...this.state.viewport,
  //       latitude: lat,
  //       longitude: long,
  //       zoom: 3
  //     };
  //     this.setState({viewport})
  //   });
  // }

  _sourceRef = React.createRef();

  _onViewportChange = viewport => this.setState({ viewport });

  _renderPopup () {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.geometry.coordinates[0]}
          latitude={popupInfo.geometry.coordinates[1]}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <PopupInfo info={popupInfo} />
        </Popup>
      )
    );
  };

  _onClick = (event, value) => {
    const feature = event.features[0];
    if (feature === undefined || feature.properties.cluster_id === undefined) {
      if (feature !== undefined) { this.setState({ popupInfo: feature, showPopUp: true }); }
      return;
    }
    const clusterId = feature.properties.cluster_id;
    const mapboxSource = this._sourceRef.current.getSource();

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      this._onViewportChange({
        ...this.state.viewport,
        longitude: feature.geometry.coordinates[0],
        latitude: feature.geometry.coordinates[1],
        zoom,
        transitionDuration: 500,
      });
    });
  };

  _onClickMarker = city => {
    this.setState({ popupInfo: city, showPopUp: true });
  };

  render () {
    const { viewport } = this.state;
    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        // mapStyle="mapbox://styles/mapbox/dark-v9"
        onViewportChange={this._onViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={[clusterLayer.id]}
        onClick={this._onClick}
        attributionControl= {false}
      >
        {this.state.showPopUp ? this._renderPopup() : null}
        <Source
          type="geojson"
          data={this.props.data}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          ref={this._sourceRef}
          clusterProperties= {{ 'count': ['+', ['get', 'count']] }}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <Layer {...unclusteredCountPointLayer} />
        </Source>
      </MapGL>
    );
  }
}
