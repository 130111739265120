
import useTextKeyLimit from '../hooks/useTextKeyLimit';
import Validations, { InputPatterns } from '../../utils/validations';

function useSignupInputLimits () {
    const {
        onKeyDown: onFirstNameKeyDown,
    } = useTextKeyLimit({
        includedPattern: InputPatterns.name,
        charLimit: Validations.name.maxLength,
    });

    const {
        onKeyDown: onLastNameKeyDown,
    } = useTextKeyLimit({
        includedPattern: InputPatterns.name,
        charLimit: Validations.name.maxLength,
    });

    const {
        onKeyDown: onEmailKeyDown,
    } = useTextKeyLimit({
        includedPattern: InputPatterns.email,
        charLimit: Validations.email.maxLength,
    });

    const {
        onKeyDown: onPhoneKeyDown,
    } = useTextKeyLimit({
        includedPattern: InputPatterns.phone,
        charLimit: Validations.phone.maxLength,
        pasteEnabled: false,
    });

    const {
        onKeyDown: onPublisherKeyDown,
    } = useTextKeyLimit({
        includedPattern: InputPatterns.publisherName,
        charLimit: Validations.name.maxLength,
        pasteEnabled: false,
    });

    return {
        onFirstNameKeyDown,
        onLastNameKeyDown,
        onEmailKeyDown,
        onPhoneKeyDown,
        onPublisherKeyDown,
    };
}

export default useSignupInputLimits;
