import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    searchPublishers,
    loginAsSuperAdmin,
    updateStatus,
    updateBasicDetails,
    updatePublisherInfo,
    updateEmail,
    updateActivePublisher,
    updateExpiryDate,

    paginate,
    resetPagingation,
    reloadSearch,

    getPublisherById,
} from './actions';

import {
    createPublisherUser,
    getPublisherUsers,
    deletePublisherUsers,
    activatePublisherUsers,
    updatePublisherUsers,
} from './actions_publisher_users';

import {
    updatePlan,
    getPlans,
    getPublisherCurrentPlan,
} from './actions_plans';

import {
    setActivePublisher,
    saveActivePublisher,
    loadActivePublisher,
    saveSearchFilters,
    loadSearchFilters,
} from './actions_persists';

/*
 * Note: PublishersStore is used by superadmin alone.
 * To Access non superAdmin publishers access, use usersStore.
 */
export default class PublishersStore {
    env = null
    observed = {};
    publishers = [];
    publisherUsers = [];
    pageInfo = {
        showPagingation: false,
        page: 0,
        itemsPerPage: 10,
        totalCount: 0,
    };

    pubUsersPageInfo = {
        showPagingation: false,
        page: 0,
        itemsPerPage: 10,
        totalCount: 0,
    };

    superAdminDashboard = false;
    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    searchPublishers = searchPublishers;
    loginAsSuperAdmin = loginAsSuperAdmin;
    updateStatus = updateStatus;
    saveActivePublisher = saveActivePublisher;
    loadActivePublisher = loadActivePublisher;
    setActivePublisher = setActivePublisher;
    updateBasicDetails = updateBasicDetails;
    updatePublisherInfo = updatePublisherInfo;
    updateEmail = updateEmail;
    saveSearchFilters = saveSearchFilters;
    loadSearchFilters = loadSearchFilters;

    updateActivePublisher = updateActivePublisher;
    updateExpiryDate = updateExpiryDate;

    updatePlan = updatePlan;
    getPlans = getPlans;
    getPublisherCurrentPlan = getPublisherCurrentPlan;

    createPublisherUser = createPublisherUser;
    getPublisherUsers = getPublisherUsers;
    activatePublisherUsers = activatePublisherUsers;
    updatePublisherUsers = updatePublisherUsers;
    deletePublisherUsers = deletePublisherUsers;

    paginate = paginate;
    resetPagingation = resetPagingation;
    reloadSearch = reloadSearch;
    getPublisherById = getPublisherById;
}

export function usePublishersStore () {
    const { publishersStore } = useStores();
    return publishersStore;
}
