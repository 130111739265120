import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import Dialog from '../../../commons/dialog/Dialog';
import { logInfo } from '../../../utils';
import { useStyles } from './styles';
function EventPreviewDialog (props) {
    const {
      onClose,
      open,
      eventPreviewIframeSrc,
      previewSettings: previewSettingsSource,
    } = props;

    const [previewSettings, setPreviewSettings] = useState(previewSettingsSource);

    const classes = useStyles({
      mode: previewSettingsSource.mode,
    });

    const widgetRef = useRef();

    function renderWidget () {
      if (previewSettings.mode === 'calendar') {
        return;
      }

      function displayWidget () {
        const widgetSpace = document.querySelector('.event-display');
        if (!window.EcalEventWidget || !widgetSpace) {
          setTimeout(displayWidget, 400);
        } else {
          logInfo('displayWidget() mode ->: ', previewSettings.mode);
          // render when EcalEventWidget is available and !widgetSpace is available...
          window.EcalEventWidget.allowSync = false;
          window.EcalEventWidget.renderWidget({
              selector: '.event-display',
              widgetType: previewSettings.mode,
              apiKey: previewSettings.apiKey,
              widgetId: previewSettings.data.id,
              deviceInfo: '',
          });
        }
      }
      setTimeout(displayWidget, 400);
    }

    function debounceRender () {
      clearTimeout(window.widgetRenderTimeout);
      window.widgetRenderTimeout = setTimeout(() => renderWidget(), 1000);
    }

    useEffect(() => {
      // debounce state update...
      clearTimeout(window.previewStateTO);
      function debounceStateChange () {
        setPreviewSettings(previewSettingsSource);
      }
      window.previewStateTO = setTimeout(debounceStateChange, 1000);
    }, [previewSettingsSource]);

    useEffect(() => {
      debounceRender();
    }, [previewSettings]);

    function renderPreviewDisplay () {
      if (!open) {
        return (
          <React.Fragment></React.Fragment>
        );
      }
      if (previewSettings.mode === 'carousel' || previewSettings.mode === 'column' || previewSettings.mode === 'page') {
        return (<div ref={widgetRef} className='event-display'> </div>);
      }
      return (
        <iframe
          className={classes.widgetPreviewMode}
          frameBorder={0}
          src={eventPreviewIframeSrc}
          // src={'https://bit.ly/3lOKHLC?_=ecal'}
        />
      );
    }

    return (<Dialog
        containerMaxWidth={'960px'}
        bodyMaxWidth={'960px'}
        className={classes.buttonCodeDialog}
        containerClass={classes.dialogButtonCodeContainer}
        openDialog={open}
        buttonSecondaryFlag={false}
        buttonPrimaryFlag={false}
        buttonPrimary={'Close'}
        buttonPrimaryVariant={'outlined'}
        buttonPrimaryColor={'normal'}
        handleAgree={() => {
            onClose();
        }}
        title={'Preview Event Display'}
        dialogContextText={''}
        withCloseIcon
      >
        <Grid className={classes.fullSize}>
          { renderPreviewDisplay() }
        </Grid>
    </Dialog>);
}

EventPreviewDialog.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    eventPreviewIframeSrc: PropTypes.string,
    previewSettings: PropTypes.any,
};

EventPreviewDialog.defaultProps = {
    onClose: () => {},
    open: false,
    eventPreviewIframeSrc: '',

    previewSettings: {
      mode: 'calendar',
      apiKey: '',
      data: {},
    },
};

export default EventPreviewDialog;
