import { useEffect, useState } from 'react';
import { useBillingStore, useEnvStore, useUserStore } from '../../store/models';
import useBillingErrors from '../../store/models/billing/errors/useBillingErrors';
import { logInfo, isFreePlan, isFreeTrial } from '../../utils';
import useStoreModuleDependency from '../hooks/useStoreModuleDependency';
import useEnvHistory from '../hooks/useEnvHistory';

function useBillingPlan (props = {}) {
    const {
        isSetup = false,
    } = props;

    useEnvHistory();

    const billingStore = useBillingStore();

    const {
        errors,
    } = useBillingErrors({});
    const userStore = useUserStore();
    const envStore = useEnvStore();

    const [publisherPlan, setPublisherPlan] = useState({});
    const [planDetail, setPlanDetail] = useState({});
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [freePlan, setFreePlan] = useState(false);
    const [plansList, setPlansList] = useState([]);
    const [isUpgrading, setIsUpgrading] = useState(false);
    const [selectedPlanData, setSelectedPlanData] = useState({});
    const [selectionTouched, setSelectionTouced] = useState(false);
    const [upgradeDisabled, setUpgradeDisabled] = useState(false);
    const [coupon, setCoupon] = useState(null);

    function updateSelectedPlanInternal (value, skipStoreUpdate = false) {
        logInfo('useBillingPlan.updateSelectedPlan() Value :', value);
        setSelectedPlan(value);
        if (skipStoreUpdate === false) {
            billingStore.selectPlanFromID(value);
        }

        if (selectionTouched) {
            const tempPlan = billingStore.getPlanDetailsFromID(value);
            if (isFreePlan(tempPlan.name)) {
                setFreePlan(true);
            } else {
                setFreePlan(false);
            }
        }
    }

    function updateSelectedPlan (value, skipStoreUpdate = false) {
        if (!selectionTouched) {
            setUpgradeDisabled(false);
            setSelectionTouced(true);
            // wait then update
            setTimeout(() => {
                updateSelectedPlanInternal(value, skipStoreUpdate);
            }, 400);
        } else {
            updateSelectedPlanInternal(value, skipStoreUpdate);
        }
    }

    function decodeInput () {
        const planSource = billingStore.planDetails.map((plan) => {
            return {
                label: plan.name,
                value: plan.id ? plan.id : '-',
            };
        });
        logInfo('Plans Source: ', planSource);
        setPlans(planSource);
        if (isSetup) {
            selectFromParams();
        }
    }

    async function initStore () {
        await userStore.currentUser();
        if (!userStore.countryInfo) {
            userStore.getCountryInfo();
        }
        billingStore.getPlanDetails();
        if (!isSetup) {
            await billingStore.getCurrentPlan();
        } else {
            selectFromParams();
        }
    }

    function selectFromParams () {
        const settings = envStore.getEnvSettings();
        const planHandle = settings.plan;
        const plan = getPlanFromHandle(planHandle);
        // console.log('Selected Plan from Params: ', plan);
        if (plan) {
            updateSelectedPlanInternal(plan.id);
        }
    }

    function getPlanFromHandle (handleValue) {
        const planDetails = billingStore.planDetails;
        if (!planDetails || planDetails.length <= 0 || !handleValue) {
            return null;
        }
        for (let i = 0; i < planDetails.length; i++) {
            const plan = planDetails[i];
            if (plan.code === handleValue) {
                return plan;
            }
        }

        return planDetails[0];
    }

    async function upgradeFree () {
        setIsUpgrading(true);
        await billingStore.upgradeFree();
        setIsUpgrading(false);
    }

    function selectForFreeTrial () {
        logInfo('selectForFreeTrial(): isSetup ', isSetup);
        logInfo('selectForFreeTrial(): billingStore.planDetails -> ', billingStore.planDetails);
        if (!isSetup) {
            setUpgradeDisabled(true);
        }
    }

    /*
     * useEffects
     *
     */
    useEffect(() => {
        if (billingStore.planDetails && billingStore.planDetails.length > 0) {
            decodeInput();
            setPlanDetail(billingStore.planDetails[0]);
            setPlansList([...billingStore.planDetails]);
        }
    }, [billingStore.observed.planDetails]);

    useEffect(() => {
        if (billingStore.publisherPlan) {
            logInfo('useBillingFormInput.useEffect() -> Publisher Plan: ', billingStore.publisherPlan);
            setPublisherPlan(billingStore.publisherPlan);
            if (isFreeTrial(billingStore.publisherPlan.name)) {
                selectForFreeTrial();
            } else {
                if (isSetup) {
                    setSelectedPlan(billingStore.publisherPlan.id);
                } else {
                    setUpgradeDisabled(true);
                }
            }
        }
    }, [billingStore.observed.publisherPlan]);

    useEffect(() => {
        if (billingStore.selectedPlanData) {
            setSelectedPlanData({
                ...billingStore.selectedPlanData,
            });
            setFreePlan(isFreePlan(billingStore.selectedPlanData.name));
        }
    }, [billingStore.observed.selectedPlanData]);

    useEffect(() => {
        console.log('Setting -> useBillingPlan -> settings ', envStore.settings);
        if (envStore.settings && envStore.settings?.coupon) {
            billingStore.coupon = envStore.settings.coupon;
            setCoupon(envStore.settings.coupon);
            console.log('Billings Store Coupon: ', billingStore.coupon);
        }
    }, [envStore.observed.settings]);

    useEffect(() => {
        setIsUpgrading(false);
    }, [errors]);

    useStoreModuleDependency({ initStore });

    return {
        plansList,
        plans,
        planDetail,
        selectedPlan,
        updateSelectedPlan,
        publisherPlan,
        freePlan,
        upgradeFree,
        isUpgrading,
        selectedPlanData,
        upgradeDisabled,
        coupon,
    };
}

export default useBillingPlan;
