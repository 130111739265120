export const columns = [
    {
        id: 'eventName',
        label: 'Event Name*',
    },
    {
        id: 'location',
        label: 'Location*',
    },

    {
        id: 'startDate',
        label: 'Start Date*',
    },
    {
        id: 'startTime',
        label: 'Start Time*',
    },
    {
        id: 'endDate',
        label: 'End Date*',
    },

    {
        id: 'endTime',
        label: 'End Time*',
    },

    {
        id: 'allDay',
        label: 'All Day (Yes / No)',
    },

    {
        id: 'reminder',
        label: 'Reminder',
    },

    {
        id: 'secondReminder',
        label: '2nd Reminder',
    },

    {
        id: 'eventDetails',
        label: 'Event Details',
    },

    {
        id: 'quickLinkName1',
        label: 'Quick Link 1 Name',
    },

    {
        id: 'quickLinkURL1',
        label: 'Quick Link 1 URL',
    },

    {
        id: 'quickLinkName2',
        label: 'Quick Link 2 Name',
    },

    {
        id: 'quickLinkURL2',
        label: 'Quick Link 2 URL',
    },

    {
        id: 'quickLinkName3',
        label: 'Quick Link 3 Name',
    },

    {
        id: 'quickLinkURL3',
        label: 'Quick Link 3 URL',
    },

    {
        id: 'quickLinkName4',
        label: 'Quick Link 4 Name',
    },

    {
        id: 'quickLinkURL4',
        label: 'Quick Link 4 URL',
    },

    {
        id: 'quickLinkName5',
        label: 'Quick Link 5 Name',
    },

    {
        id: 'quickLinkURL5',
        label: 'Quick Link 5 URL',
    },

    {
        id: 'quickLinkName6',
        label: 'Quick Link 6 Name',
    },

    {
        id: 'quickLinkURL6',
        label: 'Quick Link 6 URL',
    },

    {
        id: 'quickLinkName7',
        label: 'Quick Link 7 Name',
    },

    {
        id: 'quickLinkURL7',
        label: 'Quick Link 7 URL',
    },

    {
        id: 'quickLinkName8',
        label: 'Quick Link 8 Name',
    },

    {
        id: 'quickLinkURL8',
        label: 'Quick Link 8 URL',
    },

    {
        id: 'facebookURL',
        label: 'Facebook URL',
    },

    {
        id: 'twitter',
        label: 'Twitter @ or #',
    },

    {
        id: 'instagramURL',
        label: 'Instagram URL',
    },

    {
        id: 'youtubeURL',
        label: 'YouTube URL',
    },

    {
        id: 'vimeoURL',
        label: 'Vimeo URL',
    },

    {
        id: 'podcastURL',
        label: 'Podcast URL',
    },

    {
        id: 'linkedInURL',
        label: 'LinkedIn URL',
    },

    {
        id: 'blogURL',
        label: 'Blog URL',
    },

    {
        id: 'tiktokURL',
        label: 'TikTok URL',
    },

    {
        id: 'iphoneAppURL',
        label: 'iPhone App URL',
    },

    {
        id: 'ipadAppURL',
        label: 'Ipad App URL',
    },

    {
        id: 'androiAppURL',
        label: 'Android App URL',
    },

    {
        id: 'snapchatURL',
        label: 'Snapchat URL',
    },

    {
        id: 'twitchURL',
        label: 'Twitch URL',
    },

    {
        id: 'discordURL',
        label: 'Discord URL',
    },

    {
        id: 'linktreeURL',
        label: 'Linktree URL',
    },

    /*
    {
        id: 'googleURL',
        label: 'Google+ URL',
    },

    {
        id: 'pinterestURL',
        label: 'Pinterest URL',
    },

    {
        id: 'myspaceURL',
        label: 'mySpace URL',
    },

    {
        id: 'foursquareURL',
        label: 'Foursquare URL',
    },

    {
        id: 'flickerURL',
        label: 'Flickr URL',
    },

    {
        id: 'blackberryAppURL',
        label: 'Blackberry App URL',
    },

    {
        id: 'stacklaURL',
        label: 'Stackla URL',
    },

    {
        id: 'soundcloudURL',
        label: 'SoundCloud URL',
    },

     */

];
