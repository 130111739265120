import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',
        width: '100%',
    },

    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        boxShadow: theme.customShadows.widget,
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    tablePagination: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    statusCell: {
        color: '#fff',
        height: '20px',
        width: '65px',
        fontSize: '11px',
        fontWeight: 'bold',
    },

    nameCell: {
        width: '200px',
        fontSize: '14px',
    },

    subscriberCell: {
        width: '60px',
        fontSize: '14px',
    },

    subscriberCellTD: {
        width: '60px',
        fontSize: '14px',
    },

    text: {
        fontSize: '12px !important',
        padding: '4px',
    },

    actionCell: {
        width: '100px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    actionCellIcon: {
        padding: '2px',
        margin: '2px',
    },

    previewCell: {
        padding: '2px',
        margin: '2px',
        width: '300px',
    },

    previewImage: {
        width: '100%',
        cursor: 'pointer',
    },

    dialogContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',

        '& .MuiDialogContent-root': {
            padding: '8px 16px !important',
        },

        '& .MuiDialogActions-root': {
            padding: '16px !important',
        },
    },

    buttonCodeDialog: {
        display: 'flex',
        width: '100%',
        minWidth: '600px',

        flexDirection: 'column',
        justifyContent: 'center',

        '& .MuiDialog-container': {
            minHeight: '600px',
        },

        '& .MuiDialogContent-root': {
            padding: '8px 16px !important',
        },

        '& .MuiDialogActions-root': {
            padding: '16px !important',
        },
    },

    dialogButtonCodeContainer: {
        width: '600px',
        minHeight: '400px',
    },

    dialogSection: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    topSpace: {
        marginTop: '16px',
    },

    leftAligned: {
        alignItems: 'flex-start',
    },

    bottomSpace: {
        marginBottom: '16px',
    },

    qrCodeLink: {
        textTransform: 'none !important',
    },

    qrCodeContainer: {
        display: 'block',
        position: 'relative',
    },

    qrCodeLogo: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        width: '32px',
        height: '32px',
        borderRadius: '2px',
    },

    buttonsTable: {
        '& .ecal-sync-widget-button': {
          maxWidth: '200px !important',
          '& > img': {
            width: '100% !important',
            maxHeight: 80,
          },

          '& > object': {
            width: '100% !important',
            maxHeight: 80,
          },
        },
      },

    qrCodeLogoImg: {
        width: '24',
        height: '24px',
    },

    buttonCodeContent: {
        width: '100%',
        minHeight: '200px',
    },

    minHeight400: {
        minHeight: '429px',
    },

    buttonCodeBlock: {
        overflow: 'auto',
        borderRadius: '6px',
        border: '1px solid #939393',
        backgroundColor: '#FCFCFC',
        padding: '16px',
        fontFamily: 'courier',
        fontSize: '12px',
        color: '#525252',
    },

    buttonCodeSocial: {
        overflow: 'auto',
        borderRadius: '6px',
        border: '1px solid #939393',
        backgroundColor: '#FCFCFC',
        padding: '2px',
        fontFamily: 'courier',
        fontSize: '12px',
        color: '#525252',
    },

    iconSpace: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        // justifyContent: 'flex-end',
        // alignItems: 'center',
    },

    buttonCodeIcon: {
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        padding: '8px',
        margin: '0px',
        color: '#232323',
    },

    buttonCodeSocialIcon: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '8px',
        margin: '0px',
        color: '#232323',
    },
    fbLink: {
      textDecoration: 'underline',
    },
    fullSize: {
      width: '100%',
      height: '100%',
    },
    widgetPreviewMode: {
      display: 'block',
      zIndex: 101,
      position: 'relative',

      top: 0,
      left: 0,
      border: 0,
      background: 'transparent',

      width: '100% !important',
      height: '100% !important',
      minHeight: 300,
      overflow: 'auto',
  },
});

export default styles;
export const useStyles = makeStyles(styles);
