import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import {
  Grid,
} from '@material-ui/core';

// styles
import { useStyles } from './styles';

import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

import { Button, Typography } from '../../components/Wrappers/Wrappers';
// import SignUpForm from './signUpForm';
import { useEnvStore } from '../../store/models';

function Error404 (props) {
  const envStore = useEnvStore();
  const classes = useStyles();
  const history = useHistory();
  //  const [googleFlow, setGoogleFlag] = useState(true);

  useEffect(() => {
    envStore.progressEnabled = false;
  }, []);

  return (
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={EcalLogoImage} alt="logo" className={classes.logotypeImage} />
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>

          <React.Fragment>
            <Grid item container className={classes.logoImageContainer}>
              <div id='logoImage' className={classes.logoImage}></div>
            </Grid>

            <div className={classes.formDividerContainer}></div>

            <Grid className={classes.messageContainer}>
                <Typography
                    variant="h2"
                    className={classes.messageTitle}
                >
                    404
                </Typography>
                <Typography
                    variant="h5"
                >
                    Oops! We cannot find the page the you are looking for.
                </Typography>
            </Grid>

            <Grid className={classes.buttonContainer}>
                <Button
                        color="primary"
                        size="large"
                        onClick={() => {
                            history.push('/login');
                        }}
                        className={classes.forgetButton}
                    >
                        Back to Home
                    </Button>
            </Grid>
          </React.Fragment>

        </div>
        <Typography variant={'body2'} color="primary" className={classes.copyright}>
          © 2010-{new Date().getFullYear()} | ECAL | HyperKu Pte Ltd | All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default observer(Error404);
