import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';
import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

import FileSelect from '../../../../commons/ecal/fileSelect';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

const INSTRUCTION = 'Override the look and feel of the widget by uploading custom CSS. For more info, see our user guide.';

function Custom (props) {
    const classes = useStyles();

    const {
        cssFile,
        uploadCSSFile,
        cssFileError = '',
    } = props;

    return (
        <React.Fragment>

            <Grid
                container
                item
                className={classes.sectionRoot}>

                    <Grid className={classes.sectionDescription}>
                        <Typography
                            variant='sub1'
                            >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classes.sectionContent}
                        >
                            <Grid className={classes.input}>
                                <Typography
                                    variant='body2'
                                    weight='medium'
                                    className={classes.uploadLabel}
                                >
                                    Upload CSS File
                                </Typography>

                                <FileSelect
                                    id="cssFile"
                                    accept=".css"
                                    label=""
                                    type="file"
                                    value={cssFile}
                                    onFileSelect={(files) => {
                                        uploadCSSFile(files);
                                    }}
                                    name="message"
                                    variant="outlined"
                                    helperText={'Upload CSS File'}
                                />

                                { cssFileError &&
                                  (
                                    <Grid>
                                      <Typography
                                        variant={'caption'}
                                        weight={'medium'}
                                        style={{ marginBottom: 30, color: 'red' }}
                                      >
                                        {cssFileError}
                                      </Typography>
                                    </Grid>
                                  )
                                }
                            </Grid>
                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

Custom.propTypes = {
    uploadCSSFile: PropTypes.func,
    cssFile: PropTypes.any,
};

Custom.defaultProps = {
    uploadCSSFile: () => {},
    cssFile: {},
};

export default Custom;
