import { useEffect } from 'react';
import { useHistory } from 'react-router';
import useConfirmationDialog from '../../commons/dialog/useConfirmationDialog';
import { isFreeTrialExpired } from '../../utils';

function useFreeTrialDialog (props) {
    const {
        publisher,
        whiteLabelSettings,
        role,
    } = props;

    const history = useHistory();

    function contactSupport () {
        if (whiteLabelSettings.labelName === 'ecal') {
            window.Intercom('showMessages');
        } else {
            window.open(whiteLabelSettings.supportContact, '_blank');
        }
    }

    const {
        openDialog: openFreeTrialExpiredDialog,
        renderDialog: renderFreeTrialExpiredDialog,
    } = useConfirmationDialog({

        onClose: () => contactSupport(),
        onConfirm: () => history.push('/admin/settings/billing'),
        title: 'Your 30 day free trial has expired.',
        message: 'We hope you\'ve enjoyed using ECAL, it\'s now time to choose a plan that\'s right for you. Please contact us now to convert your account to a new plan',
        confirmLabel: 'Upgrade plan',
        cancelLabel: 'Contact Us',
    });

    useEffect(() => {
        if (isFreeTrialExpired({
            publisher,
            role,
            whiteLabelSettings,
        })) {
            openFreeTrialExpiredDialog();
        }
    }, [publisher, role, whiteLabelSettings]);

    return {
        openFreeTrialExpiredDialog,
        renderFreeTrialExpiredDialog,
    };
}

export default useFreeTrialDialog;
