import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    /* Border */
    st12: (props) => {
        const {
            backgroundColor,
        } = props;

        return {
            fill: backgroundColor || '#00A8E2',
            filter: 'brightness(0.85)',
        };
    },

    /* Background */
    st0: (props) => {
        const {
            backgroundColor,
        } = props;

        return {
            fill: backgroundColor || '#00A8E2',
        };
    },

    /* font color */
    st1: (props) => {
        const {
            fontColor,
        } = props;

        return {
            fill: fontColor || '#FFFFFF',
        };
    },

    st2: {
        fontFamily: 'Roboto, sans-serif',
        // fontFamily: 'Open Sans, sans-serif',
    },

    st3: {
        fontSize: '15px',
        // fontWeight: '600',
    },

    st4: {
        clipPath: 'url(#SVGID_2_)',
    },

    st5: {
        fill: '#F28C00',
    },

    st6: {
        fill: '#EE7624',
    },

    st7: {
        fill: '#009BE0',
    },

    st8: {
        fill: '#01BFD7',
    },

    st9: {
        fill: '#F9BF01',
    },

    st10: {
        fill: '#76BD22',
    },

    st11: {
        fill: '#00A8E2',
    },

    st13: {
        fill: '#FFFFFF',
    },

}));
