import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  // Box,
  TablePagination,
} from '@material-ui/core';
// import { Typography } from '../../../../components/Wrappers';
import TableContainer from '../../../../commons/ECALTable/TableContainer';
// import Dialog from '../../../../commons/dialog/Dialog';

import { useStyles } from './styles';

// import { useHistory } from 'react-router';

import {
  columns,
  customCells,
} from './tableSettings';

// import {
//   useLabelsStore,
// } from '../../../../store/models';

function AdditionalUserList (props) {
    const {
        listData,
        showProgress,
        onEdit,
        onDelete,
        pageInfo,
        updatePageInfo,
    } = props;

    // const history = useHistory();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    // const labelStore = useLabelsStore();

    useEffect(() => {
      setLoading(showProgress);
    }, [showProgress]);

    return (
      <Grid item xs={12}>
        <TableContainer
              classes={classes}
              tableData={listData}
              // rowsPerPage={listData.length > 10 ? listData.length : 10}
              columns={columns}
              customCells={customCells}
              // tableTitle='User Group'
              moduleType='user group'
              showProgress={loading}
              // options={options}

              onEdit={(data) => onEdit(data)}
              onDelete={(data) => onDelete(data)}
            />

        <Grid className={classes.tablePagination}>
          <TablePagination
              rowsPerPageOptions={[]}
              page={pageInfo.page}
              rowsPerPage={pageInfo.itemsPerPage}
              count={pageInfo.totalCount}
              labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} of ${count} `;
              }}
              onChangePage={(_, page) => {
                  updatePageInfo({ page });
              }}
          />
        </Grid>
      </Grid>
  );
}

AdditionalUserList.propTypes = {
    listData: PropTypes.array,
    showProgress: PropTypes.bool,
};

AdditionalUserList.default = {
    listData: [],
    showProgress: true,
};

export default AdditionalUserList;
