import React, { useEffect, useState } from 'react';

import {
    IconButton,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
  } from '../../app/context/LayoutContext';

  import {
    Menu as MenuIcon,
    ArrowBack as ArrowBackIcon,
  } from '@material-ui/icons';

// styles
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';

function BackMenuButton (props) {
      // global
    const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const classes = useStyles();
    const [isSmall, setSmall] = useState(false);
    const theme = useTheme();

    useEffect(function () {
        window.addEventListener('resize', handleWindowWidthChange);
        handleWindowWidthChange();

        return function cleanup () {
          window.removeEventListener('resize', handleWindowWidthChange);
        };
    }, []);

    function handleWindowWidthChange () {
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme.breakpoints.values.md;
        const isSmallScreen = windowWidth < breakpointWidth;
        setSmall(isSmallScreen);
    }

    return (
        <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
            )}
      >
        {(!layoutState.isSidebarOpened && isSmall) ||
        (layoutState.isSidebarOpened && !isSmall)
? (
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        )
: (
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        )}
      </IconButton>
    );
}

BackMenuButton.propTypes = {
    open: PropTypes.bool,
};

BackMenuButton.default = {
    open: false,
};

export default BackMenuButton;
