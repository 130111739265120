import { useState, useEffect } from 'react';

import {
    defaultTemlplate,
    getThemes,
    themeMapping,
} from './utils';

function useButtonTempalteTheme (props) {
    const {
        buttonDesignData,
        updateDesignData,
    } = props;

    const [template, setTemplate] = useState(defaultTemlplate);

    useEffect(() => {
        if (buttonDesignData.template) {
            setTemplate({
                ...template,
                name: buttonDesignData.template || 'ecal-round',
                themesList: getThemes(buttonDesignData.template),
                theme: buttonDesignData.theme || 'custom',
            });
        }
    }, [buttonDesignData]);

    function updateTemplate (name) {
        setTemplate({
            ...template,
            name,
            themesList: getThemes(name),
        });
    }

    function selectTheme (newTheme, hexValue = null) {
        console.log('');
        console.log('');
        console.log('selectTheme()', newTheme);
        const theme = themeMapping[newTheme];

        console.log('Actual Theme: ', theme);

        setTemplate({
            ...template,
            theme,
        });

        if (hexValue !== null) {
            updateDesignData({
                ...buttonDesignData,
                theme,
                backgroundColour: '#' + hexValue,
            });
        } else {
            updateDesignData({
                ...buttonDesignData,
                theme,
            });
        }
    };

    return {
        template,
        setTemplate,
        updateTemplate,
        selectTheme,
    };
}

export default useButtonTempalteTheme;
