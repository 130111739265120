import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
} from '@material-ui/core';
import { Typography } from '../../components/Wrappers';

import { useStyles } from './styles';

function PlanInfo (props) {
    const {
        input,
    } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid
                container
                className={classes.planInfoContainer}
            >
                <Typography
                    className={classNames([classes.bottomSpace])}
                    variant={'h6'}>
                    ECAL { input.planName } Plan Add Ons
                </Typography>

                <Typography
                    variant={'body2'}
                    className={classes.planDescription}
                >
                    {input.description}
                </Typography>
            </Grid>
        </React.Fragment>
    );
}

PlanInfo.propTypes = {
    input: PropTypes.object,
    billingInputProps: PropTypes.any,
};

PlanInfo.defaultProps = {
    input: {},
    billingInputProps: {},
};

export default PlanInfo;
