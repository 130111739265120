import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  actionCellIcon: {
    padding: '4px',
    margin: '4px',
  },
  tableContainer: {
    '& td[class^="MuiTableCell-root"], & th[class^="MuiTableCell-root"], & span[class^="MuiChip-label"], & div[class^=MUIDataTableToolbar-titleRoot] > * ': {
      fontFamily: '"GothamRounded","Roboto","Helvetica Neue",sans-serif !important',
    },
    fontFamily: '"GothamRounded","Roboto","Helvetica Neue",sans-serif !important',
    '& >div[class^="MUIDataTable-responsiveStacked"], >div[class*="MUIDataTable-responsiveStacked"]': {
      padding: '0 12px',
    },
    '& td[class^="MuiTableCell-root"], th[class^="MuiTableCell-root"], td[class*="MuiTableCell-root", th[class*="MuiTableCell-root"]': {
      padding: '6px',
      fontFamily: '"GothamRounded","Roboto","Helvetica Neue",sans-serif !important',
    },
    '& .MuiCheckbox-colorPrimary': {
      color: '#0c0644 !important',
    },
    '& .MuiTableSortLabel-root': {
      paddingTop: '11px',
    },
    '& div[class^="MUIDataTableHeadCell-sortAction"]>span': {
      paddingTop: '11px',
    },
    '& div[class^="MUIDataTableToolbar-titleRoot"] h6': {
      fontWeight: 400,
      fontSize: '20px',
      textTransform: 'none',
      lineHeight: '32.016px',
      fontFamily: '"GothamRounded","Roboto","Helvetica Neue",sans-serif !important',
    },
    '& div[class^="MUIDataTableToolbarSelect"], div[class*="MUIDataTableToolbarSelect"]': {
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  actionsMenu: {
    '& div[class*="MuiMenu-paper"], div[class^="MuiMenu-paper"]': {
      boxShadow: '0px 1px 2px 0px #e8eafc, 0 1px 2px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
      padding: 0,
      margin: 0,
    },
  },
  actionsMenuEvents: {
    '& div[class*="MuiMenu-paper"], div[class^="MuiMenu-paper"]': {
      boxShadow: '0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
      padding: 0,
      margin: 0,
    },
  },
}));
