import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Grid,
  Tab,
  Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import Widget from '../../../components/Widget/Widget';
import EngagementsGraph from '../../homepage/charts/engagementsBarGraph';
import PopularLinksTable from '../../homepage/charts/popularLinksTable';
import DataInsights from '../../homepage/insights/pillDataInsights';
import { useDashboardStore } from '../../../store/models';
import { parseCountData } from '../../../commons/util/utilities';
import Select from '../../../commons/select/Select';
import { Typography } from '../../../components/Wrappers';
import Tabs from '../../../commons/tabs';

import { useStyles } from './styles';
import usePremiumPlan from '../../hooks/usePremiumPlan';
import FeatureNotAvailable from '../featureNotAvailable';
import useWhiteLabel from '../../../store/models/styles/useWhiteLabel';

const TOOLTIP_TEXT = {
  monthlyActives: (day) => `This is your estimated monthly active users. Note: If you haven’t sent an event in the last ${day || 30} days, this metric will be understated.`,
  eventImpression: (day) => `The number of events that have appeared in the calendar over the last ${day || 30} days.`,
  reminderImpression: (day) => `The number of reminders that have appeared in the calendar over the last ${day || 30} days.`,
  clicks: (day) => `The number of clicks on your links in the calendar over the last ${day || 30} days.`,
};

const NUMBER_OF_DAYS = [
  {
    value: 7,
    key: '7',
    label: 'Last 7 days',
  },
  {
    value: 14,
    key: '14',
    label: 'Last 14 days',
  },
  {
    value: 30,
    key: '30',
    label: 'Last 30 days',
  },
];

const getCTRvalue = (totalClicks, totalImpressions) => totalImpressions ? `${((totalClicks / totalImpressions) * 100).toFixed(2)}%` : '0.00%';

function Engagement () {
  const dashboardStore = useDashboardStore();
  const { isPremium } = usePremiumPlan();
  const { whiteLabelSettings } = useWhiteLabel();
  const {
    analyticsClicks,
    eventImpressions,
    reminderImpressions,
    observed: {
      analyticsClicksDataLoading,
      eventImpresionsDataLoading,
      reminderImpresionsDataLoading,
      popularLinksDataLoading,
    },
  } = dashboardStore;
  const classes = useStyles();
  const [numOfdaysSelected, setNumOfdaysSelected] = useState(NUMBER_OF_DAYS[2].value);
  const [eventImpressionsCount, setEventImpressionsCount] = useState(0);
  const [reminderImpressionsCount, setReminderImpressionsCount] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [tab, setTab] = useState(0);

  // const [filteredEventImpressionsCount, setFilteredEventImpressionsCount] = useState([]);
  // const [filteredReminderImpressionsCount, setFilteredReminderImpressionsCount] = useState([]);
  // const [filteredClicks, setFilteredClicks] = useState([]);

  const [reminderImpressionsList, setReminderImpressionsList] = useState([]);
  const [eventImpressionsList, setEventImpressionsList] = useState([]);
  const [analyticsClicksList, setAnalyticsClicksList] = useState([]);

  // subtract days from current date to get accurate date and timezone
  const findDate = (daysCount) => {
    let startdate;
    const todayDate = moment();
    startdate = todayDate.subtract(daysCount, 'days');
    startdate = startdate.startOf('date');
    const dateToUse = moment.parseZone(startdate).format('YYYY-MM-DD');
    return dateToUse;
  };

  const filterData = (data, id, type) => {
    let newData = [];
    let dateToFitler;
    if (id === 7) {
      dateToFitler = findDate(7);
    } else if (id === 14) {
      dateToFitler = findDate(14);
    } else {
      dateToFitler = findDate(30);
    }

    if (data) {
      newData = data;
      const dataAfterFilter = newData.filter(data => data.date > dateToFitler);
      const result = dataAfterFilter.reduce((sums, obj) => Object.keys(obj).reduce((s, k) => {
        k === 'id' || (s[k] = (s[k] || 0) + +obj[k]);

        return s;
      }, sums), {});

      if (type === 'clicks') {
        setTotalClicks(result.count);
        setAnalyticsClicksList(dataAfterFilter);
      } else if (type === 'eventImp') {
        setEventImpressionsCount(result.count);
        setEventImpressionsList(dataAfterFilter);
      } else {
        setReminderImpressionsCount(result.count);
        setReminderImpressionsList(dataAfterFilter);
      }
    }

    return newData;
  };

  useEffect(() => {
    dashboardStore.getMonthlyActives();
    dashboardStore.getEventImpressions();
    dashboardStore.getReminderImpressions();
    dashboardStore.getClicksCount();
    dashboardStore.getAnalyticsClicks();
  }, []);

  useEffect(() => {
    if (analyticsClicks || eventImpressions || reminderImpressions) {
      filterData(analyticsClicks, 2, 'clicks');
      filterData(eventImpressions, 2, 'eventImp');
      filterData(reminderImpressions, 2, 'reminderImp');
    }
  }, [analyticsClicks, eventImpressions, reminderImpressions]);

  useEffect(() => {
    if (analyticsClicks || eventImpressions || reminderImpressions) {
      filterData(analyticsClicks, numOfdaysSelected, 'clicks');
      filterData(eventImpressions, numOfdaysSelected, 'eventImp');
      filterData(reminderImpressions, numOfdaysSelected, 'reminderImp');
    }
  }, [numOfdaysSelected]);

  function renderAnalytics () {
    if (isPremium) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <Widget
              className={classes.widgetContainer}
              buttonFlag={false}
              title='Engagement'
              inheritHeight
              disableWidgetMenu
            />
          </Grid>
          <Grid item xs={12}>
            <Widget
              className={classes.widgetRangeContainer}
              buttonFlag={false}
              title=''
              inheritHeight
              disableWidgetMenu
            >
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Select
                    id='numOfDays'
                    label=''
                    name='numOfDays'
                    value={numOfdaysSelected}
                    labelHeading='Select range'
                    placeHolder=''
                    handleChange={(e, data) => {
                      setNumOfdaysSelected(e.target.value);
                    }}
                    dataForDropdown={NUMBER_OF_DAYS}
                    style={{ display: 'none' }}
                  // helperText='Please choose an option'
                  />
                </Grid>
                <Grid item xs={6} className={classes.ctrText}>
                  <div className={classes.ctrEngagementsCircle}>
                    <TouchAppIcon />
                  </div>
                  <Typography variant={'h5'}>CTR: {getCTRvalue(totalClicks, eventImpressionsCount)}</Typography>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Monthly Actives'}
                value={parseCountData(dashboardStore.monthlyActives)}
                tooltipId='monthlyActives'
                tooltipText={TOOLTIP_TEXT.monthlyActives(numOfdaysSelected)}
              >
                <div className={classes.engagementsCircle}>
                  <PeopleIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Total Clicks'}
                value={parseCountData(totalClicks)}
                isLoading={!dashboardStore.analyticsClicks}
                tooltipId='clicks'
                tooltipText={TOOLTIP_TEXT.clicks(numOfdaysSelected)}
              >
                <div className={classes.engagementsCircle}>
                  <TouchAppIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Event Impressions'}
                value={parseCountData(eventImpressionsCount)}
                tooltipId='eventImpression'
                tooltipText={TOOLTIP_TEXT.eventImpression(numOfdaysSelected)}
              >
                <div className={classes.engagementsCircle}>
                  <VisibilityIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Reminder Impressions'}
                value={parseCountData(reminderImpressionsCount)}
                tooltipId='eventImpression'
                tooltipText={TOOLTIP_TEXT.reminderImpression(numOfdaysSelected)}
              >
                <div className={classes.engagementsCircle}>
                  <VisibilityIcon />
                </div>
              </DataInsights>
            </Grid>
          </Grid>
          <Typography
            className={classes.timezoneText}
            size='sm'
            variant='body2'
            component='p'
            weight='light'
          >
            {Intl.DateTimeFormat().resolvedOptions().timeZone} Timezone
          </Typography>
          <Grid item xs={12}>
            <Widget buttonFlag={false} noBodyPadding disableWidgetMenu className={classes.widgetContainer} >
              <EngagementsGraph
                reminderImpressions={reminderImpressionsList}
                eventImpressions={eventImpressionsList}
                analyticsClicks={analyticsClicksList}
                isLoading={
                  !reminderImpressionsList ||
                  !eventImpressionsList ||
                  !analyticsClicksList ||
                  analyticsClicksDataLoading ||
                  eventImpresionsDataLoading ||
                  reminderImpresionsDataLoading
                }
              />
            </Widget>
          </Grid>
          {/* Popular links */}
          <Grid item xs={12}>
            <Widget
              className={classes.widgetContainer}
              // buttonText={'Add'}
              buttonFlag={false}
              title='Popular Links'
              // handleAdd={handleAddSchedule}
              inheritHeight
              disableWidgetMenu
              noBodyPadding
            >
                <Typography variant='body2' component='p' className={classes.subHeading}>50 most popular links (Last 30 days)</Typography>
              </Widget>
              <Box>
                <Tabs
                  onTabChange={(newTab) => {
                    setTab(newTab);
                  }}
                >
                  <Tab
                    label="By Link Name"
                  />
                  <Tab
                    label="By URL"
                  />
                </Tabs>
              </Box>
              <PopularLinksTable tab={tab} isLoading={popularLinksDataLoading} />
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <FeatureNotAvailable
          whiteLabelSettings={whiteLabelSettings}
      />
    );
  }

  return renderAnalytics();
}

export default observer(Engagement);
