import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { usePublishersStore } from '../../store/models/publishers';
// import { DEFAULT_IMAGE as ecalLogo } from '../../../commons/imageUploader/AdvertisingDefaultImage';

import {
    logInfo,
    isFreeTrial,
} from '../../utils';

import Dialog from '../../commons/dialog/Dialog';
import useUserPublisherInfo from '../../store/models/user/useUserPublisherInfo';
import usePublishersErrors from '../../store/models/publishers/errors/usePublishersErrors';

const dialogsData = [

    /* FOR BASIC DETAILS */

    {
        title: 'Save Profile',
        message: 'Are you sure you want to update user profile?',
    },

    /* FOR EMAIL ACCOUNT SETTINGS */

    {
        title: 'Update Account Settings',
        message: 'Are you sure you want to update the email?',
    },

    /* FOR PUBLISHER INFO */

    {
        title: 'Update Organisation Information',
        message: 'Are you sure you want to update organisation information.?',
    },

    /* FOR PLAN INFO */

    {
        title: 'Update Plan Information',
        message: 'Are you sure you want to update plan information?',
    },
    /* FOR EXPIRY DATE */
    {
        title: 'Update Expiry Date',
        message: 'Are you sure you want to update expiry date?',
    },

];

const SettingType = {
    PROFILE: 0,
    ACCOUNT_SETTINGS: 1,
    ORGANISATION: 2,
    PLAN: 3,
};

function useSuperAdminPublisher (props = {}) {
    const {
        user,
    } = useUserPublisherInfo({ isSuperAdmin: true });

    const publishersStore = usePublishersStore();
    const [errors, setErrors] = useState({});
    const [publisher, setPublisher] = useState({});

    const [basicDetailsData, setBasicDetailsData] = useState({});
    const [accountSettingsData, setAccountSettingsData] = useState({});
    const [companyInfo, setCompanyInfo] = useState({});
    const [planInfo, setPlanInfo] = useState({});
    const [expiryDateInfo, setExpiryDateInfo] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const [type, setTypeState] = useState(0);
    const [publisherUsers, setPublisherUsers] = useState([]);
    const [pubUsersPageInfo, setPubUsersPageInfo] = useState(publishersStore.pubUsersPageInfo);

    const [dialog, setDialog] = useState({
        title: 'Update Account Settings',
        message: 'Are you sure you want to update the email?',
    });

    const {
       errors: publisherErrors,
    } = usePublishersErrors();

    useEffect(() => {
        console.log('userSuperAdminPublisher(user updated...) loginUser USER-TOKEN ->', user.token);
        if (!isEmpty(user)) {
            initStore();
        }
    }, [user]);

    useEffect(() => {
        logInfo('publisherStore active publisher: ', publishersStore.activePublisher);
        if (publishersStore.activePublisher) {
            setPublisher(publishersStore.activePublisher);
        }
    }, [publishersStore.observed.activePublisher]);

    useEffect(() => {
        setPubUsersPageInfo(publishersStore.pubUsersPageInfo);
    }, [publishersStore.observed.pubUsersPageInfo]);

    useEffect(() => {
        if (!isEmpty(publishersStore.warning)) {
            // console.log('useSuperAdminPulibhser.useEffect().pubStore.waring -> ', publishersStore.warning);
            setErrors(publishersStore.warning);
        }
    }, [publishersStore.observed.warning]);

    useEffect(() => {
        if (!isEmpty(publisherErrors)) {
            setErrors(publisherErrors);
        }
    }, [publisherErrors]);

    useEffect(() => {
        console.log('publishersStore.publisherUsers Update -> , ', publishersStore.publisherUsers);
       setPublisherUsers(publishersStore.publisherUsers);
    }, [publishersStore.observed.publisherUsers]);

    useEffect(() => {
        publishersStore.loadActivePublisher();
        return () => {
            setErrors({});
            setPublisherUsers([]);
            publishersStore.publisherUsers = [];
        };
    }, []);

    async function initStore () {
        await publishersStore.getPlans();
        publishersStore.getPublisherUsers({
            publisher: publishersStore.activePublisher,
        });
        await publishersStore.getPublisherCurrentPlan();
    }

    function saveBasicDetails (data) {
        setType(SettingType.PROFILE);
        setBasicDetailsData(data);
        setDialogOpen(true);
    }

    function saveAccountSettings (data) {
        setType(SettingType.ACCOUNT_SETTINGS);
        setDialogOpen(true);
        setAccountSettingsData(data);
    }

    function saveCompanyInformation (data) {
        setType(SettingType.ORGANISATION);
        setDialogOpen(true);
        console.log('Set Company info -> ', data);
        setCompanyInfo(data);
    }

    function savePlan (data, expiryData) {
        setType(SettingType.PLAN);
        setDialogOpen(true);
        setPlanInfo(data);
        setExpiryDateInfo(expiryData);
    }

    function callAPIByType () {
        logInfo('callAPIByType() -> type: ', type);
        setDialogOpen(false);
        if (type === SettingType.PROFILE) {
            publishersStore.updateBasicDetails(basicDetailsData);
        } else if (type === SettingType.ACCOUNT_SETTINGS) {
            publishersStore.updateEmail({
                publisher: publishersStore.activePublisher,
                newEmail: accountSettingsData.email,
            });
        } else if (type === SettingType.ORGANISATION) {
            publishersStore.updatePublisherInfo({
                publisher: companyInfo,
            });
        } else if (type === SettingType.PLAN) {
            updatePlanAndExpiryDate();
        }
    }

    async function updatePlanAndExpiryDate () {
        await publishersStore.updatePlan({
            planInfo: planInfo,
            publisher: publishersStore.activePublisher,
        });

        if (isFreeTrial(publisher.planName)) {
            await publishersStore.updateExpiryDate({
                publisher: publishersStore.activePublisher,
                expiryDateInfo: expiryDateInfo,
            });
        }
        publishersStore.reloadSearch(publishersStore.activePublisher);
    }

    function renderSuperAdminConfirmation () {
        return (
           <Dialog
               openDialog={dialogOpen}

               closeModal={() => {
                setDialogOpen(false);
                }}

               handleAgree={() => {
                    callAPIByType();
               }}

               title={dialog.title}
               dialogContextText={dialog.message}
           />
        );
    }

    function setType (newType) {
        setTypeState(newType);
        const dialogInfo = dialogsData[newType];
        setDialog(dialogInfo);
    }

    function resetErrorState () {
        setErrors({});
        publishersStore.warning = {};
    }

    function updatePubUsersPageInfo (info) {
        publishersStore.pubUsersPageInfo = {
            ...pubUsersPageInfo,
            ...info,
        };
        setPubUsersPageInfo({
            ...publishersStore.pubUsersPageInfo,
        });
        publishersStore.getPublisherUsers({ publisher });
    }

    return {
        user: publisher,
        publisher,
        errors,

        saveBasicDetails,
        saveAccountSettings,
        saveCompanyInformation,
        savePlan,

        renderSuperAdminConfirmation,
        setType,
        plans: publishersStore.plans,
        publisherUsers,
        resetErrorState,

        pubUsersPageInfo,
        updatePubUsersPageInfo,
    };
}

export default useSuperAdminPublisher;
