import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import {
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { logInfo } from '../../../../utils';

import {
    Typography,
//    Button,
} from '../../../../components/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import useDesign from './useDesign';

import Logo from './inputLogo';
import Header from './inputHeader';
import Buttons from './inputButtons';
import Custom from './custom';
// import Height from './inputHeight';
import Settings from './inputSettings';

import WidgetPreview from './widget';

import { DesignSections } from './utils';

function PopupDesign (props) {
    const classes = useStyles();

    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
        setTabDisabled,
    } = props;

    const {

        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        designData,
        setDesignData,
        widgetRef,
        buttonLogoError,

        uploadCSSFile,
        cssFile,
        cssFileError,

    } = useDesign({
        dataSource,
        onDataChange,
        updateSavable,
        setTabDisabled,
    });

    const [expanded, setExpanded] = useState(DesignSections.LOGO);

    useEffect(() => {
        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('PopupDesign.useEffect()[] rendering...');
    }, []);
    return (
        <React.Fragment>
            <Grid
                item
                className={classes.mainContainer}
                justify={'center'}
                container>

                <Grid
                    className={classes.titleBase}
                    >
                    <Typography
                        variant='h5'
                        weight='medium'
                    >
                        Design Display
                    </Typography>
                </Grid>

                <Grid className={classes.baseContent}>

                    <Grid className={classes.controlsSpace} >

                        <Accordion
                            expanded={(expanded === DesignSections.LOGO)}
                            onChange={() => {
                                if (expanded === DesignSections.LOGO) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.LOGO);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="simpleStyling"
                                id="simpleStyling"
                            >
                                <Grid className={classes.sectionTitle}>
                                        <Typography
                                            variant='h6'
                                            weight='medium'
                                            >
                                            { DesignSections.LOGO }
                                        </Typography>
                                    </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Logo
                                    designData={designData}
                                    setDesignData={setDesignData}
                                    buttonLogo={buttonLogo}
                                    uploadButtonLogo={uploadButtonLogo}
                                    deleteButtonLogo={deleteButtonLogo}
                                    buttonLogoError={buttonLogoError}
                                />

                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === DesignSections.HEADER)}
                            onChange={() => {
                                if (expanded === DesignSections.HEADER) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.HEADER);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={DesignSections.HEADER}
                                id={DesignSections.HEADER}
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                     { DesignSections.HEADER }
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Header
                                    designData={designData}
                                    setDesignData={setDesignData}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === DesignSections.BUTTONS)}
                            onChange={() => {
                                if (expanded === DesignSections.BUTTONS) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.BUTTONS);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="custom"
                                id="custom"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                            {DesignSections.BUTTONS}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Buttons
                                    designData={designData}
                                    setDesignData={setDesignData}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === DesignSections.SETTINGS)}
                            onChange={() => {
                                if (expanded === DesignSections.SETTINGS) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.SETTINGS);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="custom"
                                id="custom"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                            {DesignSections.SETTINGS}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Settings
                                    designData={designData}
                                    setDesignData={setDesignData}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === DesignSections.CUSTOM)}
                            onChange={() => {
                                if (expanded === DesignSections.CUSTOM) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.CUSTOM);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="custom"
                                id="custom"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                    Custom Styling
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Custom
                                    styleData={designData}
                                    setStyleData={setDesignData}

                                    cssFile={cssFile}
                                    uploadCSSFile={uploadCSSFile}
                                    cssFileError={cssFileError}
                                />
                            </AccordionDetails>
                        </Accordion>

                    </Grid>

                    <Grid className={classes.widgetSpace} >
                        <WidgetPreview
                            widgetRef={widgetRef}
                            styleData={designData}
                        />
                    </Grid>

                </Grid>

                <Box
                    mt={'40px'}
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>
        </React.Fragment>
    );
};

PopupDesign.propTypes = {
    onDataChange: PropTypes.func,
    dataSource: PropTypes.any,
    updateSavable: PropTypes.func,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    setTabDisabled: PropTypes.func,
};

PopupDesign.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    setTabDisabled: () => {},
};

export default observer(PopupDesign);

/*
   <Accordion
                            expanded={(expanded === DesignSections.HEIGHT)}
                            onChange={() => {
                                if (expanded === DesignSections.HEIGHT) {
                                    setExpanded('');
                                } else {
                                    setExpanded(DesignSections.HEIGHT);
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="custom"
                                id="custom"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                            {DesignSections.HEIGHT} (px)
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Height
                                    designData={designData}
                                    setDesignData={setDesignData}
                                />
                            </AccordionDetails>
                        </Accordion>

*/
