import { useEffect, useState } from 'react';
import { AssetType } from '../../../../utils/constants';
import { useButtonsStore } from '../../../../store/models';

function useCSSUpload (props) {
    const {
        dataSource,
        designData,
        setDesignData,
        sizeLimit = 102, // Kilobytes
    } = props;

    const [cssFile, setCSSFile] = useState(dataSource?.style?.cssFile || '');
    const [cssFileError, setCssFileError] = useState('');

    const buttonsStore = useButtonsStore();

    useEffect(() => {
        if (buttonsStore.assets.buttonCss) {
            setCSSFile(buttonsStore.assets.buttonCss.fileUrl);
            setDesignData({
                ...designData,
                cssFile: buttonsStore.assets.buttonCss.fileUrl,
            });
        }
    }, [buttonsStore.observed.assets.buttonCss]);

    function isFilesizeValid (fileSize) {
      const kbSize = fileSize / 1024;
      if (kbSize >= sizeLimit) {
          return false;
      }

      return true;
    }

    function uploadCSSFile (files) {
      if (!isFilesizeValid(files[0].size)) {
        setCssFileError('File is limited to 102 KB only.');
        return;
      }
        setCssFileError('');
        setCSSFile(files[0].name);
        buttonsStore.uploadWidgetAssets({
            file: files[0],
            assetType: AssetType.BUTTON_CSS,
        });
    }

    return {
        uploadCSSFile,
        cssFile,
        cssFileError,
    };
}

export default useCSSUpload;
