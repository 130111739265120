import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import {
    useSchedulesStore,
    // useUserStore,
    // useErrorStore,
} from '../../../../../store/models/index';

import useConfirmationDialog from '../../../../../commons/dialog/useConfirmationDialog';

const CategoryLimit = {
  MAX: 35,
  TITLE: 'Category Selection Limit Exceeded',
  MESSAGE: 'A maximun of 35 categories can only be selected at once.',
};

function useCategorySelectionButton (props) {
  const {
      // dataSource,
      scheduleData,
      updateScheduleData,
      // onDataChange,
      // updateSavable,
  } = props;
    const schStore = useSchedulesStore();
    const [checked, setChecked] = useState(scheduleData.categories ? scheduleData.categories : []);
    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodesFiltered, setNodesFiltered] = useState([]);
    const [loadingTree, setLoadingTree] = useState(true);
    useEffect(() => {
      if (schStore.categoryData && schStore.categoryData.nodes) {
        setNodesFiltered(cloneDeep(schStore.categoryNodes));
        setNodes(cloneDeep(schStore.categoryNodes));
      }
    }, [schStore.categoryData.nodes]);

    useEffect(() => {
      setLoadingTree(schStore.loadingTree);
    }, [schStore.loadingTree]);

    // function updateCategoryData (data) {
    //   /* do data computation before updating the input states */
    //   /* Or update the input Source for Saving without triggering state change things */
    //   console.log('updateCategoryData', data);
    //   setChecked(data);
    //   updateSavable({
    //       ...dataSource,
    //       display: { ...data },
    //   });
    // }
    function updateCategorySelection (data) {
      if (data.length > CategoryLimit.MAX) {
        openLimitDialog(
          CategoryLimit.TITLE,
          CategoryLimit.MESSAGE
        );
        return;
      }
      const categories = [
          ...data,
      ];

      setChecked(categories);
      updateScheduleData({
        ...scheduleData,
        categories: categories,
      });
  }

    function onFilterChange (e) {
      schStore.filterText = e.target.value;
      filterTree(e.target.value);
    };

    function filterTree (filterText) {
        function getAllValuesFromNodes (nodes) {
        const values = [];
        if (nodes) {
            for (const n of nodes) {
                    values.push(n.value);
                    if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                    }
                }
            }
            return values;
        };
        function filterNodes (filtered, node) {
          let children;
          if (node.children) {
            children = node.children.reduce(filterNodes, []);
          }
          if (
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
              -1 ||
            (children && children.length)
          ) {
            let expandedNodes = [];
            expandedNodes = [node.value, ...getAllValuesFromNodes(children)];
            setExpanded(expandedNodes);
            if (node.children) {
              filtered.push({ ...node, children });
            } else {
              filtered.push(node);
            }
          }
          return filtered;
        }
        if (filterText === '') {
            setNodesFiltered(cloneDeep(nodes));
        } else {
        const filterNodesOnSearch = () => (nodes.reduce(filterNodes, []));
        const nodesAfterFilter = filterNodesOnSearch();
        setNodesFiltered(cloneDeep(nodesAfterFilter));
        }
      }

    const {
      renderDialog: renderLimitDialog,
      openDialog: openLimitDialog,
    } = useConfirmationDialog({
      showSecondaryButton: false,
    });

    return {
        checked,
        loadingTree,
        setChecked,
        nodes,
        nodesFiltered,
        setNodes,
        expanded,
        setExpanded,
        updateCategorySelection,
        onFilterChange,
        filterText: schStore.filterText,
        renderLimitDialog,
    };
}

export default useCategorySelectionButton;
