import { v4 as uuid } from 'uuid';
import mockedList, { getDomainStyles, getByPublisherId } from './clients/list';
import { getSubdomain, logInfo } from '../../../utils';

const SESSION_NAMESPACE = 'ecal-whitelabel-settings';

export function getWhiteLabelStaticSettings () {
    const styles = getDomainStyles();

    if (styles) {
        saveWhiteLabelSettings(styles);
        return styles;
    }

    const subdomain = getSubdomain();
    if (subdomain) {
        // has sub domain
        const whiteLabelSettings = mockedList[subdomain];
        if (whiteLabelSettings) {
            const settings = {
                ...mockedList.ecal,
                ...whiteLabelSettings,
            };
            saveWhiteLabelSettings(settings);
            return settings;
        }
    }

    return mockedList.ecal;
}

export function getWhiteLabelSettings () {
    this.whiteLabelSettings = getWhiteLabelStaticSettings();
    logInfo('stylesStore.action.getWhiteLabelSettings(): ', this.whiteLabelSettings);
    this.observed.whiteLabelSettings = uuid();
}

export function getWhiteLabelSettingsByPublisher () {
    const publisher = this.env.userStore?.publisher;
    console.log('getWhiteLabelSettingsByPublisher() Id: ', publisher);
    return getByPublisherId(publisher.publisherId);
}

export function saveWhiteLabelSettings (settings) {
    sessionStorage.setItem(SESSION_NAMESPACE, JSON.stringify(settings));
}

export function getStoredWhiteLabelSettings () {
    sessionStorage.getItem(SESSION_NAMESPACE);
}
