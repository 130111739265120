import React from 'react';
import Widget from '../Widget';
import { Link, useLocation } from 'react-router-dom';
import {
    Box,
    Grid,
    Breadcrumbs,
    Tabs,
    Tab,
} from '@material-ui/core';
import { Typography, Button } from '../Wrappers';
import {
    NavigateNext as NavigateNextIcon,
    CalendarToday as CalendarIcon,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/styles';

// styles
import useStyles from './styles';

// components

// import { useLayoutState } from '../../context/LayoutContext';
// import structure from '../Sidebar/SidebarStructure';
import useSidebarStructure from '../Sidebar/useSidebarStructure';
// Sidebar structure

// Tab styling

const CustomTab = withStyles(theme => ({
    root: {
        minWidth: 72,
        textTransform: 'none',
        fontWeight: 400,
    },
}))(props => <Tab {...props} />);
// Sidebar structure

const BreadCrumbs = (props) => {
    const {
        publisher,
    } = props;
  const location = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(2);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    structure,
    } = useSidebarStructure({ publisher });

  const open = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const id = open ? 'add-section-popover' : undefined;
  // eslint-disable-next-line no-unused-vars
  const handleClick = event => {
      setAnchorEl(open ? null : event.currentTarget);
  };

  function formatBreadcrumb (text) {
    const formatMap = {
        apikeys: 'API Keys',
        optins: 'Opt-ins',
    };
    return formatMap[text.toLowerCase()] ? formatMap[text.toLowerCase()] : text;
  }

  const renderBreadCrumbs = () => {
    let url = location.pathname;

    /*
     * Check whether url ends with '/'.
     * This validation is needed because
     * cognito federated signin will redirect to url
     * with extra '/' at the end of the url.
    */

    if (url.slice(-1) === '/') { // does it ends with '/' ?
        url = url.slice(0, -1); // remove the last character
    }

    const route = url.split('/')
    .slice(1)
    .map(route => route
      .split('-')
      .map(word => {
          if (!word[0]) {
              return '';
          }
          return word[0].toUpperCase() + word.slice(1);
      })
      .join(' ')
    );

    const length = route.length;
    return route.map((item, index) => {
      const middlewareUrl = '/' + url.split('/').slice(1, index + 2).join('/');
      return (
        <Breadcrumbs
          key={index + '_b'}
          separator={
              <NavigateNextIcon fontSize="small" />
          }
          aria-label="breadcrumb"
        >
          <Typography variant="body1" color={length === index + 1 ? 'primary' : ''}>
            {length === index + 1
              ? formatBreadcrumb(item)
              : <Link data-intercom-target={`breadcrumb-link-${middlewareUrl}`} to={middlewareUrl} style={{ color: 'unset', textDecoration: 'none' }}>
                {formatBreadcrumb(item)}
              </Link>
            }
          </Typography>
        </Breadcrumbs>
      );
    });
  };

  // global
  // eslint-disable-next-line no-unused-vars
//   const layoutState = useLayoutState();

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  function a11yProps (index) {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
      };
  }
  return (
    <Widget
      disableWidgetMenu
      inheritHeight
      className={classes.margin}
      bodyClass={classes.navPadding}
    >
      <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
      >

          {// eslint-disable-next-line
            structure.map(c => {
              if (
                  window.location.hash.includes(c.link) &&
                  c.link && c.label === 'Dashboard'
              ) {
                  return (
                      <Box
                          display="flex"
                          alignItems="center"
                          key={c.id}
                      >
                          <Breadcrumbs aria-label="breadcrumb">
                              <Typography variant="h4">
                                  {c.label}
                              </Typography>
                          </Breadcrumbs>
                          {window.location.hash.includes(
                              '/app/dashboard'
                          ) && (
                              <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  aria-label="simple tabs example"
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  style={{ marginLeft: 38 }}
                              >
                                  <CustomTab
                                      label="Today"
                                      {...a11yProps(0)}
                                  />
                                  <CustomTab
                                      label="This week"
                                      {...a11yProps(1)}
                                  />
                                  <CustomTab
                                      label="This month"
                                      {...a11yProps(2)}
                                  />
                                  <CustomTab
                                      label="This year"
                                      {...a11yProps(3)}
                                  />
                              </Tabs>
                          )}
                      </Box>
                  );
              }
          })}
          {window.location.hash.includes('/app/dashboard')
? (
              <Box display="flex" alignItems="center">
                  <CalendarIcon
                      className={classes.calendarIcon}
                  />
                  <Typography className={classes.date} style={{ marginRight: 38 }}>
                      29 Oct 2019, Tuesday
                  </Typography>
                  <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                  >
                      Latest Reports
                  </Button>
              </Box>
          )
: (
            <Breadcrumbs
                separator={
                  <NavigateNextIcon fontSize="small" />
              }
              aria-label="breadcrumb"
            >
              {renderBreadCrumbs()}
            </Breadcrumbs>
          )}
      </Grid>
  </Widget>

  );
};
export default BreadCrumbs;
