import { useState, useEffect } from 'react';
import useUserPublisherInfo from '../../../../store/models/user/useUserPublisherInfo';
function useDetails (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
    } = props;
    // const buttonsStore = useButtonsStore();

    const {
        publisher,
    } = useUserPublisherInfo();

    function getDipslayName () {
        if (publisher?.name) {
            return publisher?.name;
        }

        if (publisher?.orgName) {
            return publisher.orgName;
        }

        return publisher?.company;
    }

    function decodeSource () {
        return {
            displayName: getDipslayName(),
            displayId: dataSource.name,
        };
    }

    function encodeSavable (data) {
        return {
            ...dataSource,
            name: data.displayId,
        };
    }

    const [detailsData, setDetailsDataState] = useState(decodeSource());

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        setDetailsData(decodeSource());
    }, [dataSource, publisher]);

    function setDetailsData (data) {
        setDetailsDataState(data);
        updateSavable(encodeSavable(data));
    }

    return {
        detailsData,
        setDetailsData,
    };
}

export default useDetails;
