import { makeStyles } from '@material-ui/core/styles';

// import EcalLogoImage from '../../commons/images/brandImage/ECALSuperstar.jpg';
// import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';
// import EcalLogo from '../../commons/images/brandImage/ECAL_Logo.svg';

const styles = (theme) => ({
    page: ({ whiteLabelSettings }) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${whiteLabelSettings.background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        height: '70%',
        marginTop: '64px',
        overflow: 'auto',
        backgroundColor: '#FFFFFF',
        padding: '24px',
        [theme.breakpoints.up('sm')]: {
            // height: '500px',
            // width: '700px',
        },
        [theme.breakpoints.up('md')]: {
            // height: '500px',
        },
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFFFFF',
    },

    superAdminText: {
      marginRight: '16px',
    },

    logoImageContainer: {
        width: '100%',
        height: '90px',
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 20px 10px 0px',
        top: '20px',
        position: 'absolute',
      },

      logoImage: ({ whiteLabelSettings }) => {
        return {
          backgroundSize: '150px',
          backgroundImage: `url(${whiteLabelSettings.logo})`,
          width: '150px',
          height: '30px',
        };
      },

      searchField: {
        padding: '18.5px 14px',
        borderRadius: '4px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px',
        borderStyle: 'solid',
      },

});

export default styles;
export const useStyles = makeStyles(styles);
