import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import {
    ColorPicker,
} from 'material-ui-color';

import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';
import SelectSearch from '../../../../commons/ecal/singleselectsearch';
import ImageUploader from '../../../../commons/imageUploader/ImageUploader';
import Language from '../../../../commons/util/Language.json';

import { colorPickerValueToHex } from '../../../../utils';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

function languageWithAutoDetect () {
    return Language.reduce((current, lang, index) => {
        if (index === 0) {
            return [{
                value: 'auto',
                label: 'Auto-Detect',
            }];
        }
        return [
            ...current,
            lang,
        ];
    }, []);
}

const AUTO_DETECT_TEXT = 'Select \'Auto Detect\' option and your button display will appear in the user\'s detected language.';
function Basics (props) {
    const classes = useStyles();

    const {
        styleData,
        setStyleData,
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        buttonLogoError,
        isUploading,
    } = props;

    return (
        <React.Fragment>
                <Grid
                    container
                    item
                    className={classes.sectionRoot}>

                        <Grid className={classes.sectionContent}>
                            <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Button Colour
                                </Typography>
                                <ColorPicker
                                    value={styleData.secondaryColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setStyleData({
                                            ...styleData,
                                            // secondaryColour: '#' + value.hex,
                                            secondaryColour: colorPickerValueToHex(value),
                                        });
                                    }}
                                    hideTextfield
                                    />
                            </Grid>

                            <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Heading Colour
                                </Typography>
                                <ColorPicker
                                    value={styleData.headingColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setStyleData({
                                            ...styleData,
                                            // headingColour: '#' + value.hex,
                                            headingColour: colorPickerValueToHex(value),
                                        });
                                    }}
                                    hideTextfield
                                    />
                            </Grid>

                            <Grid
                                className={classes.input}
                            >
                                 <Grid className={classes.logoTitle}>
                                    <Typography
                                            variant={'body1'}
                                            // weight='medium'
                                        >
                                            Language
                                    </Typography>
                                </Grid>
                                <SelectSearch
                                    data-intercom-target={'button-design-language'}
                                    dataIntercomTarget='basic-widget-language'
                                    selected={styleData.language ? [styleData.language] : undefined}
                                    onSelect={(data) => {
                                        setStyleData({
                                            ...styleData,
                                            language: data[0],
                                        });
                                    }}
                                    helperText={(
                                        <Typography
                                            variant={'sub1'}
                                        >
                                            <b> Auto-detection! </b>
                                            { AUTO_DETECT_TEXT }
                                        </Typography>
                                    )}
                                    placeHolder='Default Language'
                                    dataProvider={languageWithAutoDetect()}
                                />
                            </Grid>

                            <Grid className={classes.input}>
                                <Grid
                                    container
                                    item
                                    className={classes.logoSpace}
                                    data-intercom-target={'button-design-logo'}
                                    >
                                        <Grid className={classes.logoTitle}>
                                            <Typography
                                                    variant={'body1'}
                                                    // weight='medium'
                                                >
                                                    Logo
                                            </Typography>
                                        </Grid>
                                      {console.log('dedign pop-up buttonLogo? ', buttonLogo)}
                                        <ImageUploader
                                            layoutDesign={'row'}
                                            hasCreate={true}
                                            hasDelete={true}
                                            imageData={buttonLogo}
                                            createLabel={'Upload'}
                                            imageHeight={'auto'}
                                            onImageSelected={(files) => {
                                                uploadButtonLogo(files);
                                                console.log('dedign pop-up upload buttonLogo? ', files);
                                            }}
                                            onImageDelete={() => {
                                              console.log('btn logo img deleted?');
                                              deleteButtonLogo();
                                            }}
                                            isUploading={isUploading}
                                        />
                                        { buttonLogoError &&
                                          (
                                              <Grid>
                                                  <Typography
                                                      variant={'caption'}
                                                      weight={'medium'}
                                                      style={{ marginBottom: 30, color: 'red' }}
                                                  >
                                                      {buttonLogoError}
                                                  </Typography>
                                              </Grid>
                                          )
                                      }
                                </Grid>

                            </Grid>

                        </Grid>

                </Grid>
        </React.Fragment>
    );
};

Basics.propTypes = {
    setStyleData: PropTypes.func,
    uploadButtonLogo: PropTypes.func,
    styleData: PropTypes.object,

    buttonLogo: PropTypes.any,
    imageError: PropTypes.bool,
    buttonLogoError: PropTypes.string,
    isUploading: PropTypes.bool,
};

Basics.defaultProps = {
    setStyleData: () => {},
    uploadButtonLogo: () => {},
    styleData: {},
    buttonLogo: null,
    imageError: false,
    buttonLogoError: '',
    isUploading: false,
};

export default Basics;
