import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import {
    Grid,
} from '@material-ui/core';

// import { useStyles } from './styles';

import {
    useOptinsStore,
} from '../../store/models';
import useOptinsErrors from '../../store/models/optins/errors/useOptinsErrors';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import useStoreModuleDependency from '../hooks/useStoreModuleDependency';

import OptinsList from './list';

function Optins (props) {
    const optinStore = useOptinsStore();

    const [optinsData, setOptinsData] = useState([]);
    const history = useHistory();

    const {
        errors,
    } = useOptinsErrors({
        input: null,
    });

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors,
        onCloseError: () => {
            optinStore.getOptins();
        },
    });

    function initStore () {
        optinStore.getOptins();
    }

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        if (optinStore.optins) {
            setOptinsData(optinStore.optins);
        }
    }, [optinStore.observed.optins]);

    return (
        <React.Fragment>
            <Grid container>
                <OptinsList
                    optinsData={optinsData}
                    onAdd={() => {
                        console.log('on Optins Add');
                        history.push('/admin/marketing/optins/add');
                    }}
                    showProgress={optinStore.observed.optinsLoading}
                />
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>
    );
}

export default observer(Optins);
