export const defaultApiKey = '2nXpdLBh2akNjd7B0T8zBLIVbWcxzSySP9CS6Muk4GU=61779d';

export const instruction1 = '1. Copy & Paste this JavaScript code into the <head> of the website. ';
export const instruction2 = '2. Then, Copy & Paste this HTML code wherever you would like the {WidgetType} to appear on the website.';

export const socialInstruction = '1. Add this link to your email, or social media post. When users click the link, your display will be opened in a new browser window.';
export const socMedLink = 'https://testing-sync.ecal.com/button/v1/ecal.html?widgetId=6195ee2fa3294d00104329f5&apiKey=2nXpdLBh2akNjd7B0T8zBLIVbWcxzSySP9CS6Muk4GU=61779d';
export const socMedInstruction = 'Add this link to your email, or social media post. When users click the link, your display will be opened in a new browser window.';
export const facebookInstruction = 'To publish this display as a Facebook App on your official Facebook page, please follow ';
export const facebookLink = 'https://ecal.com/wp-content/uploads/2021/12/ECAL-Facebook-Guide.pdf';
export const instructionIframeSrc = 'https://docs.ecal.com/basics/button/app.html';

const headCode = `<script>
  !function(e,t,n,c,i,a,s){i=i||'EcalWidget',e.EcalWidgetObject=i,e[i]=e[i]||function(){(e[i].q=e[i].q||[]).push(arguments)},a=t.createElement(n),s=t.getElementsByTagName(n)[0],e[i].u=c,a.async=1,a.src=c,s.parentNode.insertBefore(a,s)}(window,document,'script', '//testing-sync.ecal.com/button/v1/main.js' + '?t=' + Date.now());
  (function(){function l() {var w=window.ECAL_SYNC_WIDGET=EcalWidget.init({apiKey: '{APIKEY_CODE_CODE}'});}
  ;(window.attachEvent?window.attachEvent('onload', l):window.addEventListener('load', l, false));
  }());
</script>`;

export const bodyCode = `<button class='ecal-sync-widget-button' data-ecal-widget-id='{WIDGET_ID}' {CONFIG}>
Sync to Calendar
</button>`;

export default headCode;

export function downloadGeneratedData (data) {
  const alink = document.createElement('a');
  alink.setAttribute('href', data);
  alink.setAttribute('download', 'widget-qr-code');
  alink.setAttribute('style', 'display: none;');
  document.body.appendChild(alink);
  alink.click();
  document.body.removeChild(alink);
}

export function resizeBy (canvas, scale) {
  const resizedCanvas = document.createElement('canvas');
  const resizedContext = resizedCanvas.getContext('2d');
  const width = canvas.width;
  const height = canvas.height;
  resizedCanvas.width = width * scale;
  resizedCanvas.height = height * scale;
  resizedContext.drawImage(canvas, 0, 0, width * scale, height * scale);
  return resizedCanvas.toDataURL();
}

// return a promise to ensure all images even when dynamically generated is loaded before canvas draws it.

export async function qrCodeWithLogo (qrCanvas, logoImage) {
  const p = new Promise((resolve, reject) => {
    const width = qrCanvas.width;
    const height = qrCanvas.height;

    const qrImage = document.createElement('img');
    /* draw qr image to canvas when merge image is loaded */
    qrImage.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;

      const logoSize = width / 5;
      const logo = {
        width: logoSize,
        height: logoSize,
        x: (width - logoSize) / 2,
        y: (height - logoSize) / 2,
      };

      const boxSize = logoSize + 6;
      const box = {
        width: boxSize,
        height: boxSize,
        x: (width - boxSize) / 2,
        y: (height - boxSize) / 2,
        radius: 4,
        fill: '#FFFFFF',
        stroke: false,
      };

      ctx.drawImage(qrImage, 0, 0, width, height);

      roundRect(
        ctx,
        box.x,
        box.y,
        box.width,
        box.height,
        box.radius,
        box.fill,
        box.stroke
      );

      ctx.drawImage(logoImage, logo.x, logo.y, logo.width, logo.height);

      resolve(canvas);
    };

    qrImage.setAttribute('width', width);
    qrImage.setAttribute('height', height);
    qrImage.setAttribute('src', qrCanvas.toDataURL());
  });
  return p;
}

export function navigateBlank (url) {
  const alink = document.createElement('a');
  alink.setAttribute('href', url);
  alink.setAttribute('target', '_blank');
  document.body.appendChild(alink);
  alink.click();
  document.body.removeChild(alink);
}

function roundRect (ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (const side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}
