import Amplify from 'aws-amplify';
import awsConfig, { redirect } from './aws-exports';
import { getSubDomainURL } from '../store/models/styles/clients/list';
import { logInfo } from '../utils/index';

const hostApi = process.env.NODE_ENV === 'development'
    ? 'http://localhost'
    : 'https://sing-generator-node.herokuapp.com';
const portApi = process.env.NODE_ENV === 'development' ? 8080 : '';
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ''}/api`;
const redirectUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://flatlogic.github.io/react-material-admin-full';

export default {
  hostApi,
  portApi,
  baseURLApi,
  redirectUrl,
  remote: 'https://sing-generator-node.herokuapp.com',
  isBackend: process.env.REACT_APP_BACKEND,
  auth: {
    email: 'admin@flatlogic.com',
    password: 'password',
  },
};

/*
const appConfig = {
  //    googleClientID: '921894458693-v2j9t2qv6u45l17buohlft1n5lg7ec7j.apps.googleusercontent.com',
      googleClientID: '562162205913-lvutdnomu16lba1irugq2p5g8pb5ttlh.apps.googleusercontent.com',
};
*/

/* NOTE THIS CONFIG IS NOT BEING USED  Anymore ... */
/*
function getAppConfig () {
  const conf = {
    googleClientID: '562162205913-lvutdnomu16lba1irugq2p5g8pb5ttlh.apps.googleusercontent.com',
  };

  if (process.env.REACT_APP_ENV_VAR === 'stage') {
    conf.googleClientID = '921894458693-v2j9t2qv6u45l17buohlft1n5lg7ec7j.apps.googleusercontent.com';
  }
  return conf;
}

export const AppConfig = getAppConfig();
*/

function isLocalHost () {
  /*
  if (process.env.REACT_APP_ENV_VAR !== 'local') {
    return false;
  }
  */
  console.log('Host Name: ', window.location.hostname);
  if (window.location.hostname.indexOf('localhost') !== -1 ||
  window.location.hostname === '[::1]' ||
   window.location.hostname.match(
     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
   )) {
     return true;
   }
  return false;
}

const externalHosts = [
  'events.helix-playground.net',
  'events.helix-uat.net',
  'events.helixleisure.net',
];

function isHosted () {
  const hostName = window.location.hostname;
  for (let i = 0; i < externalHosts.length; i++) {
    if (hostName === externalHosts[i]) {
      return {
        hostName,
        hosted: true,
      };
    }
  }

  return {
    hosted: false,
  };
}

function getRedirectURLs () {
  const {
    hosted,
    hostName,
  } = isHosted();

  if (hosted) {
    logInfo('Is Hosted: ', hostName);
    return {
      signIn: `https://${hostName}/home/`,
      signOut: `https://${hostName}/login`,
    };
  }

  const prop = process.env.REACT_APP_ENV_VAR;
  const subdomain = getSubDomainURL();
  const envInfo = redirect[prop];
  const baseDomain = envInfo.baseDomain;

  if (subdomain) {
    if (isLocalHost()) {
      return {
        signIn: `https://${subdomain}.${redirect.local.baseDomain}/home/`,
        signOut: `https://${subdomain}.${redirect.local.baseDomain}/login`,
      };
    }

    return {
      signIn: `https://${subdomain}.${baseDomain}/home/`,
      signOut: `https://${subdomain}.${baseDomain}/login`,
    };
  }

  return {
    signIn: isLocalHost() ? redirect.local.signin : envInfo.signin,
    signOut: isLocalHost() ? redirect.local.signout : envInfo.signout,
  };
}

export function configureAWSWithHostedUI () {
  const urls = getRedirectURLs();

  const finalAWSConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: urls.signIn,
      redirectSignOut: urls.signOut,
    },
  };
  logInfo('conf.config.AWSConfig: ', finalAWSConfig);
  Amplify.configure(finalAWSConfig);
}

export const APIKeys = {
  Google: '',
};
