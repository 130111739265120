/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { scrollToTop, logInfo } from '../../utils';
import { isEmpty } from 'lodash';
import {
    usePublishersStore,
} from '../../store/models';

import useStoreModuleDependency from '../hooks/useStoreModuleDependency';

import Dialog from '../../commons/dialog/Dialog';

function useModuleList (props) {
    const {
        columns,
        filters,
    } = props;
    const history = useHistory();
    const [visibleColumns, setVisibleColumns] = useState(columns);

    const publishersStore = usePublishersStore();

    const [showSearch, setShowSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [listData, setListData] = useState([]);
    const [progress, setProgress] = useState({
        publishers: false,
    });

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    const [statusData, setStatusData] = useState({});
    const [statusTarget, setStatusTarget] = useState(false);

    function initStore () {
        setProgress({ ...progress, buttons: false });
        scrollToTop();
    }

    useEffect(() => {
        if (!isEmpty(publishersStore.publishers)) {
            setListData(publishersStore.publishers);
        }
    }, [publishersStore.observed.publishers]);

    useStoreModuleDependency({ initStore });

    function search (query) {
        publishersStore.resetPagingation();
        publishersStore.searchPublishers(query, filters);
    }

    // async function updateStatus (row) {
    //     logInfo('updateStatus() Status: ', row);
    //     await publishersStore.updateStatus(row);
    // }

    function editPublisher (publisher) {
        logInfo('editPublishers() -> ', publisher);
        publishersStore.setActivePublisher(publisher);
        history.push('/superadmin/edit/' + publisher.publisherId);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            search(searchValue);
        }
    };

    function updateStatus (row) {
        logInfo('updateStatus() Status: ', row);
        setStatusData(row);
        setStatusTarget(true);
    }

    function renderStatusDialog () {
        return (
           <Dialog
               openDialog={statusTarget}
               handleAgree={() => {
                    setStatusTarget(false);
                    publishersStore.updateStatus(statusData);
               }}

               closeModal={() => {
                    setStatusTarget(false);
               }}
               title={'Change Publisher Status'}
               dialogContextText={'Are you sure you want to change the publisher\'s status?'}
           />
        );
    }

    return {
        listData,
        progress,

        deleteOpen,
        deleteTarget,
        setDeleteOpen,
        setDeleteTarget,
        pageInfo: publishersStore.pageInfo,

        selectedRows,
        setSelectedRows,

        visibleColumns,
        setVisibleColumns,
        search,
        history,

        showSearch,
        searchValue,
        handleKeyDown,
        setSearchValue,
        setShowSearch,
        updateStatus,
        editPublisher,

        renderStatusDialog,
        statusData,
        setStatusData,
        statusTarget,
        setStatusTarget,
        paginate: (p) => publishersStore.paginate(p),
    };
}

export default useModuleList;
