import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import {
    Grid,
} from '@material-ui/core';

import {
    useAPIKeyStore, useEnvStore,
} from '../../store/models';

import useStoreModuleDependency from '../hooks/useStoreModuleDependency';
import useApiKeyErrors from '../../store/models/apikey/errors/useApiKeyErrors';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import List from './list';

function ApiKey (props) {
    const apikeyStore = useAPIKeyStore();
    const envStore = useEnvStore();

    const [listData, setListData] = useState([]);
    const [showDeactivate, setShowDeactivate] = useState(false);
    const [isProgress, setIsProgress] = useState(apikeyStore.observed.apikeysLoading);
    const history = useHistory();

    const { errors } = useApiKeyErrors({ input: null });
    const { renderErrorDialog } = useModuleErrorDialog({ errors });

    function initStore () {
        apikeyStore.getAPIKeys();
    }

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        if (apikeyStore.apikeys) {
            setListData(apikeyStore.apikeys);
            setShowDeactivate(!apikeyStore.hasInactive);
        }
    }, [apikeyStore.observed.apikeys]);

    useEffect(() => {
        setListData([]);
        setIsProgress(false);
        envStore.closeProgress();
    }, [errors]);

    useEffect(() => {
        envStore.closeProgress();
        setIsProgress(apikeyStore.observed.apikeysLoading);
    }, [apikeyStore.observed.apikeysLoading]);

    return (
        <React.Fragment>
            <Grid container>
                <List
                    listData={listData}
                    showDeactivate={showDeactivate}
                    onAdd={() => {
                        history.push('/admin/developers/apikeys/add');
                    }}
                    showProgress={isProgress}
                />
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>
    );
}

export default observer(ApiKey);
