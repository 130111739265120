import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    statusButton: {
      minWidth: '82px',
    },
    tableRow: {
      '& td': {
        padding: '4px',
      },
    },
    tableCell: {
      padding: '4px',
    },
    noData: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      color: '#737373',
      marginTop: 12,
    },
}));
