import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    TextField,
    // Tab,
    // Box,
} from '@material-ui/core';

import { Typography } from '../../../components/Wrappers';

import SelectSearch from '../../../commons/ecal/singleselectsearch';
import Select from '../../../commons/select/Select';
import WorksheetDownload from './worksheetDownload';
import { useStyles } from './styles';

const Instructions = {
    LINE_1: 'You can easily import your events from an Excel (.xlsx) or a CSV (.csv) file.',
    LINE_2: 'We recommend using one of our pre-formatted import worksheets above.',
    LINE_3: 'Data formatting rules:',
    LINE_4: 'Do not reformat cells, unless changing the date format.',
    LINE_5: 'Date Formats: (1) dd/mm/yyyy or (2) mm/dd/yyyy. e.g.: 25/11/2022 or 11/25/2022',
    LINE_6: 'Time Format: hh:mm:ss (24 hour format). e.g.: 23:00:00 is 11pm',
    LINE_7: 'Reminder Values: - / 5M / 15M / 30M / 1H / 2H / 3H / 6H / 12H / 18H / 1D / 2D / 7D / 14D / 28D',
    LINE_8: 'Note that "-" denotes no reminder, M = minute, H = Hour, D = Day',
};

function ImportScheduleDetails (props) {
    const {
        input,
        setInput,
        onSearchSchedule,
        onSelectSchedule,
        scheduleProvider,

        genres,
        subGenres,
        showSubGenres,
    } = props;
    const classes = useStyles();

    function renderEditableScheduleGenre () {
        return (
            <React.Fragment>
            <Grid className={classNames([classes.input, classes.inputMargin])}>
                <TextField
                    id="scheduleName"
                    label="Schedule name*"
                    value={input.scheduleName}
                    className={classes.textInput}
                    onChange={(e) => {
                        const v = e.target.value;
                        setInput({
                            ...input,
                            scheduleName: v,
                        });
                    }}
                    name="scheduleName"
                    variant="outlined"
                    helperText={'Please enter a name for a new schedule.'}
            />
            </Grid>
            <Grid className={classNames([classes.input, classes.inputMargin])}>
                <Select
                    marginBottom={'0px'}
                    id='genre'
                    name='genre'
                    value={input.genre}
                    labelHeading='Genre*'
                    placeHolder='Genre*'
                    handleChange={(e, data) => {
                        console.log(' value: ', data.props.value);
                        setInput({
                            ...input,
                            genre: data.props.value,
                        });
                    }}
                    dataForDropdown={genres}
                    // error={errors.genre}
                    helperText={'Please select the genre that best suits the events'}
                />
            </Grid>
            { showSubGenres && (
                <Grid className={classNames([classes.input, classes.inputMargin])}>
                    <Select
                        marginBottom={'0px'}
                        id='sub-genre'
                        name='subGenre'
                        value={input.subGenre}
                        labelHeading='Sub-Genre*'
                        placeHolder='Sub-Genre*'
                        handleChange={(e, data) => {
                            console.log(' value: ', data.props.value);
                            setInput({
                                ...input,
                                subGenre: data.props.value,
                            });
                        }}
                        dataForDropdown={subGenres}
                        // error={errors.subGenre}
                        helperText={'Please select a sub-genre'}
                    />
                </Grid>
            )}
        </React.Fragment>
        );
    }

    function renderNonEditableScheduleGenre () {
        return (
            <React.Fragment>
                <Grid className={classNames([classes.input, classes.inputMargin])}>
                <TextField
                    disabled
                    id="genre"
                    label="Genre"
                    value={input.genre}
                    className={classes.textInput}
                    name="genre"
                    variant="outlined"
                    helperText={'Please select the genre that best suits the events'}
                />
            </Grid>
            { showSubGenres && (
                <Grid className={classNames([classes.input, classes.inputMargin])}>
                <TextField
                    disabled
                    id="subGenre"
                    label="Sub-Genre"
                    value={input.subGenre}
                    className={classes.textInput}
                    name="subGenre"
                    variant="outlined"
                    helperText={'Please select a sub-genre'}
                />
                </Grid>
            )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid container className={classes.stepContentRoot}>
                <Grid className={classNames([classes.input, classes.inputTopMargin, classes.inputMargin])}>
                  <Grid>
                    <Typography
                      variant="body1"
                    >
                      Import Worksheets
                    </Typography>
                  </Grid>
                  <WorksheetDownload/>
                  <Grid className={classes.worksheetSubtitle}>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_1 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_2 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> <strong>{ Instructions.LINE_3 }</strong> </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_4 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_5 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_6 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_7 } </Grid>
                    <Grid className={classes.worksheetSubtitleLine}> { Instructions.LINE_8 } </Grid>
                  </Grid>
                </Grid>
                <Grid className={classNames([classes.input, classes.inputMargin])}>
                    <SelectSearch
                        showLabelAlways={true}
                        label={'Select a Schedule for import'}
                        selected={input.selectedSchedule ? input.selectedSchedule : ['-']}
                        onSelect={(data) => {
                            onSelectSchedule(data);
                            /*
                            setInput({
                                ...input,
                                selectedSchedule: data,
                            });
                            */
                        }}
                        helperText={'Please select the Schedule where the events will be imported'}
                        placeHolder='Select a Schedule for import'
                        dataProvider={scheduleProvider}

                        isRemoteSearch={true}
                        onRemoteSearch={(search) => {
                            onSearchSchedule(search);
                        }}
                    />
                </Grid>
                {input.isNewSchedule ? renderEditableScheduleGenre() : renderNonEditableScheduleGenre()}
            </Grid>
        </React.Fragment>

    );
};

ImportScheduleDetails.propTypes = {
    dataProvider: PropTypes.any,
    input: PropTypes.any,
    setInput: PropTypes.func,
    onSearchSchedule: PropTypes.func,
    scheduleProvider: PropTypes.array,
    genres: PropTypes.array,
    subGenres: PropTypes.array,
    showSubGenres: PropTypes.bool,
};

ImportScheduleDetails.defaultProps = {
    dataProvider: {},
    input: {},
    setInput: () => {},
    onSearchSchedule: () => {},
    onSelectSchedule: () => {},
    scheduleProvider: [],
    genres: [],
    subGenres: [],
    showSubGenres: false,
};

export default ImportScheduleDetails;

/*
     <Grid className={classes.input}>
            <Select
                marginBottom={'0px'}
                id='Input Type'
                name='Schedul Type'
                value={input.selectedType}
                labelHeading= 'Select a Type'
                placeHolder='Select a Type'
                handleChange={(e, data) => {
                    setInput({
                        ...input,
                        selectedType: data.props.value,
                    });
                }}

                dataForDropdown={dataProvider.scheduleTypes}
            />
    </Grid>
*/
