import { useState } from 'react';
import moment from 'moment';

function useDatePicker (props) {
    const {
      onDateUpdate,
      useTwoWeeksAsDefault,
    } = props;
    const now = new Date();

    const _start = moment(
        new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            0,
            0,
            0,
            0)
    );

    const _end = moment(_start).add(0, 'days').subtract(0, 'seconds');
    const startDate = useTwoWeeksAsDefault ? moment(_start).subtract(13, 'days') : _start;
    const initialDateStr = useTwoWeeksAsDefault ? `${startDate.format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}` : '';

    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(_end);
    const [dateDisplay, setDateDisplay] = useState(initialDateStr);
    const [maxDate] = useState(moment(_start).add(0, 'hour'));

    const [ranges] = useState({
        'Today Only': [
            moment(_start),
            moment(_end),
        ],

        'Yesterday Only': [
            moment(_start).subtract(1, 'days'),
            moment(_end).subtract(1, 'days'),
        ],

        'Last 7 Days': [
            moment(_start).subtract(6, 'days'),
            moment(_end),
        ],

        'Last 14 Days': [
            moment(_start).subtract(13, 'days'),
            moment(_end),
        ],

        'Current Month': [
            moment().startOf('month'),
            moment(end),
        ],

        'Last Month': [
            moment().startOf('month').subtract(1, 'months'),
            moment().endOf('month').subtract(1, 'months'),
        ],
    });

    const [local] = useState({
        format: 'DD-MM-YYYY HH:mm',
        sundayFirst: false,
    });

    function onDateChange (startDate, endDate) {
        setStart(startDate);
        setEnd(endDate);
        const dateStr = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`;
        setDateDisplay(dateStr);
        if (typeof onDateUpdate === 'function') {
            onDateUpdate({
                start: startDate.toDate(),
                end: endDate.toDate(),
                dateDisplay: dateStr,
            });
        }
    }

    return {
        start,
        end,
        onDateChange,
        ranges,
        local,
        dateDisplay,
        maxDate,
    };
}

export default useDatePicker;
