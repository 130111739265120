import { useEffect, useState } from 'react';

function useDebounceTextUpdates (props) {
    const {
        textSource,
        onStateUpdate,
        offset = 400,
    } = props;

    const [value, setValue] = useState(textSource);

    useEffect(() => {
        setValue(textSource);
    }, [textSource]);

    function onTextChange (v) {
        setValue(v);
        clearTimeout(window.debounceTextUpdateTO);
        window.debounceTextUpdateTO = setTimeout(() => {
            onStateUpdate(v);
        }, offset);
    }

    return {
        value,
        onTextChange,
    };
}

export default useDebounceTextUpdates;
