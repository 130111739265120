import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  TablePagination,
} from '@material-ui/core';
// import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import TableControls from '../../../commons/ecal/tableControls';
import DraftLiveBar from '../../../commons/ecal/draftLiveBar';
import Dialog from '../../../commons/dialog/Dialog';
import { Typography } from '../../../components/Wrappers';
import Widget from '../../../components/Widget';

import { useStyles } from './styles';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

import useModuleList from './useModuleList';
import useButtonCodeDialog from '../buttonsList/useButtonCodeDialog';
function EventDisplaysList (props) {
  const classes = useStyles();

  const {
    progress,
    listData,
    deleteOpen,
    setDeleteOpen,
    setDeleteTarget,
    pageInfo,
    updatePageInfo,

    selectedRows,
    setSelectedRows,
    visibleColumns,
    saveEventDisplayData,
    makeDraft,
    makeLive,
    deleteSelection,
    addEvent,
    deleteSingle,
    renderEventPreviewDialog,
    showEventPreview,
  } = useModuleList({
    columns,
  });

  const {
    renderButtonCode,
    showButtonCode,
    showQRCode,
    renderQRCode,
  } = useButtonCodeDialog({
    title: 'Publish Your Display',
    options: {
      showRoles: false,
      type: 'eventDisplay',
      showMode: true,
    },
  });

  function renderTableBar () {
    if (selectedRows && selectedRows.length > 0) {
      return (
        <DraftLiveBar
          infoText={ selectedRows.length + ' row(s) selected'}
          onLive={() => {
           makeLive();
          }}

          onDraft={() => {
            makeDraft();
          }}

          onDelete={() => {
            deleteSelection();
          }}
        />
      );
    }
    return (
      <TableControls
        hasSearch={false}
        title={'Event Displays'}
        excluded={['actions']}
      />
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Widget
          className={classes.widgetContainer}
          buttonText={'Add'}
          title="Manage Event Displays"
          handleAdd={() => {
            addEvent();
          }}
          intercomTitleTarget={'manage-event-display'}
          intercomAddTarget='add-event-display'
          inheritHeight disableWidgetMenu>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'flex-start'}
          >
            <Typography variant='body2'>
            Your event displays allow users to browse your events, select schedules and sync your events to their calendar.
            </Typography>
          </Box>
        </Widget>
        <Grid item
          xs={12}
          className={classes.tableContainer}
        >
          { renderTableBar() }
          <TableContainer
            hasCheck={true}
            classes={classes}
            tableData={listData}
            columns={visibleColumns}
            customCells={customCells}
            tableTitle='Manage Displays'
            moduleType='event displays'
            removeEmptyRows

            showProgress={progress.buttons}
            options={options}
            isInsideWidget={false}
            rowsPerPage={pageInfo.limit}

            onEdit={(data) => {
              saveEventDisplayData(data);
            }}

            onViewCode={(data) => {
              showButtonCode(data);
            }}
            onViewEventPreview={(data, mode) => {
              showEventPreview(data, mode);
            }}

            onShowQRCode={(data) => {
              showQRCode(data);
            }}

            onDelete={(data) => {
              console.log('Data: ', data);
              setDeleteTarget(data);
              setDeleteOpen(true);
            }}

            onSelectRow={(selection) => {
              console.log('selection: ', selection);
              setSelectedRows(selection);
            }}
          />
          <Grid className={classes.tablePagination}>
            <TablePagination

                rowsPerPageOptions={[
                  5,
                  10,
                  15,
                  20,
                  // {
                  //   value: -1,
                  //   label: 'All',
                  // },
                ]}

                page={pageInfo.page}
                rowsPerPage={pageInfo.limit}
                count={pageInfo.count}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count} `;
                }}

                onChangePage={(_, page) => {
                    updatePageInfo({ page });
                }}

                onChangeRowsPerPage={(e) => {
                  updatePageInfo({
                    page: 0,
                    limit: e.target.value,
                  });
                }}
              />
          </Grid>
        </Grid>

      </Grid>

      { renderButtonCode() }
      { renderEventPreviewDialog() }
      { renderQRCode() }

      <Dialog
        openDialog={deleteOpen}
        buttonSecondary={'Cancel'}
        handleAgree={() => {
          setDeleteOpen(false);
          deleteSingle();
        }}

        closeModal={() => {
          setDeleteOpen(false);
        }}
        title={'Delete Display'}
        dialogContextText={'Are you sure you want to delete this display?'}
      />
    </React.Fragment>
  );
}

EventDisplaysList.propTypes = {
  renderEventPreviewDialog: PropTypes.func,
  showEventPreview: PropTypes.func,
};

EventDisplaysList.default = {
  renderEventPreviewDialog: () => {},
  showEventPreview: () => {},
};

export default observer(EventDisplaysList);
