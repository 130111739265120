import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    /* Stroke */
    st0: (props) => {
        const {
            backgroundColor,
        } = props;

        return {
            fill: backgroundColor,
            filter: 'brightness(0.85)',
        };
    },

    /* Background */
    st1: (props) => {
        const {
            backgroundColor,
        } = props;

        return {
            fill: backgroundColor,
        };
    },

    /* font color */
    st2: (props) => {
        const {
            fontColor,
        } = props;

        return {
            fill: fontColor,
        };
    },

    st3: {
        fontFamily: 'Roboto, sans-serif',
        // fontFamily: 'Open Sans, sans-serif',
    },

    st4: {
        fontSize: '15px',
        // fontWeight: '600',
        // fontStyle: 'normal',
    },

    st5: {
        clipPath: 'url(#SVGID_3_)',
    },

}));
