import React, { useState } from 'react';
import {
    Grid,
    Switch,
    Typography,
} from '@material-ui/core';

import Select from '../../commons/select/Select';

import {
    BillingCoupons,
    HandleNames,
} from '../../utils/constants';

function getQuantityData () {
    const data = [];
    const max = 10;
    for (let i = 0; i <= max; i++) {
        data.push({
            label: i,
            value: i,
        });
    }
    return data;
}

function OnOffAddOn (props) {
    const {
        data,
        classes,
        updateAddOnsValue,
        addOnsValues,
        billingInputProps,
        addOnsDisabled,
    } = props;

    const {
        currencySymbol,
        coupon,
    } = billingInputProps;

    function isDisabled () {
        if (addOnsDisabled[data.id] && addOnsDisabled[data.id] === true) {
            return true;
        }
        return false;
    }

    function getValue () {
        if (data.type === 'quantity' &&
            (data.handle.indexOf('binary') !== -1 ||
            data.handle.indexOf('toggle') !== -1)) {
            return addOnsValues[data.id] > 0;
        }
        return addOnsValues[data.id];
    }

    function newValue () {
        if (data.type === 'onOff') {
            return !addOnsValues[data.id];
        }
        if (addOnsValues[data.id] > 0) {
            return 0;
        }
        return 1;
    }

    function shouldRenderFreeSetup () {
        if (coupon === BillingCoupons.SETMEUP23) {
            // data.handle.indexOf(HandleNames.PROJECT_SETUP) === -1
            if (data.handle.indexOf(HandleNames.SETUP) !== -1 &&
                data.handle.indexOf(HandleNames.CUSTOM) === -1 &&
                data.handle.indexOf(HandleNames.SSO_ADMIN_SETUP) === -1
                ) {
                    return true;
            }
        }
        return false;
    }

    function renderPrice () {
        if (shouldRenderFreeSetup()) {
            return (
                <React.Fragment>
                    <span className={classes.addOnsFieldPriceValue}><strike>{currencySymbol}{data.price}</strike></span>
                    <strong>FREE SET-UP</strong>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <span className={classes.addOnsFieldPriceValue}>{currencySymbol}{data.price}</span>
                when enabled
            </React.Fragment>
        );
    }

    return (
        <Grid
            className={classes.addOnField}>
            <Grid
                className={classes.addOnFieldInfo}
                >
                    <Grid className={classes.addOnName}>
                        {data.name}
                        <span className={classes.addOnsFieldPrice}>
                                { renderPrice() }
                        </span>
                    </Grid>
                    <Grid className={classes.addOnDesc}>
                        {data.description}
                    </Grid>
            </Grid>

            <Grid
                 className={classes.addOnFieldControl}
                 >
                     <Switch
                        checked={getValue()}
                        disabled={isDisabled()}
                        onClick={(e) => {
                            // e.stopPropagation();
                            // e.preventDefault();
                        }}

                        onChange={(e) => {
                            updateAddOnsValue(data.id, newValue());
                        }}
                        color="primary"
                        name="switch"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
            </Grid>

        </Grid>
    );
}

function QuantityAddOn (props) {
    const {
        data,
        classes,
        updateAddOnsValue,
        addOnsValues,
        billingInputProps,
    } = props;
    const {
        currencySymbol,
    } = billingInputProps;

    const [quantity] = useState(getQuantityData());
    return (
        <Grid
            className={classes.addOnField}>
            <Grid
                className={classes.addOnFieldInfo}
                >
                    <Typography variant="body">
                        {data.name}
                        <span className={classes.addOnsFieldPrice}>
                                <span className={classes.addOnsFieldPriceValue}>{currencySymbol}{data.price}</span>
                                each
                        </span>
                    </Typography>
            </Grid>

            <Grid
                className={classes.addOnFieldControl}
                >
                    <Select
                        marginBottom={'0px'}
                        id='age'
                        name='age'
                        value={addOnsValues[data.id]}
                        labelHeading= ''
                        placeHolder='Quantity'

                        handleChange={(e, selectedData) => {
                            updateAddOnsValue(data.id, selectedData.props.value);
                        }}

                        dataForDropdown={quantity}
                    />
            </Grid>
    </Grid>
    );
}

export const addOnMap = {
    onOff: OnOffAddOn,
    quantity: QuantityAddOn,
};

function getComponent (type) {
    if (type) {
        if (addOnMap[type]) {
            return addOnMap[type];
        }
    }
    return OnOffAddOn;
}

export function AddOn (props) {
    const Component = getComponent(props.type);
    return (
        <Component { ...props} />
    );
}

export default AddOn;
