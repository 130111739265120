import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  body: (props) => {
    const {
      headerBackgroundColor,
    } = props;

    return {
      backgroundColor: headerBackgroundColor,
      color: theme.palette.primary.contrastText,
      width: '100vw',
      textAlign: 'center',
      padding: '20px 0',
      position: 'relative',
      zIndex: 1201,
    };
  },
}));
