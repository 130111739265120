import React from 'react';
import {
    Grid,
} from '@material-ui/core';

// import { useStyles } from './styles';

import {
    Typography,
    Link,
} from '../../components/Wrappers/Wrappers';

function FeatureNotAvailable (props) {
    const {
        whiteLabelSettings,
    } = props;

    function handleUpgradeClick () {
        if (whiteLabelSettings.labelName === 'ecal') {
            window.Intercom('showMessages');
        } else {
            window.open(whiteLabelSettings.supportContact, '_blank');
        }
    }

    return (
        <Grid container>
            <Typography
                variant={'body2'}
                weight={'light'}
                style={{ marginBottom: 30 }}
            >
                This feature is not available on your current plan. Please contact  &nbsp;
                    <strong>
                        <u>
                            <Link
                                onClick={handleUpgradeClick}>
                                {whiteLabelSettings.supportLabel}
                            </Link>
                        </u>
                    </strong>
                    &nbsp; to upgrade or make changes to your plan.
            </Typography>
        </Grid>
    );
}

export default FeatureNotAvailable;
