import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    buttonsGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: 32,
      marginBottom: 32,
    },

    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '60px',
      paddingLeft: 0,

      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderTop: '2px solid rgba(185, 185, 185, 0.3)',
      backgroundColor: 'transparent',

      '&:hover': {
        color: '#536DFE',
        backgroundColor: '#F7F7F7',
      },
      cursor: 'pointer',
    },

    img: {
      float: 'left',
      height: 50,
      width: 50,
      marginRight: 20,
      borderRadius: '50%',
    },

    userInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      marginLeft: '10px',
    },

    itemSpace: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
    },

    userName: {
      // fontSize: '1.15rem',
      pointerEvents: 'none',
    },

    noPointer: {
      marginLeft: '16px',
      pointerEvents: 'none',
    },

    userLabel: {
      color: theme.palette.text.secondary,
    },

    dot: {
      '@media (max-width: 320px)': {
        display: 'none',
      },
    },

    filterButton: {
      display: 'flex',
      marginLeft: '16px',
    },

    tableContainer: {
        marginTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        boxShadow: theme.customShadows.widget,
    },

    tablePagination: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',

      '& .MuiTableCell-root': {
        borderBottom: '0px solid rgba(0, 0, 0, 0)',
      },
    },

    searchField: {
        padding: '8px 8px',
        borderRadius: '8px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px',
        borderStyle: 'solid',
    },

    emailCell: () => ({ ...truncatedCell('200px') }),
    nameCell: () => ({ ...truncatedCell('150px') }),
    companyCell: () => ({ ...truncatedCell('120px') }),
    pubIdCell: () => ({ ...truncatedCell('80px') }),

    tableTop: () => {
      return {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      };
    },

    controlFields: () => {
      return {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginTop: '16px',
        '& .MuiFormControl-marginNormal': {
          margin: 0,
        },

      };
    },

});

function truncatedCell (width) {
    return {
        width: width || '180px',
        fontSize: '14px',
        maxWidth: width || '180px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    };
}

export default styles;
export const useStyles = makeStyles(styles);
