import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    boxStyling: {
        backgroundColor: '#ffffff',
        padding: 24,
        '& .rct-node-icon': {
        color: '#0c0644!important',
        },
    },

    mainContainer: {
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: 4,
        padding: '24px',
        boxShadow: theme.customShadows.widget,
    },

    titleBase: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: '24px',
    },

    baseContent: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
    },

    controlsSpace: {
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
        maxWidth: '280px',
        justifyContent: 'flex-start',
    },

    widgetSpace: {
        display: 'flex',
        width: '65%',
        minWidth: '560px',
        minHeight: '700px',
       /*  backgroundColor: '#FBFBFB', */
        justifyContent: 'flex-start',
        marginLeft: '24px',
    },

    sectionRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '40px',
    },

    sectionTitle: {
        marginBottom: '8px',
    },

    sectionDescription: {
        marginBottom: '2px',
    },

    sectionContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       // backgroundColor: '#FBFBFB',
        minHeight: '40px',
        borderRadius: '6px',
       // padding: '16px',
    },

    colorPicker: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '280px',
        padding: '0px 16px 0px 16px',
        borderRadius: '4px',
        height: '48px',
        border: '1px solid #B2B2B2',
        marginTop: '24px',
    },

    colorPickerText: {
        marginRight: '16px',
    },

    input: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
        maxWidth: '280px',
        width: '100%',
        '& span': {
            maxWidth: '150px !important',
        },
    },

    logoSpace: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& .image-item': {
            marginBottom: '8px !important',
        },

        '& .MuiButtonBase-root': {
            marginRight: '0px !important',
        },

        '& .MuiBox-root': {
            justifyContent: 'center !important',
        },
    },

    logoTitle: {
        display: 'flex',
        justifyContent: 'left',
        width: '100%',
    },

    textInput: {
        width: '100%',
    },

    fullSize: {
        width: '100%',
        height: '100',
    },
    widgetPreviewMode: {
        display: 'block',
        zIndex: 101,
        position: 'relative',

        top: 0,
        left: 0,
        border: 0,
        background: 'transparent',

        width: '100% !important',
        height: '100% !important',
        overflow: 'auto',
    },

    uploadLabel: {
        marginBottom: '8px',
    },
}));
