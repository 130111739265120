import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import PropTypes from 'prop-types';
import {
    Paper,
    Grid,
    Popper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from '@material-ui/core';

import useStyles from './styles';

const DEFAULT_WIDTH = 280;

function PopMenu (props) {
    const {
        selected,
        dataProvider,
        open,
        anchorEl,
        onClose,
        onSelect,
        onDelete,
        width,
    } = props;

    const classes = useStyles();
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        if (open) {
           setCanClose(true);
        } else {
            setCanClose(false);
        }
    }, [open]);

    function isSelected (d) {
        const result = selected.filter((selectedValue) => (selectedValue === d.value));
        return result.length > 0;
    }

    function renderItems () {
        if (!dataProvider || dataProvider.length <= 0) {
            return (
                <Grid className={classes.noData}>
                    No Data
                </Grid>
            );
        }

        return dataProvider.map((data) => {
            const itemSelected = isSelected(data);
            return (
                <MenuItem
                    key={uuid()}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    selected={itemSelected}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!itemSelected) {
                            onSelect(data, [...selected, data.value]);
                        } else {
                            onDelete(data.value);
                        }
                    }}
                    >
                    {data.label}
                </MenuItem>
            );
        });
    }
    return (
        <ClickAwayListener
            onClickAway={() => {
                if (canClose) {
                    onClose();
                }
            }}
        >
        <Popper
            className={classes.popParent}
            disableAutoFocus={true}
            disableEnforceFocus={true}

            id="long-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}

            keepMounted
            open={open}
            onClose={onClose}
        >
            <Paper
                className={classes.popPaper}
                >
                <Grid
                    className={classes.menuRoot}
                    style={{
                    width: width || DEFAULT_WIDTH,
                    }}

                >

                    <MenuList
                        className={classes.menuList}
                    >
                        { renderItems() }
                    </MenuList>
                </Grid>
            </Paper>
        </Popper>
        </ClickAwayListener>
    );
}

PopMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    width: PropTypes.any,
};

PopMenu.defaultProps = {
    open: false,
    dataProvider: [],
    selected: [],
    anchorEl: null,
    onClose: () => {},
    onSelect: () => {},
    onDelete: () => {},
    onPopOut: () => {},
    width: '280px',
};

export default PopMenu;
