import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Grid,
} from '@material-ui/core';

import UserProfile from './user';
import Security from './password';
import OrganisationSettings from './account';
import Billing from './billing';
// import ButtonPanels from './ButtonPanels';
import Tabs from './tabs';
import { useUserStore } from '../../store/models';
import useUserPublisherInfo from '../../store/models/user/useUserPublisherInfo';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import { getTabByPath } from './profileUtils';
import { logInfo } from '../../utils';

function Profile (props) {
    const {
        location,
    } = props;

    const userStore = useUserStore();
    const [tab, setTab] = useState(getTabByPath(location.pathname));

    const {
        user,
        publisher,
        progress,
        setProgress,
        publisherLogoEditable,
        publisherLogoError,
        uploadPublisherLogo,
        deletePublisherLogo,
        aws,
        errors,
    } = useUserPublisherInfo();

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors,
        onCloseError: () => {},
    });

    useEffect(() => {
        const path = location.pathname;
        logInfo('Profie.useEFfect(pathname) -> publisher: ', publisher);
        if (path.indexOf('admin/settings') !== -1) {
            setTab(getTabByPath(path));
        }
    }, [location.pathname]);

    return (
        <React.Fragment>

            <Grid container spacing={6}>
                <Tabs
                    tabIndex={tab}
                    onTabChange={(newTab) => {
                        console.log('Tab ', newTab);
                        setTab(newTab);
                    }}
                />
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid>
                            <UserProfile
                                onDataChange={(data) => {
                                    console.log('Data: ', data);
                                }}
                                user={user}
                                inProgress={progress.user}
                                publisher={publisher}
                                onSave={(data) => {
                                    setProgress({
                                        ...progress,
                                        user: true,
                                    });
                                    console.log('Save Account Details: ', data);

                                    userStore.updateUserProfile({ user: data });
                                }}
                            />
                            <Security
                                onDataChange={(data) => {}}
                                isFederated={aws.isFederated}
                                onSavePassword={(data) => {
                                    console.log('password will be updated...', data);
                                    userStore.changePassword({ ...data });
                                }}
                            />
                        </Grid>
                    )}

                    { tab === 1 && (
                    <OrganisationSettings
                            onDataChange={(data) => {
                                console.log('Data: ', data);
                            }}
                            inProgress={progress.publisher}
                            publisher={publisher}

                            publisherLogo={publisherLogoEditable}
                            uploadPublisherLogo={uploadPublisherLogo}
                            deletePublisherLogo={deletePublisherLogo}
                            onSave={(data) => {
                                setProgress({
                                    ...progress,
                                    publisher: true,
                                });
                                console.log('Save Organization Settings: ', data);
                                userStore.updateProfile({ user: data, orgSettings: true });
                            }}
                            publisherLogoError={publisherLogoError}
                        />
                    )}

                    { tab === 2 && (
                        <Billing
                            onDataChange={(data) => {}}
                            user={user}
                            publisher={publisher}
                            onSave={(data) => {
                                console.log('Save Profile: ', data);
                                userStore.updateProfile({ user: data });
                            }}
                        />
                    )}

                    { tab === 3 && (
                        <Grid
                        >
                            Plan Settings
                        </Grid>
                    )}

                    {/* <ButtonPanels
                    /> */}
                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>
    );
}

Profile.propTypes = {

};

Profile.defaultProps = {

};

export default observer(Profile);
