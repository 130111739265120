import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    nameCell: {
        width: '120px',
    },

    messageCell: {
        width: '50%',
        fontSize: '14px',
    },

    messageText: {
        fontSize: '12px !important',
        padding: '4px',
    },

    actionCell: {
        width: '100px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    actionCellIcon: {
        padding: '4px',
        margin: '4px',
    },
});

export default styles;
export const useStyles = makeStyles(styles);
