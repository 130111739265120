import { v4 as uuid } from 'uuid';
import {
    apiGetOptins,
    apiAddOptins,
    apiEditOptins,
    apiDeleteOptins,
} from './api';

export async function getOptins () {
    this.env.errorStore.action = 'getOptins';
    this.observed.optinsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiGetOptins({
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data && res.data.data) {
        this.optins = res.data.data;
        this.observed.optins = uuid();
    } else {
        // handle error.
    }
    this.observed.optinsLoading = false;
}

export async function addOptins (optins) {
    this.env.errorStore.action = 'addOptin';
    const publisher = this.env.userStore.publisher;

   /* We need this because the select component treat empty value '' as negative  */
   if (optins.language === 'default') {
       optins.language = '';
   }
    const res = await apiAddOptins({
        body: { ...optins },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });
    console.log('ADD Optins Complete: ', res);

    this.env.history.push('/admin/marketing/optins');
}

export async function editOptins (optins) {
    this.env.errorStore.action = 'editOptin';
    const publisher = this.env.userStore.publisher;

    if (optins.language === 'default') {
        optins.language = '';
    }

    const res = await apiEditOptins({
        body: { ...optins },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });

    console.log('Edit Optins Complete: ', res);
    this.env.history.push('/admin/marketing/optins');
}

export async function deleteOptins (optins) {
    this.env.errorStore.action = 'deleteOptin';
    this.observed.optinsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiDeleteOptins({
        body: { ...optins },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });
    console.log('Edit Delete Complete: ', res);
    this.getOptins();
}
