import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getAnalyticsCalendarTypes,
    getAnalyticsSubscriptions,
    getAnalyticsClicks,
    getAnalyticsPopularLocations,
    getAnalyticsDevices,
    getTotalSubscribers,
    getLiveScheduleCount,
    getLiveEventCount,
    getOptinCount,
    getMonthlyActives,
    getEventImpressions,
    getReminderImpressions,
    getClicksCount,
    getMapSubscriptionsData,
} from './actions';

import {
    groupAndSortClicks,
} from './actions_serializers';

export default class DashboardStore {
    subscriptions = null;
    eventImpressions = null;
    reminderImpressions = null;
    analyticsClicks = null;
    monthlyActives = null;
    optinCount = null;
    totalSubscribers = null;
    liveScheduleCount = null;
    liveEventCount = null;
    clicks = null;
    popularLocations = null;
    calendarTypes = null;
    deviceTypes = null;
    subsMapData = null;

    observed = {
      subscriptions: [],
      subscriptionsDataLoading: false,
      popularLinksDataLoading: false,
      popularLocationsDataLoading: false,
      deviceTypesDataLoading: false,
      analyticsClicksDataLoading: false,
      eventImpresionsDataLoading: false,
      reminderImpresionsDataLoading: false,
    };

    constructor () {
        makeObservable(this, {
            observed: observable,
            subscriptions: observable,
            eventImpressions: observable,
            reminderImpressions: observable,
            analyticsClicks: observable,
            monthlyActives: observable,
            optinCount: observable,
            totalSubscribers: observable,
            liveScheduleCount: observable,
            liveEventCount: observable,
            subsMapData: observable,
            // clicks: observable,
            popularLocations: observable,
            calendarTypes: observable,
            deviceTypes: observable,
        });
    }

    getAnalyticsCalendarTypes = getAnalyticsCalendarTypes;
    getAnalyticsSubscriptions = getAnalyticsSubscriptions;
    getAnalyticsPopularLocations = getAnalyticsPopularLocations;
    getAnalyticsDevices = getAnalyticsDevices;
    getTotalSubscribers = getTotalSubscribers;
    getLiveScheduleCount = getLiveScheduleCount;
    getLiveEventCount = getLiveEventCount;
    getOptinCount = getOptinCount;
    getMonthlyActives = getMonthlyActives;
    getEventImpressions = getEventImpressions;
    getReminderImpressions = getReminderImpressions;
    getAnalyticsClicks = getAnalyticsClicks;
    getClicksCount = getClicksCount;
    getMapSubscriptionsData = getMapSubscriptionsData;

    groupAndSortClicks = groupAndSortClicks;
}

export function useDashboardStore () {
    const { dashboardStore } = useStores();
    return dashboardStore;
}
