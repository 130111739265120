import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useCategorySelectionButton from './useCategorySelectionButton';
import CategoryTreeStructure from '../../../../../components/TreeComponent/TreeComponent';
import useStyles from '../../../buttons.styles';

function Category (props) {
    const {
        updateScheduleData,
        scheduleData,
        dataSource,
        loadCategories,
        lastDataLoad,
        setLastDataLoad,
    } = props;
    const classes = useStyles();
    const {
        nodesFiltered,
        checked,
        expanded,
        // setChecked,
        loadingTree,
        setExpanded,
        updateCategorySelection,
        onFilterChange,
        filterText,
    } = useCategorySelectionButton({
        updateScheduleData,
        scheduleData,
        dataSource,
    });
    return (
        <span className={classes.treeContainer}>
            <CategoryTreeStructure
                showButton={false}
                onLoadCategories={loadCategories}
                lastDataLoad={lastDataLoad}
                setLastDataLoad={setLastDataLoad}
                filterText={filterText}
                // nodes={nodes}
                checked={checked}
                expanded={expanded}
                nodesFiltered={nodesFiltered}
                loadingIndicator={loadingTree}
                onFilterChange={onFilterChange}
                onCheck={(val) => {
                    // schStore.treeCheck(val);
                    // setChecked(val);
                    updateCategorySelection(val);
                }}
                onExpand={(val, targetNode) => {
                    // schStore.treeExpand(val, targetNode);
                    setExpanded(val);
                }}

                onNodeClick={(node) => {
                    /*
                    console.log('on Node Click: ', node);
                    schStore.getCategoriesByFilter({ parent: node.value });
                    */
                }}
            />
        </span>
    );
};

Category.propTypes = {
    dataSource: PropTypes.func,
    onDataChange: PropTypes.fun,
    updateSavable: PropTypes.fun,
};

Category.defaultProps = {
    dataSource: () => {},
    onDataChange: () => {},
    updateSavable: () => {},
};

export default observer(Category);
