import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import {
    useSchedulesStore,
    // useUserStore,
    // useErrorStore,
} from '../../../../../store/models/index';

function useCategorySelectionButton (props) {
  const {
      // dataSource,
      scheduleData,
      updateScheduleData,
  } = props;
    const schStore = useSchedulesStore();
    const [checked, setChecked] = useState(scheduleData.categories);
    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodesFiltered, setNodesFiltered] = useState([]);
    const [loadingTree, setLoadingTree] = useState(true);
    const [categoryInfo, setCategoryInfo] = useState([]);

    useEffect(() => {
      if (schStore.categoryData && schStore.categoryData.nodes) {
        setNodesFiltered(cloneDeep(schStore.categoryNodes));
        setNodes(cloneDeep(schStore.categoryNodes));
      }
    }, [schStore.categoryData.nodes]);

    useEffect(() => {
      setLoadingTree(schStore.loadingTree);
    }, [schStore.loadingTree]);

    useEffect(() => {
      setChecked(scheduleData.categories);
    }, [scheduleData.categories]);

    useEffect(() => {
      debounceGetCategoryInfo();
    }, [checked]);

    useEffect(() => {
      updateScheduleData({
        ...scheduleData,
        categoryInfo,
        categories: checked,
      });
    }, [categoryInfo]);

    function debounceGetCategoryInfo () {
      const catIds = checked;
      clearTimeout(window.getCategoryInfoTO);
      function getCatInfo () {
        const catInfo = schStore.getCategoryInfo(catIds);
        setCategoryInfo(catInfo);
      }
      window.getCategoryInfoTO = setTimeout(getCatInfo, 400);
    }

    function updateCategorySelection (data) {
      const categories = [
          ...data,
      ];
      console.log('updateCategorySelection() Data: ', categories);
      setChecked(categories);
    }

    function onFilterChange (e) {
      schStore.filterText = e.target.value;
      filterTree(e.target.value);
  };

    function filterTree (filterText) {
        function getAllValuesFromNodes (nodes) {
        const values = [];
        if (nodes) {
            for (const n of nodes) {
                    values.push(n.value);
                    if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                    }
                }
            }
            return values;
        };
        function filterNodes (filtered, node) {
          let children;
          if (node.children) {
            children = node.children.reduce(filterNodes, []);
          }
          if (
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
              -1 ||
            (children && children.length)
          ) {
            let expandedNodes = [];
            expandedNodes = [node.value, ...getAllValuesFromNodes(children)];
            setExpanded(expandedNodes);
            if (node.children) {
              filtered.push({ ...node, children });
            } else {
              filtered.push(node);
            }
          }
          return filtered;
        }
        if (filterText === '') {
            setNodesFiltered(cloneDeep(nodes));
        } else {
        const filterNodesOnSearch = () => (nodes.reduce(filterNodes, []));
        const nodesAfterFilter = filterNodesOnSearch();
        setNodesFiltered(cloneDeep(nodesAfterFilter));
        }
      }

    return {
        checked,
        loadingTree,
        setChecked,
        nodes,
        nodesFiltered,
        setNodes,
        expanded,
        setExpanded,
        updateCategorySelection,
        onFilterChange,
        filterText: schStore.filterText,
    };
}

export default useCategorySelectionButton;
