import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    select: {
        '& fieldset': {
             borderColor: 'rgba(0, 0, 0, 0.23)',
        },
    },

    selectError: {
        '& fieldset': {
            borderColor: '#f44336',
       },
    },

    helperError: {
        color: '#f44336',
    },
});
const useStyles = makeStyles(styles);
function SelectComp (props) {
    const {
        error,
        multiple,
        marginBottom,
        disabled,
        defaultValueValue = '',
        hasOutlinedLabel,
        inputProps,
    } = props;
    const classes = useStyles();

    const [touched, setTouched] = useState(false);
    function shouldShowOutlinedLabel () {
        if (!hasOutlinedLabel) {
            return false;
        }

        if (props.value === '') {
            return true;
        }
        if (props.value || props.value === defaultValueValue) {
            return touched;
        }
        return true;
    }

    const [showOutlinedLabel, setShowOutlinedLabel] = useState(shouldShowOutlinedLabel());

    useEffect(() => {
        const shouldShow = shouldShowOutlinedLabel();
        setShowOutlinedLabel(shouldShow);
    }, [props.value, hasOutlinedLabel]);

    /*
     * Note:
     * It's not a good practice to hard code styles on common components especially margin.
     * margin should be taken care on the actual usage of component not from inside.
     * TODO temporary used marginBottom as props
     * Optimum solution is to use a passable styles or className with defaults...
     */
    function renderHelperText () {
        if (props.helperText) {
            return props.helperText;
        }

        if (props.label) {
            return `Choose your ${props.label}`;
        }

        return '';
    }
    return (
        <FormControl
        variant="outlined"
        style={{
            width: '100%',
            marginBottom: marginBottom,
        }}
        >
        {showOutlinedLabel && (
            <InputLabel
                id="demo-simple-select-outlined-label"
                color={error ? 'secondary' : 'primary'}
            >
                {props.value ? props.labelHeading : props.placeHolder }
            </InputLabel>
        )}

        <Select
            inputProps={inputProps}
            labelId={`select-${props.id}`}
            id={`select-${props.id}`}
            value={props.value}
            defaultValue={props.value}
            onChange= {(e, selectedValue) => {
                setTouched(true);
                setShowOutlinedLabel(true);
                props.handleChange(e, selectedValue);
            }}

            onClick={(e) => {

            }}
            label={
                showOutlinedLabel && props.labelHeading ? props.labelHeading : undefined
            }
            name={props.name}
            className={error ? classes.selectError : classes.select}
            displayEmpty
            disabled={disabled}
            /*
            renderValue={(selected) => {
                if (!selected || selected.length === 0) {
                  return <em>{props.placeHolder}</em>;
                }
                return selected;
              }
            }
            */
            multiple={multiple}
        >
            {
                props.dataForDropdown && props.dataForDropdown.map((data, index) =>
                    <MenuItem
                        code={data.code ? data.code : ''}
                        key={index} value={data.value}
                    >{data.label}</MenuItem>
            )}
        </Select>
        <FormHelperText
            id={'demo-simple-select-outlined'}
            className={error ? classes.helperError : '' }
        >
            { renderHelperText() }
        </FormHelperText>
    </FormControl>
    );
}
SelectComp.propTypes = {
    /**
     * Is this the principal call to action on the page?
     */
    dataForDropdown: PropTypes.any,
    value: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    labelHeading: PropTypes.string,
    id: PropTypes.string,
    handleChange: PropTypes.func,
    placeHolder: PropTypes.string,
    multiple: PropTypes.bool,
    marginBottom: PropTypes.number,
    hasOutlinedLabel: PropTypes.bool,
    inputProps: PropTypes.any,
};

SelectComp.defaultProps = {
    dataForDropdown: '',
    value: '',
    id: '',
    name: '',
    labelHeading: '',
    label: '',
    handleChange: undefined,
    placeHolder: 'Please select',
    multiple: false,
    marginBottom: 32,
    disabled: false,
    hasOutlinedLabel: true,
    inputProps: {},
};

export default SelectComp;
