import { useState } from 'react';

const SignupProgressTexts = {
   CREATE_ACCOUNT: 'creating your account…',
   MAKING_MAGIC: 'making magic…',
   ALMOST_DONE: 'almost done…',
};

function useSignupProgress () {
    const [signupProgressText, setSignupProgressText] = useState(SignupProgressTexts.CREATE_ACCOUNT);

    function updateText () {
        if (signupProgressText === SignupProgressTexts.CREATE_ACCOUNT) {
            setSignupProgressText(SignupProgressTexts.MAKING_MAGIC);
            window.progressTextTO = setTimeout(updateText, 3000);
        } else if (signupProgressText === SignupProgressTexts.MAKING_MAGIC) {
            setSignupProgressText(SignupProgressTexts.ALMOST_DONE);
        }
    }

    function progressStarted () {
        setSignupProgressText(SignupProgressTexts.CREATE_ACCOUNT);
        window.progressTextTO = setTimeout(updateText, 3000);
    }

    function progressComplete () {
        clearTimeout(window.progressTextTO);
        setSignupProgressText(SignupProgressTexts.CREATE_ACCOUNT);
    }

    return {
        signupProgressText,
        progressStarted,
        progressComplete,
    };
}

export default useSignupProgress;
