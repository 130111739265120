import {
  get,
  endpoints,
} from '../../api';

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/calendarType
 */
export function apiGetAnalyticsCalendarTypes (options) {
  return get(
      endpoints().analyticsCalendarTypes,
      {
        ...options,
        action: 'analyticsCalendarTypes',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/subscriptions/daily?from=2021-10-20&to=2021-11-18&tz=Asia/Manila
 */
export function apiGetAnalyticsSubscriptions (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().analyticsSubscriptions + params,
      {
        ...options,
        action: 'analyticsSubscriptions',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/clicks/daily?from=2021-10-20&to=2021-11-18&tz=Asia/Manila
 */
export function apiGetAnalyticsClicks (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().analyticsClicks + params,
      {
        ...options,
        action: 'analyticsClicks',
      }
  );
}

export function apiGetMapSubscriptionsData (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().subscriptionsMap + params,
      {
        ...options,
        action: 'subscriptionsMap',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/locations/leaderboard?from=2021-10-21&to=2021-11-19&tz=Asia/Manila
 */
export function apiGetAnalyticsPopularLocations (body, options) {
  const params = 'from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      `${endpoints().analyticsPopularLocations}?${params}`,
      {
        ...options,
        action: 'analyticsPopularLocations',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/deviceType
 */
export function apiGetAnalyticsDevices (options) {
  return get(
      endpoints().analyticsDeviceTypes,
      {
        ...options,
        action: 'analyticsDeviceTypes',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/subscriber
 */
export function apiGetTotalSubscribers (options) {
  return get(
      endpoints().totalSubscribers,
      {
        ...options,
        action: 'totalSubscribers',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/schedule
 */
export function apiGetLiveScheduleCount (options) {
  return get(
      endpoints().liveScheduleCount,
      {
        ...options,
        action: 'liveScheduleCount',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/event
 */
export function apiGetLiveEventCount (options) {
  return get(
      endpoints().liveEventCount,
      {
        ...options,
        action: 'liveEventCount',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/count/event
 */
export function apiGetOptinCount (options) {
  return get(
      endpoints().optinCount,
      {
        ...options,
        action: 'optinCount',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/subscribers/active?from=2021-08-26&to=2021-11-22&tz=Asia/Manila
 */
export function apiGetMonthlyActives (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().monthlyActives + params,
      {
        ...options,
        action: 'monthlyActives',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/impressions/event?from=2021-10-24&to=2021-11-22&tz=Asia/Manila
 */
export function apiGetEventImpressions (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().eventImpressions + params,
      {
        ...options,
        action: 'eventImpressions',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/impressions/reminder?from=2021-10-24&to=2021-11-22&tz=Asia/Manila
 */
export function apiGetReminderImpressions (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().reminderImpressions + params,
      {
        ...options,
        action: 'reminderImpressions',
      }
  );
}

/*
 * URL:
 * https://test-svc.ecal.com/v4/analytics/clicks/link?from=2021-10-24&to=2021-11-22&tz=Asia/Manila
 */
export function apiGetClicksCount (body, options) {
  const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
  return get(
      endpoints().clicks + params,
      {
        ...options,
        action: 'clicks',
      }
  );
}
