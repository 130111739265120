import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
// import Alert from '@material-ui/lab/Alert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Checkbox from '../../../../commons/Checkbox';
import SelectSearch from '../../../../commons/ecal/selectsearch';
import { Typography } from '../../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import CategoryTreeStructure from '../../../../components/TreeComponent/TreeComponent';
import CategorySelect from './Category';
import useStyles from '../../buttons.styles';
import useScheduleData from './useScheduleData';

const Schedules = (props) => {
    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
        loadCategories,
        lastDataLoad,
        setLastDataLoad,
    } = props;
    const classes = useStyles();

    const {
        // input,

        scheduleData,
        updateScheduleData,

        schedules,
        updateScheduleSelection,
        searchSchedule,
        showProgress,

    } = useScheduleData({
        onDataChange,
        dataSource,
        updateSavable,
        isDisplaySetting: true,
    });

    const handleChange = (event) => {
      updateScheduleData({
        ...scheduleData,
        scheduleOption: parseInt(event.target.value),
      });
        // setSelectedValue(parseInt(event.target.value));
    };
    return (
        <Grid item justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={800}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    Which schedules should be displayed?
                </Typography>
                {/* <Typography
                    // variant={'body1'}
                    // weight={'medium'}
                    block
                    style={{ marginBottom: 30 }}
                >
                    Schedules are what your users subscribe to in order to receive event information and updates, direct to their personal calendar.<br/>
                    Here you can choose to display all your schedules, or just specific schedules in this widget.
                </Typography> */}
                <FormControlLabel
                    style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 1}
                      onChange={handleChange}
                      value={1}
                      color='default'
                      name='radio-button-all-schedules'
                      inputProps={{ 'aria-label': 'all schedules' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Show all my Schedules'}
                />
                <FormControlLabel
                    style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 2}
                      onChange={handleChange}
                      value={2}
                      color='default'
                      name='radio-button-schedules-by-cat'
                      inputProps={{ 'aria-label': 'select schedules by category' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Show Schedules by Category'}
                />
                <Box sx={{
                        margin: '0 30px 30px 30px',
                        display: `${scheduleData.scheduleOption === 2 ? 'block' : 'none'}`,
                    }} >
                    <CategorySelect
                      lastDataLoad={lastDataLoad}
                      setLastDataLoad={setLastDataLoad}
                      loadCategories={loadCategories}
                      dataSource={ dataSource }
                      scheduleData={scheduleData}
                      updateScheduleData={updateScheduleData}
                    />
                </Box>
                <FormControlLabel
                    style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 3}
                      onChange={handleChange}
                      value={3}
                      color='default'
                      name='radio-button-selected-schedules'
                      inputProps={{ 'aria-label': 'select schedules' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Only show these Schedules'}
                />
                { scheduleData.scheduleOption === 3
                ? <Box
                    sx={{
                        margin: 30,
                    }}
                >
                    <SelectSearch
                        label='Schedules'
                        placeHolder='Search Schedules beginning with'
                        helperText='Search and select schedule'
                        dataProvider={schedules}
                        selected={scheduleData.schedules}
                        onSelect={(data) => {
                            console.log('on select: ', data);
                            updateScheduleSelection(data);
                        }}

                        isRemoteSearch={true}
                        onRemoteSearch={(search) => {
                            searchSchedule(search);
                        }}
                        showProgress={showProgress}
                    />
                </Box>
                : null}
                <FormControlLabel
                  style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 4}
                      onChange={handleChange}
                      value={4}
                      color='default'
                      name='radio-button-no-schedules'
                      inputProps={{ 'aria-label': 'no schedules selected' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Don’t show any Schedules'}
                />
                { scheduleData.scheduleOption === 4
                ? <Typography variant='body2' style={{ margin: '0 30px 30px 30px' }}>
                    User will be subscribed to your ECAL service, but no Schedules will be synced by default. Use this option if you’re using the ECAL API to push events into a User’s calendar.
                </Typography>
                : null }
                <Typography block style={{ marginBottom: '-10px' }}>
                {/* <b>Auto-subscribe to schedules</b><br/> */}
                    With Auto-subscribe enabled, users are automatically subscribed to all schedules that you determine.<br/>
                    &nbsp;<br/>
                </Typography>
                <Checkbox
                    labelSmallFont={false}
                    checked={scheduleData.isAutoSubscribe}
                    onChange={() => {
                          updateScheduleData({
                              ...scheduleData,
                              isAutoSubscribe: !scheduleData.isAutoSubscribe,
                          });
                    }}
                    variant='primary'
                    label={'Enable Auto-subscribe'}
                />
                <Typography variant='body2' style={{ marginTop: 30, marginBottom: 30 }}>
                    {/* <Alert severity="info">This is an info alert — check it out!</Alert> */}
                    <b>Note:</b> Only use this option if you&apos;re using the ECAL API to deliver Private Events into users calendars.
                </Typography>
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                     <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

Schedules.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    hasError: PropTypes.bool,
    hasInputError: PropTypes.bool,
    errors: PropTypes.object,
    loadCategories: PropTypes.func,
    lastDataLoad: PropTypes.string,
    setLastDataLoad: PropTypes.func,
};

Schedules.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    hasError: false,
    hasInputError: false,
    errors: {},
    loadCategories: () => {},
    setLastDataLoad: () => {},
    lastDataLoad: '',
};

export default observer(Schedules);
