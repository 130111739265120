import { v4 as uuid } from 'uuid';

const messages = [
    'Loading',
    'Please wait',
    'Almost done',
];

const targetMessageMap = {
    appProgress: 'progressMessage',
    category: 'loadCategoryMessage',
};

function getDots (count) {
    if (count <= 1) {
        return '.';
    } else if (count === 2) {
        return '..';
    }
    return '...';
}

export function updateLoadCounterProgress (target, changeIntervalSeconds) {
    const max = messages.length * changeIntervalSeconds;
    if (this.loadCounter >= max) {
        // repeate when max interval is reached...
        this.loadCounter = 0;
    }

    const dotsCount = this.loadCounter % changeIntervalSeconds;
    const dots = getDots(dotsCount);

    const msgIndex = Math.floor(this.loadCounter / changeIntervalSeconds);

    let msg = messages[msgIndex];
    if (!msg) {
        msg = messages[0];
    }

    this.updateViewProgress(`${msg}${dots}`, target);
}

export function updateViewProgress (msg, target) {
    // console.log('updateViewProgress() Messages - ', msg);
    let propKey = targetMessageMap[target];
    if (!propKey) {
        propKey = 'progressMessage';
    }
    this[propKey] = msg;
    // console.log('updateViewProgress() propkey - ', propKey);
    this.observed[propKey] = uuid();
}

export function startLoadCounter (options = {}) {
    const {
        changeIntervalSeconds = 5,
        target = 'appProgress',
    } = options;

    this.stopLoadCounter();
    const self = this;
    function updateLoadCounter () {
        self.loadCounter += 1;
        self.updateLoadCounterProgress(target, changeIntervalSeconds);
    }
    this.loadCounterTimeout = setInterval(updateLoadCounter, 1000);
}

export function stopLoadCounter () {
    this.loadCounter = 0;
    clearInterval(this.loadCounterTimeout);
}

const loaderActions = {
    stopLoadCounter,
    startLoadCounter,
    updateLoadCounterProgress,
    updateViewProgress,
};

export default loaderActions;
