import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import useStyles from './card.styles';

// components
import { Typography, Button, Badge } from '../../components/Wrappers';

function CardComp (props) {
  const {
    titleText = '',
    bodyDescription = '',
    buttonText,
    handleCreate,
    cardImage,
    buttonIntercomTarget = '',
  } = props;
  const classes = useStyles();
  return (
        <Card className={classes.cardMedia}>
          <CardActionArea onClick={handleCreate}>
          <CardMedia
                className={classes.media}
                image={cardImage}
                title={titleText}
              />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {titleText}
                {titleText === 'Event Display' && <Badge
                  className={classes.badgeIcon}
                  badgeContent={'New'}
                  color={'secondary'}
                />}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {bodyDescription}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions classes={{ root: classes.cardActions }}>
            {/* <Button variant="contained" size="small" color="primary">
              Share
            </Button> */}
            <Button
              data-intercom-target={buttonIntercomTarget}
              variant="contained"
              onClick={handleCreate}
              size="small" color="primary">
              {buttonText}
            </Button>
          </CardActions>
        </Card>
  );
}

export default CardComp;
