import { v4 as uuid } from 'uuid';
import {
    apiImportEvent,
    apiBulkCreateEvents,
} from './api';

export async function importEvents (props) {
    const publisher = this.env.userStore.publisher;
    this.env.errorStore.action = 'importEvents';
    const formData = new FormData();
    formData.append('timezone', props.timezone);
    formData.append('dateFormat', props.dateFormat);
    formData.append('ignoreFirstRow', props.ignoreFirstRow);
    formData.append('file', props.file);

    const res = await apiImportEvent({
        data: formData,
        token: publisher.token,
        traceId: this.env.traceId,
    });

     /* process the event back to data */

    if (res.data.errors) {
        return;
    }

    /* process the event back to data */
    console.log('');
    console.log('');
    console.log('Import Uploaded: ', res);

    this.importedEvents = res.data.data.rows;
    this.importedEventsWarnings = res.data.data.validationIssues.warnings;
    this.importedEventsErrors = res.data.data.validationIssues.errors;

    this.observed = {
        ...this.observed,
        importedEvents: uuid(),
    };
}

export async function saveImportedEvents (props) {
    this.env.errorStore.action = 'saveImportedEvents';
    const {
        scheduleName,
        scheduleId,
        isNewSchedule,
        timezone,
        genre,
        subGenre,
    } = props;

    const publisher = this.env.userStore.publisher;
    let schedId = scheduleId;

    if (isNewSchedule) {
        this.env.schedulesStore.categoryChecked = [];
        const res = await this.env.schedulesStore.addSchedule({ name: scheduleName, genre: genre, subGenre: subGenre }, false);
        console.log('Add Schedule Response: ', res);

        if (res.data.data.addSchedule) {
            const edges = res.data.data.addSchedule.edges;
            if (edges.length > 0) {
                schedId = edges[0].node.id;
            }
        } else {
            console.log('handle error here');
        }
    }

    const events = this.importedEvents.map((event) => {
        return {
            ...event,
            scheduleId: schedId,
            time: {
                ...event.time,
                timezone,
            },
        };
    });

    console.log('Saving IMported Events: ', events);
    this.env.errorStore.action = 'saveImportedEvents';
    const response = await apiBulkCreateEvents({
        events,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('createEvents(): ', response);
    return true;
}
