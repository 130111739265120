import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  FileCopy as CopyIcon,
} from '@material-ui/icons';

import {
  Button,
  Link,
  Typography,
} from '../../../components/Wrappers';

import { useStyles } from './styles';
import {
  navigateBlank,
  socialInstruction,
  socMedInstruction,
  facebookInstruction,
} from './utils';

function ButtonCodeSocial (props) {
  const {
    socialMediaLink,
    facebookLink,
    instructionIframeSrc,
    instructionWithLink,
  } = props;

  const classes = useStyles();

  return (
    <Grid className={classNames([classes.buttonCodeContent, classes.topSpace, classes.minHeight400])}>
      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
        ])}>
        <Typography
          variant={'sub2'}
        >
          {instructionWithLink ? socMedInstruction : socialInstruction }
        </Typography>
      </Grid>

      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
          classes.buttonCodeSocial,
        ])}
        data-intercom-target="social-direct-link"
        >

        <Grid className={classes.iconSpace}>
          <Tooltip title="Copy link to clipboard.">
            <IconButton
              color={'primary'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigator.clipboard.writeText(socialMediaLink);
              }}
              className={classes.buttonCodeSocialIcon}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Button
          onClick={(e) => {
            e.preventDefault();
            navigateBlank(socialMediaLink);
          }}
          variant={'link'}
          color={'primary'}
        >
          <Link className={classes.qrCodeLink} > {socialMediaLink} </Link>
        </Button>
      </Grid>
      {facebookLink && (
        <Grid
          className={classNames([
            classes.dialogSection,
            classes.topSpace,
            classes.bottomSpace,
            classes.leftAligned,
          ])}>
          <Typography
            variant={'sub2'}
          >
            {facebookInstruction}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              className={classes.fbLink}
              href={facebookLink}
            >
              these instructions.
            </Link>
          </Typography>
        </Grid>
      )}
      {instructionIframeSrc && (
        <Grid className={classes.fullSize}>
          <iframe
            className={classes.widgetPreviewMode}
            frameBorder={0}
            src={instructionIframeSrc}
          />
        </Grid>
      )}
    </Grid>
  );
}

ButtonCodeSocial.propTypes = {
  socialMediaLink: PropTypes.string,
  widgetId: PropTypes.string,
};

ButtonCodeSocial.defaultProps = {
  socialMediaLink: '',
  widgetId: '',
};

export default ButtonCodeSocial;
