import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
} from '@material-ui/core';
// import Input from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';

import {
    Typography,
    // Button,
} from '../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';

// import Widget from '../../../components/Widget';
import { useStyles } from '../profile.styles';

function Account (props) {
    // const classes = useStyles();
    const {
        user,
        onDataChange,
        onSave,
        inProgress,
    } = props;

    const [data, setData] = useState(user);
    const classes = useStyles();

    useEffect(() => {
        setData(user);
    }, [user]);

    useEffect(() => {
        if (typeof onDataChange === 'function') {
            onDataChange(data);
        }
    }, [data]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                            className={classes.boxStyling}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Profile
                            </Typography>
                            <TextField
                                id="first-name"
                                label="First Name"
                                onChange={handleChange}
                                name="firstName"
                                value={data.firstName || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Please enter your first name"
                            />
                            <TextField
                                id="last-name"
                                label="Last Name"
                                onChange={handleChange}
                                name="lastName"
                                value={data.lastName || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Please enter your last name"
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                {/* <Button
                                    // onClick={handleBack}
                                    variant={'contained'}
                                    color={'secondary'}
                                >
                                    Cancel
                                </Button> */}
                                <ButtonProgress
                                    showProgress={inProgress}
                                    label="Save"
                                    onClick={(e) => {
                                        onSave(data);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

Account.propTypes = {
    user: PropTypes.object,
    onDataChange: PropTypes.func,
    inProgress: PropTypes.bool,
};

Account.defaultProps = {
    user: {
        firstName: '',
        email: '',
        role: 'user',
    },
    onDataChange: () => { },
    onSave: () => {},
    inProgress: false,
};

export default Account;
