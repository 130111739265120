import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    dialogContainer: {
      '& .MuiDialog-paper': {
        minWidth: '300px',
      },
    },

}));
