import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import App from './app/App';
import './index.css';

import { LayoutProvider } from './app/context/LayoutContext';
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext,
} from './app/context/ThemeContext';

import config, { configureAWSWithHostedUI } from './conf/config';

import store from './store';

import TagManager from 'react-gtm-module';

/* Google Tag Manager */
const tagManagerArgs = {
    gtmId: 'GTM-TT5XK43',
};

TagManager.initialize(tagManagerArgs);

configureAWSWithHostedUI();

/*
 * Override axios defaults...
 */
axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = 'application/json';

/* initialise sentry */
if (window.env !== 'dev') {
  Sentry.init({
    dsn: 'https://df6556ef067d40afbdbddcb4b39b5255@o357750.ingest.sentry.io/6141264',
    integrations: [
      new Integrations.BrowserTracing(),
      /* Disable console.logs: https://github.com/getsentry/sentry-react-native/issues/794#issuecomment-908189765 */
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider
    envStore={store.envStore}
    userStore={store.userStore}
    schedulesStore={store.schedulesStore}
    eventsStore={store.eventsStore}
    optinsStore={store.optinsStore}
    webhooksStore={store.webhooksStore}
    errorStore={store.errorStore}
    labelsStore={store.labelsStore}
    subscribersStore={store.subscribersStore}
    apikeyStore={store.apikeyStore}
    buttonsStore={store.buttonsStore}
    dashboardStore={store.dashboardStore}
    billingStore={store.billingStore}
    stylesStore={store.stylesStore}
    publishersStore={store.publishersStore}
  >
    <LayoutProvider>
          <ThemeChangeProvider>
            <ThemeStateContext.Consumer>
              {theme => (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </ThemeProvider>
              )}
            </ThemeStateContext.Consumer>
          </ThemeChangeProvider>
    </LayoutProvider>
  </Provider>,
  document.getElementById('root')
);
