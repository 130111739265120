import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid, Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
function AddOns (props) {
    const {
        data,
    } = props;
    const classes = useStyles();

    function renderValue () {
        if (data.type === 'onOff') {
            if (data.quantity === 1) {
                return 'ON';
            }
            return 'OFF';
        }
        return data.quantity;
    }

    return (
        <React.Fragment>
            <Grid className={classes.addOnsBox}>
                <Typography
                    variant="body1"
                    className={classes.addOnsName}
                >
                    {data.name}
                </Typography>

                <Typography
                    variant="body2"
                    className={classes.addOnsValue}
                >
                    { renderValue() }
                </Typography>

            </Grid>
        </React.Fragment>
    );
}

AddOns.propTypes = {
    data: PropTypes.object,
};

AddOns.defaultProps = {
    data: {},
};

export default AddOns;
