import React from 'react';
import { observer } from 'mobx-react';

import { useHistory } from 'react-router';

import {
  Grid,
  Box,
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Dialog from '../../../commons/dialog/Dialog';

import ProgressBar from '../../../commons/progress/Progress';
import { Button, Typography, Link } from '../../../components/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';

import { useStyles } from './styles';
import useModuleInput from './useModuleInput';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';

const InputForm = (props) => {
    const {
        location,
    } = props;

    const {
        isLoading,
        setIsLoading,
        progress,
        input,
        updateInput,
        generateKey,
        errors,
        hasInputError,
        messageOpen,
        setMessageOpen,
    } = useModuleInput({ location });

    const history = useHistory();
    const classes = useStyles();

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({ errors });

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    API Key
                                </Typography>
                                { progress
                                    ? <ProgressBar />
                                    : <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={'100%'}>

                                        <TextField
                                            inputProps={{
                                                className: classes.multilineInput,
                                            }}
                                            id="name"
                                            label="Name"

                                            onChange={(e) => {
                                                updateInput({
                                                    ...input,
                                                    name: e.target.value,
                                                });
                                            }}
                                            name="message"
                                            value={input.name}
                                            variant="outlined"
                                            style={{ marginBottom: 32 }}
                                            helperText={ errors.name || '*Enter Apikey Name'}
                                            error={errors.name}
                                        />

                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                        >
                                                <Button
                                                    onClick= { (e) => {
                                                        e.preventDefault();
                                                        history.push('/admin/developers/apikeys');
                                                    }}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                >
                                                    <Link
                                                        color='#fff'>
                                                    close
                                                    </Link>
                                                </Button>

                                                <ButtonProgress
                                                    disabled={hasInputError}
                                                    showProgress={isLoading}
                                                    label="Save"
                                                    onClick={(e) => {
                                                        setIsLoading(true);
                                                        generateKey();
                                                    }}
                                                />
                                        </Box>

                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

           { renderErrorDialog() }

            <Dialog
                openDialog={messageOpen}
                buttonSecondaryFlag={false}

                handleAgree={() => {
                    setMessageOpen(false);
                    history.push('/admin/developers/apikeys');
                }}

                closeModal={() => {
                    setMessageOpen(false);
                    history.push('/admin/developers/apikeys');
                }}

                title={'Warning API Key'}
                dialogContextText={'You already have 2 keys in use. You\'ll need to de-activate a key before you can generate a new one.'}
            />
        </React.Fragment>
    );
};

export default observer(InputForm);
