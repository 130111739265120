import React from 'react';
import classNames from 'classnames';
import {
    Box,
    Grid,
    Tooltip,
} from '@material-ui/core';

import { Typography, Chip } from '../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';

import {
    DeleteOutlined as DeleteIcon,
    CreateOutlined as CreateIcon,
    Code as CodeIcon,
} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

/* Custom Cells */
export function statusCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;
    return (
        <Grid>
            <Chip
                label={row.draft ? 'DRAFT' : 'LIVE'}
                className={classes.statusCell}
                style={{
                    backgroundColor: row.draft ? '#f50057' : '#75bd43',
                }}
            />
        </Grid>
    );
}

export function nameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.nameCell}
                variant={'body2'}
            >
                {row.name}
            </Typography>
    );
}

export function subscribersCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.subscriberCell}
                variant={'body2'}
            >
                {row.subscribers}
            </Typography>
    );
}

export function actionsCell ({ index, row, column, props }) {
    const {
        classes,
        onShowButtonCode,
        onShowQRCode,
        onEdit,
        onDelete,

    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
            <Tooltip title="Button Code">
                <IconButton
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowButtonCode(row);
                    }}
                    className={classes.actionCellIcon}
                    data-intercom-target='display-button-code'
                >
                    <CodeIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title="View Button QR Code">
                <IconButton
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowQRCode(row);
                    }}
                    className={classes.actionCellIcon}
                    data-intercom-target='display-qrcode'
                >
                    <FontAwesomeIcon
                        icon={faQrcode}
                        style={{ fontSize: '14px' }}
                        />
                </IconButton>
            </Tooltip>

            <Tooltip title="Edit Button">
                <IconButton
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onEdit(row);
                    }}
                    className={classes.actionCellIcon}
                    data-intercom-target='display-edit'
                >
                    <CreateIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Delete Button">
                <IconButton
                    data-intercom-target='display-delete'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(row);
                    }}
                    className={classes.actionCellIcon}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export function previewCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    function objectStyle () {
        if (row?.style?.width > 200) {
            return {
                width: row.style.width + 'px !important',
            };
        }
        return {
            width: '200px',
        };
    }

    return (
        <Grid className={classes.previewCell}>
            { row.draft
            ? <div style={objectStyle()}>
                <object className={classNames([classes.previewImage])}
                data={row.image}
                />
              </div>
            : <Tooltip title="Preview Pop-up">
                <button className='ecal-sync-widget-button' key={row.id} data-ecal-widget-id={row.id}>
                    {row.display?.text}
                </button>
              </Tooltip>
            }
        </Grid>
    );
}
