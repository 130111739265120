import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    st0: {
        fill: 'url(#SVGID_1_)',
    },

    st1: {
        fill: '#A324AD',
    },

    st2: {
        fill: '#FFFFFF',
    },

    st3: {
        fontFamily: 'Roboto, sans-serif',
        // fontFamily: 'Open Sans, sans-serif',
    },

    st4: {
        fontSize: '15px',
        // fontWeight: '600',
    },

    st5: {
        fill: '#100239',
    },

    st6: {
        clipPath: 'url(#SVGID_3_)',
    },

    st7: {
        fill: '#F28C00',
    },

    st8: {
        fill: '#EE7624',
    },

    st9: {
        fill: '#009BE0',
    },

    st10: {
        fill: '#01BFD7',
    },

    st11: {
        fill: '#F9BF01',
    },

    st12: {
        fill: '#76BD22',
    },

    st13: {
        fill: '#00A8E2',
    },

}));
