import axios from 'axios';

import { dispatchErrors } from '../models/error/utils';
// import { getReferer } from './factory';
import { verifyToken } from './tokenUtil';
const EndpointSources = {
    remote: () => ({
         /* to test Local APIS */
        /*
        authGetPublisherInfo: 'http://localhost:3003/v4/auth/publisher',
        searchPublishers: 'http://localhost:3021/v4/publishers/graphql',
        updateProfile: 'http://localhost:3003/v4/auth/secured/publisher',
        billing: 'http://localhost:3034/v4/billing/graphql',
        plansGQL: 'http://localhost:3022/v4/plans/graphql',
        publisherUser: 'http://localhost:3038/v4/publisher-user/graphql',
        uploadPublisherAssets: 'http://localhost:3021/v4/publishers/upload-assets',
        adminCRM: 'http://localhost:3035/v4/crm',
        */

        authGetPublisherInfo: getBaseURL() + '/v4/auth/publisher',
        searchPublishers: getBaseURL() + '/v4/publishers/graphql',
        updateProfile: getBaseURL() + '/v4/auth/secured/publisher',
        billing: getBaseURL() + '/v4/billing/graphql',
        plansGQL: getBaseURL() + '/v4/plans/graphql',
        publisherUser: getBaseURL() + '/v4/publisher-user/graphql',
        uploadPublisherAssets: getBaseURL() + '/v4/publishers/upload-assets',
        adminCRM: getBaseURL() + '/v4/crm',

        publisherGenerateSVG: getBaseURL() + '/v4/publishers/generate-svg',
        publisherLogo: getBaseURL() + '/v4/publishers/publisher-logo',
        getOptins: getBaseURL() + '/v4/optins',
        getSchedules: getBaseURL() + '/v4/schedules/graphql',
        getEvents: getBaseURL() + '/v4/event/graphql',
        eventImport: getBaseURL() + '/v4/event/import',
        getWebhooks: getBaseURL() + '/v4/webhook',
        updateWebhooks: getBaseURL() + '/v4/webhook',

        label: getBaseURL() + '/v4/label',
        subscribers: getBaseURL() + '/v4/subscribers',
        monthlySubscribers: getBaseURL() + '/v4/analytics/subscriptions/monthly',
        apikey: getBaseURL() + '/v4/apikey',
        buttons: getBaseURL() + '/v4/buttons/widget/graphql',
        subscribersByWidget: getBaseURL() + '/v4/analytics/count/subscribersByWidget',
        subscribersByEventDisplay: getBaseURL() + '/v4/analytics/count/subscribersByEventDisplay',
        analyticsCalendarTypes: getBaseURL() + '/v4/analytics/count/calendarType',
        analyticsSubscriptions: getBaseURL() + '/v4/analytics/subscriptions/daily',
        analyticsClicks: getBaseURL() + '/v4/analytics/clicks/daily',
        subscriptionsMap: getBaseURL() + '/v4/analytics/locations/map',
        analyticsPopularLocations: getBaseURL() + '/v4/analytics/locations/leaderboard',
        analyticsDeviceTypes: getBaseURL() + '/v4/analytics/count/deviceType',
        totalSubscribers: getBaseURL() + '/v4/analytics/count/subscriber',
        liveScheduleCount: getBaseURL() + '/v4/analytics/count/schedule',
        liveEventCount: getBaseURL() + '/v4/analytics/count/event',
        optinCount: getBaseURL() + '/v4/analytics/count/optin',
        monthlyActives: getBaseURL() + '/v4/analytics/subscribers/active',
        eventImpressions: getBaseURL() + '/v4/analytics/impressions/event',
        reminderImpressions: getBaseURL() + '/v4/analytics/impressions/reminder',
        clicks: getBaseURL() + '/v4/analytics/clicks/link',

    }),

    /*
     * LOCAL doesn't need base URL because every go service is on port
     * note don't point this to test. instead point the window.env to dev at src/store/models.index.js
     */
    local: () => ({
        authGetPublisherInfo: 'http://localhost:3003/v4/auth/publisher',
        searchPublishers: 'http://localhost:3021/v4/publishers/graphql',
        publisherUser: 'http://localhost:3038/v4/publisher-user/graphql',
        uploadPublisherAssets: 'http://localhost:3021/v4/publishers/upload-assets',
        publisherGenerateSVG: 'http://localhost:3021/v4/publishers/generate-svg',
        publisherLogo: 'http://localhost:3021/v4/publishers/publisher-logo',
        getSchedules: 'http://localhost:3020/v4/schedules/graphql',
        getEvents: 'http://localhost:3020/v4/event/graphql',
        eventImport: 'http://localhost:3020/v4/event/import',
        // authGetPublisherInfo: 'https://test-svc.ecal.com/v4/auth/publisher',
       // searchPublishers: 'https://test-svc.ecal.com/v4/publishers/graphql',
        getOptins: 'http://localhost:3009/v4/optins',
        getWebhooks: 'http://localhost:3009/v4/webhook',
        updateProfile: 'http://localhost:3003/v4/auth/secured/publisher',
        getWebhook: 'https://test-svc.ecal.com/v4/webhook',
        label: 'http://localhost:3009/v4/label',
        subscribers: 'http://localhost:3009/v4/subscribers',
        monthlySubscribers: 'http://localhost:3009/v4/analytics/subscriptions/monthly',
        apikey: 'http://localhost:3009/v4/apikey',
        buttons: 'http://localhost:3009/v4/buttons/widget/graphql',
        subscribersByWidget: 'http://localhost:3009/v4/analytics/count/subscribersByWidget',
        subscribersByEventDisplay: 'http://localhost:3009/v4/analytics/count/subscribersByEventDisplay',
        analyticsCalendarTypes: 'http://localhost:3009/v4/analytics/count/calendarType',
        analyticsSubscriptions: 'http://localhost:3009/v4/analytics/subscriptions/daily',
        analyticsClicks: 'http://localhost:3009/v4/analytics/clicks/daily',
        subscriptionsMap: 'http://localhost:3009/v4/analytics/locations/map',
        analyticsPopularLocations: 'http://localhost:3009/v4/analytics/locations/leaderboard',
        analyticsDeviceTypes: 'http://localhost:3009/v4/analytics/count/deviceType',
        totalSubscribers: 'http://localhost:3009/v4/analytics/count/subscriber',
        liveScheduleCount: 'http://localhost:3009/v4/analytics/count/schedule',
        liveEventCount: 'http://localhost:3009/v4/analytics/count/event',
        optinCount: 'http://localhost:3009/v4/analytics/count/optin',
        monthlyActives: 'http://localhost:3009/v4/analytics/subscribers/active',
        eventImpressions: 'http://localhost:3009/v4/analytics/impressions/event',
        reminderImpressions: 'http://localhost:3009/v4/analytics/impressions/reminder',
        clicks: 'http://localhost:3009/v4/analytics/clicks/link',
        billing: 'http://localhost:3034/v4/billing/graphql',
        plansGQL: 'http://localhost:3034/v4/plans/graphql',
    }),
};

const baseURL = {
    /* LOCAL doesn't need base URL because every go service is on port */
    local: '',
    prod: 'https://svc.ecal.com',
    stage: 'https://stage-svc.ecal.com',
    dev: 'https://test-svc.ecal.com',
};

function getBaseURL () {
    // console.log('getBaseURL() Env: ', window.env);
    if (baseURL[window.env]) {
        // console.log('Service BaseURL () -> ', baseURL[window.env]);
        return baseURL[window.env];
    }
    // console.log('Service  BaseURL () -> ', baseURL[window.dev]);
    return baseURL.dev;
}

export function endpoints () {
    if (window.env !== undefined && window.env !== 'local') {
       return EndpointSources.remote();
    }
    /*
        Instead of changing this EndpointSources.remote().
        Update package.json run script with REACT_APP_ENV_VAR = dev
        "start": "yarn lint && REACT_APP_ENV_VAR=dev react-scripts start",
    */
    return EndpointSources.local();
}

export function post (endpoint, body, options = { token: '', traceId: '', headers: {}, returnOnError: false, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;

    // check whether token is expired or not.. wait for it...

    console.log('Posting: Endpoint: ', endpoint);
    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        const reqHeaders = {
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,
        };

        console.log('REQUEST HEADERS -> ', reqHeaders);

        axios
        .post(
            endpoint,
            body,
            {
                headers: reqHeaders,
                signal: controller.signal,
            }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);
            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                if (options.returnOnError) {
                    resolve({});
                }
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            // const errorResponse = error.response.data
            // ? error.response
            // : {
            //     data: {
            //       errors: 'generic error',
            //     },
            //   };
            window.envStore.clearRequest(controllerId);
            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });
            if (options.returnOnError) {
                console.log('Error ? ', error);
                resolve({});
            }
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function get (endpoint, options = { token: '', traceId: '', headers: {}, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;

    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);
        const reqHeaders = {
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,

        };

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        axios
        .get(
            endpoint,
            { headers: reqHeaders, signal: controller.signal }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);
            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            window.envStore.clearRequest(controllerId);

            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });
            // resolve(error.response);
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function upload (endpoint, formData, options = { token: '', traceId: '', headers: {}, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;

    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);
        const reqHeaders = {
            'content-type': 'multipart/form-data',
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,
        };

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        axios
        .post(
            endpoint,
            formData,
            { headers: reqHeaders, signal: controller.signal }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);
            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            window.envStore.clearRequest(controllerId);
            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function postGQL (endpoint, query, options = { token: '', traceId: '', headers: {}, returnOnError: false, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;

    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);
        const reqHeaders = {
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,
        };

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        axios
        .post(
            endpoint,
            query,
            { headers: reqHeaders, signal: controller.signal }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);

            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                if (options.returnOnError) {
                    resolve({});
                }
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            window.envStore.clearRequest(controllerId);

            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });

            if (options.returnOnError) {
                reject({});
            }
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function put (endpoint, body, options = { token: '', traceId: '', headers: {}, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;
    console.log('Put: Endpoint: ', endpoint);
    console.log('Body: ', body);
    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);
        const reqHeaders = {
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,
        };

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        axios
        .put(
            endpoint,
            body,
            { headers: reqHeaders, signal: controller.signal }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);

            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            window.envStore.clearRequest(controllerId);

            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function deleteCall (endpoint, options = { token: '', traceId: '', headers: {}, action: '' }) {
    const {
        token,
        headers,
        traceId,
        action,
    } = options;
    console.log('Delete: Endpoint: ', endpoint);
    async function execRequest (resolve, reject) {
        const refreshedToken = await verifyToken(token);
        const reqHeaders = {
            authorization: 'Bearer ' + refreshedToken,
            'x-trace-id': traceId,
            ...headers,
        };

        const controller = new AbortController();
        const controllerId = window.envStore.createRequest(controller);

        axios
        .delete(
            endpoint,
            { headers: reqHeaders, signal: controller.signal }
        )
        .then(function (response) {
            window.envStore.clearRequest(controllerId);

            if (dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response,
            })) {
                return;
            }
            resolve(response);
        })
        .catch(function (error) {
            window.envStore.clearRequest(controllerId);

            dispatchErrors({
                action: action || window.envStore.errorStore.action,
                store: window.envStore.errorStore,
                response: error.response,
            });
        });
    }
    renewExpiration();
    return new Promise(execRequest);
};

export function renewExpiration () {
    const userStore = window.envStore.userStore;
    if (typeof userStore.expireWhenIdle === 'function') {
        userStore.expireWhenIdle();
    }

    if (typeof userStore.refreshSession === 'function') {
        userStore.refreshSession();
    }
}
