import isEmpty from 'lodash/isEmpty';

export const Patterns = {
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+([.]{1}([a-zA-Z0-9-]+))+$/im,
    phone: /^[+]{1}(93|358|355|213|1684|376|244|1264|672|1268|54|374|297|61|43|994|1242|973|880|1246|375|32|501|229|1441|975|591|387|267|55|246|673|359|226|257|855|237|1|238| 345|236|235|56|86|61|61|57|269|242|243|682|506|225|385|53|357|420|45|253|1767|1849|593|20|503|240|291|372|251|500|298|679|358|33|594|689|241|220|995|49|233|350|30|299|1473|590|1671|502|44|224|245|595|509|379|504|852|36|354|91|62|98|964|353|44|972|39|1876|81|44|962|77|254|686|850|82|965|996|856|371|961|266|231|218|423|370|352|853|389|261|265|60|960|223|356|692|596|222|230|262|52|691|373|377|976|382|1664|212|258|95|264|674|977|31|599|687|64|505|227|234|683|672|1670|47|968|92|680|970|507|675|595|51|63|872|48|351|1939|974|40|7|250|262|590|290|1869|1758|590|508|1784|685|378|239|966|221|381|248|232|65|421|386|677|252|27|211|500|34|94|249|597|47|268|46|41|963|886|992|255|66|670|228|690|676|1868|216|90|993|1649|688|256|380|971|44|1|598|998|678|58|84|1284|1340|681|967|260|263){1}[0-9]{7,16}$/im,
    name: /^([A-Za-z]+)(([A-Za-z]+[-']?)*([A-Za-z]+)?(\s)*)+([A-Za-z]+[-']?)*([A-Za-z]+)?$/im,
    categoryName: /^[a-z0-9. _-]+$/i,
    verificationCode: /^[0-9]{6}/im,
    password: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
    urlCheck: new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+/=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i'),
    urlCheck2: /((http:\/\/)|(https:\/\/)){1}/i,
    urlCheck3: new RegExp('^(https?:\\/\\/)'),
    space: /\s/g,
};

export const InvalidChar = {
    name: /[^a-zA-Z ]/g,
    email: /[ +!#$%&*^,()]/g,
    phone: /[^+0-9]/g,
};

export const InputPatterns = {
    all: /[a-zA-Z0-9. +!#$%&*^_@-]/,
    publisherName: /[a-zA-Z0-9. _-]/,
    email: /[a-zA-Z0-9._@-]/,
    name: /[a-zA-Z._ ]/,
    phone: /[0-9+ ]/,
    numberOnly: /[0-9]/,
};

export function validatePattern (validPattern, value) {
    if (validPattern === undefined || validPattern === null) {
        return true;
    }
    return validPattern.test(value);
}

export function validateMinLength (validValue, value) {
    if (validValue === undefined || validValue === null) {
        return true;
    }
    if (value.length < validValue) {
        return false;
    }
   return true;
}

export function validateMaxLength (validValue, value) {
    if (validValue === undefined || validValue === null) {
        return true;
    }
    if (value.length > validValue) {
        return false;
    }
    return true;
}

export function validateRequired (validValue, value) {
    if (validValue === undefined || validValue === null) {
        return true;
    }

    if (validValue === true && !value) {
        return false;
    }
    return true; // validation success
}

export function validateMin (validValue, value) {
   if (value < validValue) {
       return false;
   }
   return true;
}

export function validateMax (validValue, value) {
   if (value > validValue) {
       return false;
   }
   return true;
}

export function validatePublisherEmail (validValue, value) {
    if (validValue === undefined || validValue === null) {
        return true;
    }
    if (value === validValue) {
        return false;
    }
   return true;
}

export function validate (params) {
    const {
        rules,
        value,
        messages,
    } = params;

    if (!validateRequired(rules.required, value)) {
        return messages.required;
    }

    if (!validateMinLength(rules.minLength, value)) {
        return messages.minLength;
    }

    if (!validateMaxLength(rules.maxLength, value)) {
        return messages.maxLength;
    }

    if (!validatePattern(rules.pattern, value)) {
        return messages.pattern;
    }

    if (!validatePublisherEmail(rules.publisherEmail, value)) {
        return messages.publisherEmail;
    }

    return '';
}

const Validations = {
    email: {
        minLength: 5,
        maxLength: 256,
        pattern: Patterns.email,
        required: true,
        publisherEmail: 'test@test.com',
    },

    phone: {
        minLength: 7,
        maxLength: 16,
        pattern: Patterns.phone,
        required: false,
    },

    name: {
        minLength: 1,
        maxLength: 128,
        pattern: Patterns.name,
        required: false,
    },

    lastName: {
        minLength: 1,
        maxLength: 128,
        pattern: Patterns.name,
        required: true,
    },

    password: {
        minLength: 8,
        maxLength: 20,
        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_?!@#$%^&*])(?=.{8,})'),
        required: true,
    },

    rePassword: {
        minLength: 8,
        maxLength: 20,
        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_?!@#$%^&*])(?=.{8,})'),
        required: true,
    },

    verificationCode: {
        minLength: 6,
        maxLength: 6,
        pattern: Patterns.verificationCode,
        required: true,
    },

    required: {
        required: true,
    },

    urlCheck: {
        pattern: Patterns.urlCheck,
        required: true,
    },

    urlNotRequired: {
        pattern: Patterns.urlCheck,
    },
};

/*
 var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
*/

export function isNonEmptyLinkValid (link) {
    if (link !== '' && link !== undefined && !Patterns.urlCheck3.test(link)) {
       return false;
    }

    if (Patterns.space.test(link)) {
        return false;
    }

    return true;
}

export function validateRePassword (params) {
    const {
        messages,
        valueRePassword,
        valueNewPassword,
    } = params;

    if (!isEmpty(valueRePassword) || !isEmpty(valueNewPassword)) {
        if (valueRePassword !== valueNewPassword) {
            return messages.passwordNotMatch;
        }
    }

    return '';
}

export default Validations;
