import { v4 as uuid } from 'uuid';
import { action, makeObservable, observable } from 'mobx';
import { isEmpty } from 'lodash';

import { useStores } from '../useStores';
import { randomString } from '../../../utils';
import { handleAuthError } from './auth_middleware';
import { handlePublisherError } from './publisher_middlerware';
// import { handleWebhookError } from './webhook_middleware';
import {
    dispatchError,
    dispatchSessionError,
    cancelError,
    dispatchAWSMarketplaceBillingError,
} from './actions';
export default class ErrorStore {
    env = {}
    stores = {}
    message = ''
    title = ''
    errors = null;
    genericErrors = null;
    hasError = false
    getWebhookKey = ''
    addWebhookKey = ''

    authError = {};
    publisherError = {};
    webhookError = {};

    customLabels = {};
    apikeys = {};
    observed = {
        customLabels: {
            key: '',
            action: '',
        },

        apikeys: {
            key: '',
            action: '',
        },

        buttons: {
            key: '',
            action: '',
        },
        errors: {},
    };

    updateError (apiName, title, message) {
        if (!this.title && !this.message) {
            this.hasError = false;
        } else {
            this.hasError = true;
        }
        this.title = title;
        this.message = message;
        this[apiName + 'Key'] = randomString(20);
    }

    appErrorKey = ''
    apiSource = ''
    reason = ''

    /* For easier maintainance, explicity assign errors per api to the error  middleware */
    getWebhookError = {}
    handleAuthError = handleAuthError;
    handlePublisherError = handlePublisherError;
    // handleWebhookError = handleWebhookError;

    constructor () {
        makeObservable(this, {

            getWebhookKey: observable,
            addWebhookKey: observable,
            appErrorKey: observable,
            hasError: observable,
            authError: observable,
            publisherError: observable,
            webhookError: observable,

            observed: observable,

            getWebhookError: observable,
            updateError: action,
        });
    }

    dispatchSessionError = dispatchSessionError;
    dispatchError = dispatchError;
    cancelError = cancelError;
    dispatchAWSMarketplaceBillingError = dispatchAWSMarketplaceBillingError;

    validateInput = ({ input, moduleFunc, action }) => {
        clearTimeout(this.validateTimeout);
        const self = this;
        function doValidation () {
            if (typeof self[moduleFunc] === 'function') {
                self[moduleFunc]({ input, action });
            }
        }
        this.validateTimeout = setTimeout(doValidation, 400);
    }

    /* a generic validate function that will traverse the custom errors state */
    validate = ({ input, customFuncs, action, errorProp }) => {
        this[errorProp] = {};
        for (const func in customFuncs) {
            if (typeof customFuncs[func] === 'function') {
                this[errorProp] = customFuncs[func](input, this[errorProp]);
            }
        }
        if (!isEmpty(this[errorProp])) {
            this.observed[errorProp] = {
                key: uuid(),
                action: action,
            };
        }
    }
}

export function useErrorStore () {
    const { errorStore } = useStores();
    return errorStore;
}
