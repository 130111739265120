import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './squareMonoSVG.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */

const DEFAULT_ICON_SIZE = 53;
const BUTTON_BASE_WIDTH = 193;

const SquareMonoSVG = (props) => {
    const classes = useStyles();
    const {
        width,
        text,
        iconSize,
        onTextMatrixChange,
    } = props;
    const textRef = useRef();
    const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 58.5319 28.4999)');

    useEffect(() => {
        if (textRef.current) {
            const text = textRef.current;
            const tbox = text.getBBox();
            // Note: Always use the iconSize as an offset to center things accordingly
            const newX = (iconSize / 2) + ((BUTTON_BASE_WIDTH - tbox.width) / 2);
            const translation = 'matrix(1 0 0 1 ' + newX + ' 28.4999)'; // will be sent to the backend as textMatrix
            setTransformMatrix(translation);
        }
    }, [textRef.current, width, text]);

    useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

    return (
        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
            viewBox='0 0 202.6 48' style={{ enableBackground: 'new 0 0 202.6. 48' }} xmlSpace='preserve'>
            <g>
                <rect x='0.5' y='0.5' width='201.6' height='47'/>
                <path className={classes.st0} d='M201.6,1v46H1V1H201.6 M202.6,0H0v48h202.6V0L202.6,0z'/>
            </g>
            <text ref={textRef} transform={transformMatrix} className={classNames([classes.st0, classes.st1, classes.st2])} >{text}</text>
            <g>
                <defs>
                    <rect id='SVGID_1_' x='12.4' y='1' width='47' height='33'/>
                </defs>
                <clipPath id='SVGID_2_'>
                    <use xlinkHref='#SVGID_1_' style={{ 'overflow': 'visible' }}/>
                </clipPath>
                <g className={classes.st3}>
                    <path className={classes.st0} d='M36.3,20.8H24.8c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1s0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5
                        h11.6c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
                        c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.2-0.4-0.3-0.6-0.3C36.8,20.8,36.5,20.8,36.3,20.8z'/>
                    <path className={classes.st0} d='M47,15.9H28.9c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.4,0.5
                        c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1H47
                        c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
                        c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.3-0.6-0.3C47.5,15.9,47.3,15.9,47,15.9z'/>
                    <path className={classes.st0} d='M32.4,25.8H14c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1
                        c0.3,0.3,0.7,0.5,1.1,0.5h18.4c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1C33.3,26,32.9,25.8,32.4,25.8
                        z'/>
                </g>
            </g>
        </svg>

    );
};

SquareMonoSVG.propTypes = {
    width: PropTypes.number,
    text: PropTypes.string,
    iconSize: PropTypes.number,
    onTextMatrixChange: PropTypes.func,
};

SquareMonoSVG.defaultProps = {
    width: 200,
    text: 'Sync to Calendar',
    iconSize: DEFAULT_ICON_SIZE,
    onTextMatrixChange: () => {},
};

export default SquareMonoSVG;
