import { isEmpty } from 'lodash';

import {
    logInfo,
} from '../utils';

import {
    AddOnTypes,
    BillingCoupons,
    HandleNames,
} from '../utils/constants';

/* Aligned to US Tax Rate in Chargify */

/*
const usTaxRate = {
    default: 4.0,
    al: 4.0, // Alabama
    ak: 0, // Alaska
    az: 5.6, // Arizona
    ar: 6.5, // Arkansas
    ca: 7.5, // California
    co: 2.9, // Colorado
    ct: 6.3, // Connecticut
    de: 0, // Delaware
    dc: 5.7, // District of Columbia
    fl: 6.0, // Florida

    ga: 4.0, // Georgia
    hi: 4.0, // Hawaii
    id: 6.0, // Idaho
    il: 6.2, // Illinois
    in: 7.0, // Indiana
    ia: 6.0, // Iowa
    ks: 6.1, // Kansas
    ky: 6.0, // Kentucky
    la: 4.0, // Louisana
    me: 5.5, // Maine

    md: 6.0, // Maryland
    ma: 6.2, // Massachusetts
    mi: 0, // Michigan
    mn: 0, // Minnesota
    ms: 0, // Mississippi
    mo: 4.2, //  Missouri
    mt: 0, // Montana
    ne: 5.5, // Nebraska
    nv: 6.8, // Nevada
    nh: 0, // New Hampshire

    nj: 7.0, // New Jersey
    nm: 5.125, // New Mexico
    ny: 4.0, // New York
    nc: 4.7, // North Carolina
    nd: 5.0, // North Dakota
    oh: 5.7, // Ohio
    ok: 4.5, // Oklahoma
    or: 0, // Oregon
    pa: 6.0, // Pennsylvania
    ri: 7.0, // Rhode Island

    sc: 6.0, // South Carolina
    sd: 4.0, // South Dakota
    tn: 7.0, // Tennessee
    tx: 6.2, // Texas
    ut: 5.9, // Utah
    vt: 6.0, // Vermont
    va: 5.3, // Virginia
    wa: 6.5, // Washington
    wv: 6.0, // West Virginia
    wi: 5.0, // Wisconsin
    wy: 4.0, // Wyoming
};
*/

/* EURO TAX RATE */
/*
const euTaxRate = {
    default: 0,
    fr: 20, // France
    de: 20, // Germany
    it: 20, // Italy
    es: 20, // Spain
    ru: 20, // Russia
    pt: 20, // Portugal
    nl: 20, // Netherlands
    ie: 20, // Ireland
    dk: 20, // Denmark
    ch: 7.7, // Switzerland
};
*/

const euTaxRate_testEnv = {
    fr: 10,
    de: 10,
    it: 10,
    es: 10,
    ru: 10,
    pt: 10,
    nl: 10,
    ie: 10,
    dk: 10,
    ch: 10, // switzerland
};

export const CurrencyLabels = {
    au: 'AUD',
    us: 'USD',
    uk: 'GBP',
    eu: 'EUR',
};

export const CurrencySymbols = {
    au: '$',
    us: '$',
    uk: '£',
    eu: '€',
};

export const TaxLabels = {
    au: 'GST',
    us: 'Tax',
    uk: 'VAT',
    eu: 'VAT',
};

const ukTaxRate = 20;
const auTaxRate = 10;
const euTaxRate = 0;
const usTaxRate = 0;
export function getCurrencyLabel (ccy) {
    if (CurrencyLabels[ccy]) {
        return CurrencyLabels[ccy];
    }
    return CurrencyLabels.au;
}

export function getCurrencySymbol (ccy) {
    if (CurrencySymbols[ccy]) {
        return CurrencySymbols[ccy];
    }
    return CurrencySymbols.au;
}

export function getTaxLabel (ccy) {
    if (TaxLabels[ccy]) {
        return TaxLabels[ccy];
    }
    return TaxLabels.au;
}

const chargifyConfig = {
    local: {
        au: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            taxRate: {
                default: 0,
                au: auTaxRate,
            },
            currencyLabel: CurrencyLabels.au,
            currencySymbol: CurrencySymbols.au,
            taxLabel: TaxLabels.au,

            addUser: {
               'au-community-1k': 1000,
               'au-grow-5k': 1000,
               'au-pro-30k': 10000,
               'au-enterprise': -1,
            },
        },

        uk: {
            publicKey: 'chjs_prsnq5xsrt42ytk3byn5bty4',
            endpoint: 'ecal-uk-test.chargify.com',
            taxRate: {
                default: 0,
                uk: ukTaxRate,
            },
            currencyLabel: CurrencyLabels.uk,
            currencySymbol: CurrencySymbols.uk,
            taxLabel: TaxLabels.uk,

            addUser: {
                'uk-community-1k': 1000,
                'uk-grow-5k': 1000,
                'uk-pro-30k': 10000,
                'uk-enterprise': -1,
            },
        },

        us: {
            publicKey: 'chjs_f4ggbym42tn9hy7yhtpntqnm',
            endpoint: 'ecal-us-test.chargify.com',
            taxRate: {
                default: 0,
                us: usTaxRate,
            },
            currencyLabel: CurrencyLabels.us,
            currencySymbol: CurrencySymbols.us,
            taxLabel: TaxLabels.us,

            addUser: {
                'us-community-1k': 1000,
                'us-grow-5k': 1000,
                'us-pro-30k': 10000,
                'us-enterprise': -1,
            },
        },

        eu: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            taxRate: {
                default: 0,
                ...euTaxRate_testEnv,
            },
            currencyLabel: CurrencyLabels.eu,
            currencySymbol: CurrencySymbols.eu,
            taxLabel: TaxLabels.eu,

            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
            },
        },
    },

    dev: {
        au: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            taxRate: {
                default: 10,
                au: auTaxRate,
            },
            currencyLabel: CurrencyLabels.au,
            currencySymbol: CurrencySymbols.au,
            taxLabel: TaxLabels.au,

            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
             },
        },

        uk: {
            publicKey: 'chjs_prsnq5xsrt42ytk3byn5bty4',
            endpoint: 'ecal-uk-test.chargify.com',
            taxRate: {
                default: 20,
                uk: ukTaxRate,
            },
            currencyLabel: CurrencyLabels.uk,
            currencySymbol: CurrencySymbols.uk,
            taxLabel: TaxLabels.uk,

            addUser: {
                'uk-community-1k': 1000,
                'uk-grow-5k': 1000,
                'uk-pro-30k': 10000,
                'uk-enterprise': -1,
            },
        },

        us: {
            publicKey: 'chjs_f4ggbym42tn9hy7yhtpntqnm',
            endpoint: 'ecal-us-test.chargify.com',
            taxRate: {
                default: 0,
                us: usTaxRate,
            },
            currencyLabel: CurrencyLabels.us,
            currencySymbol: CurrencySymbols.us,
            taxLabel: TaxLabels.us,

            addUser: {
                'us-community-1k': 1000,
                'us-grow-5k': 1000,
                'us-pro-30k': 10000,
                'us-enterprise': -1,
            },
        },

        eu: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            currencyLabel: CurrencyLabels.eu,
            currencySymbol: CurrencySymbols.eu,
            taxLabel: TaxLabels.eu,

            taxRate: {
                default: 0,
                eu: euTaxRate,
                ...euTaxRate_testEnv,
            },
            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
            },
        },
    },

    stage: {
        au: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            taxRate: {
                default: 0,
                au: auTaxRate,
            },
            currencyLabel: CurrencyLabels.au,
            currencySymbol: CurrencySymbols.au,
            taxLabel: TaxLabels.au,

            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
             },
        },

        uk: {
            publicKey: 'chjs_prsnq5xsrt42ytk3byn5bty4',
            endpoint: 'ecal-uk-test.chargify.com',
            taxRate: {
                default: 0,
                uk: ukTaxRate,
            },
            currencyLabel: CurrencyLabels.uk,
            currencySymbol: CurrencySymbols.uk,
            taxLabel: TaxLabels.uk,

            addUser: {
                'uk-community-1k': 1000,
                'uk-grow-5k': 1000,
                'uk-pro-30k': 10000,
                'uk-enterprise': -1,
            },
        },

        us: {
            publicKey: 'chjs_f4ggbym42tn9hy7yhtpntqnm',
            endpoint: 'ecal-us-test.chargify.com',
            taxRate: {
                default: 0,
                us: usTaxRate,
            },
            currencyLabel: CurrencyLabels.us,
            currencySymbol: CurrencySymbols.us,
            taxLabel: TaxLabels.us,

            addUser: {
                'us-community-1k': 1000,
                'us-grow-5k': 1000,
                'us-pro-30k': 10000,
                'us-enterprise': -1,
            },
        },

        eu: {
            publicKey: 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            endpoint: 'ecal-au-test.chargify.com',
            currencyLabel: CurrencyLabels.eu,
            currencySymbol: CurrencySymbols.eu,
            taxLabel: TaxLabels.eu,

            taxRate: {
                default: 0,
                eu: euTaxRate,
                ...euTaxRate_testEnv,
            },
            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
            },
        },
    },

    prod: {
        au: {
            publicKey: 'chjs_5v4b2vh5x7rq5v9gxw2csyyd',
            endpoint: 'ecal-au.chargify.com',
            taxRate: {
                default: 0,
                au: auTaxRate,
            },
            currencyLabel: CurrencyLabels.au,
            currencySymbol: CurrencySymbols.au,
            taxLabel: TaxLabels.au,

            addUser: {
                'au-community-1k': 1000,
                'au-grow-5k': 1000,
                'au-pro-30k': 10000,
                'au-enterprise': -1,
             },
        },

        uk: {
            publicKey: 'chjs_p4xb4783fsdswrx38gnn6bbz',
            endpoint: 'ecal-uk.chargify.com',
            taxRate: {
                default: 0,
                uk: ukTaxRate,
            },
            currencyLabel: CurrencyLabels.uk,
            currencySymbol: CurrencySymbols.uk,
            taxLabel: TaxLabels.uk,

            addUser: {
                'uk-community-1k': 1000,
                'uk-grow-5k': 1000,
                'uk-pro-30k': 10000,
                'uk-enterprise': -1,
            },
        },

        us: {
            publicKey: 'chjs_cm34b44zsnfhsqqd2ryjj8pr',
            endpoint: 'ecal-us.chargify.com',
            taxRate: {
                default: 0,
                us: usTaxRate,
            },
            currencyLabel: CurrencyLabels.us,
            currencySymbol: CurrencySymbols.us,
            taxLabel: TaxLabels.us,

            addUser: {
                'us-community-1k': 1000,
                'us-grow-5k': 1000,
                'us-pro-30k': 10000,
                'us-enterprise': -1,
            },
        },

        eu: {
            publicKey: 'chjs_mnwbztwvqp9c427q3p462x85',
            endpoint: 'ecal-eu.chargify.com',
            taxRate: {
                default: 0,
                eu: euTaxRate,
                // ...euTaxRate,
            },
            currencyLabel: CurrencyLabels.eu,
            currencySymbol: CurrencySymbols.eu,
            taxLabel: TaxLabels.eu,

            addUser: {
                'eu-community-1k': 1000,
                'eu-grow-5k': 1000,
                'eu-pro-30k': 10000,
                'eu-enterprise': -1,
            },
        },
    },
};

export default chargifyConfig;

export function getChargifyTaxRate (currency, country, state) {
    logInfo('getChargifyTaxRate() currency -> ', currency);
    logInfo('getChargifyTaxRate() country -> ', country);
    logInfo('getChargifyTaxRate() state -> ', state);
    const conf = getChargifyConfig(currency);
    const taxRate = conf.taxRate;
    logInfo('TaxRate Object: ', taxRate);
    if (!country) {
        logInfo('Returning TaxRate for Undefined Country', taxRate.default);
        return usTaxRate;
    }

    let _country = country.toLowerCase();
    //* There are case when country is uk
    if (_country === 'gb') {
        _country = 'uk';
    }
    // const _state = state.toLowerCase();

    if (taxRate[_country]) {
        logInfo('Checking Tax Country: ', _country);
        if (_country !== 'us') {
            logInfo('Getting Tax From Non US Country -> Tax is', taxRate[_country]);
            return taxRate[_country];
        } else {
            return usTaxRate;
            /*
            const usTaxRate = taxRate[_country];
            logInfo('Getting Tax From US: ', usTaxRate);
            logInfo('Getting Tax From State is: ', _state);
            if (usTaxRate[_state]) {
                logInfo('Getting Tax From US State : ', usTaxRate[_state]);
                return usTaxRate[_state];
            }
            logInfo('Getting Tax From US Default : ', usTaxRate.default);
            return usTaxRate.default;
            */
        }
    }

    logInfo('Returning TaxRate for  Default', taxRate.default);
    return taxRate.default;
}

function isAddOnMonthly (addOn) {
    // const handle = addOn.handle;
    // if (handle && handle.indexOf('monthly') !== -1) {
    if (addOn.recurring) {
        return true;
    }
    return false;
}

export function calculatePriceSummary (inputValue, addOnsValue, tax, options) {
    const {
        untouchedAddOnsValues = null,
        coupon = null,
    } = options;

    logInfo('chargify.calculatePrice() Input: ', inputValue);
    logInfo('chargify.calculatePrice() addOnValues: ', addOnsValue);
    logInfo('chargify.calculatePrice() untouchedAddOnsValues: ', untouchedAddOnsValues);

    if (isEmpty(inputValue)) {
        return {};
    }

    const taxRate = tax * 0.01;
    const price = Number(inputValue.price);
    let newSummary = {
        planName: inputValue.name,
        monthly: price,
        monthlyWithTax: price + (price * taxRate),
        addOns: [],
        totalAddOnsPrice: 0,
        totalAddOnsRecurringPrice: 0,
        taxValue: price * taxRate,
     };

     if (!isEmpty(inputValue.addOns)) {
        for (let i = 0; i < inputValue.addOns.length; i++) {
            const addOn = inputValue.addOns[i];
            const addOnValue = addOnsValue[addOn.id];
            const addOnPrice = Number(addOn.price);
            let tprice = 0;
            // logInfo('addOn value: ', addOnValue);

            if (addOn.type === AddOnTypes.ON_OFF) {
                if (addOnValue) {
                    tprice = addOnPrice;
                }
            } else if (addOn.type === AddOnTypes.QUANTITY) {
                if (Number(addOnValue) > 0) {
                    tprice = computeQuantityPrice({
                        addOn,
                        addOnValue,
                        untouchedAddOnsValues,
                        addOnPrice,
                    });
                    console.log('calculatePriceSummary () Add on Price ', tprice);
                }
            }

            if (tprice > 0) {
                if (isAddOnMonthly(addOn)) {
                    newSummary.totalAddOnsRecurringPrice += tprice;
                }

                newSummary.addOns.push({
                    ...addOn,
                    priceValue: tprice,
                });

                if (addOn.handle.indexOf(HandleNames.SETUP) !== -1 &&
                    addOn.handle.indexOf(HandleNames.CUSTOM) === -1 &&
                    coupon === BillingCoupons.SETMEUP23) {
                    continue;
                }
                newSummary.totalAddOnsPrice += tprice;
            }
        }
     }

    let totalPrice = price + newSummary.totalAddOnsPrice;
    let totalPriceRecurring = price + newSummary.totalAddOnsRecurringPrice;

    if (coupon === BillingCoupons.ITSONUS23) {
        totalPrice = 0 + newSummary.totalAddOnsPrice;
    }

    if (coupon === BillingCoupons.LETSDOTHIS23 && newSummary.planName.indexOf('Professional') !== -1) {
        const percentOff = BillingCoupons.LETSDOTHIS_PERCENT_OFF;
        newSummary.monthlyPlanWithDiscount = (price - (price * percentOff));
        totalPrice = (price - (price * percentOff)) + newSummary.totalAddOnsPrice;
        totalPriceRecurring = (price - (price * percentOff)) + newSummary.totalAddOnsRecurringPrice;
    }

    newSummary.totalPrice = totalPrice;
    newSummary.taxValue = totalPrice * taxRate;
    newSummary.taxValueRecurring = totalPriceRecurring * taxRate;

    newSummary.grandTotal = newSummary.totalPrice + newSummary.taxValue;
    newSummary.grandTotalRecurring = totalPriceRecurring + newSummary.taxValueRecurring;
    newSummary.grandTotalRecurringNoTax = totalPriceRecurring;

    logInfo('Summary: ', newSummary);
    newSummary = withCoupon(newSummary, coupon);
    return newSummary;
}

function withCoupon (summary, coupon) {
    if (!coupon) {
        return summary;
    }
    /// handle various coupon here...
    if (coupon === BillingCoupons.ITSONUS23) {
        //
    }
    return summary;
}

function computeQuantityPrice (p) {
    const {
        untouchedAddOnsValues,
        addOn,
        addOnValue,
        addOnPrice,
    } = p;
    let tprice = 0;
    if (!isEmpty(untouchedAddOnsValues)) {
        const originalQty = untouchedAddOnsValues[addOn.id];
        const diff = addOnValue - originalQty;
        if (diff > 0) {
            tprice = diff * addOnPrice;
            console.log('computeQuantityPrice() -> diffing... ', tprice);
        }
    } else {
        tprice = addOnPrice * addOnValue;
    }
    return tprice;
}

export function stripPrefixFromHandleName (handle, newPlanHandle) {
    // logInfo('stripPrefixFromHandleName() NewPlan -> ', newPlanHandle);
    // logInfo('stripPrefixFromHandleName() Component Handle -> ', handle);
    const stripped = handle.split(newPlanHandle + '-')[1];
    return stripped;
}

export function getAddOnByHandleName (params) {
    const {
        handleName, // <- component handle name of the newPlan
        addOns,
        newPlanHandle,
         // currentPlanHandle,
    } = params;

    if (isEmpty(addOns) || (isEmpty(handleName))) {
        return null;
    }

    const handle = stripPrefixFromHandleName(handleName, newPlanHandle);
    // logInfo('getAddonByHandleName(): -> stripped ', handle);
    for (let i = 0; i < addOns.length; i++) {
        const addOn = addOns[i];
        logInfo('getAddByHandleName() -> ', addOn);
        if (addOn.handle.indexOf(handle) !== -1) {
            logInfo('getAddByHandleName() will Return Add On ', addOn);
            return addOn;
        }
    }
    return null;
}

export function getAddOns (source, inputBase) {
    const newAddOns = [];
    for (const i in source) {
        const value = source[i];
        if (value) {
            const addOn = inputBase.addOns.filter((v) => (v.id === i))[0];

            const newAddOn = {
                id: i,
                type: addOn.type,
                handle: addOn.handle, // uncomment when API is ready...
             };

            if (addOn.type === 'quantity') {
                newAddOn.quantity = value;
            }

            if ((addOn.handle.indexOf('binary') !== -1 ||
                addOn.handle.indexOf('toggle') !== -1) &&
            addOn.type === 'quantity') {
                newAddOn.type = 'onOff';
                delete newAddOn.quantity;
            }
            newAddOns.push(newAddOn);
        }
    }
    return newAddOns;
}

function getConfigSource () {
    const env = process.env.REACT_APP_ENV_VAR;
    if (env && chargifyConfig[env]) {
        return chargifyConfig[env];
    }
    return chargifyConfig.prod;
}

export function getChargifyConfig (currency) {
   const configSource = getConfigSource();
   if (currency && configSource[currency]) {
        return configSource[currency];
   }
   return configSource.au; // use au as default...
}

export function calcualteAdditionalUserLimit (params) {
    const {
        currency,
        planHandle,
        planLimit,
    } = params;
    const conf = getChargifyConfig(currency);
    const addUserLimit = conf.addUser[planHandle];
    return planLimit + addUserLimit;
}

export function isAddUserLimitReached (params) {
    const {
        subsriberCount,
    } = params;
    const limit = calcualteAdditionalUserLimit(params);
    return subsriberCount < limit;
}

function orderSetup (addOnsSetup) {
    const newAddOns = [];
    const orderedAddons = {};
    for (let i = 0; i < addOnsSetup.length; i++) {
        const addOn = addOnsSetup[i];
        if (addOn.handle.indexOf('setup-simple') !== -1) {
            orderedAddons['0'] = addOn;
        } else if (addOn.handle.indexOf('setup-cmplx') !== -1) {
            orderedAddons['1'] = addOn;
        } else if (addOn.handle.indexOf('setup-integration-simple') !== -1) {
            orderedAddons['2'] = addOn;
        } else if (addOn.handle.indexOf('setup-integration-cmplx') !== -1) {
            orderedAddons['3'] = addOn;
        } else if (addOn.handle.indexOf('setup-integration-custom') !== -1) {
            orderedAddons['4'] = addOn;
        } else if (addOn.handle.indexOf(HandleNames.PROJECT_SETUP) !== -1) {
            orderedAddons['0'] = addOn;
        }
    }

    const keys = Object.keys(orderedAddons);
    keys.sort();
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        newAddOns.push(orderedAddons[key]);
    }
    return newAddOns;
}

export function withOrganizedAddOns (addOns) {
    const newAddOns = [];
    let addOnsSetup = [];

    for (let i = 0; i < addOns.length; i++) {
        const addOn = addOns[i];
        // addOn.handle.indexOf(HandleNames.PROJECT_SETUP) !== -1
        if (addOn.handle.indexOf(HandleNames.SSO_ADMIN_SETUP) !== -1) {
            newAddOns.push(addOn);
            continue;
        }
        if (addOn.handle.indexOf('setup') !== -1 || addOn.handle.indexOf(HandleNames.PROJECT_SETUP) !== -1) {
            addOnsSetup.push(addOn);
        } else {
            newAddOns.push(addOn);
        }
    }

    addOnsSetup = orderSetup(addOnsSetup);

    return [
        ...addOnsSetup,
        ...newAddOns,
    ];
}

export function isSetupAddOn (handle) {
    // not setup addon
    // handle.indexOf(HandleNames.PROJECT_SETUP) !== -1
    if (handle.indexOf(HandleNames.SSO_ADMIN_SETUP) !== -1) {
        return false;
    }

    if (handle.indexOf(HandleNames.SETUP) !== -1) {
        return true;
    }
    return false;
}

export function getDisabledASetupAddons (addOns, values) {
    const disabled = {};

    // get the setup
    let selectedSetupAddon = {};
    for (let i = 0; i < addOns.length; i++) {
        const addOn = addOns[i];
        const value = values[addOn.id];
        if (value && isSetupAddOn(addOn.handle)) {
            selectedSetupAddon = addOn;
            break;
        }
    }

    if (!selectedSetupAddon.id) {
        return disabled;
    }
    for (let i = 0; i < addOns.length; i++) {
        const addOn = addOns[i];
        if (isSetupAddOn(addOn.handle) &&
        addOn.id !== selectedSetupAddon.id) {
            disabled[addOn.id] = true;
        }
    }
    return disabled;
}

export const chargifyFields = {

    number: {
        selector: '#chargifyPanel',
        label: 'Number',
        placeholder: 'Credit Card Number',
        message: 'Invalid card number',
        style: {
            field: {
                backgroundColor: '#FFFFFF',
                padding: '3px',
                borderRadius: '5px',
            },
            input: {
                backgroundColor: '#FFFFFF',
                paddingTop: '2px',
                paddingBottom: '1px',
            },
            label: {
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '11px',
            },
            message: { paddingTop: '2px', paddingBottom: '1px' },
        },
    },
    month: {
        selector: '#chargifyPanel',
        label: 'Mon',
        placeholder: 'MM',
        message: 'Month is required',
        style: {
            field: {
                backgroundColor: '#FFFFFF',
                padding: '3px',
                borderRadius: '5px',
            },
            input: {
                backgroundColor: '#FFFFFF',
                paddingTop: '2px',
                paddingBottom: '1px',
            },
            label: {
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '11px',
            },
            message: { paddingTop: '2px', paddingBottom: '1px' },
        },
    },
    year: {
        selector: '#chargifyPanel',
        label: 'Year',
        placeholder: 'YYYY',
        message: 'Year is required',
        style: {
            field: {
                backgroundColor: '#FFFFFF',
                padding: '3px',
                borderRadius: '5px',
            },
            input: {
                backgroundColor: '#FFFFFF',
                paddingTop: '2px',
                paddingBottom: '1px',
            },
            label: {
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '11px',
            },
            message: { paddingTop: '2px', paddingBottom: '1px' },
        },
    },

    cvv: {
        selector: '#chargifyPanel',
        label: 'CVV code',
        placeholder: 'CVV',
        required: false,
        message: 'CVV is required.',
        style: {
            field: {
                backgroundColor: '#FFFFFF',
                padding: '3px',
                borderRadius: '5px',
            },
            input: {
                backgroundColor: '#FFFFFF',
                paddingTop: '2px',
                paddingBottom: '1px',
            },
            label: {
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '11px',
            },
            message: { paddingTop: '2px', paddingBottom: '1px' },
        },
    },
};

export const couponMapping = {
    firstmonthfree: 'ITSONUS23',
    '12monthdiscount': 'LETSDOTHIS23',
    freesetup: 'SETMEUP23',
    expertsession: 'EXPERTPLS',
};

export function getCouponValue (couponName) {
    if (couponMapping[couponName]) {
        return couponMapping[couponName];
    }
    return undefined;
}
