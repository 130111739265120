import React from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    Label as LabelIcon,
} from '@material-ui/icons';

import {
    statusCell,
    nameCell,
    subscribersCell,
    previewCell,
    actionsCell,
} from './customCells';

export const columns = [
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },

    {
        id: 'name',
         numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'subscribers',
        numeric: true,
        disablePadding: false,
        label: 'Subscribers',
        cellClassName: 'subscriberCellTD',
    },

    {
        id: 'preview',
        numeric: true,
        disablePadding: false,
        label: 'Preview',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: '',
    },
];

export const customCells = {
    status: statusCell,
    name: nameCell,
    subscribers: subscribersCell,
    preview: previewCell,
    actions: actionsCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
