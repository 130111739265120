import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Country, State } from 'country-state-city';
// import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import {
    Grid,
    TextField,
} from '@material-ui/core';

import Select from '../../commons/select/Select';
import { useStyles, chargifyStyles } from './styles';

import {
    BillingCoupons,
    HandleNames,
} from '../../utils/constants';

import { isSetupAddOn } from '../../conf/chargify';

import { logInfo } from '../../utils';

function countriesForSelect () {
    let c = Country.getAllCountries();
    c = c.map((item) => ({
        value: item.isoCode,
        label: item.name,
    }));
    logInfo('Countries for Selection: ', c);
    return c;
}

export function SummaryEntry (props) {
    const {
        entryText,
        entryPrice,
        highlighted = false,
        hasBorder = true,
        handle = '',
        coupon = null,
    } = props;

    const classes = useStyles({ highlighted, hasBorder });

    function renderPrice () {
        if (isSetupAddOn(handle) &&
            handle.indexOf(HandleNames.CUSTOM) === -1 &&
            coupon === BillingCoupons.SETMEUP23) {
            return (
                <Grid className={classes.summaryEntryPrice}>
                    <b>FREE</b>&nbsp;<strike>{ entryPrice }</strike>
                </Grid>
            );
        }

        return (
            <Grid className={classes.summaryEntryPrice}>
                { entryPrice }
            </Grid>
        );
    }

    return (
        <Grid className={classes.summaryEntry}>
            <Grid className={classes.summaryEntryText}>
                { entryText }
            </Grid>
            { renderPrice() }
        </Grid>
    );
};

function BillingChargifyForm (props) {
    const {
        billingInputProps,
    } = props;

    const {
        pricingSummary,
        chargifyFormRef,
        // chargify,
        chargifyInput,
        updateChargifyInput,
        errors = {},
        initChargify,
        currencyLabel,
        taxLabel,
        taxRate,
        coupon,
    } = billingInputProps;

    const [countries] = useState(countriesForSelect());
    const [states, setStates] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        // logInfo('Countries in Timezone: ', moment.tz.zone(tz).countries());
        /* Chargify Load should be an action of billing */
        updateStates(chargifyInput.country);
        initChargify(chargifyStyles);
    }, []);

    function renderPricedAddOns () {
        if (pricingSummary.addOns.length <= 0) {
            return null;
        }

        return (
            <Grid className={classes.summaryAddOns}>
                {
                    pricingSummary.addOns.map((addOn) => {
                        function addOnName () {
                            // if (addOn.handle && addOn.handle.indexOf('monthly') !== -1) {
                            if (addOn.recurring) {
                                return `${addOn.name} (monthly)`;
                            }
                            return addOn.name;
                        }

                        return (
                            <SummaryEntry
                                key={uuid()}
                                handle={addOn.handle}
                                coupon={coupon}
                                entryText={ addOnName()}
                                entryPrice={ addOn.priceValue.toFixed(2) + ' ' + currencyLabel}
                            />
                        );
                    })
                }
            </Grid>
        );
    }

    function updateStates (countryCode) {
        let s = State.getStatesOfCountry(countryCode);
        s = s.map(state => ({
            value: countryCode.toLowerCase() !== 'us' ? state.name : state.isoCode,
            label: state.name,
        }));

        // logInfo('updateStates() -> Country States: ', s);
        setStates(s);
    }

    function renderTotalRecurring () {
        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary.planName.indexOf('Professional') !== -1) {
            // return `${pricingSummary.grandTotalRecurringNoTax.toFixed(2)} ${currencyLabel} ${BillingCoupons.LETSDOTHIS_OFF_LABEL}`;
            return `${pricingSummary.grandTotalRecurring.toFixed(2)} ${currencyLabel}`; // total recurring with thax
        }
        return `${pricingSummary.grandTotalRecurring.toFixed(2)} ${currencyLabel}`;
        // return pricingSummary.grandTotalRecurringNoTax.toFixed(2) + ' ' + currencyLabel;
    }

    function renderPlan () {
        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary.planName.indexOf('Professional') !== -1) {
            return `${pricingSummary.planName} Plan ${BillingCoupons.LETSDOTHIS_OFF_MONTHLY_LABEL}`;
        }

        if (coupon === BillingCoupons.ITSONUS23) {
            return `${pricingSummary.planName} Plan (monthly)`;
        }
        return `${pricingSummary.planName} Plan (monthly)`;
    }

    function renderPlanPrice () {
        if (coupon === BillingCoupons.ITSONUS23) {
            return `(FIRST MONTH FREE) ${pricingSummary.monthly.toFixed(2)} ${currencyLabel}`;
        }

        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary.planName.indexOf('Professional') !== -1) {
            // pricingSummary.grandTotalRecurringNoTax.toFixed(2)
            return (<Grid className={classes.summaryEntryPrice}>
                <b>{`${pricingSummary.monthlyPlanWithDiscount.toFixed(2)} ${currencyLabel}`}</b>&nbsp;
                <strike> {`${pricingSummary.monthly.toFixed(2)} ${currencyLabel}`} </strike>
            </Grid>);
        }
        return `${pricingSummary.monthly.toFixed(2)} ${currencyLabel}`;
    }

    function renderTotalRecurringSummaryEntry () {
        return (<SummaryEntry
            entryText={ 'Total Recurring'}
            entryPrice={ renderTotalRecurring() }
        />);
    }

    function renderTodaysTotalPrice () {
        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary.planName.indexOf('Professional') !== -1) {
            return `${pricingSummary.grandTotal.toFixed(2)} ${currencyLabel}`; // ${BillingCoupons.LETSDOTHIS_OFF_LABEL}
        }
        return `${pricingSummary.grandTotal.toFixed(2)} ${currencyLabel}`;
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid className={classes.summary}>

                    <SummaryEntry
                        entryText={ renderPlan() }
                        entryPrice={ renderPlanPrice() }
                    />

                    {renderPricedAddOns()}

                    <SummaryEntry
                        entryText={ 'Sub-Total'}
                        entryPrice={ pricingSummary.totalPrice.toFixed(2) + ' ' + currencyLabel }
                    />

                    <SummaryEntry
                        entryText={ `${taxLabel} (${taxRate}%)`}
                        entryPrice={ pricingSummary.taxValue.toFixed(2) + ' ' + currencyLabel }
                    />

                    <SummaryEntry
                         hasBorder={false}
                        highlighted={true}
                        entryText={ 'Today\'s Total'}
                        entryPrice={ renderTodaysTotalPrice() }
                    />

                    { renderTotalRecurringSummaryEntry() }

                </Grid>
                <Grid
                    container
                    className={classes.planFormContainer}
                    >
                        <form
                            ref={chargifyFormRef}
                            className={classes.chargifyFields}
                        >
                            <div id='chargifyPanel' className={classes.formContent}> </div>
                            <input type="hidden" data-chargify="address" value={chargifyInput.address}/>
                            <input type="hidden" data-chargify="address2" value={chargifyInput.address2}/>
                            <input type="hidden" data-chargify="city" value={chargifyInput.city}/>
                            <input type="hidden" data-chargify="state" value={chargifyInput.state}/>
                            <input type="hidden" data-chargify="country" value={chargifyInput.country}/>
                            <input type="hidden" data-chargify="zip" value={chargifyInput.zip}/>
                        </form>
                </Grid>

                <Grid className={classes.planFormContainer}>
                    <TextField
                        id="address"
                        label="Address"
                        onChange={(e) => {
                            updateChargifyInput({ address: e.target.value });
                        }}
                        name="address"
                        value={chargifyInput.address}
                        variant="outlined"

                        className={classes.formInput}
                        helperText={errors.address || 'Address'}
                        error={errors.address}
                    />

                    <TextField
                        id="address"
                        label="City"
                        onChange={(e) => {
                            updateChargifyInput({ city: e.target.value });
                        }}
                        name="city"
                        value={chargifyInput.city}
                        variant="outlined"
                        className={classes.formInput}
                        helperText={errors.city || 'City'}
                        error={errors.city}
                    />

                    <Grid className={classes.formInput} >
                        <Select
                            id='country'
                            label= 'Country'
                            name= 'country'
                            value={chargifyInput.country}
                            labelHeading= 'Country'
                            placeHolder= 'Country'
                            handleChange={(e, data) => {
                                logInfo('Select Country: ', data.props.value);
                                updateStates(data.props.value);
                                updateChargifyInput({ country: data.props.value });
                            }}
                            marginBottom={0}
                            dataForDropdown={countries}
                            helperText={errors.country || 'Country'}
                            error={errors.country}
                        />
                    </Grid>

                    <Grid className={classes.formInput} >
                        <Select
                            id='state'
                            label= 'State'
                            name= 'state'
                            value={chargifyInput.state}
                            labelHeading= 'State'
                            placeHolder= 'State'
                            handleChange={(e, data) => {
                                logInfo('Select State: ', data.props.value);
                                updateChargifyInput({ state: data.props.value });
                            }}
                            marginBottom={0}
                            dataForDropdown={states}
                            helperText={errors.state || 'State'}
                            error={errors.country}
                        />
                    </Grid>

                    <TextField
                        id="zip"
                        label="Zip"
                        onChange={(e) => {
                            updateChargifyInput({ zip: e.target.value });
                        }}
                        name="zip"
                        variant="outlined"
                        value={chargifyInput.zip}
                        helperText={errors.zip || 'Zip'}
                        error={errors.zip}
                        className={classes.formInput}
                    />

                </Grid>

            </Grid>
        </React.Fragment>
    );
}

BillingChargifyForm.propTypes = {
    billingInputProps: PropTypes.object,
};

BillingChargifyForm.defaultProps = {
    billingInputProps: {},
};

export default BillingChargifyForm;

/*
 <SummaryEntry
                        entryText={ `Total Recurring with GST (${taxRate}%)`}
                        entryPrice={ pricingSummary.grandTotalRecurring.toFixed(2) + ' ' + currencyLabel }
                    />
*/
