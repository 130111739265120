import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
} from '@material-ui/core';
import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import Dialog from '../../../commons/dialog/Dialog';
import Widget from '../../../components/Widget';

import { useStyles } from './styles';

import { useHistory } from 'react-router';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

import {
  useLabelsStore,
} from '../../../store/models';

function CustomLabelsList (props) {
    const {
        listData,
        onAdd,
        showProgress,
    } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(false);
    const classes = useStyles();

    const labelStore = useLabelsStore();

    useEffect(() => {
      setLoading(showProgress);
    }, [showProgress]);

    return (
    <React.Fragment>
      <Grid item xs={12}>
        <Widget className={classes.widgetContainer}
            title="Manage Custom Labels"
            inheritHeight
            disableWidgetMenu
            handleAdd={onAdd}
            >
            <Box
                justifyContent={'space-between'}
                display={'flex'}
                alignItems={'flex-start'}
            >
                <Typography variant='body2'>
                  You can create custom text descriptions on your displays, to provide users with more meaning about your intended service (Introduction Label) and your privacy terms (Privacy Terms Label).
                </Typography>
            </Box>
        </Widget>

        <TableContainer
              classes={classes}
              tableData={listData}
              rowsPerPage={listData.length > 10 ? listData.length : 10}
              columns={columns}
              customCells={customCells}
              tableTitle='Custom Labels'
              moduleType='custom labels'
              showProgress={loading}
              options={options}

              onEdit={(data) => {
                labelStore.labelForEdit = data;
                labelStore.saveLabelForEdit();
                history.push('/admin/marketing/custom-labels/edit/' + data.id);
              }}

              onDelete={(data) => {
                console.log('Data: ', data);
                setDeleteTarget(data);
                setDeleteOpen(true);
              }}
            />

      </Grid>

      <Dialog
        openDialog={deleteOpen}
        handleAgree={() => {
          setDeleteOpen(false);
          labelStore.deleteLabel({ ...deleteTarget });
        }}

        closeModal={() => {
          setDeleteOpen(false);
        }}
        title={'Delete opt-in'}
        dialogContextText={'Are you sure you want to delete this custom-label?'}
      />
    </React.Fragment>
  );
}

CustomLabelsList.propTypes = {
    listData: PropTypes.array,
    onAdd: PropTypes.func,
    showProgress: PropTypes.bool,
};

CustomLabelsList.default = {
    listData: [],
    onAdd: () => {},
    showProgress: true,
};

export default CustomLabelsList;
