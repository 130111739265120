import { useRef, useEffect } from 'react';
import {
    InputPatterns,
} from '../../utils/validations';

function useTextKeyLimit (props) {
    const {
        includedPattern = InputPatterns.name,
        backspaceEnabled = true,
        pasteEnabled = true,
        charLimit = 1000,
    } = props;
    const textRef = useRef();

    useEffect(() => {
        return () => {
            if (textRef.current) {
                cleanListeners();
            }
        };
    }, []);

    useEffect(() => {
        if (textRef.current) {
            addListeners();
        }
    }, [textRef.current]);

    function onKeyDown (e) {
        if (backspaceEnabled && e.keyCode === 8) {
            return true;
        }

        if (e.keyCode === 16) { // shifts
            return true;
        }

        if (e.target.value.length >= charLimit) {
            e.preventDefault();
            return false;
        }

        const result = includedPattern.test(e.key);
        if (result) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function onPaste (e) {
        const clipboardData = e.clipboardData || window.clipboardData;
        const text = clipboardData.getData('Text');
        const currentText = e.target.value;
        const currentLength = currentText.length;
        const limit = charLimit - currentLength;

        if (text.length >= limit) {
            // only paste the additional thing at the end..
           // e.preventDefault();
        }
        // do checks here...
        // check the limit cut the limit
        if (pasteEnabled) {
            return;
        }
         e.preventDefault();
    }

    function addListeners () {
        textRef.current.addEventListener('keydown', onKeyDown);
        textRef.current.addEventListener('paste', onPaste);
    }

    function cleanListeners () {
        textRef.current.removeEventListener('keyDown', onKeyDown);
        textRef.current.removeEventListener('paste', onPaste);
    }

    return {
        textRef,
        onKeyDown,
        onPaste,
    };
}

export default useTextKeyLimit;
