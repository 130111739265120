import { useEffect, useState } from 'react';

import {
    formattedDateNow,
} from '../../../commons/util/date';

import {
    useSchedulesStore,
    useSubscribersStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import useRemoteSearch from '../../../commons/ecal/selectsearch/useRemoteSearch';

const ALL_SCHEDULES = {
    value: '-',
    label: 'All Schedules',
};

function useFilterInput () {
   // const subscribersStore = useSubscribersStore();
    const schedulesStore = useSchedulesStore();
    const subscribersStore = useSubscribersStore();

   // const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [reportDisabled, setReportDisabled] = useState(true); // setReportDisabled...
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [subscribers, setSubscribers] = useState([]);

    const {
        dataProvider: schedules,
        updateProvider,
        setSelected: setRemoteSelected,
    } = useRemoteSearch();

    const [input, setInput] = useState({
        startDate: formattedDateNow(),
        endDate: formattedDateNow(),
        dateStartDate: new Date(),
        dateEndDate: new Date(),
        schedules: [ALL_SCHEDULES.value],
    });

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (subscribersStore.schedules) {
            updateProvider(subscribersStore.schedules);
        }
    }, [subscribersStore.observed.schedules]);

    useEffect(() => {
        if (subscribersStore.observed.subscribersData) {
            if (subscribersStore.subscribersData.length > 0) {
                setReportDisabled(false);
            } else {
                setReportDisabled(true);
            }
            setIsLoading(false);
            setDynamicColumns([...subscribersStore.subscribersColumns]);
            setSubscribers([...subscribersStore.subscribersData]);
        }
    }, [subscribersStore.observed.subscribersData]);

    useEffect(() => {
        if (subscribersStore.observed.csvDownloaded) {
            setIsLoadingReport(false);
        }
    }, [subscribersStore.observed.csvDownloaded]);

    function updateInput (newInput) {
        setInput(newInput);
    };

    function updateScheduleSelection (data) {
        console.log('Schedule Data: ', data);

        const schedules = [
            ...data,
        ];

        setRemoteSelected(schedules);

        updateInput({
            ...input,
            schedules,
        });
    }

    async function searchSchedule (search) {
        const searchedSchedules = await schedulesStore.searchSchedules({
            name: search,
            draftStatus: 'live',
            renderSearchResult: false,
        });
        console.log('Search Schedules: ', searchedSchedules);
        updateProvider(searchedSchedules);
    }

    function generateData () {
        setIsLoading(true);
        subscribersStore.getSubscribersData({
            scheduleIds: input.schedules,
            startDate: input.dateStartDate,
            endDate: input.dateEndDate,
        });
    }

    function generateReport () {
        setIsLoadingReport(true);
        subscribersStore.getSubscribersReport({
            scheduleIds: input.schedules,
            startDate: input.dateStartDate,
            endDate: input.dateEndDate,
        });
    }

    function initStore () {
        schedulesStore.getSchedulesForSubscribersFilter();
    }

    useStoreModuleDependency({ initStore });

    return {
        input,
        updateInput,
        schedules,
        updateScheduleSelection,
        searchSchedule,
        generateData,
        generateReport,
        isLoading,
        isLoadingReport,
        setIsLoading,
        setIsLoadingReport,
        reportDisabled,
        dynamicColumns,
        subscribers,
    };
}

export default useFilterInput;
