import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Box,
} from '@material-ui/core';
import { Typography, Button } from '../../../../components/Wrappers';
import TableContainer from '../../../../commons/ECALTable/TableContainer';

import Dialog from '../../../../commons/dialog/Dialog';
import ButtonProgress from '../../../../commons/ButtonProgress';

import useStyles from './styles';

import {
    columns,
    customCells,
    options,
} from './tableSettings';

import CustomFieldDialog from './customFieldDialog/';
import useSubscriberData from './useSubscriberData';
import useCustomFieldDialog from './customFieldDialog/useCustomFieldDialog';

// const WHATS_THIS = 'What\'s this?';
function SubscriberData (props) {
    const classes = useStyles();
    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
    } = props;

    const {
        tableData,
        onTableStateChange,
        mode,
        setMode,
        customFieldDataSource,
        updateCustomFieldSource,
        upsertCustomField,
        deleteCustomField,
        setDeleteTarget,
    } = useSubscriberData({
        dataSource,
        onDataChange,
        updateSavable,
    });

    const {
        openCustomField,
        setOpenCustomField,
        deleteOpen,
        setDeleteOpen,
    } = useCustomFieldDialog({ ...props, mode });

    return (
        <React.Fragment>
            <Grid item className={classes.mainContainer} justify={'center'} container>
                <Grid className={classes.titleBase}>
                    <Typography
                        variant={'h5'}
                        block
                        weight={'medium'}
                        style={{ marginBottom: 30 }}
                    >
                    Subscriber Data
                    </Typography>
                </Grid>

                <Grid className={classes.panelContent}>
                    <Grid className={classes.instructions}>
                        <Typography variant={'body1'} weight='bold' >
                            What information would you like the user to complete to subscribe to yours schedules?
                        </Typography>
                        <Typography
                            block
                            className={classes.instructionsMessage}
                        >
                            Please note, email is an essential field.<br/>
                            For GDPR compliance, fields should be essential for the purpose.
                            {/* With ECAL you can control the specific information that a user must complete in order to subscribe to your Schedules.
                        Here you can specify exactly what information a subscriber must complete when using this widget. <br/><br/>
                        At minimum subscribers must always provide their email address.
                            We always recommend you keep the amount of information you request to a minimum as this has been shown to improve your subscription rate.<br/><br/> */}
                        </Typography>
                    </Grid>

                    <Grid className={classes.tableContent}>
                        <TableContainer
                            hasRowSelection={false}
                            className={classes.tableBase}
                            isInsideWidget={false}
                            classes={classes}
                            tableData={tableData}
                            rowsPerPage={tableData.length}
                            moduleType='subscribers'

                            columns={columns}
                            customCells={customCells}
                            showProgress={false}
                            options={options}

                            onTableStateChange={onTableStateChange}

                            onDeleteCustomField={(data) => {
                                setDeleteTarget(data);
                                setDeleteOpen(true);
                            }}

                            onEditCustomField={(data) => {
                                console.log('Edit Custom Field', data);
                                updateCustomFieldSource(data);
                                setMode('edit');
                                setOpenCustomField(true);
                            }}

                            />
                            <Grid className={classes.tableAddCustom}>
                                <Button
                                    onClick= { (e) => {
                                        e.preventDefault();
                                        updateCustomFieldSource(null);
                                        setMode('add');
                                        setOpenCustomField(true);
                                    }}
                                    variant={'text'}
                                    color={'primary'}
                                >
                                    Add Custom Field
                                </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Box
                    mt={'40px'}
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>

            <CustomFieldDialog
                open={openCustomField}
                onSaveCustomField={(data) => {
                    upsertCustomField(data);
                    setOpenCustomField(false);
                }}
                onClose={() => setOpenCustomField(false)}
                customFieldDataSource={customFieldDataSource}

            />

            <Dialog
                openDialog={deleteOpen}

                handleAgree={() => {
                    setDeleteOpen(false);
                    deleteCustomField();
                }}

                closeModal={() => {
                   setDeleteOpen(false);
                }}

                title={'Delete Custom Field'}
                dialogContextText={'Are you sure you want to delete this field? Deleting this field will also delete all values associated with this field?'}
            />

        </React.Fragment>
    );
};

SubscriberData.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    hasError: PropTypes.bool,
    hasInputError: PropTypes.bool,
    errors: PropTypes.object,
};

SubscriberData.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    hasError: false,
    hasInputError: false,
    errors: {},
};

export default SubscriberData;
