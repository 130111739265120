import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {

        '& .MuiDialog-paper': {
            backgroundColor: 'rgba(0,0,0,0) !important',
            boxShadow: '0px 0px rgba(0,0,0,0) !important',
            overflow: 'visible',
        },

    },

    progressBox: {
        overflow: 'visible',
    },

});

export default styles;

export const useStyles = makeStyles(styles);
