import { useState, useEffect } from 'react';

import {
    DefaultStep,
    stepConditions,
} from './utils';

function useStep (props) {
    const {
        input,
        events,
        spreadsheetFile,
    } = props;

    const [buttons, setButtonsState] = useState({
        saveDisabled: true,
        backDisabled: true,
        nextDisabled: true,
    });
    const [stepInfo, setStepInfo] = useState(DefaultStep);

    function validateStep01 () {
        setButtonsState({
            ...buttons,
            nextDisabled: !stepConditions.step1(input, events),
        });
    }

    function validateStep02 () {
        setButtonsState({
            ...buttons,
            nextDisabled: !stepConditions.step2(input, spreadsheetFile),
        });
    }

    function validateStep03 () {
        console.log('validating step 03');
        setButtonsState({
            ...buttons,
            saveDisabled: !stepConditions.step3(input, events),
        });
    }

    const validateMap = [
        validateStep01,
        validateStep02,
        validateStep03,
    ];

    useEffect(() => {
        const validator = validateMap[stepInfo.activeStep];
        if (typeof validator === 'function') {
            validator();
        }
    }, [input, events, stepInfo.activeStep]);

    function next () {
        let activeStep = stepInfo.activeStep + 1;
        if (activeStep >= 2) {
            activeStep = 2;
        }

        setButtonsState({
            ...buttons,
            backDisabled: false,
        });

        setStepInfo({
            ...stepInfo,
            activeStep,
        });
    }

    function back () {
        let activeStep = stepInfo.activeStep - 1;
        if (activeStep <= 0) {
            activeStep = 0;
            setButtonsState({
                ...buttons,
                backDisabled: true,
            });
        } else {
            setButtonsState({
                ...buttons,
                backDisabled: false,
            });
        }

        setStepInfo({
            ...stepInfo,
            activeStep,
        });
    }

    function retry () {
        const activeStep = 1;
        setButtonsState({
            ...buttons,
            backDisabled: false,
            nextDisabled: true,
        });

        setStepInfo({
            ...stepInfo,
            activeStep,
        });
    }

    return {
        buttons,
        next,
        back,
        retry,
        stepInfo,
    };
}

export default useStep;
