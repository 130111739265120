import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import Dialog from '../../../../../commons/dialog/Dialog';
import Select from '../../../../../commons/select/Select';

import { useStyles } from './styles';

import {
    customFieldTypes,
    dynamicForms,
    customFieldDataSchema as newCustomFieldData,
    defaultValueForType,
} from './customFieldSettings';
function CustomFieldDialog (props) {
    const {
        onClose,
        onSaveCustomField,
        open,
        title,
        customFieldDataSource,
       // onTableStateChange,
    } = props;

    const [selectedType, setSelectedType] = useState(customFieldDataSource ? customFieldDataSource.type : customFieldTypes[0].value);
    const [isTouched, setIsTouched] = useState(false);
    const [customFieldData, setCustomFieldData] = useState(customFieldDataSource || { ...newCustomFieldData });

    const classes = useStyles();

    useEffect(() => {
        console.log('');
        console.log('xxxxx');
        console.log('customFieldDialog.userEffect().customFieldDataSource():: ', customFieldDataSource);
        if (customFieldDataSource) {
            setCustomFieldData(customFieldDataSource);
            setSelectedType(customFieldDataSource.type);
        } else {
            console.log('setting a default custom Field: ', newCustomFieldData);
            setCustomFieldData({ ...newCustomFieldData });
            setSelectedType(customFieldTypes[0].value);
        }
    }, [customFieldDataSource]);

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('useEffect().selectedType() -> ', selectedType);
        // setCustomFieldData(customFieldDataSource || { ...newCustomFieldData });
        const source = customFieldDataSource || { ...newCustomFieldData };

        if (isTouched) {
            const newNameValue = defaultValueForType(selectedType);
            setCustomFieldData({
                ...source,
                ...newNameValue,
                type: selectedType,
            });
        } else {
            setCustomFieldData({
                ...source,
                type: selectedType,
            });
        }
    }, [selectedType]);

    function renderFieldForm () {
        const FormComponent = dynamicForms[selectedType];
        if (FormComponent) {
            return (
                <FormComponent
                    classes={classes}
                    customFieldData={customFieldData}
                    setCustomFieldData={setCustomFieldData}
                    {...props}
                />
            );
        }
    }

    return (<Dialog
        openDialog={open}
        buttonSecondaryFlag={true}
        buttonPrimary="Save"
        buttonSecondary="Cancel"

        handleAgree={() => {
            onSaveCustomField(customFieldData);
        }}

        closeModal={() => {
            onClose(false);
        }}
        title={title}
        dialogContextText={''}
        className={classes.dialogContent}
      >
          <Grid>
                <Grid>
                    <Select
                        id='name'
                        name= 'name'
                        value={selectedType}

                        handleChange={(e, data) => {
                            setIsTouched(true);
                            console.log(' handle Selected type: ', data);
                            setSelectedType(data.props.value);
                            /*
                            setCustomFieldData({
                                ...customFieldData,
                                type: data.props.value,
                            });
                            */
                        }}

                        dataForDropdown={customFieldTypes}
                        labelHeading={'Custom Field Type'}
                        helperText={'Select custom field type.'}
                    />
                </Grid>
                { renderFieldForm() }
          </Grid>
    </Dialog>
    );
}

CustomFieldDialog.propTypes = {

    onTableStateChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveCustomField: PropTypes.func,
    secretKey: PropTypes.string,
    open: PropTypes.bool,
    appName: PropTypes.string,

    title: PropTypes.string,
    message: PropTypes.string,
    okLabel: PropTypes.string,
    customFieldDataSource: PropTypes.any,
};

CustomFieldDialog.defaultProps = {
    title: 'Custom Field',
    okLabel: 'Deactivate',
    onClose: () => {},
    onSaveCustomField: () => {},
    onTableStateChange: () => {},
    appName: '',
    open: false,
    customFieldDataSource: null,
};

export default CustomFieldDialog;
