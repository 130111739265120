import { v4 as uuid } from 'uuid';

import {
    apiGetLabels,
    apiAddLabel,
    apiEditLabel,
    apiDeleteLabel,
} from './api';

export function organizeLabels () {

}

export async function getLabels () {
    console.log('');
    console.log('loading labels...');
    this.env.errorStore.action = 'getLabels';
    this.observed.labelsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiGetLabels({
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data && res.data.data) {
        this.labels = res.data.data;

        this.observed.labels = uuid();
    } else {
        // handle error.
    }
    this.observed.labelsLoading = false;
}

export async function addLabel (label) {
    this.env.errorStore.action = 'addLabel';
    const publisher = this.env.userStore.publisher;

   /* We need this because the select component treat empty value '' as negative  */
   if (label.language === 'default') {
        label.language = '';
   }

    if (!label.ordinal) {
        label.ordinal = 1;
    }
    const res = await apiAddLabel({
        body: { ...label },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });
    console.log('Add Label Complete: ', res);
    this.env.history.push('/admin/marketing/custom-labels');
}

export async function editLabel (label) {
    this.env.errorStore.action = 'editLabel';
    const publisher = this.env.userStore.publisher;

    if (label.language === 'default') {
        label.language = '';
    }

    const res = await apiEditLabel({
        body: { ...label },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });

    console.log('Custom Label Edit Success: ', res);

    this.env.history.push('/admin/marketing/custom-labels');
}

export async function deleteLabel (label) {
    this.env.errorStore.action = 'deleteLabel';
    this.observed.labelsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiDeleteLabel({
        body: { ...label },
        options: {
            token: publisher.token,
            traceId: this.env.traceId,
        },
    });
    console.log('Delete Label Complete: ', res);
    this.getLabels();
}
