import EcalBackground from '../../../../commons/images/brandImage/ECALWhiteLabel2.jpg';

import EcalLogo from '../../../../commons/images/brandImage/ECAL_Logo.svg';

export default {
    labelName: 'ecal',
    favicon: '/ecal_icon_purple_dark.svg',
    title: 'Admin ECAL',
    background: EcalBackground,
    footerColor: '#0c0644',
    headerColor: '#0c0644',
    logo: EcalLogo,
    copyrightText: `© 2010-${new Date().getFullYear()} | ECAL | HyperKu Pte Ltd | All rights reserved.`,
    supportContact: 'support@ecal.com',
    hideIntercomChat: false, /* hide_default_launcher */
    supportLabel: 'ECAL Support',
    sidebar: 'funUnlimited',

    autoLoginValue: 'embed',
};
