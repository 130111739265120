import React from 'react';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
  Box,
} from '@material-ui/core';
// import FilterIcon from '@material-ui/icons/FilterList';
import { Button } from '../../components/Wrappers';
import { makeStyles } from '@material-ui/core/styles';
import {
    // CreateOutlined as CreateIcon,
    VisibilityOutlined as MakeLiveIcon,
    VisibilityOffOutlined as MakeDraftIcon,
} from '@material-ui/icons';

const defaultToolbarSelectStyles = (theme) => ({
    iconButton: {
      marginRight: '4px',
      top: '50%',
      position: 'relative',
  },
});
const useStyles = makeStyles(defaultToolbarSelectStyles);

function CustomToolbarSelect (props) {
    const {
      onDeleteItems,
      onSetItemsLive,
      onSetItemsDraft,
      selectedRows,
    } = props;

    const classes = useStyles();

    return (
      <Box
        display= 'flex'
        className={'custom-toolbar-select'}>
        <Tooltip title={'Set live'}>
          <Button
            variant={'contained'}
            color={'success'}
            className={classes.iconButton}
            onClick={() => {
              if (onSetItemsLive) {
                onSetItemsLive(selectedRows);
              }
            }}
          >
            <MakeLiveIcon />&nbsp; Set Live
          </Button>
        </Tooltip>
        <Tooltip title={'Set draft'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            className={classes.iconButton}
            onClick={() => {
              if (onSetItemsDraft) {
                onSetItemsDraft(selectedRows);
              }
            }}
          >
            <MakeDraftIcon /> &nbsp; Set Draft
          </Button>
        </Tooltip>
        <Tooltip title={'Delete selected row(s)'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            style={{ backgroundColor: '#AB003C' }}
            className={classes.iconButton}
            onClick={() => {
              if (onDeleteItems) {
                onDeleteItems(selectedRows);
              }
            }}
            >
            <DeleteIcon className={classes.deleteIcon} />&nbsp; Delete
          </Button>
        </Tooltip>
      </Box>
    );
}

export default CustomToolbarSelect;
