import { useState, useEffect } from 'react';
import { AssetType } from '../../../../utils/constants';
import { logInfo } from '../../../../utils';

import { useButtonsStore, useUserStore } from '../../../../store/models';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import useImageUpload from '../../../hooks/useImageUpload';
import useImageRenderable from '../../../hooks/useImageRenderable';

function useOptions (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
    } = props;
    const buttonsStore = useButtonsStore();
    const userStore = useUserStore();

    const [optionData, setOptionDataState] = useState({ ...dataSource.settings });

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        setOptionData({ ...dataSource.settings });
    }, [dataSource]);

    function updatePublisherNamePlaceholder (publisher) {
      const getUpdatedValue = (data) => {
        return data.replace('[Publisher name]', publisher.company || publisher.name);
      };

      const hasValue = (data) => Object.keys(data).length;

      if (hasValue(optionData.sharing) && hasValue(publisher)) {
        const newSharingMessage = getUpdatedValue(optionData.sharing.message);
        const newTitle = getUpdatedValue(optionData.welcomeMessage.title);
        const newWelcomeMsg = getUpdatedValue(optionData.welcomeMessage.message);
        const updatedInput = {
          ...optionData,
            sharing: {
              ...optionData.sharing,
              message: newSharingMessage,
            },
            welcomeMessage: {
              ...optionData.welcomeMessage,
              title: newTitle,
              message: newWelcomeMsg,
            },
        };

        setOptionData(updatedInput);
      }
    }

    useEffect(() => {
        logInfo('message', optionData);
        const sharingMessage = optionData.sharing.message;
        const welcomeMessage = optionData.welcomeMessage.title;
        if (userStore.publisher && (sharingMessage.includes('[Publisher name]') || welcomeMessage.includes('[Publisher name]'))) {
            updatePublisherNamePlaceholder(userStore.publisher);
        }
    }, [optionData.sharing, userStore.publisher]);

    function setOptionData (data) {
        setOptionDataState(data);
        updateSavable({
            ...dataSource,
            settings: { ...data },
        });
    }

    function setAdsLogoURL (url) {
        setOptionData({
            ...optionData,
            advertising: {
                ...optionData.advertising,
                banner: url,
            },
        });
    };

    const {
        image: adsBanner,
    } = useImageRenderable({
        imageSource: optionData.advertising.banner,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: adsLogo,
        upload: uploadAdsLogo,
        deleteImage: deleteAdsLogo,
        isUploading,
        imageError: adsLogoError,
    } = useImageUpload({
        assetType: AssetType.BUTTON_AD_UNIT,
        observableDataSource: buttonsStore.observed.assets[AssetType.BUTTON_AD_UNIT],
        consumableDataSource: buttonsStore.assets[AssetType.BUTTON_AD_UNIT],
        setStateFunc: setAdsLogoURL,
        customDefaultImage: adsBanner,
        shouldCheckFilesize: true,
        uploadMethod: 'uploadWidgetAssets',
        sizeLimit: 1024,
    });

    return {
        isUploading,
        adsLogo,
        uploadAdsLogo,
        deleteAdsLogo,
        optionData,
        setOptionData,
        adsLogoError,
    };
}

export default useOptions;
