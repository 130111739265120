import { useState, useEffect } from 'react';
import { useUserStore } from '../../../store/models';
import useAuthError from '../../../store/models/user/errors/useAuthError';
import useConfirmationDialog from '../../../commons/dialog/useConfirmationDialog';

import usePasswordValidation from '../../hooks/usePasswordValidation';

function useProfilePasswordChange (props) {
    const {
        passData,
        onDataChange,
        onSavePassword,
    } = props;

    const userStore = useUserStore();
    const [data, setData] = useState(passData);
    const [isLoading, setIsLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRetypePassword, setShowRetypePassword] = useState(false);

    const { changePasswordError } = usePasswordValidation({ changePasswordInput: data });

    const [dialogAttr] = useState({
        title: '',
        message: '',
    });

    const {
        renderDialog,
        openDialog,
    } = useConfirmationDialog({
        onConfirm: () => {
            setData({
                oldPassword: '',
                newPassword: '',
                rePass: '',
            });
        },
        showSecondaryButton: false,
        title: dialogAttr.title,
        message: dialogAttr.message,
    });

    const {
        renderErrorDialog,
        errors,
    } = useAuthError({
        shouldRedirect: false,
    });

    useEffect(() => {
        setData(passData);
    }, [passData]);

    useEffect(() => {
        if (typeof onDataChange === 'function') {
            onDataChange(data);
        }

        if (!data.oldPassword || !data.rePass || !data.newPassword) {
            setSaveDisabled(true);
            return;
        }

        if (data.rePass === data.newPassword) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [data]);

    useEffect(() => {
        if (userStore.observed.changePasswordOk && data.oldPassword && data.rePass && data.newPassword) {
            setIsLoading(false);
            openDialog('Change password success', 'Success! Your password has been updated.');
        }
    }, [userStore.observed.changePasswordOk]);

    useEffect(() => {
        setIsLoading(false);
    }, [errors]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }

    function savePassword () {
        setIsLoading(true);
        onSavePassword(data);
    }

    return {
        handleChange,
        data,
        showOldPassword,
        setShowOldPassword,
        showNewPassword,
        setShowNewPassword,
        showRetypePassword,
        setShowRetypePassword,
        saveDisabled,
        isLoading,
        renderErrorDialog,
        renderDialog,
        savePassword,

        changePasswordError,
    };
}

export default useProfilePasswordChange;
