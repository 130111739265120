import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({

    margin: {
        marginBottom: 24,
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `${theme.spacing(1)}px !important`,
    },
    calendarIcon: {
        color: theme.palette.primary.main,
        marginRight: 14,
    },
    date: {
        marginRight: 38,
        color: theme.palette.type === 'dark' ? '#D6D6D6' : '#4A494A',
    },
    button: {
        boxShadow: theme.customShadows.widget,
        textTransform: 'none',
        '&:active': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
}));
