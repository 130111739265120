const tabMapping = {
    profile: 0,
    account: 1,
    billing: 2,
};

export function getTabByPath (path) {
    console.log('location: ', path);
    if (!path) {
        return 0;
    }
    let tab = 0;
    Object.keys(tabMapping).forEach((key) => {
        if (path.indexOf(key) !== -1) {
            tab = tabMapping[key];
        }
    });
    return tab;
}
