import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './pillMonoDark.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */
const DEFAULT_ICON_SIZE = 44;
const PillMonoDarkSVG = (props) => {
	const classes = useStyles();
	const {
		width,
		text,
		// iconSize,
		onTextMatrixChange,
	} = props;
	const textRef = useRef();
	const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 115 28.5142)');

	useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

	useEffect(() => {
		if (textRef.current) {
			const text = textRef.current;
			const tbox = text.getBBox();
			const newX = (118 - tbox.width / 2);
			// const newY = ((iconSize - tbox.height) / 2);
			const translation = 'matrix(1 0 0 1 ' + newX + ' 28.5142)';
			setTransformMatrix(translation);
		}
	}, [textRef.current, width, text]);
	return (
		<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
			viewBox='0 0 204 48' style={{ 'enable-background': 'new 0 0 204 48' }} xmlSpace='preserve'>

			<path className={classes.st0} d='M24,0.4H180c13,0,23.5,10.5,23.5,23.5l0,0c0,13-10.5,23.5-23.5,23.5H24c-13,0-23.5-10.5-23.5-23.5l0,0
	   C0.5,10.9,11,0.4,24,0.4z'/>
			<circle className={classes.st1} cx='24' cy='23.9' r='20' />
			<rect x='55.9' y='17.5' className={classes.st2} width='129.1' height='13.5' />
			<text ref={textRef} transform={transformMatrix} className={classNames([classes.st3, classes.st4, classes.st5])}>{props.text}</text>
			<g>
				<defs>
					<rect id='SVGID_1_' x='8.8' y='14' width='26.9' height='19.1' />
				</defs>
				<clipPath id='SVGID_2_'>
					<use xlinkHref='#SVGID_1_' style={{ 'overflow': 'visible' }} />
				</clipPath>
				<g className={classes.st6}>
					<path className={classes.st3} d='M22.7,16.9h-6.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
			   c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h6.5
			   c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4
			   c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C22.9,16.9,22.8,16.9,22.7,16.9z'/>
					<path className={classes.st3} d='M34.8,14H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
			   c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h16.6
			   c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C35.2,14.1,35,14,34.8,14z'/>
					<path className={classes.st3} d='M31.3,31.2H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
			   c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h13.1
			   c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C31.8,31.3,31.5,31.2,31.3,31.2z'/>
					<path className={classes.st3} d='M22.5,25.5h-6.6c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3
			   h6.6c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4
			   c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C22.7,25.5,22.6,25.5,22.5,25.5z'/>
					<path className={classes.st3} d='M24.2,19.7H11.6c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.3,0.7
			   c0.2,0.2,0.4,0.3,0.7,0.3h12.6c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.5-0.3-0.7
			   C24.7,19.8,24.4,19.7,24.2,19.7z'/>
					<path className={classes.st3} d='M28.6,22.6H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
			   c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h10.4
			   c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4
			   c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C28.9,22.6,28.7,22.6,28.6,22.6z'/>
					<path className={classes.st3} d='M20.2,28.4H9.7c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6
			   c0.2,0.2,0.4,0.3,0.6,0.3h10.5c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6
			   C20.7,28.5,20.5,28.4,20.2,28.4z'/>
				</g>
			</g>
		</svg>

	);
};

PillMonoDarkSVG.propTypes = {
	width: PropTypes.number,
	text: PropTypes.string,
	iconSize: PropTypes.number,
	onTextMatrixChange: PropTypes.func,
};

PillMonoDarkSVG.defaultProps = {
	width: 250,
	text: 'Sync to Calendar',
	iconSize: DEFAULT_ICON_SIZE,
	onTextMatrixChange: () => {},
};

export default PillMonoDarkSVG;
