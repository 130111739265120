import { useEffect, useState } from 'react';
// import { cloneDeep } from 'lodash';
// import { useHistory } from 'react-router';
// import { v4 as uuid } from 'uuid';
import {
    useButtonsStore,
    useSchedulesStore,
    // useUserStore,
    useAPIKeyStore,
    useEnvStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import useButtonWidgetErrors from '../../../store/models/buttons/errors/useButtonWidgetErrors';

import useModuleError from './useModuleError';
import defaultInput from './defaultEventDisplay.json';

const Mode = {
    ADD: 'add',
    EDIT: 'edit',
};

const DisplayTypes = {
   EVENT: 'eventV2',
};

function useModuleInput (props) {
    const {
        location,
        setTabDisabled,
    } = props;

    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return Mode.EDIT;
        }
        return Mode.ADD;
    }

    function getDisplayType () {
        return DisplayTypes.EVENT;
    }

    const [inputErrors, setInputErrors] = useState({});
    const [mode] = useState(getMode());
    const [displayType] = useState(getDisplayType());
    const [input, setInput] = useState({
        ...defaultInput,
    });

    const [touched, setTouched] = useState({
        details: false,
        design: false,
        schedules: false,
        subscriberData: false,
        options: false,
    });

    /*
     * savable state is a state that will not be observe by the UI.
     * a replica of input state but will be updated every time the changes happened...
     * Changes on the savable state will not be rendered. for faster performance.
     * will be validated for an error..
     */
    const [savable, setSavableData] = useState({
        ...defaultInput,
    });

    const [saveDisabled, setSaveDisabled] = useState(true);
    const [progress, setProgress] = useState({
        save: false,
    });

    const [messageOpen, setMessageOpen] = useState(false);
    const {
        // errors,
        hasError,
        hasInputError,
        closeError,
        errorMessage,
        errorTitle,
        validateInput,
    } = useModuleError({
        progress,
        setProgress,
        input,
        mode,
    });

    const {
      errors,
  } = useButtonWidgetErrors({
      progress,
      setProgress,
      input: savable,
      mode,
  });

    const buttonsStore = useButtonsStore();
    const schedulesStore = useSchedulesStore();
    // const userStore = useUserStore();
    const apikeyStore = useAPIKeyStore();
    const envStore = useEnvStore();

    const [APIKey, setAPIKey] = useState('');
    const [traceId, setTraceId] = useState('');

    function initStore () {
        setProgress({ ...progress, buttons: true });
        schedulesStore.filterText = '';
        apikeyStore.getAPIKeys();
        envStore.getAPIV3Location(APIKey, traceId);
        schedulesStore.getRootCategories();
        if (mode === Mode.EDIT) {
            initWithEditDataSource();
        }
    }

    function initWithEditDataSource () {
        buttonsStore.loadEventDisplayData();
        const source = buttonsStore.eventDisplayData;
        source.display.categoryInfo = schedulesStore.getCategoryInfo(source.display.categories);
        setInput(source);
        setSavableData(source);
        window.savableData = { ...source };
    }

    useEffect(() => {
       if (savable) {
        setTabDisabled(false);
       }
    }, [savable]);

    useEffect(() => {
        if (input) {
            setTabDisabled(false);
           }
    }, [input]);

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        window.savableData = { ...savable };
    }, [savable]);

    useEffect(() => {
        buttonsStore.assets = {}; // ensure that assets url is empty on launch..
    }, []);

    useEffect(() => {
        if (apikeyStore.apikeys && apikeyStore.apikeys.length > 0) {
            setAPIKey(apikeyStore.apikeys[0].id);
            // console.log('useEffect() -> useModuleInput apikeyStore.apikeys. -> ', apikeyStore.apikeys[0].id);
            getEnvStoreLocation();
            // console.log('useEffect() -> useModuleInput envStore -> ', envStore);
        }
    }, [apikeyStore.observed.apikeys]);

    useEffect(() => {
        if (envStore?.traceId) {
            setTraceId(envStore.traceId);
            // console.log('useEffect() -> useModuleInput envStore.traceId -> ', envStore.traceId);
        }
    }, [envStore.observed.traceId]);

    useEffect(() => {
        if (envStore.locationAPI?.timezone) {
            const locationAPITimeZone = envStore.locationAPI.timezone;
            console.log('useEffect() -> useModuleInput locationAPITimeZone -> ', locationAPITimeZone);
            setEventTimezone(locationAPITimeZone);
        }
    }, [envStore.locationAPI]);

    async function getEnvStoreLocation () {
        await envStore.getAPIV3Location(APIKey, traceId);
    }

    function setEventTimezone (apiTZ) {
        if (mode === Mode.ADD) {
            setInput({
                ...input,
                display: {
                    ...input.display,
                    timezone: apiTZ,
                },
            });
        }
    }

    function updateSavable (data) {
        clearTimeout(window.updateSavable);
        function delay () {
            setTabDisabled(true);
            setSavableData(data);
            window.savableData = { ...data };
            if (!data.name && data.name.length <= 0) {
                setSaveDisabled(true);
            } else {
                setSaveDisabled(false);
            }
            validateInput(data);
        }
        window.updateSavable = setTimeout(delay, 400); // debounce update till user input stopped in 400ms
    }

    /*
     * This will just copy the updated state to input to reflect the changes on the UIs
     * only being called during the unmounting
     */
    function copySavableToInput () {
        setInput({ ...window.savableData });
    }

    function sanitizedWidget () {
        const widget = {
            ...savable,
            type: displayType,
            draft: undefined,
            display: {
                ...savable.display,
                categories: undefined,
            },

            style: {
                ...savable.style,
                eventDisplay: {
                    ...savable.style.eventDisplay,
                    cssFile: undefined,
                },
            },
        };
        return widget;
    }

    function upsertEvents () {
        const widget = sanitizedWidget();
        if (mode === Mode.ADD) {
            buttonsStore.addWidget(widget);
        } else if (mode === Mode.EDIT) {
            buttonsStore.editWidget(widget);
        }
    }

    function upsert () {
        if (shouldUpsert()) {
            setProgress({ ...progress, save: true });
            upsertEvents();
        }
    }

    function shouldUpsert () {
        const widget = sanitizedWidget();
        return validateUpsertRule(widget, setInputErrors);
    }

    function updateTouch (data) {
        setTouched({
            ...touched,
            ...data,
        });
    }

    return {
        input,
        mode,

        upsert, // call add or edit

        progress,
        setProgress,

        hasError,
        errorTitle,
        errorMessage,
        inputErrors,
        setInputErrors,

        closeError,
        errors,
        hasInputError,
        messageOpen,
        setMessageOpen,
        saveDisabled,
        updateSavable,
        copySavableToInput,
        loadCategories: initStore,
        touched,
        updateTouch,
    };
}

function validateUpsertRule (savable, setInputErrors) {
    if (savable?.display?.scheduleOption === 2 &&
        savable?.display?.categoryInfo?.length <= 0) {
        setInputErrors({
            title: 'Category is required',
            message: 'Please select one or more category',
        });
        return false;
    }

    return true;
}

export default useModuleInput;
