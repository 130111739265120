import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';
import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import ImageUploader from '../../../../commons/imageUploader/ImageUploader';

const INSTRUCTION = 'If you are using a direct link, the pop-up will be displayed on a custom landing page.';
function LandingPage (props) {
    const classes = useStyles();

    const {
       landingPageBackground,
       landingPageLogo,
       deleteLandingPageLogo,
       uploadLandingPageLogo,
       uploadLandingPageBackground,
       deleteLandingPageBackground,
       landingPageLogoError,
       landingPageBackgroundError,
       isUploading = false,
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}>
                    <Grid className={classes.sectionDescription}>
                        <Typography variant='sub1'>
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid className={classes.sectionContent} >

                        <Grid className={classes.input}>

                            <Grid
                                className={classes.logoSpace}
                                data-intercom-target={'button-design-landing-page'}
                            >

                                <Grid className={classes.logoTitle}>
                                    <Typography
                                            variant={'body1'}
                                            // weight='medium'
                                        >
                                            Landing Page Logo
                                    </Typography>
                                </Grid>

                                <ImageUploader
                                    imageDataIntercomTarget='landing-page-logo'
                                    layoutDesign={'row'}
                                    hasCreate={true}
                                    hasDelete={true}
                                    imageData={landingPageLogo}
                                    createLabel={'Upload'}
                                    isUploading={isUploading}
                                    onImageSelected={(files) => {
                                        uploadLandingPageLogo(files);
                                    }}
                                    onImageDelete={() => {
                                      console.log('lp logo img deleted?');
                                      deleteLandingPageLogo();
                                    }}
                                    onImageRemove={() => { console.log('img removed?'); }}
                                />
                                { landingPageLogoError &&
                                    (
                                        <Grid>
                                            <Typography
                                                variant={'caption'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30, color: 'red' }}
                                            >
                                                {landingPageLogoError}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>

                        <Grid className={classes.input}>
                            <Grid className={classes.logoSpace}>

                                <Grid className={classes.logoTitle}>
                                    <Typography
                                            variant={'body1'}
                                            // weight='medium'
                                        >
                                            Landing Page Background
                                    </Typography>
                                </Grid>

                                <ImageUploader
                                    imageDataIntercomTarget='landing-page-bg'
                                    layoutDesign={'row'}
                                    hasCreate={true}
                                    hasDelete={true}
                                    imageData={landingPageBackground}
                                    createLabel={'Upload'}
                                    onImageSelected={(files) => {
                                        uploadLandingPageBackground(files);
                                    }}
                                    onImageDelete={() => {
                                      console.log('img deleted BG?');
                                      deleteLandingPageBackground();
                                    }}
                                    isUploading={isUploading}
                                />
                                { landingPageBackgroundError &&
                                    (
                                        <Grid>
                                            <Typography
                                                variant={'caption'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30, color: 'red' }}
                                            >
                                                {landingPageBackgroundError}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>

                        </Grid>
                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

LandingPage.propTypes = {
    setStyleData: PropTypes.func,
    logoURL: PropTypes.any,
    styleData: PropTypes.any,

    landingPageLogo: PropTypes.any,
    landingPageBackground: PropTypes.any,
    uploadLandingPageLogo: PropTypes.func,
    uploadLandingPageBackground: PropTypes.func,
    landingPageLogoError: PropTypes.string,
    landingPageBackgroundError: PropTypes.string,
};

LandingPage.defaultProps = {
    setStyleData: () => {},
    styleData: {},
    uploadImage: () => {},
    logoURL: null,
    landingPageLogo: null,
    landingPageBackground: null,
    uploadLandingPageLogo: () => {},
    uploadLandingPageBackground: () => {},
    landingPageLogoError: '',
    landingPageBackgroundError: '',
};

export default LandingPage;
