import React from 'react';

import {
    Menu,
    MenuItem,
    Fab,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '../Wrappers/Wrappers';
import UserAvatar from '../UserAvatar/UserAvatar';

import {
    Send as SendIcon,
} from '@material-ui/icons';

// styles
import useStyles from './styles';

function MessageMenu (props) {
    const {
        onClose,
        anchorEl,
        open,
        messages,
    } = props;
    const classes = useStyles();

    return (
        <Menu
            id="mail-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={() => onClose(null)}
            MenuListProps={{ className: classes.headerMenuList }}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
        >
        <div className={classes.profileMenuUser}>

            <Typography variant="h4" weight="medium">
                New Messages
            </Typography>

            <Typography
                className={classes.profileMenuLink}
                color="secondary"
            >
                {messages.length} New Messages
            </Typography>

        </div>
        {messages.map(message => (
          <MenuItem key={message.id} className={classes.messageNotification}>
            <div className={classes.messageNotificationSide}>
              <UserAvatar color={message.variant} name={message.name} />
              <Typography size="sm" color="text" colorBrightness="secondary">
                {message.time}
              </Typography>
            </div>
            <div
              className={classNames(
                classes.messageNotificationSide,
                classes.messageNotificationBodySide
              )}
            >
              <Typography weight="medium" gutterBottom>
                {message.name}
              </Typography>
              <Typography color="text" colorBrightness="secondary">
                {message.message}
              </Typography>
            </div>
          </MenuItem>
        ))}
        <Fab
          variant="extended"
          color="primary"
          aria-label="Add"
          className={classes.sendMessageButton}
        >
          Send New Message
          <SendIcon className={classes.sendButtonIcon} />
        </Fab>
      </Menu>
    );
}

MessageMenu.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSelectNotification: PropTypes.func,
    anchorEl: PropTypes.any,
    messages: PropTypes.array,
};

MessageMenu.default = {
    open: false,
    onClose: () => {},
    onSelectNotification: () => {},
    anchorEl: null,
    messages: [],
};

export default MessageMenu;
