import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import PropTypes from 'prop-types';
import {
    Paper,
    Grid,
    Popper,
    MenuList,
    MenuItem,
    ClickAwayListener,
    Tooltip,
} from '@material-ui/core';

import {
    AccountTreeOutlined as AccountTreeIcon,
  } from '@material-ui/icons';

import useStyles from './styles';

const DEFAULT_WIDTH = 280;

function PopMenu (props) {
    const {
        selected,
        dataProvider,
        open,
        anchorEl,
        onClose,
        onSelect,
        onDelete,
        width,
        showProgress,
    } = props;

    const classes = useStyles({ width, DEFAULT_WIDTH });
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        if (open) {
           setCanClose(true);
        } else {
            setCanClose(false);
        }
    }, [open]);

    function isSelected (d) {
        const result = selected.filter((selectedValue) => (selectedValue === d.value));
        return result.length > 0;
    }

    function renderItemLabel (data) {
        if (data.isChild) {
            return (
                <>
                    <AccountTreeIcon
                        className={classes.accountTreeIconcon}
                    />
                    <span>{ data.label }</span>
                </>
            );
        }

        return (
            <>
                <span>{ data.label }</span>
            </>
        );
    };

    function renderItems () {
        if (showProgress) {
            return (
                <Grid className={classes.noData}>
                    Loading Data
                </Grid>
            );
        }

        if (!dataProvider || dataProvider.length <= 0) {
            return (
                <Grid className={classes.noData}>
                    No Data
                </Grid>
            );
        }

        return dataProvider.map((data) => {
            const itemSelected = isSelected(data);
            return (
                <Tooltip
                    title={data?.catNameRestJoinedLabel || ''}
                    key={data.value}
                    placement="top"
                    classes={{
                        tooltip: classes.tooltipStyle,
                    }}
                    arrow
                >
                    <div>
                        <MenuItem
                            key={uuid()}
                            disableAutoFocus={true}
                            disableEnforceFocus={true}
                            selected={itemSelected}
                            onClick={() => {
                                if (!itemSelected) {
                                    onSelect(data, [...selected, data.value]);
                                } else {
                                    onDelete(data.value);
                                }
                            }}
                        >
                            { renderItemLabel(data) }
                        </MenuItem>
                    </div>
                </Tooltip>
            );
        });
    }
    return (
        <ClickAwayListener
            onClickAway={() => {
                if (canClose) {
                    onClose();
                }
            }}
        >
        <Popper
            disableAutoFocus={true}
            disableEnforceFocus={true}

            id="long-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}

            keepMounted
            open={open}
            onClose={onClose}
        >
            <Paper
                className={classes.popPaper}
                >
                <Grid
                    className={classes.menuRoot}
                    style={{
                    width: width || DEFAULT_WIDTH,
                    }}
                >
                    <MenuList
                        className={classes.menuList}
                    >
                        { renderItems() }
                    </MenuList>
                </Grid>
            </Paper>
        </Popper>
        </ClickAwayListener>
    );
}

PopMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    width: PropTypes.any,
    showProgress: PropTypes.bool,
};

PopMenu.defaultProps = {
    open: false,
    dataProvider: [],
    selected: [],
    anchorEl: null,
    onClose: () => {},
    onSelect: () => {},
    onDelete: () => {},
    onPopOut: () => {},
    width: '280px',
    showProgress: true,
};

export default PopMenu;
