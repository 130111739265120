import { DEFAULT_IMAGE } from '../commons/imageUploader/DefaultEventBanner';
export const Roles = {
    PUBLISHER_ADMIN: 'pub-admin',
    SUPER_AMDIN: 'sup-admin',
    GROUP_ADMIN: 'group-admin',
    ADMIN: 'admin',
};

export const SignInSignUpState = {
    SIGNED_OUT: 'signedOut',
    VERIFYING_CREDENTIALS: 'verifyingCredentialss',

    LOGIN_SUCCESS: 'loginSuccess',
    IN_SESSION: 'inSession',
    SETUP_ACCOUNT: 'setupAccount',

    OTP_CONFIRMATION: 'otpConfirmation',
    OTP_CONFIRM_SUCCESS: 'otpConfirmSuccess',
    OTP_RESEND: 'otpResend',
    OTP_RESENDING: 'otpResending',
    OTP_CONFIRMING: 'otpConfirming',

    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',

    FEDERATED_SIGNIN_SUCCESS: 'federatedSignInSuccess',
    FEDERATED_SIGNIN_SETUP_COMPLETE: 'federatedSignInSetupComplete',
    SIGN_UP_INITIAL_STEP: 'signUpInitialStep',
    SIGN_UP_CONFIRMATION_PHASE: 'signupConfirmation',
    SIGN_UP_COMPLETE: 'signUpComplete',
};

export const RecoveryState = {
    INITIAL_STEP: 'INITIAL_STEP',
    GET_RECOVERY_CODE: 'getRecoveryCode',
    RECOVERY_CODE_SENT: 'recoveryCodeSent',
    VERIFY_RECOVERY_CODE: 'verifyRecoveryCode',
};

export const AuthType = {
    VERIFY_OTP: 'verifyOTP',
    CONFIRM_SIGN_IN: 'confirmSignIn',
};

export const AssetType = {
    CALENDAR_LOGO: 'scheduleLogo',
    EVENT_BANNER: 'eventBanner',
    SCHEDULE_BANNER: 'scheduleBanner',
    BUTTON_LOGO: 'buttonLogo',
    BUTTON_DIRECT_LINK: 'buttonDirectLink',
    BUTTON_BACKGROUND: 'buttonBackground',
    BUTTON_CSS: 'buttonCss',
    BUTTON_AD_UNIT: 'buttonAdUnit',
    BUTTON_AD_DISPLAY_IMAGE: 'buttonAdDisplayImage',
};

export const configButtonWidth = {
    BUTTON_MAX_WIDTH: 350,
    BUTTON_MIN_WIDTH: 150,
    BUTTON_ICON_MAX_WIDTH: 100,
    BUTTON_ICON_MIN_WIDTH: 10,

    EVENT_BANNER_WIDTH: 970,
    EVENT_BANNER_HEIGHT: 250,
};

export const defaultButtonWidth = {
    DEFAULT_BUTTON_WIDTH: 200,
    ICON_BUTTON_WIDTH: 64,
};

export function renderableImageFormat (url) {
    return [{ data_url: url }];
}

export function getScheduleTypes () {
    return [
        {
            value: 'fixture',
            label: 'Standard',
        },

        {
            value: 'single-event',
            label: 'Single Event',
        },
    ];
}

export const DefaultURLs = {
    // https://testing-ecal-publisher-assets.s3.amazonaws.com/6282_14370/6282_14370_1649206129_200.png
    EVENT_BANNER: DEFAULT_IMAGE,
};

export const PlanTypes = {
    FREE_TRIAL: 'free trial',
    FREE_TRIAL_2: 'free-trial',
    FREE: 'free',
    ENTERPRISE: 'enterprise',
    PROFESSIONAL: 'professional',
    PRO: 'pro',
    SOCIAL: 'social',
    EXPERT: 'expert',
};

export const ChargifyPlanId = {
    COMMUNITY: 22,
    GROW: 23,
    PRO: 24,
    ENTERPRISE: 25,
};

export const chargifyPlanIds = [
   ChargifyPlanId.COMMUNITY,
   ChargifyPlanId.GROW,
   ChargifyPlanId.PRO,
   ChargifyPlanId.ENTERPRISE,
];

export const BillingProviders = {
    ECAL: 'ecal',
    CHARGIFY: 'chargify',
    AWS: 'aws',
};

export const BillingCoupons = {
    SETMEUP23: 'SETMEUP23',
    ITSONUS23: 'ITSONUS23',
    EXPERTPLS: 'EXPERTPLS',
    LETSDOTHIS23: 'LETSDOTHIS23',

    LETSDOTHIS_PERCENT_OFF: 25 / 100,
    LETSDOTHIS_OFF_LABEL: '(Discount applied)',
    LETSDOTHIS_OFF_MONTHLY_LABEL: '(25% off monthly)',
};

export const AddOnTypes = {
    QUANTITY: 'quantity',
    ON_OFF: 'onOff',
};

export const HandleNames = {
    ADD_USERS: 'add-users',
    ADDITIONAL_USERS: 'additional-users',
    ACCOUNT_SETUP_SIMPLE: 'acct-setup-simple',
    SETUP: 'setup',
    CUSTOM: 'custom',
    SSO_ADMIN_SETUP: 'enterprise-sso-admin-setup',
    PROJECT_SETUP: 'enterprise-project-setup',
};

export const ScheduleBannerOptions = [

    {
        value: 'all-events',
        label: 'All Events in this Schedule',
    },

    {
        value: 'alternate-events',
        label: 'Every 2nd / 3rd / 5th event in this Schedule',
    },

    {
        value: 'no-default',
        label: 'No default banner',
    },
];
