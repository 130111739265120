
import { useState, useEffect } from 'react';
import { DEFAULT_IMAGE } from '../../commons/imageUploader/DefaultImage';
import { renderableImageFormat } from '../../utils/constants';

function useImageRenderable (props) {
    const {
        imageSource,
        customDefaultImage = DEFAULT_IMAGE,
    } = props;

    const [image, setImage] = useState(renderableImageFormat(imageSource || customDefaultImage));
    useEffect(() => {
        /*
        console.log('');
        console.log('');
        console.log('');
        console.log('useImageRenderable()', imageSource);
        console.log('useImageRenderable()', customDefaultImage);
        */
        if (imageSource) {
            setImage(renderableImageFormat(imageSource));
        } else {
            setImage(renderableImageFormat(customDefaultImage));
        }
    }, [imageSource]);
    return {
        image,
    };
}

export default useImageRenderable;
