import React from 'react';
import { observer } from 'mobx-react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Grid,
  Box,
} from '@material-ui/core';

// styles
import { useStyles } from './login.styles';

import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

// components
import { useUserStore } from '../../store/models';
import useEnvHistory from '../hooks/useEnvHistory';

function ConfirmSignUp (props) {
  const classes = useStyles();
  const userStore = useUserStore();
  console.log('User STore User: ', userStore);
  useEnvHistory();
  return (
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={EcalLogoImage} alt="logo" className={classes.logotypeImage} />
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid item container className={classes.logoImageContainer}>
              <div id='logoImage' className={classes.logoImage}></div>
            </Grid>

            <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <ReCAPTCHA
                                sitekey="6LcaVW0bAAAAABiDhcXvtILLCYP40U4lPDY8Acrx"
                                onChange={(data) => {
                                    console.log('Re captcha Complete: ', data);
                                }}
                            />
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
          </React.Fragment>

        </div>
      </div>
    </Grid>
  );
}

export default observer(ConfirmSignUp);
