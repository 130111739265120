import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Grid,
    TextField,
} from '@material-ui/core';
import {
    Typography,
    Link,
    // Button,
} from '../../../../components/Wrappers/Wrappers';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

const INSTRUCTION = 'What shall we name this display?';
const calendarNameHelperText = 'The name of your Calendar feed as it appears in the subscriber\'s Personal Calendar.';
function NameIdDetails (props) {
    const classes = useStyles();

    const {
        detailsData,
        setDetailsData,
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRootNoBottomPad}>

                    <Grid className={classes.sectionDescription}>
                        <Typography block >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classes.sectionContent}
                        >
                            <Box
                                className={classes.inputBox}>

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}

                                    id="googleAnalytics"
                                    label="Display Id"

                                    value={detailsData.displayId}
                                    onChange={(e) => {
                                        setDetailsData({
                                            ...detailsData,
                                            displayId: e.target.value,
                                        });
                                    }}

                                    name="shareURL"
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    helperText={ 'Display ID is the name your display will be given within your Publisher Admin.'}
                                />

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}
                                    disabled
                                    id="message"
                                    label="Publisher Name"

                                    value={detailsData.displayName}
                                    onChange={(e) => {
                                        setDetailsData({
                                            ...detailsData,
                                            displayName: e.target.value,
                                        });
                                    }}

                                    name="calendarNameHelperText"
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    helperText={calendarNameHelperText}
                                />
                            </Box>
                            <Grid className={classes.sectionDescription}>
                                <Typography
                                    className={classes.noteHelperText}
                                    block
                                >
                                  <b> Note:</b> To change your Publisher Name, update your Organisation Name in <u><Link href='/admin/settings'> Account Settings</Link></u>.
                                </Typography>
                            </Grid>
                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

NameIdDetails.propTypes = {
    setDetailsData: PropTypes.func,
    detailsData: PropTypes.any,
};

NameIdDetails.defaultProps = {
    setDetailsData: () => {},
    detailsData: {},
};

export default NameIdDetails;
