import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',
        width: '100%',
    },

    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        boxShadow: theme.customShadows.widget,
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    tablePagination: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    statusCell: {
        color: '#fff',
        height: '20px',
        width: '65px',
        fontSize: '11px',
        fontWeight: 'bold',
    },

    nameCell: {
        width: '200px',
        fontSize: '14px',
    },

    subscriberCell: {
        width: '60px',
        fontSize: '14px',
    },

    subscriberCellTD: {
        width: '60px',
        fontSize: '14px',
    },

    text: {
        fontSize: '12px !important',
        padding: '4px',
    },

    actionCell: {
        width: '100px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    actionCellIcon: {
        padding: '2px',
        margin: '2px',
    },

    previewCell: {

    },

    previewCellIconsMargin: {
        marginRight: '8px',
    },

    previewCellIcons: {
        padding: '0px',
    },

    previewImage: {
        width: '200px',
        cursor: 'pointer',
    },

    badgeIcon: {
        position: 'absolute',
        left: '-20px',
        top: '11px',

        '& .MuiBadge-badge': {
            fontSize: '11px !important',
            height: '14px !important',
            fontWeight: '450',
        },
    },

    fullSize: {
      width: '100%',
      height: '100%',
    },

    widgetPreviewMode: {
      display: 'block',
      zIndex: 101,
      position: 'relative',

      top: 0,
      left: 0,
      border: 0,
      background: 'transparent',

      width: '100% !important',
      height: '100% !important',
      minHeight: 500,
      overflow: 'auto',
  },

  dialogButtonCodeContainer: ({ mode }) => {
        function getHeight () {
            if (mode === 'page') {
                return '80vh';
            }

            if (mode === 'calendar') {
                return '80vh';
            }

            if (mode === 'carousel') {
                return '320px';
            }

            if (mode === 'column') {
                return '650px';
            }

            return '500px';
        }

        function getWidth () {
            if (mode === 'column') {
                return '400px';
            }

            return '1200px';
        }

        return {
            width: getWidth(),
            maxWidth: '1200px',

            '& .MuiDialogContent-root': {
                height: getHeight(),
            },
        };
    },
});

export default styles;
export const useStyles = makeStyles(styles);
