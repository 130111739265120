import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function usePublishersErrors (props = {}) {
    const {
        input,
    } = props;

    const store = useErrorStore();

    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        useTouched: true,
        hook: 'usePublishersErrors',
        input: input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.searchPublishers,
            store.observed.errors.superAdminUpdatePlan,
        ],
    });

    useEffect(() => {
        console.log('usePublishersErrors() -> useEffect()-> ', errors);
    }, [errors]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default usePublishersErrors;
