import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

// const Actions = {
//     ADD_CATEGORY: 'addCategory',
// };
const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = {
    ...ErrorAPIMessages,
    INVALID_IMAGE_RATIO: 'error resizing image: incorrect image size for calendar logo, aspect ratio must be 1:1',
    'INPUT_PARAM_NOT_VALID': 'input parameter is not valid',
    'URL_IS_NOT_VALID': 'url is not valid',
    'URL_IS_REQUIRED': "Key: 'Webhook.URL' Error:Field validation for 'URL' failed on the 'required' tag",
    'UNPROCESSABLE_ENTITY': 'Maximum four webhooks are allowed. Each webhook url must be under 1024 chars.',
};

const errorErrors = {
    ...ErrorAPIErrors,
     RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
};

const customErrorMap = {
    ...errorMap,

    // RESOURCE_ALREADY_EXISTS: (err) => {
    //     return {
    //         title: 'Duplicate custom labels',
    //         message: err.message,
    //         label: err.message,
    //     };
    // },

    INVALID_IMAGE_RATIO: (err) => {
        return {
            imageError: err.message,
        };
    },
    URL_IS_NOT_VALID: (err) => {
        return {
          title: 'Invalid input',
          url: err.message,
          message: 'URL is not valid',
        };
    },
    URL_IS_REQUIRED: (err) => {
        return {
          title: 'Invalid input',
          url: err.message,
          message: 'Field URL is required',
        };
    },
    UNPROCESSABLE_ENTITY: (err) => {
        return {
          title: 'Webhook quota reached',
          url: err.message,
          message: 'Maximum four webhooks are allowed. Each webhook url must be under 1024 chars.',
        };
    },

    INVALID_INPUT_PARAMETER: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },

    // INPUT_PARAM_NOT_VALID: (err) => {
    //     const errStore = window.envStore.errorStore;
    //     const action = errStore.action;
    //     if (action === Actions.ADD_CATEGORY) {
    //         return {
    //             title: 'Duplicate category',
    //             message: 'This Category / Subcategory already exists.',
    //             action: action,
    //         };
    //     }

    //     return {
    //         title: 'Invalid input',
    //         message: err.message,
    //         text: err.message,
    //     };
    // },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}
