import React, { useState, useEffect } from 'react';
// import moment from 'moment';

// import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';

import {
    Typography,
    Link,
} from '../../../components/Wrappers/Wrappers';

import {
    // isChargifyAccount,
    isFreeTrial,
} from '../../../utils';

import ButtonProgress from '../../../commons/ButtonProgress';
import Select from '../../../commons/select/Select';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { useStyles } from './styles';

import ecalSettings from '../../../store/models/styles/clients/defaultEcal';
function PlanDetails (props) {
    const {
        publisher,
        // onDataChange,
        onSavePlan,
        plans,
    } = props;

    function toPlanSource (planList) {
        if (isEmpty(planList)) {
            return [];
        }
        return planList.map((i) => {
            return {
                label: i.name,
                value: i.id,
            };
        });
    }

    const [data, setData] = useState({ ...publisher });
    const [planSource, setPlanSource] = useState(toPlanSource(plans));
    const [saveDisable, setSaveDisable] = useState(true);
    const [isChargify, setIsChargify] = useState(false);

    const [planTouch, setPlanTouch] = useState(false);

    const [expiryDate, setExpiryDate] = useState(publisher.dateExpiry);

    // eslint-disable-next-line no-unused-vars

    const classes = useStyles();

    function getPlanId () {
        let planId = '';
        plans.forEach((p) => {
            if (publisher.planName === p.name) {
                planId = p.id;
            }
        });
        return planId;
    }

    useEffect(() => {
        setData({
            ...publisher,
            planId: getPlanId(),
        });
        //  || isChargifyAccount(publisher.planId)
        if (publisher.provider === 'chargify') {
            setIsChargify(true);
            setSaveDisable(true);
        } else {
            setIsChargify(false);
            if (planTouch) {
                setSaveDisable(false);
            }
        }
        setExpiryDate(publisher.dateExpiry);
    }, [publisher]);

    useEffect(() => {
        setPlanSource(toPlanSource(plans));
    }, [plans]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        setPlanTouch(true);
        if (!isChargify) {
            setSaveDisable(false);
        }
    }

    function handleExpiryDateChange (date) {
        setExpiryDate(date);
        if (!isChargify) {
            setSaveDisable(false);
        }
    }

    function getPlanFromId (id) {
        let plan = null;
        plans.forEach((item) => {
            if (item.id === id) {
                plan = item;
            }
        });
        return plan;
    }

    function handleSelectChange (event) {
        const plan = getPlanFromId(event.target.value);
        setSaveDisable(false);
        setPlanTouch(true);
        setData({
            ...data,
            planId: event.target.value,
            planName: plan.name,
            subscriberLimit: plan.subscriberLimit,
        });
    }

    function navigtateOut () {
        window.open(ecalSettings.supportContact, '_blank');
    }

    function renderChargifyAlt () {
        if (!isChargify) {
            return (<React.Fragment/>);
        }
        return (
            <Typography
                variant={'body2'}
                weight={'light'}
                style={{ marginBottom: 12 }}
            >
                <strong>Please contact </strong><u><Link className={classes.linkPointer} onClick={navigtateOut} > {ecalSettings.supportLabel} </Link></u> to upgrade or make changes to your plan.
            </Typography>
        );

        /*

        <Typography
            variant={'body2'}
            weight={'light'}
            style={{ marginBottom: 30 }}
        >
            This plan is setup with chargify. Please update it on <strong>  <u><Link className={classes.linkPointer} onClick={navigtateOut} >Chargify</Link></u> </strong> to make changes to this plan.
        </Typography>
            */
            /* <strong>View/edit billing details: </strong>{!enterprisePlan ? <Link>click here</Link> : <Link>contact us</Link>} */
    }

    function renderExpiryDateField () {
        if (!isFreeTrial(publisher.planName)) {
            return <React.Fragment></React.Fragment>;
        }
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid
                    container
                    className={classes.dateField}
                    >

                    <KeyboardDatePicker
                        // autoOk
                        margin='normal'
                        id='date-picker-dialog'
                        label='Expiry Date'
                        /* format="MMM d, yyyy" */
                        format='MM / dd / yyyy'
                        value={expiryDate}
                        onChange={(date) => {
                            handleExpiryDateChange(date);
                        }}

                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}

                        inputProps={{
                            readOnly: false,
                            'data-intercom-target': 'event-start-date',
                        }}

                    />

                </Grid>

            </MuiPickersUtilsProvider>
        );
    }

    function renderPlanBox () {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={600}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    Plan
                </Typography>

                { renderChargifyAlt() }

                <Select
                    value={data.planId}
                    label= 'plan'
                    name= 'plan'
                    id= 'plan'
                    labelHeading= 'Select Plan'
                    handleChange={(event) => handleSelectChange(event)}
                    dataForDropdown={planSource}
                    disabled={isChargify}
                />

                <TextField
                    disabled={isChargify}
                    id="subscriberLimit"
                    name="subscriberLimit"
                    label="User Subscription Limit"
                    onChange={handleChange}
                    value={data.subscriberLimit}
                    variant="outlined"
                    style={{ marginBottom: 57 }}
                />

                { renderExpiryDateField() }

                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisable}
                        showProgress={false}
                        label="Save"
                        onClick={(e) => {
                            onSavePlan(
                                {
                                    id: data.id,
                                    newPlanId: data.planId,
                                    newPlanName: data.planName,
                                    subscriberLimit: data.subscriberLimit,
                                },
                                {
                                    dateExpiry: expiryDate,
                                }
                            );
                        }}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            { renderPlanBox() }
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

PlanDetails.propTypes = {
    publisher: PropTypes.any,
    onSavePlan: PropTypes.func,
    plans: PropTypes.any,
};

PlanDetails.defaultProps = {
    publisher: {},
    onSavePlan: () => {},
    plans: [],
};

export default observer(PlanDetails);
