import { isEmpty } from 'lodash';

import emptyRow from './emptyRow.json';
import { getSubGenreList } from '../manualEntry/GenreUtil';

export const NEW_SCHEDULE = {
    value: '-',
    label: 'New Schedule',
};

export const emptyRowData = emptyRow;

export const ScheduleTypes = [
    {
        value: 'fixture',
        label: 'Standard Schedule',
    },
];

export const DefaultInput = {
    fileName: '',
    selectedSchedule: [],
    scheduleName: '',
    selectedType: '',
    isNewSchedule: true,
    dateFormat: '',
    allClear: false,
    timezone: [],
    ignoreFirstRow: true,
    genre: '',
    subGenre: '',
};

export const ignoreRowData = [
    {
        value: true,
        label: 'Yes',
    },

    {
        value: false,
        label: 'No',
    },
];

export const DateFormats = [
    {
        value: 'dd-mm-yyyy',
        label: 'dd-mm-yyyy',
    },

    {
        value: 'mm-dd-yyyy',
        label: 'mm-dd-yyyy',
    },

    {
        value: 'dd/mm/yyyy',
        label: 'dd/mm/yyyy',
    },

    {
        value: 'mm/dd/yyyy',
        label: 'mm/dd/yyyy',
    },
];

export function getClientTimezone () {
    if (Intl) {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return '';
};

export const ImportEventSteps = [
    'Schedule Details',
    'Upload Data',
    'Review Fields',
];

export const DefaultStep = {
    activeStep: 0,
    steps: ImportEventSteps,
};

function step1Condition (input) {
    if (!input.selectedSchedule) {
        return false;
    }
    if (input.selectedSchedule <= 0) {
        return false;
    }
    if (input.selectedSchedule[0] !== '-') {
        return true;
    }

    if (input.selectedSchedule[0] === '-' && input.scheduleName !== '') {
        if (isEmpty(input.genre)) {
            return false;
        }
        const hasSubGenreList = getSubGenreList(input.genre);
        if (isEmpty(hasSubGenreList)) {
            return true;
        }
        if (!input.subGenre || input.subGenre.length === 0) {
            return false;
        }
        return true;
    }

    return false;
}

function step2Condition (input, spreadsheetFile) {
    if (input.dateFormat === '') {
        return false;
    }

    if (!spreadsheetFile) {
        return false;
    }

    return true;
}

function step3Condition (input) {
    return input.readyForSave;
}

export const stepConditions = {
    'step1': step1Condition,
    'step2': step2Condition,
    'step3': step3Condition,
};
