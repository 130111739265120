export const errorsMap = {
    eventName: 'eventName is required',
    'Event Name*': 'Event name is required.',

    location: 'Location is required.',
    'Location*': 'Location is reuqired.',

    startDate: 'Please check if date format is valid or start date is less than the end date.',
    'Start Date*': 'Please check if date format is valid or start date is less than the end date.',

    startTime: 'Please check if date format is aligned to your spreadsheet or check if the start time is less than the end time.',
    'Start Time*': 'Please check if date format is aligned to your spreadsheet or check if the start time is less than the end time.',

    endDate: 'Please check if date format is valid or start date is less than the end date.',
    'End Date*': 'Please check if date format is valid or start date is less than the end date.',

    endTime: 'Please check if date format is aligned to your spreadsheet or check if the start time is less than the end time.',
    'End Time*': 'Please check if date format is aligned to your spreadsheet or check if the start time is less than the end time.',

};
