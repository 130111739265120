import React from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Widget from '../../components/Widget';
import Card from '../../commons/card/Card';
import useStyles from './schedule.styles';
import { Typography } from '../../components/Wrappers';
import useScheduleNavigation from './useScheduleNavigation';
import ManualEntryImage from '../../commons/images/schedule/ManualEntry.png';
import FeedSyncImage from '../../commons/images/schedule/FeedSync.png';
import FileImportImage from '../../commons/images/schedule/FileImport.png';

function AddSchedule () {
    const classes = useStyles();
    const {
        handleManualEntrySchedule,
        handleImportSchedule,
      } = useScheduleNavigation();

    function handleFeedConnect () {
        window.Intercom('showMessages');
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Widget className={classes.widgetContainer}
                    title="Add Schedule"
                    buttonFlag={false}
                    inheritHeight disableWidgetMenu >
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'flex-start'}
                    >
                        <Typography variant='body2'>
                            Choose your way! You can create a schedule manually, connect to an API feed, or import from a file.
                        </Typography>
                    </Box>
                </Widget>
            </Grid>

        <Grid container spacing={6}>
            <Grid item md={4} xs={12}>
                <Card
                    buttonIntercomTarget={'schedule-create'}
                    cardImage={ManualEntryImage}
                    handleCreate={handleManualEntrySchedule}
                    titleText={'Manual entry'}
                    bodyDescription={'Create a new schedule and add events manually in seconds.'}
                    buttonText={'Create'} />
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    buttonIntercomTarget={'schedule-connect'}
                    cardImage={FeedSyncImage}
                    handleCreate={handleFeedConnect}
                    buttonText={'Connect'}
                    bodyDescription={'Connect to your events via API, for a totally automated events service.'}
                    titleText={'Connect to feed'}/>
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    buttonIntercomTarget={'schedule-import'}
                    cardImage={FileImportImage}
                    handleCreate={handleImportSchedule}
                    buttonText={'Import'}
                    bodyDescription={'Import events from an Excel (.xls, .xlsx) or CSV (.csv) file, to a new or existing schedule.'}
                    titleText={'Import from file'}/>
            </Grid>
        </Grid>

        </Grid>
    );
}

export default observer(AddSchedule);
