import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/styles';
import ReactApexChart from 'react-apexcharts';
import { getCountsWithComma } from '../../../../commons/util/utilities';
import SkeletonLoader from '../../../../components/SkeletonLoader';

const themeOptions = theme => {
    return {
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
      },
        labels: ['Mobile', 'Desktop', 'Tablet'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                    },
                    legend: {
                        position: 'left',
                    },
                },
            },
        ],
        colors: [
            '#90CA68',
            theme.palette.warning.light,
            theme.palette.info.light,
        ],
        states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
          hover: {
              filter: {
                  type: 'lighten',
                  value: -0.2,
              },
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'lighten',
                  value: -0.4,
              },
          },
        },
    };
};

export default function Charts (props) {
    const theme = useTheme();
    const [options, setOptions] = useState(themeOptions(theme));
    const [values, setValues] = useState([]);

    const deviceTypes = props.data;
    const getLabels = (data) => data?.map(({ name }) => name);
    const getValues = (data) => data?.map(({ count }) => count);
    const getTotalCount = (dataToFilter) => {
      if (Object.keys(dataToFilter).length === 0 && dataToFilter.constructor === Object) return;
      const count = dataToFilter.reduce(function (prev, cur) {
          return prev + cur.count;
        }, 0);
      return count;
  };

    useEffect(() => {
      if (deviceTypes?.length) {
        if (props.type === 'calendar') {
          const calendarTypes = props.data;
          setValues(getValues(calendarTypes));
          console.log('deviceTypes? ', calendarTypes);
          const newCalendarTypes = JSON.stringify(calendarTypes);
          const formattedData = getCountsWithComma(JSON.parse(newCalendarTypes));
          setOptions({
            ...themeOptions(theme),
            labels: deviceTypes.map(({ type }) => type || 'other'),
            tooltip: { y: { formatter: (val, series) => formattedData[series.dataPointIndex].count } },
          });
          return;
        }
        const deviceTypesList = [
          { 'type': 'Mobile', 'name': 'Mobile', 'count': 0 },
          { 'type': 'Desktop', 'name': 'Desktop', 'count': 0 },
          { 'type': 'Tablet', 'name': 'Tablet', 'count': 0 },
      ];

      const filteredDevices = deviceTypes.filter(function (o1) {
        return deviceTypesList.some(function (o2) {
            o1.name = o2.name;
            return o2.type.toLowerCase() === o1.type.toLowerCase();
        });
      });
      const otherItemsDevices = deviceTypes.filter(function (o1) {
        return !deviceTypesList.some(function (o2) {
            o1.name = 'other';
            return o1.type.toLowerCase() === o2.type.toLowerCase() ||
              o1.type.toLowerCase() === 'mobile' ||
              o1.type.toLowerCase() === 'phone';
        });
      });
      const phoneFilteredData = deviceTypes.filter(function (o1) {
        return deviceTypesList.some(function () {
            o1.name = 'Phone';
            return o1.type.toLowerCase() === 'phone';
        });
      });
      const otherDevicesTotal = getTotalCount(otherItemsDevices);
      const phoneDevicesTotal = getTotalCount(phoneFilteredData);

      const deviceDataFiltered = filteredDevices.map((a) => {
        a.count = a.type === 'Desktop' ? a.count + otherDevicesTotal : a.count;
        a.count = (a.type === 'Mobile') ? a.count + phoneDevicesTotal : a.count;
        a.name = a.type === 'Phone' ? 'Mobile' : a.type;
        return a;
      });

        setValues(getValues(deviceDataFiltered));

        const newDeviceTypes = JSON.stringify(deviceDataFiltered);
        const formattedData = getCountsWithComma(JSON.parse(newDeviceTypes));
        setOptions({
          ...themeOptions(theme),
          labels: getLabels(deviceDataFiltered),
          tooltip: { y: { formatter: (val, series) => formattedData[series.dataPointIndex].count } },
        });
      }
    }, [deviceTypes]);

    return (
      <>
      {props.isLoading
? (
        <SkeletonLoader />
      )
: (
        <ReactApexChart
          options={options}
          series={values}
          type='donut'
          height='200'
          stroke={''}
        />
      )}
      </>
    );
}
