import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getAPIKeys,
    generateAPIKey,
    getAPIKeySecret,
    deleteAPIKey,
    deactivateAPIKey,
    activateAPIKey,
} from './actions';

import {
    loadEditData,
    saveEditData,
} from './actions_persist';

export default class APIKeyStore {
    version = {}
    env = null;
    apikeys = null;
    hasInactive = false;
    editData = null;
    observed = {
        apikeys: null,
        apikeysLoading: false,
    };

    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    getAPIKeys = getAPIKeys;
    generateAPIKey = generateAPIKey;
    getAPIKeySecret = getAPIKeySecret;

    activateAPIKey = activateAPIKey;
    deactivateAPIKey = deactivateAPIKey;

    deleteAPIKey = deleteAPIKey;

    /* Data Persist */
    loadEditData = loadEditData;
    saveEditData = saveEditData;
}

export function useAPIKeyStore () {
    const { apikeyStore } = useStores();
    return apikeyStore;
}
