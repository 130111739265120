import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
// import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  TablePagination,
} from '@material-ui/core';
// import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import TableControls from '../../../commons/ecal/tableControls';
import DraftLiveBar from '../../../commons/ecal/draftLiveBar';
import Dialog from '../../../commons/dialog/Dialog';
import { Typography } from '../../../components/Wrappers';
import Widget from '../../../components/Widget';

import { useStyles } from './styles';

import { useHistory } from 'react-router';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

import useModuleList from './useModuleList';
import useButtonCodeDialog from '../buttonsList/useButtonCodeDialog';
function DisplaysList (props) {
  const classes = useStyles();
  const history = useHistory();

  const {
    progress,
    listData,
    deleteOpen,
    deleteTarget,
    setDeleteOpen,
    setDeleteTarget,
    pageInfo,
    updatePageInfo,

    selectedRows,
    setSelectedRows,
    visibleColumns,
    makeDraft,
    makeLive,
    deleteSelection,
    deleteSingle,
    saveEditData,
    activeAPIKey,
  } = useModuleList({
    columns,
  });

  const renderUniwidget = (activeAPIKey) => {
    const eCalButton = document.getElementsByClassName('ecal-sync-widget-button');
    setTimeout(() => {
      if (eCalButton.length > 0 && activeAPIKey) {
      window.EcalWidget('destroy');
      window.EcalWidget('boot', { apiKey: activeAPIKey });
      }
    }, 1000);
  };

  const {
    renderQRCode,
    renderButtonCode,
    showQRCode,
    showButtonCode,
  } = useButtonCodeDialog({
    options: {
      showRoles: false,
      activeAPIKey,
      renderUniwidget,
    },
  });

  const onShowButtonCode = useCallback((data) => {
    showButtonCode(data);
  }, [activeAPIKey]);

  const onDelete = useCallback((data) => {
    console.log('Data: ', data);
    setDeleteTarget(data);
    setDeleteOpen(true);
  }, []);

  const onShowQRCode = useCallback((data) => {
    showQRCode(data);
  }, [activeAPIKey]);

  const onEdit = useCallback((data) => {
    saveEditData(data);
  }, []);

  const onSelectRow = useCallback((selection) => {
    console.log('selection: ', selection);
    setSelectedRows(selection);
    setTimeout(() => {
      const eCalButton = document.getElementsByClassName('ecal-sync-widget-button');
      if (eCalButton.length > 0 && activeAPIKey) {
      window.EcalWidget('destroy');
      window.EcalWidget('boot', { apiKey: activeAPIKey });
      }
    }, 1000);
  }, [activeAPIKey]);

  function renderTableBar () {
    if (selectedRows && selectedRows.length > 0) {
      return (
        <DraftLiveBar
          infoText={ selectedRows.length + ' row(s) selected'}
          onLive={() => {
            makeLive();
          }}

          onDraft={() => {
            makeDraft();
          }}

          onDelete={() => {
            deleteSelection();
          }}
        />
      );
    }
    return (
      <TableControls
        hasSearch={false}
        title={'Ad Displays'}
        excluded={['actions']}
      />
    );
  }
  useEffect(() => {
    setTimeout(() => {
      const eCalButton = document.getElementsByClassName('ecal-sync-widget-button');
      if (eCalButton.length > 0 && activeAPIKey) {
      window.EcalWidget('destroy');
      window.EcalWidget('boot', { apiKey: activeAPIKey });
      }
    }, 1000);
  }, [progress.buttons, activeAPIKey]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Widget
          className={classes.widgetContainer}
          buttonText={'Add'}
          title="Manage Ad Displays"
          intercomTitleTarget={'manage-ad-display'}
            intercomAddTarget='add-ad-display'
          handleAdd={() => {
            history.push('/admin/displays/add/ad');
          }}
          inheritHeight disableWidgetMenu>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'flex-start'}
          >
            <Typography variant='body2'>
              Your ad displays allow users to select schedules and sync your events to their calendar.
            </Typography>
          </Box>
        </Widget>
        <Grid item
          xs={12}
          className={classes.tableContainer}
        >
          { renderTableBar() }
          <TableContainer
            hasCheck={true}
            classes={classes}
            tableData={listData}
            columns={visibleColumns}
            customCells={customCells}
            tableTitle='Manage Displays'
            className={classes.adsTable}
            showProgress={progress.buttons}
            options={options}
            isInsideWidget={false}
            moduleType="ad displays"
            removeEmptyRows

            onEdit={onEdit}

            onDelete={onDelete}

            onSelectRow={onSelectRow}

            onShowCode={onShowButtonCode}

            onShowQRCode={onShowQRCode}
          />
          <Grid className={classes.tablePagination}>
            <TablePagination

                rowsPerPageOptions={[
                  5,
                  10,
                  15,
                  20,
                  // {
                  //   value: -1,
                  //   label: 'All',
                  // },
                ]}

                page={pageInfo.page}
                rowsPerPage={pageInfo.limit}
                count={pageInfo.count}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count} `;
                }}

                onChangePage={(_, page) => {
                    updatePageInfo({ page });
                }}

                onChangeRowsPerPage={(e) => {
                  updatePageInfo({
                    page: 0,
                    limit: e.target.value,
                  });
                }}
              />
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        openDialog={deleteOpen}
        buttonSecondary={'Cancel'}
        handleAgree={() => {
          setDeleteOpen(false);
          deleteSingle(deleteTarget);
          console.log('Delete Target: ', deleteTarget);
        }}

        closeModal={() => {
          setDeleteOpen(false);
        }}
        title={'Delete Display'}
        dialogContextText={'Are you sure you want to delete this display?'}
      />
       { renderQRCode() }
      { renderButtonCode() }
    </React.Fragment>
  );
}

DisplaysList.propTypes = {
};

DisplaysList.default = {
};

export default observer(DisplaysList);
