import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    widgetContainer: {
      paddingBottom: '16px',
    },
    widgetRangeContainer: {
      paddingBottom: '16px',
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
    },
    timezoneText: {
      textAlign: 'right',
      margin: '8px 0 4px',
    },
    ctrText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    engagementsCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#3CD4A044',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ctrEngagementsCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#3CD4A044',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '12px',
    },
    subHeading: {
      padding: '0 12px',
    },
});

export default styles;
export const useStyles = makeStyles(styles);
