import React, { useEffect, useState } from 'react';
import Dialog from '../../commons/dialog/Dialog';

function useConfirmationDialog (props) {
    const {
        onClose = () => {},
        onConfirm = () => {},
        open: defaultOpen = false,
        title: defaultTitle,
        message: defaultMessage,
        confirmLabel = 'Ok',
        cancelLabel = '',
        showSecondaryButton = true,
        showPrimaryButton = true,
    } = props;

    const [open, setOpen] = useState(defaultOpen);
    const [title, setTitle] = useState(defaultTitle);
    const [message, setMessage] = useState(defaultMessage);

    useEffect(() => {
        setTitle(defaultTitle);
    }, [defaultTitle]);

    useEffect(() => {
        setMessage(defaultMessage);
    }, [defaultMessage]);

    function renderDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <Dialog
                openDialog={open}
                buttonSecondaryFlag={showSecondaryButton}
                buttonPrimaryFlag={showPrimaryButton}
                buttonSecondary={cancelLabel}
                buttonPrimary={confirmLabel}

                handleAgree={() => {
                    if (typeof onConfirm === 'function') {
                        onConfirm();
                        setOpen(false);
                    }
                }}

                closeModal={() => {
                    if (typeof onClose === 'function') {
                        onClose();
                        setOpen(false);
                    }
                }}
                title={title}
                dialogContextText={message}
            />
        );
    }

    function openDialog (newTitle = null, newMessage = null) {
        if (newTitle) {
            setTitle(newTitle);
        }

        if (newMessage) {
            setMessage(newMessage);
        }
        setOpen(true);
    };

    return {
        renderDialog,
        openDialog,
    };
}

export default useConfirmationDialog;
