import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Grid,
  TablePagination,
} from '@material-ui/core';
import {
  AccountCircle,
} from '@material-ui/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee as SignInIcon,
} from '@fortawesome/free-solid-svg-icons';
*/

import { Typography } from '../../components/Wrappers/Wrappers';

import { useStyles } from './list.styles';

function PublisherList (props) {
  const {
    obervablePageInfo,
    pageInfo,
    listData,
    onSelectPublisher,
    paginate,
  } = props;

  const [__listData, setListData] = useState(listData);
  const [__pageInfo, setPageInfo] = useState(pageInfo);

  useEffect(() => {
    console.log('Publishers List -> Page Info -> ', pageInfo);
    setPageInfo(pageInfo);
  }, [obervablePageInfo]);

  const classes = useStyles();

  useEffect(() => {
    if (listData) {
      setListData(listData);
    }
  }, [listData]);

  function renderList () {
    if (!__listData) {
      return (<React.Fragment></React.Fragment>);
    }

    return __listData.map((item, index) => {
      return renderListItem(item, index);
    });
  }

  function colorByStatus (item) {
    if (item.status === 'active') {
      return '#51842E';
    }
    return '#f50057';
  }

  function renderListItem (item, index) {
    if (!item) {
      return (<React.Fragment></React.Fragment>);
    }

    const prependNameValue = 'Owner/Creator: ';
    return (
      <Grid
        key={'pub-' + index}
        container className={classes.button}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            console.log('publisher item is selected: ', item);
            onSelectPublisher(item);
        }}
      >
        <Grid item xs={0.5} className={classNames([classes.noPointer])}>
            <AccountCircle style={{ color: colorByStatus(item) }} />
        </Grid>

        <Grid item xs={0.5} className={classNames([classes.noPointer])} >
          <Typography variant={'body1'} className={classes.userName}>{item.publisherId}</Typography>
        </Grid>
        <Grid item xs={1} className={classNames([classes.noPointer])}>
          <Typography variant={'body1'} className={classes.userName}>{item.planName}</Typography>
        </Grid>
        <Grid item xs={6} className={classNames([classes.noPointer, classes.userInfo])} >
          <Grid className={classNames([classes.noPointer, classes.itemSpace])}>
            <Typography variant={'h6'} noWrap className={classes.userName}>{item.name}</Typography>
            <Typography variant={'subtitle2'} className={classes.email}>{prependNameValue} {item.email}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={0.5} className={classNames([classes.loginIconCell])}>
          <div id='logoImage' className={classes.loginIcon}></div>
        </Grid>
    </Grid>
    );
  }

  return (
    <div className={classes.buttonsGroup}>
      {renderList()}
      { pageInfo.showPagingation && (
            <Grid className={classes.tablePagination}>
              <TablePagination

                  rowsPerPageOptions={[
                      10,
                  ]}

                  page={__pageInfo.page}
                  rowsPerPage={__pageInfo.itemsPerPage}
                  count={__pageInfo.totalCount}

                  labelDisplayedRows={({ from, to, count }) => {
                      return `${from}-${to} of ${count} `;
                  }}

                  onChangePage={(_, page) => {
                      paginate(page);
                  }}

                  />
          </Grid>
      )}
    </div>
  );
}

PublisherList.propTypes = {
  listData: PropTypes.array,
  onSelectPublisher: PropTypes.func,
  paginate: PropTypes.func,
  pageInfo: PropTypes.any,
  obervablePageInfo: PropTypes.any,
};

PublisherList.default = {
  listData: [],
  onSelectPublisher: () => {},
  paginate: () => {},
  pageInfo: {},
  obervablePageInfo: {},
};

export default observer(PublisherList);
