import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
} from '@material-ui/core';

import { useStyles } from './styles';
import { useUserStore, useEnvStore, useErrorStore } from '../../store/models';
import { Typography } from '../../components/Wrappers/Wrappers';
import useEnvHistory from '../hooks/useEnvHistory';
import Dialog from '../../commons/dialog/Dialog';
import useGenericErrorDialog from '../../store/models/error/useGenericErrorDialog';
import useAuthError from '../../store/models/user/errors/useAuthError';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

function Home () {
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    message: '',
  });

  const { whiteLabelSettings } = useWhiteLabel();

  const user = useUserStore();
  const env = useEnvStore();
  const errStore = useErrorStore();
  const classes = useStyles({ whiteLabelSettings });
  useEnvHistory();

  useEffect(() => {
    if (env.superAdmin) {
        console.log('User: ', user);
    }
  }, [env.superAdmin]);

  useEffect(() => {
    if (errStore.authError.payload) {
      const payload = errStore.authError.payload;

      setDialog({
        open: true,
        title: payload.title,
        message: payload.message,
      });
    }
  }, [errStore.authError.payload]);

  const { renderErrorDialog } = useGenericErrorDialog();
  useAuthError();

  return (
    <React.Fragment>
      <Grid container className={classes.page}>
        <Grid item container className={classes.content}>
            <Grid item container className={classes.logoImageContainer}>
                <div id='logoImage' className={classes.logoImage}></div>
            </Grid>
            <Grid item className={classes.text}>
                <Typography>
                    Initialising, please wait...
                </Typography>
            </Grid>
        </Grid>
      </Grid>

      <Dialog
        openDialog={dialog.open}
        buttonSecondaryFlag={false}
        buttonPrimary="OK"
        handleAgree={() => {
          setDialog({
            ...dialog,
            open: false,
          });
          env.history.push('/login'); /* must go to support page */
        }}

        closeModal={() => {
          setDialog({
            ...dialog,
            open: false,
          });
        }}

        title={dialog.title}
        dialogContextText={dialog.message}
    />

      { renderErrorDialog() }
    </React.Fragment>
  );
}

export default observer(Home);
