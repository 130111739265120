
import React, { useState } from 'react';
import BillingDialog from './billingDialog';

function useBillingDialog (props = {}) {
    const {
        open: defaultOpen = false,
    } = props;

    const [open, setOpen] = useState(defaultOpen);

    function renderBillingDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <BillingDialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
            />
        );
    }

    function openDialog () {
        setOpen(true);
    };

    return {
        renderBillingDialog,
        openDialog,
    };
}

export default useBillingDialog;
