// import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((p) => ({

    controlRoot: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#FFFFFF',
        minHeight: '48px',
        justifyContent: 'space-between',
        /*
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        */
    },

    title: {
        display: 'flex',
        marginLeft: '16px',
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    /* Pop Menu */

    popPaper: {
        border: '1px solid rgba(0,0,0,0.30)',
    },

    menuRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'scroll',
        maxHeight: '240px',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',

        '& .MuiList-padding': {
            paddingTop: '0px !important',
        },
    },

    menuList: {
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: '8px',
        fontSize: '16px',
    },

    menuListTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: '100%',
        marginTop: '16px',
        minHeight: '24px',

        fontSize: '16px',
    },

    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        color: '#737373',
    },

}));

export default useStyles;
