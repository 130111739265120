import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    widgetContainer: {
      paddingBottom: '16px',
      position: 'relative',
    },
    widgetRangeContainer: {
      paddingBottom: '16px',
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
    },
    timezoneText: {
      textAlign: 'right',
      margin: '8px 0 4px',
    },
    ctrText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: '#75bd43',
    },
    engagementsCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#3CD4A044',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subHeading: {
      padding: '0 12px',
    },
    subscriptionCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#536DFE44',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    subsGraphLoader: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      margin: 'auto',
    },
    boxContainer: {
      position: 'relative',
    },
    subsByMonthContainer: {
      '& thead > tr > th:first-of-type': {
        width: 110,
      },
    },
});

export default styles;
export const useStyles = makeStyles(styles);
