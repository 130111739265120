import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function useEventsError (props) {
    const {
        input,
    } = props;

    const store = useErrorStore();

    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        hook: 'useEventsError',
        input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.saveImportedEvents,
            store.observed.errors.getEvents,
            store.observed.errors.searchEvents,
            store.observed.errors.updateEvents,
            store.observed.errors.updateBulkEvents,
            store.observed.errors.deleteEvents,
            store.observed.errors.uploadEventBanner,
            store.observed.errors.importEvents,
        ],
    });

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useEventsError() Error Updates...', errors);
    }, [errors]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default useEventsError;
