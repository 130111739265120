import { useEffect, useState } from 'react';
// import { isEmpty } from 'lodash';
// import { useErrorStore } from '../../../../store/models';

import Validations, { validate, isNonEmptyLinkValid } from '../../../../utils/validations';

const messages = {

    url: {
        required: 'Destination URL is required.',
        pattern: 'Destination must be a valid URL.',
    },

    name: {
        required: 'Link name is required.',
    },

};

function useQuickLinksError (props) {
    const {
        activeQuickLink,
    } = props;

    // const errStore = useErrorStore();
    const [touched, setTouched] = useState({});
    const [_input, setInput] = useState(activeQuickLink);

    const [linkConfirmDisabled, setLinkConfirmDisabled] = useState(true);

    const [error, setError] = useState({
        url: '',
        name: '',
        disableSend: true,
    });

    function validateByChange () {
        const err = {
            url: validateURL(),
            name: validateName(),
        };

        if (err.name || err.url) {
            console.log('disabling?...');
            setLinkConfirmDisabled(true);
        } else {
            setLinkConfirmDisabled(false);
        }

        if (err.code) {
            err.disableSend = true;
        } else {
            err.disableSend = false;
        }

        setError(err);
    }

    function validateURL () {
        if (!touched.url) {
            return '';
        }

        if (_input.url === '') {
            return messages.url.required;
        }

        if (!isNonEmptyLinkValid(_input.url)) {
            return messages.url.pattern;
        }

        return '';
    }

    function validateName () {
        if (!touched.name) {
            return '';
        }

        return validate({
            rules: { ...Validations.required },
            messages: messages.name,
            value: _input.name,
        });
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in activeQuickLink) {
            if (activeQuickLink[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setInput(activeQuickLink);
    }, [activeQuickLink]);

    useEffect(() => {
        if (Object.entries(touched).length > 0) {
            validateByChange();
        }
    }, [_input]);

    function disableConfirm () {
        setLinkConfirmDisabled(true);
    }

    function resetTouch () {
        setTouched({});
        setError({
            url: '',
            name: '',
            disableSend: true,
        });
    }

    return {
        error,
        linkConfirmDisabled,
        disableConfirm,
        resetTouch,
    };
}

export default useQuickLinksError;
