import { useState, useEffect } from 'react';
// import { serviceErrorSerializer } from './error_map';
// import useErrors from './useErrors';
import { useErrorStore } from './index';

function useGenericErrors () {
    const store = useErrorStore();

    const [errors, setErrors] = useState({});
    const [hasError, setHasError] = useState(false);

    /*
    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        hook: 'useGenericErrors',
        isGeneric: true,
        shouldValidateInput: false,
        input: {},
        inputValidator: () => {},
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.generics,
        ],
    });
    */

    useEffect(() => {
        if (store.genericErrors) {
            setHasError(true);
            const error = store.genericErrors;
            setErrors({
                ...error,
                id: store.observed.errors.generics,
            });
        } else {
            setHasError(false);
            setErrors({});
        }
    }, [store.observed.errors.generics]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
    };
}

export default useGenericErrors;
