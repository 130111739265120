import React from 'react';

import {
    Grid,
    TextField,
} from '@material-ui/core';

import Checkbox from '../../../../../commons/Checkbox';

function parentalConsent (props) {
    const {
        classes,
        customFieldData,
        setCustomFieldData,
    } = props;

    function dataValue () {
        if (customFieldData.value && customFieldData.value.length > 0) {
            return customFieldData.value[0];
        }
        return 'Are you over the age of 16?';
    }

    return (
        <Grid
            className={classes.fieldsRoot}>
            <TextField
                inputProps={{
                    className: classes.multilineInput,
                }}
                id="message"
                label="Question"
                multiline
                rows="4"
                value={dataValue()}
                onChange={(e) => {
                const v = e.target.value;

                setCustomFieldData({
                    ...customFieldData,
                    name: 'parental consent age',
                    value: [v],
                });
                }}
                name="message"
                variant="outlined"
                helperText={'If user answers "No" to above question, they will be required to provide a parent\'s email address'}
            />
             <Checkbox
                checked={customFieldData.required}
                defaultChecked={customFieldData.required}
                onChange={e => {
                    setCustomFieldData({
                        ...customFieldData,
                        required: !customFieldData.required,
                    });
                }}
                variant="primary"
                label=" Required"
            />
    </Grid>
    );
}

export default parentalConsent;
