import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
} from '@material-ui/core';

import {
    ColorPicker,
} from 'material-ui-color';

import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

import {
    colorPickerValueToHex,
} from '../../../../utils';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
// import { debounce } from './utils';

function InputButtons (props) {
    const classes = useStyles();

    const {
        designData,
        setDesignData,
    } = props;

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}>

                    <Grid className={classes.sectionContent} >

                        <Grid className={classes.sectionDescription}>
                            <Typography
                                variant='sub1'
                                >
                                    Sync to Calendar
                            </Typography>
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Button Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.sync.buttonColour}

                                    onChange={(value) => {
                                        const newValue = {
                                            ...designData,
                                            sync: {
                                                ...designData.sync,
                                                // buttonColour: '#' + value.hex,
                                                buttonColour: colorPickerValueToHex(value),
                                            },
                                        };
                                        // debounce(setDesignData, newValue);
                                       setDesignData(newValue);
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classNames([classes.colorPicker, classes.colorPickerBottom])} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Font Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.sync.fontColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            sync: {
                                                ...designData.sync,
                                                // fontColour: '#' + value.hex,
                                                fontColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classes.sectionDescription}>
                            <Typography
                                variant='sub1'
                                >
                                    Primary Links
                            </Typography>
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Button Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.primary.buttonColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            primary: {
                                                ...designData.primary,
                                                // buttonColour: '#' + value.hex,
                                                buttonColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classNames([classes.colorPicker, classes.colorPickerBottom])} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Font Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.primary.fontColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            primary: {
                                                ...designData.primary,
                                                // fontColour: '#' + value.hex,
                                                fontColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classes.sectionDescription}>
                            <Typography
                                variant='sub1'
                                >
                                    Secondary Links
                            </Typography>
                        </Grid>

                        <Grid className={classes.colorPicker} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Button Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.secondary.buttonColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            secondary: {
                                                ...designData.secondary,
                                                // buttonColour: '#' + value.hex,
                                                buttonColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                        <Grid className={classNames([classes.colorPicker, classes.colorPickerBottom])} >
                                <Typography
                                    variant='body1'
                                    className={classes.colorPickerText}
                                >
                                    Font Colour
                                </Typography>
                                <ColorPicker
                                    value={designData.secondary.fontColour}
                                    defaultValue='#000'
                                    onChange={(value) => {
                                        setDesignData({
                                            ...designData,
                                            secondary: {
                                                ...designData.secondary,
                                                // fontColour: '#' + value.hex,
                                                fontColour: colorPickerValueToHex(value),
                                            },
                                        });
                                    }}
                                    hideTextfield
                                />
                        </Grid>

                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

InputButtons.propTypes = {
    setDesignData: PropTypes.func,
    designData: PropTypes.any,
};

InputButtons.defaultProps = {
    setDesignData: () => {},
    designData: {},
};

export default InputButtons;
