import { cloneDeep } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { logInfo } from '../../../utils';
import {
    apiGetEvents,
    apiCreateEvent,
    apiUpsertEvents,
    apiBulkDeleteEvents,
    apiSearchEvents,
    apiBulkUpdateEvents,
    apiBulkUpdateEventsStatus,
} from './api';

// import { handleScheduleError } from '../error/sched_middleware';

export async function getEvents (scheduleId) {
    this.events = [];
    this.eventIsLoading = true;
    this.env.errorStore.action = 'getEvents';
    const publisher = this.env.userStore.publisher;
    let draft;
    if (this.eventFilter) {
      // console.log('has eventFilter? ', this.eventFilter);
        if (this.eventFilter === 'live') {
            draft = false;
        } else {
            draft = true;
        }
    }
    const res = await apiGetEvents({
        draft,
        page: this.eventsPage + 1,
        // pageLength: this.scheduleRowsPerPage,
        scheduleId,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data &&
        res.data.data &&
        res.data.data.getEvents) {
        console.log('response.data', res.data.data.getEvents);
        this.renderEvents(res.data.data.getEvents);
    } else {
        console.log('response.error', res.data);
    }

    if (res.response && res.response.status === 401) {
        console.log(res.response);
    }
}

export function renderEvents (source) {
    function mapEvents (edges) {
        const startsAt = moment(`${edges.node.time.startDate} ${edges.node.time.startTime}`);
        const endsAt = moment(`${edges.node.time.endDate} ${edges.node.time.endTime}`);
        edges.node.start = startsAt.format('lll');
        edges.node.end = endsAt.format('lll');
        edges.node.label = edges.node.name;
        edges.node.value = edges.node.name;
        edges.node.status = edges.node.data.draft;
        edges.node.location = edges.node.data.location;
        edges.node.timezone = edges.node.time.timezone;
        edges.node.reminder1 = edges.node.alertData.alert1;
        edges.node.reminder2 = edges.node.alertData.alert2;
        edges.node.details = edges.node.data.details;
        edges.node.sponsoredMessage = edges.node.data.sponsoredMessage;
        edges.node.reminders = (`${edges.node.alertData.alert1}, ${edges.node.alertData.alert2}`);
        if (edges.node.time.allDay) {
            const endsFormatAt = moment(`${edges.node.time.startDate} 11:59 PM`);
            edges.node.end = endsFormatAt.format('lll');
        }
        // edges.node.quickLinks = edges.node.quickLinks.filter(link => link.name !== '' && link.url !== '');
        return edges.node;
    }
    const edges = source.edges;
    this.eventsTotalCount = source.totalCount;
    let events = [];
    if (edges) {
        events = edges.map(mapEvents);
    }
    console.log('Events List : ', events);
    this.eventsUnobserved = cloneDeep(events);
    this.events = events;
    this.eventIsLoading = false;
}

export async function upsertEvents (params) {
    const {
        name,
        id,
        alertData,
        data,
        time,
        socialData,
        /*
        startDateTime,
        endDateTime,
        startTime,
        startDate,
        endTime,
        endDate,
        */

        quickLinks,
    } = params;
    const publisher = this.env.userStore.publisher;
    this.env.errorStore.action = 'updateEvents';
    this.eventIsLoading = true;
    const inputArray = [];

    const quickLinksData = quickLinks.map(link => {
        delete link.id;
        return link;
    });
    const filteredQuickLinks = quickLinksData.filter(link => link.name !== '' && link.url !== '');
    console.log('quickLinksData filtered', quickLinksData);
    const eventToUpdate = {
        scheduleId: this.selectedScheduleId,
        eventId: id,
        name,
        alert: alertData,
        data,
        time,
        social: socialData,
        quicklinks: filteredQuickLinks,
        draft: data.draft,
    };
    /* Removed unneccessary edit fields */
    delete eventToUpdate.time.startGMT;
    delete eventToUpdate.time.endGMT;
    delete eventToUpdate.data.draft;
    delete eventToUpdate.data.primaryLink;
    delete eventToUpdate.data.secondaryLink;
    delete eventToUpdate.data.shortURL;

    /*
    const startDateObj = moment(startDate);
    const endDateObj = moment(endDate);
    const startTimeObj = moment(startTime);
    const endTimeObj = moment(endTime);

    eventToUpdate.time.startDate = startDateObj.format('yyyy-MM-DD');
    eventToUpdate.time.startTime = startTimeObj.format('HH:mm');
    eventToUpdate.time.endDate = endDateObj.format('yyyy-MM-DD');
    eventToUpdate.time.endTime = endTimeObj.format('HH:mm');
    */

    if (eventToUpdate.time.allDay) {
        delete eventToUpdate.time.startTime;
        delete eventToUpdate.time.endDate;
        delete eventToUpdate.time.endTime;
    }

    inputArray.push(eventToUpdate);
    logInfo('EventStore.actions.upsertEvents() -> ', eventToUpdate);
    if (id !== 0 && id !== '') {
        const editRes = await apiUpsertEvents({
            events: inputArray,
            token: publisher.token,
            traceId: this.env.traceId,
        });
        logInfo('Event updated/added: ', editRes);
    } else {
        delete eventToUpdate.eventId;
        delete eventToUpdate.draft;
        const createRes = await apiCreateEvent({
            events: eventToUpdate,
            token: publisher.token,
            traceId: this.env.traceId,
        });
        logInfo('Event created: ', createRes);

        const metadata = {
            event_name: name,
            schedule_id: eventToUpdate.scheduleId,
            ip: this.env.userStore.IP || '',
        };

        logInfo('Will Track Intercom Event: ', metadata);
        window.Intercom(
            'trackEvent',
            'Created event by add',
            metadata);
    }
    this.eventIsLoading = false;

    this.env.history.push(`/admin/schedules/${this.selectedScheduleId}/events?scheduleName=${this.selectedScheduleName}`);
}

export function updateEventPage (page) {
    this.eventIsLoading = true;
    this.eventsPage = page;
    this.searchOrGetEvents();
}

export function searchOrGetEvents () {
    this.eventIsLoading = true;
    this.events = [];
    if ((!this.eventSearchPrev && this.eventSearchText) ||
         (this.eventSearchPrev && !this.eventSearchText)
    ) {
        this.eventsPage = 0;
        this.eventSearchPrev = this.eventSearchText;
    }

    if (this.eventSearchText) {
        console.log('Make Search event');
        this.searchEvents({
            name: this.eventSearchText,
            draftStatus: this.eventFilter,
        });
    } else if (typeof this.eventFilter === 'boolean') {
        // this.eventsPage = 0;
        const draftStatus = this.eventFilter;
        this.searchEvents({
            draftStatus: draftStatus,
        });
    } else {
        console.log('Get event');
        const id = this.selectedScheduleId;
        this.getEvents(id);
    }
}

export async function searchEvents ({ name, draftStatus }) {
    this.scheduleIsLoading = true;
    this.env.errorStore.action = 'searchEvents';
    const publisher = this.env.userStore.publisher;
    const res = await apiSearchEvents({
        name,
        draftStatus,
        page: this.eventsPage + 1,
        pageLength: this.eventRowsPerPage,
        scheduleId: this.selectedScheduleId,

        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data &&
        res.data.data &&
        res.data.data.getEvents) {
        this.renderEvents(res.data.data.getEvents);
    }
}

export function eventDataFromSelectedItem () {
    if (!this.eventsUnobserved) {
        return;
    }
    const id = this.eventEditTarget.id;
    const data = this.eventsUnobserved.filter((item) => (item.id === id))[0];
    this.eventEditTarget.selectedData = data;
}

export function duplicateEventDataFromSelectedItem () {
    if (!this.eventsUnobserved) {
        return;
    }
    const name = this.eventEditTarget.name;
    const data = this.eventsUnobserved.filter((item) => (item.name === name))[0];
    data.id = '';
    this.eventEditTarget.selectedData = data;
}

export function getEventsFromSelection (selection) {
    const eventIds = [];
    for (let i = 0; i < selection.length; i++) {
        const index = selection[i].index;
        const event = this.eventsUnobserved[index];

        eventIds.push(event.id);
    }
    return eventIds;
}

export function getEventsFromSelectionForUpdate (selection, action) {
    const events = [];
    for (let i = 0; i < selection.length; i++) {
        const index = selection[i].index;
        const event = this.eventsUnobserved[index];

        const newEvent = {
            eventId: event.id,
            scheduleId: this.selectedScheduleId,
        };
        if (action === 'draft') {
            newEvent.draft = true;
        } else if (action === 'live') {
            newEvent.draft = false;
        }
        events.push(newEvent);
    }
    return events;
}

export function makeEventsLive (selection) {
    const events = this.getEventsFromSelectionForUpdate(selection.data, 'live');
    console.log('');
    console.log('EventService.makeEventsLive()', selection);
    console.log('Events: ', events);
    this.bulkUpdateEventsStatus({ events });
}

export function makeEventsDraft (selection) {
    const events = this.getEventsFromSelectionForUpdate(selection.data, 'draft');
    console.log('');
    console.log('eventsService.makeeventsDraft()', selection);
    console.log('events: ', events);
    this.bulkUpdateEventsStatus({ events });
}

export function deleteEvents (selection) {
    const eventIds = this.getEventsFromSelection(selection.data);
    console.log('');
    console.log('EventService.deleteEvents()', selection);
    console.log('Events: ', eventIds);
    this.bulkDeleteEvents({ eventIds });
}

export async function bulkUpdateEvents ({ events }) {
    const publisher = this.env.userStore.publisher;

    this.env.errorStore.action = 'updateBulkEvents';
    const res = await apiBulkUpdateEvents({
        events,
        token: publisher.token,
        traceId: this.env.traceId,
    });
    this.eventIsLoading = true;
    console.log('Responses: ', res);
    // this.eventsPage = 0;
    this.searchOrGetEvents();
    this.eventIsLoading = false;
}

export async function bulkUpdateEventsStatus ({ events }) {
    const publisher = this.env.userStore.publisher;

    this.env.errorStore.action = 'updateBulkEventsStatus';
    const res = await apiBulkUpdateEventsStatus({
        events,
        token: publisher.token,
        traceId: this.env.traceId,
    });
    this.eventIsLoading = true;
    console.log('Responses: ', res);
    // this.eventsPage = 0;
    this.searchOrGetEvents();
    this.eventIsLoading = false;
}

export async function bulkDeleteEvents ({ eventIds }) {
    this.eventIsLoading = true;
    const publisher = this.env.userStore.publisher;
    this.env.errorStore.action = 'deleteEvents';
    const res = await apiBulkDeleteEvents({
        eventIds,
        scheduleId: this.selectedScheduleId,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Responses: ', res);
    // this.eventsPage = 0;
    this.eventIsLoading = false;
    this.searchOrGetEvents();
}

export async function uploadEventBanner ({ file, assetType }) {
    this.env.errorStore.action = 'uploadEventBanner';
    const res = await this.env.userStore.uploadAssets({
        file,
        assetType,
    });
    console.log('uploadEventBanner(): response:: ', res);
    this.assets.eventBanner = res.data.data;
    this.observed.assets.eventBanner = uuid();
}
