import React from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';
import Timezones from '../../../commons/util/Timezone.json';
import Reminders from '../../../commons/util/Reminders.json';
import ButtonProgress from '../../../commons/ButtonProgress';
import Select from '../../../commons/select/Select';
import Dialog from '../../../commons/dialog/Dialog';
import useStyles from './styles';
import useDateTimeInput from './useDateTimeInput';
const BasicDetails = (props) => {
    const {
        pageTitle,
        input,
        setInput,
        updateEvents,
        isManagedByFeed,
        isManagedByChild,
        hasEventId,
        referenceName,
        eventId,
        isLoading,
        errors,
        saveDisabled,
        setHasValidationFieldError,
    } = props;
    const classes = useStyles();

    const {
        startDate,
        startTime,
        endDate,
        endTime,
        allDay,
        onStartDateChange,
        onStartTimeChange,
        onEndDateChange,
        onEndTimeChange,
        dateError,
        openError,
        setOpenError,
        setIsTouched,
    } = useDateTimeInput({ input, setInput, setHasValidationFieldError });

    function renderEventId () {
        if (hasEventId) {
            if (isManagedByFeed || isManagedByChild) {
                return (
                    <React.Fragment>
                        {isManagedByChild && <>
                            <Typography
                                block
                                style={{ marginBottom: 18 }}
                            >
                                This event is managed by a child account.
                            </Typography>
                        </>}
                        {isManagedByFeed && <>
                            <Typography
                                block
                                // style={{ marginBottom: 30 }}
                            >
                                This event is managed by a feed.
                            </Typography>
                            <Typography
                                block
                                style={{
                                    // marginBottom: 30,
                                    fontSize: 12,
                                 }}
                            >
                                EventId: {eventId}
                            </Typography>
                            <Typography
                                block
                                className={classes.textStyleBottomLine}
                            >
                                Reference: {referenceName}
                            </Typography>
                        </>}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <Typography
                            block
                            // style={{ marginBottom: 30 }}
                        >
                            Please complete all required fields*
                        </Typography>
                        <Typography
                            block
                            className={classes.textStyleBottomLine}
                        >
                            EventId: {eventId}
                        </Typography>
                    </React.Fragment>
                );
            }
        } else {
            return (
                <Typography
                    block
                    style={{ marginBottom: 30 }}
                >
                    Please complete all required fields*
                </Typography>
            );
        }
    }

    return (
        <Grid item className={classes.mainContainer} justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={600}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    {pageTitle} Event
                </Typography>
                { renderEventId() }
                <TextField
                    id='title'
                    label='Title'

                    name='name'
                    value={input.name}
                    disabled={isManagedByFeed || isManagedByChild}
                    onChange={(e) => {
                        setInput({
                            ...input,
                            name: e.target.value,
                        });
                    }}
                    error={errors.name}
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    inputProps={{
                        'data-intercom-target': 'event-title',
                    }}
                    helperText='*Please enter event title'
                />
                <TextField
                    id='location'
                    label='Location'
                    name='location'
                    value={input.data.location}
                    disabled={isManagedByFeed || isManagedByChild}
                    onChange={(e) => {
                        setInput({
                            ...input,
                            data: { ...input.data, location: e.target.value },
                        });
                    }}
                    error={errors.location}
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    helperText='*Please enter event location.'

                    inputProps={{
                        'data-intercom-target': 'event-location',
                    }}
                />

                <Grid container>
                    <Grid item xs={6} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                color='default'
                                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                checkedIcon={<CheckBoxOutlinedIcon fontSize='small' />}
                                checked={input.time.allDay}
                                onChange={(e) => {
                                    setIsTouched(true);
                                    setInput({
                                        ...input,
                                        time: { ...input.time, allDay: e.target.checked },
                                    });
                                }}
                                />
                            }
                            label='All day'
                            style={{ marginBottom: 35 }}
                            disabled={isManagedByFeed || isManagedByChild}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Select
                            // label= 'timezone'
                            name= 'timezone'
                            id= 'timezone'
                            labelHeading= 'Timezone'
                            placeHolder= 'Please select timezone'
                            value={input.time.timezone}
                            handleChange={(e) => {
                                setInput({
                                    ...input,
                                    time: { ...input.time, timezone: e.target.value },
                                });
                            }}
                            dataForDropdown={Timezones}
                            helperText={
                                '*Timezone'
                            }
                            disabled={isManagedByFeed || isManagedByChild}

                            inputProps={{
                                'data-intercom-target': 'event-timeozone',
                            }}
                        />
                    </Grid>

                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid
                        container
                        style={{
                            justifyContent: 'space-between',
                            marginBottom: 47,
                        }}>

                        <KeyboardDatePicker
                            // autoOk
                            margin='normal'
                            id='date-picker-dialog'
                            label='Start date'
                            /* format="MMM d, yyyy" */
                            format='dd / MM / yyyy'
                            value={startDate}
                            onChange={(date) => {
                                clearTimeout(window.startDateTO);
                                window.startDateTO = setTimeout(() => {
                                    onStartDateChange(date);
                                }, 500);
                            }}

                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                            disabled={isManagedByFeed || isManagedByChild}
                            error={dateError}
                            helperText={dateError ? 'Invalid Date' : ''}
                            inputProps={{
                                readOnly: false,
                                'data-intercom-target': 'event-start-date',
                            }}

                        />

                        <KeyboardTimePicker
                            margin='normal'
                            id='time-picker'
                            label='Start time'

                            value={startTime}
                            onChange={(date) => {
                                clearTimeout(window.startTimeTO);
                                window.startTimeTO = setTimeout(() => {
                                    onStartTimeChange(date);
                                }, 500);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            disabled={isManagedByFeed || allDay || isManagedByChild}
                            error={dateError}
                            helperText={dateError ? 'Invalid Date' : ''}

                            inputProps={{
                                readOnly: false,
                                'data-intercom-target': 'event-start-time',
                            }}
                        />

                    </Grid>

                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid container style={{ justifyContent: 'space-between', marginBottom: 47 }}>

                      <KeyboardDatePicker
                        clearable
                        disabled={isManagedByFeed || allDay || isManagedByChild}

                        margin='normal'

                        id='date-picker-dialog2'

                        label='End date'
                        format="dd / MM / yyyy"
                        value={endDate}
                        onChange={(date) => {
                            clearTimeout(window.endDateTO);
                            window.endDateTO = setTimeout(() => {
                                onEndDateChange(date);
                            }, 500);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}

                        error={dateError}
                        helperText={dateError ? 'Invalid Date' : ''}

                        inputProps={{
                            readOnly: false,
                            'data-intercom-target': 'event-end-date',
                        }}
                      />

                      <KeyboardTimePicker
                        clearable
                        disabled={isManagedByFeed || allDay || isManagedByChild}

                        margin='normal'
                        id='time-picker2'
                        label='End time'
                        value={endTime}
                        onChange={(date) => {
                            clearTimeout(window.endTimeTO);
                            window.endTimeTO = setTimeout(() => {
                                onEndTimeChange(date);
                            }, 500);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}

                        error={dateError}
                        helperText={dateError ? 'Invalid Date' : ''}
                        inputProps={{
                            readOnly: false,
                            'data-intercom-target': 'event-end-time',
                        }}
                      />
                    </Grid>
                </MuiPickersUtilsProvider>

                <Box
                    // style={{ marginBottom: 35 }}
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}
                >
                    <Grid spacing={10} container>
                        <Grid item xs={6} >
                            <Select
                                // label= 'reminder1'
                                name= 'reminder1'
                                id= 'event-reminder1'
                                labelHeading= 'Reminder 1'
                                value={input.alertData.alert1}
                                handleChange={(e) => {
                                    setInput({
                                        ...input,
                                        alertData: { ...input.alertData, alert1: e.target.value },
                                    });
                                }}
                                dataForDropdown={Reminders}
                                helperText='Please enter reminder 1'
                                disabled={isManagedByFeed || isManagedByChild}
                                inputProps={{
                                    'data-intercom-target': 'event-reminders',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <Select
                                value={input.alertData.alert2}
                                handleChange={(e) => {
                                    setInput({
                                        ...input,
                                        alertData: { ...input.alertData, alert2: e.target.value },
                                    });
                                }}
                                // label= 'reminder1'
                                name= 'reminder2'
                                id= 'event-reminder2'
                                labelHeading= 'Reminder 2'
                                dataForDropdown={Reminders}
                                helperText='Please enter reminder 2'
                                disabled={isManagedByFeed || isManagedByChild}
                                // helperText={
                                //     signUpError.industry || 'What\'s your industry?*'
                                // }
                                // error={signUpError.industry}
                                inputProps={{
                                    'data-intercom-target': 'event-alert',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <TextField
                  id='outlined-multiline-static'
                  label='Details'
                  name='details'
                  multiline
                  rows='6'
                  defaultValue=''
                  helperText='eg: Media details, event description, calls to action, ticket pricing etc.'
                  margin='normal'
                  variant='outlined'
                  value={input.data.details}
                  onChange={(e) => {
                    setInput({
                        ...input,
                        data: { ...input.data, details: e.target.value },
                    });
                  }}
                  style={{ marginBottom: 35, marginTop: 0 }}
                  disabled={isManagedByFeed || isManagedByChild}

                    inputProps={{
                        'data-intercom-target': 'event-details',
                    }}
                />
                <TextField
                  disabled={isManagedByChild}
                  id='outlined-multiline-static'
                  label='Promotional/ Sponsored message'
                  name='sponsoredMessage'
                  value={input.data.sponsoredMessage}
                  onChange={(e) => {
                    setInput({
                        ...input,
                        data: { ...input.data, sponsoredMessage: e.target.value },
                    });
                  }}
                  multiline
                  rows='6'
                  defaultValue=''
                  helperText='eg: Sponsor mention, promotional blurb, offer code etc.'
                  margin='normal'
                  variant='outlined'
                  style={{ marginBottom: 35, marginTop: 0 }}

                    inputProps={{
                        'data-intercom-target': 'event-promo',
                    }}
                />
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={isManagedByChild || saveDisabled}
                        showProgress={isLoading}
                        label='Save'
                        onClick={() => {
                            console.log('Save event');
                            updateEvents();
                        }}
                        dataIntercomTarget='event-save-button'
                    />
                </Box>
            </Box>

            <Dialog
              openDialog={openError}
              handleAgree={() => {
                setOpenError(false);
              }}

              closeModal={() => {
                setOpenError(false);
              }}
              buttonSecondaryFlag={false}
              buttonPrimaryFlag
              buttonPrimary="ok"
              withCloseIcon
              title={'Invalid Date'}
              dialogContextText={'End date should be greater than Start date'}
            />

        </Grid>
    );
};

BasicDetails.propsTypes = {
    input: PropTypes.object,
    setInput: PropTypes.func,
    updateEvents: PropTypes.func,
    pageTitle: PropTypes.string,
    isManagedByFeed: PropTypes.bool,
    isManagedByChild: PropTypes.bool,
    hasEventId: PropTypes.bool,
    referenceName: PropTypes.string,
    eventId: PropTypes.string,
    isLoading: PropTypes.bool,
    hasValidationFieldError: PropTypes.bool,
    saveDisabled: PropTypes.bool,
};

BasicDetails.defaultProps = {
    input: {},
    setInput: () => {},
    updateEvents: () => {},
    pageTitle: '',
    isManagedByFeed: false,
    isManagedByChild: false,
    hasEventId: false,
    referenceName: '',
    eventId: '',
    isLoading: false,
    hasValidationFieldError: false,
    saveDisabled: true,
};

export default observer(BasicDetails);
