import {
    get,
    post,
    put,
    deleteCall,
    endpoints,
} from '../../api';

export function apiGetOptins (options) {
    return get(
        endpoints().getOptins,
        options
    );
}

export function apiAddOptins ({ body, options }) {
    return post(
        endpoints().getOptins,
        body,
        options
    );
}

export function apiEditOptins ({ body, options }) {
    return put(
        endpoints().getOptins + '/' + body.id,
        body,
        options
    );
}

export function apiDeleteOptins ({ body, options }) {
    return deleteCall(
        endpoints().getOptins + '/' + body.id,
        options
    );
}
