import React from 'react';
// import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Typography } from '../../components/Wrappers';
import useStyles from './table.styles';

function TableHeadings (props) {
    const {
        // onSelectAllClick,
        order,
        orderBy,
        // numSelected,
        // rowCount,
        onRequestSort,
        headCells,
    } = props;

    const classes = useStyles();
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map(headCell => (
                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography
                                noWrap
                                weight={'medium'}
                                variant={'body2'}
                            >
                                {headCell.label}
                            </Typography>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
TableHeadings.propTypes = {

    //   title: PropTypes.string,
    //   dialogContextText: PropTypes.string,
    //   buttonPrimary: PropTypes.string,
    //   buttonSecondary: PropTypes.string,
    //   handleAgree: PropTypes.func,
};

TableHeadings.defaultProps = {
    //   title: 'Are you sure that you want to delete this?',
    //   dialogContextText: 'This will be deleted.',
    //   buttonPrimary: 'Yes',
    //   buttonSecondary: 'No',
    //   handleAgree: undefined,
};

export default TableHeadings;
