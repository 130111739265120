import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
} from '@material-ui/core';

import {
    Typography,
//    Button,
} from '../../../../components/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import useOptions from './useOptions';
import Advertising from './advertising';
import Sharing from './sharing';
import WelcomeMessage from './welcomemessage';
// import Tracking from './tracking';

import Timezone from './timezone';
/*
    <Tracking optionData={optionData} setOptionData={setOptionData} />
*/
function Options (props) {
    const classes = useStyles();

    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
        errors,
    } = props;

    const {
        adsLogo,
        uploadAdsLogo,
        deleteAdsLogo,
        optionData,
        setOptionData,
        isUploading,
        adsLogoError,
    } = useOptions({
        dataSource,
        onDataChange,
        updateSavable,
    });

    return (
        <React.Fragment>
            <Grid
                item
                className={classes.mainContainer}
                justify={'center'}
                container>

                <Grid
                    className={classes.titleBase}
                    >
                    <Typography
                        variant='h5'
                        weight='medium'
                    >
                        More Options
                    </Typography>
                </Grid>

                <Advertising
                    isUploading={isUploading}
                    adsLogo={adsLogo}
                    optionData={optionData}
                    setOptionData={setOptionData}
                    uploadAdsLogo={uploadAdsLogo}
                    deleteAdsLogo={deleteAdsLogo}
                    errors={errors}
                    adsLogoError={adsLogoError}
                />

                <WelcomeMessage
                  optionData={optionData}
                  setOptionData={setOptionData}
                />

                <Sharing
                    optionData={optionData}
                    setOptionData={setOptionData}
                />

                <Timezone
                    optionData={optionData}
                    setOptionData={setOptionData}
                />

                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>
        </React.Fragment>
    );
};

Options.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
};

Options.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
};

export default observer(Options);
