import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import {
    Grid,
    Box,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Typography, Link } from '../../components/Wrappers';
import TableContainer from '../../commons/table/TableContainer';
import useErrorNotification from '../../store/models/error/useErrorNotification';

import Dialog from '../../commons/dialog/Dialog';
import ErrorContainer from '../../commons/errorContainer/Error';
import Notification from '../../components/Notification/Notification';
import Widget from '../../components/Widget';
import useStyles from './webhook.styles';
import ProgressBar from '../../commons/progress/Progress';
import {
    useWebhooksStore,
    useUserStore,
    useEnvStore,
} from '../../store/models';
import useWebhookErrors from '../../store/models/webhook/errors/useWebhookErrors';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';

function Webhook () {
    const history = useHistory();
    const webhooksStore = useWebhooksStore();
    const userStore = useUserStore();
    const envStore = useEnvStore();

    const { errors } = useWebhookErrors({});
    const { renderErrorDialog } = useModuleErrorDialog({ errors });

    const [webhookData, setWebhookData] = useState([]);
    const [dialogFlag, setDialogFlag] = useState(false);
    const [webhookDeleteID, setWebhookDeleteID] = useState('');
    const {
        hasError,
        title,
        message,
      } = useErrorNotification();

    function sendNotification (text) {
        const componentProps = {
            type: 'feedback',
            message: text,
            variant: 'contained',
            color: 'success',
        };
        const options = {
            type: 'info',
            position: toast.POSITION.TOP_CENTER,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 200,
        };
        return toast(
            <Notification
            {...componentProps}
            className={classes.notificationComponent}
            />,
            options
        );
    }

    useEffect(() => {
        // if (webhooksStore.webhooks === null) {
            webhooksStore.getWebhooks({
                user: userStore.user,
                publisher: envStore.publisher,
            });
        // }
    }, []);

    useEffect(() => {
        console.log('webhooks received data', webhooksStore.webhooks);
        // setWebhookData({
        //     ...webhooksStore.webhooks,
        // });
        setWebhookData(webhooksStore.webhooks);
    }, [webhooksStore.webhooks]);

    useEffect(() => {
        // if (webhooksStore.notificationText !== '' && (webhooksStore.notificationKey !== webhooksStore.previousNotificationKey)) {
            if (webhooksStore.notificationFlag) {
                sendNotification(webhooksStore.notificationText);
                webhooksStore.notificationFlag = false;
            }
        // }
    }, [webhooksStore.notificationKey]);

    const deleteWebhook = () => {
        setDialogFlag(false);
        webhooksStore.deleteWebhook({
            webhookId: webhookDeleteID,
            publisher: envStore.publisher,
        });
    };

    const saveWebhookFunction = (webhook, id) => {
        // webhook.status = webhook.status === 0 ? 1 : 0;
        // const webhookData = webhook;
        const webhookData = Object.assign({}, webhook);
        webhookData.status = webhookData.status === 0 ? 1 : 0;
        webhooksStore.saveWebhooks({
            body: webhookData,
            publisher: envStore.publisher,
            webhookId: id,
        });
    };

    const classes = useStyles();
    console.log('webhook state', webhookData);

    const openModal = (cell) => {
        setWebhookDeleteID(cell);
        setDialogFlag(true);
    };
    const closeModal = () => {
        setDialogFlag(false);
    };

    const changeStatus = (cell) => {
        saveWebhookFunction(cell, cell.id);
    //   actions.doOpenConfirm(cell)(managementDispatch);
    };

    const handleAddWebhook = (e) => {
        e.preventDefault();
        history.push('/admin/developers/webhooks/add');
    };

    const headCells = [
        { id: 'url', numeric: true, disablePadding: false, label: 'Callback URL' },
        { id: 'secret', numeric: true, disablePadding: false, label: 'Secret Key' },
        {
            id: 'createdOn',
            numeric: true,
            disablePadding: false,
            label: 'Created On',
        },
        { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
    ];

    return (
        <React.Fragment>

            <Grid container>
                {hasError
                ? <ErrorContainer
                    title={title}
                    dialogContextText={message}
                    openDialog={true}
                />
                : null}
                <Dialog
                    openDialog={dialogFlag}
                    handleAgree={deleteWebhook}
                    closeModal={closeModal}
                    title={'Delete webhook'}
                    dialogContextText={'Are you sure you want to delete this webhook?'}
                />
                { webhooksStore.webhook_progressBar
    ? <ProgressBar />
                : <Grid item xs={12}>
                    <Widget className={classes.widgetContainer} title="Manage Webhooks" inheritHeight disableWidgetMenu handleAdd={handleAddWebhook} >
                        <Box
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'flex-start'}
                        >
                            <Typography variant='body2'>
                                Set up webhooks to capture the user details as they sign up to your schedules and <Link target='_blank' href={'https://docs.ecal.com/crm/webhook.html'} color="blue">
                                click here
                                                                    </Link> to learn more.
                            </Typography>
                        </Box>
                    </Widget>
                    <TableContainer
                    tableData = {webhookData}
                    headCells = {headCells}
                    tableTitle = 'Webhooks'
                    moduleType='webhooks'
                    changeStatus = {changeStatus}
                    openModal = {openModal}
                    skeletonFlag = {webhooksStore.skeletonFlag}
                    // deleteWebhook = {deleteWebhookFunction}
                    saveWebhook = {saveWebhookFunction}
                    showStatusTooltip={true}
                    />
                </Grid>
                }
            </Grid>

            { renderErrorDialog() }
        </React.Fragment>
    );
}

export default observer(Webhook);
