import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    Tab,
    Tabs,
} from '@material-ui/core';

import {
    Person as PersonIcon,
    Lock as LockIcon,
    Payment as BillingIcon,
    SettingsApplications as SettingsIcon,
} from '@material-ui/icons';

import Widget from '../../../components/Widget';

import { useStyles } from '../profile.styles';

function ProfileTabs (props) {
    const {
        onTabChange,
        tabIndex = 0,
    } = props;
    const [tab, setTab] = useState(tabIndex);
    const classes = useStyles();

    function handleTabChange (event, newValue) {
        setTab(newValue);
        onTabChange(newValue);
    }

    useEffect(() => {
        setTab(tabIndex);
    }, [tabIndex]);
    return (
        <Grid item xs={12}>
            <Widget>
                <Box display={'flex'} justifyContent={'center'}>
                    <Tabs
                        indicatorColor='primary'
                        textColor='primary'
                        value={tab}
                        onChange={handleTabChange}
                        aria-label='Tabs'
                    >
                        <Tab
                            label='Profile'
                            icon={<PersonIcon />}
                            classes={{ wrapper: classes.icon }}
                        />

                        <Tab
                            label='Account'
                            icon={<SettingsIcon />}
                            classes={{ wrapper: classes.icon }}
                        />

                        <Tab
                            label='Organisation'
                            icon={<LockIcon />}
                            classes={{ wrapper: classes.icon }}
                        />
                        <Tab
                            label='Plan'
                            icon={<BillingIcon />}
                            classes={{ wrapper: classes.icon }}
                        />
                    </Tabs>
                </Box>
            </Widget>
        </Grid>
    );
}

ProfileTabs.propTypes = {
    onTabChange: PropTypes.func,
};

ProfileTabs.defaultProps = {
    onTabChange: () => {},
};

export default ProfileTabs;
