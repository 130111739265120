import React, { useState, useEffect } from 'react';
import moment from 'moment';
// import classnames from 'classnames';
import { observer } from 'mobx-react';
import {
    Grid,
    Tab,
    Box,
} from '@material-ui/core';

import {
    Dehaze as DehazeIcon,
    Category as CategoryIcon,
    PhotoLibrary as PhotoLibraryIcon,
} from '@material-ui/icons';

import {
    Typography,
    Button,
} from '../../../components/Wrappers/Wrappers';

import Dialog from '../../../commons/dialog/Dialog';
import PageExitDialog from '../../../commons/dialog/PageExitDialog';

import Tabs from '../../../commons/tabs';
import EntryForm from './ManualEntryForm';
import CategoryTreeStructure from '../../../components/TreeComponent/TreeComponent';

import CategoryMenu from './CategoryMenu';
import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';

import useStyles from '../schedule.styles';

import { useSchedulesStore } from '../../../store/models';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';
// import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';

import useScheduleInput from './useScheduleInput';
import useCategoryInput from './useCategoryInput';
import useVersion from './useVersion';
import EventBanner from './ScheduleEventBanner';

function ManualEntryForm (props) {
    const {
        location,
    } = props;
    const [tab, setTab] = useState(0);
    const classes = useStyles();
    const schStore = useSchedulesStore();

    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return 'edit';
        }
        return 'add';
    }
    const [mode, setMode] = useState(getMode());
    const [dataLoaded, setDataLoaded] = useState(false);
    const [lastDataLoad, setLastDataLoad] = useState(moment().calendar());
    const [isDirty, setIsDirty] = useState(false);

    const {
        input,
        setInput,
        saveSchedule,
        title,
        imageError,
        uploadImage,
        logo,
        errors,
        hasError,
        isCalendarLogoUploading,
        deleteLogo,
        logoImageError,
        saveDisabled,
        updateInput,

        genres,
        subGenres,
        showSubGenres,
        categoryLoadingMessage,
    } = useScheduleInput({
        defaultInput: {
            name: '',
            reference: '',
            logo: '',
            banner: '',
            type: 'fixture',
            genre: '',
            subGenre: '',
        },
        setIsDirty,
        mode,
        dataLoaded,
    });

    const {
        checked,
        nodes,
        expanded,
        selectedCategory,
        setSelectedCategory,
        openAddDialog,
        setOpenAddDialog,
        openDeleteDialog,
        setOpenDeleteDialog,
        menu,
        setMenu,
        onFilterChange,
    } = useCategoryInput();

    const {
        schedulesV2,
        scheduleTypes,
    } = useVersion();

    useEffect(() => {
        if (location.pathname.indexOf('edit') !== -1) {
            setMode(getMode());
        }
    }, []);

    useEffect(() => {
      if (nodes.length) {
        setLastDataLoad(moment().calendar());
      }
    }, [nodes]);

    function initStore () {
        schStore.filterText = '';
        if (mode === 'edit') {
            schStore.loadEditTarget();
            setDataLoaded(true);
        }
        schStore.getRootCategories();
    }

    useStoreModuleDependency({ initStore });

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({ errors });

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Tabs
                    onTabChange={(newTab) => {
                        console.log('Tab ', newTab);
                        setTab(newTab);
                    }}
                >
                    <Tab
                        label="Details"
                        icon={<DehazeIcon />}
                        classes={{ wrapper: classes.icon }}
                        data-intercom-target={'schedule-details-tab'}
                    />

                    <Tab
                        label="Categories"
                        icon={<CategoryIcon />}
                        classes={{ wrapper: classes.icon }}
                        data-intercom-target={'schedule-categories-tab'}
                    />

                    <Tab
                        label="Banner"
                        icon={<PhotoLibraryIcon />}
                        classes={{ wrapper: classes.icon }}
                        data-intercom-target={'schedule-event-banner-tab'}
                    />
                </Tabs>
                <Grid item xs={12}>

                    { tab === 0 && (
                        <EntryForm
                            name={input.name}
                            errors={errors}
                            isLoading={schStore.scheduleIsLoading}
                            hasError={hasError}
                            setName={(value) => {
                                setInput({
                                    ...input,
                                    name: value,
                                });
                                setIsDirty(true);
                            }}
                            reference={input.reference}
                            setReference={(value) => {
                                setInput({
                                    ...input,
                                    reference: value,
                                });
                                setIsDirty(true);
                            }}
                            setIsDirty={setIsDirty}

                            saveSchedule={saveSchedule}
                            title={title}

                            imageError={imageError}
                            uploadImage={uploadImage}
                            deleteLogo={deleteLogo}
                            logoUploadProgress={isCalendarLogoUploading}
                            logoURL={logo}
                            setInput={setInput}
                            logoImageError={logoImageError}
                            saveDisabled={saveDisabled}
                            schedulesV2={schedulesV2}
                            scheduleTypes={scheduleTypes}
                            genres={genres}
                            subGenres={subGenres}
                            showSubGenres={showSubGenres}
                            updateInput={updateInput}
                            isChild={input.isChild}
                            input={input}
                        />
                    )}

                    { tab === 1 && (
                        <Grid item justifyContent={'center'} alignItems={'center'} className={classes.mainContainer} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    width={551}
                                    mt={6}
                                    mb={6}
                                    // className={classnames([classes.boxStyling])}
                                >
                                    <Typography
                                        variant={'h5'}
                                        weight={'medium'}
                                        style={{ marginBottom: 30 }}
                                    >
                                        Categories
                                    </Typography>
                                    <Typography
                                        // variant={'body1'}
                                        // weight={'medium'}
                                        block
                                        style={{ marginBottom: 30 }}
                                    >
                                        Categories allow you to group common schedules together (eg: leagues, genres, business units).<br />
                                        <br />
                                        If you create multiple categories, these will be displayed as a menu on your buttons and displays, making it easy for users to browse and find the schedules they want across your categories.
                                    </Typography>

                                    <CategoryTreeStructure
                                            openAddDialog={openAddDialog}

                                            nodes={nodes}
                                            checked={checked}
                                            expanded={expanded}
                                            nodesFiltered={nodes}

                                            loadingIndicator={schStore.loadingTree}
                                            loadingMessage={categoryLoadingMessage}
                                            filterText={schStore.filterText}
                                            onLoadCategories={initStore}
                                            lastDataLoad={lastDataLoad}
                                            setLastDataLoad={setLastDataLoad}

                                            onFilterChange={onFilterChange}
                                            onCheck={(val) => {
                                                schStore.treeCheck(val);
                                                setIsDirty(true);
                                            }}
                                            onExpand={(val, targetNode) => {
                                                schStore.treeExpand(val, targetNode);
                                            }}

                                            onNodeClick={(node) => {
                                                /*
                                                console.log('on Node Click: ', node);
                                                schStore.getCategoriesByFilter({ parent: node.value });
                                                */
                                            }}

                                            onOpenMenu={(targetElement, data) => {
                                                console.log('');
                                                console.log('OPen Menu Anchore Element: ', targetElement);
                                                console.log('data -> ', data);
                                                setSelectedCategory(data);
                                                setMenu({
                                                    anchor: targetElement,
                                                    open: true,
                                                });
                                            }}

                                            onAddChildNode={({ name, parentId }) => {
                                                schStore.addCategory({ name, parentId });
                                                setOpenAddDialog(false);
                                            }}

                                            onCloseAddDialog={() => {
                                                setOpenAddDialog(false);
                                            }}

                                            onShowAddRootNode={() => {
                                                setOpenAddDialog(true);
                                            }}

                                            isChild={input.isChild}
                                    />
                                </Box>
                                <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                        width='100%'
                                        mb={6}
                                    >
                                        <Button
                                            data-intercom-target='schedule-save'
                                            disabled={input.isChild ? true : saveDisabled}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                saveSchedule();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                            </Box>
                        </Grid>
                    )}

                    { tab === 2 && (
                         <Grid
                            item
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            className={classes.mainContainer}
                            >
                            <EventBanner
                                pageTitle={title}
                                input={input}
                                setInput={updateInput}
                                hasValidationFieldError={hasError}
                                onSave={() => saveSchedule()}
                                isChild={input.isChild}
                            />
                        </Grid>
                    )}

                </Grid>
            </Grid>
            <CategoryMenu
                anchorEl={menu.anchor}
                open={menu.open}
                data={selectedCategory}

                onAdd={() => {
                    setMenu({
                        ...menu,
                        open: false,
                    });

                    setOpenAddDialog(true);
                }}
                onCloseMenu={() => {
                    setMenu({
                        ...menu,
                        open: false,
                    });
                }}

                onDelete={() => {
                    setMenu({
                        ...menu,
                        open: false,
                    });
                    setOpenDeleteDialog(true);
                }}
                isChild={input.isChild}
            />

            <Dialog
                openDialog={openDeleteDialog}
                handleAgree={() => {
                    console.log('Selected Category to Delete:', selectedCategory);
                    setOpenDeleteDialog(false);
                    schStore.deleteCategory({ categoryId: selectedCategory });
                }}

                closeModal={() => {
                    setOpenDeleteDialog(false);
                }}
                title={'Delete category'}
                dialogContextText={'Are you sure you want to delete this category?'}
            />

            <PageExitDialog
              isBlocked={isDirty}
              title="Are you sure?"
              content="You have unsaved changes to this schedule. Do you want to leave without saving?"
            />

            { renderErrorDialog() }
        </React.Fragment>

    );
}

export default observer(ManualEntryForm);
