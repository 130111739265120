import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',
        width: '100%',
    },

    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        boxShadow: theme.customShadows.widget,
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    tablePagination: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    statusCell: {
        color: '#fff',
        height: '20px',
        width: '65px',
        fontSize: '11px',
        fontWeight: 'bold',
    },

    nameCell: {
        width: '200px',
        fontSize: '14px',
    },

    subscriberCell: {
        width: '60px',
        fontSize: '14px',
    },

    subscriberCellTD: {
        width: '60px',
        fontSize: '14px',
    },

    text: {
        fontSize: '12px !important',
        padding: '4px',
    },

    actionCell: {
        width: '100px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    actionCellIcon: {
        padding: '2px',
        margin: '2px',
    },

    previewCell: {
        padding: '2px',
        margin: '2px',
        overflow: 'hidden',

        '& .ecal-sync-widget-button': {
            overflow: 'hidden',
            maxWidth: '200px !important',

            '& > div': {
                maxWidth: '200px',
                '& > object': {
                    maxWidth: '100%',
                },
            },
        },
    },

    previewImage: {
        width: '200px',
        cursor: 'pointer',
    },

    adsTable: {
     /*
      '& .ecal-sync-widget-button': {
        maxWidth: '200px !important',
        '& > img': {
          width: '100% !important',
          maxHeight: 80,
        },
      },
      */
    },
});

export default styles;
export const useStyles = makeStyles(styles);
