import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';
import {
  Grid,
  InputAdornment,
  Box,
  TextField,
} from '@material-ui/core';

// components
import {
  Typography,
} from '../../components/Wrappers/Wrappers';

import ButtonProgress from '../../commons/ButtonProgress';
import usePasswordChange from './usePasswordChange';

import { useStyles } from './passwordChange.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

function PasswordChange (props) {
  const {
    defaultPasswordData,
  } = props;

  const { whiteLabelSettings } = useWhiteLabel();
  const classes = useStyles({ whiteLabelSettings });

  const {
    data,
    renderConfirmDialog,
    renderErrorDialog,
    handleChange,

    showOldPassword,
    showNewPassword,
    showRetypePassword,

    setShowOldPassword,
    setShowNewPassword,
    setShowRetypePassword,

    isLoading,
    saveDisabled,
    savePassword,

    changePasswordError,

  } = usePasswordChange({
    defaultPasswordData,
    whiteLabelSettings,
  });

  return (
    <React.Fragment>

        <Grid container className={classes.container}>

            <div className={classes.bgContainer}>
                <img src={whiteLabelSettings.background} alt="logo" className={classes.brandBackgroundImage} />
            </div>

            <Grid className={classes.formContainer}>

              <Grid className={classes.form}>

                <Typography variant={'h5'} className={classes.instruction}>
                  Please update your password to signin.
                </Typography>

                <TextField
                    id="old-password"
                    label="*Old Password"
                    onChange={handleChange}
                    name="oldPassword"
                    value={data.oldPassword || ''}
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    helperText={ changePasswordError.oldPassword || 'Please enter your old password' }
                    error={ changePasswordError.oldPassword }
                    InputProps={{
                        type: showOldPassword ? 'text' : 'password',
                        endAdornment: (
                            <InputAdornment position="start">
                                    <Box onClick={() => {
                                        setShowOldPassword(!showOldPassword);
                                    }}>
                                    <FontAwesomeIcon icon={ showOldPassword ? faEye : faEyeSlash } />
                                    </Box>
                            </InputAdornment>
                        ),

                    }}
                />

                <TextField
                    id="new-password"
                    label="*New Password"
                    onChange={handleChange}
                    name="newPassword"
                    value={data.newPassword || ''}
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    helperText={ changePasswordError.newPassword || 'Please enter your new password' }
                    error={ changePasswordError.newPassword }
                    InputProps={{
                        type: showNewPassword ? 'text' : 'password',
                        endAdornment: (
                            <InputAdornment position="start">
                                    <Box onClick={() => {
                                        setShowNewPassword(!showNewPassword);
                                    }}>
                                    <FontAwesomeIcon icon={ showNewPassword ? faEye : faEyeSlash } />
                                    </Box>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    id="old-password"
                    label="*Re-enter Password"
                    onChange={handleChange}
                    name="rePass"
                    value={data.rePass || ''}
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    helperText={ changePasswordError.rePass || 'Please re-enter your new password' }
                    error={ changePasswordError.rePass }
                    InputProps={{
                        type: showRetypePassword ? 'text' : 'password',
                        endAdornment: (
                            <InputAdornment position="start">
                                    <Box onClick={() => {
                                        setShowRetypePassword(!showRetypePassword);
                                    }}>
                                    <FontAwesomeIcon icon={ showRetypePassword ? faEye : faEyeSlash } />
                                    </Box>
                            </InputAdornment>
                        ),
                    }}
                />
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={isLoading}
                        label="Change Password"
                        onClick={(e) => {
                            savePassword();
                        }}
                    />

                </Box>
              </Grid>
            </Grid>

        </Grid>

        { renderErrorDialog() }
        { renderConfirmDialog() }
    </React.Fragment>
  );
}

PasswordChange.propTypes = {
  defaultPasswordData: PropTypes.object,
};

PasswordChange.defaultProps = {
  defaultPasswordData: {
      oldPassword: '',
      newPassword: '',
      rePass: '',
  },
};

export default withRouter(observer(PasswordChange));
