import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    /* Dialog Styles */

    dialogContent: {
        '& .MuiDialogContent-root': {
            padding: '8px 16px !important',
            width: '520px',
        },

        '& .MuiDialogActions-root': {
            padding: '16px !important',
        },
    },

    fieldsRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        '& .MuiFormControl-root': {
            marginBottom: '24px',
        },
    },

});

export default styles;
export const useStyles = makeStyles(styles);
