import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
    card: {
        minWidth: '100%',
        backgroundImage: props => `url(${props.card})`,
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        color: '#fff',
      },
      cardContent: {
        height: 135,
      },
      cardMedia: {
        minWidth: '100%',
      },
      media: {
        aspectRatio: '2 / 1',
      },
      starIcon: {
        color: '#ffc247',
      },
      cardActions: {
        padding: theme.spacing(2),
        justifyContent: 'flex-end',
      },
      badgeIcon: {
        position: 'absolute',
      },
}));
