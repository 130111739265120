import { observable, action, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import { RecoveryState, SignInSignUpState } from '../../../utils/constants';
import { getFeatures } from '../../../conf/feature';
import withActions from '../withActions';

import {
    storeUserInfo,
    initUserInfo,
    getStoredInfo,
    clearStoredInfo,
    showNotification,
    closeNotification,
    showProgress,
    closeProgress,
    getPageInfo,
    setPageInfo,
    setEnvSettings,
    getEnvSettings,
    createRequest,
    abortRequest,
    abortAllRequests,
    clearRequest,
    signOut,
    shouldSignOut,
    signIn,
    persistPublisher,
    getAPIV3Location,
} from './actions';

import loaderActions from './actions-load-counter';
export default class EnvStore {
    version = {}
    observed = {};
    requestControllers = {};
    locationAPI = {};
    onTour = false;
    productTourStarted = false;

    loadCounter = 0;
    loadCounterTimeout = null;
    loadCategoryMessage = '';

    unobserved = {
        userInfoLoaded: false,
    };

    userInfoLoaded = false;
    progressEnabled = true;
    userStore = null;
    optinsStore = null;
    webhooksStore = null;
    schedulesStore = null;
    signInSuccess = null;
    errorStore = null;
    token = null;
    mode = '';
    settings = {};
    features = getFeatures();

    cognitoUser = null; /* Not observable for authenticity */

    user = null;
    superAdmin = null;
    publisher = null;

    history = null;
    signInSignUpState = {
        status: SignInSignUpState.SIGNED_OUT,
    };

    recoveryState = {
        status: RecoveryState.INITIAL_STEP,
    };

    constructor () {
        withActions(this, loaderActions);
        makeObservable(this, {
            progressOpen: observable,
            progressMessage: observable,
            notificationOpen: observable,
            notificationType: observable,
            notificationMessage: observable,
            signInSuccess: observable,
            superAdmin: observable,
            signInSignUpState: observable,
            recoveryState: observable,
            userInfoLoaded: observable,
            progressEnabled: observable,
            observed: observable,

            showProgress: action,
            closeProgress: action,
            showNotification: action,
            closeNotification: action,
            storeUserInfo: action,
            getStoredInfo: action,
            initUserInfo: action,
            clearStoredInfo: action,
        });
    }

    progressOpen = false;
    progressMessage = 'loading';
    showProgress = showProgress;
    closeProgress = closeProgress;

    notificationOpen = true;
    notificationType = 'success';
    notificationMessage = 'welcome.';
    showNotification = showNotification;
    closeNotification = closeNotification;

    storeUserInfo = storeUserInfo;
    getStoredInfo = getStoredInfo;
    initUserInfo = initUserInfo;
    clearStoredInfo = clearStoredInfo;
    getPageInfo = getPageInfo;
    setPageInfo = setPageInfo;

    createRequest = createRequest;
    abortRequest = abortRequest;
    abortAllRequests = abortAllRequests;
    clearRequest = clearRequest;

    setEnvSettings = setEnvSettings;
    getEnvSettings = getEnvSettings;
    persistPublisher = persistPublisher;
    signOut = signOut;
    shouldSignOut = shouldSignOut;

    signIn = signIn;
    log = (...args) => {
        if (this.mode === 'debug') {
            console.log('');
            console.log('');
            console.log(...args);
        }
    }

    getAPIV3Location = getAPIV3Location;
}

export function useEnvStore () {
    const { envStore } = useStores();
    return envStore;
}
