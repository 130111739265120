import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
// import Alert from '@material-ui/lab/Alert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
// import Checkbox from '../../../../commons/Checkbox';
import SelectSearch from '../../../../commons/ecal/selectsearch';
import { Typography } from '../../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import CategoryTreeStructure from '../../../../components/TreeComponent/TreeComponent';
import CategorySelect from './Category';
import useStyles from '../../buttons.styles';
import useScheduleData from './useScheduleData';
import useFeatures from '../../../hooks/useFeatures';

const Schedules = (props) => {
    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
        loadCategories,
        lastDataLoad,
        setLastDataLoad,
        shouldSave,
        onTouch,
    } = props;
    const classes = useStyles();

    const {
        // input,
        scheduleData,
        updateScheduleData,

        updateScheduleSelection,
        schedules,
        searchSchedule,
        showProgress,

    } = useScheduleData({
        onDataChange,
        dataSource,
        updateSavable,
        onTouch,
        isDisplaySetting: true,
    });
    const {
        features,
    } = useFeatures();

    const handleChange = (event) => {
      updateScheduleData({
        ...scheduleData,
        scheduleOption: parseInt(event.target.value),
      });
        // setSelectedValue(parseInt(event.target.value));
    };

    function renderCategoryOptions () {
        if (scheduleData.scheduleOption === 2) {
            return (
                <Box sx={{
                    margin: '0 30px 30px 30px',
                    display: 'block',
                }} >
                    <CategorySelect
                        lastDataLoad={lastDataLoad}
                        setLastDataLoad={setLastDataLoad}
                        loadCategories={loadCategories}
                        dataSource={ dataSource }
                        scheduleData={scheduleData}
                        updateScheduleData={updateScheduleData}
                    />
                </Box>
            );
        }
        return (<React.Fragment></React.Fragment>);
    }
    return (
        <Grid item justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={800}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    Which schedules should be displayed?
                </Typography>
                <Typography
                    // variant={'body1'}
                    // weight={'medium'}
                    block
                    style={{ marginBottom: 30 }}
                >
                    Schedules are a collection of events that your users subscribe to receive in their personal calendar. Categories are groups of schedules.
                    <br /> <br />
                    You can choose to display all your Schedules, or display Schedules only belonging to particular Categories.
                </Typography>
                <FormControlLabel
                    style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 1}
                      onChange={handleChange}
                      value={1}
                      color='default'
                      name='radio-button-all-schedules'
                      inputProps={{ 'aria-label': 'all schedules' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Show all my Schedules'}
                />
                <FormControlLabel
                    style={{ marginBottom: 10 }}
                  control={
                    <Radio
                    //   defaultChecked={isChecked.default}
                    //   onChange={e =>
                    //     setChecked({ [e.target.value]: !isChecked.default })
                    //   }
                      checked={scheduleData.scheduleOption === 2}
                      onChange={handleChange}
                      value={2}
                      color='default'
                      name='radio-button-schedules-by-cat'
                      inputProps={{ 'aria-label': 'select schedules by category' }}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                    />
                  }
                  label={'Show Schedules by Category'}
                />
                { renderCategoryOptions() }

                { features.eventDisplaySelectScheduleActive && (
                <>
                    <FormControlLabel
                        style={{ marginBottom: 10 }}
                    control={
                        <Radio
                        //   defaultChecked={isChecked.default}
                        //   onChange={e =>
                        //     setChecked({ [e.target.value]: !isChecked.default })
                        //   }
                        checked={scheduleData.scheduleOption === 3}
                        onChange={handleChange}
                        value={3}
                        color='default'
                        name='radio-button-selected-schedules'
                        inputProps={{ 'aria-label': 'select schedules' }}
                        icon={<RadioButtonUncheckedIcon fontSize='small' />}
                        checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                        />
                    }
                    label={'Only show these schedules'}
                    />
                    { scheduleData.scheduleOption === 3
                    ? <Box
                        sx={{
                            margin: 30,
                        }}
                    >
                        <SelectSearch
                            label='Schedules'
                            placeHolder='Search Schedule'
                            helperText='Search and select schedule'
                            dataProvider={schedules}
                            selected={scheduleData.schedules}
                            onSelect={(data) => {
                                console.log('on select: ', data);
                                updateScheduleSelection(data);
                            }}

                            isRemoteSearch={true}
                            onRemoteSearch={(search) => {
                                searchSchedule(search);
                            }}
                            showProgress={showProgress}
                        />
                    </Box>
                    : null}
                </>
                )}
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                     <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            if (shouldSave()) {
                                onSave();
                            }
                        }}
                    />
                </Box>
            </Box>
        </Grid>
    );
};

Schedules.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    loadCategories: PropTypes.func,
    lastDataLoad: PropTypes.string,
    setLastDataLoad: PropTypes.func,
    shouldSave: PropTypes.func,
};

Schedules.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    loadCategories: () => {},
    setLastDataLoad: () => {},
    lastDataLoad: '',
    shouldSave: () => true,
};

export default observer(Schedules);
