import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import {
    Dialog,
    Grid,
    Box,
} from '@material-ui/core';

import {
    Typography,
} from '../../../components/Wrappers';

import ButtonProgress from '../../../commons/ButtonProgress';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../profile.styles';
import useUserValidation from './useUserValidation';
function UserInputDialog (props) {
    const {
        userData,
        onDataChange,
        onSave,
        inProgress,
        open,
        onClose,
        mode,
    } = props;

    const [saveDisabled, setSaveDisabled] = useState(true);
    const [data, setData] = useState(userData);
    const classes = useStyles();

    const {
        inputError: errors,
        fieldsValid,
    } = useUserValidation({
        input: data,
    });

    useEffect(() => {
        setData(userData);
    }, [userData]);

    useEffect(() => {
        if (typeof onDataChange === 'function') {
            onDataChange(data);
        }
    }, [data]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        setSaveDisabled(false);
    }

    function isDisabled () {
        // NOTE: on Edit mode, email and password should not be validated...
        if (!fieldsValid && mode === 'add') {
            return true;
        }
        return saveDisabled;
    }

    return (
            <Dialog
                open={open}
                disableEnforceFocus
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <Grid item justify={'center'} container>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    width={600}
                                    className={classes.boxStyling}
                                >
                                    <Typography
                                        variant={'h5'}
                                        weight={'medium'}
                                        style={{ marginBottom: 30 }}
                                    >
                                        User Details
                                    </Typography>

                                    { mode === 'add' && (
                                        <React.Fragment>
                                             <TextField
                                                id="email"
                                                label="Email"
                                                onChange={handleChange}
                                                name="email"
                                                value={data.email || ''}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}
                                                helperText={errors.email || 'Please enter your email'}
                                                error={errors.email}
                                            />
                                            <TextField
                                                id="temp-password"
                                                label="Temporary Password"
                                                onChange={handleChange}
                                                name="password"
                                                value={data.password || ''}
                                                variant="outlined"
                                                style={{ marginBottom: 35 }}

                                                helperText={errors.password || 'This is your temporary password'}
                                                error={errors.password}

                                            />
                                        </React.Fragment>
                                    )}

                                    <TextField
                                        id="first-name"
                                        label="First Name"
                                        onChange={handleChange}
                                        name="firstName"
                                        value={data.firstName || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="Please enter your first name"
                                    />

                                    <TextField
                                        id="last-name"
                                        label="Last Name"
                                        onChange={handleChange}
                                        name="lastName"
                                        value={data.lastName || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="Please enter your last name"
                                    />
                                    <Grid className={classes.buttons} >
                                        <ButtonProgress
                                            label="Cancel"
                                            onClick= { (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onClose();
                                            }}
                                        />
                                        <ButtonProgress
                                            disabled={isDisabled()}
                                            showProgress={inProgress}
                                            label="Submit"
                                            onClick={(e) => {
                                                console.log('show new user data: ', data);
                                                onSave(data);
                                                setSaveDisabled(true);
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>
    );
}

UserInputDialog.propTypes = {
    userData: PropTypes.object,
    onDataChange: PropTypes.func,
    inProgress: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    mode: PropTypes.string,
};

UserInputDialog.defaultProps = {
    userData: {
        firstName: '',
        lastName: '',
        tempPassword: '',
        email: '',
    },
    onDataChange: () => { },
    onSave: () => {},
    inProgress: false,
    open: false,
    onClose: () => {},
    mode: 'add',
};

export default observer(UserInputDialog);
