import { useState, useEffect } from 'react';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import { AssetType } from '../../../../utils/constants';
import useImageUpload from '../../../hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../../hooks/useImageRenderable';
import { useButtonsStore } from '../../../../store/models';

function useAdDesignInput (props) {
    const {
        updateSavable,
        dataSource,
        onDataChange,
    } = props;
    const buttonsStore = useButtonsStore();
    /*
     * instead of data mapping use decoder/encoder
     * function to easily see how things are converted from dataSource to ui consumable
     */
    function decodeSource () {
        return {
            displayId: dataSource.name,
            image: dataSource.image,
        };
    }

    function encodeSavable (data) {
        return {
            ...dataSource,
            name: data.displayId,
            image: data.image,
        };
    }

    const [adData, setAdData] = useState(decodeSource());

    useEffect(() => {
        setAdData(decodeSource());
    }, [dataSource]);

    useEffect(() => {
        setAdData(decodeSource());
    }, [dataSource]);

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    function updateAdData (data) {
        const newData = {
            ...adData,
            ...data,
        };
        updateSavable(encodeSavable(newData));
        setAdData(newData);
    }

    function setAdImageURL (url) {
        console.log('Set Ad Image URL: ', url);
        updateAdData({ image: url });
    };

    const {
        image: adsBanner,
    } = useImageRenderable({
        imageSource: adData.image,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: adsImage,
        upload: uploadAdsImage,
        deleteImage: deleteAdsImage,
        imageError: adsImageError,
    } = useImageUpload({
        assetType: AssetType.BUTTON_AD_DISPLAY_IMAGE,
        observableDataSource: buttonsStore.observed.assets.buttonAdDisplayImage,
        consumableDataSource: buttonsStore.assets.buttonAdDisplayImage,
        setStateFunc: setAdImageURL,
        customDefaultImage: adsBanner,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: true,
        sizeLimit: 1024,
    });

    return {
        adData,
        updateAdData,
        adsImage,
        uploadAdsImage,
        deleteAdsImage,
        adsImageError,
    };
}

export default useAdDesignInput;
