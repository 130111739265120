import { makeStyles } from '@material-ui/core/styles';

import loginIcon from './login.svg';

const styles = (theme) => ({
    buttonsGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: 32,
      marginBottom: 32,
    },

    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '60px',
      paddingLeft: 0,

      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderTop: '2px solid rgba(185, 185, 185, 0.3)',
      backgroundColor: 'transparent',

      '&:hover': {
        color: '#536DFE',
        backgroundColor: '#F7F7F7',
      },
      cursor: 'pointer',
    },

    img: {
      float: 'left',
      height: 50,
      width: 50,
      marginRight: 20,
      borderRadius: '50%',
    },

    userInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      marginLeft: '10px',
    },

    itemSpace: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
    },

    userName: {
      // fontSize: '1.15rem',
      pointerEvents: 'none',
    },

    noPointer: {
      marginLeft: '16px',
      pointerEvents: 'none',
    },

    userLabel: {
      color: theme.palette.text.secondary,
    },

    dot: {
      '@media (max-width: 320px)': {
        display: 'none',
      },
    },

    loginIconCell: {
      marginRight: '16px',
      pointerEvents: 'none',
    },

    tablePagination: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',

      '& .MuiTableCell-root': {
        borderBottom: '0px solid rgba(0, 0, 0, 0)',
      },

    },

    loginIcon: {
      backgroundSize: '24px',
      backgroundImage: `url(${loginIcon})`,
      width: '24px',
      height: '24px',
      '&:hover': {
        color: '#536DFE',
      },
    },
});

export default styles;
export const useStyles = makeStyles(styles);
