import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';
import {
    Typography,
    //  Link,
//    Button,
} from '../../../../components/Wrappers';
import ImageUploader from '../../../../commons/imageUploader/ImageUploader';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import useAdDesignInput from './useAdDesignInput';
const INSTRUCTION = 'Ad Display\'s allow you to subscribe to ECAL from any image or banner ad you like. Simply upload your image, and publish it to your website or ad network. Simple!';
// const calendarNameHelperText = 'The name of your Calendar feed as it appears in the subscriber\'s Personal Calendar.';
function AdDesignInput (props) {
    const classes = useStyles();

    const {
        onDataChange,
        dataSource,
        updateSavable,
        onSave,
        progress,
        saveDisabled,
    } = props;

    const {
        adData,
        updateAdData,
        adsImage,
        uploadAdsImage,
        deleteAdsImage,
        adsImageError,
        // isEmptyBanner,
        // setIsEmptyBanner,
    } = useAdDesignInput({
        onDataChange,
        dataSource,
        updateSavable,
    });

    const isEmptyBanner = adsImage[0].data_url.includes('data:image/png');

    return (
        <React.Fragment>
            <Grid
                item
                className={classes.mainContainer}
                justify={'center'}
                container>

                <Grid
                    className={classes.titleBase}
                    >
                    <Typography
                        variant='h5'
                        weight='medium'
                    >
                        Create an Ad Display
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    className={classes.sectionRootNoBottomPad}>

                    <Grid className={classes.sectionDescription}>
                        <Typography block >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classNames([classes.sectionContent])}
                        >

                            <Grid className={classNames([classes.sectionContentUpload, classes.bottomSpace])}>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.uploadSection}
                                    >
                                        <Typography
                                                variant={'body1'}
                                                className={classes.imageTitle}
                                            >
                                                <b>Upload your Creative</b>
                                                <br/>
                                                Accepts JPG, PNG, or GIF images up to 1MB
                                        </Typography>
                                        {console.log('adsImage? ', adsImage)}
                                        <ImageUploader
                                            layoutDesign={'spaceBetween'}
                                            hasCreate={true}
                                            hasDelete={true}
                                            createLabel={'Upload'}
                                            imageWidth='100px'
                                            imageHeight='70px'
                                            imageData={adsImage}
                                            onImageSelected={(images) => {
                                                uploadAdsImage(images);
                                                // setIsEmptyBanner(false);
                                            }}
                                            onImageDelete={() => {
                                              console.log('ads logo img deleted?');
                                              deleteAdsImage();
                                              // setIsEmptyBanner(true);
                                            }}
                                        />
                                        { adsImageError &&
                                          (
                                            <Grid>
                                                <Typography
                                                    variant={'caption'}
                                                    weight={'medium'}
                                                    style={{ marginBottom: 30, color: 'red' }}
                                                >
                                                    {adsImageError}
                                                </Typography>
                                            </Grid>
                                        )}
                                </Grid>
                            </Grid>

                            <Box
                                className={classes.inputBox}>

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}

                                    id="googleAnalytics"
                                    label="Display Id*"

                                    value={adData.displayId}
                                    onChange={(e) => {
                                        updateAdData({ displayId: e.target.value });
                                    }}

                                    name="displayId"
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    helperText={ 'Display ID is the name your button will be given within your Publisher Admin.'}
                                />

                            </Box>

                    </Grid>

                </Grid>

                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                  {console.log('progress? ', { progress, saveDisabled })}
                    <ButtonProgress
                        disabled={saveDisabled || isEmptyBanner}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>
        </React.Fragment>
    );
};

AdDesignInput.propTypes = {
    buttonDesignData: PropTypes.object,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    updateDesignData: PropTypes.func,
    onSave: PropTypes.func,
    uploadAdsLogo: PropTypes.func,
    adsLogo: PropTypes.any,
};

AdDesignInput.defaultProps = {
    buttonDesignData: {},
    updateDesignData: () => {},
    onSave: () => {},
    progress: {},
    saveDisabled: false,
    uploadAdsLogo: () => {},
    adsLogo: null,
};

export default observer(AdDesignInput);
