export function serializeForParentConsent (value) {
    if (value === 0) {
        return false;
    }
    return true;
}

export const EventDisplayMode = {
    CALENDAR: 'calendar',
    PAGE: 'page',
    COLUMN: 'column',
    CAROUSEL: 'carousel',
};

export const CalendarEventDisplayViews = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    LIST: 'list',
};

export const defaultDisplayMode = EventDisplayMode.CALENDAR;
export const defaultCalendarDisplayView = CalendarEventDisplayViews.MONTH;

const ModeVariations = {
    CALENDAR: 'data-ecal-widget-type=\'calendar\'',
    PAGE: 'data-ecal-widget-type=\'page\'',
    COLUMN: 'data-ecal-widget-type=\'column\'',
    CAROUSEL: 'data-ecal-widget-type=\'carousel\'',
};

const ViewVariations = {
    MONTH: 'data-ecal-widget-view=\'month\'',
    WEEK: 'data-ecal-widget-view=\'week\'',
    DAY: 'data-ecal-widget-view=\'day\'',
    LIST: 'data-ecal-widget-view=\'list\'',
};

export function withMode (code, mode) {
    let newCode = code;
    if (mode === EventDisplayMode.CALENDAR) {
        newCode = newCode.replace(ModeVariations.PAGE, ModeVariations.CALENDAR);
        newCode = newCode.replace(ModeVariations.COLUMN, ModeVariations.CALENDAR);
        newCode = newCode.replace(ModeVariations.CAROUSEL, ModeVariations.CALENDAR);
    } else if (mode === EventDisplayMode.PAGE) {
        newCode = newCode.replace(ModeVariations.CALENDAR, ModeVariations.PAGE);
        newCode = newCode.replace(ModeVariations.COLUMN, ModeVariations.PAGE);
        newCode = newCode.replace(ModeVariations.CAROUSEL, ModeVariations.PAGE);
    } else if (mode === EventDisplayMode.CAROUSEL) {
        newCode = newCode.replace(ModeVariations.CALENDAR, ModeVariations.CAROUSEL);
        newCode = newCode.replace(ModeVariations.COLUMN, ModeVariations.CAROUSEL);
        newCode = newCode.replace(ModeVariations.PAGE, ModeVariations.CAROUSEL);
    } else if (mode === EventDisplayMode.COLUMN) {
        newCode = newCode.replace(ModeVariations.CALENDAR, ModeVariations.COLUMN);
        newCode = newCode.replace(ModeVariations.CAROUSEL, ModeVariations.COLUMN);
        newCode = newCode.replace(ModeVariations.PAGE, ModeVariations.COLUMN);
    }
    return newCode;
}

export function withView (code, view) {
    let newCode;
    if (code.includes('data-ecal-widget-view')) {
        newCode = code;
    } else {
        newCode = code.split('></div>');
        newCode = `${newCode[0]} ${ViewVariations.MONTH}></div>`;
    }

    if (view === CalendarEventDisplayViews.MONTH) {
        newCode = newCode.replace(ViewVariations.WEEK, ViewVariations.MONTH);
        newCode = newCode.replace(ViewVariations.DAY, ViewVariations.MONTH);
        newCode = newCode.replace(ViewVariations.LIST, ViewVariations.MONTH);
    } else if (view === CalendarEventDisplayViews.WEEK) {
        newCode = newCode.replace(ViewVariations.MONTH, ViewVariations.WEEK);
        newCode = newCode.replace(ViewVariations.DAY, ViewVariations.WEEK);
        newCode = newCode.replace(ViewVariations.LIST, ViewVariations.WEEK);
    } else if (view === CalendarEventDisplayViews.DAY) {
        newCode = newCode.replace(ViewVariations.MONTH, ViewVariations.DAY);
        newCode = newCode.replace(ViewVariations.WEEK, ViewVariations.DAY);
        newCode = newCode.replace(ViewVariations.LIST, ViewVariations.DAY);
    } else if (view === CalendarEventDisplayViews.LIST) {
        newCode = newCode.replace(ViewVariations.MONTH, ViewVariations.LIST);
        newCode = newCode.replace(ViewVariations.WEEK, ViewVariations.LIST);
        newCode = newCode.replace(ViewVariations.DAY, ViewVariations.LIST);
    };

    return newCode;
}

export const displayModes = [
    {
        value: EventDisplayMode.CALENDAR,
        key: EventDisplayMode.CALENDAR,
        label: 'Calendar display mode',
    },

    {
        value: EventDisplayMode.PAGE,
        key: EventDisplayMode.PAGE,
        label: 'Page display mode',
    },

    {
        value: EventDisplayMode.CAROUSEL,
        key: EventDisplayMode.CAROUSEL,
        label: 'Carousel display mode',
    },

    {
        value: EventDisplayMode.COLUMN,
        key: EventDisplayMode.COLUMN,
        label: 'Column display mode',
    },
];

export const calendarViews = [
    {
        value: CalendarEventDisplayViews.MONTH,
        key: CalendarEventDisplayViews.MONTH,
        label: 'Month',
    },

    {
        value: CalendarEventDisplayViews.WEEK,
        key: CalendarEventDisplayViews.WEEK,
        label: 'Week',
    },

    {
        value: CalendarEventDisplayViews.DAY,
        key: CalendarEventDisplayViews.DAY,
        label: 'Day',
    },

    {
        value: CalendarEventDisplayViews.LIST,
        key: CalendarEventDisplayViews.LIST,
        label: 'List',
    },
];
