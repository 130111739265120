import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import {
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    Typography,
//    Button,
} from '../../../../components/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import usePopupDesign from './usePopupDesign';
import Basics from './basics';
import LandingPage from './landingPage';
import Custom from './custom';
import WidgetPreview from './widget';

function PopupDesign (props) {
    const classes = useStyles();

    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
    } = props;

    const {
        landingPageLogo,
        uploadLandingPageLogo,
        deleteLandingPageLogo,

        landingPageBackground,
        uploadLandingPageBackground,
        deleteLandingPageBackground,

        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,

        styleData,
        setStyleData,
        cssFile,
        uploadCSSFile,
        landingPageLogoError,
        landingPageBackgroundError,
        buttonLogoError,
        cssFileError,
        isUploading,

    } = usePopupDesign({
        dataSource,
        onDataChange,
        updateSavable,
    });

    const [expanded, setExpanded] = useState('simpleStyling');

    return (
        <React.Fragment>
            <Grid
                item
                className={classes.mainContainer}
                justify={'center'}
                container>

                <Grid
                    className={classes.titleBase}
                    >
                    <Typography
                        variant='h5'
                        weight='medium'
                    >
                        Design Pop-up
                    </Typography>
                </Grid>

                <Grid className={classes.baseContent}>

                    <Grid className={classes.controlsSpace} >

                        <Accordion
                            expanded={(expanded === 'simpleStyling')}
                            onChange={() => {
                                if (expanded === 'simpleStyling') {
                                    setExpanded('');
                                } else {
                                    setExpanded('simpleStyling');
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="simpleStyling"
                                id="simpleStyling"
                            >
                                <Grid className={classes.sectionTitle}>
                                        <Typography
                                            variant='h6'
                                            weight='medium'
                                            >
                                            Simple Styling
                                        </Typography>
                                    </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Basics
                                    buttonLogo={buttonLogo}
                                    deleteButtonLogo={deleteButtonLogo}
                                    styleData={styleData}
                                    setStyleData={setStyleData}
                                    uploadButtonLogo={uploadButtonLogo}
                                    buttonLogoError={buttonLogoError}
                                    isUploading={isUploading}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === 'landingPage')}
                            onChange={() => {
                                if (expanded === 'landingPage') {
                                    setExpanded('');
                                } else {
                                    setExpanded('landingPage');
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="landingPage"
                                id="landingPage"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                    Landing Page Styling
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LandingPage
                                    styleData={styleData}
                                    setStyleData={setStyleData}

                                    landingPageLogo={landingPageLogo}
                                    uploadLandingPageLogo={uploadLandingPageLogo}

                                    landingPageBackground={landingPageBackground}
                                    uploadLandingPageBackground={uploadLandingPageBackground}
                                    deleteLandingPageBackground={deleteLandingPageBackground}
                                    deleteLandingPageLogo={deleteLandingPageLogo}
                                    landingPageLogoError={landingPageLogoError}
                                    landingPageBackgroundError={landingPageBackgroundError}

                                    isUploading={isUploading}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={(expanded === 'custom')}
                            onChange={() => {
                                if (expanded === 'custom') {
                                    setExpanded('');
                                } else {
                                    setExpanded('custom');
                                }
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="custom"
                                id="custom"
                            >
                                <Grid className={classes.sectionTitle}>
                                    <Typography
                                        variant='h6'
                                        weight='medium'
                                        >
                                    Custom Styling
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Custom
                                    styleData={styleData}
                                    setStyleData={setStyleData}
                                    cssFile={cssFile}
                                    uploadCSSFile={uploadCSSFile}
                                    cssFileError={cssFileError}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid className={classes.widgetSpace} >
                        <WidgetPreview
                            styleData={styleData}
                        />
                    </Grid>

                </Grid>

                <Box
                    mt={'40px'}
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>
        </React.Fragment>
    );
};

PopupDesign.propTypes = {
    onDataChange: PropTypes.func,
    dataSource: PropTypes.any,
    updateSavable: PropTypes.func,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    hasError: PropTypes.bool,
    hasInputError: PropTypes.bool,
    errors: PropTypes.object,
    landingPageLogoError: PropTypes.string,
    landingPageBackgroundError: PropTypes.string,
    buttonLogoError: PropTypes.string,
    cssFileError: PropTypes.string,
};

PopupDesign.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    hasError: false,
    hasInputError: false,
    errors: {},
    landingPageLogoError: '',
    landingPageBackgroundError: '',
    buttonLogoError: '',
    cssFileError: '',
};

export default observer(PopupDesign);
