
import {
    visibleCell,
    fieldCell,
    requiredCell,
    defaultValueCell,
} from './customCells';

export const columns = [
    {
        id: 'visible',
        numeric: true,
        disablePadding: false,
        label: 'Visibility',
    },

    {
        id: 'field',
         numeric: true,
        disablePadding: false,
        label: 'Field',
    },

    {
        id: 'required',
        numeric: true,
        disablePadding: false,
        label: '',
    },

    {
        id: 'defaultValue',
        numeric: true,
        disablePadding: false,
        label: 'Default Value',
    },
];

export const customCells = {
    visible: visibleCell,
    field: fieldCell,
    required: requiredCell,
    defaultValue: defaultValueCell,
};

export const options = {
    titleIcon: null,
};
