import React from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    Label as LabelIcon,
} from '@material-ui/icons';

import {
    linkNameCell,
    urlCell,
    clicksCell,
} from './customCells';

export const columns = [
    {
        id: 'link_name',
        numeric: true,
        disablePadding: false,
        label: 'Link Name',
    },

    {
        id: 'url',
         numeric: true,
        disablePadding: false,
        label: 'URL',
    },

    {
        id: 'count',
        numeric: true,
        disablePadding: false,
        label: 'Clicks',
    },
];

export const customCells = {
    link_name: linkNameCell,
    url: urlCell,
    count: clicksCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
