export function searchQuery (vars) {
    return {
        query: `query searchPublishers($filterInput: SearchPublisherInput!, $pageInput:pageInput!) {

            searchPublishers( filterInput:$filterInput, pageInput: $pageInput) {
                totalCount
                edges {
                    node {
                        name
                        email
                       firstName
                       lastName
                       status
                       token
                       role
                       planName
                       industry
                       objective
                       timezone
                       subscriberLimit
                        subscriberRemaining
                        subscriptionPlanCurrency
                        dateCreated
                        dateExpiry
                        intercomUserHash
                    }
                    cursor
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}
