import { useState } from 'react';
// import Papa from 'papaparse';
// import XLSX from 'xlsx';

function useCSVReader (props) {
    const [eventsData] = useState({
        'headers': 'standard',
        'rows': [{}],
    });

    const [spreadsheetFile, setSpreadsheetFile] = useState(null);
    // REMOVE THE FE Parsing - use BE Parsing instead.
    /*
    function readASXLS (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            // Use reader.result
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            const sheetNames = workbook.SheetNames; // Sheet name collection
            const worksheet = workbook.Sheets[sheetNames[0]]; // Here we only read the first sheet
            const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });

            const headers = Object.keys(json[0]);
            console.log('headers: ', headers);
            setEventsData({
                headers,
                rows: json,
            });
        };
        reader.readAsBinaryString(file);
    }

    function readAsCSV (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
            // Use reader.result
            const json = Papa.parse(reader.result, { header: true });
            console.log(json);

            const headers = Object.keys(json.data[0]);
            console.log('headers: ', headers);
            setEventsData({
                headers,
                rows: json.data,
            });
        };
        reader.readAsText(file);
    }
    */

    function readSpreadsheet (files) {
        const file = files[0];
        setSpreadsheetFile(file);
        /*
        const nameParts = file.name.split('.');
        const fileType = nameParts[nameParts.length - 1];
        console.log('file: ', fileType);
        if (fileType === 'xls' || fileType === 'xlsx') {
            readASXLS(file);
            return;
        }
        readAsCSV(file);
        */
    }

    return {
        eventsData,
        readSpreadsheet,
        spreadsheetFile,
    };
}

export default useCSVReader;
