
/*
 * for the registration fields
 * each row is assigned to specific set of fields
 *
 */

export const fieldRowMap = {
    name: 1,
    mobile: 2,
    ageGroup: 3,
    country: 4,
    gender: 5,
    zip: 6,
    dob: 7,
    requiredAge: 8,
    parentalConsent: 9,
};

const columnValuePropMap = {
    visible: 'visibleValue',
    field: 'fieldValue',
    required: 'requiredValue',
    defaultValue: 'defaultValueValue',
};

export function onTableCellStateChange (params, source, setTableData) {
    const {
        field,
        newValue,
        column,
        row,
    } = params;

    const newTableData = [
        ...source,
    ];

    const i = fieldRowMap[field];
    const valueProp = columnValuePropMap[column];

    if (i && valueProp) {
        newTableData[i] = {
            ...newTableData[i],
            [valueProp]: newValue,
        };
    }

    if (field === 'customField') {
        const i = row.customFieldIndex;
        newTableData[i] = {
            ...newTableData[i],
            [valueProp]: newValue,
        };
    }
    /* Do custom fields update here */

    setTableData(newTableData);
}

export function rowDataToCustomField (rowdata) {
    return {
        type: rowdata.customFieldType,
        name: rowdata.fieldValue,
        value: rowdata.customFieldValues,
        visible: rowdata.visibleValue,
        required: rowdata.requiredValue,
        index: rowdata.customFieldIndex,
    };
}

export function customFieldToRowData (customField) {
    return {
        field: 'customField',
        customFieldType: customField.type,
        customFieldValues: customField.value,
        customFieldIndex: customField.index,

        visibleType: 'toggle',
        visibleValue: customField.visible,
        fieldType: 'text',
        fieldValue: customField.name,

        requiredType: 'check',
        requiredValue: customField.required,

        defaultValueType: 'customField',
        defaultValueValue: '',
    };
}

export function upsertRow (row, tableData) {
    const newTableData = [
        ...tableData,
    ];

    if (row.customFieldIndex !== -1) {
        newTableData[row.customFieldIndex] = { ...row };
    } else {
        newTableData.push({ ...row, customFieldIndex: tableData.length });
    }

    return newTableData;
}

export function deleteRow (row, tableData) {
    let i = -1;
   return tableData.reduce((newArr, item) => {
        if (item.customFieldIndex === row.customFieldIndex) {
            return newArr;
        }
        i++;
        return [...newArr, { ...item, customFieldIndex: i }];
    }, []);
}
