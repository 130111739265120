import { makeObservable, observable, action } from 'mobx';
import { useStores } from '../useStores';

import {
    getOptins,
    addOptins,
    editOptins,
    deleteOptins,
} from './actions';

import {
    loadOptinsForEdit,
    saveOptinsForEdits,
} from './actions_persist';

export default class OptinsStore {
    version = {}
    env = null;
    optins = null;
    optinsForEdit = null;

    observed = {
        optins: null,
        optinsLoading: false,
    };

    constructor () {
        makeObservable(this, {

            observed: observable,
            optins: observable,
            getOptins: action,
        });
    }

    getOptins = getOptins;
    addOptins = addOptins;
    editOptins = editOptins;
    deleteOptins = deleteOptins;

    /* Data Persist */
    loadOptinsForEdit = loadOptinsForEdit;
    saveOptinsForEdits = saveOptinsForEdits;
}

export function useOptinsStore () {
    const { optinsStore } = useStores();
    return optinsStore;
}
