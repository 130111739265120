import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import {
    useErrorStore,
} from '../../../store/models';

/*
 * This hook will be use in useModuleInput
 * Especially made to handle and manage all error state of particular module input
 */

function useModuleError (props) {
    const {
        mode,
        input,
        setProgress,
        progress,
    } = props;

    const errorStore = useErrorStore();
    /* Use In Form Input Errors */
    const [errors, setErrors] = useState({});
    const [hasInputError, setHasInputError] = useState(true);

    /* Used with Dialogs Errors */
    const [hasError, setHasError] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const action = errorStore.observed.apikeys.action;
        if (action === 'edit') {
            const buttons = errorStore.buttons;
            if (buttons.message || buttons.label) {
                setErrorTitle(errorStore.apikeys.title);
                setErrorMessage(errorStore.apikeys.message);
                setHasError(true);
            }

            setErrors({
                ...errorStore.apikeys,
            });
        }

        setProgress({
            ...progress,
            buttons: false,
        });
    }, [errorStore.observed.buttons.key]);

    useEffect(() => {
        setErrors({});
        setHasError(false);
    }, [input]);

    useEffect(() => {
        if (isEmpty(errors)) {
            setHasInputError(false);
        } else {
            setHasInputError(true);
        }
    }, [errors]);

    function closeError () {
        setHasError(false);
    }

    function validateInput (newInput) {
        errorStore.validateInput({
            input: newInput,
            action: mode,
            moduleFunc: 'buttonsValidateInput',
        });
    }

    return {
        hasError,
        hasInputError,
        errors,
        closeError,
        errorMessage,
        errorTitle,
        validateInput,
    };
}

export default useModuleError;
