import { v4 as uuid } from 'uuid';

import {
    apiGetSubscribersData,
    apiGetSubscribersReport,
    apiGetMonthlySubscribers,
} from './api';

import {
    defaultColumns,
    generateColumns,
    availableColumns,
} from './dynamic_columns';

export function updateSchedulesFilter (res) {
    console.log('');
    console.log('');
    console.log('updateSchedulesFilter()', res.data.data.getSchedules);
    if (res.data &&
        res.data.data &&
        res.data.data.getSchedules) {
        const edges = res.data.data.getSchedules.edges;
        this.schedules = edges.map(item => ({
            value: item.node.id,
            label: item.node.name,
            catName: item.node.categoryNames,
        }));

        console.log('Schedules filter: ', this.schedules);
        this.observed.schedules = uuid();
    }
}

/*
https://test-svc.ecal.com/v4/subscribers/json?
startDate=2021-01-01&
endDate=2021-09-06&
scheduleIds=59150d50a736423b25bf9302,59942d68aa8a3beb048b4569&
tz=Asia/Manila
*/

export async function getSubscribersData ({ scheduleIds, startDate, endDate }) {
    this.observed.subsribersDataLoading = true;
    const publisher = this.env.userStore.publisher;
    const params = getParams({ scheduleIds, startDate, endDate });
    console.log('');
    console.log('loading subscribers data publisher: ...', publisher);
    const res = await apiGetSubscribersData(
        {
            ...params,
            tz: publisher.timezone,
        },
        {
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data && res.data.data) {
        if (res.data.data.results) {
            this.subscribersData = res.data.data.results; /* process this */
            const colNames = availableColumns(res.data.data.results);
            const columns = generateColumns(defaultColumns, colNames);
            this.subscribersColumns = columns;
        } else {
            this.subscribersData = [];
            this.subscribersColumns = defaultColumns;
        }

        this.observed.subscribersData = uuid();
    } else {
        // handle error.
    }
    this.observed.subsribersDataLoading = false;
}

function getParams ({ startDate, endDate, scheduleIds }) {
    return {
        startDate: dateParamsFormat(startDate),
        endDate: dateParamsFormat(endDate),
        scheduleIds: getScheduleIds(scheduleIds),
    };
}

function getScheduleIds (ids) {
    if (ids.length === 1 && ids[0] === '-') {
        return '';
    }
    if (ids.length <= 0) {
        return '';
    }
    return ids.join(',');
}

function dateParamsFormat (date) {
    let m = date.getMonth() + 1;
    let d = date.getDate();

    if (m < 10) {
        m = '0' + m;
    }

    if (d < 10) {
        d = '0' + d;
    }
    return date.getFullYear() + '-' + m + '-' + d;
}

export async function getMonthlySubscribers ({ startDate, endDate }) {
    this.observed.monthlySubsLoading = true;
    const publisher = this.env.userStore.publisher;
    const res = await apiGetMonthlySubscribers(
        {
            from: startDate,
            to: endDate,
            tz: publisher.timezone,
        },
        {
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data) {
        this.monthlySubscriptions = res.data.data;
    }

    this.observed.monthlySubsLoading = false;
}

export async function getSubscribersReport ({ scheduleIds, startDate, endDate }) {
    this.observed.subsribersDataLoading = true;
    const publisher = this.env.userStore.publisher;

    const params = getParams({ scheduleIds, startDate, endDate });
    const res = await apiGetSubscribersReport(
        {
            ...params,
            tz: publisher.timezone,
        },
        {
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data) {
        downloadGeneratedCSV(res.data);
        this.observed.csvDownloaded = uuid();
    }

    this.observed.subsribersDataLoading = false;
}

function downloadGeneratedCSV (csv) {
    const dllink = document.createElement('a');
    const blob = new Blob(['\ufeff', csv]);
    const url = URL.createObjectURL(blob);
    dllink.setAttribute('style', 'display: hidden;');
    dllink.href = url;
    dllink.download = 'subscribers-' + uuid() + '.csv';
    document.body.appendChild(dllink);
    dllink.click();
    document.body.removeChild(dllink);
}
