import React from 'react';
import classNames from 'classnames';

import {
    Box,
    Grid,
    Tooltip,
} from '@material-ui/core';

import { Typography, Chip, Badge } from '../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';

import {
    DeleteOutlined as DeleteIcon,
    CreateOutlined as CreateIcon,
    Code as CodeIcon,

    BorderOuter as PageModeIcon,
    BorderTop as CarouselModeIcon,
    BorderRight as ColumnModeIcon,
    BorderAll as BorderAllIcon,
} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

/* Custom Cells */
export function statusCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;
    return (
        <Grid>
            <Chip
                label={row.draft ? 'DRAFT' : 'LIVE'}
                className={classes.statusCell}
                style={{
                    backgroundColor: row.draft ? '#f50057' : '#75bd43',
                }}
            />
        </Grid>
    );
}

export function nameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.nameCell}
                variant={'body2'}
            >
                {row.name}
            </Typography>
    );
}

export function subscribersCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.subscriberCell}
                variant={'body2'}
            >
                {row.subscribers}
            </Typography>
    );
}

export function actionsCell ({ index, row, column, props }) {
    const {
        classes,
        onEdit,
        onDelete,
        onViewCode,
        onShowQRCode,
    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
             <Tooltip title="Display Code">
                <IconButton
                    data-intercom-target='display-code'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onViewCode(row);
                    }}
                    className={classes.actionCellIcon}
                >
                    <CodeIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title="View QR Code">
                <IconButton
                    data-intercom-target='display-qrcode'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (typeof onShowQRCode === 'function') {
                            onShowQRCode(row);
                        }
                    }}
                    className={classes.actionCellIcon}
                >
                    <FontAwesomeIcon
                        icon={faQrcode}
                        style={{ fontSize: '14px' }}
                        />
                </IconButton>
            </Tooltip>

            <Tooltip title="Edit Display">
                <IconButton
                    data-intercom-target='display-edit'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onEdit(row);
                    }}
                    className={classes.actionCellIcon}
                >
                    <CreateIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Delete Display">
                <IconButton
                    data-intercom-target='display-delete'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(row);
                    }}
                    className={classes.actionCellIcon}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export function previewCell ({ index, row, column, props }) {
    const {
        classes,
        onViewEventPreview = () => {},
    } = props;

    return (
        <Grid
            className={classes.previewCell}
        >
            <Tooltip title="Calendar mode">
                <IconButton
                    data-intercom-target='display-preview'
                    data-ecal-ui-id='calendar-preview'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onViewEventPreview(row, 'calendar');
                    }}
                    className={classNames([classes.previewCellIcons, classes.previewCellIconsMargin])}
                >
                    <Badge
                        className={classes.badgeIcon}
                        badgeContent={'New'}
                        color={'secondary'}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                    </Badge>
                    <BorderAllIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Page mode">
                <IconButton
                    data-intercom-target='display-preview'
                    data-ecal-ui-id='page-preview'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onViewEventPreview(row, 'page');
                    }}
                    className={classNames([classes.previewCellIcons, classes.previewCellIconsMargin])}
                >
                    <PageModeIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Carousel mode">
                <IconButton
                    data-intercom-target='display-preview'
                    data-ecal-ui-id='carousel-preview'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onViewEventPreview(row, 'carousel');
                    }}
                    className={classNames([classes.previewCellIcons, classes.previewCellIconsMargin])}
                >
                    <CarouselModeIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Column mode">
                <IconButton
                    data-intercom-target='display-preview'
                    data-ecal-ui-id='column-preview'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onViewEventPreview(row, 'column');
                    }}
                    className={classes.previewCellIcons}
                >
                    <ColumnModeIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    );
}
