import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './pillCustomVibrantBlue.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */
const DEFAULT_ICON_SIZE = 44;
const PillCustomVibrantBlueSVG = (props) => {
	const {
		width,
		text,
        // iconSize,
        backgroundColor,
        fontColor,
        onTextMatrixChange,
	} = props;
	const textRef = useRef();
	const classes = useStyles({
		backgroundColor,
        fontColor,
	});
	const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 115 28.5142)');

	useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

	useEffect(() => {
		if (textRef.current) {
			const text = textRef.current;
			const tbox = text.getBBox();
			const newX = (118 - tbox.width / 2);
			// const newY = ((iconSize - tbox.height) / 2);
			const translation = 'matrix(1 0 0 1 ' + newX + ' 28.5142)';
			setTransformMatrix(translation);
		}
	}, [textRef.current, width, text]);
	return (
		<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
			viewBox='0 0 204 48' style={{ 'enable-background': 'new 0 0 204 48' }} xmlSpace='preserve'>
			<g>

				<linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='7.599592e-07' y1='1584.0967' x2='183.497' y2='1584.0967' gradientTransform='matrix(1 0 0 -1 0 1608)'>
					<stop offset='0' style={{ 'stop-color': '#3A1860' }} />
					<stop offset='1' style={{ 'stop-color': '#0F0138' }} />
				</linearGradient>
				<path className={classes.st0} d='M23.62,47.4C10.87,47.4,0.5,36.86,0.5,23.9S10.87,0.4,23.62,0.4h156.77c12.75,0,23.12,10.54,23.12,23.5
		s-10.37,23.5-23.12,23.5H23.62z'/>
				<path className={classes.st1} d='M180.38,0.9c12.47,0,22.62,10.32,22.62,23s-10.15,23-22.62,23H23.62C11.15,46.9,1,36.59,1,23.9
		s10.15-23,22.62-23H180.38 M180.38-0.1H23.62C10.57-0.1,0,10.65,0,23.9s10.57,24,23.62,24h156.77c13.04,0,23.62-10.75,23.62-24
		S193.43-0.1,180.38-0.1L180.38-0.1z'/>
			</g>
			<circle className={classes.st2} cx='23.73' cy='23.9' r='20' />
			<g>
				<defs>
					<rect id='SVGID_2_' x='8.73' y='13.9' width='27' height='19' />
				</defs>
				<clipPath id='SVGID_3_'>
					<use xlinkHref='#SVGID_2_' style={{ 'overflow': 'visible' }} />
				</clipPath>
				<g className={classes.st3}>
					<path className={classes.st4} d='M22.69,16.76h-6.52c-0.13-0.01-0.26,0.01-0.38,0.06c-0.12,0.04-0.23,0.11-0.32,0.2
			c-0.09,0.09-0.17,0.19-0.22,0.31c-0.05,0.12-0.08,0.24-0.08,0.37c0,0.13,0.03,0.25,0.08,0.37c0.05,0.12,0.12,0.22,0.22,0.31
			c0.09,0.09,0.2,0.15,0.32,0.2c0.12,0.04,0.25,0.06,0.38,0.06h6.52c0.13,0.01,0.26-0.01,0.38-0.06c0.12-0.04,0.23-0.11,0.32-0.2
			c0.09-0.09,0.17-0.19,0.22-0.31c0.05-0.12,0.08-0.24,0.08-0.37c0-0.13-0.03-0.25-0.08-0.37c-0.05-0.12-0.12-0.22-0.22-0.31
			c-0.09-0.09-0.2-0.15-0.32-0.2C22.94,16.78,22.81,16.76,22.69,16.76z'/>
					<path className={classes.st5} d='M34.79,13.9h-16.6c-0.13-0.01-0.26,0.01-0.38,0.06c-0.12,0.04-0.23,0.11-0.32,0.2
			c-0.09,0.09-0.17,0.19-0.22,0.31c-0.05,0.12-0.08,0.24-0.08,0.37s0.03,0.25,0.08,0.37c0.05,0.12,0.12,0.22,0.22,0.31
			c0.09,0.09,0.2,0.15,0.32,0.2c0.12,0.04,0.25,0.06,0.38,0.06h16.6c0.24-0.01,0.47-0.12,0.63-0.29c0.17-0.17,0.26-0.4,0.26-0.64
			s-0.09-0.46-0.26-0.64C35.25,14.02,35.03,13.92,34.79,13.9z'/>
					<path className={classes.st6} d='M31.3,31.06H18.19c-0.13-0.01-0.26,0.01-0.38,0.06c-0.12,0.04-0.23,0.11-0.32,0.2
			c-0.09,0.09-0.17,0.19-0.22,0.31c-0.05,0.12-0.08,0.24-0.08,0.37c0,0.13,0.03,0.25,0.08,0.37c0.05,0.12,0.12,0.22,0.22,0.31
			c0.09,0.09,0.2,0.15,0.32,0.2c0.12,0.04,0.25,0.06,0.38,0.06H31.3c0.24-0.01,0.47-0.12,0.63-0.29c0.17-0.17,0.26-0.4,0.26-0.64
			c0-0.24-0.09-0.46-0.26-0.64C31.77,31.17,31.54,31.07,31.3,31.06z'/>
					<path className={classes.st7} d='M22.46,25.33h-6.64c-0.24,0.01-0.47,0.12-0.63,0.29c-0.17,0.17-0.26,0.4-0.26,0.64
			c0,0.24,0.09,0.46,0.26,0.64c0.17,0.17,0.39,0.27,0.63,0.29h6.64c0.13,0.01,0.26-0.01,0.38-0.06c0.12-0.04,0.23-0.11,0.32-0.2
			c0.09-0.09,0.17-0.19,0.22-0.31c0.05-0.12,0.08-0.24,0.08-0.37c0-0.13-0.03-0.25-0.08-0.37c-0.05-0.12-0.12-0.22-0.22-0.31
			c-0.09-0.09-0.2-0.15-0.32-0.2C22.72,25.34,22.59,25.33,22.46,25.33z'/>
					<path className={classes.st8} d='M24.2,19.62H11.56c-0.25,0-0.49,0.1-0.67,0.27s-0.28,0.41-0.28,0.65s0.1,0.48,0.28,0.65
			c0.18,0.17,0.42,0.27,0.67,0.27H24.2c0.25,0,0.49-0.1,0.67-0.27c0.18-0.17,0.28-0.41,0.28-0.65s-0.1-0.48-0.28-0.65
			S24.45,19.62,24.2,19.62z'/>
					<path className={classes.st9} d='M28.62,22.48H18.19c-0.13-0.01-0.26,0.01-0.38,0.06c-0.12,0.04-0.23,0.11-0.32,0.2
			c-0.09,0.09-0.17,0.19-0.22,0.31c-0.05,0.12-0.08,0.24-0.08,0.37s0.03,0.25,0.08,0.37c0.05,0.12,0.12,0.22,0.22,0.31
			c0.09,0.09,0.2,0.15,0.32,0.2c0.12,0.04,0.25,0.06,0.38,0.06h10.43c0.13,0.01,0.26-0.01,0.38-0.06c0.12-0.04,0.23-0.11,0.32-0.2
			c0.09-0.09,0.17-0.19,0.22-0.31c0.05-0.12,0.08-0.24,0.08-0.37s-0.03-0.25-0.08-0.37c-0.05-0.12-0.12-0.22-0.22-0.31
			c-0.09-0.09-0.2-0.15-0.32-0.2C28.88,22.49,28.75,22.47,28.62,22.48z'/>
					<path className={classes.st10} d='M20.24,28.2H9.67c-0.24,0.01-0.47,0.12-0.63,0.29c-0.17,0.17-0.26,0.4-0.26,0.64c0,0.24,0.09,0.46,0.26,0.64
			c0.17,0.17,0.39,0.27,0.63,0.29h10.57c0.24-0.01,0.47-0.12,0.63-0.29c0.17-0.17,0.26-0.4,0.26-0.64c0-0.24-0.09-0.46-0.26-0.64
			C20.71,28.32,20.48,28.21,20.24,28.2z'/>
				</g>
			</g>
			<rect x='55.94' y='17.49' className={classes.st11} width='129.06' height='13.51' />
			<text ref={textRef} transform={transformMatrix} className={classNames([classes.st12, classes.st13, classes.st14])} >{props.text}</text>
		</svg>
	);
};

PillCustomVibrantBlueSVG.propTypes = {
	width: PropTypes.number,
	text: PropTypes.string,
	iconSize: PropTypes.number,
	onTextMatrixChange: PropTypes.func,
};

PillCustomVibrantBlueSVG.defaultProps = {
	width: 200,
	text: 'Sync to Calendar',
	iconSize: DEFAULT_ICON_SIZE,
	onTextMatrixChange: () => {},
};

export default PillCustomVibrantBlueSVG;
