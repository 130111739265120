// import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((p) => ({

    root: {
        display: 'block',
        width: '100%',
        minHeight: '48px',
    },

    inputBase: {
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 8px 0px 12px',
        minHeight: '48px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #7B7B7B',

        '& .MuiButtonBase-root': {
            width: '32px !important',
            height: '32px !important',
        },
    },

    truncate: (props) => {
        const {
            width,
        } = props;

        return {
            maxWidth: width || '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '16px',
        };
    },

    fileInput: {
        display: 'none',
    },

}));

export default useStyles;
