import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    TextField,
    Box,
} from '@material-ui/core';
import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';
import ImageUploader from '../../../../commons/imageUploader/ImageUploader';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

function Advertising (props) {
    const {
        adsLogo,
        uploadAdsLogo,
        deleteAdsLogo,
        setOptionData,
        optionData,
        errors,
        isUploading,
        adsLogoError,
    } = props;

    const classes = useStyles({ errors });

    const renderError = (error) => (
      <Typography
          variant={'caption'}
          weight={'medium'}
          className={classes.adUnitError}
      >
          {error}
      </Typography>
    );

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}>
                    <Grid className={classes.sectionTitle}>
                        <Typography
                            variant='h6'
                            weight='medium'
                            >
                            Exit Advertising
                        </Typography>
                    </Grid>
                    <Grid className={classes.sectionDescription}>
                        <Typography
                            variant='sub1'
                            >
                        </Typography>
                    </Grid>
                    <Box
                        display={'flex'}
                        // height={150}
                        marginBottom={'35px'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        border={'1px solid lightgrey'}
                        padding={'20px'}
                        // display={'flex'}
                        // justifyContent={'space-between'}
                        borderRadius={'4px'}
                    >
                    <Grid
                        justify={'center'}
                        alignItems={'center'}
                        container
                        // className={classes.sectionContent}
                        >

                        <Grid
                            item xs={5}
                            className={classes.imageSpace}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.uploadSection}
                                    >
                                        <Typography
                                                variant={'body1'}
                                                className={classes.imageTitle}
                                            >
                                                Ad Unit
                                        </Typography>
                                        <ImageUploader
                                            isUploading={isUploading && !errors.adUnitImage}
                                            hasCreate={true}
                                            hasDelete={true}
                                            imageData={adsLogo}
                                            createLabel={'Upload'}
                                            imageWidth='170px'
                                            imageHeight='140px'
                                            onImageSelected={(images) => {
                                                uploadAdsLogo(images);
                                            }}
                                            onImageDelete={() => {
                                              console.log('ads logo img deleted?');
                                              deleteAdsLogo();
                                            }}
                                        />
                                </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            className={classes.adUnit}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant={'body2'}
                                    weight={'light'}
                                    className={classes.adUnitText}
                                >
                                    You can upload a 300 x 250px clickable ad to appear on the success page of the display.
                                </Typography>

                                {(adsLogoError && renderError(adsLogoError)) || (errors.adUnitImage && renderError(errors.adUnitImage)) }
                            </Grid>
                            {/* { imageError &&
                                (
                                    <Grid item xs={12}>
                                        <Typography
                                            variant={'caption'}
                                            weight={'medium'}
                                            style={{ marginBottom: 30, color: 'red' }}
                                        >
                                            {imageError}
                                        </Typography>
                                    </Grid>
                                )
                            } */}
                        </Grid>
                        {(optionData.advertising.banner) && (
                            <Grid className={classes.inputBox} >
                                    <TextField
                                        InputProps={{
                                            className: classes.textInput,
                                        }}

                                        id="message"
                                        label="Destination URL"

                                        value={optionData.advertising.url}
                                        onChange={(e) => {
                                            setOptionData({
                                                ...optionData,
                                                advertising: {
                                                    ...optionData.advertising,
                                                    url: e.target.value,
                                                },
                                            });
                                        }}

                                        name="shareURL"
                                        variant="outlined"
                                        style={{ marginBottom: 32 }}
                                        /*
                                        helperText={ errors.text || '*Enter label text for this custom-label'}
                                        error={errors.text}
                                        */
                                    />
                            </Grid>
                        )}

                    </Grid>
                    </Box>

            </Grid>
        </React.Fragment>
    );
};

Advertising.propTypes = {
    setOptionData: PropTypes.func,
    uploadAdsLogo: PropTypes.func,
    deleteAdsLogo: PropTypes.func,
    adsLogo: PropTypes.any,
    optionData: PropTypes.object,
    imageError: PropTypes.bool,
};

Advertising.defaultProps = {
    setOptionData: () => {},
    uploadAdsLogo: () => {},
    deleteAdsLogo: () => {},
    optionData: PropTypes.object,
    adsLogo: null,
    imageError: false,
};

export default Advertising;
