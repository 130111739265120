import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { useSchedulesStore } from '../';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function useSchedulesErrors (props) {
    const {
        input,
    } = props;

    const store = useErrorStore();
    const schedulesStore = useSchedulesStore();
    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        hook: 'useSchedulesErrors',
        input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.getSchedules,
            store.observed.errors.addSchedule,
            store.observed.errors.searchSchedules,
            store.observed.errors.editSchedule,
            store.observed.errors.bulkEditSchedules,
            store.observed.errors.bulkDeleteSchedules,
            store.observed.errors.getSchedulesForSubscribersFilter,
            store.observed.errors.getSchedulesAsFilter,
            store.observed.errors.uploadScheduleLogo,
            store.observed.errors.addCategory,
        ],
    });

    useEffect(() => {
        schedulesStore.loadingTree = false;
    }, [store.observed.errors.addCategory]);

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useSchedulesErrors() Error Updates...', errors);
    }, [errors]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default useSchedulesErrors;
