import { makeStyles } from '@material-ui/styles';

const styles = (theme) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
        width: '196px',

        minWidth: '196px',
        minHeight: '250px',

        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        boxShadow: '0px 2px 5px #00000020',
        overflow: 'hidden',
    },

    filterContent: {
        width: 'calc(100% - 48px)',
        minHeight: 'calc(300px - 48px)',
        height: 'calc(300px - 48px)',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'hidden',
    },

    parentScrollable: {
        width: '100%',
        height: '200px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'scroll',
        marginTop: '4px',
        marginBottom: '16px',
    },

    contentScrollable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    filterRow: {
        width: '100%',
        display: 'flex',
        marginBottom: '4px',
    },

    filterButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    fields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    filterOptions: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '12px',
    },

    filterTitle: {
        marginBottom: '8px',
    },

    optionRadio: {
        width: '100%',
        height: '24px',
        marginBottom: '8px',

        '& .MuiTypography-body1': {
            fontSize: '12px !important',
        },
    },

});

export default styles;
export const useStyles = makeStyles(styles);
