import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
// const errorMessages = { ...ErrorAPIMessages };
const errorMessages = {
    ...ErrorAPIMessages,
    EVENT_LOCATION_REQUIRED: 'event location is required',
    EVENT_NAME_REQUIRED: 'event name is required',
    EVENT_DATE_TIME: 'the event\'s end date/time needs to be after it\'s start time',
    EVENT_TWITTER_URL: 'error when validating the input for event id 61775542ff4b210007443f18 : invalid twitter url',
    INVALID_FILE_INPUT: 'invalid file type uploaded',
};

const errorErrors = {
    ...ErrorAPIErrors,
     RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
};
const customErrorMap = {
    ...errorMap,
    RESOURCE_ALREADY_EXISTS: (err) => {
        return {
            title: 'Duplicate Buttons',
            message: err.message,
            label: err.message,
        };
    },

    EVENT_TWITTER_URL: (err) => {
        return {
            title: 'Invalid input',
            message: 'Invalid twitter url',
            text: err.message,
        };
    },

    EVENT_NAME_REQUIRED: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },

    EVENT_LOCATION_REQUIRED: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },

    INVALID_FILE_INPUT: (err) => {
        return {
            title: 'Invalid File',
            message: err.message,
            text: err.message,
        };
    },

    EVENT_DATE_TIME: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },

    INVALID_INPUT_PARAMETER: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };

    return validateError(errors[0], options);
}
