import React from 'react';
import classNames from 'classnames';
import {
    Box,
    Grid,
    Tooltip,
} from '@material-ui/core';

import { Typography, Button } from '../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';

import {
//    EventAvailableSharp as EventAvailableSharpIcon,
   // DeleteOutlined as DeleteIcon,
    CreateOutlined as CreateIcon,
} from '@material-ui/icons';

/* Custom Cells */
export function statusCell ({ index, row, column, props }) {
    const {
        classes,
        onChangeStatus = () => {},
    } = props;

    function renderActive () {
        return (
            <Tooltip title="Deactivate Publisher">
                <Grid>
                    <Button
                        style={{
                            width: '90px',
                        }}
                        className={classes.statusCell}
                        size="small"
                        onClick= { (e) => {
                            onChangeStatus(row);
                        }}
                        variant={'contained'}
                        color={'success'}
                    >
                        Active
                    </Button>
                </Grid>
            </Tooltip>
        );
    }

    function renderInactive () {
        return (
            <Tooltip title="Activate Publisher">
                <Grid>
                    <Button
                        style={{
                            width: '90px',
                        }}
                        size="small"
                        onClick= { (e) => {
                            onChangeStatus(row);
                        }}
                        variant={'contained'}
                        color={'secondary'}
                    >
                        Inactive
                    </Button>
                </Grid>
            </Tooltip>
        );
    }

    function renderButton () {
        if (row.status.toLowerCase() === 'active') {
            return renderActive();
        }
        return renderInactive();
    }

    return (
        <Grid>
            { renderButton() }
        </Grid>
    );
}

export function truncatedCell ({ value, classes, selector }) {
    return (
        <Tooltip
            title={value}
        >
            <Typography
                className={classes[selector]}
                variant={'body2'}
            >
                { value }
            </Typography>
        </Tooltip>
    );
}

export function emailCell ({ row, props }) {
    const {
        classes,
    } = props;

    return truncatedCell({
        value: row.email,
        classes,
        selector: 'emailCell',
    });
}

export function planNameCell ({ row, props }) {
    const {
        classes,
    } = props;

    return truncatedCell({
        value: row.planName,
        classes,
        selector: 'planName',
    });
}

export function nameCell ({ row, props }) {
    const {
        classes,
    } = props;

    return truncatedCell({
        value: `${row.firstName} ${row.lastName}`,
        classes,
        selector: 'nameCell',
    });
}

export function pubIdCell ({ row, props }) {
    const {
        classes,
    } = props;

    return truncatedCell({
        value: `${row.publisherId}`,
        classes,
        selector: 'pubIdCell',
    });
}

export function companyCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return truncatedCell({
        value: `${row.name}`,
        classes,
        selector: 'companyCell',
    });
}

export function actionsCell ({ index, row, column, props }) {
    const {
        classes,
        onEdit = () => {},
    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >

            <Tooltip title="Edit PUblisher">
                <IconButton
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log('Custom cell onEdit', row);
                        onEdit(row);
                    }}
                    className={classes.actionCellIcon}
                    data-intercom-target='display-edit'
                >
                    <CreateIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

/*
            <Tooltip title="Delete Publisher">
                <IconButton
                    data-intercom-target='display-delete'
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(row);
                    }}
                    className={classes.actionCellIcon}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            */

export function previewCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    function objectStyle () {
        if (row?.style?.width > 200) {
            return {
                width: row.style.width + 'px !important',
            };
        }
        return {
            width: '200px',
        };
    }

    return (
        <Grid
            className={classes.previewCell}
        >
            { row.draft
            ? <div style={objectStyle()}>
                <object className={classNames([classes.previewImage])}
                data={row.image}
                />
                </div>
            : <Tooltip title="Preview Pop-up">
                <button className='ecal-sync-widget-button' key={row.id} data-ecal-widget-id={row.id}>
                    {row.display?.text}
                </button>
              </Tooltip>
            }

        </Grid>
    );
}
