import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuid } from 'uuid';

import {
    Paper,
    Grid,
    Popper,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from '@material-ui/core';

import useStyles from './styles';

import Checkbox from '../../Checkbox';

const DEFAULT_WIDTH = 200;
function PopColumns (props) {
    const {
        selected,
        columns,
        excluded,
        fieldSelection,
        open,
        anchorEl,
        onClose,
        onUpdateSelection,
        width,
    } = props;

    const classes = useStyles();
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        if (open) {
           setCanClose(true);
        } else {
            setCanClose(false);
        }
    }, [open]);

    function isSelected (column) {
        return selected.includes(column[fieldSelection]);
    }

    function updateSelection (column, itemSelected) {
        const colValue = column[fieldSelection];

        if (!itemSelected) {
            onUpdateSelection([
                ...selected,
                colValue,
            ]);
            return;
        }
        // remove selected
        const filtered = selected.filter((v) => (v !== colValue));
        onUpdateSelection(filtered);
    }

    function renderItems () {
        if (!columns || columns.length <= 0) {
            return (
                <Grid className={classes.noData}>
                    No Columns
                </Grid>
            );
        }

        return columns.reduce((cols, data) => {
            const itemSelected = isSelected(data);
            if (excluded.includes(data.id)) {
                return cols;
            }

            return [
                ...cols,
                (
                    <MenuItem
                        key={uuid()}
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            updateSelection(data, itemSelected);
                        }}
                    >
                        <Checkbox
                            checked={itemSelected}
                            defaultChecked={itemSelected}
                            onChange={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                updateSelection(data, itemSelected);
                            }}
                            variant="primary"
                            label={data.label}
                        />
                    </MenuItem>
                ),
            ];
        }, []);
    }

    return (
        <ClickAwayListener
        onClickAway={() => {
            if (canClose) {
                onClose();
            }
        }}
    >
        <Popper
            disableAutoFocus={true}
            disableEnforceFocus={true}

            id="long-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}

            keepMounted
            open={open}
            onClose={onClose}
        >
            <Paper
                className={classes.popPaper}
                >
                <Grid
                    className={classes.menuRoot}
                    style={{
                    width: width || DEFAULT_WIDTH,
                    }}
                >
                    <Grid className={classes.menuListTitle}>
                        Columns
                    </Grid>
                    <MenuList
                        className={classes.menuList}
                    >
                        { renderItems() }
                    </MenuList>
                </Grid>
            </Paper>
        </Popper>
    </ClickAwayListener>
    );
}

PopColumns.propTypes = {
    columns: PropTypes.array,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    fieldSelection: PropTypes.string,

    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    width: PropTypes.any,

    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    onPopOut: PropTypes.func,
    onUpdateSelection: PropTypes.func,
};

PopColumns.defaultProps = {
    open: false,
    columns: [],
    selected: [],
    excluded: [],
    fieldSelection: 'id',

    width: '200px',

    anchorEl: null,
    onClose: () => {},
    onSelect: () => {},
    onPopOut: () => {},
    onUpdateSelection: () => {},

};

export default PopColumns;
