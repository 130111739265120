import React from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    Label as LabelIcon,
} from '@material-ui/icons';

export const columns = [
  { id: 'year', numeric: true, disablePadding: false, label: 'Subscriptions' },
  { id: 'Jan', numeric: true, disablePadding: false, label: 'Jan' },
  { id: 'Feb', numeric: true, disablePadding: false, label: 'Feb' },
  { id: 'Mar', numeric: true, disablePadding: false, label: 'Mar' },
  { id: 'Apr', numeric: true, disablePadding: false, label: 'Apr' },
  { id: 'May', numeric: true, disablePadding: false, label: 'May' },
  { id: 'Jun', numeric: true, disablePadding: false, label: 'Jun' },
  { id: 'Jul', numeric: true, disablePadding: false, label: 'Jul' },
  { id: 'Aug', numeric: true, disablePadding: false, label: 'Aug' },
  { id: 'Sep', numeric: true, disablePadding: false, label: 'Sep' },
  { id: 'Oct', numeric: true, disablePadding: false, label: 'Oct' },
  { id: 'Nov', numeric: true, disablePadding: false, label: 'Nov' },
  { id: 'Dec', numeric: true, disablePadding: false, label: 'Dec' },
  { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
];

export const dateTemplate = {
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
