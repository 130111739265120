import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    boxStyling: {
        backgroundColor: '#ffffff',
        padding: 24,
        '& .rct-node-icon': {
        color: '#0c0644!important',
        },
    },

    mainContainer: {
        // width: '100%',
        // maxWidth: '600px',
        // backgroundColor: '#ffffff',
        // borderRadius: 4,
        // padding: '24px',
        // boxShadow: theme.customShadows.widget,
    },

    sectionContentUpload: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
       // backgroundColor: '#FBFBFB',
        minHeight: '40px',
        borderRadius: '6px',
       // padding: '16px',
    },

    uploadSection: {
        width: '100%',
        height: '80px',
    },

    bottomSpace: {
        marginBottom: '24px',
    },

    imageTitle: {
        marginBottom: '10px !important',
        // fontWeight: 'bold !important',
    },

    imageMessage: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },

    textStyleBottomLine: {
        marginBottom: '30px',
        fontSize: '12px',
    },

}));
