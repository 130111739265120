import React from 'react';

import {
    Home as HomeIcon,
    /*
    Settings as SettingsIcon,
    EventNote as EventIcon,
    HelpOutline as HelpIcon,
    Queue as ButtonDisplayIcon,
    PlaylistAddCheck as MarketingIcon,
    TrendingUp as AnalyticsIcon,
    Code as DeveloperIcon,
    BuildRounded as GettingStartedIcon,
    */
} from '@material-ui/icons';

function getStructure (params) {
    return [
        { id: 0, label: 'Home', link: '/superadmin/home', icon: <HomeIcon /> },
    ];
}

export default getStructure;
