import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getURLElements, logInfo } from '../../utils';

function useTabByPath (props) {
    const history = useHistory();
    const {
        mapping = {
            button: 0,
            ad: 1,
            event: 2,
        },
        parentPath = 'admin/displays',
        onTour = false,
    } = props;

    function tabByPath (path) {
        if (!path) {
            return 0;
        }
        let tab = 0;
        Object.keys(mapping).forEach((key) => {
            if (path.indexOf(key) !== -1) {
                tab = mapping[key];
            }
        });
        return tab;
    }

    function getTab () {
        if (!history.location) {
            return 0;
        }
        const path = history.location.pathname;
        if (path.indexOf(parentPath) !== -1) {
            return tabByPath(path);
        }
        return 0;
    }
    const [tab, setTab] = useState(getTab());

    useEffect(() => {
        setTab(getTab());
    }, [history.location]);

    function updateAddressByTab () {
        logInfo('useTabByPath().updateAddresByTab(): onTour? ', onTour);
        if (onTour) {
            return;
        }

        let path = '';
        let query = '';
        if (history && history.location) {
            query = history.location.search;
        }

        for (const key in mapping) {
            if (mapping[key] === tab) {
                path = key;
                break;
            }
        }

        const {
            params,
        } = getURLElements();

        logInfo('getTabByPath() useEffect->Tab: ', params);

        if (path && !params.product_tour_id) {
            history.push(path + query);
        }
    }

    useEffect(() => {
        updateAddressByTab();
    }, [tab]);
    return {
        tab,
        setTab,
    };
}

export default useTabByPath;
