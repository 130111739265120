import { Auth } from 'aws-amplify';
import { logInfo } from '../../../utils';

import {
    post,
    put,
    endpoints,
    postGQL,
    upload,
    deleteCall,
} from '../../api';
import { getReferer } from '../../api/factory';

import {
   searchQuery,
} from './queries';

export function apiAuthGetPublisherInfo (data, { traceId }) {
    return post(
        endpoints().authGetPublisherInfo,
        data,
        {
            traceId,
        }
    );
}

export function apiSearch ({ name, email, publisherIds, token, traceId, page = 1 }) {
    const qInput = {
        filterInput: {
            name,
            email,
            publisherIds,
        },

        pageInput: {
            pageNumber: page,
            pageLength: 10,
        },
    };

    /*
    if (!name) {
        qInput.input = {};
    }
    */

    return postGQL(
        endpoints().searchPublishers,
        searchQuery({ ...qInput }),
        {
            token,
            traceId,
            action: 'searchPublishers',
        }
    );
}

export function apiUpdateProfile ({ user, token, traceId }) {
    return put(
        endpoints().updateProfile,
        user,
        {
            token,
            traceId,
            action: 'updateProfile',
        }
    );
}

export function apiUploadPublisherAssets ({ data, token, traceId }) {
   return upload(
        endpoints().uploadPublisherAssets,
        data,
        {
            token,
            traceId,
        }
    );
}

export function apiGenerateSVG ({ data, token, traceId }) {
    return post(
         endpoints().publisherGenerateSVG,
         data,
         {
             token,
             traceId,
         }
     );
}

export function apiPublisherLogo ({ data, token, traceId }) {
    return post(
         endpoints().publisherLogo,
         data,
         {
             token,
             traceId,
             returnOnError: true,
         }
     );
}

export function apiDeletePublisherLogo ({ token, traceId }) {
    return deleteCall(
         endpoints().publisherLogo,
         {
             token,
             traceId,
         }
     );
}

export function apiChangePassword ({ oldPassword, newPassword }) {
    function execRequest (resolve, reject) {
        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => {
            logInfo('apiChangePassword() Success for Resolve::', data);
            resolve(data);
        })
        .catch(err => {
            logInfo('apiChangePassword() Reject.. Error -> ', err);
            reject(err);
        });
    }
    return new Promise(execRequest);
}

export function apiInsertLead ({ data, token, traceId }) {
    return post(
        `${endpoints().adminCRM}/insertLead`,
        data,
        {
            token,
            traceId,
            headers: {
                'referrer': getReferer(),
            },
            returnOnError: true,
        }
    );
}
