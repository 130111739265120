import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  // Box,
} from '@material-ui/core';
// import { Typography } from '../../../../components/Wrappers';
import TableContainer from '../../../../commons/ECALTable/TableContainer';
import Dialog from '../../../../commons/dialog/Dialog';
// import Widget from '../../../../components/Widget';

import { useStyles } from './styles';

// import { useHistory } from 'react-router';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

// import {
//   useOptinsStore,
// } from '../../../store/models';
function QuickLinksList (props) {
    const {
        linksData,
        // onAdd,
        handleDelete,
        showProgress,
        handleEdit,
        isManagedByChild,
    } = props;
    // const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(false);
    const classes = useStyles();

    // const optinsStore = useOptinsStore();

    useEffect(() => {
      setLoading(showProgress);
    }, [showProgress]);
    return (
    <React.Fragment>
      <Grid item xs={12}>

        <TableContainer
              isManagedByChild={isManagedByChild}
              classes={classes}
              tableData={linksData}
              columns={columns}
              customCells={customCells}
              tableTitle='Quick Links'
              moduleType='quick links'
              showProgress={loading}
              options={options}

              onEdit={(data) => {
                handleEdit(data);
                // optinsStore.optinsForEdit = data;
                // optinsStore.saveOptinsForEdits();
                // history.push('/admin/marketing/optins/edit/' + data.id);
              }}

              onDelete={(data) => {
                console.log('onDelete Data: ', data);
                setDeleteTarget(data);
                setDeleteOpen(true);
              }}
            />

      </Grid>

      <Dialog
        openDialog={deleteOpen}
        handleAgree={() => {
          setDeleteOpen(false);
          handleDelete(deleteTarget);
          // optinsStore.deleteOptins({ ...deleteTarget });
        }}

        closeModal={() => {
          setDeleteOpen(false);
        }}
        title={'Delete quick link'}
        dialogContextText={'Are you sure you want to delete this quick link?'}
      />
    </React.Fragment>
  );
}

QuickLinksList.propTypes = {
    linksData: PropTypes.array,
    handleEdit: PropTypes.func,
    onAdd: PropTypes.func,
    handleDelete: PropTypes.func,
    showProgress: PropTypes.bool,
    isManagedByChild: PropTypes.bool,
};

QuickLinksList.default = {
  linksData: [],
    onAdd: () => {},
    handleDelete: () => {},
    handleEdit: () => {},
    showProgress: true,
    isManagedByChild: false,
};

export default QuickLinksList;
