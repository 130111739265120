import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { decodeToken } from 'react-jwt';
import {
    logInfo,
    toDateString,
    trackIntercom,
} from '../../../utils';

import {
    apiSuperAdminSearch,
    apiSuperAdminUpdatePublishers,
    apiSuperAdminUpdateEmail,
    apiSuperAdminGetPublisherById,
} from './api';

export const SearchFilters = {
    PLAN_ECAL_ENTERPRISE: 'Enterprise',
    PLAN_PRO: 'Pro',
    PLAN_GROW: 'Grow',
    PLAN_COMMUNITY: 'Community',
    PLAN_FREE: 'Free',
    PLAN_FREE_TRIAL: 'Free Trial',

    PLAN_SOCIAL: 'Social',
    PLAN_EXPERT: 'Expert',

    PLAN_GAMEDAY_PARTNER: 'GameDay Partner',
    PLAN_GAMEDAY_PRO: 'GameDay Professional',
    PLAN_EMBED_PARTNER: 'Embed Partner',
    PLAN_EMBED_PRO: 'Embed Professional',
    STATUS_ACTIVE: 'active',
    STATUS_INACTIVE: 'inactive',
};

const ERR_USER_NOT_IN_COGNITO = 'User doesn\'t exist in cognito user pool';

export function loginAsSuperAdmin () {
    this.superAdminDashboard = true;
    this.env.history.push('/superadmin');
}

export function sanitizeFilters (filters) {
    if (isEmpty(filters)) {
        return {
            status: [],
            plan: [],
        };
    }
    const status = [];

    for (const p in filters.status) {
        if (filters.status[p]) {
            status.push(p);
        }
    }

    const plan = [];

    for (const p in filters.plan) {
        if (p === 'enterpriseCustom' && filters.plan[p]) {
            plan.push(SearchFilters.PLAN_GAMEDAY_PARTNER);
            plan.push(SearchFilters.PLAN_GAMEDAY_PRO);
            plan.push(SearchFilters.PLAN_EMBED_PARTNER);
            plan.push(SearchFilters.PLAN_EMBED_PRO);
            continue;
        }

        if (p === 'others' && filters.plan[p]) {
            plan.push(SearchFilters.PLAN_SOCIAL);
            plan.push(SearchFilters.PLAN_EXPERT);
            continue;
        }

        if (p === 'freeTrial' && filters.plan[p]) {
            plan.push(SearchFilters.PLAN_FREE_TRIAL);
            continue;
        }

        if (filters.plan[p] && filters.plan[p]) {
            plan.push(p);
        }
    }

    const date = {};

    if (filters.startDate && filters.endDate) {
        date.startDate = toDateString(filters.startDate);
        date.endDate = toDateString(filters.endDate);
    }

    return {
        status,
        plan,
        ...date,
    };
}

export async function searchPublishers (searchQuery, filters) {
    this.env.showProgress();
    this.recentQuery = searchQuery;
    this.recentFilters = filters;
    logInfo('searchPublishers(): Query ->  ', searchQuery);
    logInfo('searchPublishers(): Filters -> ', filters);
    this.saveSearchFilters();

    const sanitized = sanitizeFilters(filters);
    logInfo('searchPublishers(): sanitized  -> ', sanitized);
    if (!this.env.userStore.superAdmin) {
        return;
    }

    this.user = this.env.userStore.user;

    const res = await apiSuperAdminSearch({
        query: searchQuery,
        filter: sanitized,
        token: this.user.token,
        traceId: this.env.traceId,
        page: this.pageInfo.page + 1,
    });
    let data = {};
    if (res.data.data) {
        data = res.data.data;
    }

    if (data &&
        data.superAdminSearch &&
        data.superAdminSearch.edges) {
        const publishers = data.superAdminSearch.edges.map((edge) => {
            const pubData = edge.node;
            const payload = decodeToken(pubData.token);
            logInfo('Decoded Token Payload : ', payload);
            return {
                ...pubData,
                email: payload.email,
                publisherId: payload.publisherId,
                orgId: payload.organisationId,
            };
        });

        logInfo('Publishers: ', publishers);
        this.publishers = publishers;
        this.observed.publishers = uuid();
        this.pageInfo = recomputePageInfo(this.pageInfo, data.superAdminSearch.pageInfo);
        this.observed.pageInfo = uuid();
    }
    this.env.closeProgress();
}

export async function getPublisherById (id) {
    // this.env.showProgress();

    if (!this.env.userStore.superAdmin) {
        return;
    }

    this.user = this.env.userStore.user;

    const res = await apiSuperAdminGetPublisherById({
        id,
        token: this.user.token,
        traceId: this.env.traceId,
    });

    let data = {};
    if (res.data.data) {
        data = res.data.data;
    }
    let publisher = null;
    if (data &&
        data.superAdminGetPublisher &&
        data.superAdminGetPublisher.edges) {
        const publishers = data.superAdminGetPublisher.edges.map((edge) => {
            const pubData = edge.node;
            const payload = decodeToken(pubData.token);
            console.log('publisherStore.getPublisher() Decoded Token Payload : ', payload);
            return {
                ...pubData,
                email: payload.email,
                publisherId: payload.publisherId,
                orgId: payload.organisationId,
            };
        });

        console.log('Publishers: ', publishers);
        if (publishers.length > 0) {
            publisher = publishers[0];
        }
    }
    // this.env.closeProgress();
    return publisher;
}

function recomputePageInfo (pageInfo, dataPageInfo) {
    const newPageInfo = {
        ...pageInfo,
        showPagingation: true,
    };
    newPageInfo.totalCount = Number(dataPageInfo?.endCursor);

    return newPageInfo;
}

function updatePageInfoNumber (pageInfo, newPage) {
    const newPageInfo = {
        ...pageInfo,
        page: newPage,
    };
    return newPageInfo;
}

export async function paginate (page) {
    this.pageInfo = updatePageInfoNumber(this.pageInfo, page);
    this.loadSearchFilters();
    await this.searchPublishers(this.recentQuery, this.recentFilters);
}

export function resetPagingation () {
    this.pageInfo = {
        showPagingation: false,
        page: 0,
        itemsPerPage: 10,
        totalCount: 0,
    };
    this.observed.pageInfo = uuid();
}

function sanitizeForStatusUpdate (p, poolId) {
    const status = p.status === 'active' ? 'inactive' : 'active';
    return {
        id: p.publisherId,
        orgId: p.orgId,
        status,
    };
}

export async function updateStatus (publisher) {
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;
    const pub = sanitizeForStatusUpdate(publisher, this.aws.cognitoUserPoolId);
    const res = await apiSuperAdminUpdatePublishers({
        publishers: [pub],
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();
    if (res.data.data) {
        this.searchPublishers(this.recentQuery, this.recentFilters);
        trackIntercom({
            event: 'superadmin update publisher status',
            data: {
                user_id: publisher.publisherId,
                status: pub.status,
                email: publisher.email,
                first_name: publisher.firstName,
                last_name: publisher.lastName,
            },
        });
    }
}

export async function updateBasicDetails (publisher) {
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    const sanitized = sanitizeBasicDetails(publisher, this.aws.cognitoUserPoolId);
    logInfo('updateBasicDetails() -> ', sanitized);

    const res = await apiSuperAdminUpdatePublishers({
        publishers: [sanitized],
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();
    logInfo('updateBasicDetails() -> ', res);
    if (res.data.data) {
        trackIntercom({
            event: 'superadmin update publisher basic details',
            data: {
                user_id: publisher.publisherId,
                email: publisher.email,
                first_name: publisher.firstName,
                last_name: publisher.lastName,
            },
        });
        this.loadSearchFilters();
        await this.searchPublishers(this.recentQuery, this.recentFilters);
        this.updateActivePublisher(publisher);
    }
}

function sanitizeBasicDetails (p, cognitoPoolId) {
    return {
        id: p.publisherId,
        orgId: p.orgId,
        firstName: p.firstName,
        lastName: p.lastName,
        dataRights: p.dataRights,
        cognitoPoolId: cognitoPoolId,
    };
}

export async function updatePublisherInfo ({ publisher }) {
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;
    const res = await apiSuperAdminUpdatePublishers({
        publishers: [sanitizePublisherInfo(publisher, this.aws.cognitoUserPoolId)],
        token: this.user.token,
        traceId: this.env.traceId,
    });

    this.env.closeProgress();

    if (res.data.data) {
        trackIntercom({
            event: 'superadmin update publisher info',
            data: {
                user_id: publisher.publisherId,
                email: publisher.email,
                company_name: publisher.company || publisher.name,
                company_industry: publisher.industry,
            },
        });
        this.loadSearchFilters();
        await this.searchPublishers(this.recentQuery, this.recentFilters);
        this.updateActivePublisher(publisher);
    }
}

function sanitizePublisherInfo (p, cognitoPoolId) {
    return {
        id: p.publisherId,
        orgId: p.orgId,
        cognitoPoolId,

        company: p.company,
        industry: p.industry,
        objective: p.objective,
        timezone: p.timezone,
    };
}

export async function updateEmail ({ publisher, newEmail }) {
    this.env.showProgress();
    this.env.errorStore.action = 'superAdminUpdateEmail';
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    logInfo('Update Email for Publisher -> ', publisher);
    const res = await apiSuperAdminUpdateEmail({
        id: publisher.publisherId,
        orgId: publisher.orgId,
        email: publisher.email,
        newEmail,
        cognitoPoolId: this.aws.cognitoUserPoolId,
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();
    logInfo('updateEmail() -> ', res);
    if (res.data.data) {
        trackIntercom({
            event: 'superadmin update email',
            data: {
                user_id: publisher.publisherId,
                email: publisher.email,
            },
        });

        // should be removed?
        const data = res.data.data;
        if (data.superAdminUpdateEmail.error &&
            data.superAdminUpdateEmail.error.message.indexOf(ERR_USER_NOT_IN_COGNITO) !== -1) {
               this.warning = {
                    title: 'Migration Warning',
                    message: 'Account email is updated. User email is not migrated yet in Cognito user pool.',
               };
               this.observed.warning = uuid();
        }

        this.loadSearchFilters();
        await this.searchPublishers(this.recentQuery, this.recentFilters);
        this.updateActivePublisher(publisher);
    }
}

export function updateActivePublisher (publisher) {
    const filtered = this.publishers.filter((p) => {
        if (p.publisherId === publisher.publisherId) {
            return true;
        }
        return false;
    });

    if (!isEmpty(filtered)) {
        const updated = filtered[0];
        this.setActivePublisher({
            ...updated,
            provider: this.activePublisher.provider || undefined,
        });
    }
}

export async function updateExpiryDate ({ publisher, expiryDateInfo }) {
    this.env.errorStore.action = 'superAdminUpdateEmail';
    this.env.showProgress();
    this.user = this.env.userStore.user;
    this.aws = this.env.userStore.aws;

    const sanitized = sanitizeForExpiryDate({
        ...publisher,
        dateExpiry: expiryDateInfo.dateExpiry,
    }, this.aws.cognitoUserPoolId);

    logInfo('updateExpiryDate() -> ', sanitized);

    const res = await apiSuperAdminUpdatePublishers({
        publishers: [sanitized],
        token: this.user.token,
        traceId: this.env.traceId,
    });
    this.env.closeProgress();

    logInfo('updateExpiryDate() -> ', res);
}

export async function reloadSearch (publisher) {
    this.loadSearchFilters();
    await this.searchPublishers(this.recentQuery, this.recentFilters);
    this.updateActivePublisher(publisher);
}

function sanitizeForExpiryDate (p, cognitoPoolId) {
    return {
        id: p.publisherId,
        orgId: p.orgId,
        dateExpiry: p.dateExpiry,
        cognitoPoolId: cognitoPoolId,
    };
}
