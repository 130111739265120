import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

import {
    useSchedulesStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import Timezones from '../../../commons/util/Timezone.json';

import useEventsError from '../../../store/models/event/errors/useEventErrors';
import useEventsImport from '../../../store/models/event/useEventsImport';
import useRemoteSearch from '../../../commons/ecal/selectsearch/useRemoteSearch';
import GenreList from '../../../commons/util/GenreList.json';
import { getSubGenreList } from '../manualEntry/GenreUtil';

import {
    NEW_SCHEDULE,
    ScheduleTypes,
    DefaultInput,
    DateFormats,
    getClientTimezone,
} from './utils';

function useModuleInput (props) {
    const history = useHistory();
    const {
        // eventsData,
        spreadsheetFile,
    } = props;
    const [input, setInput] = useState({
        ...DefaultInput,
        timezone: [getClientTimezone()],
    });

    const [subGenres, setSubGenres] = useState([]);
    const [showSubGenres, setShowSubGenres] = useState(false);

    const [dataProvider, setDataProvider] = useState({
        schedules: [NEW_SCHEDULE],
        scheduleTypes: ScheduleTypes,
        dateFormats: DateFormats,
        timezones: Timezones,
    });

    const [events, setEvents] = useState({
        headers: 'standard',
        rows: [],
    });

    const [progress, setProgress] = useState({
        save: false,
        upload: false,
    });

    const [messageOpen, setMessageOpen] = useState(false);

    const {
       errors: serviceErrors,
       hasError,
       hasInputError,
    } = useEventsError({
        progress,
        setProgress,
        input,
    });

    const {
        events: importedEvents,
        errors,
        warnings,
        importEvents,
        hasSpreadsheetErrors,
        saveImportedEvents,
    } = useEventsImport();

    const schedulesStore = useSchedulesStore();

    function initStore () {
        setProgress({ ...progress, save: false });
        schedulesStore.getSchedulesAsFilter();
    }

    useEffect(() => {
        if (spreadsheetFile) {
            setInput({
                ...input,
                fileName: spreadsheetFile.name,
            });
        }
    }, [spreadsheetFile]);

    useEffect(() => {
        setEvents({
            headers: 'standard',
            rows: importedEvents,
        });
    }, [importedEvents]);

    useEffect(() => {
        if (schedulesStore.schedulesAsFilter) {
            // Hide child schedules from the list
            const filteredChildSchedules = schedulesStore.schedulesAsFilter.filter((schedule) => {
                return !schedule.isChild;
            });

            updateScheduleProvider([
                NEW_SCHEDULE,
                ...filteredChildSchedules,
            ]);

            setDataProvider({
                ...dataProvider,
                schedules: [
                    NEW_SCHEDULE,
                    ...filteredChildSchedules,
                ],
            });
        }
    }, [schedulesStore.observed.schedulesAsFilter]);

    useEffect(() => {
        const subGenreList = getSubGenreList(input.genre);
        setSubGenres(subGenreList);
        const showSubGenresSelect = getSubGenreListLength(subGenreList);
        if (!showSubGenresSelect) {
            setInput({
                ...input,
                subGenre: '',
            });
        }
        setShowSubGenres(showSubGenresSelect);
    }, [input.genre]);

    useStoreModuleDependency({ initStore });

    function updateInput (data) {
        setInput(data);
    }

    function getSubGenreListLength (subGenreListValue) {
        if (isEmpty(subGenreListValue)) {
            return false;
        }
        return true;
    }

    async function uploadSpreadsheet (data) {
        setProgress({ ...progress, save: true });
        await importEvents({
            file: spreadsheetFile,
            dateFormat: input.dateFormat,
            timezone: input.timezone[0],
            ignoreFirstRow: input.ignoreFirstRow,
        });
        setProgress({ ...progress, save: false });
    }

    async function saveEvents () {
        setProgress({ ...progress, save: true });
        await saveImportedEvents({
            scheduleName: input.scheduleName,
            isNewSchedule: input.isNewSchedule,
            scheduleId: input.selectedSchedule[0],
            timezone: input.timezone[0],
            genre: input.genre,
            subGenre: input.subGenre,
        });

        setProgress({ ...progress, save: false });
        history.push('/admin/schedules');
    }

    const {
        dataProvider: scheduleProvider,
        updateProvider: updateScheduleProvider,
        setSelected: setRemoteSelected,
        selected,
    } = useRemoteSearch();

    function getSelectedScheduleData () {
        for (let i = 1; i < scheduleProvider.length; i++) {
            const scheduleInfo = scheduleProvider[i];
            if (scheduleInfo.value === selected[0]) {
                return scheduleInfo;
            }
        };
    }

    function updateExistingScheduleData (selectedScheduleData) {
        if (isEmpty(selectedScheduleData) || selectedScheduleData === undefined) {
            return;
        }

        const showSubGenresSelect = selectedScheduleData.subGenre;
        if (showSubGenresSelect) {
            setShowSubGenres(showSubGenresSelect);
        }
        setInput({
            ...input,
            genre: selectedScheduleData.genre,
            subGenre: selectedScheduleData.subGenre,
        });
    }

    useEffect(() => {
        const selectedScheduleData = getSelectedScheduleData();
        updateExistingScheduleData(selectedScheduleData);
    }, [input.selectedSchedule]);

    function onSelectSchedule (data) {
        const schedules = [
            ...data,
        ];
        setRemoteSelected(schedules);

        setInput({
            ...input,
            selectedSchedule: schedules,
            isNewSchedule: data[0] === '-' || data.length <= 0,
        });
    }

    async function searchSchedule (search) {
        const searchedSchedules = await schedulesStore.searchSchedules({
            name: search,
            draftStatus: '',
            renderSearchResult: false,
        });

        updateScheduleProvider([
            NEW_SCHEDULE,
            ...searchedSchedules,
        ]);
    }

    return {
        hasError,
        hasInputError,
        serviceErrors,

        uploadSpreadsheet,
        hasSpreadsheetErrors,
        saveEvents,
        events,
        progress,
        setProgress,
        errors,
        messageOpen,
        setMessageOpen,

        input,
        updateInput,

        dataProvider,
        warnings,

        searchSchedule,
        scheduleProvider,
        onSelectSchedule,

        genres: GenreList,
        subGenres,
        showSubGenres,
    };
}

export default useModuleInput;
