import React from 'react';

import {
    Grid,
    TextField,
} from '@material-ui/core';

import Checkbox from '../../../../../commons/Checkbox';

function dropDown (props) {
    const {
        classes,
        customFieldData,
        setCustomFieldData,
    } = props;

    function dataValue () {
        if (customFieldData.value && customFieldData.value.length > 0) {
            return customFieldData.value.join('\n');
        }
        return '';
    }

    return (
        <Grid
            className={classes.fieldsRoot}>
            <TextField
                id="message"
                label="Field name*"
                value={customFieldData.name}
                onChange={(e) => {
                  const v = e.target.value;

                  setCustomFieldData({
                      ...customFieldData,
                    name: v,
                  });
                }}
                name="message"
                variant="outlined"
                helperText={'Enter the name of the custom field.'}
            />

            <TextField
                inputProps={{
                    className: classes.multilineInput,
                }}
                id="message"
                label="Values*"
                multiline
                rows="4"
                value={dataValue()}
                onChange={(e) => {
                const v = e.target.value;
                const dropDownArray = v.split('\n');

                setCustomFieldData({
                    ...customFieldData,
                    value: dropDownArray,
                });
                }}
                name="message"
                variant="outlined"
                helperText={'Please enter one value per line'}
            />
             <Checkbox
                checked={customFieldData.required}
                defaultChecked={customFieldData.required}
                onChange={e => {
                    setCustomFieldData({
                        ...customFieldData,
                        required: !customFieldData.required,
                    });
                }}
                variant="primary"
                label=" Required"
            />
    </Grid>
    );
}

export default dropDown;
