import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    st0: {
        fill: 'url(#SVGID_1_)',
    },

    st1: {
        fill: '#A324AD',
    },

    st2: {
        fill: '#F28C00',
    },

    st3: {
        fill: '#EE7624',
    },

    st4: {
        fill: '#009BE0',
    },

    st5: {
        fill: '#01BFD7',
    },

    st6: {
        fill: '#F9BF01',
    },

    st7: {
        fill: '#76BD22',
    },

    st8: {
        fill: '#00A8E2',
    },

}));
