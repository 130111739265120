import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Grid,
    Tab,
    // Box,
} from '@material-ui/core';

import {
    EventNote as EventDetailsIcon,
    Link as LinkIcon,
    Group as SocialLinksIcon,
    Image as BannerIcon,
} from '@material-ui/icons';
import Tabs from '../../../commons/tabs';
import BasicDetails from './BasicDetails';
import Links from './Links';
import SocialLinks from './SocialLinks';
import EventBanner from './EventBanner';
import { useEventsStore } from '../../../store/models';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';
// import Preview from './Preview';

import useEventsInput from './useEventsInput';
// import { logInfo } from '../../../utils';
const Event = (props) => {
    const {
        location,
    } = props;
    const [tab, setTab] = useState(0);
    const [hasValidationFieldError, setHasValidationFieldError] = React.useState(false);
    const [dateInvalidError, setDateInvalidError] = React.useState(false);

    const {
        pageTitle,
        input,
        setInput,
        updateEvents,
        isManagedByFeed,
        isManagedByChild,
        hasEventId,
        referenceName,
        eventId,
        errors,
        saveDisabled,
    } = useEventsInput({
        location,
        hasValidationFieldError,
    });

    const eventsStore = useEventsStore();
    const {
        renderErrorDialog,
    } = useModuleErrorDialog({ errors });

    useEffect(() => {
      if (isEmptyString(input.name) || isEmptyString(input.data.location)) {
        setHasValidationFieldError(true);
      } else {
        setHasValidationFieldError(false);
      }
    }, [input?.name, input?.data?.location]);

    const isEmptyString = (str) => str.trim() === '';

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Tabs
                    onTabChange={(newTab) => {
                        console.log('Tab ', newTab);
                        setTab(newTab);
                    }}
                >
                    <Tab
                        label="Event details"
                        icon={<EventDetailsIcon />}
                        data-intercom-target={'event-details-tab'}
                        // classes={{ wrapper: classes.icon }}
                    />

                    <Tab
                        label="Links"
                        icon={<LinkIcon />}
                        data-intercom-target={'event-links-tab'}
                        // classes={{ wrapper: classes.icon }}
                    />
                    <Tab
                        label="Social Links"
                        icon={<SocialLinksIcon />}
                        data-intercom-target={'event-social-tab'}
                        // classes={{ wrapper: classes.icon }}
                    />
                    <Tab
                        label="Banner"
                        icon={<BannerIcon />}
                        data-intercom-target={'event-banner-tab'}
                        // classes={{ wrapper: classes.icon }}
                    />
                </Tabs>
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid item justify={'center'} container>
                            <BasicDetails
                                saveDisabled={saveDisabled}
                                pageTitle={pageTitle}
                                input={input}
                                setInput={setInput}
                                updateEvents={updateEvents}
                                isLoading={eventsStore.eventIsLoading}
                                isManagedByFeed={isManagedByFeed}
                                isManagedByChild={isManagedByChild}
                                hasEventId={hasEventId}
                                referenceName={referenceName}
                                eventId={eventId}
                                hasValidationFieldError={hasValidationFieldError}
                                errors={errors}
                                setHasValidationFieldError={setHasValidationFieldError}
                                dateInvalidError={dateInvalidError}
                                setDateInvalidError={setDateInvalidError}
                            />
                        </Grid>
                    )}

                    { tab === 1 && (
                        <Grid item justify={'center'} container>
                            <Links
                                pageTitle={pageTitle}
                                input={input}
                                setInput={setInput}
                                updateEvents={updateEvents}
                                isLoading={eventsStore.eventIsLoading}
                                isManagedByFeed={isManagedByFeed}
                                isManagedByChild={isManagedByChild}
                                hasValidationFieldError={hasValidationFieldError}
                            />
                        </Grid>
                    )}

                    { tab === 2 && (
                        <Grid item justify={'center'} container>
                            <SocialLinks
                                pageTitle={pageTitle}
                                input={input}
                                setInput={setInput}
                                isLoading={eventsStore.eventIsLoading}
                                updateEvents={updateEvents}
                                location={location}
                                isManagedByFeed={isManagedByFeed}
                                isManagedByChild={isManagedByChild}
                                hasValidationFieldError={hasValidationFieldError}
                                errors={errors}
                            />
                        </Grid>
                    )}

                    { tab === 3 && (
                        <Grid item justify={'center'} container>
                            {/* <Preview /> */}
                            <EventBanner
                                  pageTitle={pageTitle}
                                  input={input}
                                  isLoading={eventsStore.eventIsLoading}
                                  setInput={setInput}
                                  updateEvents={updateEvents}
                                  isManagedByFeed={isManagedByFeed}
                                  isManagedByChild={isManagedByChild}
                                  hasValidationFieldError={hasValidationFieldError}
                            />
                        </Grid>
                    )}

                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>

    );
};

export default observer(Event);
