import React, { PureComponent } from 'react';
import FontAwesomeIcon from 'react-fontawesome';
// import styled from 'styled-components';

// const TextWrapper = styled.span`
//  margin: 5px 0;
//  display: inline-block;
// `

export default class PopUpInfo extends PureComponent {
  render () {
    const { info } = this.props;
    const displayName = `${info.properties.city},`;

    return (
      <div>
        <div>
          <FontAwesomeIcon name='users' size="1x" />&nbsp; {info.properties.count}<br />
          <span>{displayName} {' '} {info.properties.countryCode}</span>
          {/* <a
            target="_new"
            href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${displayName}`}
          >
            Wikipedia
          </a> */}
        </div>
        {/* <img width={240} alt={displayName} src={info.image} /> */}
      </div>
    );
  }
}
