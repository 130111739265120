import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import ButtonProgress from '../../../commons/ButtonProgress';
import { Typography } from '../../../components/Wrappers/Wrappers';
import { useStyles } from '../profile.styles';

import useProfilePasswordChange from './useProfilePasswordChange';

function Security (props) {
    const {
        passData,
        onDataChange,
        onSavePassword,
        isFederated,
    } = props;

    const classes = useStyles();

    const {
        handleChange,
        data,
        showOldPassword,
        setShowOldPassword,
        showNewPassword,
        setShowNewPassword,
        showRetypePassword,
        setShowRetypePassword,
        saveDisabled,
        isLoading,
        renderErrorDialog,
        renderDialog,
        savePassword,

        changePasswordError,
      } = useProfilePasswordChange({
        passData,
        onDataChange,
        onSavePassword,
      });

    function renderForm () {
        if (isFederated) {
            return (
                <React.Fragment></React.Fragment>
            );
        }
        return (
            <Grid container spacing={6} className={classes.passwordContainer}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                Password
                                </Typography>

                                { isFederated && (
                                    <Typography
                                        variant={'body1'}
                                        style={{
                                            marginBottom: 30,
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Note: Password change is not available for third-party sign in.
                                    </Typography>
                                )}

                                <TextField
                                    id="old-password"
                                    label="Old Password"
                                    onChange={handleChange}
                                    name="oldPassword"
                                    value={data.oldPassword || ''}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={ changePasswordError.oldPassword || 'Please enter your old password' }
                                    error={ changePasswordError.oldPassword }
                                    InputProps={{
                                        type: showOldPassword ? 'text' : 'password',
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                    <Box onClick={() => {
                                                        setShowOldPassword(!showOldPassword);
                                                    }}>
                                                    <FontAwesomeIcon icon={ showOldPassword ? faEye : faEyeSlash } />
                                                    </Box>
                                            </InputAdornment>
                                        ),

                                    }}
                                    disabled={isFederated}
                                />
                                <TextField
                                    id="new-password"
                                    label="New Password"
                                    onChange={handleChange}
                                    name="newPassword"
                                    value={data.newPassword || ''}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={ changePasswordError.newPassword || 'Please enter your new password' }
                                    error={ changePasswordError.newPassword }
                                    InputProps={{
                                        type: showNewPassword ? 'text' : 'password',
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                    <Box onClick={() => {
                                                        setShowNewPassword(!showNewPassword);
                                                    }}>
                                                    <FontAwesomeIcon icon={ showNewPassword ? faEye : faEyeSlash } />
                                                    </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={isFederated}
                                />
                                <TextField
                                    id="old-password"
                                    label="Re-enter Password"
                                    onChange={handleChange}
                                    name="rePass"
                                    value={data.rePass || ''}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText={ changePasswordError.rePass || 'Please re-enter your new password' }
                                    error={ changePasswordError.rePass }
                                    InputProps={{
                                        type: showRetypePassword ? 'text' : 'password',
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                    <Box onClick={() => {
                                                        setShowRetypePassword(!showRetypePassword);
                                                    }}>
                                                    <FontAwesomeIcon icon={ showRetypePassword ? faEye : faEyeSlash } />
                                                    </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={isFederated}
                                />
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <ButtonProgress
                                        disabled={saveDisabled}
                                        showProgress={isLoading}
                                        label="Change Password"
                                        onClick={(e) => {
                                            savePassword();
                                        }}
                                    />

                                </Box>
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            { renderForm() }
            { renderErrorDialog() }
            { renderDialog() }
        </React.Fragment>
    );
}

Security.propTypes = {
    user: PropTypes.object,
    onPasswordChange: PropTypes.func,
    isFederated: PropTypes.bool,
};

Security.defaultProps = {
    user: {},
    passData: {
        oldPassword: '',
        newPassword: '',
        rePass: '',
    },
    isFederated: false,
    onSavePassword: () => { },
};

export default observer(Security);
