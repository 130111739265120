import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../commons/Checkbox';

import {
    Grid,
    // Tooltip,
} from '@material-ui/core';

function RequiredCell (props) {
    const {
        row,
        tableProps,
    } = props;

    const {
        // classes,
        onTableStateChange,
    } = tableProps;

    function checkedValue () {
        if (row.field === 'requiredAge' || row.field === 'parentalConsent') {
            return true;
        }
        return row.requiredValue;
    }

    function disabledValue () {
        if (row.field === 'requiredAge' || row.field === 'parentalConsent') {
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            {(row.requiredType === 'check' &&
                <Grid>
                     <Checkbox
                        disabled={disabledValue()}
                        checked={checkedValue()}
                        defaultChecked={checkedValue()}
                        onChange={e => {
                            if (typeof onTableStateChange === 'function') {
                                onTableStateChange({
                                    column: 'required',
                                    field: row.field,
                                    row: row,
                                    newValue: !row.requiredValue,
                                });
                            }
                        }}
                        variant="primary"
                        label=" Required"
                    />
                </Grid>
            )}

        </React.Fragment>
    );
}

RequiredCell.propTypes = {
    tableProps: PropTypes.any,
    row: PropTypes.any,
    column: PropTypes.any,
    index: PropTypes.number,
};

RequiredCell.defaultProps = {
    onDataChange: () => {},
    row: {},
    index: 0,
    column: '',
};

export default memo(RequiredCell);
