import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import {
    useLabelsStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';

import labelTypes from '../../../commons/util/labelTypes.json';
import language from '../../../commons/util/Language.json';

import useCustomLabelErrors from '../../..//store/models/labels/errors/useCustomLabelErrors';
import { useTouchContext } from '../../../app/context/TouchContext';

const EDIT = 'edit';
const ADD = 'add';

function useModuleInput (props) {
    const {
        location,
    } = props;

    const history = useHistory();

    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return EDIT;
        }
        return ADD;
    }
    const [mode] = useState(getMode());

    const [input, setInput] = useState({
        ordinal: 0,
        id: 0,
        label: labelTypes[0].value,
        language: language[0].value,
        text: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const labelStore = useLabelsStore();
    const { touches } = useTouchContext('useCustomLabelErrors');

    function initStore () {
        if (mode === EDIT) {
            labelStore.loadLabelForEdit();
            initWithEditData();
        }
    }

    function initWithEditData () {
        if (labelStore.labelForEdit) {
            if (labelStore.labelForEdit.language === '') {
                labelStore.labelForEdit.language = 'default';
            }

            setInput({
                ...labelStore.labelForEdit,
            });
        } else {
            history.push('/admin/marketing/custom-labels');
        }
    }

    function upsert () {
        const params = cloneDeep(input);
        if (mode === EDIT) {
            labelStore.editLabel(params);
        } else {
            labelStore.addLabel(params);
        }
    }

    useEffect(() => {
        setIsLoading(false);
        setProgress(false);
    }, []);

    useEffect(() => {
        console.log(' Input is Updated in useModuleInput: ', input);
    }, [input]);

    useStoreModuleDependency({ initStore });

    function updateInput (newInput) {
        console.log('updateInput(): ', newInput);
        setInput(newInput);
        // validateInput(newInput);
    }

    const {
        errors,
        hasError,
    } = useCustomLabelErrors({
        input,
    });

    useEffect(() => {
        setIsLoading(false);
        setProgress(false);
        if (isEmpty(errors)) {
            if (touches.text) {
                setSaveDisabled(false);
            }
        } else {
            setSaveDisabled(true);
        }
    }, [errors]);

    return {
        input,
        updateInput,
        setIsLoading,
        upsert,

        hasError,
        isLoading,
        progress,
        errors,
        saveDisabled,
        setSaveDisabled,
    };
}

export default useModuleInput;
