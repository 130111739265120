import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
    useOptinsStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import useOptinsErrors from '../../../store/models/optins/errors/useOptinsErrors';

const DEFAULT_OPTINS_MESSAGE = 'Yes, I would like to receive additional marketing communications suiting my profile, in accordance with [Publisher Name]\'s <a href="[insert your Privacy Policy url]">Privacy Policy</a> and <a href="[insert your Terms of Use url]">Terms of Use</a>.';
const DEFAULT_OPTINS_MESSAGE_NEW_PUB = 'I have read and agree to [Publisher Name]\'s <a href="[insert your Privacy Policy url]">Privacy Policy</a> and <a href="[insert your Terms of Use url]">Terms of Use</a>';
const EDIT = 'edit';
const ADD = 'add';

function useOptinsInput (props) {
    const {
        location,
    } = props;

    const history = useHistory();

    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return EDIT;
        }
        return ADD;
    }
    const [mode] = useState(getMode());

    const [input, setInput] = useState({
        id: 0,
        language: 'default',
        message: DEFAULT_OPTINS_MESSAGE,
        ref: '',
        isSelected: false,
        optional: false,
    });

    const {
        errors,
        hasError,
    } = useOptinsErrors({ input });

    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);

    const optinsStore = useOptinsStore();

    function initStore () {
        setIsLoading(false);
        setProgress(false);
        if (mode === EDIT) {
            optinsStore.loadOptinsForEdit();
            initWithEditData();
        }
        if (mode === ADD) {
            initWithAddData();
        }
    }

    function initWithEditData () {
        if (optinsStore.optinsForEdit) {
            if (optinsStore.optinsForEdit.language === '') {
                optinsStore.optinsForEdit.language = 'default';
            }

            setInput({
                ...optinsStore.optinsForEdit,
            });
        } else {
            history.push('/admin/marketing/optins');
        }
    }

    function initWithAddData () {
        if (optinsStore.optins.length === 0) {
            if (optinsStore.optinsForEdit && optinsStore.optinsForEdit.language === '') {
                setInput({
                    ...input,
                    message: DEFAULT_OPTINS_MESSAGE_NEW_PUB,
                });
            } else {
                setInput({
                    ...input,
                    message: DEFAULT_OPTINS_MESSAGE,
                });
            }
        }
    }

    function upsertOptins () {
        if (mode === EDIT) {
            optinsStore.editOptins(input);
        } else {
            optinsStore.addOptins(input);
        }
    }

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        setIsLoading(false);
        setProgress(false);
    }, [errors]);

    return {
        errors,
        hasError,

        isLoading,
        progress,

        input,
        setInput,
        setIsLoading,
        upsertOptins,
    };
}

export default useOptinsInput;
