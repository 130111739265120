import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import {
    ViewColumn as ColumnIcon,
    Search as SearchIcon,
    FilterList as FilterIcon,
} from '@material-ui/icons';

import useStyles from './styles';
function IconButtons (props) {
    const {
        onSearchClick,
        onFilterClick,
        onColumnClick,
        hasSearch,
        hasColumns,
        hasFilters,
    } = props;

    const classes = useStyles();
    return (
        <Grid className={classes.buttons}>
            { hasSearch && (
                <Tooltip title='Search'>
                    <IconButton
                        onClick= { (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSearchClick(e);
                        }}

                        color={'primary'}
                    >
                        <SearchIcon fontSize={'medium'} />
                    </IconButton>
                </Tooltip>
            )}

            { hasColumns && (
                <Tooltip title='Columns'>
                    <IconButton
                        onClick= { (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onColumnClick(e);
                        }}

                        color={'primary'}
                    >
                        <ColumnIcon fontSize={'medium'} />
                    </IconButton>
                </Tooltip>
            )}

            { hasFilters && (
                <Tooltip title='Filters'>
                    <IconButton
                        onClick= { (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onFilterClick(e);
                        }}

                        color={'primary'}
                    >
                        <FilterIcon fontSize={'medium'} />
                    </IconButton>
                </Tooltip>
            )}
        </Grid>
    );
}

IconButtons.propTypes = {
    onSearchClick: PropTypes.func,
    onColumnClick: PropTypes.func,
    onFilterClick: PropTypes.func,
    hasSearch: PropTypes.bool,
    hasColumns: PropTypes.bool,
    hasFilters: PropTypes.bool,
};

IconButtons.defaultProps = {
    onSearchClick: () => {},
    onColumnClick: () => {},
    onFilterClick: () => {},
    hasSearch: true,
    hasColumns: true,
    hasFilters: true,
};

export default IconButtons;
