import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Dialog from '../../../commons/dialog/Dialog';
import { useErrorStore } from '.';
import { useEnvStore } from '../env';

function useModuleErrorDialog (props) {
    const errStore = useErrorStore();
    const envStore = useEnvStore();
    const {
        errors,
        onCloseError = () => {},
    } = props;

    const [errorOpen, setErrorOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        // console.log('errors is updated...', errors);
        envStore.closeProgress();
        if (!isEmpty(errors) && errors.title && errors.message) {
            setTitle(errors.title);
            setMessage(errors.message);
            setErrorOpen(true);
        } else {
            // console.log('useModuleErrorDialog().useEffect() -> Error is empty.. ');
            setTitle('');
            setMessage('');
            setErrorOpen(false);
        }
    }, [errors]);

    function renderErrorDialog () {
        if (isEmpty(errors) || !errorOpen) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <Dialog
                openDialog={errorOpen}
                buttonSecondaryFlag={false}
                buttonPrimary={'Ok'}
                showSubtitle={true}
                subtitle={'Ref.ID: ' + errStore.env.traceId}
                handleAgree={() => {
                    errStore.errors = {};
                    errStore.observed.errors = {};
                    errStore.cancelError();
                    setErrorOpen(false);
                    if (typeof onCloseError === 'function') {
                        onCloseError();
                    }
                }}

                closeModal={() => {
                    errStore.errors = {};
                    errStore.observed.errors = {};
                    setErrorOpen(false);
                    errStore.cancelError();
                    if (typeof onCloseError === 'function') {
                        onCloseError();
                    }
                }}
                title={title}
                dialogContextText={message}
            />
        );
    }

    return {
        title,
        message,
        errorOpen,
        setErrorOpen,
        renderErrorDialog,
    };
}

export default useModuleErrorDialog;
