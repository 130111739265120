import { v4 as uuid } from 'uuid';
import { errorMap } from './error_map';
import {
    getEnvInfo,
    logInfo,
} from '../../../utils';

export function dispatchSessionError () {
    logInfo('models.errors.actions.dispatchSessionError()');
    const settings = getEnvInfo();
    if (settings.signedOut) {
        return;
    }
    this.genericErrors = errorMap.INVALID_ACCESS_TOKEN;
    this.observed.errors.generics = uuid();
}

export function dispatchError (action, errors) {
    this.errors = errors;
    this.observed.errors[action] = uuid();
}

export function cancelError () {
    this.errors = {};
    this.observed.errors = {};
    this.observed.errorCancel = uuid();
}

export function dispatchAWSMarketplaceBillingError (err) {
    this.genericErrors = {
        errCode: 'INVALID TRANSACTION',
        title: 'Invalid Billing Information',
        message: err.message,
    };
    this.observed.errors.generics = uuid();
}
