import { useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
    logInfo,
    getURLElements,
    // urlParams,
} from '../../utils';
// import useFeatures from '../../pages/hooks/useFeatures';

import {
    getSidebarStructureData,
    isGettingStartedOpen,
} from './clients/';

function useSidebarStructure (props) {
    const {
        whiteLabelSettings,
        env,
        isSuperAdmin = false,
    } = props;

    const sidebarScrollParent = useRef();

    function getStructureData () {
        let structureFunc = getSidebarStructureData('ecal', env);

        if (isSuperAdmin) {
            console.log('useSidebarStructure() -> isSuperAdmin: ', isSuperAdmin);
            structureFunc = getSidebarStructureData('superAdmin');
             return structureFunc({
                onGettingStartedClick,
                gettingStartedOpen: isGettingStartedOpen(env),
            });
        }

        if (whiteLabelSettings && whiteLabelSettings?.sidebar) {
            structureFunc = getSidebarStructureData(whiteLabelSettings?.sidebar, env);
            return structureFunc({
                onGettingStartedClick,
                gettingStartedOpen: isGettingStartedOpen(env),
            });
        }

        return structureFunc({
            onGettingStartedClick,
            gettingStartedOpen: isGettingStartedOpen(env),
        });
    }

    const [closeAll, setCloseAll] = useState('');
    const [structure, setStructure] = useState([]);

    useEffect(() => {
        setStructure(getStructureData());
    }, []);

    useEffect(() => {
        console.log('useSidebarStructure() structure by whitelabel..: ', whiteLabelSettings);
        setStructure(getStructureData());
    }, [whiteLabelSettings]);

    // NOTE: shouldClose() will be triggered when closeAll state is updated..
    // see SidebarLink useEffect(()=>{}[closeAll]) , this side effect will update SidebarLink isOpen state.
    // This workaround is created because Sidebar and SidebarLink states are final

    function shouldClose (data) {
        const { label } = data;

        const closeExcluded = [
            'Getting Started',
        ];

        if (closeExcluded.includes(label)) {
            return false;
        }
        return true;
    }

    function navigateForTourId (data) {
        /*
        const params = urlParams(data.link);
        if (params.product_tour_id === '339837') {
            window.open(data.link, '_self');
            return true;
        }
        */
        return false;
    }

    function scrollUp () {
        const parent = sidebarScrollParent?.current?.parentElement;
        if (parent) {
            parent.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    function tourWhenReady (tourId, count, link) {
        logInfo('tourWhenReady() -> ', tourId);

        if (count > 10) {
            window.location = link + '?product_tour_id=' + tourId;
        }

        if (env?.observed?.intercomBoot) {
            window.Intercom('startTour', tourId);
        } else {
            setTimeout(() => tourWhenReady(tourId, count++, link), 500);
        }
    }

    function onGettingStartedClick (data) {
        scrollUp();
        const { path, params } = getURLElements();
        const link = data.link.split('?')[0];
        env.onTour = true;
        logInfo('useSidebarStructure.onGettingStartedClick() ', data);
        logInfo('Path: ', path);
        logInfo('Window Location: ', window.location);
        logInfo('onGettingStartedClick() -> Params: ', params);
        logInfo('Link: ', link);
        const linkDataParam = data.link.split('product_tour_id=')[1];

        logInfo('onGettingStartedClick() Link Data Param: ', linkDataParam);
        let tourId = Number(linkDataParam);
        if (!tourId) {
            tourId = Number(params.product_tour_id);
        }

        if (navigateForTourId(data)) {
            return;
        }

        if (link === path && params.product_tour_id) {
            // will only trigger the tour when link and path are the same..
            logInfo(' Will do the tour based on click');
            logInfo('tourID >>', tourId);
            // window.Intercom('startTour', tourId);
            tourWhenReady(tourId, 0, link);
            // env.history.push('/admin/home/summary?product_tour_id=' + tourId);
            // window.location = '/admin/home/summary?product_tour_id=' + tourId;
        } else if (link === path && linkDataParam) {
            // will only trigger the tour when link and path are the same..
            logInfo(' Will do the tour based on click');
            const tourId = Number(linkDataParam);
            logInfo('tourID >>', tourId);
            // window.Intercom('startTour', tourId);
            tourWhenReady(tourId, 0, link);
            // env.history.push('/admin/home/summary?product_tour_id=' + tourId);
           // window.location = '/admin/home/summary?product_tour_id=' + tourId;
        }

        setCloseAll(uuid()); // this will trigger to close all open elements
    }

    return {
        structure,
        shouldClose,
        closeAll,
        sidebarScrollParent,
    };
}

export default useSidebarStructure;
