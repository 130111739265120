import React from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    Label as LabelIcon,
} from '@material-ui/icons';

import {
    cityCell,
    countryCell,
    countCell,
    percentageCell,
} from './customCells';

export const columns = [
    {
        id: 'city',
        numeric: true,
        disablePadding: false,
        label: 'City',
    },

    {
        id: 'countryCode',
         numeric: true,
        disablePadding: false,
        label: 'Country',
    },

    {
        id: 'count',
        numeric: true,
        disablePadding: false,
        label: 'Count',
    },

    {
        id: 'percentage',
        numeric: true,
        disablePadding: false,
        label: '%',
    },
];

export const customCells = {
    city: cityCell,
    countryCode: countryCell,
    count: countCell,
    percentage: percentageCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
