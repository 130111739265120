import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
} from '@material-ui/core';
import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import Dialog from '../../../commons/dialog/Dialog';
import Widget from '../../../components/Widget';

import SecretkeyDialog from './secretKeyDialog';
import ConfirmDialog from './confirmDialog';

import { useStyles } from './styles';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

import {
  useAPIKeyStore,
} from '../../../store/models';
function ApiKeysList (props) {
    const {
        listData,
        onAdd,
        showProgress,
        showDeactivate,
    } = props;

    const [loading, setLoading] = useState(false);

    const [msgOpen, setMessageOpen] = useState(false);
    const [secretKeyOpen, setSecretKeyOpen] = useState(false);
    const [deactivateOpen, setDeactivateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [appName, setAppName] = useState('');
    const [secretKey, setSecretKey] = useState('');

    const classes = useStyles();
    const apikeyStore = useAPIKeyStore();

    useEffect(() => {
      setLoading(showProgress);
    }, [showProgress]);

    return (
    <React.Fragment>
      <Grid item xs={12}>
        <Widget className={classes.widgetContainer}
            title="Manage API Keys"
            inheritHeight
            disableWidgetMenu
            handleAdd={() => {
              if (listData.length >= 2) {
                setMessageOpen(true);
              } else {
                onAdd();
              }
            }}
            buttonText={'Add New Key'}
            >
            <Box
                justifyContent={'space-between'}
                display={'flex'}
                alignItems={'flex-start'}
            >
                <Typography variant='body2'>
                    Manage Your API Keys
                </Typography>
            </Box>
        </Widget>

        <TableContainer
              classes={classes}
              tableData={listData}
              columns={columns}
              customCells={customCells}
              tableTitle='API Keys'
              moduleType='API keys'
              showProgress={loading}
              options={options}

              onActivate={(data) => {
                apikeyStore.activateAPIKey(data.id);
              }}

              onDeactivate={(data) => {
                apikeyStore.deactivateTarget = data;
                setAppName(data.appName);
                setDeactivateOpen(true);
              }}

              onShowSecretKey={(data) => {
                setSecretKey(data.secret);
                setSecretKeyOpen(true);
              }}

              onDelete={(data) => {
                console.log('Delete Data: ', data);
                setAppName(data.appName);
                apikeyStore.deleteTarget = data;
                setDeleteOpen(true);
              }}
              showDeactivate={showDeactivate}
            />

      </Grid>

      <Dialog
        openDialog={msgOpen}
        buttonSecondaryFlag={false}
        handleAgree={() => {
          setMessageOpen(false);
        }}

        closeModal={() => {
          setMessageOpen(false);
        }}
        title={'Warning API Key'}
        dialogContextText={'You already have 2 keys in use. You\'ll need to delete a key before you can generate a new one.'}
      />

      <SecretkeyDialog
        open={secretKeyOpen}
        secretKey={secretKey}
        onClose={() => {
          setSecretKeyOpen(false);
        }}
        isLoading={!(secretKey)}
      />

      <ConfirmDialog
        title="Deactivate API Key"
        message="Are you sure you want to deactivate your ECAL key? Doing so will make any displays using this key inactive."
        okLabel="Deactivate"
        appName={appName}
        open={deactivateOpen}
        onClose={() => {
          setDeactivateOpen(false);
        }}
        onConfirm={() => {
          console.log('confirm deactivate...', apikeyStore.deactivateTarget);
          apikeyStore.deactivateAPIKey(apikeyStore.deactivateTarget.id);
          setDeactivateOpen(false);
        }}
        isLoading={true}
      />

    <ConfirmDialog
        title="Delete API Key"
        message="Are you sure you want to delete your ECAL key? You cannot recover deleted keys."
        okLabel="Delete"
        appName={appName}
        open={deleteOpen}
        onClose={() => {
          setDeleteOpen(false);
        }}

        onConfirm={() => {
          console.log('confirm delete...', apikeyStore.deleteTarget);
          apikeyStore.deleteAPIKey({
            ...apikeyStore.deleteTarget,
          });
          setDeleteOpen(false);
        }}
        isLoading={true}
      />
    </React.Fragment>
  );
}

ApiKeysList.propTypes = {
    listData: PropTypes.array,
    onAdd: PropTypes.func,
    showProgress: PropTypes.bool,
    showDeactivate: PropTypes.bool,
};

ApiKeysList.default = {
    listData: [],
    onAdd: () => {},
    showProgress: true,
    showDeactivate: true,
};

export default ApiKeysList;
