import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = {
    ...ErrorAPIMessages,
    INVALID_AD_UNIT_IMAGE: 'error resizing image: incorrect image size for button ad unit image, aspect ratio must be 6:5 (300x250)',
};

const errorErrors = {
    ...ErrorAPIErrors,
};

const customErrorMap = {
    ...errorMap,

    INVALID_AD_UNIT_IMAGE: {
        adUnitImage: 'incorrect image size for button ad unit image, aspect ratio must be 6:5 (300x250)',
    },

    INVALID_INPUT_PARAMETER: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}
