
import useImageUpload from '../../../hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../../hooks/useImageRenderable';
import { AssetType } from '../../../../utils/constants';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import { useButtonsStore } from '../../../../store/models';

function useDesignUploads (props) {
    const buttonsStore = useButtonsStore();
    const {
        designData,
        setDesignData,
        setTabDisabled = () => {},
    } = props;

    /*
     *  Asset Type: buttonLogo
     * style.logo
     */

    function setButtonLogoURL (url) {
        setTabDisabled(false);
        setDesignData({
            ...designData,
            logo: url,
        });
    };

    const {
        image: logoImage,
    } = useImageRenderable({
        imageSource: designData.logo,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: buttonLogo,
        upload,
        deleteImage: deleteButtonLogo,
        imageError: buttonLogoError,
    } = useImageUpload({
        assetType: AssetType.BUTTON_LOGO,
        observableDataSource: buttonsStore.observed.assets.buttonLogo,
        consumableDataSource: buttonsStore.assets.buttonLogo,
        setStateFunc: setButtonLogoURL,
        customDefaultImage: logoImage,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: true,
    });

    function uploadButtonLogo (params) {
        setTabDisabled(true);
        upload(params);
    }

    return {
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        buttonLogoError,
    };
}

export default useDesignUploads;
