import React, { useState, useEffect } from 'react';

import QRCodeDialog from './qrCodeDialog';
import ButtonCodeDialog from './buttonCodeDialog';

function useButtonCodeDialog (props) {
    const {
        title,
        activeAPIKey = '',
        renderUniwidget = () => {},
        options = { showRoles: true },
    } = props;

    const [buttonData, setButtonData] = useState({});
    const [qrCodeOpen, setQRCodeOpen] = useState(false);
    const [buttonCodeOpen, setButtonCodeOpen] = useState(false);

    const [codeData, setCodeData] = useState({
        qrCode: '',
        socialMedia: '',
        widgetId: '',
    });

    function renderQRCode () {
        return (
            <QRCodeDialog
                qrCode={codeData.qrCode}
                open={qrCodeOpen}
                onClose={() => {
                    setQRCodeOpen(false);
                    renderUniwidget(activeAPIKey);
                }}
            />
        );
    }

    function renderButtonCode () {
        return (
            <ButtonCodeDialog
                title={title}
                options={options}
                open={buttonCodeOpen}
                onClose={() => {
                    setButtonCodeOpen(false);
                    renderUniwidget(activeAPIKey);
                }}
                widgetId={codeData.widgetId}
                socialMediaLink={codeData.socialMedia}
                buttonData={buttonData}
                apiKey={activeAPIKey}
            />
        );
    }

    function showQRCode (data) {
        setButtonData(data);
        setQRCodeOpen(true);
    }

    function showButtonCode (data) {
        setButtonData(data);
        setButtonCodeOpen(true);
    }

    useEffect(() => {
        if (buttonData &&
            buttonData.settings &&
            buttonData.settings.directLinks
            ) {
            setCodeData({
                widgetId: buttonData.id,
                qrCode: buttonData.settings.directLinks.qrCodeDirectLink || buttonData.settings.directLinks.socialMediaDirectLink,
                socialMedia: buttonData.settings.directLinks.socialMediaDirectLink,
            });
        }
    }, [buttonData]);

    return {
        renderQRCode,
        renderButtonCode,
        showButtonCode,
        showQRCode,
    };
}

export default useButtonCodeDialog;
