import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
  widgetContainer: {
    paddingBottom: '24px',
  },
  boxStyling: {
    backgroundColor: '#ffffff',
    padding: 24,
    '& .rct-node-icon': {
      color: '#0c0644!important',
    },
  },

  mainContainer: {
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    boxShadow: theme.customShadows.widget,
  },

  treeContainer: {
    '& .rct-node-clickable': {
      display: 'none!important',
    },
  },

  buttonBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

}));
