import React from 'react';
import PropTypes from 'prop-types';

import {
    Radio,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import {
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    RadioButtonChecked as RadioButtonCheckedIcon,
} from '@material-ui/icons';

import { useStyles } from './styles';
export const ControlType = {
    RADIO: 'radio',
    CHECK: 'check',
};

function Control (props) {
    const {
        type,
        label,
        name,
        onChange,
        value,
        marginBottom,
    } = props;
    const classes = useStyles();

    function renderRadio () {
        return (
            <FormControlLabel
            className={classes.optionRadio}
            style={{ marginBottom: marginBottom }}
            label={label}
            control={
                <Radio
                    checked={false}
                    onChange={() => {
                        onChange(value);
                    }}
                    value={value}
                    color='default'
                    name={name}
                    inputProps={{ 'aria-label': label }}
                    icon={<RadioButtonUncheckedIcon fontSize='small' />}
                    checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                />
            }
        />
        );
    }

    function renderCheck () {
        return (
            <FormControlLabel
            className={classes.optionRadio}
            style={{ marginBottom: marginBottom }}
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={() => {
                        onChange(value);
                    }}
                    value={value}
                    color='default'
                    name={name}
                    inputProps={{ 'aria-label': label }}
                />
            }
        />
        );
    }

    function renderControl () {
        if (type === ControlType.RADIO) {
            return renderRadio();
        } else if (type === ControlType.CHECK) {
            return renderCheck();
        }
        return renderRadio();
    }

    return renderControl();
}

Control.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    marginBottom: PropTypes.number,
};

Control.defaultProps = {
    type: ControlType.RADIO,
    name: 'name',
    label: 'label',
    onChange: () => {},
    value: false,
    marginBottom: 8,
};

export default Control;
