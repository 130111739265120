import moment from 'moment';

export function combine (dateSource, timeSource) {
    const d = new Date(dateSource);
    const t = new Date(timeSource);
    d.setHours(t.getHours());
    d.setMinutes(t.getMinutes());
    return d;
}

export function isDateOk (start, end) {
    if (start.getTime() <= end.getTime()) {
        return true;
    }
    return false;
}

export function isTimeOk (startDate, startTime, endDate, endTime) {
    const s = removeTime(startDate);
    const e = removeTime(endDate);

    if (s === null) {
        return true;
    }

    if (e === null) {
        return true;
    }

    if (s.getTime() === e.getTime()) {
        const st = newTime(startTime);
        const et = newTime(endTime);
        if (!st) {
            return true;
        }

        if (!et) {
            return true;
        }
        if (st.getTime() >= et.getTime()) {
            return false;
        }
    }
    return true;
}

export function removeTime (src) {
    if (!src) {
        return null;
    }
    const d = new Date(src);
    d.setHours(0, 0, 0, 0);
    return d;
}

export function newTime (time) {
    if (!time) {
        return null;
    }
    const d = new Date();
    d.setHours(time.getHours(), time.getMinutes(), 0, 0);
    return d;
}

export function stringToTime (time) {
    if (!time) {
        return null;
    }
    const d = new Date();
    const t = time.split(':');
    const h = Number(t[0]);
    const m = Number(t[1]);
    d.setHours(h, m, 0, 0);
    /*
    console.log('time String: ', time);
    console.log('time:', t);
    console.log('hour: ', h);
    console.log('Minute: ', m);
    console.log('stringToTime(): ', d);
    */
    return d;
}

export function toDate (dateStr) {
    if (!dateStr) {
        return null;
    }
    return moment(dateStr).toDate();
}

export function toDateString (date) {
    if (!date) {
        return '';
    }
    return moment(date).format('yyyy-MM-DD');
}

export function toTimeString (date) {
    if (!date) {
        return '';
    }
    return moment(date).format('HH:mm');
}
