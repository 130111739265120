// import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        color: '#363b3f',
        fontFamily: 'GothamRounded-Book, sans-serif',
        marginTop: '5px',

        '& .daterangepicker': {
            minHeight: '340px !important',
        },

        '& select': {
            height: '42px',
            marginRight: '8px',
            // color: 'hsl(0,0%,50%) !important',
            color: '#0C0641 !important',
            fontSize: '16px',
            lineHeight: '4px',
            border: '1px solid #dfdfdf',
            cursor: 'pointer',
            position: 'relative',
            borderRadius: '4px',
            backgroundColor: '#fff',
            outline: '0px solid #fff',
           /* boxShadow: '0 2px 5px 0 rgba(0,0,0,.26), 0 2px 5px 0 rgba(0,0,0,.16)', */
            padding: '6px',
        },

        '& select option': {
            borderRadius: '4px',
            backgroundColor: '#ffffff',
            padding: '20px',
        },

        '& .multipleContentOnLine': {
            outline: '0px solid #fff !important',
        },

        '& .monthYearContainer': {
            marginBottom: '24px !important',
        },

        '& .rangebuttontextstyle': {
            paddingTop: '5px !important',
        },

        '& .rangecontainer': {
            marginTop: '42px',
        },

        '& .fromDateHourContainer': {
            display: 'none !important',
        },

        '& .activeNotifier': {
            display: 'none !important',
        },

        '& .rangecontainer > div[tabindex="-1"]': {
            color: '#0c0644 !important',
        },

        '& .rangecontainer > div[tabindex="0"]': {
            backgroundColor: '#0C0641 !important',
            border: '1px solid #0C0641 !important',
            outline: '0px solid #0C0641 !important',
        },

        '& .rangecontainer > div:hover': {
            backgroundColor: '#0E0846 !important',
            color: '#FFFFFF !important',
        },

        '& .calendarCell[tabindex="-1"]:hover': {
            backgroundColor: '#0C0641 !important',
            color: '#FFFFFF !important',
        },

        '& .calendarCell[tabindex="0"]': {
            backgroundColor: '#0C0641 !important',
            color: '#FFFFFF !important',
        },

    },

    inputStyle: (p) => {
        return {
            minWidth: '280px',

            '& input': {
                // textAlign: p.dateDisplay ? 'center' : 'left',
                textAlign: 'left',
                fontSize: '16px',
                color: '',
            },
        };
    },

}));

export default useStyles;
