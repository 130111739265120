const industryObjMap = [
    {
        Arts00: ['Awareness01', 'Attendance01', 'Ticket01', 'Membership01', 'Satisfy01', 'Engage01', 'Commerce01', 'Insights01'],
        Banking00: ['Awareness01', 'Satisfy01', 'Engage01', 'Payments01', 'Retention01', 'Expenses01'],
        Community00: ['Awareness01', 'Attendance01', 'Membership01', 'Participation01', 'Engage01', 'Live01', 'Insights01'],
        Conferences00: ['Awareness01', 'Attendance01', 'Satisfy01', 'Engage01', 'Major01', 'Expenses01'],
        Corporate00: ['Awareness01', 'Attendance01', 'Ticket01', 'Participation01', 'Satisfy01', 'Engage01', 'Expenses01'],
        Education00: ['Awareness01', 'Participation01', 'Satisfy01', 'Engage01', 'Live01', 'Retention01'],
        Festivals00: ['Awareness01', 'Attendance01', 'Ticket01', 'Satisfy01', 'Engage01', 'Commerce01', 'Payments01'],
        Government00: ['Awareness01', 'Attendance01', 'Participation01', 'Satisfy01', 'Engage01', 'Live01', 'Expenses01', 'Insights01'],
        Health00: ['Awareness01', 'Membership01', 'Participation01', 'Satisfy01', 'Engage01', 'Sales01', 'Retention01', 'Insights01'],
        Media00: ['Awareness01', 'Membership01', 'Participation01', 'Satisfy01', 'Engage01', 'Live01', 'Sales01', 'Payments01', 'Expenses01'],
        Member00: ['Awareness01', 'Attendance01', 'Ticket01', 'Membership01', 'Participation01', 'Satisfy01', 'Engage01', 'Commerce01', 'Major01', 'Retention01', 'Expenses01'],
        Professional00: ['Awareness01', 'Attendance01', 'Ticket01', 'Membership01', 'Participation01', 'Engage01', 'Live01', 'Commerce01', 'Broadcast01', 'Major01', 'Retention01', 'Insights01'],
        Religion00: ['Awareness01', 'Membership01', 'Participation01', 'Satisfy01', 'Engage01', 'Insights01'],
        Retail00: ['Awareness01', 'Attendance01', 'Membership01', 'Participation01', 'Satisfy01', 'Engage01', 'Commerce01', 'Sales01', 'Insights01'],
        Stadiums00: ['Awareness01', 'Attendance01', 'Ticket01', 'Membership01', 'Satisfy01', 'Engage01', 'Commerce01', 'Major01', 'Retention01', 'Insights01'],
        Telecomms00: ['Awareness01', 'Satisfy01', 'Sales01', 'Payments01', 'Retention01', 'Expenses01', 'Insights01'],
        Ticketing00: ['Awareness01', 'Attendance01', 'Ticket01', 'Satisfy01', 'Engage01', 'Commerce01', 'Major01', 'Insights01'],
        Tourism00: ['Awareness01', 'Attendance01', 'Ticket01', 'Satisfy01', 'Engage01', 'Commerce01', 'Major01', 'Insights01'],
        Gaming00: ['Attendance01', 'Ticket01', 'Membership01', 'Satisfy01', 'Engage01', 'Commerce01', 'Insights01'],
        Travel00: ['Awareness01', 'Satisfy01', 'Engage01', 'Commerce01', 'Expenses01', 'Insights01'],
        Other00: ['Awareness01', 'Attendance01', 'Ticket01', 'Participation01', 'Satisfy01', 'Engage01', 'Commerce01', 'Insights01'],
    },
];

export default industryObjMap;
