import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Grid,
  Box,
} from '@material-ui/core';
import {
  PlaylistAddCheck as MarketingIcon,
  // TrendingUp as AnalyticsIcon,
} from '@material-ui/icons';
import PeopleIcon from '@material-ui/icons/People';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import TouchAppIcon from '@material-ui/icons/TouchApp';

import Widget from '../../../components/Widget/Widget';
import SubscriptionGraph from '../../homepage/charts/barGraph';
import DevicesPie from '../../homepage/charts/pieChart';
import DataInsights from '../../homepage/insights/pillDataInsights';
import { useDashboardStore, useSubscribersStore } from '../../../store/models';
import { parseCountData, getOptinPercentage } from '../../../commons/util/utilities';
import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import DatePicker from '../../../commons/ecal/datepicker';
import {
  formatDate,
} from '../../../commons/util/date';

import useFilterInput from './useFilterInput';
import {
  columns,
  // customCells,
  options,
} from './tableSettings';

import { useStyles } from './styles';

import usePremiumPlan from '../../hooks/usePremiumPlan';
import FeatureNotAvailable from '../featureNotAvailable';
import useWhiteLabel from '../../../store/models/styles/useWhiteLabel';

// const TOOLTIP_TEXT = {
//   monthlyActives: (day) => `This is your estimated monthly active users. Note: If you haven’t sent an event in the last ${day || 30} days, this metric will be understated.`,
//   eventImpression: (day) => `The number of events that have appeared in the calendar over the last ${day || 30} days.`,
//   reminderImpression: (day) => `The number of reminders that have appeared in the calendar over the last ${day || 30} days.`,
//   clicks: (day) => `The number of clicks on your links in the calendar over the last ${day || 30} days.`,
// };

function Engagement () {
  const dashboardStore = useDashboardStore();
  const subscribersStore = useSubscribersStore();
  const { monthlySubscriptions } = subscribersStore;
  const classes = useStyles();
  const [subs, setSubs] = useState([]);
  const [isDateRangeUpdated, setIsDateRangeUpdated] = useState(false);
  const [optinsCount, setOptinsCount] = useState('0');

  const {
    input,
    updateInput,
  } = useFilterInput({});

  const { isPremium } = usePremiumPlan();
  const { whiteLabelSettings } = useWhiteLabel();

  async function loadDashboardData () {
    await dashboardStore.getMonthlyActives();
    await dashboardStore.getEventImpressions();
    await dashboardStore.getReminderImpressions();
    await dashboardStore.getClicksCount();
    await dashboardStore.getAnalyticsClicks();
    await dashboardStore.getAnalyticsDevices();
    await dashboardStore.getAnalyticsCalendarTypes();
    await dashboardStore.getAnalyticsSubscriptions();
    await dashboardStore.getOptinCount();
    await dashboardStore.getTotalSubscribers();
    await subscribersStore.getMonthlySubscribers({ startDate: '2020-01-01', endDate: moment().format('YYYY-MM-DD') });
  }

  useEffect(() => {
    loadDashboardData();
  }, []);

  useEffect(() => {
    setOptinsCount(getOptinPercentage(dashboardStore.totalSubscribers, dashboardStore.optinCount));
  }, [dashboardStore.totalSubscribers, dashboardStore.optinCount]);

  useEffect(() => {
    if (monthlySubscriptions) {
      let obj = {};
      const tallyObject = {};
      monthlySubscriptions.forEach(({ date, count }) => {
        const [year, month] = date.split('-');
        // const year = moment(date).format('YYYY');
        // const month = moment(date).format('MMM').toLowerCase();
        obj[year] = obj[year] || {};
        obj[year][month] = count.toLocaleString() || 0;
        obj[year].total = obj[year].total + count || count;
        tallyObject[month] = tallyObject[month] + count || count;
        tallyObject.total = tallyObject.total + count || count;
      });
      obj = Object.entries(obj);
      let newList = obj.map(data => ({ year: data[0], ...data[1] }));
      Object.keys(tallyObject).forEach(key => {
        tallyObject[key] = tallyObject[key].toLocaleString();
      });
      newList.push({ year: 'Total', ...tallyObject });
      newList = newList.map(annualData => ({ ...annualData, total: annualData.total.toLocaleString() }));
      setSubs(newList);
    }
  }, [monthlySubscriptions]);

  useEffect(() => {
    const to = moment(input.endDate).format('YYYY-MM-DD');
    const from = moment(input.startDate).format('YYYY-MM-DD');
    if (isDateRangeUpdated) {
      dashboardStore.getAnalyticsSubscriptions(to, from);
    }
  }, [input]);

  function renderAnalytics () {
    if (isPremium) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            <Widget
              className={classes.widgetContainer}
              buttonFlag={false}
              title='Subscriber Analytics board'
              inheritHeight
              disableWidgetMenu
            />
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Total Subscriptions'}
                value={parseCountData(dashboardStore.totalSubscribers)}
              >
                <div className={classes.subscriptionCircle}>
                  <PeopleIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Opt-in 1'}
                value={optinsCount}
                isLoading={!dashboardStore.totalSubscribers && !dashboardStore.optinCount}
              >
                <div className={classes.subscriptionCircle}>
                  <MarketingIcon />
                </div>
              </DataInsights>
            </Grid>
          </Grid>
          <Typography
            className={classes.timezoneText}
            size='sm'
            variant='body2'
            component='p'
            weight='light'
          >
            {Intl.DateTimeFormat().resolvedOptions().timeZone} Timezone
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} />
            <Grid item xs={6} container justifyContent="flex-end">
              <DatePicker
                placeHolder='Select date range'
                helperText='Please select date'
                onDateUpdate={(dateData) => {
                  setIsDateRangeUpdated(true);
                  updateInput({
                    ...input,
                    startDate: formatDate(dateData.start),
                    dateStartDate: dateData.start,
                    endDate: formatDate(dateData.end),
                    dateEndDate: dateData.end,
                  });
                }}
                useTwoWeeksAsDefault
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Widget className={classes.widgetContainer} buttonFlag={false} title={'Subscriptions timeline'} noBodyPadding disableWidgetMenu>
              <SubscriptionGraph
                data={dashboardStore.subscriptions}
                isLoading={!dashboardStore.subscriptions || dashboardStore.observed.subscriptionsDataLoading}
              />
            </Widget>
          </Grid>
          <Grid className={`${classes.widgetContainer} ${classes.subsByMonthContainer}`} item xs={12}>
            <TableContainer
              tableData={subs}
              moduleType='subscriptions'
              tableTitle='Subscriptions by month'
              matchWidgetHeadingColor
              options={options}
              columns={columns}
              removeEmptyRows
              sortableColumns={false}
              showProgress={!subs.length || subscribersStore.observed.monthlySubsLoading}
            />
          </Grid>
          <Grid container spacing={4}>
            <Box
              component={Grid}
              item
              sm={6}
              sx={{ display: { xs: 'none', sm: 'block' } }}
              className={classes.boxContainer}
            >
              <Widget buttonFlag={false} title={'Device Type'} noBodyPadding disableWidgetMenu>
                <DevicesPie
                  data={dashboardStore.deviceTypes?.deviceTypeCounts}
                  isLoading={!dashboardStore.deviceTypes?.deviceTypeCounts}
                />
              </Widget>
            </Box>
            <Box
              component={Grid}
              item
              sm={6}
              sx={{ display: { xs: 'none', sm: 'block' } }}
              className={classes.boxContainer}
            >
              <Widget buttonFlag={false} title={'Calendar Type'} noBodyPadding disableWidgetMenu>
                <DevicesPie
                  type="calendar"
                  data={dashboardStore.calendarTypes?.calendarTypeCounts}
                  isLoading={!dashboardStore.calendarTypes?.calendarTypeCounts}
                />
              </Widget>
            </Box>
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <FeatureNotAvailable
          whiteLabelSettings={whiteLabelSettings}
      />
    );
  }
  return renderAnalytics();
}

export default observer(Engagement);
