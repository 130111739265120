import React from 'react';
import SwitchCell from './cells/switchCell';
import FieldCell from './cells/fieldCell';
import RequiredCell from './cells/requiredCell';
import DefaultValueCell from './cells/defaultValueCell';

/* Custom Cells */
export function visibleCell ({ index, row, column, props }) {
    return (
        <SwitchCell
            index={index}
            row={row}
            column={column}
            tableProps={props}
        />
    );
}

export function fieldCell ({ index, row, column, props }) {
    return (
        <FieldCell
            index={index}
            row={row}
            column={column}
            tableProps={props}
        />
    );
}

export function requiredCell ({ index, row, column, props }) {
    return (
        <RequiredCell
            index={index}
            row={row}
            column={column}
            tableProps={props}
        />
    );
}

export function defaultValueCell ({ index, row, column, props }) {
    return (
        <DefaultValueCell
            index={index}
            row={row}
            column={column}
            tableProps={props}
        />
    );
}
