import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  FileCopy as CopyIcon,
} from '@material-ui/icons';

import {
  Button,
  Link,
  Typography,
} from '../../../components/Wrappers';

import { useStyles } from './styles';
import {
  navigateBlank,
} from './utils';

function ButtonCodeApp (props) {
  const {
    socialMediaLink,
  } = props;

  const classes = useStyles();

  return (
    <Grid className={classNames([classes.buttonCodeContent, classes.topSpace, classes.minHeight400])}>
      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
        ])}>
        <Typography
          weight="medium"
          component="h2"
        >
          Direct link
        </Typography>
      </Grid>
      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
          classes.buttonCodeSocial,
        ])}
        data-intercom-target="app-direct-link"
        >

        <Grid className={classes.iconSpace}>
          <Tooltip title="Copy link to clipboard.">
            <IconButton
              color={'primary'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigator.clipboard.writeText(socialMediaLink);
              }}
              className={classes.buttonCodeSocialIcon}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Button
          onClick={(e) => {
            e.preventDefault();
            navigateBlank(socialMediaLink);
          }}
          variant={'link'}
          color={'primary'}
        >
          <Link className={classes.qrCodeLink} > {socialMediaLink} </Link>
        </Button>
      </Grid>

      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
        ])}>
        <Typography
          weight="medium"
          component="h2"
        >
          Open this direct link in the mobile browser
        </Typography>
        <Typography
          variant={'sub2'}
        >
          Refer to the instructions <a href="https://docs.ecal.com/basics/button/app.html#configure-direct-link" target="_blank" rel="noopener noreferrer">here</a> for customising the direct link before using.
        </Typography>
      </Grid>

      <Grid
        className={classNames([
          classes.dialogSection,
          classes.topSpace,
          classes.bottomSpace,
          classes.leftAligned,
        ])}>
        <Typography
          component="h2"
          weight="medium"
        >
          Open this direct link inside the app
        </Typography>
        <Typography
          variant={'sub2'}
        >
          Refer to the instructions <a href="https://docs.ecal.com/basics/button/app.html#configure-direct-link" target="_blank" rel="noopener noreferrer">here</a> for iOS and Android apps.
        </Typography>
      </Grid>

    </Grid>
  );
}

ButtonCodeApp.propTypes = {
  socialMediaLink: PropTypes.string,
  widgetId: PropTypes.string,
};

ButtonCodeApp.defaultProps = {
  socialMediaLink: '',
  widgetId: '',
};

export default ButtonCodeApp;
