import { v4 as uuid } from 'uuid';
import {
    // getEnvInfo,
    Roles,
} from '../../../utils';
import { SignInSignUpState } from '../../../utils/constants';
import { isEmpty } from 'lodash';
import AWSConfig from '../../../conf/aws-exports';

function processScope (payload) {
    if (!payload['custom:scope']) {
        return null;
    }
    const scope = JSON.parse(payload['custom:scope']);
    console.log('Scope: ', scope);
    return scope;
}

export function userIsPublisher () {
    console.log('userIsPublisher?');
    console.log(' user: ', this.user);
    console.log(' publisher: ', this.publisher);
    if (this.user.email === this.publisher?.email) {
        return true;
    }
    return false;
}

function isFederatedIdentity (payload) {
    const providers = [
        'google',
    ];
    const username = payload['cognito:username'];
    const p = providers.filter(value => username.indexOf(value) !== -1);
    return p.length > 0;
}

// managed the payload and setup the user object

export function processAuthUser (authUser, setupForPlan = false) {
    this.env.clearStoredInfo();

    if (authUser.idToken && authUser.idToken.jwtToken) {
        const token = authUser.idToken.jwtToken;
        const payload = authUser.idToken.payload;
        this.manageCognitoPayload(payload);

        /*
        SOON TO BE REMOVED -> User and AWS Processing is in manageCognitoPayload
        const user = {
            email: payload.email,
            firstName: payload.given_name,
            lastName: payload.family_name,
            phone: payload.phone_number,
            timezone: payload.zoneinfo,
            company: payload['custom:company'],
            orgName: payload['custom:company'],
            name: payload['custom:company'],
            industry: payload['custom:industry'],
            objective: payload['custom:objective'],
            scope: payload['custom:scope'],
        };

        console.log('');
        console.log('');
        console.log('about to store user: ', user);
        console.log('Environment: ', this.env);
        */

        /* SOON TO BE REMOVED
        if (!user.email) {
            user.email = this.userEmail;
        }

        user.isFederated = isFederatedIdentity(payload);
        this.user = { ...user };
        this.observed.user = uuid();

        const aws = {
            cognitoUsername: payload['cognito:username'],
            cognitoUserPoolId: AWSConfig.aws_user_pools_id,
            isFederated: user.isFederated,
        };
        console.log('updating AWS: ', aws);
        this.aws = aws;
        this.env.storeUserInfo({
            user: user,
            aws,
        });
        this.observed.aws = uuid();
        */

        this.authGetPublisherInfo({ cognitoToken: token, setupForPlan });
    }
}

/*
 * Mangage Cognito Payload Retrieve Cognito User Info and AWS Informaiton
 */
export function manageCognitoPayload (payload) {
    console.log('');
    console.log('');
    console.log('UserStore.manageCognitoPayload() ', payload);
    this.userEmail = payload.email;
    this.cognitoPayload = payload;

    if (payload) {
        if (payload['custom:configured'] === '1') {
            this.configured = true;
        } else {
            this.configured = false;
        }
    }

    const scope = processScope(payload);
    if (!scope) {
        return this.env.errorStore.handleAuthError({
            error: { code: 'MissingCustomScope' },
            action: 'payload',
            source: 'cognito',
        });
    }

    const user = this.userFromPayload(payload);

    if (!user.email) {
        user.email = this.userEmail;
    }

    this.user = user;
    this.manageUserRole(user);

    const aws = {
        cognitoUsername: payload['cognito:username'],
        cognitoUserPoolId: AWSConfig.aws_user_pools_id,
        isFederated: user.isFederated,
    };

    this.aws = aws;
    console.log('AWS Swettings: ', aws);
    console.log('updating AWS', aws);

    // store user info session
    this.env.storeUserInfo({
        aws,
        user,
    });

    this.observed.user = uuid();
    this.observed.aws = uuid();
}

export function userFromPayload (payload) {
    const scope = processScope(payload);

    const user = {
        email: payload.email,
        firstName: payload.given_name,
        lastName: payload.family_name,
        phone: payload.phone_number,
        timezone: payload.zoneinfo,
        company: payload['custom:company'],
        orgName: payload['custom:company'],
        name: payload['custom:company'],
        industry: payload['custom:industry'],
        objective: payload['custom:objective'],
        role: scope?.d[0].r,
        scope: scope,
        isFederated: isFederatedIdentity(payload),
    };

    return user;
}

export function manageUserRole (authUser) {
    if (authUser.role === Roles.SUPER_AMDIN) {
        this.superAdmin = authUser;
        this.isSuperAdmin = true;
        this.groupAdmin = false;
        this.configured = true;
    }

    if (authUser.role === Roles.GROUP_ADMIN) {
        this.isSuperAdmin = true;
        this.groupAdmin = true;
        this.configured = true;
    }

    if (authUser.role === Roles.PUBLISHER_ADMIN) {
        this.isSuperAdmin = false;
        this.groupAdmin = false;
        // this.configured = true;
    }

    this.observed.isSuperAdmin = uuid();
}

/*
 * Use these actions to manage user logins and publisher selections
 * Remove env.user on and env.publisher on on envStore.
 * UserStore (model) has only one source of publisher / user.
 */

export function pushPublisherInfo (publisher) {
    this.publisher = {
        ...publisher,
    };

    if (!this.publisher.company) {
        this.publisher.company = this.publisher.orgName;
    }

    this.observed.publisher = uuid();
    this.env.persistPublisher(this.publisher);
}

export function managePublisher (publisher) {
    // use is always the publisher...
    if (!this.isSuperAdmin) {
        // only update publisher info from here if non super admin access
        this.pushPublisherInfo(publisher);
    }

    /*
     * Note: As future reference. Only Specific to this section.
     * Never Update publisher even SuperAdmin.email = publisher.email it will produce Side effects.
     */
}

export function selectPublisher (publisher, goToSummary = true) {
    const selectedPublisher = {
        /* Deconstruct it instead */
        email: publisher.email,
        firstName: publisher.firstName,
        lastName: publisher.lastName,
        name: publisher.name,
        publisherId: publisher.publisherId,
        subscriberLimit: publisher.subscriberLimit,
        subscriberRemaining: publisher.subscriberRemaining,
        subscriptionPlanCurrency: publisher.subscriptionPlanCurrency,
        role: publisher.role,
        status: publisher.status,
        token: publisher.token,
        industry: publisher.industry,
        objective: publisher.objective,
        planName: publisher.planName,
        timezone: publisher.timezone,

        company: publisher.name,
        dateExpiry: publisher.dateExpiry,
        dateCreated: publisher.dateCreated,
        intercomUserHash: publisher.intercomUserHash,
    };

    this.env.persistPublisher(selectedPublisher);
    this.publisher = selectedPublisher; /* non observable publisher used in api calls */
    this.observed.publisher = uuid(); /* attached to observables used in rendering */
    if (goToSummary) {
        window.location = '/admin/home/summary';
    }
}

export async function navigateInitPageByUser (authUser, setupForPlan = false) {
    // verify here if billing is enable and it is coming from plan

    const path = this.env.history?.location?.pathname;

    if (setupForPlan) {
        this.env.history.push('/setup-plan');
        return;
    }

    if (path.indexOf('/setup-plan') !== -1) {
        return;
    }

    if (this.env.signInSignUpState.status === SignInSignUpState.IN_SESSION) {
        const path = this.env.history?.location?.pathname;

        if (path === '/home/' || path === '/home') {
            if (authUser.role === Roles.SUPER_AMDIN || authUser.role === Roles.GROUP_ADMIN) {
                this.env.history.push('/publishers');
                return;
            }
            this.env.history.push('/admin/home/summary');
        }
        return;
    }

    if (this.configured === false) {
        console.log('navigateInitPageByUser() : ', authUser);
        await this.insertLead({
            firstName: authUser.firstName,
            lastName: authUser.lastName,
            email: authUser.email,
        });
        this.env.history.push('/setup');
        return;
    }

    if (this.env.signInSignUpState.status === SignInSignUpState.FEDERATED_SIGNIN_SETUP_COMPLETE) {
        this.env.history.push('/admin/home/summary');
        return;
    }

    if (authUser.role !== Roles.SUPER_AMDIN && authUser.role !== Roles.GROUP_ADMIN) {
        this.env.history.push('/admin/home/summary');
        return;
    }

    // redirect to publishers when Role === Role.SUPER_ADMIN or Role.GROUP_ADMIN...

    this.env.history.push('/publishers');
}

export async function updateUserProfile ({ user }) {
    await this.updateUserAttrForProfile({ userInfo: user });
    if (this.userIsPublisher()) {
        const publisher = this.publisher || {};
        this.user.token = publisher.token;

        this.updateProfile({
            user: {
                ...user,
                publisherId: publisher.publisherId,
                company: publisher.orgName,
                industry: publisher.industry,
                objective: publisher.objective,
                timezone: publisher.timezone,
            },
            orgSettings: false,
        }, false);
    }
}

export async function processAWSMarketplaceBilling () {
    const billingResData = await this.env.billingStore.createSubscriptionAWSMarketplace();
    if (billingResData) {
        if (!isEmpty(billingResData.createSubscription.error)) {
            // throw an error here...
            this.env.errorStore.dispatchAWSMarketplaceBillingError(billingResData.createSubscription.error);
        }
    }
}
