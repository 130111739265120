import {
    // post,
    postGQL,
    endpoints,
} from '../../api';

import {
    querySearchPublishers,
    queryPublishersUpdate,
    queryUpdateEmail,
    queryUpdatePlan,
    queryGetPlans,
    queryCreateUser,
    queryGetPublisherUsers,
    queryUpdateUsers,
    queryDeleteUsers,
    queryGetPublisherById,
    queryGetCurrentPlan,
} from './queries';

export function apiSuperAdminSearch ({ query, filter, token, traceId, page }) {
    window.envStore.errorStore.gqlAction = 'superAdminSearch';
    const qInput = {
        input: {
            query,
            filter,
            page,
        },
    };

    return postGQL(
        endpoints().searchPublishers,
        querySearchPublishers({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiSuperAdminUpdatePublishers ({ publishers, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'superAdminPublishersUpdate';
    const qInput = {
        input: {
            publishers,
        },
    };

    return postGQL(
        endpoints().searchPublishers,
        queryPublishersUpdate({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiSuperAdminUpdateEmail ({ email, newEmail, token, cognitoPoolId, orgId, id, traceId }) {
    window.envStore.errorStore.gqlAction = 'superAdminUpdateEmail';
    const qInput = {
        input: {
            id,
            orgId,
            email,
            newEmail,
            cognitoPoolId,
        },
    };

    return postGQL(
        endpoints().searchPublishers,
        queryUpdateEmail({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

function append (o, p, v) {
    if (v) {
        o[p] = v;
    }
}

export function apiSuperAdminUpdatePlan (params) {
    const {
        id,
        planId,
        planName = '',
        subscriberLimit = 0,
        calendarLimit = 0,
        newPlanId = 0,
        newPlanName = '',
        token,
        traceId,
        provider,
    } = params;

    const input = {
        id,
        planId,
    };

    append(input, 'planName', planName);
    append(input, 'newPlanId', newPlanId);
    append(input, 'newPlanName', newPlanName);
    append(input, 'subscriberLimit', subscriberLimit);
    append(input, 'calendarLimit', calendarLimit);
    append(input, 'provider', provider);

    window.envStore.errorStore.gqlAction = 'superAdminUpdatePlan';
    const qInput = {
        input,
    };

    return postGQL(
        endpoints().searchPublishers,
        queryUpdatePlan({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiSuperAdminGetPublisherById (params) {
    const {
        id,
        token,
        traceId,
    } = params;

    const input = {
        id,
    };

    window.envStore.errorStore.gqlAction = 'superAdminUpdatePlan';
    const query = {
        input,
    };

    return postGQL(
        endpoints().searchPublishers,
        queryGetPublisherById({ ...query }),
        {
            token,
            traceId,
        }
    );
}

export function apiGetPlans ({ token, traceId }) {
    window.envStore.errorStore.gqlAction = 'superAdminGetPlans';
    const qInput = {
        input: {},
    };

    return postGQL(
        endpoints().plansGQL,
        queryGetPlans({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiCreateUser ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'createPublisherUser';
    const qInput = {
        input: { ...input },
    };

    return postGQL(
        endpoints().publisherUser,
        queryCreateUser({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiGetPublisherUsers ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'getPublisherUsers';
    const qInput = {
        input: { ...input },
    };

    return postGQL(
        endpoints().publisherUser,
        queryGetPublisherUsers({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiUpdateUsers ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'updatePublisherUsers';
    const qInput = {
        input: { ...input },
    };

    return postGQL(
        endpoints().publisherUser,
        queryUpdateUsers({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiDeleteUsers ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'deletePublisherUsers';
    const qInput = {
        input: { ...input },
    };

    return postGQL(
        endpoints().publisherUser,
        queryDeleteUsers({ ...qInput }),
        {
            token,
            traceId,
        }
    );
}

export function apiGetCurrentPlan ({ data, token, traceId }) {
    return postGQL(
        endpoints().billing,
        queryGetCurrentPlan(data),
        {
            token,
            traceId,
        }
    );
}
