import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    Popper,
    ClickAwayListener,
    Button,
    Typography,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import { useStyles } from './styles';
import { logInfo } from '../../../utils';

function PopFilter (props) {
    const {
        open,
        anchorEl,
        setFiltersOpen,
        updateFilters,
        filters,
        onApplyFilters,
    } = props;

    const classes = useStyles();

    useEffect(() => {
        logInfo('Filters.useEffect()');
    }, []);

    useEffect(() => {
        logInfo('Filters.useEffect(open) updated -> ', open);
    }, [open]);

    function handleChange (props) {
        const {
            name,
            value,
        } = props;

        const data = filters;
        data[name] = value;
        updateFilters(data);
    }

    function renderOptions () {
        return (
            <Grid className={classes.fields}>
                <Grid className={classes.filterOptions}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid
                        container
                        style={{
                            justifyContent: 'space-between',
                            marginBottom: 47,
                        }}>

                        <KeyboardDatePicker
                            // autoOk
                            margin='normal'
                            id='date-picker-dialog'
                            label='Start date'
                            /* format="MMM d, yyyy" */
                            format='dd / MM / yy'
                            value={filters.startDate}
                            onChange={(date) => {
                                handleChange({
                                    name: 'startDate',
                                    value: date,
                                });
                            }}

                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                            inputProps={{
                                readOnly: false,
                                'data-intercom-target': 'event-start-date',
                            }}

                        />

                        <KeyboardDatePicker
                            // autoOk
                            margin='normal'
                            id='date-picker-dialog'
                            label='End date'
                            /* format="MMM d, yyyy" */
                            format='dd / MM / yy'
                            value={filters.endDate}
                            onChange={(date) => {
                                handleChange({
                                    name: 'endDate',
                                    value: date,
                                });
                            }}

                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                            inputProps={{
                                readOnly: false,
                                'data-intercom-target': 'event-end-date',
                            }}

                        />
                    </Grid>

                </MuiPickersUtilsProvider>
                </Grid>

            </Grid>
        );
    }

    function renderMenu () {
        if (!open) {
            return (<React.Fragment> </React.Fragment>);
        }

        return (
            <ClickAwayListener
            onClickAway={() => {
                console.log('ClickAwayListener() clicked... state is?', open);
                if (open) {
                    setFiltersOpen(false);
                }
            }}
        >
            <Popper
               open={open}
               anchorEl={anchorEl}
               placement={'bottom-end'}
            >
                <Grid className={classes.root}>

                    <Grid className={classes.filterContent}>

                        <Grid
                            className={classNames([classes.filterRow])}>
                                <Typography variant='body1'>
                                    Date Filters
                                </Typography>
                        </Grid>

                        <Grid className={classes.parentScrollable}>
                            <Grid className={classes.contentScrollable}>
                               { renderOptions() }

                            </Grid>

                        </Grid>

                        <Grid
                                className={classNames([classes.filterRow, classes.filterButton])}>
                                    <Button
                                        variant='contained'
                                        onClick={() => onApplyFilters()}
                                    >
                                    Apply Date
                                    </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Popper>
        </ClickAwayListener>
        );
    }

    return (<React.Fragment>
        { renderMenu() }
    </React.Fragment>);
}

PopFilter.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.any,
    data: PropTypes.array,
    setFiltersOpen: PropTypes.func,
    updateFilters: PropTypes.func,
    filters: PropTypes.any,
    onApplyFilters: PropTypes.any,
};

PopFilter.defaultProps = {
    open: false,
    anchorEl: null,
    data: [],
    setFiltersOpen: () => {},
    updateFilters: () => {},
    filters: {},
    onApplyFilters: () => {},
};

export default PopFilter;
