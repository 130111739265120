import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import PlanInfo from './planInfo';
import AddOns from '../billing/billingAddOns';
import { useStyles } from './styles';

function BillingForm (props) {
    const {
        billingInputProps,
    } = props;

    const {
        input,
        updateInput,
        planDetail,
        plans,
        selectPlan,
        addOns,
        updateAddOnsValue,
        addOnsValues,
    } = billingInputProps;

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid
                container
                className={classes.planFormContainer}
                >

                <PlanInfo
                    input={input}
                    planDetail={planDetail}
                    plans={plans}
                    selectPlan={selectPlan}
                    billingInputProps={billingInputProps}
                />

                <AddOns
                    addOns={addOns}
                    input={input}
                    updateInput={updateInput}
                    updateAddOnsValue={updateAddOnsValue}
                    addOnsValues={addOnsValues}
                    billingInputProps={billingInputProps}
                />

            </Grid>
        </React.Fragment>
    );
}

BillingForm.propTypes = {
    billingInputProps: PropTypes.object,
};

BillingForm.defaultProps = {
    billingInputProps: {},
};

export default BillingForm;
