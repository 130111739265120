
export function monthName (i) {
    const names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (i > 11) {
        return names[0];
    }
    return names[i];
}

export function formatDay (day) {
    if (day < 10) {
        return '0' + day;
    }
    return day;
}

export function formatDate (d) {
    return '' + (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
}

export function readable (d) {
    return '' + formatDay(d.getDate()) + ' ' + monthName(d.getMonth() + 1) + ' ' + d.getFullYear();
}

export function legacyFormat (d) {
    const m = d.getMonth() + 1;
    const month = m < 10 ? '0' + m : m;
    return d.getFullYear() + '-' + month + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
};

export function formattedDateNow () {
    return formatDate(new Date());
}
