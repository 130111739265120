import {
    // get,
    postGQL,
    // put,
    // post,
    // deleteCall,
    endpoints,
} from '../../api';

import {
    schedulesQuery,
    queryGetRootCategory,
    queryGetCategoriesByFilter,
    queryAddCategory,
    queryDeleteCategory,
    queryAddSchedule,
    querySearchSchedules,

    queryEditSchedule,
    queryBulkDeleteSchedules,
    queryBulkEditSchedules,

 } from './queries';

export function apiGetSchedules ({ draft, page, pageLength, token, traceId, sort }) {
    const input = {};

    if (draft !== undefined) {
        input.draft = draft;
    }

    let sortOpt = {
        field: 'modified',
        order: 'DESC',
    };

    if (sort) {
        sortOpt = sort;
    }

    return postGQL(
        endpoints().getSchedules,

        schedulesQuery({
            input,
            sort: sortOpt,

            pageInput: {
                pageNumber: page,
                pageLength: pageLength,
            },
        }),
        {
            token,
            traceId,
        }
    );
}

export function apiSearchSchedules ({ name, categoryName, draftStatus, page, pageLength, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        querySearchSchedules({

            searchInput: {
                name,
                categoryName,
                status: draftStatus,
            },

            sortInput: {
                field: 'name',
                order: 'ASC',
            },

            pageInput: {
                pageNumber: page,
                pageLength: pageLength,
            },
        }),
        {
            token,
            traceId,
            returnOnError: true,
        }
    );
}

export function apiGetRootCategories ({ token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryGetRootCategory({}),
        {
            token,
            traceId,
        }
    );
}

export function apiGetCategoriesByFilter ({ filter, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryGetCategoriesByFilter({ filter: { ...filter } }),
        {
            token,
            traceId,
        }
    );
}

export function apiAddCategory ({ input, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryAddCategory({ input: { ...input } }),
        {
            token,
            traceId,
        }
    );
}

export function apiDeleteCategory ({ input, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryDeleteCategory({ input: { ...input } }),
        {
            token,
            traceId,
        }
    );
}

export function apiAddSchedule ({ input, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryAddSchedule({ input: { ...input } }),
        {
            token,
            traceId,
        }
    );
}

export function apiEditSchedule ({ input, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryEditSchedule({ input: { ...input } }),
        {
            token,
            traceId,
        }
    );
}

export function apiBulkEditSchedules ({ schedules, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryBulkEditSchedules({ schedules }),
        {
            token,
            traceId,
        }
    );
}

export function apiBulkDeleteSchedules ({ schedules, token, traceId }) {
    return postGQL(
        endpoints().getSchedules,
        queryBulkDeleteSchedules({ schedules }),
        {
            token,
            traceId,
        }
    );
}

export function apiGetSchedulesByIds ({ ids, token, traceId }) {
    const input = {
        ids,
    };

    const sortOpt = {
        field: 'modified',
        order: 'DESC',
    };

    return postGQL(
        endpoints().getSchedules,

        schedulesQuery({
            input,
            sort: sortOpt,

            pageInput: {
                pageNumber: 0,
                pageLength: ids.length,
            },
        }),
        {
            token,
            traceId,
        }
    );
}
