import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import ecal from './clients/defaultEcal';
import {
    getWhiteLabelSettings,
    getWhiteLabelStaticSettings,
    getStoredWhiteLabelSettings,
    saveWhiteLabelSettings,
    getWhiteLabelSettingsByPublisher,
} from './actions';

export default class StylesStore {
    env = null;
    initialized = false;
    version = {}
    observed = {};
    whiteLabelSettings = { ...ecal };
    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    getWhiteLabelSettings = getWhiteLabelSettings;
    getWhiteLabelStaticSettings = getWhiteLabelStaticSettings;
    getStoredWhiteLabelSettings = getStoredWhiteLabelSettings;
    saveWhiteLabelSettings = saveWhiteLabelSettings;
    getWhiteLabelSettingsByPublisher = getWhiteLabelSettingsByPublisher;
}

export function useStylesStore () {
    const { stylesStore } = useStores();
    return stylesStore;
}
