import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';
import Select from '../../../commons/select/Select';
import Timezones from '../../../commons/util/Timezone.json';

// import Widget from '../../../components/Widget';
import { useStyles } from '../profile.styles';
import useOrgProfileHook from './useOrgProfileHook';
import ButtonProgress from '../../../commons/ButtonProgress';
import ImageUploader from '../../../commons/imageUploader/ImageUploader';
import useAuthError from '../../../store/models/user/errors/useAuthError';
function OrgSettings (props) {
    const {
        publisher,
        onSave,
        inProgress,
        publisherLogo,
        publisherLogoError,
        uploadPublisherLogo,
        deletePublisherLogo,
    } = props;

    const {
        data,
        setData,
        industries,
        objectives,
        saveDisabled,
        setSaveDisabled,
    } = useOrgProfileHook({ publisher });

    const [progress, setProgress] = useState(inProgress);
    const classes = useStyles();

    const {
        renderErrorDialog,
        errors,
    } = useAuthError({
        shouldRedirect: false,
    });

    useEffect(() => {
        setSaveDisabled(true);
        setProgress(false);
    }, [errors]);

    useEffect(() => {
        setProgress(inProgress);
    }, [inProgress]);

    function handleChange (e, selectedVal) {
        console.log('Data: ', data);
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }

    const renderError = (error) => (
      <Typography
          variant={'caption'}
          weight={'medium'}
          style={{ marginBottom: 30, color: 'red' }}
      >
          {error}
      </Typography>
    );

    return (
        <React.Fragment>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Details
                            </Typography>
                                <TextField
                                    id="company"
                                    label="Organisation Name"
                                    onChange={handleChange}
                                    name="company"
                                    value={data.company}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText="Please enter your organisation name"
                                />

                                <Select
                                    value={data.industry}
                                    label='industry'
                                    name='industry'
                                    id='profile-industry'
                                    labelHeading='Industry'
                                    placeHolder='Please select industry'
                                    handleChange={handleChange}
                                    dataForDropdown={industries}
                                />
                                <Select
                                    value={data.objective}
                                    label='objective'
                                    name='objective'
                                    placeHolder='Please select objective'
                                    id='profile-objective'
                                    labelHeading='Objective'
                                    handleChange={handleChange}
                                    dataForDropdown={objectives}
                                />
                                <Select
                                    value={data.timezone}
                                    label='timezone'
                                    name='timezone'
                                    id='profile-timezone'
                                    labelHeading='Timezone'
                                    placeHolder='Please select timezone'
                                    handleChange={handleChange}
                                    dataForDropdown={Timezones}
                                />

                                <Box
                                    display={'flex'}
                                    // height={150}
                                    marginBottom={'35px'}
                                    flexDirection={'column'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                    border={'1px solid lightgrey'}
                                    padding={'20px'}
                                    // display={'flex'}
                                    // justifyContent={'space-between'}
                                    borderRadius={'4px'}
                                >
                                    <>
                                        {/* <img
                        src={ScheduleImage}
                        alt={'ScheduleImage'}
                        style={{ width: 150, marginRight: 8 }}
                        /> */}

                                        <Grid item justify={'center'} alignItems={'center'} container>

                                            <Grid container item xs={4}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.uploadSection}
                                                >

                                                    <Typography
                                                        variant={'body1'}
                                                        // weight={'300'}
                                                        style={{ marginBottom: 10 }}
                                                    >
                                                        Add Logo
                                                    </Typography>
                                                    <ImageUploader
                                                        hasCreate={true}
                                                        hasDelete={true}
                                                        imageData={publisherLogo}
                                                        createLabel={'Upload'}
                                                        imageWidth='170px'
                                                        imageHeight='140px'
                                                        onImageSelected={(images) => {
                                                            uploadPublisherLogo(images);
                                                        }}

                                                        onImageDelete={() => {
                                                            deletePublisherLogo();
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid item xs={8}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginBottom: 30,
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                            <Grid item xs={12}>
                                                <Typography
                                                variant={'body2'}
                                                weight={'light'}
                                                style={{ marginBottom: 30, marginTop: 70 }}
                                                >
                                                Browse or drag and drop an image file to use as your publisher logo.
                                                Upload a square image, (.png, .jpg, .gif), aspect ratio 1:1.
                                                </Typography>
                                            </Grid>
                                            {(publisherLogoError && renderError(publisherLogoError))}
                                            </Grid>
                                        </Grid>

                                    </>
                                </Box>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    {/* <Button
                                        // onClick={handleBack}
                                        variant={'contained'}
                                        color={'secondary'}
                                    >
                                        Cancel
                                </Button> */}

                                    <ButtonProgress
                                        disabled={saveDisabled}
                                        showProgress={progress}
                                        label="Save"
                                        onClick={(e) => {
                                            onSave(data);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            { renderErrorDialog() }
        </React.Fragment>
    );
}

OrgSettings.propTypes = {
    publisher: PropTypes.object,
    onSave: PropTypes.func,
    inProgress: PropTypes.bool,
    uploadPublisherLogo: PropTypes.func,
    deletePublisherLogo: PropTypes.func,
    publisherLogo: PropTypes.any,
};

OrgSettings.defaultProps = {
    publisher: {
        industry: '',
        company: '',
        objective: '',
    },
    onSave: () => { },
    uploadPublisherLogo: () => {},
    deletePublisherLogo: () => {},
    inProgress: false,

};

export default observer(OrgSettings);
