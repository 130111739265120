import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    Popper,
    ClickAwayListener,
    Button,
    Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
import Control, { ControlType } from './control';
import { logInfo } from '../../../utils';

function PopFilter (props) {
    const {
        open,
        anchorEl,
        setFiltersOpen,
        updateFilters,
        filters,
        onApplyFilters,
    } = props;

    const classes = useStyles();

    useEffect(() => {
        logInfo('Filters.useEffect()');
    }, []);

    useEffect(() => {
        logInfo('Filters.useEffect(open) updated -> ', open);
    }, [open]);

    function handleChange (props) {
        const {
            filter,
            name,
            value,
        } = props;

        const data = filters[filter] ? filters[filter] : {};
        data[name] = value;
        updateFilters(filter, { ...data });
    }

    function renderOptions () {
        return (
            <Grid className={classes.fields}>
                <Grid className={classes.filterOptions}>
                    <Typography
                        variant='sub'
                        className={classes.filterTitle}
                    >
                            Status
                    </Typography>

                    <Control
                        type={ControlType.CHECK}
                        label='Active'
                        name='status'
                        value={filters?.status?.active}
                        onChange={(value) => {
                            handleChange({
                                filter: 'status',
                                name: 'active',
                                value: !filters?.status?.active,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Inactive'
                        name='status'
                        value={filters?.status?.inactive}
                        onChange={(value) => {
                            handleChange({
                                filter: 'status',
                                name: 'inactive',
                                value: !filters?.status?.inactive,
                            });
                        }}
                    />

                </Grid>

                <Grid className={classes.filterOptions}>
                    <Typography
                        variant='sub'
                        className={classes.filterTitle}
                    >
                            Plan Type
                    </Typography>

                    <Control
                        type={ControlType.CHECK}
                        label='Enterprise Partners'
                        name='plan'
                        value={filters?.plan?.enterpriseCustom}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'enterpriseCustom',
                                value: !filters?.plan?.enterpriseCustom,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Enterprise'
                        name='plan'
                        value={filters?.plan?.enterprise}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'enterprise',
                                value: !filters?.plan?.enterprise,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Pro'
                        name='plan'
                        value={filters?.plan?.pro}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'pro',
                                value: !filters?.plan?.pro,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Grow'
                        name='plan'
                        value={filters?.plan?.grow}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'grow',
                                value: !filters?.plan?.grow,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Community'
                        name='plan'
                        value={filters?.plan?.community}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'community',
                                value: !filters?.plan?.community,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Free'
                        name='plan'
                        value={filters?.plan?.free}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'free',
                                value: !filters?.plan?.free,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Free Trial'
                        name='plan'
                        value={filters?.plan?.freeTrial}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'freeTrial',
                                value: !filters?.plan?.freeTrial,
                            });
                        }}
                    />

                    <Control
                        type={ControlType.CHECK}
                        label='Others'
                        name='plan'
                        value={filters?.plan?.others}
                        onChange={(value) => {
                            handleChange({
                                filter: 'plan',
                                name: 'others',
                                value: !filters?.plan?.others,
                            });
                        }}
                    />

                </Grid>

            </Grid>
        );
    }

    function renderMenu () {
        if (!open) {
            return (<React.Fragment> </React.Fragment>);
        }

        return (
            <ClickAwayListener
            onClickAway={() => {
                console.log('ClickAwayListener() clicked... state is?', open);
                if (open) {
                    setFiltersOpen(false);
                }
            }}
        >
            <Popper
               open={open}
               anchorEl={anchorEl}
               placement={'bottom-end'}
            >
                <Grid className={classes.root}>

                    <Grid className={classes.filterContent}>

                        <Grid
                            className={classNames([classes.filterRow])}>
                                <Typography variant='body1'>
                                    Filters
                                </Typography>
                        </Grid>

                        <Grid className={classes.parentScrollable}>
                            <Grid className={classes.contentScrollable}>
                               { renderOptions() }
                            </Grid>

                        </Grid>

                        <Grid
                                className={classNames([classes.filterRow, classes.filterButton])}>
                                    <Button
                                        variant='contained'
                                        onClick={() => onApplyFilters()}
                                    >
                                    Apply Filter
                                    </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Popper>
        </ClickAwayListener>
        );
    }

    return (<React.Fragment>
        { renderMenu() }
    </React.Fragment>);
}

PopFilter.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.any,
    data: PropTypes.array,
    setFiltersOpen: PropTypes.func,
    updateFilters: PropTypes.func,
    filters: PropTypes.any,
    onApplyFilters: PropTypes.any,
};

PopFilter.defaultProps = {
    open: false,
    anchorEl: null,
    data: [],
    setFiltersOpen: () => {},
    updateFilters: () => {},
    filters: {},
    onApplyFilters: () => {},
};

export default PopFilter;
