import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
    Grid,
    // Tab,
    // Box,
} from '@material-ui/core';
import { logInfo } from '../../../utils';
import TableContainer from '../../../commons/ECALTable/TableContainer';

import { useStyles } from './styles';
import {
    columnsFromHeaders,
    standardTableColumns,
    customCells,
    options,
} from './tableSettings';

function getIssueInfo (source) {
    const keys = Object.keys(source);
    const rows = keys.reduce((prev, current, index, arr) => {
        if (index === 0) {
            return '' + (Number(current) + 1) + '';
        } else if (index === arr.length - 1) {
            // last entry
            return prev + ' and ' + (Number(current) + 1);
        }
        return prev + ', ' + (Number(current) + 1);
    }, '');
    return {
        rows,
        length: keys.length,
    };
}

function getWarningMessage (source) {
    const {
        length,
        rows,
    } = getIssueInfo(source);
    if (length === 0) {
        return '';
    } else if (length === 1) {
        return 'Warning found at row ' + rows + '.';
    }
    return 'Warnings found at rows ' + rows + '.';
}

function getErrorMessage (source) {
    const {
        length,
        rows,
    } = getIssueInfo(source);
    if (length === 0) {
        return '';
    } else if (length === 1) {
        return 'Error found at row ' + rows + '.';
    }
    return 'Errors found at rows ' + rows + '.';
}
function ImportResults (props) {
    const {
        eventsData,
        errors,
        warnings,
    } = props;

    function getColumns () {
        if (Array.isArray(eventsData.headers)) {
            columnsFromHeaders(eventsData.headers);
        }
        return standardTableColumns();
    }

    const [columns, setColumns] = useState(getColumns());
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarrningMessage] = useState('');

    useEffect(() => {
        const cols = getColumns();
        logInfo(cols);
        setColumns(cols);
    }, [eventsData]);

    useEffect(() => {
        if (isEmpty(errors)) {
            setErrorMessage('');
        } else {
            setErrorMessage(getErrorMessage(errors));
        }
    }, [errors]);

    useEffect(() => {
        if (isEmpty(warnings)) {
            setWarrningMessage('');
        } else {
            setWarrningMessage(getWarningMessage(warnings));
        }
    }, [warnings]);

    return (
        <React.Fragment>
            <Grid container className={classes.stepResultContentRoot}>
                {errorMessage && (
                    <Grid
                        className={classNames([classes.errorNotificaiton])}
                        ecal-data-id={'error-message'}
                    >
                        {errorMessage}
                    </Grid>
                )}

                {warningMessage && (
                    <Grid className={classNames([classes.warningNotification])}>
                        {warningMessage}
                    </Grid>
                )}

                { /* eventsData.rows.length <= 0 && (
                    <Grid className={classNames([classes.noData])}>
                        No Data
                    </Grid>
                ) */ }
                { /* eventsData.rows.length >= 1 && ( */ }
                <Grid container className={classes.resultContent}>
                    <TableContainer
                        hasRowSelection={false}
                        className={classes.tableBase}
                        widgetClassName={classes.tableBase}
                        isInsideWidget={true}
                        moduleType='events'
                        classes={classes}
                        tableData={eventsData.rows}
                        rowsPerPage={eventsData.rows.length}
                        sortableColumns={false}
                        columns={columns}
                        customCells={customCells}
                        showProgress={eventsData.rows.length <= 0}
                        options={options}
                        warnings={warnings}
                        errors={errors}
                    />
                </Grid>
            </Grid>
        </React.Fragment>

    );
};

ImportResults.propTypes = {
    eventsData: PropTypes.any,
};

ImportResults.defaultProps = {
    activeStep: 0,
    errors: {},
    warnings: {},
    eventsData: {
        headers: [],
        rows: [],
    },
};

export default ImportResults;
