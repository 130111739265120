import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import {
    Grid,
} from '@material-ui/core';

import {
    useLabelsStore,
} from '../../store/models';

import useStoreModuleDependency from '../hooks/useStoreModuleDependency';

import List from './list';

function CustomLabels (props) {
    const labelStore = useLabelsStore();

    const [listData, setListData] = useState([]);
    const history = useHistory();

    function initStore () {
        console.log('init Store: ', labelStore);
        labelStore.getLabels();
    }

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        if (labelStore.labels) {
            console.log('Custom Labels Labe list: ', labelStore.labels);
            setListData(labelStore.labels);
        }
    }, [labelStore.observed.labels]);

    return (
        <Grid container>
            <List
                listData={listData}
                onAdd={() => {
                    history.push('/admin/marketing/custom-labels/add');
                }}
                showProgress={labelStore.observed.labelsLoading}
            />
        </Grid>
    );
}

export default observer(CustomLabels);
