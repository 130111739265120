import { v4 as uuid } from 'uuid';

import {
    logInfo,
    sortByPropASC,
    trackIntercom,
} from '../../../utils';

import {
    apiSuperAdminUpdatePlan,
    apiGetPlans,
    apiGetCurrentPlan,
} from './api';

export async function updatePlan ({ publisher, planInfo }) {
    this.env.errorStore.action = 'superAdminUpdatePlan';
    this.env.showProgress();
    this.user = this.env.userStore.user;

    const input = {
        id: publisher.publisherId,
        planId: publisher.planId,
        subscriberLimit: planInfo.subscriberLimit,
        provider: this.activePublisher.provider,
        token: this.user.token,
        traceId: this.env.traceId,
    };

    if (planInfo.newPlanId) {
        input.newPlanId = planInfo.newPlanId;
        input.newPlanName = planInfo.newPlanName;
    }

    const res = await apiSuperAdminUpdatePlan(input);
    this.env.closeProgress();

    if (res.data.data) {
        const metadata = {
            user_id: publisher.publisherId,
            email: publisher.email,
            subscriber_limit: input.subscriberLimit,
        };

        if (input.newPlanId && input.newPlanName) {
            metadata.plan = input.newPlanName;
            metadata.plan_id = input.newPlanId;
        }

        trackIntercom({
            event: 'superadmin update plan',
            data: metadata,
            label: 'Plan Upgraded',
        });

        /*
            Since it it needs to update expiry date after, this will not be called yet
            this.loadSearchFilters();
            await this.searchPublishers(this.recentQuery, this.recentFilters);
            this.updateActivePublisher(publisher);
        */
    }
}

const allowedPlanIds = [
    14, // Free Trial
    15, // Free
    /*
    18, // GameDay Partener
    19, // GameDay Professional
    20, // Embed Partner
    21, // Ember Professional
    */
    22, // Community
    23, // Grow,
    24, // Professional
    25, // Enterprise
    /*
    26, // AWS Pro
    27, // AWS Grow
    28, // AW Enterprice
    */
];

export async function getPlans () {
    this.env.showProgress();
    const user = this.env.userStore.user;
    if (!this.env.userStore.superAdminToken) {
        return;
    }

    const res = await apiGetPlans({
        token: user.token,
        traceId: this.env.traceId,
    });

    this.env.closeProgress();
    if (res?.data?.data) {
        const edges = res.data.data.getPlans.edges;
        let plans = [];
        edges.forEach((edge) => {
            if (allowedPlanIds.includes(edge.node.id)) {
                plans.push({ ...edge.node });
            }
        });
        plans = sortByPropASC(plans, 'id');
        this.plans = plans;
        logInfo('getPlans -> plans ', this.plans);
        this.observed.plans = uuid();
    }
}

export async function getPublisherCurrentPlan () {
    const ccy = this.activePublisher.subscriptionPlanCurrency;
    const token = this.activePublisher.token;
    const input = {
        'currency': ccy,
    };

    const res = await apiGetCurrentPlan({
        token: token,
        traceId: this.env.traceId,
        data: {
            i: input,
        },
    });

    if (res.data && res.data.data && res.data.data.getCurrentPlan) {
        const edges = res.data.data.getCurrentPlan.edges;
        if (edges.length <= 0) return;
        const planInfo = edges[0].node;
        this.activePublisher.provider = planInfo.provider;
        this.observed.activePublisher = uuid();
    }
}
