import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',

        '& .MuiPaper-root': {
            overflow: 'visible !important',
        },
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    wideCell: {
        width: '150px',
    },

    widerCell: {
        width: '300px',
        fontSize: '11px !important',
    },

    longCell: {
        width: '400px',
        fontSize: '11px !important',
    },

    /* CONTROLS STYLES */

    controlsBase: {
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '8px',
        padding: '8px',
        paddingBottom: '24px',
    },

    controls: {
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
    },

    buttonsControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        marginTop: '24px',
    },

    buttonSpace: {
        marginRight: '24px',
    },

    schedulesFilter: {
        width: '40%',
        minWidth: '320px',
        marginRight: '24px',
        marginTop: '16px',
    },

    dateFilters: {
        justifyContent: 'flex-end',
        '& .MuiFormControl-marginNormal': {
            marginTop: '0px',
        },
    },
});

export default styles;
export const useStyles = makeStyles(styles);
