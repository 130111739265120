import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../commons/select/Select';
import {
    Grid,
    // Tooltip,
} from '@material-ui/core';

const namesSource = [
    {
        value: 'firstName',
        label: 'First Name',
    },

    {
        value: 'fullName',
        label: 'Full Name',
    },
];
function FieldCell (props) {
    const {
        row,
        tableProps,
    } = props;

    const [name] = useState(row.fieldValue);

    const {
        classes,
        onTableStateChange,
    } = tableProps;

    return (
        <React.Fragment>

            { row.field !== 'name' && (
                <Grid className={classes.cellBase}>
                    {row.fieldValue}
                </Grid>
            )}

            { row.field === 'name' && (
                <Grid className={classes.cellBase}>

                    <Select
                        id='name'
                        name= 'name'
                        value={name}
                        labelHeading= ''
                        marginBottom={'0px'}
                        handleChange={(e, data) => {
                            if (typeof onTableStateChange === 'function') {
                                onTableStateChange({
                                    column: 'field',
                                    field: row.field,
                                    row: row,
                                    newValue: data.props.value,
                                });
                            }
                        }}

                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}

                        dataForDropdown={namesSource}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
}

FieldCell.propTypes = {
    tableProps: PropTypes.any,
    row: PropTypes.any,
    column: PropTypes.any,
    index: PropTypes.number,
};

FieldCell.defaultProps = {
    tableProps: {},
    row: {},
    index: 0,
    column: '',
};

export default memo(FieldCell);
