import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';
import {
    updateWidgetSettings,
    // updateWidgetSetting,
} from './widgetUtil';

function WidgetPreview (props) {
    const {
        styleData,
    } = props;
    const classes = useStyles();

    const iframeRef = useRef();

    function updateWidgetStyle () {
        // console.log('Style Data: ', styleData);

        const settings = {
            primary_button_background: styleData.secondaryColour,
            header_background: styleData.headingColour,
            logo: styleData.logo,
        };

        if (styleData.cssFile) {
            settings.customCssUrl = styleData.cssFile;
        }

        updateWidgetSettings(iframeRef.current.contentWindow, settings);
    }

    useEffect(() => {
        // console.log('Style Data Updated: ', styleData);
        updateWidgetStyle();
    }, [styleData]);

    function widgetURL () {
        // "https://staging-sync.ecal.com/button/v1/ecal.html?_=1632800773590&amp;widgetRef=widget-demo&amp;apiKey=6ea0955297341b6b22f516a42177979d55821c6d7217b&amp;docReferrer=https%3A%2F%2Fstaging-admin.ecal.com%2F%3FsuperAdminUser%3D10865&amp;isPreview=true&amp;language=en&amp;"
        return 'https://staging-sync.ecal.com/button/v1/ecal.html?_=1632800773590&widgetRef=widget-demo&apiKey=6ea0955297341b6b22f516a42177979d55821c6d7217b&docReferrer=https%3A%2F%2Fstaging-admin.ecal.com%2F%3FsuperAdminUser%3D10865&isPreview=true&language=' + styleData.language + '&';
    }

    return (
        <Grid className={classes.fullSize}>
            <iframe
                onLoad={() => {
                    // eslint-disable-next-line no-undef
                    updateWidgetStyle();
                }}

                ref={iframeRef}
                className={classes.widgetPreviewMode}
                id="widget-demo"
                scrolling="no"
                frameBorder={0}
                src={widgetURL()}
            >
            </iframe>
        </Grid>
    );
}

WidgetPreview.propTypes = {
    styleData: PropTypes.any,
};

WidgetPreview.defaultProps = {
   styleData: {},
};

export default WidgetPreview;
