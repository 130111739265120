import { useState, useEffect } from 'react';

import { useButtonsStore } from '../../store/models';
import { aToB } from '../../utils/';
import { DEFAULT_IMAGE } from '../../commons/imageUploader/DefaultImage';

const DEFAULT_IMAGE_DATA = [{ data_url: DEFAULT_IMAGE }]; /* THIS IS THE FORMAT ACCEPTED BY THE IMAGE */

/*
 * A Reusable Hook used to upload images and then get its data and serialized for the uploader component.
 */
function useImageUpload (props) {
    const buttonsStore = useButtonsStore();
    const {
        setStateFunc,
        assetType,
        observableDataSource, /* is the data that will be observed by the hook */
        consumableDataSource, /* is the full data that will be consumed by the hook */
        customDefaultImage,
        shouldCheckFilesize = false,
        sizeLimit = 102, // Kilobytes
        onFileSizeError = () => {},
    } = props;

    const [image, setImage] = useState(customDefaultImage || DEFAULT_IMAGE_DATA);
    const [isUploading, setIsUploading] = useState(false);
    const [imageError, setImageError] = useState('');

    useEffect(() => {
        setIsUploading(false);
        if (consumableDataSource) {
            const url = consumableDataSource.fileUrl;
            console.log('URL: ', url);
            setImage([{ data_url: url }]);
            setStateFunc(url);
        }
    }, [observableDataSource]);

    useEffect(() => {
        setIsUploading(false);
        setImage(customDefaultImage);
    }, [customDefaultImage]);

    function upload (images) {
        if (!isFilesizeValid(images[0])) {
            if (typeof onFileSizeError === 'function') {
                onFileSizeError();
            }
            setImageError(`File is limited to ${sizeLimit || 102} KB only.`);
            setImage(DEFAULT_IMAGE_DATA);
            return;
        } else {
          // clearFileSizeError();
          setImageError('');
        }

        if (!images) {
            setImage(DEFAULT_IMAGE_DATA);
            return;
        }

        setImage([{ data_url: images[0].data_url }]);
        // setStateFunc(images[0].data_url);
        setIsUploading(true);
        buttonsStore.uploadWidgetAssets({
            file: images[0].file,
            assetType,
        });
        setIsUploading(false);
    }

    function isFilesizeValid (file) {
        if (!shouldCheckFilesize) {
            return true;
        }

        const base64str = file.data_url.substr(22);
        const decoded = aToB(base64str);
        let fileSize = decoded.length;

        if (file.file && file.file.size) {
            fileSize = file.file.size;
        }

        const kbSize = fileSize / 1024;
        if (kbSize >= sizeLimit) {
            return false;
        }

        return true;
    }

    function deleteImage () {
        setIsUploading(false);
        setImage(DEFAULT_IMAGE_DATA);
        setStateFunc('');
    }

    function imageUploadError () {
        setIsUploading(false);
        setImage(DEFAULT_IMAGE_DATA);
        setStateFunc('');
    }

    return {
        isUploading,
        setIsUploading,
        upload,
        image,
        deleteImage,
        imageUploadError,
        imageError,
        setImageError,
    };
}

export default useImageUpload;
