import React from 'react';

import {
     Grid,
//     Tooltip,
} from '@material-ui/core';

import {
    ArrowRight as UnexpandedIcon,
    ArrowDropDown as ExpandedIcon,
} from '@material-ui/icons';

import { Typography } from '../../../../components/Wrappers/Wrappers';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQrcode } from '@fortawesome/free-solid-svg-icons';

/* Custom Cells */

export function linkNameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    function renderIcon () {
        if (!row.parent) return;
        if (row.open) {
            return (<ExpandedIcon />);
        }
        return (<UnexpandedIcon />);
    }
    return (
        <Grid
            className={classes.linkNameContent}
        >
            { renderIcon() }
            <Typography
                className={classes.linkNameCell}
                variant={'body2'}
                style={{ textTransform: 'capitalize' }}
            >
                {row.link_name} {row?.links?.length && `(${row?.links?.length})`}
            </Typography>
        </Grid>
    );
}

export function urlCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.tableURLCell}
                variant={'body2'}
            >
                {row.url}
            </Typography>
    );
}

export function clicksCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.clicksCell}
                variant={'body2'}
            >
                {row.count}
            </Typography>
    );
}
