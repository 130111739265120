import React, { useState, useEffect } from 'react';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Drawer, IconButton, List } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../app/context/LayoutContext';

function Sidebar ({
  location,
  structure,
  clickOverride,
  closeAll = false,
  shouldClose = () => {},
  scrollParent,
}) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    width < 1100 && handleSideNavToggle();
    width > 1100 && handleSideNavToggleLargeDesktop();
  }, [width]);

  const handleSideNavToggle = () => {
    if (isSidebarOpened) {
      setPermanent(false);
      toggleSidebar(layoutDispatch);
    }
  };

  const handleSideNavToggleLargeDesktop = () => {
    if (!isSidebarOpened) {
      setPermanent(true);
      toggleSidebar(layoutDispatch);
    }
  };

  const toggleDrawer = value => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup () {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  function shouldSidebarOpen (link) {
    if (link?.open) {
      return link?.open;
    }
    return !isPermanent ? !isSidebarOpened : isSidebarOpened;
  }

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: !isPermanent ? !isSidebarOpened : isSidebarOpened,
        [classes.drawerClose]: !isPermanent ? isSidebarOpened : !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: !isPermanent
            ? !isSidebarOpened
            : isSidebarOpened,
          [classes.drawerClose]: !isPermanent
            ? isSidebarOpened
            : !isSidebarOpened,
        }),
      }}
      open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
      onClose={toggleDrawer(true)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        classes={{ padding: classes.padding }}
        ref={scrollParent}
      >
        {structure.map(link => (
          <SidebarLink
            clickOverride={clickOverride}
            key={link.id}
            location={location}
            // isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
            isSidebarOpened={shouldSidebarOpen(link)}
            {...link}
            toggleDrawer={toggleDrawer(true)}
            closeAll={closeAll}
            shouldClose={shouldClose}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange () {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
