const objectives = [
    {
        value: 'Increase awareness of events',
        label: 'Increase awareness of events',
        code: 'Awareness01',
    },
    {
        value: 'Increase attendance at events',
        label: 'Increase attendance at events',
        code: 'Attendance01',
    },
    {
        value: 'Increase ticket sales',
        label: 'Increase ticket sales',
        code: 'Ticket01',
    },
    {
        value: 'Increase membership',
        label: 'Increase membership',
        code: 'Membership01',
    },
    {
        value: 'Increase participation',
        label: 'Increase participation',
        code: 'Participation01',
    },
    {
        value: 'Better satisfy customers',
        label: 'Better satisfy customers',
        code: 'Satisfy01',
    },
    {
        value: 'Better communicate and engage',
        label: 'Better communicate and engage',
        code: 'Engage01',
    },
    {
        value: 'Increase tune-in to live events',
        label: 'Increase tune-in to live events',
        code: 'Live01',
    },
    {
        value: 'Deliver new commerce',
        label: 'Deliver new commerce',
        code: 'Commerce01',
    },
    {
        value: 'Support broadcast partners',
        label: 'Support broadcast partners',
        code: 'Broadcast01',
    },
    {
        value: 'Improve sales conversion',
        label: 'Improve sales conversion',
        code: 'Sales01',
    },
    {
        value: 'Support major corporate partners',
        label: 'Support major corporate partners',
        code: 'Major01',
    },
    {
        value: 'Increase on-time payments',
        label: 'Increase on-time payments',
        code: 'Payments01',
    },
    {
        value: 'Increase customer retention (reduce churn)',
        label: 'Increase customer retention (reduce churn)',
        code: 'Retention01',
    },
    {
        value: 'Reduce costs / expenses',
        label: 'Reduce costs / expenses',
        code: 'Expenses01',
    },
    {
        value: 'Collect customer data & insights',
        label: 'Collect customer data & insights',
        code: 'Insights01',
    },
];

export default objectives;
