import { useState, useEffect, useRef } from 'react';

function useSelectSearch (props) {
    const {
        mode,
        dataProvider: sourceDataProvider,
        selected: sourceSelected,
        onSelect,
        onDelete,
        onSearch,
        isRemoteSearch = false,
        onRemoteSearch,
    } = props;

    const [dataProvider, setDataProvider] = useState(sourceDataProvider);
    const [selected, setSelected] = useState(sourceSelected);
    const [touched, setTouched] = useState(false);

    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menuWidth, setMenuWidth] = useState(null);
    const [menuOpen, setMenuOpenState] = useState(false);
    const baseRef = useRef();
    const editRef = useRef();

    useEffect(() => {
        setMenuAnchor(baseRef.current);
        setMenuWidth(baseRef.current.clientWidth);
    }, [baseRef]);

    useEffect(() => {
        setDataProvider(sourceDataProvider);
        if (isRemoteSearch === true && touched) {
            setMenuOpen(true);
        }
    }, [sourceDataProvider]);

    useEffect(() => {
        setSelected(sourceSelected);
    }, [sourceSelected]);

    useEffect(() => {
        console.log('menu open updated: ', menuOpen);
    }, [menuOpen]);

    function handleDelete (target) {
        const newSelection = selected.filter((value) => !(target === value));
        setSelected(newSelection);
        if (typeof onSelect === 'function') {
            onSelect(newSelection);
        }
    }

    function handleSelect (data) {
        const newSelection = [
            data.value,
        ];

        setSelected(newSelection);
        if (typeof onSelect === 'function') {
            onSelect(newSelection);
        }

       doSearch('');
       editRef.current.innerHTML = '';
    }

    function doSearch (search) {
        if (mode === 'remote') {
            if (typeof onSearch === 'function') {
                onSearch(search);
            }
            return;
        }

        if (search.trim().length <= 0) {
            setDataProvider(sourceDataProvider);
            return;
        }

        const results = sourceDataProvider.filter((item) => {
            const regex = new RegExp(search, 'i');
            return regex.test(item.label);
        });

        setDataProvider(results);
        setMenuOpen(true);
    }

    function handleSearch (search) {
        document.inputTimeout =
        clearTimeout(document.inputTimeout);
        function run () {
            setTouched(true);
            if (!isRemoteSearch) {
                doSearch(search);
            } else {
                if (typeof onRemoteSearch === 'function') {
                    onRemoteSearch(search);
                }
            }
        }
        document.inputTimeout = setTimeout(run, 250);
    }

    function setMenuOpen (value) {
        setTouched(true);
        setMenuOpenState(value);
    }

    return {
        baseRef,
        editRef,

        onDelete,
        dataProvider,
        selected,
        handleSelect,
        handleDelete,
        handleSearch,

        menuOpen,
        menuAnchor,
        menuWidth,

        setMenuOpen,
        setMenuAnchor,
        setMenuWidth,
    };
}

export default useSelectSearch;
