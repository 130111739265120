import React from 'react';
import { Grid } from '@material-ui/core';
import TableContainer from '../../../../commons/ECALTable/TableContainer';
import {
    columns,
    customCells,
    options,
  } from './tableSettings';
import { useStyles } from '../../styles';
import CustomFooter from '../../../../commons/tables/CustomFooter';

import usePopLinkTable from './usePoplinkTable';

function LocTable ({ tab, isLoading }) {
    const classes = useStyles();

    const {
      tableData,
      toggleRow,
      computedRowsPerPage,
      page,
      updatePage,
      parentRowsPerPage,
      totalRows,
      showPagination,
      paginationInfo,
    } = usePopLinkTable({
      rowsPerPage: 10,
      tab,
    });

    return (
      <Grid style={{ padding: '0 8px' }}>
        <TableContainer
          hasCheck={false}
          rowsPerPage={computedRowsPerPage}
          classes={classes}
          tableData={tableData}
          columns={columns}
          customCells={customCells}
          tableTitle='Manage'
          moduleType='links'
          showProgress={isLoading}
          options={options}
          isInsideWidget={false}
          removeEmptyRows

          rowsClickEnabled={true}
          onRowClick={(row) => {
            toggleRow(row);
          }}
        />

        { showPagination && (
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CustomFooter
              pageInfoFunc={paginationInfo}

              count={totalRows}
              rowsPerPage={parentRowsPerPage}
              page={page}

              changePage={(newPage) => {
                updatePage(newPage);
              }}
              textLabels=''
            />
          </Grid>
        )}
      </Grid>
    );
}

export default LocTable;
