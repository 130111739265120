import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './squareCustomSVG.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */

const DEFAULT_ICON_SIZE = 53;
const BUTTON_BASE_WIDTH = 193;

const SquareCustomSVG = (props) => {
    const {
        width,
        text,
        iconSize,
        backgroundColor,
        fontColor,
        onTextMatrixChange,
    } = props;
    const classes = useStyles({
        backgroundColor,
        fontColor,
    });
    const textRef = useRef();
    const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 58.5319 28.4999)');

    useEffect(() => {
        if (textRef.current) {
            const text = textRef.current;
            const tbox = text.getBBox();
            // Note: Always use the iconSize as an offset to center things accordingly
            const newX = (iconSize / 2) + ((BUTTON_BASE_WIDTH - tbox.width) / 2);
            const translation = 'matrix(1 0 0 1 ' + newX + ' 28.4999)'; // will be sent to the backend as textMatrix
            setTransformMatrix(translation);
        }
    }, [textRef.current, width, text]);

    useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

    return (
        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
            viewBox='0 0 202.6 48' style={{ enableBackground: 'new 0 0 202.6. 48' }} xmlSpace='preserve'>
            <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-0.9706' y1='25.0724' x2='199.4738' y2='24.1088' gradientTransform='matrix(1 0 0 -1 0 50)'>
                <stop offset='0' style={{ 'stop-color': '#153865' }} />
                <stop offset='1' style={{ 'stop-color': '#164D93' }} />
            </linearGradient>
            <rect y='2.8' className={classes.st0} width='199.5' height='45.2' />
            <rect x='2.8' className={classes.st1} width='199.7' height='45.2' />
            <text ref={textRef} transform={transformMatrix} className={classNames([classes.st2, classes.st3, classes.st4])} >{text}</text>
            <g>
                <defs>
                    <rect id='SVGID_2_' x='12.5' y='1' width='47' height='33' />
                </defs>
                <clipPath id='SVGID_3_'>
                    <use xlinkHref='#SVGID_2_' style={{ 'overflow': 'visible' }} />
                </clipPath>
                <g className={classes.st5}>
                <path className={classes.st2} d='M36.4,20.8H24.8c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1s0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5
                    h11.6c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
                    c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.2-0.4-0.3-0.6-0.3C36.8,20.8,36.6,20.8,36.4,20.8z'/>
                <path className={classes.st2} d='M47.1,15.9H28.9c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.4,0.5
                    c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1h18.2
                    c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
                    c-0.1-0.2-0.2-0.4-0.4-0.5C48.2,16.2,48,16,47.8,16C47.5,15.9,47.3,15.9,47.1,15.9z'/>
                <path className={classes.st2} d='M32.5,25.8H14.1c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.7-0.4,1.1s0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.5,1.1,0.5
                    h18.4c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1s-0.2-0.8-0.5-1.1C33.3,26,32.9,25.8,32.5,25.8z'/>
                </g>
            </g>
        </svg>

    );
};

SquareCustomSVG.propTypes = {
    width: PropTypes.number,
    text: PropTypes.string,
    iconSize: PropTypes.number,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    onTextMatrixChange: PropTypes.func,
};

SquareCustomSVG.defaultProps = {
    width: 200,
    text: 'Sync to Calendar',
    iconSize: DEFAULT_ICON_SIZE,
    backgroundColor: '#1CB1F9',
    fontColor: '#FFFFFF',
    onTextMatrixChange: () => {},
};

export default SquareCustomSVG;
