import { v4 as uuidv4 } from 'uuid';
import EnvStore from './env';
import UserStore from './user';
import SchedulesStore from './schedule';
import EventsStore from './event';
import OptinsStore from './optins';
import WebhooksStore from './webhook';
import ErrorStore from './error';
import LabelsStore from './labels';
import SubscribersStore from './subscribers';
import APIKeyStore from './apikey';
import ButtonsStore from './buttons';
import DashboardStore from './dashboard';
import BillingStore from './billing';
import StylesStore from './styles';
import PublishersStore from './publishers';

export { useEnvStore } from './env';
export { useUserStore } from './user';
export { useSchedulesStore } from './schedule';
export { useEventsStore } from './event';
export { useOptinsStore } from './optins';
export { useWebhooksStore } from './webhook';
export { useErrorStore } from './error';
export { useLabelsStore } from './labels';
export { useSubscribersStore } from './subscribers';
export { useAPIKeyStore } from './apikey';
export { useButtonsStore } from './buttons';
export { useDashboardStore } from './dashboard';
export { useBillingStore } from './billing';
export { useStylesStore } from './styles';
export { usePublishersStore } from './publishers';

const uniqueTraceId = uuidv4();

const envStore = new EnvStore();

/* Change this to Point to Environment Variable */
window.env = process.env.REACT_APP_ENV_VAR;
// window.env = 'dev';
// window.env = 'remote';
// window.env = 'local';
envStore.Environment = window.env;
envStore.traceId = uniqueTraceId;

const userStore = new UserStore();
userStore.env = envStore;

const schedulesStore = new SchedulesStore();
const eventsStore = new EventsStore();
const optinsStore = new OptinsStore();
const webhooksStore = new WebhooksStore();
const errorStore = new ErrorStore();
const labelsStore = new LabelsStore();
const subscribersStore = new SubscribersStore();
const apikeyStore = new APIKeyStore();
const buttonsStore = new ButtonsStore();
const dashboardStore = new DashboardStore();
const billingStore = new BillingStore();
const stylesStore = new StylesStore();
const publishersStore = new PublishersStore();
schedulesStore.env = envStore;
eventsStore.env = envStore;
optinsStore.env = envStore;
webhooksStore.env = envStore;
errorStore.env = envStore;
labelsStore.env = envStore;
subscribersStore.env = envStore;
apikeyStore.env = envStore;
buttonsStore.env = envStore;
dashboardStore.env = envStore;
billingStore.env = envStore;
stylesStore.env = envStore;
publishersStore.env = envStore;

envStore.userStore = userStore;
envStore.schedulesStore = schedulesStore;
envStore.eventsStore = eventsStore;
envStore.optinsStore = optinsStore;
envStore.webhooksStore = webhooksStore;
envStore.errorStore = errorStore;
envStore.labelsStore = labelsStore;
envStore.subscribersStore = subscribersStore;
envStore.apikeyStore = apikeyStore;
envStore.buttonsStore = buttonsStore;
envStore.dashboardStore = dashboardStore;
envStore.billingStore = billingStore;
envStore.stylesStore = stylesStore;
envStore.publishersStore = publishersStore;

/*
 * initialize from store...
 */
envStore.initUserInfo();
window.envStore = envStore;

const models = {
    envStore,
    userStore,
    schedulesStore,
    eventsStore,
    optinsStore,
    webhooksStore,
    errorStore,
    labelsStore,
    subscribersStore,
    apikeyStore,
    buttonsStore,
    dashboardStore,
    billingStore,
    stylesStore,
    publishersStore,
};

export default models;
