import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { logInfo } from '../../utils';
import { errorMap } from '../../store/models/error/error_map';
import {
  Grid,
  TextField as Input,
  IconButton,
  InputAdornment,
  Tooltip,
  Button,
} from '@material-ui/core';

import {
  PersonPin as SuperAdminIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import PublisherList from './list';
import { useStyles } from './styles';
import { useUserStore, usePublishersStore } from '../../store/models';

import useEnvHistory from '../hooks/useEnvHistory';
import { Typography } from '../../components/Wrappers/Wrappers';
// import { useHistory } from 'react-router';
import useErrorDialog from '../hooks/useErrorDialog';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';
import usePublishersErrors from '../../store/models/publishers/errors/usePublishersErrors';

const skipSignoutErrCode = {
  [errorMap.PUBLISHER_INACTIVE().errCode]: true,
  InvalidSearch: true,
};

function Publishers () {
  const {
    whiteLabelSettings,
  } = useWhiteLabel();
  const user = useUserStore();
  const publishersStore = usePublishersStore();
  const classes = useStyles({ whiteLabelSettings });

  const {
    errors: publisherErrors,
 } = usePublishersErrors();

  useEnvHistory();

  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState({});
  const [publishers, setPublishers] = useState(user.publishers);

  useEffect(() => {
    if (!user.observed.isSuperAdmin) return;
    if (user.isSuperAdmin) {
      setShowSearch(true);
      setErrors({});
      if (user.groupAdmin) {
        user.searchPublishers({ publisherIds: [user?.superAdmin?.publisherId] });
      }
    } else {
      setErrors({
        title: 'Insufficient Access',
        message: 'Your account doesn\'t have the privillege to access this page.',
      });
    }
  }, [user.observed.isSuperAdmin]);

  useEffect(() => {
    user.env.closeProgress();
    setErrors(publisherErrors);
  }, [publisherErrors]);

  useEffect(() => {
    setPublishers(user.publishers);
  }, [user.observed.publishers]);

  const {
    renderErrorDialog,
  } = useErrorDialog({
    errors,
    onCloseError: () => {
      if (skipSignoutErrCode[errors.errCode]) {
        return;
      }
      user.signOut();
      // history.push('/logout');
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      doSearch();
    }
  };

  function doSearch () {
    user.resetPagingation();
    user.searchPublishers({ name: searchValue });
  }

  function renderSuperAdminButton () {
    if (user.groupAdmin) {
      return (<React.Fragment></React.Fragment>);
    }
    return (
      <Tooltip title="Login as Super Admin">
        <Button
          variant="contained"
          onClick= { (e) => {
            e.preventDefault();
            e.stopPropagation();
            publishersStore.loginAsSuperAdmin();
          }}
          size="small" color="primary">
            <span className={classes.superAdminText}> Login as Super Admin </span>
            <SuperAdminIcon fontSize="small" />
        </Button>
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <Grid container className={classes.page}>
        <Grid item container className={classes.logoImageContainer}>
          <div id='logoImage' className={classes.logoImage}></div>
        </Grid>
        <Grid className={classes.content}>

          <Grid className={classes.listHeader}>
            <Typography variant={'h5'}>Select Publisher</Typography>

            { renderSuperAdminButton() }
          </Grid>
          { showSearch && (
            <Grid>
              <Input
                    id="searchField"
                    className={classes.searchField}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      classes: {
                        underline: classes.InputUnderline,
                        input: classes.Input,
                      },

                      disableUnderline: true,
                      endAdornment: (
                          <InputAdornment position="end">
                          <IconButton
                              aria-label="Search by publisher by name or email"
                              onClick={() => {
                                  doSearch();
                              }}
                          >
                              <SearchIcon />
                          </IconButton>
                          </InputAdornment>
                      ),
                    }}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    margin="normal"
                    placeholder="Search Publisher by name/email"
                    type="email"
                    fullWidth
                  />

            </Grid>
          )}

          <PublisherList
              listData={publishers}
              pageInfo={user.publishersPageInfo}
              obervablePageInfo={user.observed.publishersPageInfo}
              paginate={(p) => user.paginate(p)}
              onSelectPublisher={(item) => {
                const publisher = { ...item };
                logInfo(' selecting Publisher ->: ', publisher);
                if (publisher.status !== 'inactive') {
                  user.selectPublisher(item);
                } else {
                  setErrors(errorMap.PUBLISHER_INACTIVE());
                }
              }}
          />
        </Grid>

      </Grid>
      { renderErrorDialog() }
    </React.Fragment>
  );
}

export default observer(Publishers);
