import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useErrorStore } from '../../store/models';
import Validations, { validate } from '../../utils/validations';

const signUpErrorMessages = {

    lastName: {
        required: 'Last name is required.',
    },

    email: {
        required: 'Email is required.',
        minLength: 'Email mininum length is 5',
        maxLength: 'Email maximum length is 256',
        pattern: 'Email format is incorrect, please follow email format john.jacob@domain.com',
    },

    password: {
        required: 'Password is required',
        minLength: 'Password mininum length is 8',
        maxLength: 'Password maximum length is 32',
        pattern: 'Password needs one or more uppercase letter, special character and number',
    },

    phone: {
        required: 'Phone is required',
        minLength: 'Phone mininum length is 7',
        maxLength: 'Phone maximum length is 16',
        pattern: 'Please follow phone number format (+) (country code) (phone number) ',
    },

    publisherName: {
        required: 'Publisher name is required',
    },

    industry: {
        required: 'Industry is required',
    },

    objective: {
        required: 'Objective is required',
    },

    timezone: {
        required: 'Timezone is required',
    },

};

function useAuthErrorHook (props) {
    const {
        signUpInput,
    } = props;

    const errStore = useErrorStore();

    const [_signUpInput, setSignUpInput] = useState(signUpInput);

    const [signUpError, setSignUpError] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        publisherName: '',
        phone: '',
        industry: '',
        objective: '',
        timezone: '',
        nextDisabled: true,
        finishDisabled: true,
    });
    const [touched, setTouched] = useState({});

    function toggleNext (err) {
        if (!err.email && !err.password && !err.lastName) {
            err.nextDisabled = false;
        } else {
            err.nextDisabled = true;
            return;
        }

        if (isEmpty(_signUpInput.email) ||
            isEmpty(_signUpInput.password) ||
            isEmpty(_signUpInput.lastName)) {
            err.nextDisabled = true;
        } else {
            err.nextDisabled = false;
        }
    }

    function validateByChange () {
        if (isEmpty(touched)) {
            return;
        }

        const err = {
            lastName: validateLastName(),
            email: validateEmail(),
            password: validatePassword(),
            phone: validatePhone(),
            publisherName: validatePublisherName(),
            industry: validateIndustry(),
            objective: validateObjective(),
            timezone: validateTimezone(),
        };

        toggleNext(err);

        if (
            err.publisherName ||
            err.phone ||
            err.industry ||
            err.timezone ||
            err.objective ||
            !_signUpInput.industry ||
            !_signUpInput.objective ||
            !_signUpInput.timezone
        ) {
            err.finishDisabled = true;
        } else {
            err.finishDisabled = false;
        }

        setSignUpError(err);
    }

    function validateLastName () {
        if (!touched.lastName) {
            return '';
        }
        return validate({
            rules: { ...Validations.lastName },
            messages: signUpErrorMessages.lastName,
            value: _signUpInput.lastName,
        });
    }

    function validateEmail () {
        if (!touched.email) {
            return '';
        }
        return validate({
            rules: { ...Validations.email },
            messages: signUpErrorMessages.email,
            value: _signUpInput.email,
        });
    }

    function validatePassword () {
        if (!touched.password) {
            return '';
        }
        return validate({
            rules: { ...Validations.password },
            messages: signUpErrorMessages.password,
            value: _signUpInput.password,
        });
    }

    function validatePhone () {
        if (!touched.phoneNumber) {
            return '';
        }

        return validate({
            rules: { ...Validations.phone },
            messages: signUpErrorMessages.phone,
            value: _signUpInput.phoneNumber,
        });
    }

    function validatePublisherName () {
        if (!touched.publisherName) {
            return '';
        }
        return validate({
            rules: { ...Validations.required },
            messages: signUpErrorMessages.publisherName,
            value: _signUpInput.publisherName,
        });
    }

    function validateIndustry () {
        if (!touched.industry) {
            return '';
        }
        return validate({
            rules: { ...Validations.required },
            messages: signUpErrorMessages.industry,
            value: _signUpInput.industry,
        });
    }

    function validateObjective () {
        if (!touched.objective) {
            return '';
        }
        return validate({
            rules: { ...Validations.required },
            messages: signUpErrorMessages.objective,
            value: _signUpInput.objective,
        });
    }

    function validateTimezone () {
        if (!touched.timezone) {
            return '';
        }
        return validate({
            rules: { ...Validations.required },
            messages: signUpErrorMessages.timezone,
            value: _signUpInput.timezone,
        });
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in signUpInput) {
            if (signUpInput[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setSignUpInput(signUpInput);
    }, [signUpInput]);

    useEffect(() => {
        // console.log('Touched: ', touched);
        if (Object.entries(touched).length > 0) {
            validateByChange();
        }
    }, [_signUpInput]);

    useEffect(() => {
        if (errStore.authError.signUp) {
            setSignUpError({
                email: errStore.authError.signUp.message,
            });
        }
    }, [errStore.authError.signUp]);

    useEffect(() => {
        validateByChange();
    }, []);
    return {
        signUpError,
    };
}

export default useAuthErrorHook;
