import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useErrorStore } from '../../store/models';
import Validations, { validate, validateRePassword } from '../../utils/validations';

const passwordChangeMessages = {
    newPassword: {
        required: 'Password is required',
        minLength: 'Password mininum length is 8',
        maxLength: 'Password maximum length is 32',
        pattern: 'Password needs one or more uppercase letter, special character and number',
    },

    rePass: {
        passwordNotMatch: 'Password does not match',
    },

};

function useAuthErrorHook (props) {
    const {
        changePasswordInput,
    } = props;

    const errStore = useErrorStore();
    const [_changePasswordInput, setChangePasswordInput] = useState(changePasswordInput);
    const [touched, setTouched] = useState({});

    const [changePasswordError, setChangePasswordError] = useState({
        oldPassword: '',
        newPassword: '',
        rePass: '',
    });

    function validateByChange () {
        if (isEmpty(touched)) {
            return;
        }

        const err = {
            newPassword: validateNewPassword(),
            rePass: validateRePass(),
        };

        setChangePasswordError(err);
    }

    function validateNewPassword () {
        if (!touched.newPassword) {
            return '';
        }
        return validate({
            rules: { ...Validations.password },
            messages: passwordChangeMessages.newPassword,
            value: _changePasswordInput.newPassword,
        });
    }

    function validateRePass () {
        if (!touched.rePass) {
            return '';
        }

        return validateRePassword({
            rules: { ...Validations.rePass },
            messages: passwordChangeMessages.rePass,
            valueRePassword: _changePasswordInput.rePass,
            valueNewPassword: _changePasswordInput.newPassword,
        });
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in changePasswordInput) {
            if (changePasswordInput[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setChangePasswordInput(changePasswordInput);
    }, [changePasswordInput]);

    useEffect(() => {
        if (Object.entries(touched).length > 0) {
            validateByChange();
        }
    }, [_changePasswordInput]);

    useEffect(() => {
        if (errStore.errors) {
            setChangePasswordError({
                oldPassword: errStore.errors.message,
            });
        };
    }, [errStore.observed.errors.changePassword]);

    useEffect(() => {
        validateByChange();
    }, []);

    return {
        changePasswordError,
    };
}

export default useAuthErrorHook;
