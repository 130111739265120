import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
    Grid,
    Tab,
    // Box,
} from '@material-ui/core';

import {
    EditAttributes as ButtonDesignIcon,
    EventAvailable as SchedulesIcon,
    Brush as PopUpIcon,
    HowToReg as SubscriberDataIcon,
    Dehaze as OptionsIcon,
} from '@material-ui/icons';
import Tabs from '../../../commons/tabs';
import Details from './details';
import Schedules from './schedules/Schedules';
import Design from './design';
import SubscriberData from './subscriberData/';
import Options from './options';

import useModuleInput from './useModuleInput';

import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';

function Inputs (props) {
    const {
        location,
    } = props;

    const [tabsDisabled, setTabDisabled] = useState(false);

    const {
        input,
        updateSavable,
        copySavableToInput,
        saveDisabled,
        progress,
        upsert,
        loadCategories,
        errors,
        updateTouch,
        touched,
        inputErrors,
        setInputErrors,
    } = useModuleInput({
        location,
        setTabDisabled,
    });

    const handleDataChange = (data) => {
      copySavableToInput();
    };

    const [tab, setTab] = useState(0);
    const [lastDataLoad, setLastDataLoad] = useState(moment().calendar());

    function shouldChangeTab () {
        const target = window.savableData;
        if (
            touched.schedules &&
            target.display?.scheduleOption === 2 &&
            (isEmpty(target.display?.categories) || target.display?.categories?.length <= 0)
        ) {
            setInputErrors({
                title: 'Category is required',
                message: 'Please select one or more category',
            });
            return false;
        }
        setInputErrors({
            title: '',
            message: '',
        });
        return true;
    }

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors: inputErrors,
    });

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Tabs
                    shouldChangeTab={shouldChangeTab}
                    onTabChange={(newTab) => {
                        setTabDisabled(true);
                        setTab(newTab);
                    }}
                >
                    <Tab
                        label="Details"
                        icon={<ButtonDesignIcon />}
                        disabled={tab === 0 ? false : tabsDisabled}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-details'
                    />

                    <Tab
                        label="Design"
                        disabled={tab === 1 ? false : tabsDisabled}
                        icon={<PopUpIcon />}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-design'
                    />

                    <Tab
                        disabled={tab === 2 ? false : tabsDisabled}
                        label="Schedules"
                        icon={<SchedulesIcon />}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-schedules'
                    />

                    <Tab
                        disabled={tab === 3 ? false : tabsDisabled}
                        label="Subscriber Data"
                        icon={<SubscriberDataIcon />}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-subscriber-data'
                    />
                    <Tab
                        disabled={tab === 4 ? false : tabsDisabled}
                        label="Options"
                        icon={<OptionsIcon />}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-options'
                    />
                </Tabs>
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid item justify={'center'} container>
                            <Details
                                location={location}
                                dataSource={input}
                                /* will be notified on button Design Unmount... */
                                updateSavable={updateSavable}
                                onDataChange={handleDataChange}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                                />
                        </Grid>
                    )}

                    { tab === 1 && (
                        <Grid item justify={'center'} container>
                            <Design
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={handleDataChange}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                                setTabDisabled={setTabDisabled}
                            />
                        </Grid>
                    )}

                    { tab === 2 && (
                        <Grid item justify={'center'} container>
                            <Schedules
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={handleDataChange}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                loadCategories={loadCategories}
                                lastDataLoad={lastDataLoad}
                                setLastDataLoad={setLastDataLoad}
                                progress={progress}
                                shouldSave={shouldChangeTab}
                                onTouch={updateTouch}
                            />
                        </Grid>
                    )}

                    { tab === 3 && (
                        <Grid item justify={'center'} container>
                            <SubscriberData
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={handleDataChange}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                            />
                        </Grid>
                    )}

                    { tab === 4 && (
                        <Grid item justify={'center'} container>
                            <Options
                                dataSource={input}
                                updateSavable={updateSavable}
                                /* will only be notified on unmount(tab change) so that no wasteful processing */
                                onDataChange={handleDataChange}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                                errors={errors}
                            />
                        </Grid>
                    )}

                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>

    );
};

export default observer(Inputs);
