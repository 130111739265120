/* This widget Util  is specifically made for Admin to Interact with Widget */
import { isEmpty } from 'lodash';

export function updateWidgetSettings (contentWindow, settings) {
    const p = {
        type: 'SETTINGS_CONFIGURE',
        payload: {
            message: settings,
            source: 'updateSettings',
            token: 'ECAL_WIDGET_POST_MESSAGE',
        },
    };
    const param = JSON.stringify(p);
    contentWindow.postMessage(param, '*');
}

export function updateWidgetSetting (styleSource) {
    if (!isEmpty(window.EcalEventWidget) &&
    typeof window.EcalEventWidget.updateSettings === 'function') {
        window.EcalEventWidget.updateSettings({
            settings: styleSource,
        });
    }
}

/* old implementation
    console.log('updateWidgetSetting:', field);

    if (field !== 'logo') {
        console.log('value::', value);
    }

    const p = {
        token: 'ecal:live-preview-update',
        property: field,
        value: value,
    };
    const param = JSON.stringify(p);
    console.log('To Post Message: ', param);
    contentWindow.postMessage(param, '*');
*/
