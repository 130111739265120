import { v4 as uuid } from 'uuid';
import { logInfo } from '../../../utils';

export function setActivePublisher (publisher) {
    this.activePublisher = publisher;
    this.saveActivePublisher();
    this.observed.activePublisher = uuid();
}

export function loadActivePublisher () {
    this.activePublisher = this.env.getPageInfo('superadmin-publisher');
    this.observed.activePublisher = uuid();
}

export function saveActivePublisher () {
    this.env.setPageInfo(
        'superadmin-publisher',
        this.activePublisher
    );
}

export function saveSearchFilters () {
    const searchParams = {
        filter: this.recentFilters,
        query: this.recentQuery,
    };
    logInfo('publishersStore.saveSearchFilters() -> ', searchParams);
    this.env.setPageInfo('superadmin-search', searchParams);
}

export function loadSearchFilters () {
    const searchParams = this.env.getPageInfo('superadmin-search');
    if (searchParams) {
        this.recentFilters = searchParams.filter;
        this.recentQuery = searchParams.query;
    }
}
