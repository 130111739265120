import { v4 as uuid } from 'uuid';
import {
    getEnvInfo,
    setEnvInfo,
    setEnvSettings as setEnvSettingsUtil,
    getEnvSettings as getEnvSettingsUtil,
    logInfo,
} from '../../../utils';

import {
    intercomShutdown,
} from '../../../utils/intercom';

import {
    apiV3Location,
} from './api';

export function initUserInfo () {
    const einfo = getEnvInfo();
    this.userStore.user = {};
    this.userStore.publisher = einfo.publisher; /* observable publisher */
    this.publisher = einfo.publisher; /* non observable publisher */
    // NOTE THE ONLY AcTION THAT CAN CHANGE UserStore.publisher are
}

export function getPageInfo (page) {
    const info = getEnvInfo();
    if (info.page && info.page[page]) {
        return info.page[page];
    }
}

export function setPageInfo (page, data) {
    const info = getEnvInfo();
    setEnvInfo({
        ...info,
        page: {
            ...info.page,
            [page]: data,
        },
    });
}

export function clearStoredInfo () {
    logInfo('clearStoredInfo()');
    setEnvInfo({});
}

export function getStoredInfo () {
    return getEnvInfo();
}

export function storeUserInfo (props) {
    logInfo('');
    logInfo('');
    logInfo('EnvStore.storeUserInfo() -> ', props);
    setEnvInfo({
        ...getEnvInfo(),
        ...props,
    });
}

export function persistPublisher (publisher) {
    logInfo('');
    logInfo('EnvStore.persistPublisher() -> ', publisher);
    this.storeUserInfo({
        publisher: publisher,
    });
}

export function showNotification (type, message) {
    this.notificationType = type;
    this.notificationMessage = message;
    this.notificationOpen = true;
}

export function closeNotification () {
    this.notificationOpen = false;
}

export function showProgress (message) {
    this.progressMessage = message;
    this.progressOpen = true;
}

export function closeProgress () {
    this.progressOpen = false;
}

export function setEnvSettings (settings) {
    logInfo('');
    logInfo('');
    logInfo('setEnvSettings()', settings);
    setEnvSettingsUtil(settings);
}

export function getEnvSettings () {
    const settings = getEnvSettingsUtil();
    logInfo('');
    logInfo('');
    logInfo('getEnvSettings()', settings);
    return settings;
}

export function signIn () {
    const settings = getEnvInfo();
    setEnvInfo({
        ...settings,
        signedOut: false,
    });
}

export function signOut () {
    intercomShutdown();
    this.clearStoredInfo();
    const settings = getEnvInfo();
    setEnvInfo({
        ...settings,
        signedOut: true,
    });

    const newSettings = getEnvInfo();
    logInfo('env.signout() settings: ', newSettings);
}

// Validated on Session Signout
export function shouldSignOut () {
    const settings = getEnvInfo();
    logInfo('env.shouldSignOut() settings: ', settings);
    if (settings.signedOut) {
        intercomShutdown();
        const url = window.location.href;
        if (url.indexOf('login') === -1 || url.indexOf('signup') === -1) {
            logInfo('will signout...');
            window.location = '/login';
        }
    }
}

/* axios controller to cancel requests */

export function createRequest (controller) {
    const id = uuid();
    this.requestControllers[id] = controller;
    return id;
}

export function clearRequest (id) {
    delete this.requestControllers[id];
}

export function abortRequest (id) {
    const controller = this.requestControllers[id];
    if (controller && typeof controller.abort === 'function') {
        controller.abort();
    }
    delete this.requestControllers[id];
}

export function abortAllRequests () {
    console.log('EnvStore.abortAllRequests() : ');
    const keys = Object.keys(this.requestControllers);
    if (keys) {
        keys.forEach(id => {
            const controller = this.requestControllers[id];
            controller.abort();
        });
    }
    this.requestControllers = {};
}

export async function getAPIV3Location (apiKey, traceId) {
    this.locationAPI = await apiV3Location(apiKey, traceId);
    logInfo('env.getAPIV3Location() : ', this.locationAPI);
}
