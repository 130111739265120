import React, { useEffect, useState } from 'react';

import { CircularProgress } from '../../components/Wrappers';
import { Grid, Dialog, Typography, Box, Button } from '@material-ui/core';

import { useStyles } from './use3dsecure.styles';

function ProgressDialog (props) {
    const {
        open,
        url = '',
    } = props;

    const classes = useStyles();
    const [btnRef, setRef] = useState(null);

    useEffect(() => {
        console.log('mounting progress dialog...', open);
    }, []);

    useEffect(() => {
        console.log('3d secured button ref -> ', btnRef);
        console.log('URL to Navigate -> url -> ', url);
        if (url && btnRef) {
            btnRef.click();
        }
    }, [btnRef]);

    useEffect(() => {
        console.log('securedDialog -> actionLink updated...');
        if (url) {
            console.log('Button -> ', btnRef);
            console.log('URL to Navigate -> url -> ', url);
            if (btnRef) {
                btnRef.click();
            }
        }
    }, [url]);

    return (<Dialog
        open={open}
    >
        <Grid
            container
            className={classes.dialogContent}>
            <Box
                mb={3}
            >
                <CircularProgress
                    color={'info'}
                />
            </Box>

            <Typography
                variant="body"
                className={classes.message}
            >
                Your card requires 3D secure authentication.<br/>
                If your bank authentication pop-up did not show,<br/> please turn off the pop-blocker and repeat the process.
            </Typography>

            <Button
                ref={(ref) => setRef(ref)}
                style={{ display: 'none' }}
                value="auth"
                onClick={() => {
                    console.log('Will Navigate to URL -> ', url);
                    window.open(url, '_blank');
                }}>

            </Button>
        </Grid>
    </Dialog>);
}

export default ProgressDialog;
