import React from 'react';

import {
    Grid,
    TextField,
} from '@material-ui/core';

import Checkbox from '../../../../../commons/Checkbox';

function text (props) {
    const {
        classes,
        customFieldData,
        setCustomFieldData,
    } = props;

    return (
        <Grid
        className={classes.fieldsRoot}
            >
            <TextField
                id="message"
                label="Field name*"
                value={customFieldData.name}
                onChange={(e) => {
                  const v = e.target.value;

                  setCustomFieldData({
                      ...customFieldData,
                    name: v,
                  });
                }}
                name="message"
                variant="outlined"
                helperText={'Enter the name of the custom field.'}
            />

            <Checkbox
                checked={customFieldData.required}
                defaultChecked={customFieldData.required}
                onChange={e => {
                    setCustomFieldData({
                        ...customFieldData,
                        required: !customFieldData.required,
                    });
                }}
                variant="primary"
                label=" Required"
            />
        </Grid>
    );
}

export default text;
