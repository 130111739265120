import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Menu,
    MenuItem,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import {
    SupervisorAccount as SwitchIcon,
} from '@material-ui/icons';

import { Typography } from '../Wrappers/Wrappers';

import classNames from 'classnames';

// styles
import useStyles from './styles';

function ProfileMenu (props) {
    const {
        onClose,
        onSignOut,
        anchorEl,
        open,
        userInfo,
        role,
    } = props;
    const classes = useStyles();
    const history = useHistory();

    function renderSwitchAccount () {
      if (role.superAdmin || role.groupAdmin) {
        return (<MenuItem
          className={classNames(
            classes.profileMenuItem,
            classes.headerMenuItem
          )}
          onClick={() => {
            history.push('/publishers');
          }}
        >
          <SwitchIcon className={classes.profileMenuIcon} /> Switch Account
        </MenuItem>);
      }
      return (<React.Fragment />);
    }

    return (
        <Menu
            id="profile-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={() => onClose()}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
        >
        <div className={classes.profileMenuUser}>
          {/* <Typography variant="h4" weight="medium">
            {userInfo.firstName + ' ' + userInfo.lastName}
          </Typography> */}
          <Typography
            className={classes.profileMenuLink}
            component="a"
            color="primary"
            href="#"
          >
            {userInfo.email ? userInfo.email : 'no email'}
          </Typography>
        </div>

        { renderSwitchAccount() }
        <div className={classes.profileMenuUser}>
          <Typography
            variant={'body2'}
            className={classes.profileMenuLink}
            color="primary"
            onClick={() => onSignOut()}
          >
            Sign Out
          </Typography>
        </div>
      </Menu>
    );
}

ProfileMenu.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSignOut: PropTypes.func,
    anchorEl: PropTypes.any,
    userInfo: PropTypes.object,
    role: PropTypes.any,
};

ProfileMenu.defaultProps = {
    open: false,
    onClose: () => {},
    onSignOut: () => {},
    anchorEl: null,
    userInfo: {
      name: 'Mary Cross',
    },
    role: {},
};

export default ProfileMenu;
