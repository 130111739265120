import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

const styles = (theme) => ({
    icon: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
    },
    passwordContainer: {
        paddingTop: theme.spacing(5),
    },
    boxStyling: {
      backgroundColor: '#ffffff',
      padding: 24,
      borderRadius: 4,
      boxShadow: theme.customShadows.widget,
    },
    uploadSection: {
        maxWidth: '150px',
        marginRight: '24px',
        '& img': {
            width: 120,
        },
    },
    linkPointer: {
        cursor: 'pointer',
    },

    addOnsSpace: {
        marginBottom: '32px',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFFFFF',
    },

    buttons: {
        display: 'flex',
        width: '100%',
        maxWidth: '536px',
        alignItems: 'flex-end',
        height: '60px',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
});

export default styles;
export const useStyles = makeStyles(styles);
