
/*
SVG Templates and Themes

Square Template : 'ecal-square'
Available themes:  "purple",  "dark-blue" ,  "vibrant-blue" , "mono-outline" , "custom"

Pill and Rounded: 'ecal-pill', 'ecal-round',
Available Themes:  "purple-dark", "blue-dark", "vibrant-light", "mono-fill", "custom"

Ecal Icon: 'ecal-icon'
Available Themes:  "purple-dark", "blue-dark", "vibrant-light", "mono-fill"
*/
import React from 'react';
import PillDarkBlueSVG from './pill/PillDarkBlueSVG';
import PillPurpleButtonSVG from './pill/PillPurpleSVG';
import PillVibrantBlueSVG from './pill/PillVibrantBlueSVG';
import PillMonoSVG from './pill/PillMonoDarkSVG';
import PillOutlineSVG from './pill/PillOutlineSVG';
import PillCustomVibrantBlueSVG from './pill/PillCustomVibrantBlueSVG';

import RoundPurpleSVG from './round/roundPurpleSVG';
import RoundDarkBlueSVG from './round/roundDarkBlueSVG';
import RoundVibrantSVG from './round/roundVibrantSVG';
import RoundMonoSVG from './round/roundMonoSVG';
import RoundCustomSVG from './round/roundCustomSVG';
import RoundOutlineSVG from './round/roundOutlineSVG';

import SquarePurpleSVG from './square/squarePurpleSVG';
import SquareDarkBlueSVG from './square/squareDarkBlueSVG';
import SquareVibrantSVG from './square/squareVibrantSVG';
import SquareMonoSVG from './square/squareMonoSVG';
import SquareOutlineSVG from './square/squareOutlineSVG';
import SquareCustomSVG from './square/squareCustomSVG';

import IconPurpleSVG from './icon/iconPurpleSVG';
import IconBlueSVG from './icon/iconBlueSVG';
import IconVibrantBlueSVG from './icon/iconVibrantBlueSVG';
import IconMonoSVG from './icon/iconMonoSVG';
import IconOutlineSVG from './icon/iconOutlineSVG';
import IconCustomSVG from './icon/iconCustomSVG';

export const themeMapping = {
    'Dark Blue': 'dark-blue',
    Purple: 'purple',
    'Vibrant Blue': 'vibrant',
    Mono: 'mono',
    Outline: 'outline',
    custom: 'custom',
};

const defaultThemes = [
    'purple',
    'dark-blue',
    'vibrant',
    'mono',
    'custom',
    'outline',
];

export const defaultTemlplate = {
    name: 'ecal-round',
    theme: 'custom',
    themesList: defaultThemes,
};

export const buttonsThemes = {
    'ecal-square': [...defaultThemes],
    'ecal-pill': [...defaultThemes],
    'ecal-round': [...defaultThemes],
    'ecal-icon': [
        'purple',
        'dark-blue',
        'vibrant',
        'mono',
    ],
};

export const pillSvgs = {
    'purple': PillPurpleButtonSVG,
    'dark-blue': PillDarkBlueSVG,
    'vibrant': PillVibrantBlueSVG,
    'mono': PillMonoSVG,
    'outline': PillOutlineSVG,
    'custom': PillCustomVibrantBlueSVG,
};

export const roundSvgs = {
    'purple': RoundPurpleSVG,
    'dark-blue': RoundDarkBlueSVG,
    'vibrant': RoundVibrantSVG,
    'mono': RoundMonoSVG,
    'outline': RoundOutlineSVG,
    'custom': RoundCustomSVG,
};

export const squareSvgs = {
    'purple': SquarePurpleSVG,
    'dark-blue': SquareDarkBlueSVG,
    'vibrant': SquareVibrantSVG,
    'mono': SquareMonoSVG,
    'outline': SquareOutlineSVG,
    'custom': SquareCustomSVG,
};

export const iconSvgs = {
    'purple': IconPurpleSVG,
    'dark-blue': IconBlueSVG,
    'vibrant': IconVibrantBlueSVG,
    'mono': IconMonoSVG,
    'outline': IconOutlineSVG,
    'custom': IconCustomSVG,
};

export function getThemes (template) {
    if (buttonsThemes[template] && buttonsThemes[template].length > 0) {
        return buttonsThemes[template];
    }
    return '';
}

export const svgs = {
    'ecal-square': squareSvgs,
    'ecal-pill': pillSvgs,
    'ecal-round': roundSvgs,
    'ecal-icon': iconSvgs,
};

export function renderSVG (template, theme, props) {
    if (svgs[template] && svgs[template][theme]) {
        const Component = svgs[template][theme];
       return <Component
            { ...props }
       />;
    }
    return null;
}

export function svgComponent (template, theme) {
    if (svgs[template] && svgs[template][theme]) {
        return svgs[template][theme];
    }
    return null;
}
