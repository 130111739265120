import { useEffect, useState } from 'react';
import { useStylesStore } from '.';
import { useUserStore } from '../user';
import defaultEcal from './clients/defaultEcal';

function updatePageSettings (whiteLabelSettings) {
    const favicon = document.querySelector("link[rel*='icon']");

    if (favicon) {
        favicon.href = whiteLabelSettings.favicon;
    }

    document.title = whiteLabelSettings.title;
}

function useWhiteLabel () {
    const userStore = useUserStore();
    const stylesStore = useStylesStore();
    const [whiteLabelSettings, setSettings] = useState(defaultEcal);

    useEffect(() => {
        if (!stylesStore.initialized) {
            stylesStore.getWhiteLabelSettings();
        }
        if (stylesStore.whiteLabelSettings) {
            setSettings(stylesStore.whiteLabelSettings);
            updatePageSettings(stylesStore.whiteLabelSettings);
        }
    }, []);

    useEffect(() => {
        if (stylesStore.whiteLabelSettings && !stylesStore.initialized) {
            stylesStore.initialized = true;
            setSettings(stylesStore.whiteLabelSettings);
            updatePageSettings(stylesStore.whiteLabelSettings);
        }
    }, [stylesStore.observed.whiteLabelSettings]);

    useEffect(() => {
        if (userStore.publisher) {
            const wlsettings = stylesStore.getWhiteLabelSettingsByPublisher();
            if (wlsettings) {
                setSettings(wlsettings);
                updatePageSettings(wlsettings);
            }
        }
    }, [userStore.observed.publisher]);

    return {
        whiteLabelSettings,
    };
}

export default useWhiteLabel;
