import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  Box,
} from '@material-ui/core';

// styles
import { useStyles } from './signUp.styles';

import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

// components
import { Button, Typography } from '../../components/Wrappers/Wrappers';

import { useUserStore, useEnvStore, useBillingStore } from '../../store/models';
import useEnvHistory from '../hooks/useEnvHistory';

import useBillingDialog from '../billing/useBillingDialog';
import useFeatures from '../hooks/useFeatures';
import useBillingPlan from '../billing/useBillingPlan';
import PlansSelection from '../billing/plansSelection';

import {
  isFreePlan,
  isFreeTrial,
  isSelectedPlanAvailable,
  logInfo,
} from '../../utils';

function SignupBillingForm (props) {
    const classes = useStyles();
    const userStore = useUserStore();
    const envStore = useEnvStore();
    const billingStore = useBillingStore();

    const {
        features,
    } = useFeatures();

    useEnvHistory();

    const {
        plans,
        selectedPlan,
        updateSelectedPlan,
        selectedPlanData,
        plansList,
    } = useBillingPlan({
      isSetup: true,
    });

    const {
        openDialog,
        renderBillingDialog,
    } = useBillingDialog();

    async function choosePlan () {
      logInfo('choosePlan()', selectedPlanData);
      if (isFreePlan(selectedPlanData.code)) {
        envStore.showProgress();
        await billingStore.upgradeFree(true);
        envStore.history.push('/admin/home/summary');
      } else {
        openDialog();
      }
    }

    useEffect(() => {
      if (userStore.user) {
          const settings = envStore.getEnvSettings();
          logInfo('signupBillingForm.useEFfect() Settings: ', settings);
          const plan = settings.plan;
          if (isFreeTrial(plan) || isFreePlan(plan)) {
            logInfo('plan setup to home...');
            envStore.history.push('/home');
          }

          if (!isSelectedPlanAvailable(plan, plansList)) {
            logInfo('plan setup to home...');
            // envStore.history.push('/home');
          }
      }
    }, [userStore.user, plansList]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={EcalLogoImage} alt="logo" className={classes.logotypeImage} />
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid item container className={classes.logoImageContainer}>
              <div id='logoImage' className={classes.logoImage}></div>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                width={600}
                                minHeight={'520px'}
                            >
                                 <Grid>
                                    <PlansSelection
                                        plans={plans}
                                        selectedPlan={selectedPlan}
                                        onSelectPlan={(p) => updateSelectedPlan(p)}
                                        title={'Let\'s Setup Your Plan'}
                                    />
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                        marginBottom={'35px'}
                                    >

                                        <Button
                                            disabled={!features.billingActive}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => choosePlan()}
                                        >
                                            Choose Plan
                                        </Button>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            { renderBillingDialog() }
          </React.Fragment>

        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2010-{new Date().getFullYear()} | ECAL | HyperKu Pte Ltd | All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default observer(SignupBillingForm);
