
import ecal from './defaultEcal';
import embed from './embedStyles';
import openid from './openId';
import funUnlimited from './funUnlimited';
import prodEvensHelix from './prod.events.helixleisure.net';

export default {
    ecal,
    embed,
    openid,
    funUnlimited,
};

const domains = [
    'embed',
    'openid',
    'events.helix-playground.net',
    'events.helix-uat.net',
    'events.helixleisure.net',
];

const urlMap = {
    'embed': embed,
    'events.helix-playground.net': embed,
    'events.helix-uat.net': embed,
    'events.helixleisure.net': prodEvensHelix,
    'openid': openid,
};

export function getDomainStyles () {
    for (let i = 0; i < domains.length; i++) {
        const subdomain = domains[i];
        if (window.location.origin.indexOf(subdomain) !== -1) {
            if (urlMap[subdomain]) {
                return urlMap[subdomain];
            }
        }
    }
    return null;
}

export function getSubDomainURL () {
    for (let i = 0; i < domains.length; i++) {
        const subdomain = domains[i];
        if (window.location.origin.indexOf(subdomain) !== -1) {
            return subdomain;
        }
    }
    return null;
}

const publisherSettingsMap = {
    '14287': funUnlimited, // nherwin on test env
    '14377': funUnlimited, // jack.happy on test env

    '472467': funUnlimited, // PROD Embed ECAL Test
    '471148': funUnlimited,
    '14335': funUnlimited,

    '11428': funUnlimited,

    /* stage */

    '7209': funUnlimited,
    '7210': funUnlimited,
    '7211': funUnlimited,
    '7295': funUnlimited,
    '7296': funUnlimited,
    '7297': funUnlimited,
    '14280': funUnlimited, // shak on stage
};

export function getByPublisherId (pubId) {
    if (publisherSettingsMap[pubId]) {
        return publisherSettingsMap[pubId];
    }
    return null;
}
