import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getButtons,
    generateImage,
    addWidget,
    editWidget,
    editWidgets,
    deleteWidgets,
    makeWidgetsDraft,
    makeWidgetsLive,
    uploadWidgetAssets,
    updateSchedulesFilterForButton,
} from './actions';

import {
    loadEditData,
    saveEditData,
    loadEventDisplayData,
    saveEventDisplayData,
} from './actions_persist';

export default class ButtonsStore {
    version = {}
    env = null;
    editData = null;

    buttons = null;
    hasInactive = false;
    assets = {};
    buttonSchedules = [];
    pageInfo = {
        page: 0, /* convert this to 1 on passing */
        limit: 10,
        count: 0,
    };

    observed = {
        buttons: null,
        buttonsLoading: false,
        pageInfo: null,
        assets: {},
        buttonSchedules: null,
    };

    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    getButtons = getButtons;
    generateImage = generateImage;
    addWidget = addWidget;
    editWidget = editWidget;
    editWidgets = editWidgets;
    deleteWidgets = deleteWidgets;
    makeWidgetsDraft = makeWidgetsDraft;
    makeWidgetsLive = makeWidgetsLive;
    uploadWidgetAssets = uploadWidgetAssets;
    updateSchedulesFilterForButton = updateSchedulesFilterForButton;

    /* Data Persist */
    loadEditData = loadEditData;
    saveEditData = saveEditData;
    saveEventDisplayData = saveEventDisplayData;
    loadEventDisplayData = loadEventDisplayData;
}

export function useButtonsStore () {
    const { buttonsStore } = useStores();
    return buttonsStore;
}
