import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

const styles = (theme) => ({
    addOnsBox: {
        width: '100%',
        height: '56px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        border: '1px solid #dfdfdf',
        borderRadius: '4px',
        backgroundColor: '#fff',
        marginBottom: '16px',
        color: '#00000061',
    },

    addOnsName: {
        marginLeft: '16px',
    },

    addOnsValue: {
        marginRight: '16px',
        fontWeight: 'bold',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
