import React from 'react';
import PropTypes from 'prop-types';

import {
    Checkbox as MUICheckbox,
    FormControlLabel,
} from '@material-ui/core';

import { Typography } from '../../components/Wrappers';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const ThemedCheckbox = withStyles({
    root: {
        color: '#0c0644',
        width: '30px',
        height: '30px',
        marginRight: '8px',

        '&$checked': {
            color: '#0c0644',
        },

    },
})(props => <MUICheckbox color="default" {...props} />);

const styles = (theme) => ({
    offset: {
        marginLeft: '0px',
    },

});

export const useStyles = makeStyles(styles);

function Checkbox (props) {
    const {
        checked,
        onChange,
        label,
        disabled,
        labelSmallFont,
        classes: _classesParams = null,
    } = props;

    const classes = useStyles();
    return (
        <FormControlLabel
            className={classes.offset}
            classes={_classesParams}
            control={
                <ThemedCheckbox
                    checked={checked}
                    disabled={disabled}
                    onChange={e => {
                        if (typeof onChange === 'function') {
                            onChange(e);
                        }
                    }}
                    value="optional"
                />
            }

            label={
                labelSmallFont
                    ? <Typography
                        variant={'overline'}
                    >
                        {label}
                    </Typography>
: label
            }
        />
    );
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.label,
    labelSmallFont: PropTypes.bool,
};

Checkbox.defaultProps = {
    checked: false,
    disabled: false,
    onChange: () => { },
    labelSmallFont: true,
    label: 'Check option',
};

export default Checkbox;
