import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

  boxStyling: {
    backgroundColor: '#ffffff',
    padding: 24,
    '& .rct-node-icon': {
      color: '#0c0644!important',
    },
  },

  mainContainer: {
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: '24px',
    boxShadow: theme.customShadows.widget,
  },

  titleBase: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    // marginBottom: '24px',
  },

  panelContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  instructions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '200px',
    marginRight: '24px',
  },

  instructionsTitle: {
    fontSize: '18px !important',
    fontWeight: '600 !important',
    marginBottom: '12px',
  },

  instructionsMessage: {
    // fontSize: '14px !important',
    marginBottom: '24px',
  },

  tableContent: {
      display: 'flex',
      flexDirection: 'column',
      /* backgroundColor: '#FBFBFB', */
      width: '100%',
      borderRadius: '16px',
  },

  tableBase: {
    width: '100%',
    // padding: '24px',
    borderRadius: '2px',
    // backgroundColor: '#FBFBFB',
  },

  tableAddCustom: {
    display: 'flex',
    paddingRight: '24px',
    marginTop: '24px',
    width: '100%',
    justifyContent: 'flex-start',
  },
  /* custom Cells */

  customFieldCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cellBase: {
    marginTop: '2px',
    '& .MuiFormControl-root': {

    },
  },

  emptyCell: {
    minWidth: '200px',
  },

}));
