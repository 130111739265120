import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
// import Slide from '@material-ui/core/Slide';
import { Button } from '../../components/Wrappers';
import useStyles from './error.styles';

function ErrorContainer ({ title, openDialog, dialogContextText, buttonPrimary, buttonSecondary, handleAgree, closeModal }) {
    // const Transition = React.forwardRef(function Transition (props, ref) {
    //     return <Slide direction="down" ref={ref} {...props} />;
    // });
    const classes = useStyles();
    return (
    <Dialog
      open={openDialog}
      // onClose={closeModal}
      className={classes.dialogContainer}
      maxWidth={'lg'}
      scroll={'body'}
    //   TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
    </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContextText}
      </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAgree}
          variant={'contained'}
          color={'success'}
          autoFocus
        >
          {buttonPrimary}
      </Button>
      </DialogActions>
    </Dialog>
  );
}
ErrorContainer.propTypes = {

  title: PropTypes.string,
  openDialog: PropTypes.bool,
  dialogContextText: PropTypes.string,
  buttonPrimary: PropTypes.string,
  handleAgree: PropTypes.func,
};

ErrorContainer.defaultProps = {
  title: 'Session is going to expire soon',
  openDialog: false,
  dialogContextText: 'Press OK to extend the session.',
  buttonPrimary: 'Ok',
  handleAgree: undefined,
};

export default ErrorContainer;
