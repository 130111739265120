import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
  dialogContainer: (prop) => {
    const {
      containerMaxWidth = '540px',
    } = prop;
    return {
      minWidth: '400px',
      maxWidth: containerMaxWidth,
      padding: theme.spacing(1),
    };
  },

  dialogActions: {
    justifyContent: 'flex-end',
    padding: '8px',
  },

  subtitleBox: {
    width: '80%',
  },

  subtitle: {
    fontSize: '10px',
    textAlign: 'center',
  },
}));
