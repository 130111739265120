export const defaultColumns = [
    {
        id: 'Logged',
        numeric: true,
        disablePadding: false,
        label: 'Logged',
        cellClassName: 'wideCell',
    },

    {
        id: 'First Name',
         numeric: true,
        disablePadding: false,
        label: 'First Name',
    },

    {
        id: 'Last Name',
        numeric: true,
        disablePadding: false,
        label: 'Last Name',
        cellClassName: 'messageCell',
    },

    {
        id: 'Gender',
        numeric: true,
        disablePadding: false,
        label: 'Gender',
    },

    {
        id: 'Email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
    },

    {
        id: 'Age Group',
        numeric: true,
        disablePadding: false,
        label: 'Age Group',
    },

    {
        id: 'Mobile',
        numeric: true,
        disablePadding: false,
        label: 'Mobile',
    },

    {
        id: 'Country',
        numeric: true,
        disablePadding: false,
        label: 'Country',
    },

    {
        id: 'Zip',
        numeric: true,
        disablePadding: false,
        label: 'Zip',
    },

    {
        id: 'Category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },

    {
        id: 'Applications',
        numeric: true,
        disablePadding: false,
        label: 'Applications',
    },

    {
        id: 'Schedules',
        numeric: true,
        disablePadding: false,
        label: 'Schedules',
    },

    {
        id: 'Country Code',
        numeric: true,
        disablePadding: false,
        label: 'Country Code',
    },

    {
        id: 'Country Name',
        numeric: true,
        disablePadding: false,
        label: 'Country Name',
    },

    {
        id: 'State Code',
        numeric: true,
        disablePadding: false,
        label: 'State Code',
    },

    {
        id: 'State Name',
        numeric: true,
        disablePadding: false,
        label: 'State Name',
    },

    {
        id: 'City Name',
        numeric: true,
        disablePadding: false,
        label: 'City Name',
    },

    {
        id: 'Zip Code',
        numeric: true,
        disablePadding: false,
        label: 'Zip Code',
    },

    {
        id: 'Latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },

    {
        id: 'Longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },

    {
        id: 'Subscriber Reference',
        numeric: true,
        disablePadding: false,
        label: 'Subscriber Reference',
    },

    {
        id: 'Date of Birth',
        numeric: true,
        disablePadding: false,
        label: 'Date of Birth',
    },

    {
        id: 'Opt-in-1',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-1',
    },

    {
        id: 'Opt-in-1-Reference',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-1-Reference',
    },

    {
        id: 'Opt-in-1-Text',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-1-Text',
    },

    {
        id: 'Opt-in-2',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-2',
    },

    {
        id: 'Opt-in-2-Reference',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-2-Reference',
    },

    {
        id: 'Opt-in-2-Text',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-2-Text',
    },

    {
        id: 'Opt-in-3',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-3',
    },

    {
        id: 'Opt-in-3-Reference',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-3-Reference',
    },

    {
        id: 'Opt-in-3-Text',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-3-Text',
    },

    {
        id: 'Opt-in-4',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-4',
    },

    {
        id: 'Opt-in-4-Reference',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-4-Reference',
    },

    {
        id: 'Opt-in-4-Text',
        numeric: true,
        disablePadding: false,
        label: 'Opt-in-4-Text',
    },

    {
        id: 'Abover Parental Consent Age',
        numeric: true,
        disablePadding: false,
        label: 'Abover Parental Consent Age',
    },

    {
        id: 'Over the Age',
        numeric: true,
        disablePadding: false,
        label: 'Over the Age',
    },

    {
        id: 'Over the Consent Age',
        numeric: true,
        disablePadding: false,
        label: 'Over the Consent Age',
    },

    {
        id: 'Parent Consent Email',
        numeric: true,
        disablePadding: false,
        label: 'Parent Consent Email',
    },

    {
        id: 'Selection',
        numeric: true,
        disablePadding: false,
        label: 'Selection',
    },

];

export function availableColumns (data) {
    const cols = [];
    for (let i = 0; i < data.length; i++) {
        const obj = data[i];
        const keys = Object.keys(obj);
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            /* don't include empty
            if (!cols.includes(key) && obj[key]) {
                cols.push(key);
            }
            */

            /* include empty */
            if (!cols.includes(key)) {
                cols.push(key);
            }
        }
    }
    return cols;
}

export function generateColumns (defaultColumns, columnNames) {
    const keys = [...columnNames];
    const newColumns = [];

    for (let i = 0; i < defaultColumns.length; i++) {
        const colObj = defaultColumns[i];
        let columnExist = false;
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            if (colObj.id === key) {
                columnExist = true;
                keys.splice(j, 1);
                break;
            }
        }
        if (columnExist) {
            newColumns.push(colObj);
        }
    }

    /* Remaining keys here are custom column */
    return [
        ...newColumns,
        ...customColumns(keys),
    ];
}

export function customColumns (names) {
    return names.map(name => ({
        id: name,
        label: name,
        numeric: true,
        disablePadding: false,
    }));
}
