import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import {
    Delete,
    Create,
} from '@material-ui/icons';

// import { Button, Link } from '../../../../../components/Wrappers';
import Select from '../../../../../commons/select/Select';
// import SelectSearch from '../../../../../commons/ecal/singleselectsearch';
import countries from '../../../../../commons/util/countries.json';

function countriesForSelect () {
    return countries.map((item) => ({
        value: item.iso2.toLowerCase(),
        label: item.name,
    }));
}

function agesForSelect () {
    const start = 12;
    const end = 25;
    const ages = [];
    for (let i = start; i <= end; i++) {
        ages.push({ label: i, value: i });
    }
    return ages;
}
function DefaultValueCell (props) {
    const {
        row,
        tableProps,
    } = props;

    const {
        classes,
        onTableStateChange,
        onEditCustomField,
        onDeleteCustomField,
    } = tableProps;

    function dataSet () {
        if (row.defaultValueType === 'ageSelect') {
            return agesForSelect();
        }
        return countriesForSelect();
    }
    const [selectData] = useState(dataSet());

    useEffect(() => {
    }, []);

    function renderAsCustomField () {
        return (
            <Grid className={classes.customFieldCell}>
                <Tooltip
                    title='Edit Custom Field'
                >
                    <IconButton
                        onClick= { (e) => {
                            e.preventDefault();
                            if (typeof onEditCustomField === 'function') {
                                onEditCustomField(row);
                            }
                        }}

                        color={'primary'}
                    >
                        <Create fontSize={'small'} />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    title='Delete Custom Field'
                >
                    <IconButton
                        color={'primary'}
                        onClick= { (e) => {
                            e.preventDefault();
                            if (typeof onDeleteCustomField === 'function') {
                                onDeleteCustomField(row);
                            }
                        }}
                    >
                        <Delete fontSize={'small'} />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            {((row.defaultValueType === 'ageSelect') &&
                <Grid className={classes.cellBase}>
                    <Select
                        marginBottom={'0px'}
                        id='age'
                        name='age'
                        value={row.defaultValueValue}
                        labelHeading= ''
                        placeHolder='Please Select Age'
                        handleChange={(e, data) => {
                            if (typeof onTableStateChange === 'function') {
                                onTableStateChange({
                                    column: 'defaultValue',
                                    field: row.field,
                                    row: row,
                                    newValue: data.props.value,
                                });
                            }
                        }}

                        dataForDropdown={selectData}
                    />
                </Grid>
            )}

                {((row.defaultValueType === 'countrySelect') &&
                <Grid className={classNames([classes.cellBase, classes.emptyCell])}>

                </Grid>
                /*
                <Grid className={classes.cellBase}>
                    <SelectSearch
                        selected={row.defaultValueValue ? [row.defaultValueValue] : undefined}
                        onSelect={(data) => {
                            if (typeof onTableStateChange === 'function') {
                                console.log('rerendering cell: ', data);
                                onTableStateChange({
                                    column: 'defaultValue',
                                    field: row.field,
                                    row: row,
                                    newValue: data[0],
                                });
                            }
                        }}
                        helperText={''}
                        placeHolder='Select country'
                        dataProvider={selectData}
                    />
                </Grid>
                */
            )}

            {((row.defaultValueType === 'customField') && renderAsCustomField())}

        </React.Fragment>
    );
}

DefaultValueCell.propTypes = {
    tableProps: PropTypes.any,
    row: PropTypes.any,
    column: PropTypes.any,
    index: PropTypes.number,
};

DefaultValueCell.defaultProps = {
    onDataChange: () => {},
    row: {},
    index: 0,
    column: '',
};

export default DefaultValueCell;
