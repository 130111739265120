// import { logInfo } from '../utils';

export const features = {

    local: {
        name: 'local',
        assetsRoot: 'https://testing-ecal-publisher-assets.s3.amazonaws.com',

        billing_v4: {
            active: true,
        },

        event_v2_display: {
            active: true,
        },

        schedules_v2: {
            active: true,
        },

        event_v2_display_select_schedule_option: {
            active: false,
        },

        getStartGuide_v4: {
            active: true,
        },

        videoLibrary_v4: {
            active: true,
        },
    },

    dev: {
        name: 'test',

        assetsRoot: 'https://testing-ecal-publisher-assets.s3.amazonaws.com',

        billing_v4: {
            active: true,
        },

        event_v2_display: {
            active: true,
        },

        schedules_v2: {
            active: true,
        },

        event_v2_display_select_schedule_option: {
            active: false,
        },

        getStartGuide_v4: {
            active: true,
        },

        videoLibrary_v4: {
            active: true,
        },
    },

    stage: {
        name: 'stage',
        assetsRoot: 'https://staging-ecal-publisher-assets.s3.amazonaws.com',

        billing_v4: {
            active: true,
        },

        event_v2_display: {
            active: true,
        },

        schedules_v2: {
            active: false,
        },

        event_v2_display_select_schedule_option: {
            active: false,
        },

        getStartGuide_v4: {
            active: false,
        },

        videoLibrary_v4: {
            active: false,
        },
    },

    prod: {
        name: 'prod',
        assetsRoot: 'https://ecal-publisher-assets.s3.amazonaws.com',

        billing_v4: {
            active: true,
        },

        event_v2_display: {
            active: true,
        },

        schedules_v2: {
            active: false,
        },

        event_v2_display_select_schedule_option: {
            active: false,
        },

        getStartGuide_v4: {
            active: false,
        },

        videoLibrary_v4: {
            active: false,
        },
    },
};

export function getFeatures () {
    const env = process.env.REACT_APP_ENV_VAR;
    // const env = 'prod';
    if (env && features[env]) {
        return features[env];
    }
    return features.local;
}
