import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    loginLoader: {
        marginLeft: theme.spacing(4),
      },
});

export default styles;
export const useStyles = makeStyles(styles);
