import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';
import Select from '../../../commons/select/Select';
import Timezones from '../../../commons/util/Timezone.json';

// import Widget from '../../../components/Widget';
import { useStyles } from '../profile.styles';
import usePublisherCompany from './usePublisherCompany';
import ButtonProgress from '../../../commons/ButtonProgress';
// import ImageUploader from '../../../commons/imageUploader/ImageUploader';

function CompanyDetails (props) {
    const {
        publisher,
        onSave,
    } = props;

    const {
        data,
        setData,
        industries,
        objectives,
    } = usePublisherCompany({ publisher });

    const [saveDisabled, setSaveDisabled] = useState(true);

    const classes = useStyles();

    function handleChange (e, selectedVal) {
        console.log('Data: ', data);
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        setSaveDisabled(false);
    }

    return (
        <React.Fragment>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Details
                            </Typography>
                                <TextField
                                    id="company"
                                    label="Organisation Name"
                                    onChange={handleChange}
                                    name="company"
                                    value={data.company}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    helperText="Please enter your organisation name"
                                />

                                <Select
                                    value={data.industry}
                                    label='industry'
                                    name='industry'
                                    id='profile-industry'
                                    labelHeading='Industry'
                                    placeHolder='Please select industry'
                                    handleChange={handleChange}
                                    dataForDropdown={industries}
                                />
                                <Select
                                    value={data.objective}
                                    label='objective'
                                    name='objective'
                                    placeHolder='Please select objective'
                                    id='profile-objective'
                                    labelHeading='Objective'
                                    handleChange={handleChange}
                                    dataForDropdown={objectives}
                                />
                                <Select
                                    value={data.timezone}
                                    label='timezone'
                                    name='timezone'
                                    id='profile-timezone'
                                    labelHeading='Timezone'
                                    placeHolder='Please select timezone'
                                    handleChange={handleChange}
                                    dataForDropdown={Timezones}
                                />

                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <ButtonProgress
                                        disabled={saveDisabled}
                                        showProgress={false}
                                        label="Save"
                                        onClick={(e) => {
                                            onSave(data);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

CompanyDetails.propTypes = {
    publisher: PropTypes.object,
    onSave: PropTypes.func,
    inProgress: PropTypes.bool,
    uploadPublisherLogo: PropTypes.func,
    deletePublisherLogo: PropTypes.func,
    publisherLogo: PropTypes.any,
};

CompanyDetails.defaultProps = {
    publisher: {
        industry: '',
        company: '',
        objective: '',
    },
    onSave: () => { },
    uploadPublisherLogo: () => {},
    deletePublisherLogo: () => {},
    inProgress: false,

};

export default observer(CompanyDetails);
