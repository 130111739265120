import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    IconButton,
} from '@material-ui/core';

import {
    PublishOutlined as UploadIcon,
} from '@material-ui/icons';

import useStyles from './styles';

function FileSelect (props) {
    const {
        value,
        onFileSelect,
        accept,
        width,
    } = props;

    const [_value, setValue] = useState(value || '');
    const classes = useStyles({ width });
    const inputRef = useRef();

    useEffect(() => {
        console.log('useEffect()[] File Select Value: ');
        console.log('Value: ', _value);
    }, []);

    useEffect(() => {
        console.log('useEffect()[] File Select Value Change');
        console.log(' Value: ', value);
        setValue(value);
    }, [value]);

    return (
        <Grid className={classes.root}>

            <Grid
                className={classes.inputBase}
            >

                <Typography
                    className={classes.truncate}
                    variant={'body1'}
                >
                    { _value }
                </Typography>

                <IconButton
                        onClick= { (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            inputRef.current.click();
                        }}

                        color={'primary'}
                    >
                        <UploadIcon
                            fontSize={'medium'}
                            ecal-data-id={'upload-worksheet'}
                        />
                </IconButton>

            </Grid>

            <input
                accept={accept}
                type='file'
                ref={inputRef}
                className={classes.fileInput}
                onChange={(e) => {
                    setValue(e.target.files[0].name);
                    onFileSelect(e.target.files);
                }}
                />

        </Grid>
    );
}

FileSelect.propTypes = {
    onFileSelect: PropTypes.func,
    value: PropTypes.string,
    accept: PropTypes.string,
    width: PropTypes.any,
};

FileSelect.defaultProps = {
    onFileSelect: () => {},
    value: '',
    accept: '',
    width: '200px',
};

export default FileSelect;
