import { isEmpty } from 'lodash';
import { getWhiteLabelStaticSettings as whiteLabelSettings } from '../../styles/actions';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = {
    ...ErrorAPIMessages,
    FILE_NOT_FOUND: 'file not found',
    ASSET_NOT_FOUND: 'record not found',
};

const errorErrors = {
    ...ErrorAPIErrors,
    UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
    INVALID_INPUT_PARAMETER: 'INVALID_INPUT_PARAMETER',
};

const customErrorMap = {
    ...errorMap,

    FILE_NOT_FOUND: () => {
        return {};
    },

    ASSET_NOT_FOUND: (err) => {
        return {
            title: 'Asset Not Found',
            message: err.message,
            text: err.message,
        };
    },

    INVALID_INPUT_PARAMETER: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },

    // RESOURCE_NOT_FOUND: () => {
    //     return {
    //         title: 'Publisher not found',
    //         message: 'context deadline exceeded',
    //         text: 'context deadline exceeded',
    //     };
    // },

    UNPROCESSABLE_ENTITY: (err) => {
        const { supportContact } = whiteLabelSettings();
        return {
            title: 'Unable to process the request',
            message: `Please retry or contact us at ${supportContact} for futher assistance with the request Ref.ID.`,
            text: err.message,
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}

export function serviceTokenErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: {
            INSUFFICIENT_ACCESS_PRIVILEGE: 'INSUFFICIENT_ACCESS_PRIVILEGE',
        },
        codes: {
            NO_ERROR: 'no errors',
        },
        messages: {
            TOKEN_ERROR: 'jwt verification failed',
            TOKEN_EXPIRED: 'Token is expired',
        },
        errors: {
            INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
        },
    };

    const error = validateError(errors[0], options);
    return error;
}
