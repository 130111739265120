import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Tooltip,
} from '@material-ui/core';

import {
    // CreateOutlined as CreateIcon,
    VisibilityOutlined as LiveIcon,
    VisibilityOffOutlined as DraftIcon,
    DeleteOutlined as DeleteIcon,
} from '@material-ui/icons';

import { Button } from '../../../components/Wrappers';
import Dialog from '../../dialog/Dialog';

import useStyles from './styles';

function DraftLiveBar (props) {
    const {
        onLive,
        onDraft,
        onDelete,
        deleteDialogTitle,
        deleteDialogMessage,
        infoText,
    } = props;

    const classes = useStyles();
    const [deleteOpen, setDeleteOpen] = useState(false);

    return (
        <React.Fragment>
            <Grid className={classes.controlRoot}>
                <Grid className={classes.infoText}>
                    { infoText }
                </Grid>
                <Grid
                    container
                    className={classes.buttons}
                    >
                    <Grid className={classes.buttonSpace}>
                        <Tooltip title={'Set live'}>
                            <Button
                                variant={'contained'}
                                color={'success'}
                                className={classes.iconButton}
                                onClick={() => {
                                    onLive();
                                }}
                            >
                                <LiveIcon />&nbsp; Set Live
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid className={classes.buttonSpace}>
                        <Tooltip title={'Set draft'}>
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                className={classes.iconButton}
                                onClick={() => {
                                    onDraft();
                                }}
                            >
                                <DraftIcon /> &nbsp; Set Draft
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid >
                        <Tooltip title={'Delete selected row(s)'}>
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                style={{ backgroundColor: '#AB003C' }}
                                className={classes.iconButton}
                                onClick={() => {
                                    setDeleteOpen(true);
                                }}
                                >
                                    <DeleteIcon className={classes.deleteIcon} />&nbsp; Delete
                            </Button>
                        </Tooltip>
                    </Grid>

                </Grid>
            </Grid>

            <Dialog
                openDialog={deleteOpen}

                handleAgree={() => {
                    onDelete();
                    setDeleteOpen(false);
                }}

                closeModal={() => {
                   setDeleteOpen(false);
                }}

                title={deleteDialogTitle}
                dialogContextText={deleteDialogMessage}
            />
        </React.Fragment>
    );
}

DraftLiveBar.propTypes = {
    onLive: PropTypes.func,
    onDraft: PropTypes.func,
    onDelete: PropTypes.func,
    deleteDialogTitle: PropTypes.string,
    deleteDialogMessage: PropTypes.string,
    infoText: PropTypes.string,
};

DraftLiveBar.defaultProps = {
    onLive: () => {},
    onDraft: () => {},
    onDelete: () => {},
    deleteDialogTitle: 'Delete selected items',
    deleteDialogMessage: 'Are you sure you want to delete the selected item(s)?',
    infoText: '',
};

export default DraftLiveBar;
