import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
// import QRCode from 'qrcode.react';
// import { QRCode } from 'react-qrcode-logo';
import QRCodeStyling from 'qr-code-styling';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import { Button, Link } from '../../../components/Wrappers';
import { ecalLogo } from '../../../commons/images/brandImage/ecalLogo';
import Dialog from '../../../commons/dialog/Dialog';
import { useStyles } from './styles';
import {
    navigateBlank,
    // downloadGeneratedData,
    // resizeBy,
    // qrCodeWithLogo,
} from './utils';

const styledQrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    image: ecalLogo,
    dotsOptions: {
      color: '#0c0644',
      type: 'rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 4,
    },
    cornersSquareOptions: {
      color: '#0c0644',
    },
  });
function QRCodeDialog (props) {
    const {
        onClose,
        open,
        qrCode,
    } = props;

    const classes = useStyles();
    // const [url, setUrl] = useState('');
    // const [fileExt, setFileExt] = useState('png');
    const ref = useRef();
    function renderQRCode () {
        function append () {
            const codeString = qrCode;
            const div = document.getElementById('buttonItemQRCodeBox');
            if (div) {
                styledQrCode.append(div);
                styledQrCode.update({
                    data: codeString.replace(/['"]+/g, ''),
                });
            } else {
                setTimeout(append, 1000);
            }
        }
        setTimeout(append, 1000);
    }

    useEffect(() => {
        renderQRCode();
    }, []);

    useEffect(() => {
        renderQRCode();
    }, [qrCode, open]);

    const onDownloadClick = () => {
        styledQrCode.download();
    };

    return (<Dialog
        openDialog={open}
        buttonSecondaryFlag={false}
        buttonPrimary={'Close'}
        buttonPrimaryVariant={'outlined'}
        buttonPrimaryColor={'normal'}
        handleAgree={() => {
            onClose();
        }}

        closeModal={() => {
            onClose(false);
        }}
        title={'QR Code'}
        dialogContextText={''}
        className={classes.dialogContent}
      >
          <Grid className={classes.dialogContent}>

              <Grid id='buttonItemQRCodeBox' ref={ref} className={classNames([classes.dialogSection, classes.bottomSpace])}>

              </Grid>

              <Grid className={classNames([classes.dialogSection, classes.bottomSpace])}>
                <Button
                    onClick= { (e) => {
                        e.preventDefault();
                        navigateBlank(qrCode);
                    }}
                    variant={'link'}
                    color={'primary'}
                >
                    <Link className={classes.qrCodeLink} > {qrCode} </Link>
                </Button>
              </Grid>

              <Grid className={classNames([classes.dialogSection, classes.bottomSpace])}>
                <Button
                    onClick= { (e) => {
                    //    download();
                    onDownloadClick();
                    }}
                    variant={'contained'}
                    color={'success'}
                >
                    Download High Res QR Code
                </Button>
              </Grid>

          </Grid>
    </Dialog>);
}

QRCodeDialog.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    qrCode: PropTypes.string,
};

QRCodeDialog.defaultProps = {
    onClose: () => {},
    open: false,
    qrCode: '',
};

export default QRCodeDialog;
