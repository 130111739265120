import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Validations, { validate } from '../../../utils/validations';
import useSuperAdminPublisher from '../../superadminProfile/useSuperAdminPublisher';

const errorMessages = {

    email: {
        required: 'Email is required.',
        minLength: 'Email mininum length is 5',
        maxLength: 'Email maximum length is 256',
        pattern: 'Email format is incorrect, please follow email format john.jacob@domain.com',
        publisherEmail: 'This email has been used as publisher account',
    },

    password: {
        required: 'Password is required',
        minLength: 'Password mininum length is 8',
        maxLength: 'Password maximum length is 20',
        pattern: 'Password needs one or more uppercase letter, special character and number',
    },
};

function validateEmail ({ validationsData, value, touched }) {
    if (!touched) {
        return '';
    }
    return validate({
        rules: { ...validationsData.email },
        messages: errorMessages.email,
        value: value,
    });
}

function validatePassword ({ value, touched }) {
    if (!touched) {
        return '';
    }
    return validate({
        rules: { ...Validations.password },
        messages: errorMessages.password,
        value: value,
    });
}

function useUserValidation (props) {
    const {
        input,
    } = props;

    const {
        publisher,
    } = useSuperAdminPublisher();

    const [_input, setInput] = useState(input);
    const [touched, setTouched] = useState({});
    const [fieldsValid, setFieldsValid] = useState(false);

    const [inputError, setInputError] = useState({
        email: '',
        password: '',
    });

    const fields = ['email', 'password'];

    function validateByChange (validationsData) {
        if (isEmpty(touched)) {
            return;
        }

        const err = {
            email: validateEmail({
                validationsData: validationsData,
                value: _input.email,
                touched: touched.email,
            }),
            password: validatePassword({
                value: _input.password,
                touched: touched.password,
            }),
        };

        setInputError(err);
        checkValidity(err);
    }

    function checkValidity (err) {
        let valid = true;
        for (let i = 0; i < fields.length; i++) {
            const fld = fields[i];
            if (!isEmpty(err[fld])) {
                valid = false;
                break;
            }
        }
        setFieldsValid(valid);
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in _input) {
            if (_input[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setInput(input);
    }, [input]);

    useEffect(() => {
        if (Object.entries(touched).length > 0) {
            validateByChange({
                ...Validations,
                email: {
                    minLength: Validations.email.minLength,
                    maxLength: Validations.email.maxLength,
                    pattern: Validations.email.pattern,
                    required: Validations.email.required,
                    publisherEmail: publisher.email,
                },
            });
        }
    }, [_input]);

    return {
        inputError,
        fieldsValid,
    };
}

export default useUserValidation;
