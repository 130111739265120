export const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'ecal',
    filter: ['has', 'count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#00A9E0', 20, '#EEB927', 25, '#E87722'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 25, 28, 30, 32],
    },
  };

  export const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'ecal',
    filter: ['has', 'count'],
    layout: {
      'text-field': '{count}',
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 12,
    },
  };

  export const unclusteredCountPointLayer = {
    id: 'unclustered-count',
    type: 'symbol',
    source: 'ecal',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'text-field': '{count}',
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 12,
    },
  };

  export const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'ecal',
    filter: ['!', ['has', 'point_count']],
    // layout: {
    //   'text-field': '{count}',
    //   'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    //   'text-size': 12
    // },
    paint: {
      'circle-color': '#78BE21',
      'circle-radius': 10,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
    },
  };
