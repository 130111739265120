import { makeStyles } from '@material-ui/core/styles';

// import EcalLogoImage from '../../commons/images/brandImage/ECALSuperstar.jpg';
import EcalBackground from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';
import EcalLogo from '../../commons/images/brandImage/ECAL_Logo.svg';

const styles = (theme) => ({

    page: ({ whiteLabelSettings }) => {
      function bgImage () {
        if (whiteLabelSettings && whiteLabelSettings?.background) {
          return whiteLabelSettings?.background;
        }
        return EcalBackground;
      }

      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundImage: `url(${bgImage()})`,
        backgroundRepeat: 'no-repeat',
      };
    },

    content: ({ whiteLabelSettings }) => {
      function bgColor () {
        if (whiteLabelSettings.labelName !== 'ecal') {
          return '#00000099';
        }
        return 'transparent';
      }

      return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '240px',
        height: '120px',
        overflow: 'hidden',
        backgroundColor: bgColor(),
        borderRadius: '8px',
        padding: '16px',
      };
    },

    logoImageContainer: {
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    logoImage: ({ whiteLabelSettings }) => {
      function logo () {
        if (whiteLabelSettings && whiteLabelSettings?.logo) {
          return whiteLabelSettings.logo;
        }
        return EcalLogo;
      }

      return {
        backgroundSize: '150px',
        backgroundRepeat: 'no-repeat',
        width: '150px',
        height: '30px',
        backgroundImage: `url(${logo()})`,
      };
    },

    text: {
      color: '#FFFFFF',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
