import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TableSkeleton = () => {
    return (
        <TableBody
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            // height={300}
            width={500}
            pt={0.5}>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
                <TableCell><Skeleton width="100%" /></TableCell>
            </TableRow>

        </TableBody>
    );
};

export default TableSkeleton;
