import React from 'react';
import PropTypes from 'prop-types';
import {
    Menu,
    MenuItem,
} from '@material-ui/core';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';

function CategoryMenu (props) {
    const {
        anchorEl,
        open,
        onAdd,
        onDelete,
        onCloseMenu,
        isChild,
    } = props;

    return (
        <Menu
            id="actions-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                console.log('on close...');
                onCloseMenu();
            }}
            disableAutoFocusItem
            style={{ padding: 0, margin: 0 }}
        >
            <MenuItem
                    onClick={() => {
                        if (typeof onAdd === 'function') {
                            onAdd();
                        }
                    }}
                    disabled={isChild}
                >
                <Typography>Add</Typography>
            </MenuItem>
            <MenuItem
                  onClick={() => {
                    if (typeof onDelete === 'function') {
                        onDelete();
                    }
                }}
                disabled={isChild}
            >
                <Typography>Delete</Typography>
            </MenuItem>
        </Menu>
    );
}

CategoryMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onCloseMenu: PropTypes.func,
};

CategoryMenu.defaultProps = {
    open: false,
    anchorEl: null,
    onAdd: () => {},
    onDelete: () => {},
    onCloseMenu: () => {},
};

export default CategoryMenu;
