import { useEffect } from 'react';

import {
    useUserStore,
} from '../../store/models';

function useStoreModuleDependency (props) {
    const {
        initStore,
    } = props;

    const user = useUserStore();
    // const [ready, setReady] = useState(false);
    document.isModuleReady = false;

    function waitForDependency () {
        function initAPICall () {
            if (!document.isModuleReady) {
                delay();
                return;
            }
            if (typeof initStore === 'function') {
                console.log('');
                console.log('useStoreModuleDependency() Initializing Module Store...');
                initStore();
            }
        }

        function delay () {
            setTimeout(initAPICall, 200);
        }
        delay();
    }

    useEffect(() => {
        if (user.userInfoLoaded) {
            if (typeof initStore === 'function') {
                initStore();
            }
        } else {
            document.isModuleReady = false;
            waitForDependency();
        }
    }, [user.userInfoLoaded]);

    useEffect(() => {
        if (user.userInfoLoaded) {
            document.isModuleReady = true;
        } else {
            document.isModuleReady = false;
        }
    }, [user.observed.userInfoLoaded]);

    return {};
}

export default useStoreModuleDependency;
