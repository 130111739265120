
import React, { useState } from 'react';
import UpgradeDialog from './dialog';

function useUpgradeAddOnDialog (props = {}) {
    const {
        open: defaultOpen = false,
    } = props;

    const [open, setOpen] = useState(defaultOpen);

    function renderDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <UpgradeDialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
            />
        );
    }

    function openDialog () {
        setOpen(true);
    };

    return {
        renderDialog,
        openDialog,
    };
}

export default useUpgradeAddOnDialog;
