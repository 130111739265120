import { cloneDeep } from 'lodash';
/*
    data Structure

        checked: ['fixture', 'au', 'sg', 'in', 'gb'],
        expanded: ['fixture', 'team'],
        nodes: [
                {
                    value: 'fixture',
                    label: 'Fixture',
                    id: '123',
                    children: [
                        { value: 'au', label: 'AU', id: '143' },
                        { value: 'sg', label: 'SG', id: '1543' },
                        { value: 'in', label: 'IN', id: '153' },
                    ],
                },
                {
                    value: 'team',
                    label: 'Team',
                    id: '13',
                    children: [
                        { value: 'us', label: 'US', id: '12' },
                        { value: 'gb', label: 'GB', id: '124' },
                    ],
                },
        ],

*/
/* source is getRootCategories.edges */

export function hasChildren (parentId, nodes) {
    let hasKids = false;
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.value === parentId) {
            if (node.children.length > 0) {
                hasKids = true;
            }
        } else {
            hasKids = hasChildren(parentId, node.children);
        }

        if (hasKids) {
            break;
        }
    }
    return hasKids;
}

export function treeCheck (value) {
    this.categoryChecked = value;
    this.categoryData.checked = value;
    console.log('checked: ', this.categoryChecked);
}

export function treeExpand (expanded, node) {
    console.log('');
    console.log('treeAction.treeExpand()', expanded);
    console.log('Node: ', node);
    if (node.isParent || node.children.length >= 0) {
        this.categoryExpanded = cloneDeep(expanded);
        this.categoryData.expanded = cloneDeep(expanded);
    }

    /*
    if (hasChildren(node.value, this.categoryNodes)) {
        console.log('has children and will expand: ', node);
        this.categoryExpanded = expanded;
        this.categoryData.expanded = expanded;
    } else {
        console.log('Will load Children of node', node);
        this.getCategoriesByFilter({ parent: node.value });
    }
    */
}

export function treeDeleteBranch (branchValue) {
    function recursiveDelete (nodes, target) {
        let foundAndDeleted = false;
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node.value === target) {
                console.log('found the target to be deleted: ', target);
                console.log(node);
                nodes.splice(i, 1);
                foundAndDeleted = true;
                break;
            }

            if (!node.children || node.children.length <= 0) {
                continue;
            }
            const { nodes: children, foundAndDeleted: found } = recursiveDelete(node.children, target);
            if (found) {
                foundAndDeleted = found;
                node.children = children;
                break;
            }
        }
        return { nodes, foundAndDeleted };
    }
    const {
        nodes,
    } = recursiveDelete(this.categoryNodes, branchValue);
    this.categoryNodes = nodes;
    this.categoryData.nodes = cloneDeep(this.categoryNodes);
}

/* source is an edge result */
export function processRoot (source) {
    let newNodes = sourceToTreeNodes(source);
    if (newNodes === null) {
        newNodes = [];
    }
    this.categoryNodes = newNodes;
    this.categoryExpanded = [];

    if (this.mode !== 'edit') {
        this.categoryChecked = [];
        this.categoryData.checked = [];
    }

    this.categoryData.nodes = newNodes;
    this.categoryData.nodesFiltered = newNodes;
    this.categoryNodesFiltered = newNodes;

    this.categoryData.expanded = [];
}

export function updateRoot (source) {
    console.log('UpdateRoot() ', source);
    if (source.length > 0) {
        const node = treeNode(source[0].node);
        const newRoot = [
            node,
            ...this.categoryNodes,
        ];
        console.log('updated Root: ', newRoot);

        /* this is not necessary we only need one node to expose to the UI */
        this.categoryNodes = newRoot;
        this.categoryNodesFiltered = newRoot;
        this.categoryData.nodes = cloneDeep(newRoot); /* remove this later , instead used uuid keys */
        this.categoryData.nodesFiltered = cloneDeep(newRoot);
    }

    /*
    for (let i = 0; i < source.length; i++) {
        const node = treeNode(source[i].node);
        for (let j = 0; j < this.categoryNodes.length; j++) {
            const node2 = this.categoryNodes[j];
            if (node.value === node2.value && node2.hasChildren) {
               node.children = node2.children;
               break;
            }
        }
        newRoot.push(node);
    }
    */
}

export function treeUpdateChildren (parentId, source) {
    const children = sourceToTreeNodes(source);
    const nodes = this.categoryNodes;

    updateParentChildren(parentId, nodes, children);

    this.categoryNodes = nodes;
    this.categoryNodesFiltered = nodes;
    this.categoryData.nodes = cloneDeep(this.categoryNodes);

    if (!this.categoryExpanded.includes(parentId)) {
        this.categoryExpanded = [
            ...this.categoryExpanded,
            parentId,
        ];
        this.categoryData.expanded = [
            ...this.categoryExpanded,
        ];
    }
}

export function updateParentChildren (parentId, nodes, children) {
    if (!nodes) return;
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.value === parentId) {
            nodes[i] = {
                ...node,
                children,
            };
            return;
        } else {
            if (node.children) {
                updateParentChildren(parentId, node.children, children);
            }
        }
    }
}

export function sourceToTreeNodes (source) {
    if (!source || source.length <= 0) return null;
    const nodes = [];
    for (let i = 0; i < source.length; i++) {
        const node = treeNode(source[i].node);
        if (node !== null) {
            nodes.push(node);
        }
    }
    return nodes;
}

export function treeNode (dataNode) {
    if (!dataNode) return null;
    return {
        value: dataNode.id,
        label: dataNode.name,
        id: dataNode.id,
        path: dataNode.path,
        root: dataNode.rootId,
        parent: dataNode.parentId,
        hasChildren: dataNode.hasChildren,
        children: dataNode.hasChildren ? [] : null,
    };
}

export function onFilterChange (e) {
    this.filterText = e.target.value;
}

export function filterTree () {
    // Reset nodes back to unfiltered state
      // const nodes = this.categoryNodes;
      const filterText = this.filterText;
      const self = this;
      function getAllValuesFromNodes (nodes) {
        const values = [];
        if (nodes) {
    for (const n of nodes) {
            values.push(n.value);
            if (n.children) {
              values.push(...getAllValuesFromNodes(n.children, false));
            }
          }
    }
        return values;
      };
    function filterNodes (filtered, node) {
      let children;
      if (node.children) {
        children = node.children.reduce(filterNodes, []);
      }
      if (
        node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1 ||
        (children && children.length)
      ) {
        let expanded = [];
        expanded = [node.value, ...getAllValuesFromNodes(children)];
        // this.setState({ expanded });
        self.categoryData.expanded = [...self.categoryData.expanded, ...expanded];
        self.categoryExpanded = [...self.categoryExpanded, ...expanded];
            // getAllValuesFromNodes(children);
        if (node.children) {
          filtered.push({ ...node, children });
        } else {
          filtered.push(node);
        }
      }
      return filtered;
    }
    if (this.filterText === '') {
        this.categoryData.nodesFiltered = cloneDeep(this.categoryData.nodes);
        this.categoryNodesFiltered = cloneDeep(this.categoryNodes);
        // this.categoryExpanded = cloneDeep(this.categoryData.expanded);
    } else {
    const nodesFiltered = () => (this.categoryNodes.reduce(filterNodes, []));
    const nodesAfterFilter = nodesFiltered();
    this.categoryData.nodesFiltered = nodesAfterFilter;
    this.categoryNodesFiltered = nodesAfterFilter;
    // this.categoryExpanded = cloneDeep(this.categoryData.expanded);
    }
  }
