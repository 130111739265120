import React from 'react';
import { observer } from 'mobx-react';

import { useHistory } from 'react-router';

import {
  Grid,
  Box,
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Select from '../../../commons/select/Select';
// import ErrorContainer from '../../../commons/errorContainer/Error';

import ProgressBar from '../../../commons/progress/Progress';
import { Button, Typography, Link } from '../../../components/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';

import language from '../../../commons/util/Language.json';
import labelTypes from '../../../commons/util/labelTypes.json';
import { useStyles } from './styles';
import useModuleInput from './useModuleInput';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';
import { useTouchContext } from '../../../app/context/TouchContext';
// import Checkbox from '../../../commons/Checkbox';

const InputForm = (props) => {
    const {
        location,
    } = props;

    const {
        isLoading,
        setIsLoading,
        progress,
        input,
        updateInput,
        upsert,
        errors,
        saveDisabled,
        setSaveDisabled,
    } = useModuleInput({ location });

    const history = useHistory();
    const classes = useStyles();
    const { updateTouches } = useTouchContext('useCustomLabelErrors');

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({ errors });
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Custom Labels
                                </Typography>
                                { progress
                                    ? <ProgressBar />
                                    : <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={'100%'}>

                                        <Select
                                            id='name'
                                            label= 'Label Type'
                                            name= 'name'
                                            value={input.label}
                                            labelHeading= 'Label Type'
                                            placeHolder= 'Choose your label type'
                                            handleChange={(e, data) => {
                                                updateInput({
                                                    ...input,
                                                    label: data.props.value,
                                                });
                                                setSaveDisabled(false);
                                            }}

                                            dataForDropdown={labelTypes}
                                            /*
                                            helperText={
                                                errors.label || 'Select a language for this custom-label'
                                            }
                                            error={errors.label}
                                            */
                                        />

                                        <Select
                                            id='language'
                                            label= 'language'
                                            name= 'language'
                                            value={input.language}
                                            labelHeading= 'Language'
                                            placeHolder= 'Please select language'
                                            handleChange={(e, data) => {
                                                updateInput({
                                                    ...input,
                                                    language: data.props.value,
                                                });
                                                setSaveDisabled(false);
                                            }}

                                            dataForDropdown={language}
                                            helperText={
                                               'Select language for your label'
                                            }
                                        />

                                        <TextField
                                            inputProps={{
                                                className: classes.multilineInput,
                                            }}
                                            id="message"
                                            label="Label text"
                                            multiline
                                            rows="4"
                                            onChange={(e) => {
                                                updateTouches({ text: true });
                                                updateInput({
                                                    ...input,
                                                    text: e.target.value,
                                                });
                                            }}
                                            name="message"
                                            value={input.text}
                                            variant="outlined"
                                            style={{ marginBottom: 32 }}
                                            helperText={ errors.text || 'Enter text for this label'}
                                            error={errors.text}
                                        />

                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            ecal-label-id={'labelaction'}
                                        >
                                                <Button
                                                    onClick= { (e) => {
                                                        e.preventDefault();
                                                        history.push('/admin/marketing/custom-labels');
                                                    }}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                >
                                                    <Link
                                                        color='#fff'>
                                                    close
                                                    </Link>
                                                </Button>

                                                <ButtonProgress
                                                    disabled={saveDisabled}
                                                    showProgress={isLoading}
                                                    label="Save"
                                                    onClick={(e) => {
                                                        setIsLoading(true);
                                                        upsert();
                                                    }}
                                                />
                                        </Box>

                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>
    );
};

export default observer(InputForm);
