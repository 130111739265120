import React from 'react';
/*
import {
    Grid,
    Box,
    TextField,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
*/
import PropTypes from 'prop-types';
import useButtonDesignData from './useButtonDesignData';
// import SquareButtonImg from './SquareButtonSVG';
import ButtonDesignInputs from './ButtonDesignInputs';
import AdDesignInput from './AdDesignInput';
const ButtonDesign = (props) => {
    const {
        onDataChange,
        dataSource,
        updateSavable,
        updateOptions,
        progress,
        saveDisabled,
        onSave,
        location,
        hasError,
        hasInputError,
        errors,
        mode,
    } = props;

    const {
        buttonDesignData,
        updateDesignData,
        displayType,
        uploadAdsLogo,
        adsLogo,
        updateTextMatrix,
    } = useButtonDesignData({
        onDataChange,
        dataSource,
        updateSavable,
        updateOptions,
        location,
    });

    return (
       <React.Fragment>
           {displayType === 'button' && (
                <ButtonDesignInputs
                        mode={mode}
                        progress={progress}
                        saveDisabled={saveDisabled}
                        onSave={onSave}
                        buttonDesignData={buttonDesignData}
                        updateDesignData={updateDesignData}
                        errors={errors}
                        hasError={hasError}
                        hasInputError={hasInputError}
                        updateTextMatrix={updateTextMatrix}
                />
           )}

            {displayType === 'ad' && (
                <AdDesignInput
                    uploadAdsLogo={uploadAdsLogo}
                    adsLogo={adsLogo}
                    progress={progress}
                    saveDisabled={saveDisabled}
                    onSave={onSave}
                    onDataChange={onDataChange}
                    dataSource={dataSource}
                    updateSavable={updateSavable}
                />
           )}
       </React.Fragment>
    );
};

ButtonDesign.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    updateOptions: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    hasError: PropTypes.bool,
    hasInputError: PropTypes.bool,
    errors: PropTypes.object,
};

ButtonDesign.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
    hasError: false,
    hasInputError: false,
    errors: {},
    updateOptions: () => {},
};

export default ButtonDesign;
