export const Options = {
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        legend: {
            show: false,
        },
        chart: {
            id: 'NewSubscriptions',
            stacked: false,
            toolbar: {
                show: true,
            },
        },
        tooltip: {
          shared: false,
          y: {
            title: {
              formatter: () => 'New subs.',
            },
          },
        },
        colors: ['#90CA68', '#fff'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.6,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: [],
            },
        },
        options: {
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff'],
                },
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff'],
            },

            xaxis: {
                categories: [2013, 2014, 2015, 2016, 2017, 2018, 2019],
            },
            // colors: ['rgba(206, 230, 180, 1)', 'rgba(206, 230, 180, 0.3'],
        },
        states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0,
              },
          },
          hover: {
              filter: {
                  type: 'lighten',
                  value: -0.4,
              },
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'lighten',
                  value: -0.6,
              },
          },
      },
};
