import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';

function WidgetPreview (props) {
    const {
        widgetRef,
    } = props;

    const classes = useStyles();

    function renderWhenReady () {
        if (window.EcalEventWidget) {
            window.EcalEventWidget.allowSync = false;
            window.EcalEventWidget.renderWidget({
                mode: 'test',
                selector: '.event-display',
                widgetType: 'page',
                apiKey: '',
                widgetId: '',
                deviceInfo: '',
            });
        } else {
            setTimeout(renderWhenReady, 500);
        }
    }

    useEffect(() => {
        renderWhenReady();
    }, [widgetRef.current]);

    useEffect(() => {
        return () => {
        };
    }, []);
    /*
    <iframe class="demo-frame" style="width:100%;height:100%;margin:auto;box-shadow: 0 0 1px #CCC;overflow: hidden" src="//testing-display.ecal.com/epw.html?_=1634626166&amp;preview=true"></iframe>
    */
    return (
        <Grid className={classes.fullSize}>
             <div ref={widgetRef} className='event-display'> </div>
        </Grid>
    );
}

WidgetPreview.propTypes = {
    widgetRef: PropTypes.any,
};

WidgetPreview.defaultProps = {
   widgetRef: null,
};

export default WidgetPreview;
