import { v4 as uuid } from 'uuid';
import { logInfo } from '../../../utils';

export function updatePageInfoFromSearch (data) {
    if (data && data.searchPublishers) {
        this.publishersPageInfo = recomputePageInfo(this.publishersPageInfo, data.searchPublishers.pageInfo, data.searchPublishers.totalCount);
        this.observed.publishersPageInfo = uuid();
    }
}

export async function paginate (page) {
    this.publishersPageInfo = updatePageInfoNumber(this.publishersPageInfo, page);
    this.observed.publishersPageInfo = uuid();
    logInfo('paginate() -> ', this.recentSearch);
    if (this?.superAdmin?.role === 'group-admin') {
        await this.searchPublishers({ publisherIds: [this?.superAdmin?.publisherId] });
    } else {
        await this.searchPublishers({ name: this.recentSearch });
    }
    this.env.closeProgress();
}

export function resetPagingation () {
    this.publishersPageInfo = {
        showPagingation: false,
        page: 0,
        itemsPerPage: 10,
        totalCount: 0,
    };
    this.observed.publishersPageInfo = uuid();
}

function recomputePageInfo (pageInfo, dataPageInfo, totalCount = 10) {
    const newPageInfo = {
        ...pageInfo,
        showPagingation: true,
    };
    newPageInfo.totalCount = totalCount; // Number(dataPageInfo?.endCursor);

    return newPageInfo;
}

function updatePageInfoNumber (pageInfo, newPage) {
    const newPageInfo = {
        ...pageInfo,
        page: newPage,
    };
    return newPageInfo;
}
