import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
    Grid,
    Tab,
} from '@material-ui/core';

import {
  facebookLink,
  instructionIframeSrc,
} from './utils';
import Tabs from '../../../commons/tabs';
import Dialog from '../../../commons/dialog/Dialog';
import { useStyles } from './styles';

import ButtonCodeApp from './buttonCodeApp';
import ButtonCodeSocial from './buttonCodeSocial';
import ButtonCodeWebsite from './buttonCodeWebsite';

function ButtonCodeDialog (props) {
    const {
        onClose,
        open,
        socialMediaLink,
        widgetId,
        apiKey,
        options,
        buttonData,
        title,
    } = props;

    let dialogTitle = title;

    if (!dialogTitle) {
        dialogTitle = 'Publish Your Button';
    }

    const classes = useStyles();

    const [tab, setTab] = useState(0);

    return (<Dialog
        containerMaxWidth={'640px'}
        className={classes.buttonCodeDialog}
        containerClass={classes.dialogButtonCodeContainer}
        openDialog={open}
        buttonSecondaryFlag={false}
        buttonPrimary={'Close'}
        buttonPrimaryVariant={'outlined'}
        buttonPrimaryColor={'normal'}
        handleAgree={() => {
            onClose();
            setTab(0);
        }}
        title={dialogTitle}
        dialogContextText={''}
      >
          <Grid className={classes.dialogContent}>
                <Tabs
                    onTabChange={(newTab) => {
                        setTab(newTab);
                    }}
                >
                    <Tab
                        label="Website"
                        data-intercom-target="button-code-tab-website"
                    />
                    <Tab
                        label="App"
                        data-intercom-target="button-code-tab-app"
                    />
                    <Tab
                        label="Email / Social Media"
                        data-intercom-target="button-code-tab-social"
                    />
                </Tabs>

                {tab === 0 && (
                    <Grid className={classNames([classes.dialogSection, classes.dialogSectionSpace])}>
                        <ButtonCodeWebsite
                            widgetId={widgetId}
                            apiKey={apiKey}
                            options={options}
                            buttonData={buttonData}
                        />
                    </Grid>
                )}

                {tab === 1 && (
                    <Grid className={classNames([classes.dialogSection, classes.dialogSectionSpace])}>
                        <ButtonCodeApp
                          socialMediaLink={socialMediaLink}
                          instructionIframeSrc={instructionIframeSrc}
                        />
                    </Grid>
                )}
                {tab === 2 && (
                  <Grid className={classNames([classes.dialogSection, classes.dialogSectionSpace])}>
                      <ButtonCodeSocial
                        instructionWithLink
                        socialMediaLink={socialMediaLink}
                        facebookLink={facebookLink}
                        />
                  </Grid>
                )}
          </Grid>
    </Dialog>);
}

ButtonCodeDialog.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    socialMediaLink: PropTypes.string,
    widgetId: PropTypes.string,
    apiKey: PropTypes.string,
    buttonData: PropTypes.object,
    options: PropTypes.object,
};

ButtonCodeDialog.defaultProps = {
    onClose: () => {},
    open: false,
    socialMediaLink: '',
    widgetId: '',
    apiKey: '',
    options: {
        showRoles: true,
        type: 'button', // values : 'button', 'ad, 'eventDisplay'
    },
    buttonData: {},
};

export default ButtonCodeDialog;

/*

 <Grid className={classNames([classes.dialogSection, classes.dialogSectionSpace])}>
                    <Button
                        onClick= { (e) => {
                        }}
                        variant={'contained'}
                        color={'success'}
                    >
                        Download Hig Res QR Code
                    </Button>
                </Grid>

*/
