const industries = [
    {
        value: 'Arts & Culture',
        label: 'Arts & Culture',
        code: 'Arts00',
    },
    {
        value: 'Banking & Finance',
        label: 'Banking & Finance',
        code: 'Banking00',
    },
    {
        value: 'Community Sports',
        label: 'Community Sports',
        code: 'Community00',
    },
    {
        value: 'Conferences & Exhibitions',
        label: 'Conferences & Exhibitions',
        code: 'Conferences00',
    },
    {
        value: 'Corporate',
        label: 'Corporate',
        code: 'Corporate00',
    },
    {
        value: 'Education',
        label: 'Education',
        code: 'Education00',
    },
    {
        value: 'Festivals',
        label: 'Festivals',
        code: 'Festivals00',
    },
    {
        value: 'Gaming & Betting',
        label: 'Gaming & Betting',
        code: 'Gaming00',
    },
    {
        value: 'Government',
        label: 'Government',
        code: 'Government00',
    },
    {
        value: 'Health & Fitness',
        label: 'Health & Fitness',
        code: 'Health00',
    },
    {
        value: 'Media & Entertainment',
        label: 'Media & Entertainment',
        code: 'Media00',
    },
    {
        value: 'Member Clubs',
        label: 'Member Clubs',
        code: 'Member00',
    },
    {
        value: 'Professional Sports',
        label: 'Professional Sports',
        code: 'Professional00',
    },
    {
        value: 'Religion',
        label: 'Religion',
        code: 'Religion00',
    },
    {
        value: 'Retail',
        label: 'Retail',
        code: 'Retail00',
    },
    {
        value: 'Stadiums, Arenas & Venues',
        label: 'Stadiums, Arenas & Venues',
        code: 'Stadiums00',
    },
    {
        value: 'Telecommunications',
        label: 'Telecommunications',
        code: 'Telecomms00',
    },
    {
        value: 'Ticketing',
        label: 'Ticketing',
        code: 'Ticketing00',
    },
    {
        value: 'Tourism',
        label: 'Tourism',
        code: 'Tourism00',
    },
    {
        value: 'Travel',
        label: 'Travel',
        code: 'Travel00',
    },
    {
        value: 'Other',
        label: 'Other',
        code: 'Other00',
    },
];

export default industries;
