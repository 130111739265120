import { useEffect, useState } from 'react';
import { set, isEmpty } from 'lodash';
// import { useHistory } from 'react-router';
import {
    useEventsStore,
    useUserStore,
    useSchedulesStore,
} from '../../../store/models';
import { isNonEmptyLinkValid } from '../../../utils/validations';

import useEventErrors from '../../../store/models/event/errors/useEventErrors';
import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import { defaultEvent, defaultEventData } from './eventData';
import { logInfo } from '../../../utils';

const EDIT = 'Edit';
const ADD = 'Add';

function useEventsInput (props) {
    const {
        location,
        hasValidationFieldError,
    } = props;

    // const history = useHistory();
    const userStore = useUserStore();
    const schedulesStore = useSchedulesStore();

    function decodeStateSource () {
        const d = defaultEventData();
        console.log('decodeStateSource() Decode State Source: ', d);
        return {
            ...d,
            time: {
                ...d.time,
                timezone: userStore.publisher.timezone,
            },
        };
    }

    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return EDIT;
        }
        return ADD;
    }
    const [mode] = useState(getMode());

    const [input, setInputState] = useState({ ...defaultEvent });

    const [hasError, setHasError] = useState(false);
    const [errorTitle, setErrorTitle] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [isManagedByFeed, setIsManagedByFeed] = useState(false);
    const [hasEventId, setHasEventId] = useState(false);
    const [referenceName, setReferenceName] = useState('');
    const [eventId, setEventId] = useState('');
    const [isManagedByChild, setIsManagedByChild] = useState(false);

    const eventsStore = useEventsStore();
    const [localErrors, setLocalErrors] = useState({});

    const [saveDisabled, setSaveDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const {
        errors,
    } = useEventErrors({
        input,
    });

    useEffect(() => {
        eventsStore.eventIsLoading = false;
        setLocalErrors(errors);
    }, [errors]);

    useEffect(() => {
        logInfo('useEventsInput.useEffect(input) updated...: ', input);
    }, [input]);

    useEffect(() => {
        if (touched) {
            if (hasValidationFieldError) {
                setSaveDisabled(true);
            } else {
                setSaveDisabled(false);
            }
        }
    }, [hasValidationFieldError]);

    function setInput (data) {
        setTouched(true);
        setInputState(data);
    }

    function initStore () {
        schedulesStore.loadEditTarget();
        eventsStore.selectedScheduleId = schedulesStore.scheduleEditTarget.id;
        eventsStore.assets.eventBanner = '';
        eventsStore.loadEventsForEdit();

        if (mode === EDIT) {
            setPageTitle(EDIT);
            initWithEditData();
        } else if (eventsStore?.eventEditTarget?.duplicateEvent) {
            setPageTitle(ADD);
            initWithEditData();
        } else {
            setPageTitle(ADD);
            initWidthAddData();
        }
    }

    function initWidthAddData () {
        const scheduleData = schedulesStore.scheduleEditTarget.selectedData;
        const newInput = decodeStateSource();
        if (isEmpty(newInput.data)) {
            newInput.data = { ...defaultEvent.data };
        }
        newInput.data.banner = scheduleData.banner;
        setInputState({ ...newInput });
    }

    function initWithEditData () {
        if (eventsStore.eventEditTarget) {
            eventsStore.selectedScheduleId = eventsStore.eventEditTarget?.scheduleId;

            if (eventsStore.eventEditTarget?.selectedData?.alertData?.alert1 === '') {
                set(eventsStore.eventEditTarget, 'selectedData.alertData.alert1', '-');
            }

            if (eventsStore.eventEditTarget?.selectedData?.alertData?.alert2 === '') {
                set(eventsStore.eventEditTarget, 'selectedData.alertData.alert2', '-');
            }

            logInfo('initWithEditData -> ', eventsStore.eventEditTarget);

            const editData = {
                ...eventsStore.eventEditTarget.selectedData,
            };

            if (editData.data && isEmpty(editData.data.banner)) {
                const scheduleData = schedulesStore.scheduleEditTarget.selectedData;
                editData.data.banner = scheduleData.banner;
            }

            setInput({
                ...eventsStore.eventEditTarget.selectedData,
            });

            if (eventsStore.eventEditTarget.selectedData.data.reference) {
                setIsManagedByFeed(true);
                setReferenceName(eventsStore.eventEditTarget.selectedData.data.reference);
                // setEventId(eventsStore.eventEditTarget.selectedData.id);
            } else {
                setIsManagedByFeed(false);
            }

            if (eventsStore.eventEditTarget.selectedData.isChild) {
                setIsManagedByChild(true);
            } else {
                setIsManagedByChild(false);
            }

            if (eventsStore.eventEditTarget.selectedData.id) {
                setHasEventId(true);
                setEventId(eventsStore.eventEditTarget.selectedData.id);
            } else {
                setHasEventId(false);
            }
        }
    }

    function areLinksValid () {
        // input.quickLinks
        for (let i = 0; i < input.quickLinks.length; i++) {
            const ql = input.quickLinks[i];
            if (!isNonEmptyLinkValid(ql.url)) {
                return false;
            }
        }

        for (const i in input.socialData) {
            const url = input.socialData[i];
            if (!isNonEmptyLinkValid(url)) {
                return false;
            }
        }

        return true;
    }

    function sanitize (event) {
        logInfo('useEventInput.sanitize(): ', event);
        const newEvent = event;
        if (newEvent.alertData.alert1 === '-') {
            newEvent.alertData.alert1 = '';
        }

        if (newEvent.alertData.alert2 === '-') {
            newEvent.alertData.alert2 = '';
        }

        const scheduleData = schedulesStore.scheduleEditTarget.selectedData;
        if (newEvent.data.banner === scheduleData.banner) {
            // save empty when banner used is scheduleData...
            newEvent.data.banner = '';
        }
        return newEvent;
    }

    function updateEvents () {
        // do link validation here...
        if (!areLinksValid()) {
            setLocalErrors({
                title: 'Invalid links URL',
                message: 'Please check if quicklinks and social links URL are valid.',
            });
            return;
        }

        const event = sanitize({ ...input });
        eventsStore.upsertEvents(event);
    }

    useEffect(() => {
        logInfo('useEventInput()mounted.. will set input.');
        const decodedInput = decodeStateSource();
        console.log('returne decoded input: ', decodedInput);
        setInput(decodedInput);
        setHasError(false);
        setErrorTitle(false);
        setErrorMessage(setErrorMessage);
        setIsLoading(false);
        setProgress(false);
        return () => {
            console.log('useEventInput->will unmount');
        };
    }, []);

    useStoreModuleDependency({ initStore });

    return {
        hasError,
        errorTitle,
        errorMessage,
        isLoading,
        progress,
        pageTitle,
        errors: localErrors,

        input,
        setInput,
        setIsLoading,
        updateEvents,
        isManagedByFeed,
        isManagedByChild,
        hasEventId,
        referenceName,
        eventId,
        saveDisabled,
    };
}

export default useEventsInput;
