import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { logInfo } from '../utils';
import { ToastContainer } from 'react-toastify';

import { Close as CloseIcon } from '@material-ui/icons';
import useStyles from '../components/styles';

import { useUserStore, useEnvStore } from '../store/models';
// pages
import Login from '../pages/login';
import Publishers from '../pages/publishers';
import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import SuperAdminDashboard from '../pages/superadmin';
import SignUp from '../pages/signUp';
import OpenSignupSetup from '../pages/signUp/openSignupSetup';
import VerifyAccount from '../pages/signUp/verifyAccount';
import SignupBillingForm from '../pages/signUp/signupBillingForm';
import Error404 from '../pages/error404';
import Recaptcha from '../pages/login/recaptcha';
import PasswordChange from '../pages/login/passwordChange';
import { TouchProvider } from './context/TouchContext';
import Progress from '../components/Progress';
// import useGenericErrorDialog from '../store/models/error/useGenericErrorDialog';

function App () {
  const classes = useStyles();
  const userStore = useUserStore();
  const envStore = useEnvStore();

  // const { renderErrorDialog } = useGenericErrorDialog();

  function CloseButton ({ closeToast, className }) {
    return <CloseIcon className={className} onClick={closeToast} />;
  }

  function loadUser () {
    userStore.currentUser();
  }

  useEffect(() => {
    const url = window.location.href;

    if (url.indexOf('login') !== -1 || url.indexOf('signup') !== -1) {
        userStore.signOut();
       return;
    }

    setTimeout(loadUser, 500);
  }, []);

  useEffect(() => {
    logInfo('progress Enabled updated', envStore.progressEnabled);
    logInfo('progress Open updated', envStore.progressEnabled);
  }, [envStore.progressEnabled, envStore.progressOpen]);

  return (
    <TouchProvider>
    <React.Fragment>
       <BrowserRouter>
        <Switch>

          {
            /*
             * '/' and 'admin' must be redirected to something existing... right now it is pointed on the profile
             */
          }
          <Route exact path="/" render={() => <Redirect to="/login" />} />

          <Route
            exact
            path="/admin"
            render={() => <Redirect to="/admin/home/summary" />}
          />

          <Route path="/login" component={Login} />
          <Route path="/signUp" component={SignUp} />
          <Route path="/verifyAccount" component={VerifyAccount} />
          <Route path="/publishers" component={Publishers} />
          <Route path="/admin" component={Dashboard} />
          <Route path="/superadmin" component={SuperAdminDashboard} />
          <Route path="/setup" component={OpenSignupSetup} />
          <Route path="/setup-plan" component={SignupBillingForm} />
          <Route path="/home" component={Home} />
          <Route path="/captcha" component={Recaptcha} />
          <Route path="/changePassword" component={PasswordChange} />
          <Route component={Error404} />
        </Switch>
        </BrowserRouter>
      {
        /*
         * Do we need toastContainer for notifications?
         */
      }
      <ToastContainer
        className={classes.toastsContainer}
        autoClose={2000}
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
      >

      </ToastContainer>
      <Progress
        open={(envStore.progressEnabled && envStore.progressOpen)}
      />

    </React.Fragment>
    </TouchProvider>
  );
}

export default observer(App);
