
/*
 * left hand side are the data coming from the service
 * right hand side are the column names of the table
 */
export const mapping = {
    'name': 'eventName',
    'data.location': 'location',
    'data.details': 'eventDetails',

    'time.startDate': 'startDate',
    'time.startTime': 'startTime',
    'time.endDate': 'endDate',
    'time.endTime': 'endTime',
    'time.allDay': 'allDay',

    'alert.alert1': 'reminder',
    'alert.alert2': 'secondReminder',

    'quicklinks.0.name': 'quickLinkName1',
    'quicklinks.0.url': 'quickLinkURL1',

    'quicklinks.1.name': 'quickLinkName2',
    'quicklinks.1.url': 'quickLinkURL2',

    'quicklinks.2.name': 'quickLinkName3',
    'quicklinks.2.url': 'quickLinkURL3',

    'quicklinks.3.name': 'quickLinkName4',
    'quicklinks.3.url': 'quickLinkURL4',

    'quicklinks.4.name': 'quickLinkName5',
    'quicklinks.4.url': 'quickLinkURL5',

    'quicklinks.5.name': 'quickLinkName6',
    'quicklinks.5.url': 'quickLinkURL6',

    'quicklinks.6.name': 'quickLinkName7',
    'quicklinks.6.url': 'quickLinkURL7',

    'quicklinks.7.name': 'quickLinkName8',
    'quicklinks.7.url': 'quickLinkURL8',

    'quicklinks.8.name': 'quickLinkName9',
    'quicklinks.8.url': 'quickLinkURL9',

    'quicklinks.9.name': 'quickLinkName10',
    'quicklinks.9.url': 'quickLinkURL10',

    'social.facebook': 'facebookURL',
    'social.twitter': 'twitter',
    'social.instagram': 'instagramURL',

    'social.youtube': 'youtubeURL',
    'social.vimeo': 'vimeoURL',
    'social.podcast': 'podcastURL',

    'social.linkedin': 'linkedInURL',
    'social.blog': 'blogURL',
    'social.tikTok': 'tiktokURL',

    'social.iphone': 'iphoneAppURL',
    'social.ipad': 'ipadAppURL',
    'social.android': 'androiAppURL',

    'social.snapchat': 'snapchatURL',
    'social.twitch': 'twitchURL',
    'social.discord': 'discordURL',

    'social.linktree': 'linktreeURL',

    /* DEPRECATED
    'social.google': 'googleURL',
    'social.pinterest': 'pinterestURL',
    'social.myspace': 'myspaceURL',
    'social.foursquare': 'foursquareURL',
    'social.flickr': 'flickerURL',
    'social.blackberry': 'blackberryAppURL',
    'social.stackla': 'stacklaURL',
    'social.soundcloud': 'soundcloudURL',
    */
};
