import {
    get,
    post,
    put,
    deleteCall,
    endpoints,
} from '../../api';

export function apiGetLabels (options) {
    return get(
        endpoints().label,
        options
    );
}

export function apiAddLabel ({ body, options }) {
    return post(
        endpoints().label,
        body,
        options
    );
}

export function apiEditLabel ({ body, options }) {
    return put(
        endpoints().label + '/' + body.id,
        body,
        options
    );
}

export function apiDeleteLabel ({ body, options }) {
    return deleteCall(
        endpoints().label + '/' + body.id,
        options
    );
}
