import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react';
import {
    Grid,
} from '@material-ui/core';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { useStyles } from './styles';

import { useLayoutState } from '../../app/context/LayoutContext';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header';
// import structure from '../../components/Sidebar/SidebarStructure';
import useSidebarStructure from '../../components/Sidebar/useSidebarStructure';
import BreadCrumbs from '../../components/BreadCrumbs';
import Footer from '../../components/Footer';

import Optins from '../optins';
import OptinsForm from '../optins/forms';

import CustomLabels from '../customlabels';
import CustomLabelsForm from '../customlabels/forms';

import ApiKeys from '../apikey';
import ApiKeysForm from '../apikey/forms';

import Profile from '../profile';
import HomePage from '../homepage';
import Schedules from '../schedules';
import AddSchedule from '../schedules/AddSchedule';
import ManualEntry from '../schedules/manualEntry';
import ImportEvents from '../schedules/imports';

import Events from '../schedules/events';
import AddEvent from '../schedules/events/AddEvent';

import AddDisplay from '../displays/AddDisplay';
import AddEditDisplays from '../displays/inputs';

import EventDisplayInputV2 from '../displays/inputEventV2';

import Displays from '../displays';
// import ButtonsList from '../displays/buttonsList';

import Webhook from '../webhook';
import EditWebhook from '../webhook/EditWebhook';

import SubscribersData from '../analytics';
import Engagement from '../analytics/engagementAnalytics';
import SubscriberAnalytics from '../analytics/subscriberAnalytics';

import SuperAdminHome from '../superadmin';

import { useUserStore, useDashboardStore, useBillingStore, useEnvStore } from '../../store/models';

import useEnvHistory from '../hooks/useEnvHistory';
import useGenericErrorDialog from '../../store/models/error/useGenericErrorDialog';
/*
 * this hooks ensuer that only source of user publisher in info is this hook.
 */
import useUserPublisherInfo from '../../store/models/user/useUserPublisherInfo';
// import Error404 from '../error404';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';
import useFreeTrialDialog from '../hooks/useFreeTrialDialog';
import useProductTour from '../hooks/useProductTour';

function Dashboard (props) {
    const classes = useStyles();
    const envStore = useEnvStore();
    const userStore = useUserStore();
    const layoutState = useLayoutState();
    const dashboardStore = useDashboardStore();
    const [subsRemaining, setSubsRemaining] = useState(null);
    const billingStore = useBillingStore();

    const {
        user,
        publisher,
        publisherLogo,
        role,
    } = useUserPublisherInfo();
    useProductTour();

    const { whiteLabelSettings } = useWhiteLabel();

    const {
        structure,
        closeAll,
        shouldClose,
        sidebarScrollParent,
    } = useSidebarStructure({
        publisher,
        whiteLabelSettings,
        env: envStore,
    });

    useEnvHistory();

    const { renderErrorDialog } = useGenericErrorDialog();

    const {
        renderFreeTrialExpiredDialog,
    } = useFreeTrialDialog({
        publisher,
        whiteLabelSettings,
        role,
    });

    React.useEffect(() => {
        if (Object.keys(user).length && publisher.publisherId) {
                console.log('window.IntercomBoot() on  user Update');
                window.Intercom('boot', {
                    app_id: window.APP_ID,
                    email: publisher.email,
                    user_id: publisher.publisherId,
                    user_hash: publisher.intercomUserHash,
                    hide_default_launcher: whiteLabelSettings.hideIntercomChat,
                    // created_at: Math.round(+new Date() / 1000),
                });
            let userTrackingLabel = 'Superuser Login';
            if (publisher.email === user.email) {
                userTrackingLabel = 'Publisher Login';
            }
            window.Intercom('trackEvent', userTrackingLabel, {
                email: user.email,
                name: user.name,
            });

            setTimeout(() => {
                userStore.env.observed.intercomBoot = uuid();
            }, 2000);
      }
    }, [user]);

    useEffect(() => {
        if (publisher.subscriberLimit === -1) {
            setSubsRemaining('infinity');
        } else {
            let count = publisher.subscriberLimit - dashboardStore.totalSubscribers;
            if (Number.isNaN(count)) {
                count = '';
            }
            setSubsRemaining(count);
        }
    }, [dashboardStore.totalSubscribers, publisher.subscriberLimit]);

    useEffect(() => {
        if (subsRemaining && subsRemaining !== 'infinity' && subsRemaining !== 'NAN') {
            const { planName, paid_subscriber } = getPlanInfo();
            console.log('window.IntercomBoot() on subRemaining');
            window.Intercom('boot', {
                app_id: window.APP_ID,
                email: publisher.email,
                user_id: publisher.publisherId,
                user_hash: publisher.intercomUserHash,
                subscriptions_remaining: subsRemaining,
                plan: planName,
                paid_subscriber: paid_subscriber,
                hide_default_launcher: whiteLabelSettings.hideIntercomChat,
                // trial_expired: trial_expired,
            });

            setTimeout(() => {
                userStore.env.observed.intercomBoot = uuid();
            }, 2000);
        }
    }, [subsRemaining, publisher, billingStore.observed.publisherPlan]);

    useEffect(() => {
        if (userStore.env.observed.intercomBoot) {
            const { planName, paid_subscriber } = getPlanInfo();
            window.Intercom('update', {
                user_id: publisher.publisherId,
                user_hash: publisher.intercomUserHash,
                subscriptions_remaining: subsRemaining,
                plan: planName,
                paid_subscriber: paid_subscriber,
            });
        }
    }, [userStore.env.observed.intercomBoot]);

    function getPlanInfo () {
        if (publisher && publisher.planName) {
            let planName = null;
            planName = publisher?.planName?.toLowerCase();
            let paid_subscriber = false;
            if (planName.match(/free/) === null) {
                paid_subscriber = true;
            }
            return {
                planName,
                paid_subscriber,
            };
        }
        return {
            planName: '',
            paid_subscriber: false,
        };
    }

    return (
        <React.Fragment>
            <Grid
                className={classes.root}
                onMouseDown={() => {
                    clearTimeout(window.moveRefreshTimeout);
                    window.moveRefreshTimeout = setTimeout(() => {
                        userStore.refreshSession();
                        userStore.expireWhenIdle();
                    }, 1000);
                }}
                >
                <Header
                    history={props.history}
                    /*
                    * in the context of observables use userStore user and publishers
                    */
                    userInfo={user}
                    publisher={publisher}
                    role={role}
                    subsRemaining={subsRemaining}
                    publisherLogo={publisherLogo}
                    onSignOut={() => {
                        console.log('Signout ....');
                        userStore.signOut();
                    }}
                />

                <Sidebar
                    structure={structure}
                    closeAll={closeAll}
                    shouldClose={shouldClose}
                    scrollParent={sidebarScrollParent}
                    />

                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />
                    <BreadCrumbs publisher={publisher} />
                    <Switch>

                        <Route path='/superadmin' component={SuperAdminHome} />
                        <Route path='/admin/home' component={HomePage} />
                        <Route path='/admin/home/summary' component={HomePage} />
                        <Route path="/admin/settings" component={Profile} />

                        <Route path='/admin/marketing/optins/edit/:id' component={OptinsForm} />
                        <Route path='/admin/marketing/optins/add' component={OptinsForm} />
                        <Route path='/admin/marketing/optins' component={Optins} />

                        <Route path="/admin/schedules/:id/events/add" component={AddEvent} />
                        <Route path="/admin/schedules/:id/events/edit" component={AddEvent} />
                        <Route path="/admin/schedules/:id/events" component={Events} />

                        <Route path="/admin/schedules/add/manual-entry" component={ManualEntry} />
                        <Route path="/admin/schedules/add" component={AddSchedule} />
                        <Route exact path="/admin/schedules/import-events" component={ImportEvents} />
                        <Route path="/admin/schedules/edit/:id" component={ManualEntry} />
                        <Route exact path="/admin/schedules/:id" component={Events} />

                        <Route path="/admin/schedules" component={Schedules} />

                        <Route path="/admin/developers/webhooks/edit/:id" component={EditWebhook} />
                        <Route path="/admin/developers/webhooks/add" component={EditWebhook} />
                        <Route path="/admin/developers/webhooks" component={Webhook} />

                        <Route path="/admin/developers/apikeys/edit/:id" component={ApiKeysForm} />
                        <Route path="/admin/developers/apikeys/add" component={ApiKeysForm} />
                        <Route path="/admin/developers/apikeys" component={ApiKeys} />

                        <Route path='/admin/marketing/custom-labels/edit/:id' component={CustomLabelsForm} />
                        <Route path='/admin/marketing/custom-labels/add' component={CustomLabelsForm} />
                        <Route path='/admin/marketing/custom-labels' component={CustomLabels} />

                        <Route path="/admin/analytics/subscriber-data" component={SubscribersData} />
                        <Route path="/admin/analytics/enagagement-analytics" component={Engagement} />
                        <Route path="/admin/analytics/subscriber-analytics" component={SubscriberAnalytics} />

                        <Route path="/admin/displays/event/edit/:id" component={EventDisplayInputV2} />
                        <Route path="/admin/displays/add/event" component={EventDisplayInputV2} />

                        <Route path="/admin/displays/ad/edit/:id" component={AddEditDisplays} />
                        <Route path="/admin/displays/add/ad" component={AddEditDisplays} />

                        <Route path="/admin/displays/add/button" component={AddEditDisplays} />
                        <Route path="/admin/displays/button/edit/:id" component={AddEditDisplays} />

                        <Route path="/admin/displays/add" component={AddDisplay} />

                        <Route path="/admin/displays" component={Displays} />

                        {/* <Route path="/admin/displays/add/event" component={AddEditDisplays} /> */}
                        {/* <Route path="/admin/buttons-&-display/buttons" component={ButtonsList} />
                        <Route path="/admin/buttons-&-display/displays" component={Displays} /> */}
                        {/* <Route component={Error404} /> */}
                    </Switch>
                </div>
            </Grid>
            { renderErrorDialog() }
            { renderFreeTrialExpiredDialog() }
            <Footer publisher={publisher}/>
        </React.Fragment>
    );
}

export default withRouter(observer(Dashboard));
