import React from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
} from '@material-ui/core';
import { Typography } from '../../components/Wrappers';

import { useStyles } from './styles';
import { BillingCoupons } from '../../utils/constants';

function BillingPlanInfo (props) {
    const {
        input,
        billingInputProps,
    } = props;

    const {
        currencySymbol,
        coupon,
        pricingSummary,
    } = billingInputProps;

    const classes = useStyles();

    function renderBullets () {
        if (!input.bullets) return null;

        return input.bullets.map((info) => {
            return (
            <li
                key={uuid()}
                className={classes.bullet}
                >
                {info }
            </li>);
        });
    }

    function renderPrice () {
        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary?.planName?.indexOf('Professional') !== -1) {
            return pricingSummary.monthlyPlanWithDiscount.toFixed(2);
        }
        if (coupon === BillingCoupons.ITSONUS23) {
            return '0';
        }
        return input.price;
    }

    function letsDoThisSavedPrice () {
        if (!input && input.price) {
            return 0;
        }
        const price = input.price * 0.25;
        return price.toFixed(2);
    }

    function renderCoupon () {
        if (coupon === BillingCoupons.LETSDOTHIS23 && pricingSummary?.planName?.indexOf('Professional') !== -1) {
            return (<span className={classes.couponCode}>
                {`(Save ${currencySymbol}${letsDoThisSavedPrice()}  per month)`}
            </span>);
        }
        if (coupon === BillingCoupons.ITSONUS23) {
            return (<span className={classes.couponCode}>
                {'(Offer applied: First Month Free)'}
            </span>);
        }

        if (coupon === BillingCoupons.SETMEUP23) {
            return (<span className={classes.couponCode}>
                {'(Offer applied: Free Set-up)'}
            </span>);
        }
        return (<React.Fragment></React.Fragment>);
    }
    return (
        <React.Fragment>
            <Grid
                container
                className={classes.planInfoContainer}
            >

                <Typography
                    className={classNames([classes.bottomSpace])}
                    variant={'h6'}>
                    ECAL { input.planName } Plan - {currencySymbol}{ renderPrice() }
                    { renderCoupon() }
                </Typography>

                <Typography
                    variant={'body2'}
                    className={classes.planDescription}
                >
                    {input.description}
                </Typography>

                <Typography
                    variant={'body2'}
                    className={classes.planFeatures}
                >
                    Features include:
                </Typography>

                <Typography
                    variant={'body1'}
                    weight={'light'}
                >
                    <ul>
                        { renderBullets() }
                    </ul>
                </Typography>

            </Grid>
        </React.Fragment>
    );
}

BillingPlanInfo.propTypes = {
    planDetail: PropTypes.object,
    plans: PropTypes.array,
    input: PropTypes.object,
    selectPlan: PropTypes.func,
    billingInputProps: PropTypes.any,
};

BillingPlanInfo.defaultProps = {
    input: {},
    plans: [],
    planDetail: {
        name: 'Plan Name',
    },
    selectPlan: () => {},
    billingInputProps: {},
};

export default BillingPlanInfo;
