import { useEffect, useState } from 'react';
import useFeatures from '../../hooks/useFeatures';
import { getScheduleTypes } from '../../../utils/constants';
function useVersion () {
    const { features } = useFeatures();

    const [scheduleTypes, setScheduleTypes] = useState([]);

    useEffect(() => {
        if (features.schedulesV2) {
            setScheduleTypes(getScheduleTypes());
        }
    }, [features]);

    return {
        features,
        schedulesV2: features.schedulesV2,
        scheduleTypes,
    };
}

export default useVersion;
