import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
} from '@material-ui/core';

// import Input from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';

import {
    Typography,
    // Button,
} from '../../../components/Wrappers/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';
import Select from '../../../commons/select/Select';

// import Widget from '../../../components/Widget';
import { useStyles } from '../profile.styles';

const dataRightsProvider = [
    {
        value: 'pi_data_rights',
        label: 'PI data rights',
    },

    {
        value: 'non_pi_data_rights',
        label: 'Non-PI data rights',
    },

    {
        value: 'no_data_rights',
        label: 'No data rights',
    },
];
function BasicDetails (props) {
    // const classes = useStyles();
    const {
        publisher,
        onDataChange,
        onSave,
        inProgress,
    } = props;
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [data, setData] = useState(publisher);
    const classes = useStyles();

    useEffect(() => {
        setData(publisher);
    }, [publisher]);

    useEffect(() => {
        if (typeof onDataChange === 'function') {
            onDataChange(data);
        }
    }, [data]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        setSaveDisabled(false);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                            className={classes.boxStyling}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Profile
                            </Typography>
                            <TextField
                                id="first-name"
                                label="First Name"
                                onChange={handleChange}
                                name="firstName"
                                value={data.firstName || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Please enter your first name"
                            />
                            <TextField
                                id="last-name"
                                label="Last Name"
                                onChange={handleChange}
                                name="lastName"
                                value={data.lastName || ''}
                                variant="outlined"
                                style={{ marginBottom: 35 }}
                                helperText="Please enter your last name"
                            />

                            <Select
                                    value={data.dataRights}
                                    label='ECAL Data Rights'
                                    name='dataRights'
                                    placeHolder='Please select ECAL DATA Rights'
                                    id='profile-dataRights'
                                    labelHeading='ECAL Data Rights'
                                    handleChange={(e) => handleChange(e)}
                                    dataForDropdown={dataRightsProvider}
                                />

                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <ButtonProgress
                                    disabled={saveDisabled}
                                    showProgress={inProgress}
                                    label="Save"
                                    onClick={(e) => {
                                        onSave(data);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

BasicDetails.propTypes = {
    publisher: PropTypes.object,
    onDataChange: PropTypes.func,
    inProgress: PropTypes.bool,
};

BasicDetails.defaultProps = {
    publisher: {
        firstName: '',
        email: '',
        role: 'user',
    },
    onDataChange: () => { },
    onSave: () => {},
    inProgress: false,
};

export default BasicDetails;
