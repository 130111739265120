import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { useWebhooksStore } from '../';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function useWebhookErrors (props) {
    const {
        input,
    } = props;

    const store = useErrorStore();
    const webhookStore = useWebhooksStore();
    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        hook: 'useWebhookErrors',
        input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.getWebhook,
            store.observed.errors.saveWebhooks,
            store.observed.errors.addWebhook,
            // store.observed.errors.searchWebhook,
            store.observed.errors.editWebhook,
            // store.observed.errors.bulkEditWebhook,
            // store.observed.errors.bulkDeleteWebhook,
            // store.observed.errors.getWebhookForSubscribersFilter,
            // store.observed.errors.getWebhookAsFilter,
            // store.observed.errors.uploadWebhookLogo,
            // store.observed.errors.addCategory,
        ],
    });

    // useEffect(() => {
    //     webhookStore.loadingTree = false;
    // }, [store.observed.errors.addCategory]);

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useWebhookErrors() Error Updates...', errors);
        webhookStore.skeletonFlag = false;
    }, [errors, store.observed.errors.addWebhook]);

    console.log('uwhe? ', {
      errors,
      hasError,
      hasInputError,
      isGenericError,
      webhookStore,
  });
    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default useWebhookErrors;
