import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    boxStyling: {
        backgroundColor: '#ffffff',
        padding: 24,
        borderRadius: 4,
        boxShadow: theme.customShadows.widget,
    },

    multilineInput: {
        fontSize: '14px',
        /*
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        */
    },
});

export default styles;
export const useStyles = makeStyles(styles);
