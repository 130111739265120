/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Box,
    Grid,
    // InputAdornment,
    // // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
    // TextField as Input,
    Tooltip,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import TableSkeleton from './TableSkeleton';
import Widget from '../../components/Widget';
import { Button, Typography } from '../../components/Wrappers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
// import Notification from '../../components/Notification/Notification';
// import { toast } from 'react-toastify';

import EnhancedTableHead from './TableHeadings';

// import {
//     useManagementDispatch,
//     useManagementState,
// } from '../../context/ManagementContext'

import useStyles from './table.styles';
// Icons
import {
    // Add as AddIcon,
    // GetApp as DownloadIcon,
    // Search as SearchIcon,
    CreateOutlined as CreateIcon,
    // SettingsInputComponent as SettingsInputComponentIcon,
} from '@material-ui/icons';
import { moduleNoDataText } from '../util/utilities';
import { logInfo } from '../../utils';

// import { actions } from '../../context/ManagementContext'

function descendingComparator (a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator (order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const TableComponent = (props) => {
    const {
        tableTitle,
        changeStatus,
        openModal,
        skeletonFlag,
        tableData,
        headCells,
        // showStatusTooltip = true,
        moduleType,
    } = props;
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
    const [dense, setDense] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [usersRows, setUsersRows] = React.useState([]);

    // var managementDispatch = useManagementDispatch()
    // var managementValue = useManagementState()

    React.useEffect(() => {
    //   sendNotification('This page is only available in React Material Admin Full with Node.js integration!');
      async function fetchAPI () {
        try {
        //   await actions.doFetch({}, false)(managementDispatch);
        //   setUsersRows(managementValue.rows);
        const rows = tableData;
        if (tableData && tableData.length >= 0) {
            setUsersRows(rows);
        }
        } catch (e) {
          console.log(e);
        }
      }
      fetchAPI();
    }, [tableData]);

    const classes = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            // const newSelecteds = managementValue.rows.map(n => n.id)
            // setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    function statusTooltipText (row) {
        logInfo('statusToolTipText: ', row.status);
        if (row.status === 1) {
            return 'Deactivate Webhook';
        }
        return 'Activate Webhook';
    }

    function editTooltip (row) {
        if (row.status === 1) {
            return 'Edit Webhook';
        }
        return 'Activate webhook to edit.';
    }

    function deleteTooltip (row) {
        if (row.status === 1) {
            return 'Deactivate webhook then delete.';
        }
        return 'Delete webhook';
    }

    return (
                <Widget inheritHeight disableWidgetMenu>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'flex-start'}
                        marginBottom={'16px'}
                        // marginTop={'32px'}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'flex-start'}>
                            {/* <Box
                                marginTop={'4px'}
                                mr={1} >
                                <SettingsInputComponentIcon fontSize={'default'} />
                            </Box> */}
                            <Typography variant={'h5'}>
                                {tableTitle}
                            </Typography>
                        </Box>
                        {/* <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-end'}
                        >
                            <Link
                                onClick= { (e) => {
                                    e.preventDefault();
                                    history.push('/admin/developers/webhooks/add');
                                }}
                                color="#fff"
                                underline="none" >
                                <Button variant={'contained'} color={'success'}>
                                    <Box mr={1} display={'flex'}>
                                        <AddIcon />
                                    </Box>
                                    Add
                                </Button>
                            </Link>
                        </Box> */}
                    </Box>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={usersRows.length}
                            />
                            { skeletonFlag
                            ? <TableSkeleton />
                            : <TableBody>
                                {stableSort(
                                    usersRows,
                                    getComparator(order, orderBy)
                                )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );
                                        // const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }
                                                // role="checkbox"

                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                className= {classes.tableRow}
                                            >
                                                <TableCell align="left">
                                                    <Typography
                                                        variant={'body2'}
                                                    >
                                                        {row.url}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant={'body2'}
                                                    >
                                                        {row.secret}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant={'body2'}
                                                    >
                                                        {row.createdOn}
                                                    </Typography>
                                                </TableCell>
                                                <Tooltip title={statusTooltipText(row)}>
                                                <TableCell align="left">
                                                        <Button
                                                            size="small"
                                                            color={(row.status === 1) ? 'success' : 'secondary'}
                                                            className={classes.statusButton}
                                                            onClick={() => changeStatus(row)}
                                                            variant="contained"
                                                            >
                                                            {(row.status === 1) ? 'active' : 'inactive'}
                                                        </Button>
                                                </TableCell>
                                                </Tooltip>
                                                <TableCell align="left">
                                                    <Box
                                                        display={'flex'}
                                                        style={{
                                                            marginLeft: -12,
                                                        }}
                                                    >
                                                        <Tooltip title={editTooltip(row)}>
                                                            <Grid>
                                                                <IconButton
                                                                    color={'primary'}
                                                                    disabled = {row.status === 0 }
                                                                    onClick= { (e) => {
                                                                        e.preventDefault();
                                                                        history.push(`/admin/developers/webhooks/edit/${row.id}`);
                                                                    }}
                                                                >
                                                                    <CreateIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Tooltip>
                                                        <Tooltip title={deleteTooltip(row)}>
                                                            <Grid>
                                                                <IconButton
                                                                    disabled = {row.status === 1 }
                                                                    onClick={() => openModal(row.id)}
                                                                    color={'primary'}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            }
                        </Table>
                        {!usersRows.length && (
                          <Grid container >
                            <Grid
                              className={classes.noData}
                              xs={12}
                            >
                                <Typography variant={'body2'}>
                                    {moduleNoDataText(moduleType)}
                                </Typography>
                            </Grid>
                          </Grid>
                        )}
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={usersRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                </Widget>

    );
};

export default TableComponent;
