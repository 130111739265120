import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Box,
} from '@material-ui/core';

import {
    Typography,
//    Button,
} from '../../../../components/Wrappers';
import ButtonProgress from '../../../../commons/ButtonProgress';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import useDetails from './useDetails';
import NameIdDetails from './nameIdDetails';
function Details (props) {
    const classes = useStyles();

    const {
        onDataChange,
        dataSource,
        updateSavable,
        progress,
        saveDisabled,
        onSave,
    } = props;

    const {
        detailsData,
        setDetailsData,
    } = useDetails({
        dataSource,
        onDataChange,
        updateSavable,
    });

    return (
        <React.Fragment>
            <Grid
                item
                className={classes.mainContainer}
                justify={'center'}
                container>

                <Grid
                    className={classes.titleBase}
                    >
                    <Typography
                        variant='h5'
                        weight='medium'
                    >
                        Event Display Details
                    </Typography>
                </Grid>

                <NameIdDetails
                    detailsData={detailsData}
                    setDetailsData={setDetailsData}
                />

                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <ButtonProgress
                        disabled={saveDisabled}
                        showProgress={progress.save}
                        label="Save"
                        onClick={(e) => {
                            onSave();
                        }}
                    />
                </Box>

            </Grid>
        </React.Fragment>
    );
};

Details.propTypes = {
    onDataChange: PropTypes.func,
    updateSavable: PropTypes.func,
    dataSource: PropTypes.any,
    progress: PropTypes.object,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
};

Details.defaultProps = {
    onDataChange: () => {},
    updateSavable: () => {},
    dataSource: {},
    progress: {},
    saveDisabled: false,
    onSave: () => {},
};

export default observer(Details);
