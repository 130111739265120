import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
    imageDropContainer: {
        width: '100%',
        '& .image-item': {
            marginBottom: '30px',
        },
    },

    columnLayout: {
        flexDirection: 'column',
        justifyContent: 'center',
    },

    rowLayout: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },

    spaceBetweenLayout: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '50px',
        height: '70px',
    },

    input: {
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '12px',
        border: '1px solid #B2B2B2',
        minHeight: '100px',
    },

    imageThumbnail: (props) => {
        const { imageType } = props;

        function isBanner () {
            if (imageType === 'banner') {
                return true;
            }
            return false;
        }

        const style = {
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '48px',
            borderRadius: '4px',
            marginBottom: '8px',
        };

        if (isBanner) {
            style.width = '100%';
            style.marginTop = '4px';
            style.marginBottom = '4px';
        }

        return style;
    },

    image: (props) => {
        const {
            imageSize: {
                width,
                height,
            },
        } = props;

        return {
            cursor: 'pointer',
            maxWidth: width,
            maxHeight: height,
        };
    },

    iconButton: {
        width: '36px',
        height: '36px',
    },

    uploadProgressBox: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        padding: '4px',
        opacity: '0.5',
    },

}));
