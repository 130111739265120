import moment from 'moment';
import { v4 as uuid } from 'uuid';
import {
  apiGetAnalyticsCalendarTypes,
  apiGetAnalyticsSubscriptions,
  apiGetAnalyticsPopularLocations,
  apiGetAnalyticsDevices,
  apiGetTotalSubscribers,
  apiGetLiveScheduleCount,
  apiGetLiveEventCount,
  apiGetOptinCount,
  apiGetMonthlyActives,
  apiGetEventImpressions,
  apiGetReminderImpressions,
  apiGetAnalyticsClicks,
  apiGetClicksCount,
  apiGetMapSubscriptionsData,
} from './api';

// import { popularLinks } from './mockdata';

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export async function getAnalyticsCalendarTypes () {
  this.observed.calendarTypesDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetAnalyticsCalendarTypes({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data && res.data.data) {
    if (res.data.data) {
      this.calendarTypes = res.data.data;
    } else {
      this.calendarTypes = [];
    }
  } else {
    console.log('err? ', res);
  }

  this.observed.calendarTypesDataLoading = false;
}

export async function getAnalyticsSubscriptions (to, from) {
  this.observed.subscriptionsDataLoading = true;
  const publisher = this.env.userStore.publisher;
  const hasCustomDate = !!(to && from);

  const res = await apiGetAnalyticsSubscriptions(
      {
          to: hasCustomDate ? to : moment().format('YYYY-MM-DD'),
          from: hasCustomDate ? from : moment().subtract(29, 'days').format('YYYY-MM-DD'),
          tz: userTimeZone,
      },
      {
      token: publisher.token,
      traceId: this.env.traceId,
  });

  if (res.data && res.data.data) {
      if (res.data.data) {
          this.subscriptions = res.data.data;
      } else {
        this.subscriptions = [];
      }
  } else {
    console.log('err? ', res);
  }
  this.observed.subscriptionsDataLoading = false;
}

export async function getMapSubscriptionsData () {
  const publisher = this.env.userStore.publisher;

  const res = await apiGetMapSubscriptionsData(
      {
          to: moment().format('YYYY-MM-DD'),
          from: moment().subtract(89, 'days').format('YYYY-MM-DD'),
          tz: userTimeZone,
      },
      {
      token: publisher.token,
      traceId: this.env.traceId,
  });
  if (res.data) {
    if (res.data.features) {
        this.subsMapData = res.data;
    } else {
      this.subsMapData = [];
    }
  } else {
    console.log('err? ', res);
  }
}

export async function getAnalyticsClicks () {
  this.observed.analyticsClicksDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetAnalyticsClicks(
      {
          to: moment().format('YYYY-MM-DD'),
          from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
          tz: userTimeZone,
      },
      {
      token: publisher.token,
      traceId: this.env.traceId,
  });

  if (res.data && res.data.data) {
      if (res.data.data) {
          this.analyticsClicks = res.data.data;
      } else {
        this.analyticsClicks = [];
      }
  } else {
    console.log('err? ', res);
  }

  this.observed.analyticsClicksDataLoading = false;
}

export async function getAnalyticsPopularLocations () {
  this.observed.popularLocationsDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetAnalyticsPopularLocations(
      {
          // to: '2021-11-10',
          // from: '2021-09-12',
          to: moment().format('YYYY-MM-DD'),
          from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
          tz: userTimeZone,
      },
      {
      token: publisher.token,
      traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
          this.popularLocations = res.data.data;
      } else {
        this.popularLocations = [];
      }
  } else {
    console.log('err? ', res);
  }

  this.observed.popularLocationsDataLoading = false;
}

export async function getAnalyticsDevices () {
  this.observed.deviceTypesDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetAnalyticsDevices({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
          this.deviceTypes = res.data.data;
      } else {
        this.deviceTypes = [];
      }
  } else {
    console.log('err? ', res);
  }
  this.observed.deviceTypesDataLoading = false;
}

export async function getTotalSubscribers (returnTotal = false) {
  const publisher = this.env.userStore.publisher;
  const userStore = this.env.userStore;
  const res = await apiGetTotalSubscribers({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
    if (returnTotal) {
      return res.data.data.total;
    }

    if (res.data.data) {
      this.totalSubscribers = res.data.data.total;
      userStore.subscriberCount = this.totalSubscribers;
      userStore.observed.subscriberCount = uuid();
    } else {
      this.totalSubscribers = [];
    }
  } else {
    console.log('err? ', res);
  }
}

export async function getLiveScheduleCount () {
  const publisher = this.env.userStore.publisher;

  const res = await apiGetLiveScheduleCount({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
    if (res.data.data) {
      this.liveScheduleCount = res.data.data.total;
    } else {
      this.liveScheduleCount = [];
    }
  } else {
    console.log('err? ', res);
  }
}

export async function getLiveEventCount () {
  const publisher = this.env.userStore.publisher;

  const res = await apiGetLiveEventCount({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
    if (res.data.data) {
      this.liveEventCount = res.data.data.total;
    } else {
      this.liveEventCount = [];
    }
  } else {
    console.log('err? ', res);
  }
}

export async function getOptinCount () {
  const publisher = this.env.userStore.publisher;

  const res = await apiGetOptinCount({
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
        this.optinCount = res.data.data.total;
      } else {
        this.optinCount = [];
      }
  } else {
    console.log('err? ', res);
  }
}

export async function getMonthlyActives () {
  const publisher = this.env.userStore.publisher;

  const res = await apiGetMonthlyActives({
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      tz: userTimeZone,
    }, {
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
        this.monthlyActives = res.data.data.total;
      } else {
        this.monthlyActives = [];
      }
  } else {
    console.log('err? ', res);
  }
}

export async function getEventImpressions () {
  this.observed.eventImpresionsDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetEventImpressions({
      // from: '2019-04-11',
      // to: '2019-04-27',
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      tz: userTimeZone,
    }, {
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
        this.eventImpressions = res.data.data;
      } else {
        this.eventImpressions = [];
      }
  } else {
    console.log('err? ', res);
  }
  this.observed.eventImpresionsDataLoading = false;
}

export async function getReminderImpressions () {
  this.observed.reminderImpresionsDataLoading = true;
  const publisher = this.env.userStore.publisher;

  const res = await apiGetReminderImpressions({
      to: moment().format('YYYY-MM-DD'),
      from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      tz: userTimeZone,
    }, {
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
        this.reminderImpressions = res.data.data;
      } else {
        this.reminderImpressions = [];
      }
  } else {
    console.log('err? ', res);
  }
  this.observed.reminderImpresionsDataLoading = false;
}

export async function getClicksCount () {
  this.observed.popularLinksDataLoading = true;
  /*
  const res = {
     data: popularLinks,
  };
  const grouped = this.groupAndSortClicks(res.data.data);

  this.clicks = grouped;
  this.urlClicks = res.data.data;
  this.observed.clicks = uuid(); // just make sure that the observed are the last one to be updated
   */

  const publisher = this.env.userStore.publisher;
  const res = await apiGetClicksCount({
    to: moment().format('YYYY-MM-DD'),
    from: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    tz: userTimeZone,
    }, {
    token: publisher.token,
    traceId: this.env.traceId,
  });

  if (res.data) {
      if (res.data.data) {
        const grouped = this.groupAndSortClicks(res.data.data);
        this.clicks = grouped;
        this.urlClicks = res.data.data;
        this.observed.clicks = uuid();
      } else {
        this.clicks = [];
      }
  } else {
    console.log('err? ', res);
  }

  this.observed.popularLinksDataLoading = false;
}
