/* eslint-disable multiline-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    IconButton,
} from '@material-ui/core';
import {
  Close,
} from '@material-ui/icons';
import { Button, Typography } from '../../components/Wrappers';
import useStyles from './dialog.styles';

function DialogBox (props) {
  const {
    children,
    title,
    openDialog,
    dialogContextText,
    buttonPrimary,
    buttonPrimaryFlag,
    primaryDisableFlag,
    buttonPrimaryVariant,
    buttonPrimaryColor,
    buttonSecondary,
    buttonSecondaryFlag,
    handleAgree,
    closeModal,
    className,
    showSubtitle,
    subtitle,
    containerMaxWidth,
    containerClass,
    withCloseIcon = false,
    bodyMaxWidth = 'sm',
  } = props;

  const classes = useStyles({ showSubtitle, containerMaxWidth });
  return (
    <Dialog
      className={className || '' }
      open={openDialog}
      // onClose={closeModal}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      maxWidth={bodyMaxWidth}
    >
      <Box
        className={classNames([classes.dialogContainer, containerClass])}>

          <DialogTitle id="alert-dialog-title">
            {title}
            {withCloseIcon ? (
              <IconButton
                aria-label="close"
                onClick={handleAgree}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <Close />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent>
            {
              dialogContextText !== ''
              ? <DialogContentText id="alert-dialog-description">
                  {dialogContextText}
                </DialogContentText>
              : null
            }

            {children}
          </DialogContent>

          <DialogActions
            className={classes.dialogActions}
          >
            { buttonSecondaryFlag && !showSubtitle
              ? <Button
                  onClick={closeModal}
                  // variant={'outlined'}
                  color={'secondary'}
                >
                  {buttonSecondary}
                </Button>
              : null
            }
            { buttonPrimaryFlag && (
              <Button
                onClick={handleAgree}
                variant={buttonPrimaryVariant}
                color={buttonPrimaryColor}
                autoFocus
                disabled={primaryDisableFlag}
              >
                {buttonPrimary}
              </Button>
            )}
          </DialogActions>
          {
            showSubtitle && (
              <Typography
                variant='sub2'
                className={classes.subtitle}
              >
                {subtitle}
              </Typography>
            )
          }
      </Box>
    </Dialog>
  );
}

DialogBox.propTypes = {
  title: PropTypes.string,
  openDialog: PropTypes.bool,
  dialogContextText: PropTypes.string,
  buttonPrimary: PropTypes.string,
  buttonSecondary: PropTypes.string,
  buttonSecondaryFlag: PropTypes.bool,
  handleAgree: PropTypes.func,
  closeModal: PropTypes.func,
  showSubtitle: PropTypes.bool,
  subtitle: PropTypes.string,
  buttonPrimaryVariant: PropTypes.string,
  buttonPrimaryColor: PropTypes.string,
  buttonPrimaryFlag: PropTypes.bool,
  containerMaxWidth: PropTypes.string,
  containerClass: PropTypes.any,
};

DialogBox.defaultProps = {
  title: 'Are you sure that you want to delete this?',
  openDialog: false,
  dialogContextText: '',
  buttonPrimary: 'Yes',
  buttonSecondary: 'No',
  buttonSecondaryFlag: true,
  primaryDisableFlag: false,
  handleAgree: undefined,
  closeModal: undefined,
  showSubtitle: false,
  buttonPrimaryFlag: true,
  buttonPrimaryVariant: 'contained',
  buttonPrimaryColor: 'success',
  subtitle: '',
  containerMaxWidth: '540px',
  containerClass: undefined,
};

export default DialogBox;
