import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import {
    logInfo,
    getCurrency,
    isSetupPlanPage,
    getURLElements,
    setAWSMaketplace,
} from '../../../utils';

import {
    BillingCoupons,
    BillingProviders,
    PlanTypes,
} from '../../../utils/constants';
import {
    apiGetBillingInfo,
    apiGetPlanDetails,
    apiGetCurrentPlan,
    apiCreateSubscription,
    apiFinalize3DSecureSubscription,
    apiUpdateSubscription,
} from './api';

const ERR_3D_SECURE = 'Your card was declined. This transaction requires 3D secure authentication.';

export function hasBilling () {
    if (this.env.stylesStore.whiteLabelSettings?.labelName !== 'ecal') {
        return false;
    }
    if (this.env.features.billing_v4.active) {
        return true;
    }
    return false;
}

export async function getBillingInfo () {
    this.env.errorStore.action = 'getBillingInfo';
    this.observed.billingInfoLoading = true;
    const publisher = this.env.userStore.publisher;

    const res = await apiGetBillingInfo({
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data && res.data.data) {
        this.optins = res.data.data;
        this.observed.optins = uuid();
    }
    this.observed.billingInfoLoading = true;
}

export async function getPlanDetails () {
    if (!this.hasBilling()) return;
    this.env.errorStore.action = 'getPlanDetails';
    this.observed.billingLoading = true;
    const publisher = this.env.userStore.publisher;

    const currency = getCurrency(this.env);
    logInfo('getPlanDetails() publisher: ', publisher);
    logInfo('getPlanDetails() currency: ', currency);

    const input = {
        'currency': currency,
    };

    /* MAY BE NEEDED IN THE FUTURE
    if (this.coupon === BillingCoupons.LETSDOTHIS23) {
        input.couponCode = this.coupon;
    }
    */

    const res = await apiGetPlanDetails({
        token: publisher.token,
        traceId: this.env.traceId,
        data: {
            i: input,
        },
    });

    if (res.data && res.data.data && res.data.data.getPlanDetails) {
        const edges = res.data.data.getPlanDetails.edges;
        this.planDetails = edges.map(edge => {
            const {
                description,
                bullets,
            } = parseDescriptionAndBullets(edge.node.description);
            return {
                ...edge.node,
                description,
                bullets,
                id: edge.node.id || '-',
            };
        });

        this.planDetails = sortPlan(this.planDetails);
        this.fullPlans = [...this.planDetails];
        this.removeLowerTier();
        this.observed.planDetails = uuid();
        if (!isEmpty(this.publisherPlan)) {
            const addOns = getCurrentPlanAddOns(this.publisherPlan, this.fullPlans);
            if (addOns) {
                logInfo('addOn with Handles: ', addOns);
                this.publisherPlan.addOns = addOns;
            }
            this.observed.publisherPlan = uuid();
            this.selectPlanFromID(this.publisherPlan.id);
        }
    }
    this.observed.billingLoading = true;
}

export function removeLowerTier (updateObservable = false) {
    // logInfo('BillingStore.actions.removeLowerTier(): current publisherPlan: ', this.publisherPlan);
    // logInfo('BillingStore.actions.removeLowerTier(): planDetails: ', this.planDetails);

    if (isSetupPlanPage(this.env) && this.planDetails) {
        // in setup plan page, all plans should be available...
        this.planDetails = removeFreePlans(this.planDetails);
        this.observed.planDetails = uuid();
        return;
    }

    if (this.publisherPlan && this.planDetails) {
        if (this.env.settings?.allowDowngrade !== 'true') {
            this.planDetails = removeLowerPlans(this.publisherPlan, this.planDetails);
            if (updateObservable) {
                this.observed.planDetails = uuid();
            }
        }
    }
}

function removeLowerPlans (currentPlan, sortedPlans) {
    const filtered = [];

    for (let i = 0; i < sortedPlans.length; i++) {
        const plan = sortedPlans[i];
        if (currentPlan.name.toLowerCase() === PlanTypes.FREE_TRIAL &&
            plan.name.toLowerCase() === PlanTypes.FREE) {
            filtered.push(plan);
            continue;
        }
        if (Number(plan.price) > Number(currentPlan.price)) {
            filtered.push(plan);
        }
    }
    return filtered;
}

function removeFreePlans (sortedPlans) {
    const filtered = [];
    for (let i = 0; i < sortedPlans.length; i++) {
        const plan = sortedPlans[i];
        if (plan.name.toLowerCase() !== PlanTypes.FREE) {
            filtered.push(plan);
        }
    }
    return filtered;
}

function parseDescriptionAndBullets (source) {
    const list = source.split('•');
    let description = list.shift();
    description = description.split('\n')[0];
    const bullets = [...list];
    return {
        description,
        bullets,
    };
}

function sortPlan (plans) {
    function priceCompare (a, b) {
        // b a descending
        // a b ascending
        if (Number(a.price) < Number(b.price)) {
            return -1;
          }
          if (Number(a.price) > Number(b.price)) {
            return 1;
          }
          return 0;
    }

    const sortedPlans = plans.sort(priceCompare);
    if (!isEmpty(sortedPlans) &&
        sortedPlans.length > 0 &&
        sortedPlans[0].code.toLowerCase().indexOf('free') !== -1) {
            const freeplan = sortedPlans.shift();
            sortedPlans.push(freeplan);
    }
    return sortedPlans;
}

export async function getCurrentPlan () {
    if (!this.hasBilling()) return;
    this.env.errorStore.action = 'getCurrentPlan';
    this.observed.billingLoading = true;
    const publisher = this.env.userStore.publisher;

    logInfo('getCurrentPlan()');
    const currency = getCurrency(this.env);
    const res = await apiGetCurrentPlan({
        token: publisher.token,
        traceId: this.env.traceId,
        data: {
            i: {
                'currency': currency,
            },
        },
    });

    logInfo('Responded for Plan Details: ', res);
    if (res.data && res.data.data && res.data.data.getCurrentPlan) {
        const edges = res.data.data.getCurrentPlan.edges;
        if (edges.length <= 0) return;
        const planInfo = edges[0].node;
        const {
            description,
            bullets,
        } = parseDescriptionAndBullets(planInfo.description);

        this.publisherPlan = {
            ...planInfo,
            description,
            bullets,
            id: planInfo.id ? planInfo.id : '-',
            currency: getCurrency(this.env, planInfo.code),
        };

        const addOns = getCurrentPlanAddOns(this.publisherPlan, this.fullPlans);
        if (addOns) {
            this.publisherPlan.addOns = addOns;
        }

        this.observed.publisherPlan = uuid();
        if (this.publisherPlan) {
            this.selectPlanFromID(planInfo.id);
        }
        logInfo('getCurrentPlan()-removeLowerTier() call ->', this.selectedPlanData);
        this.removeLowerTier(true);
    }
}

function getAddOnFromTarget (addOns, target) {
    if (isEmpty(addOns)) {
        return null;
    }
    const filtered = addOns.filter((addOn) => {
        if (addOn.name === target.name) {
            return true;
        }
        return false;
    });
    if (isEmpty(filtered)) {
        return null;
    }
    return filtered[0];
}

function getCurrentPlanAddOns (currentPlan, planDetails) {
    const targetPlan = getPlanFromId(planDetails, currentPlan.id);
    // logInfo('getCurrentPlanAddOns(): ', targetPlan);
    if (!targetPlan) {
        return null;
    }

    const withHandles = currentPlan.addOns.map((addOn) => {
        if (!isEmpty(addOn.handle)) {
            return {
                ...addOn,
            };
        }
        const addOnDetail = getAddOnFromTarget(targetPlan.addOns, addOn);
        // logInfo('addOnDetail: ', targetPlan.addOns);
        if (!addOnDetail) {
        return {
            ...addOn,
        };
        }

        return {
        ...addOn,
        handle: addOnDetail.handle,
        };
    });

    return withHandles;
    // capture plan handle **.....
}

export function getPlanFromId (planDetails, planId) {
    if (isEmpty(planDetails)) {
        return null;
    }

    const details = planDetails.filter((plan) => {
        if (plan.id === planId) {
            return true;
        }
        return false;
    })[0];
    return details;
}

export function selectPlanFromID (planId, useFullPlans = false) {
    let plans = this.planDetails;
    if (useFullPlans) {
        plans = this.fullPlans;
    }
    if (isEmpty(plans)) {
        return;
    }

    this.selectedPlanData = plans.filter((plan) => {
        if (plan.id === planId) {
            return true;
        }
        return false;
    })[0];
    this.observed.selectedPlanData = uuid();
}

export function selectPlanFromCode (planCode) {
    this.selectedPlanData = this.planDetails.filter((plan) => {
        logInfo('selectPlanFromCode', planCode);
        if (plan.code === planCode) {
            return true;
        }
        return false;
    })[0];
    this.observed.selectedPlanData = uuid();
}

export function getPlanDetailsFromID (planId) {
    if (isEmpty(this.planDetails)) {
        return {};
    }

    const details = this.planDetails.filter((plan) => {
        logInfo('selectPlanFromID', planId);
        if (plan.id === planId) {
            return true;
        }
        return false;
    })[0];
    return details;
}

function fixAddOnType (addOns) {
    if (!addOns || isEmpty(addOns)) {
        return addOns;
    }
    const fixed = addOns.map((addOn) => {
        if (addOn.handle.indexOf('toggle') !== -1 && addOn.type === 'onOff') {
            return {
                ...addOn,
                type: 'quantity',
                quantity: 1,
            };
        }
        return { ...addOn };
    });
    // logInfo('fixAddOntype() -> ', fixed);
    return fixed;
}

export async function createSubscriptionAWSMarketplace () {
    if (!this.env.awsMarketplaceToken) {
        return null;
    }

    const params = {
        provider: 'aws',
        currency: 'us',
        token: this.env.awsMarketplaceToken,
        code: 'Free',
    };
    const res = await this.createSubscription(params, false, 'createSubscriptionAWSMarketplace');
    this.env.awsMarketplaceToken = null;
    setAWSMaketplace({ token: '' });
    // reload publisher info to auto-update info
    this.env.userStore.currentUser();
    return res;
}

export async function createSubscription (params, isFree = false, action = 'createSubscription') {
    this.env.errorStore.action = action;
    // logInfo('createSubscription: ', params);
    const publisher = this.env.userStore.publisher;
    const currency = getCurrency(this.env, params.code);
    const data = {
        provider: params.provider || 'chargify',
        currency: currency,
        code: params.code,
        addOns: fixAddOnType(params.addOns),
    };

    if (this.coupon && data.provider === 'chargify') {
        data.couponCodes = [this.coupon];
        // remove coupon codes on LETSDOTHIS when it's not a pro plan..
        if (this.coupon === BillingCoupons.LETSDOTHIS23 && data.code.indexOf('pro-30k') === -1) {
            delete data.couponCodes;
        }
    }

    if (!isFree) {
        data.ott = params.token;
    }

    console.log('createSubscription()  ', data);
    const res = await apiCreateSubscription({
        input: data,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Create Subscripition Success ? : ', res);
    console.log('createSubscription() awsMarketplaceToken -> ', this.env.awsMarketplaceToken);
    if (this.env.awsMarketplaceToken) {
        return res?.data?.data;
    }

    const {
        is3DSecure,
        actionLink,
        gatewayPaymentId,
        hasCardError,
        chargifyErr,
    } = get3DSecureInfo(res);

    console.log('Is3DSecure () -> ', is3DSecure);
    console.log('Action Link () -> ', actionLink);

    if (is3DSecure) {
        this.chargifyGatewayPaymentId = gatewayPaymentId;
        this.chargifyActionLink = actionLink;
        this.observed.chargifyActionLink = uuid();
        return { is3DSecure };
    }

    logInfo('createSubscription() -> response: ', res);

    const { path } = getURLElements();
    if (path.indexOf('/setup-plan') === -1) {
        this.env.userStore.reloadSelectedPublisher();
    }

    return {
        hasCardError,
        chargifyErr,
        is3DSecure: false,
    };
}

function get3DSecureInfo (res) {
    const data = {
        is3DSecure: false,
        actionLink: '',
    };

    if (res.data?.data?.createSubscription) {
        const edges = res.data.data.createSubscription.edges;
        if (edges.length > 0) {
            const result = edges[0].node;
            if (result.chargifyResult && !isEmpty(result.chargifyResult.errors)) {
                const chargifyErr = result.chargifyResult.errors[0];
                if (chargifyErr === ERR_3D_SECURE) {
                    data.actionLink = result.chargifyResult.actionLink;
                    data.gatewayPaymentId = result.chargifyResult.gatewayPaymentId;
                    data.is3DSecure = true;
                    return data;
                }

                if (chargifyErr) {
                    data.hasCardError = true;
                    data.chargifyErr = chargifyErr;
                }
            }
        }
    }
    return data;
}

export async function upgradeFree (skipPublisherReload = false) {
    logInfo('BillingStore.upgradeFree()');
    await this.createSubscription({
        code: 'free',
        provider: BillingProviders.ECAL,
    }, true);
    window.location.replace('/admin/settings/billing');
    if (!skipPublisherReload) {
        await this.getCurrentPlan();
        const userStore = this.env.userStore;
        await userStore.authGetPublisherInfo({
            cognitoToken: userStore.env.userCognitoToken,
            navigate: false,
            updateUserInfoLoaded: false,
            setuForPlan: false,
        });
    }
}

export async function finalize3DSecure (params, isFree = false) {
    this.env.errorStore.action = 'finalize3DSecure';

    const publisher = this.env.userStore.publisher;
    const currency = getCurrency(this.env, params.code);
    const data = {
        provider: params.provider || 'chargify',
        currency: currency,
        code: params.code,
        addOns: fixAddOnType(params.addOns),
    };

    if (this.chargifyGatewayPaymentId) {
        data.gatewayPaymentId = this.chargifyGatewayPaymentId;
    }
    if (!isFree) {
        data.ott = params.token;
    }

    logInfo(' finalize3DSecure() ->  : ', data);
    const res = await apiFinalize3DSecureSubscription({
        input: data,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    logInfo('finalize3DSecure() -> response: ', res);
    const { path } = getURLElements();
    if (path.indexOf('/setup-plan') === -1) {
        this.env.userStore.reloadSelectedPublisher();
    }

    return {
        is3DSecure: false,
    };
}

export async function updateSubscription (params, isFree = false) {
    this.env.errorStore.action = 'updateSubscription';
    // logInfo('createSubscription: ', params);
    const publisher = this.env.userStore.publisher;
    const currency = getCurrency(this.env, params.code);
    const data = {
        provider: params.provider || 'chargify',
        currency: currency,
        code: params.code,
        addOns: fixAddOnType(params.addOns),
    };
    data.ott = params.token;

    logInfo(' updateSubscription() ->  : ', data);
    const res = await apiUpdateSubscription({
        input: data,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    const {
        is3DSecure,
        actionLink,
        gatewayPaymentId,
        hasCardError,
        chargifyErr,
    } = get3DSecureInfo(res);

    console.log('Is3DSecure () -> ', is3DSecure);
    console.log('Action Link () -> ', actionLink);

    if (is3DSecure) {
        this.chargifyGatewayPaymentId = gatewayPaymentId;
        this.chargifyActionLink = actionLink;
        this.observed.chargifyActionLink = uuid();
        return { is3DSecure };
    }

    return {
        hasCardError,
        chargifyErr,
        is3DSecure: false,
    };
}
