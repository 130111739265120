import React, { useState, useRef, useEffect } from 'react';
import Filter from './index';

function useSearchFilters ({ applyFilters }) {
    const iconRef = useRef();
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);

    const [filters, setFilters] = useState({
        status: {},
        plan: {},
    });

    useEffect(() => {
        // console.log('Filter Icon Ref: ', iconRef);
        if (iconRef.current) {
            setAnchor(iconRef.current);
        }
    }, [iconRef]);

    function updateFilters (props, data) {
        const newFilters = { ...filters };
        newFilters[props] = { ...data };
        setFilters(newFilters);
    }

    function renderFilters () {
        return (
            <Filter
                anchorEl={anchor}
                open={open}
                setFiltersOpen={setOpen}
                updateFilters={updateFilters}
                filters={filters}
                onApplyFilters={() => {
                    console.log('onApplyFilters() : ');
                    applyFilters();
                }}
            />
        );
    }

    function showFilters () {
        setOpen(true);
    }

    function closeFilters () {
        setOpen(false);
    }

    return {
        renderFilters,
        showFilters,
        closeFilters,
        iconRef,
        filters,
        updateFilters,
    };
}

export default useSearchFilters;
