import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';

function Dialog (props) {
    const {
        open,
        children,
        title,
        onClose,
        disableBackdropDoubleClick,
        ...otherProps
    } = props;

    const dialogRef = useRef(null);

    useEffect(() => {
        if (open === true) {
            setTimeout(() => {
                if (dialogRef && dialogRef.current) {
                    dialogRef.current.ondblclick = (e) => {
                        if (disableBackdropDoubleClick) return;
                        if (!e.srcElement) return;
                        if (!e.srcElement.className) return;
                        const className = e.srcElement.className;
                        if (typeof className.indexOf === 'function' &&
                             className.indexOf('MuiDialog-scrollPaper') !== -1) {
                                if (typeof onClose === 'function') {
                                    onClose();
                                }
                        }
                    };
                }
            }, 1000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <MuiDialog
            ref={dialogRef}
            disableBackdropClick={true}
            open={open}
            onClose={onClose}
            {...otherProps}
        >
            { children }
        </MuiDialog>
    );
}

Dialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: () => {},
};

Dialog.defaultProps = {
    title: 'Dialog Title',
    open: false,
    onClose: () => {},
};

export default Dialog;
