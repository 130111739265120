import React from 'react';

import { Typography } from '../../../../components/Wrappers/Wrappers';

/* Custom Cells */
export function cityCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;
    return (
        <Typography
                className={classes.cityCell}
                variant={'body2'}
            >
                {row.location.city}
            </Typography>
    );
}

export function countryCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.countryCell}
                variant={'body2'}
            >
                {row.location.countryCode}
            </Typography>
    );
}

export function countCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.countCell}
                variant={'body2'}
            >
                {row.count}
            </Typography>
    );
}

export function percentageCell ({ index, row, column, props }) {
    const {
      classes,
      total,
    } = props;

    const getPercentText = () => total ? `${+((row.count / total) * 100).toFixed(2)}%` : '';

    return (
        <Typography
          className={classes.percentageCell}
          variant={'body2'}
        >
          {getPercentText() || 0}
        </Typography>
    );
}
