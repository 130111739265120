import React, { useEffect } from 'react';
// import { v4 as uuid } from 'uuid';
import { observer } from 'mobx-react';
import {
    Grid,
} from '@material-ui/core';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { useStyles } from './styles';

import { useLayoutState } from '../../app/context/LayoutContext';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/superAdmin';
// import structure from '../../components/Sidebar/SidebarStructure';
import useSidebarStructure from '../../components/Sidebar/useSidebarStructure';
import BreadCrumbs from '../../components/BreadCrumbs';
import Footer from '../../components/Footer';

import SuperAdminHome from './home';
import SuperAdminEdit from '../superadminProfile';

import { useUserStore, useEnvStore } from '../../store/models';

import useEnvHistory from '../hooks/useEnvHistory';
import useGenericErrorDialog from '../../store/models/error/useGenericErrorDialog';
/*
 * this hooks ensuer that only source of user publisher in info is this hook.
 */
import useUserPublisherInfo from '../../store/models/user/useUserPublisherInfo';
// import Error404 from '../error404';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

function SuperAdmin (props) {
    const classes = useStyles();
    const envStore = useEnvStore();
    const userStore = useUserStore();
    const layoutState = useLayoutState();
    useEnvHistory();
    const {
        user,
        publisher,
        publisherLogo,
        role,
    } = useUserPublisherInfo({ isSuperAdmin: true });

    const { whiteLabelSettings } = useWhiteLabel();

    const {
        structure,
        closeAll,
        shouldClose,
        sidebarScrollParent,
    } = useSidebarStructure({
        publisher,
        whiteLabelSettings,
        env: envStore,
        isSuperAdmin: true,
    });

    const { renderErrorDialog } = useGenericErrorDialog({ shouldSignout: false });

    useEffect(() => {
        /*
        if (publisher) {
            window.Intercom('boot', {
                app_id: window.APP_ID,
                email: publisher.email,
                user_id: publisher.publisherId,
                hide_default_launcher: true,
                // created_at: Math.round(+new Date() / 1000),
            });
        }
        */
    }, [publisher]);
    return (
        <React.Fragment>
            <Grid
                className={classes.root}
                onMouseDown={() => {
                    clearTimeout(window.moveRefreshTimeout);
                    window.moveRefreshTimeout = setTimeout(() => {
                        userStore.refreshSession();
                        userStore.expireWhenIdle();
                    }, 1000);
                }}
                >
                <Header
                    history={props.history}
                    /*
                    * in the context of observables use userStore user and publishers
                    */
                    userInfo={user}
                    publisher={publisher}
                    role={role}
                    publisherLogo={publisherLogo}
                    onSignOut={() => {
                        console.log('Signout ....');
                        userStore.signOut();
                    }}
                />

                <Sidebar
                    structure={structure}
                    closeAll={closeAll}
                    shouldClose={shouldClose}
                    scrollParent={sidebarScrollParent}
                    />

                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />
                    <BreadCrumbs publisher={publisher} />
                    <Switch>
                        <Route path='/superadmin/edit/:id' component={SuperAdminEdit} />
                        <Route path='/superadmin' component={SuperAdminHome} />
                    </Switch>
                </div>
            </Grid>
            { renderErrorDialog() }
            <Footer publisher={publisher}/>
        </React.Fragment>
    );
}

export default withRouter(observer(SuperAdmin));
