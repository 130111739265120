import { useEffect, useState } from 'react';
import { useErrorStore } from '../../../store/models';

import Validations, { validate } from '../../../utils/validations';

const messages = {

    code: {
        required: 'Recovery code is required.',
        minLength: 'Enter your recovery code',
        maxLength: 'Enter your recovery code',
        pattern: 'Recovery code only accept numerical input.',
    },

};

function useOTPError (props) {
    const {
        input,
    } = props;

    const errStore = useErrorStore();
    const [touched, setTouched] = useState({});
    const [_input, setInput] = useState(input);

    const [error, setError] = useState({
        code: '',
        disableSend: true,
    });

    function validateByChange () {
        const err = {
            code: validateCode(),
        };

        if (err.code) {
            err.disableSend = true;
        } else {
            err.disableSend = false;
        }
        setError(err);
    }

    function validateCode () {
        if (!touched.code) {
            return '';
        }
        return validate({
            rules: { ...Validations.verificationCode },
            messages: messages.code,
            value: _input.code,
        });
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in input) {
            if (input[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setInput(input);
    }, [input]);

    useEffect(() => {
        if (Object.entries(touched).length > 0) {
            validateByChange();
        }
    }, [_input]);

    useEffect(() => {
        if (errStore.authError.verifyLoginOTP) {
            setError({
                ...errStore.authError.verifyLoginOTP,
            });
        }
    }, [errStore.authError.verifyLoginOTP]);

    return {
        error,
    };
}

export default useOTPError;
