import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './squareDarkBlueSVG.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */

const DEFAULT_ICON_SIZE = 53;
const BUTTON_BASE_WIDTH = 193;

const SquareDarkBlueSVG = (props) => {
    const classes = useStyles();
    const {
        width,
        text,
        iconSize,
        onTextMatrixChange,
    } = props;
    const textRef = useRef();
    const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 58.5319 28.4999)');

    useEffect(() => {
        if (textRef.current) {
            const text = textRef.current;
            const tbox = text.getBBox();
            // Note: Always use the iconSize as an offset to center things accordingly
            const newX = (iconSize / 2) + ((BUTTON_BASE_WIDTH - tbox.width) / 2);
            const translation = 'matrix(1 0 0 1 ' + newX + ' 28.4999)'; // will be sent to the backend as textMatrix
            setTransformMatrix(translation);
        }
    }, [textRef.current, width, text]);

    useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

    return (
        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
            viewBox='0 0 202.6 48' style={{ enableBackground: 'new 0 0 202.6. 48' }} xmlSpace='preserve'>
            <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-0.9706' y1='25.0724' x2='199.4738' y2='24.1088' gradientTransform='matrix(1 0 0 -1 0 50)'>
                <stop offset='0' style={{ 'stop-color': '#00E2FF' }} />
                <stop offset='1' style={{ 'stop-color': '#00A8E2' }} />
            </linearGradient>
            <rect y='2.8' className={classes.st0} width='199.5' height='45.2' />
            <rect x='2.8' className={classes.st1} width='199.7' height='45.2' />
            <text ref={textRef} transform={transformMatrix} className={classNames([classes.st2, classes.st3, classes.st4])} >{text}</text>
            <path className={classes.st5} d="M34.6,15.6H22.5c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.6
                c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.4C22,19,22.3,19,22.5,19h12.1
                c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2,0-0.5-0.1-0.7
                c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4C35.1,15.6,34.8,15.6,34.6,15.6z"/>
            <path className={classes.st6} d="M37.4,20.9H14c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5
                h23.4c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2C38.3,21.1,37.8,20.9,37.4,20.9z"/>
            <path className={classes.st7} d="M45.6,26.2H26.3c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.6
                c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.1,0.7,0.1h19.3
                c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2,0-0.5-0.1-0.7
                c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4C46,26.2,45.8,26.2,45.6,26.2z"/>
        </svg>

    );
};

SquareDarkBlueSVG.propTypes = {
    width: PropTypes.number,
    text: PropTypes.string,
    iconSize: PropTypes.number,
    onTextMatrixChange: PropTypes.func,
};

SquareDarkBlueSVG.defaultProps = {
    width: 200,
    text: 'Sync to Calendar',
    iconSize: DEFAULT_ICON_SIZE,
    onTextMatrixChange: () => {},
};

export default SquareDarkBlueSVG;
