import { isEmpty } from 'lodash';
import { validateInputError } from '../../error/utils';

function validateEmpty (input, field) {
    const map = {
        firstName: 'First name is required',
        lastName: 'Last Name is required',
        phone: 'Phone is required',
    };

    if (isEmpty(input[field])) {
        return map[field];
    }

    return '';
}

const validations = {
    firstName: (input) => validateEmpty(input, 'firstName'),
    lastName: (input) => validateEmpty(input, 'lastName'),
    phone: (input) => validateEmpty(input, 'phone'),
};

export function inputValidator (input, touched = {}) {
    const inputError = validateInputError(input, validations, touched);
    return inputError;
}
