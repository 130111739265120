import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
// import { useHistory } from 'react-router';
import {
    Grid,
} from '@material-ui/core';

// import { useStyles } from './styles';

import {
    Typography,
    Link,
} from '../../components/Wrappers/Wrappers';

import {
    useSchedulesStore,
    useSubscribersStore,
} from '../../store/models';

import useStoreModuleDependency from '../hooks/useStoreModuleDependency';
import useUserPublisherInfo from '../../store/models/user/useUserPublisherInfo';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

import List from './subscriberList';

function Analytics (props) {
    const subscribersStore = useSubscribersStore();
    const schedulesStore = useSchedulesStore();
    const {
        whiteLabelSettings,
    } = useWhiteLabel();

    const [subscribersData, setSubscribersData] = useState([]);
    const [isFreePlan, setIsFreePlan] = useState(false);
    function initStore () {
        schedulesStore.getSchedulesForSubscribersFilter();
    }

    const {
        publisher,
    } = useUserPublisherInfo();

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        const freeRegex = /free|Free/i;
        if (freeRegex.test(publisher.planName)) {
            setIsFreePlan(true);
        } else {
            setIsFreePlan(false);
        }
    }, [publisher]);

    useEffect(() => {
        if (subscribersStore.subscribersData) {
            setSubscribersData(subscribersStore.subscribersData);
        }
    }, [subscribersStore.observed.subscribersData]);

    function handleUpgradeClick () {
        if (whiteLabelSettings.labelName === 'ecal') {
            window.Intercom('showMessages');
        } else {
            window.open(whiteLabelSettings.supportContact, '_blank');
        }
    }

    return (
        <React.Fragment>
            { !isFreePlan && (
                  <Grid container>
                    <List
                        optinsData={subscribersData}
                        showProgress={subscribersStore.observed.subsribersDataLoading}
                    />
                </Grid>
            )}

            { isFreePlan && (
                <Grid container>
                    <Typography
                        variant={'body2'}
                        weight={'light'}
                        style={{ marginBottom: 30 }}
                    >
                        This feature is not available on your current plan. Please contact  &nbsp;
                            <strong>
                                <u>
                                    <Link
                                        onClick={handleUpgradeClick}>
                                        {whiteLabelSettings.supportLabel}
                                    </Link>
                                </u>
                            </strong>
                            &nbsp; to upgrade or make changes to your plan.
                    </Typography>
                </Grid>
            )}

        </React.Fragment>

    );
}

export default observer(Analytics);
