import { validateInputError } from '../../error/utils';
import { configButtonWidth } from '../../../../utils/constants';

const {
  BUTTON_MAX_WIDTH,
  BUTTON_MIN_WIDTH,
  BUTTON_ICON_MAX_WIDTH,
  BUTTON_ICON_MIN_WIDTH,
} = configButtonWidth;

function validateName (input) {
    if (!input.name || input.name.length === 0) {
        return 'Button display ID is required';
    }
    return '';
}

function validateWidth (input) {
    console.log('validateWidth() Validating Width: ', input);
    let err = '';

    const width = Number(input.style.width);
    console.log('Width: ', width);
    if (input.style.template === 'ecal-icon') {
      if (width < BUTTON_ICON_MIN_WIDTH) {
        err = `Min size for icon button is ${BUTTON_ICON_MIN_WIDTH}.`;
      }
      if (width > BUTTON_ICON_MAX_WIDTH) {
        err = `Max size for icon button is ${BUTTON_ICON_MAX_WIDTH}.`;
      }
    } else {
      if (width < BUTTON_MIN_WIDTH) {
          err = `Min size for round, pill and square button is ${BUTTON_MIN_WIDTH}.`;
      }
      if (width > BUTTON_MAX_WIDTH) {
          err = `Max size for round, pill and square button is ${BUTTON_MAX_WIDTH}.`;
      }
    }

    console.log('Width Error: "' + err + '"');
    return err;
}

const validations = {
    name: validateName,
    width: validateWidth,
};

export function inputValidator (input, touched = {}) {
    const inputError = validateInputError(input, validations, touched);
    return inputError;
}
