import axios from 'axios';

/*
 * Access APIV3 service
 * dev = testing-api
 * stage = staging-api
 * prod = api
 */
export async function apiV3Location (apiKey, traceId) {
    let env;
    switch (window.env) {
        case 'dev':
            env = 'testing-api';
            break;
        case 'stage':
            env = 'staging-api';
            break;
        case 'prod':
            env = 'api';
            break;
        default:
            env = 'testing-api';
    }
    const locationUrl = `https://${env}.ecal.com/apiv3/ip/location?apiKey=${apiKey}&traceId=${traceId}`;
    if (env && apiKey && traceId) {
        const res = await axios.get(locationUrl);
        return res.data;
    }
}
