import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './roundOutlineSVG.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */

const DEFAULT_ICON_SIZE = 53;
const BUTTON_BASE_WIDTH = 192;

const RoundOutlineSVG = (props) => {
	const classes = useStyles();
	const {
		width,
		text,
        iconSize,
        onTextMatrixChange,
	} = props;
	const textRef = useRef();
	const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 58.5319 28.4999)');

    useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

	useEffect(() => {
		if (textRef.current) {
			const text = textRef.current;
			const tbox = text.getBBox();
            // Note: Always use the iconSize as an offset to center things accordingly
			const newX = (iconSize / 2) + ((BUTTON_BASE_WIDTH - tbox.width) / 2);
			const translation = 'matrix(1 0 0 1 ' + newX + ' 28.4999)'; // will be sent to the backend as textMatrix
			setTransformMatrix(translation);
		}
	}, [textRef.current, width, text]);

	return (
		<svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 202.6 48"
            style={{ enableBackground: 'new 0 0 202.6. 48' }}
            xmlSpace="preserve"
            xmlnsXlink='http://www.w3.org/1999/xlink'
            >
                <g>
	                <path className={classes.st0} d="M197.1,1c2.5,0,4.5,2.1,4.5,4.6v36.8c0,2.5-2,4.6-4.5,4.6H5.5C3,47,1,44.9,1,42.4V5.6C1,3.1,3,1,5.5,1H197.1
		 M197.1,0H5.5C2.5,0,0,2.5,0,5.6v36.8C0,45.5,2.5,48,5.5,48h191.6c3,0,5.5-2.5,5.5-5.6V5.6C202.6,2.5,200.1,0,197.1,0L197.1,0z"/>
                </g>

                <text
                    ref={textRef}
                    transform={transformMatrix}
                    className={classNames([classes.st0, classes.st1, classes.st2])}>
                        {text}
                </text>

                <g>
                    <defs>
                        <rect id="SVGID_1_" x="7.5" y="14" width="27" height="19"/>
                    </defs>

                    <clipPath id="SVGID_2_">
                        <use
                            xlinkHref="#SVGID_1_"
                            style={{ 'overflow': 'visible' }}
                        />
                    </clipPath>

                    <g className={classes.st3}>
                        <path className={classes.st0} d="M21.5,16.9h-6.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
                            c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h6.5
                            c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.4
                            c-0.1-0.1-0.1-0.2-0.2-0.3C22.1,17,22,17,21.8,16.9C21.7,16.9,21.6,16.9,21.5,16.9z"/>
                        <path className={classes.st0} d="M33.6,14H17c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C16,14.7,16,14.8,16,14.9
                            s0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h16.6c0.2,0,0.5-0.1,0.6-0.3
                            c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6C34,14.1,33.8,14,33.6,14z"/>
                        <path className={classes.st0} d="M30.1,31.2H17c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3C16,31.8,16,32,16,32.1
                            c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h13.1c0.2,0,0.5-0.1,0.6-0.3
                            c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C30.5,31.3,30.3,31.2,30.1,31.2z"/>
                        <path className={classes.st0} d="M21.2,25.4h-6.6c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6
                            c0.2,0.2,0.4,0.3,0.6,0.3h6.6c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4
                            c0-0.1,0-0.2-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C21.5,25.4,21.4,25.4,21.2,25.4z"/>
                        <path className={classes.st0} d="M23,19.7H10.3c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3H23
                            c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7S23.2,19.7,23,19.7z"/>
                        <path className={classes.st0} d="M27.4,22.6H17c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
                            C16,23.3,16,23.4,16,23.5s0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h10.4
                            c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4s0-0.2-0.1-0.4
                            c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C27.6,22.6,27.5,22.6,27.4,22.6z"/>
                        <path className={classes.st0} d="M19,28.3H8.4c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6
                            C8,30,8.2,30.1,8.4,30.1H19c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C19.5,28.4,19.3,28.3,19,28.3z"
                            />
                    </g>
                </g>
                <line className={classes.st4} x1="43.5" y1="1" x2="43.5" y2="47"/>
        </svg>
	);
};

RoundOutlineSVG.propTypes = {
	width: PropTypes.number,
	text: PropTypes.string,
	iconSize: PropTypes.number,
    onTextMatrixChange: PropTypes.func,
};

RoundOutlineSVG.defaultProps = {
	width: 200,
	text: 'Sync to Calendar',
	iconSize: DEFAULT_ICON_SIZE,
    onTextMatrixChange: () => {},
};

export default RoundOutlineSVG;
