import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TableContainer from '../../../../commons/ECALTable/TableContainer';
// import TableControls from '../../../../commons/ecal/tableControls';

import {
    columns,
    customCells,
    options,
  } from './tableSettings';
  import { useStyles } from '../../styles';
import CustomFooter from '../../../../commons/tables/CustomFooter';

function LocTable ({ data, isLoading }) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
      if (data) {
        const indexOfLastData = (page + 1) * 10;
        const indexOfFirstData = indexOfLastData - 10;
        const sortedLocationDetails = data.locationDetails.slice(0);
        sortedLocationDetails.sort((a, b) => {
          if (a.count === b.count) {
            return a.location.city > b.location.city ? 1 : -1;
          }
          return a.count > b.count ? -1 : 1;
        });

        const currentData = sortedLocationDetails.slice(indexOfFirstData, indexOfLastData);
        setCurrentData(currentData);
      }
    }, [data, page]);

    return (
      <Grid style={{ padding: '0 8px' }}>
        <TableContainer
          hasCheck={false}
          rowsPerPage={10}
          classes={classes}
          tableData={currentData}
          total={data?.total}
          columns={columns}
          customCells={customCells}
          tableTitle='Manage'
          moduleType='locations'
          showProgress={isLoading}
          options={options}
          isInsideWidget={false}
          removeEmptyRows
        />
        {!!data?.locationDetails.length && (
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CustomFooter
              count={data?.locationDetails?.length || 0}
              page={page}
              rowsPerPage={10}
              changePage={(page) => { setPage(page); }}
              textLabels=''
            />
          </Grid>
        )}
      </Grid>
    );
}

export default LocTable;
