import { useState, useEffect } from 'react';
import { useErrorStore } from '../../error';
import useModuleErrorDialog from '../../error/useModuleErrorDialog';

import useErrors from '../../error/useErrors';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

function useAuthError (props = {}) {
    const {
        shouldRedirect = true,
        redirectTo = '/login',
    } = props;

    const store = useErrorStore();
    const [localErrors, setLocalErrors] = useState({});

    const {
        errors: serviceErrors,
    } = useErrors({
        useTouched: true,
        hook: 'useAuthErrors',
        input: null,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.updateProfile,
            store.observed.errors.authGetPublisherInfo,
        ],
    });

    function handleClose () {
        store.observed.errors = {};
        store.errors = null;
        if (typeof shouldRedirect === 'boolean' && shouldRedirect === true) {
            // old implementation
            store.env.history.push(redirectTo);
        }

        if (typeof shouldRedirect === 'function') {
            if (shouldRedirect(localErrors)) {
                store.env.history.push(redirectTo);
            }
        }
    }

    const {
        setErrorOpen,
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors: localErrors,
        onCloseError: handleClose,
    });

    useEffect(() => {
        setLocalErrors(store.errors);
    }, [store.observed.errors.forgotPassword]);

    useEffect(() => {
        setLocalErrors(serviceErrors);
    }, [serviceErrors]);

    useEffect(() => {
        setLocalErrors(store.errors);
    }, [
        store.observed.errors.confirmSignup,
        store.observed.errors.login,
        store.observed.errors.authError,
        store.observed.errors.resendSignupCode,
    ]);

    useEffect(() => {
        if (store.errors) {
            setLocalErrors({
                title: 'Change password failed',
                message: store?.errors?.message || 'The password you entered doesn\'t meet the minimum security requirements. Ensure your password includes minimum 8 characters, at least one capital letter, and at least one special character.',
            });
        }
    }, [store.observed.errors.changePassword]);

    return {
        setErrorOpen,
        renderErrorDialog,
        errors: localErrors,
    };
}

export default useAuthError;
