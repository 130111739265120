import React from 'react';
// import PropTypes from 'prop-types';
import {
    Box,
} from '@material-ui/core';
import { LinearProgress } from '../../components/Wrappers';

function ProgressBars () {
    return (
        <Box
                my={3}
                width={'100%'}
                height={400}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                >
                <Box mt={2} mr={2} width="80%">
                    <LinearProgress />
                </Box>
                <Box mt={2} mr={2} width="80%">
                    <LinearProgress color="secondary" />
                </Box>
                <Box mt={2} mr={2} width="80%">
                    <LinearProgress color="warning" />
                </Box>
                <Box mt={2} mr={2} width="80%">
                    <LinearProgress color="success" />
                </Box>
                {/* <Box mt={1} mr={2} width="100%">
                    <LinearProgress color="info" />
                    <br />
                    <LinearProgress
                    color="info"
                    variant="determinate"
                    // value={completed}
                    />
                </Box> */}
            </Box>
    );
}
ProgressBars.propTypes = {
    //   title: PropTypes.string,
};

ProgressBars.defaultProps = {
    //   title: '',
};

export default ProgressBars;
