import { makeObservable, observable, action } from 'mobx';
import { useStores } from '../useStores';
import { randomString } from '../../../utils';

import {
    getWebhooks,
    saveWebhooks,
    deleteWebhook,
    addWebhook,
} from './actions';

export default class WebhooksStore {
    version = {}
    stores = {}
    env = null
    webhooks = null
    notificationText = ''
    notificationKey = 0
    previousNotificationKey = 0
    webhook_progressBar = false
    webhook_skeleton = false
    notificationFlag = false
    notificationConfirmation (value) {
        this.notificationText = value;
        this.notificationFlag = true;
        this.previousNotificationKey = this.notificationKey;
        this.notificationKey = randomString(20);
    }

    constructor () {
        makeObservable(this, {

            webhooks: observable,
            notificationText: observable,
            notificationKey: observable,
            webhook_progressBar: observable,
            webhook_skeleton: observable,
            getWebhooks: action,
            saveWebhooks: action,
            addWebhook: action,
            deleteWebhook: action,
        });
    }

    getWebhooks = getWebhooks;
    saveWebhooks = saveWebhooks;
    addWebhook = addWebhook;
    deleteWebhook = deleteWebhook;
}

export function useWebhooksStore () {
    const { webhooksStore } = useStores();
    return webhooksStore;
}
