import { makeObservable, observable, action } from 'mobx';
import { useStores } from '../useStores';
import { randomString } from '../../../utils';

import {

    /* Schedule Related Actions */
    addSchedule,
    getSchedules,
    updateSchedulePage,
    searchSchedules,
    renderSchedules,
    searchOrGetSchedules,
    getSchedulesForSubscribersFilter,
    getSchedulesAsFilter,

    editSchedule,
    bulkEditSchedules,
    bulkDeleteSchedules,
    makeSchedulesLive,
    makeSchedulesDraft,
    deleteSchedules,
    getSchedulesFromSelection,
    uploadScheduleLogo,
    uploadScheduleBanner,
    processSearchResult,
    getSchedulesByIds,
    resetSearchAndFilters,
} from './actions';

import {
       /* Category Related Actions */
       getRootCategories,
       getCategoriesByFilter,
       addCategory,
       loadNodesRecursive,
       debounceNodesRender,
       deleteCategory,
       loadParentChildrenTree,
       findParentRecursive,
       getCategoryInfo,
} from './category_actions';

import {
    processRoot,
    treeCheck,
    treeExpand,
    treeUpdateChildren,
    treeDeleteBranch,
    updateRoot,
    sourceToTreeNodes,
    treeNode,
    onFilterChange,
    filterTree,
} from './tree_action';

import {
    loadEditTarget,
    saveEditData,
    scheduleDataFromSelectedItem,
    resetState,
} from './actions_persist';
export default class SchedulesStore {
    version = {}
    stores = {}
    env = null

    schedulePage = 0;
    scheduleRowsPerPage = 10;
    schedulesTotalCount = 0;
    scheduleSearchText = null;
    scheduleSearchPrev = null;
    scheduleFilter = '';
    scheduleIsLoading = false;
    scheduleEditTarget = null;

    schedulesAsFilter = [];
    observed = {
        schedulesAsFilter: null,
        assets: {},
    };

    assets = {};

    schedules = [];
    notificationText = ''
    notificationKey = 0
    filterText = ''
    logoUrl = '';
    previousNotificationKey = 0
    loadingTree = false
    categoriesList = [];

    categoryData = {
        nodes: [],
        nodesFiltered: [],
        checked: [],
        expanded: [],
    };

    categoryNodes = [];
    categoryNodesFiltered = [];
    categoryChecked = [];
    categoryExpanded = [];

    schedules_progressBar = false
    notificationConfirmation (value) {
        this.notificationText = value;
        this.previousNotificationKey = this.notificationKey;
        this.notificationKey = randomString(20);
    }

    constructor () {
        makeObservable(this, {
            categoryData: observable,
            observed: observable,
            filterText: observable,
            schedules: observable,
            loadingTree: observable,
            notificationText: observable,
            notificationKey: observable,
            schedules_progressBar: observable,
            scheduleIsLoading: observable,

            schedulePage: observable,
            scheduleRowsPerPage: observable,
            schedulesTotalCount: observable,

            getSchedules: action,
        });
    }

    /* Schedule Related Actions */
    getSchedulesForSubscribersFilter = getSchedulesForSubscribersFilter;
    getSchedulesAsFilter = getSchedulesAsFilter;
    getSchedules = getSchedules;
    addSchedule = addSchedule;
    updateSchedulePage = updateSchedulePage;
    searchSchedules = searchSchedules;
    renderSchedules = renderSchedules;
    searchOrGetSchedules = searchOrGetSchedules;
    editSchedule = editSchedule;
    bulkEditSchedules = bulkEditSchedules;
    bulkDeleteSchedules = bulkDeleteSchedules;
    makeSchedulesLive = makeSchedulesLive;
    makeSchedulesDraft = makeSchedulesDraft;
    deleteSchedules = deleteSchedules;
    getSchedulesFromSelection = getSchedulesFromSelection;
    uploadScheduleLogo = uploadScheduleLogo;
    uploadScheduleBanner = uploadScheduleBanner;
    processSearchResult = processSearchResult;
    getSchedulesByIds = getSchedulesByIds;
    resetSearchAndFilters = resetSearchAndFilters;

    /* Cateogry Related Actions */
    getRootCategories = getRootCategories;
    getCategoriesByFilter = getCategoriesByFilter;
    addCategory = addCategory;
    loadNodesRecursive = loadNodesRecursive;
    debounceNodesRender = debounceNodesRender;
    deleteCategory = deleteCategory;
    loadParentChildrenTree = loadParentChildrenTree;
    findParentRecursive = findParentRecursive;
    getCategoryInfo = getCategoryInfo;

    /* Category Tree Action */
    processRoot = processRoot;
    treeCheck = treeCheck;
    treeExpand = treeExpand;
    treeUpdateChildren = treeUpdateChildren;
    updateRoot = updateRoot;
    sourceToTreeNodes = sourceToTreeNodes;
    treeNode = treeNode;
    onFilterChange = onFilterChange;
    filterTree = filterTree;
    treeDeleteBranch = treeDeleteBranch;

    /* Persist  Action */
    loadEditTarget = loadEditTarget;
    saveEditData = saveEditData;
    scheduleDataFromSelectedItem = scheduleDataFromSelectedItem;
    resetState = resetState;
}

export function useSchedulesStore () {
    const { schedulesStore } = useStores();
    return schedulesStore;
}
