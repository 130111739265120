import {
    post,
    postGQL,
    endpoints,
} from '../../api';

import {
    queryGetPlanDetails,
    queryGetCurrentPlan,
    queryCreateSubscription,
    queryFinalize3DSecure,
    queryUpdateSubscription,
} from './queries';

import { planDetails } from './mocked';

export function apiGetBillingInfo ({ data, token, traceId }) {
    const env = window.envStore;

    if (env.settings.mocked === 'true') {
        return new Promise((resolve) => {
            resolve(planDetails);
        });
    }

    return post(
        endpoints().billing,
        data,
        {
            traceId,
            token,
        }
    );
}

export function apiGetPlanDetails ({ data, token, traceId }) {
    const env = window.envStore;

    if (env.settings.mocked === 'true') {
        return new Promise((resolve) => {
            resolve(planDetails);
        });
    }

    return postGQL(
        endpoints().billing,
        queryGetPlanDetails(data),
        {
            token,
            traceId,
        }
    );
}

export function apiGetCurrentPlan ({ data, token, traceId }) {
    /*
      const env = window.envStore;
    if (env.settings.mocked === 'true') {
        return new Promise((resolve) => {
            resolve(planDetails);
        });
    }
    */

    return postGQL(
        endpoints().billing,
        queryGetCurrentPlan(data),
        {
            token,
            traceId,
        }
    );
}

export function apiCreateSubscription ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = window.envStore.errorStore.action;
    return postGQL(
        endpoints().billing,
        queryCreateSubscription({ i: { ...input } }),
        {
            token,
            traceId,
            action: window.envStore.errorStore.action,
        }
    );
}

export function apiFinalize3DSecureSubscription ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'finalize3DSecureSubscription';
    return postGQL(
        endpoints().billing,
        queryFinalize3DSecure({ i: { ...input } }),
        {
            token,
            traceId,
            action: 'finalize3DSecureSubscription',
        }
    );
}

export function apiUpdateSubscription ({ input, token, traceId }) {
    window.envStore.errorStore.gqlAction = 'updateSubscription';
    return postGQL(
        endpoints().billing,
        queryUpdateSubscription({ i: { ...input } }),
        {
            token,
            traceId,
            action: 'updateSubscription',
        }
    );
}
