import React, {
    createContext,
    useContext,
    useState,
    useEffect,
} from 'react';

const TouchContext = createContext({});

export function useTouchState () {
    const [touchStore, setTouchStore] = useState({}); // the touch store will have the namespaces
    const [touches, setTouches] = useState({});
    const [namespace, setNameSpace] = useState('');

    /* select the current active touch */
    useEffect(() => {
        setTouches(touchStore[namespace] || {});
    }, [namespace]);

    function updateTouches (prop) {
        clearTimeout(window.touchUpdateTimeout);
        function doUpdate () {
            console.log('useTouchState().updateTouches()', touchStore);
            setTouchStore({
                ...touchStore,
                [namespace]: {
                    ...touches,
                    ...prop,
                },
            });

            setTouches({
                ...touches,
                ...prop,
            });
        }
        window.touchUpdateTimeout = setTimeout(doUpdate, 400);
    }

    function selectStore (value) {
        setNameSpace(value);
    }

    function getNamespaceTouches (namespace) {
        return touchStore[namespace];
    }

    function clearTouches (namespace) {
        setTouchStore({
            ...touchStore,
            [namespace]: {},
        });
    }

    return {
        touchStore,
        setTouchStore,
        touches,
        selectStore,
        updateTouches,
        namespace,
        getNamespaceTouches,
        clearTouches,
    };
}

export function useTouchContext (storeNameSpace, props = { touchEnabled: true }) {
    const {
        touchEnabled,
    } = props;
    const {
        touches,
        updateTouches,
        selectStore,
        namespace,
        getNamespaceTouches,
        clearTouches,
    } = useContext(TouchContext);

    if (touchEnabled) {
        selectStore(storeNameSpace);
    }

    return {
        touches,
        updateTouches,
        namespace,
        getNamespaceTouches,
        clearTouches,
    };
}

export function TouchProvider ({ children }) {
    const touchStore = useTouchState();
    return (
        <TouchContext.Provider value={{ ...touchStore }}>
                { children }
        </TouchContext.Provider>
    );
}
export default TouchContext;
