import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
} from '@material-ui/core';
// import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import Dialog from '../../../commons/dialog/Dialog';
import Widget from '../../../components/Widget';

import { useStyles } from './styles';

// import { useHistory } from 'react-router';

import {
  customCells,
  options,
} from './tableSettings';

import Controls from './controls';
import useFilterInput from './useFilterInput';
function List (props) {
    const {
        showProgress,
    } = props;
   // const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
   // const [deleteTarget, setDeleteTarget] = useState(false);
    const classes = useStyles();

    const {
      input,
      updateInput,
      schedules,
      updateScheduleSelection,
      generateData,
      generateReport,
      isLoading,
      isLoadingReport,
      setIsLoading,
      setIsLoadingReport,
      reportDisabled,
      dynamicColumns,
      subscribers,
      searchSchedule,
      showScheduleLoadingProgress,
    } = useFilterInput({});

    useEffect(() => {
      setLoading(showProgress);
    }, [showProgress]);

    console.log('subscribers: , ', subscribers);
    return (
    <React.Fragment>
      <Grid item xs={12}>
        <Widget className={classes.widgetContainer}
            inheritHeight
            disableWidgetMenu
            buttonFlag={false}
            title="Manage Subscriber Data"
            >
            <Box
                justifyContent={'flex-start'}
                display={'flex'}
                flexDirection='column'
                alignItems={'flex-start'}
            >
                <Controls
                    schedules={schedules}
                    input={input}
                    updateInput={updateInput}
                    updateScheduleSelection={updateScheduleSelection}
                    setIsLoading={setIsLoading}
                    generateData={generateData}
                    isLoading={isLoading}
                    isLoadingReport={isLoadingReport}
                    generateReport={generateReport}
                    setIsLoadingReport={setIsLoadingReport}
                    reportDisabled={reportDisabled}
                    onSearchSchedule={searchSchedule}
                    showProgress={showScheduleLoadingProgress}
                />
            </Box>
        </Widget>

        <TableContainer
              rowsPerPage={subscribers.length}
              classes={classes}
              tableData={subscribers}
              moduleType='subscribers'
              columns={dynamicColumns}
              customCells={customCells}
              tableTitle='Subscriber Data'
              showProgress={loading}
              options={options}

            />

      </Grid>

      <Dialog
        openDialog={deleteOpen}
        handleAgree={() => {
          setDeleteOpen(false);
        }}

        closeModal={() => {
          setDeleteOpen(false);
        }}
        title={'Delete opt-in'}
        dialogContextText={'Are you sure you want to delete this opt-in?'}
      />
    </React.Fragment>
  );
}

List.propTypes = {
    subscribers: PropTypes.array,
    showProgress: PropTypes.bool,
};

List.default = {
    subscribers: [],
    showProgress: true,
};

export default observer(List);
