import { useState, useEffect } from 'react';
import useImageUpload from '../../../hooks/useImageUpload';
import { AssetType } from '../../../../utils/constants';
import { useButtonsStore } from '../../../../store/models';

import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
const customDefaultImageForAdUnit = [{ data_url: DEFAULT_IMAGE }];

function useButtonDesignData (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
        location,
        updateOptions,
    } = props;

    const buttonsStore = useButtonsStore();
    function getDisplayType () {
        if (location.pathname.indexOf('button') !== -1) {
            return 'button';
        }
        return 'ad';
    }

    /*
     * Note: check the mapping this will be mapped on the input during onDataChange
     * please map the neccessary info to the actual UI..
     * Please see defaultButtonNoCustomFields.json to see the actual names of fields
     */
    const [buttonDesignData, setButtonDesignData] = useState({
        name: dataSource.name,
        text: dataSource.display.text,
        width: dataSource.style.width,
        fontColour: dataSource.style.fontColour,
        backgroundColour: dataSource.style.backgroundColour,
        template: dataSource.style.template ? dataSource.style.template : 'ecal-round',
        theme: dataSource.style.theme ? dataSource.style.theme : 'custom',
        textMatrix: '',
        /* add additional fields here */
    });

    const [adsData, setAdsData] = useState({

    });

    const [displayType] = useState(getDisplayType());

    useEffect(() => {
        return () => {
            if (displayType === 'ad') return;
            // avoid calling on data change when it is ad.
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
         /* add additional fields here */

         console.log('');
         console.log('');
         console.log('');
         console.log('');
         console.log('');
         console.log('');
         console.log('');
         console.log('');
         console.log('Data Source is Updated: ', dataSource);
        setButtonDesignData({
            name: dataSource.name,
            text: dataSource.display.text,
            width: dataSource.style.width,
            fontColour: dataSource.style.fontColour,
            backgroundColour: dataSource.style.backgroundColour,
            template: dataSource.style.template && dataSource.name ? dataSource.style.template : 'ecal-pill',
            theme: dataSource.style.theme && dataSource.name ? dataSource.style.theme : 'purple',
        });
    }, [dataSource]);

    function updateDesignData (data) {
        /* do data computation before updating the buttonDesignData */
        // console.log('updateDesignData', data);
        console.log('');
        console.log('');
        console.log('updateDesignData', data);
        updateSavable({
            ...dataSource,
            name: data.name,
            display: {
                ...dataSource.display,
                text: data.text,
            },

            style: {
                ...dataSource.style,
                width: data.width,
                fontColour: data.fontColour,
                backgroundColour: data.backgroundColour,
                template: data.template,
                theme: data.theme,
            },
        });

        setButtonDesignData(data);
    }

    function updateTextMatrix (textMatrix) {
        updateOptions({
            textMatrix: textMatrix,
        });
    }

    function setAdsLogoURL (url) {
        setAdsData({
            ...adsData,
            advertising: {
                ...adsData.advertising,
                banner: url,
                url,
            },
        });
    };

    const {
        image: adsLogo,
        upload: uploadAdsLogo,
    } = useImageUpload({
        assetType: AssetType.BUTTON_AD_UNIT,
        observableDataSource: buttonsStore.observed.assets[AssetType.BUTTON_AD_UNIT],
        consumableDataSource: buttonsStore.assets[AssetType.BUTTON_AD_UNIT],
        setStateFunc: setAdsLogoURL,
        customDefaultImage: customDefaultImageForAdUnit,
    });

    return {
        buttonDesignData, /* Note instead of updating the input used buttonDesignData */
        updateDesignData,
        updateTextMatrix,
        displayType,
        uploadAdsLogo,
        adsLogo,
    };
}

export default useButtonDesignData;
