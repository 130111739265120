import { validateInputError } from '../../error/utils';
import { isNonEmptyLinkValid } from '../../../../utils/validations';

function validateName (input, errors) {
    if (!input.name || input.name.length === 0) {
        return {
            ...errors,
            name: 'Name is required',
        };
    }
    return errors;
}

function validateLocation (input, errors) {
    if (!input || !input.data) {
        return errors;
    }
    if (!input.data.location || input.data.location.length === 0) {
        return {
            ...errors,
            location: 'Location is required',
        };
    }
    return errors;
}

function validateSocialLink (input, errors) {
    const socialData = errors && errors.socialData ? errors.socialData : {};
    return {
        ...socialDataError(input, socialData),
    };
}

function socialDataError (input, socialData) {
    const socErr = { ...socialData };
    for (const i in input.socialData) {
        if (isNonEmptyLinkValid(input.socialData[i])) {
            delete socErr[i]; // must assigned undefined to remove on the errors list
        } else {
            // link is invalid...
          socErr[i] = 'Incorrect url format';
        }
    }
    return socErr;
}

const validations = {
    name: validateName,
    location: validateLocation,
    socialData: validateSocialLink,
};

export function inputValidator (input) {
    return validateInputError(input, validations);
}
