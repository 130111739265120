/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

 
const awsmobile = {
    /* identity pool id is independent from user pool id */
    "aws_cognito_identity_pool_id": "us-east-1:6c43834b-f940-4047-a0a1-d63438d1f81a",

    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
 
    "aws_user_pools_id": "us-east-1_Sqn7k8Uuz",
    "aws_user_pools_web_client_id": "2taqhb35gqbldsr2fhecptoq81",
    
    "oauth": {
        "domain": "ecal-test-alpha.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/home/",
        "redirectSignOut": "http://localhost:3000/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "authenticationFlowType": "USER_PASSWORD_AUTH",
};

if (process.env.REACT_APP_ENV_VAR === 'prod') {
    console.log = function () {};
}
 
console.log('');
console.log('');
console.log('Environment : ', process.env.REACT_APP_ENV_VAR );
if ( process.env.REACT_APP_ENV_VAR === 'stage') {
    console.log('using the staged build');
    awsmobile.aws_cognito_identity_pool_id = 'us-east-1:1260b2ec-e736-4f2e-9e0c-7f4580bfda13';
    awsmobile.aws_user_pools_id = 'us-east-1_TY4n2l3nk';
    awsmobile.aws_user_pools_web_client_id = '3gglpebb4ivg8ufathkk1g13pi';
    awsmobile.oauth.domain = 'ecal-stage-auth.auth.us-east-1.amazoncognito.com'
} else if ( process.env.REACT_APP_ENV_VAR === 'prod') {
    console.log('using the staged build');
    awsmobile.aws_cognito_identity_pool_id = 'us-east-1:4e151d4c-a81f-465a-ab00-4c2fb296034b'; // Identity Pool Id
    awsmobile.aws_user_pools_id = 'us-east-1_e9QegoXLi'; // pool Id 
    awsmobile.aws_user_pools_web_client_id = '2de7rns40b0g1baa38cc1r8cdi'; // APP Client
    awsmobile.oauth.domain = 'ecal-prod-auth.auth.us-east-1.amazoncognito.com';
}

export default awsmobile;


export const redirect = {

    local: {
        signin: 'https://localhost:3000/home/',
        signout: 'https://localhost:3000/login',
        baseDomain: 'localhost:3000',
    },

    dev: {
        signin: 'https://test-app.ecal.com/home/',
        signout: 'https://test-app.ecal.com/login',
        baseDomain: 'test-app.ecal.com',
    },

    stage:  {
        signin: 'https://stage-app.ecal.com/home/',
        signout: 'https://stage-app.ecal.com/login',
        baseDomain: 'stage-app.ecal.com',
    },

    prod: {
        signin: 'https://app.ecal.com/home/',
        signout: 'https://app.ecal.com/login',
        baseDomain: 'app.ecal.com',
    },
}