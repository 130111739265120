import React from 'react';
import { observer } from 'mobx-react';
import {
    Grid,
    // Tab,
    Box,
} from '@material-ui/core';
import ButtonProgress from '../../../commons/ButtonProgress';
import StepDisplay from '../../../commons/ecal/steps';
/*
import {
    Dehaze as DehazeIcon,
    Link as LinkIcon,
    Share as ShareIcon,
} from '@material-ui/icons';
*/

import { useStyles } from './styles';
import useModuleInput from './useModuleInput';
import useStep from './useStep';
import ImportScheduleDetails from './ImportScheduleDetails';
import ImportUploadCSV from './ImportUploadCSV';
import ImportResults from './ImportResults';
import useSpreadSheet from './useSpreadSheet';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';

function ImportEvents (props) {
    const {
        location,
    } = props;

    const {
        readSpreadsheet,
        eventsData,
        spreadsheetFile,
    } = useSpreadSheet();

    const {
        progress,
        dataProvider,
        input,
        updateInput,
        events,
        saveEvents,
        uploadSpreadsheet,
        hasSpreadsheetErrors,
        errors,
        warnings,
        scheduleProvider,
        searchSchedule,
        onSelectSchedule,
        serviceErrors,
        setProgress,

        genres,
        subGenres,
        showSubGenres,
    } = useModuleInput({
        location,
        eventsData,
        spreadsheetFile,
    });

    const {
        buttons,
        next,
        back,
        stepInfo,
        retry,
    } = useStep({
        input,
        events,
        spreadsheetFile,
    });

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors: serviceErrors,
        onCloseError: () => {
            back();
            setProgress({ ...progress, save: false });
        },
    });

    async function nextOrUpload () {
        if (stepInfo.activeStep === 1) {
            next();
            await uploadSpreadsheet();
        } else {
            next();
        }
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid
                container
                item
                justify={'center'}
            >
                <Grid className={classes.root}>

                    <Grid className={classes.stepperContainer}>
                        <StepDisplay
                            steps={stepInfo.steps}
                            activeStep={stepInfo.activeStep}
                        />
                    </Grid>

                    <Grid className={classes.steps}>

                        {stepInfo.activeStep === 0 && (
                             <ImportScheduleDetails
                                input={input}
                                setInput={updateInput}
                                genres={genres}
                                subGenres={subGenres}
                                showSubGenres={showSubGenres}

                                scheduleProvider={scheduleProvider}
                                onSearchSchedule={(search) => searchSchedule(search)}
                                onSelectSchedule={onSelectSchedule}
                             />
                        )}

                        {stepInfo.activeStep === 1 && (
                             <ImportUploadCSV
                                input={input}
                                setInput={updateInput}
                                dataProvider={dataProvider}
                                readSpreadsheet={readSpreadsheet}
                             />
                        )}

                        {stepInfo.activeStep === 2 && (
                             <ImportResults
                                errors={errors}
                                warnings={warnings}
                                eventsData={events}
                             />
                        )}

                    </Grid>

                    <Box
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'space-between'}
                        >
                            <ButtonProgress
                                label="Back"
                                variant="outlined"
                                disabled={buttons.backDisabled}
                                onClick={(e) => {
                                    back();
                                }}
                            />

                            { stepInfo.activeStep < 2 && (
                                <ButtonProgress
                                    disabled={buttons.nextDisabled}
                                    showProgress={progress.upload}
                                    label="Next"
                                    onClick={(e) => {
                                        nextOrUpload();
                                    }}
                                />
                            )}
                            {stepInfo.activeStep >= 2 && (
                                <ButtonProgress
                                    showProgress={progress.save}
                                    variant={hasSpreadsheetErrors ? 'outlined' : 'contained'}
                                    label={hasSpreadsheetErrors ? 'Retry' : 'Import' }
                                    onClick={(e) => {
                                        if (hasSpreadsheetErrors) {
                                            retry();
                                        } else {
                                            saveEvents();
                                        }
                                    }}
                                />
                            )}

                    </Box>

                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>

    );
};

export default observer(ImportEvents);
