import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
} from '@material-ui/core';

import IconButtons from './iconButtons';
import PopColumns from './popColumns';
import useStyles from './styles';
import useColumnSelection from './useColumnSelection';
function TableControls (props) {
    const {
        columns: columnsBase,
        excluded,
        filters,
        onColumnChange,
        title,
        hasSearch,
    } = props;
    const classes = useStyles();

    const {
        selected,
        setSelected,
    } = useColumnSelection({
        columns: columnsBase,
        onColumnChange,
        fieldSelection: 'id',
    });

    const [columns, setColumns] = useState({
        open: false,
        anchorEl: null,
        selected: [], /* default all */
    });

    return (
        <React.Fragment>
            <Grid className={classes.controlRoot}>
                <Grid className={classes.title}>
                    <Typography variant={'h6'}>
                        {title}
                    </Typography>
                </Grid>

                <IconButtons
                    hasSearch={hasSearch}
                    hasColumns={(columnsBase && columnsBase.length > 0)}
                    hasFilters={(filters && filters.length > 0)}
                    onSearchClick={(e) => {

                    }}

                    onFilterClick={(e) => {

                    }}

                    onColumnClick={(e) => {
                        setColumns({
                            ...columns,
                            open: true,
                            anchorEl: e.target,
                        });
                    }}
                />

            </Grid>

            <PopColumns
                open={columns.open}
                anchorEl={columns.anchorEl}
                columns={columnsBase}
                excluded={excluded}

                selected={selected}
                onUpdateSelection={(newSelection) => {
                    setSelected(newSelection);
                }}

                fieldSelection='id'

                onClose={() => {
                    setColumns({
                        ...columns,
                        open: false,
                    });
                }}
            />
        </React.Fragment>
    );
}

TableControls.propTypes = {
    columns: PropTypes.array,
    excluded: PropTypes.array,
    onColumnChange: PropTypes.func,
    title: PropTypes.string,
    hasSearch: PropTypes.bool,
};

/*
 * Filters Schema
    [
        {
            name: 'status',
            options: [
                {
                    value: 'draft', label: 'Draft',
                },

                {
                    value: 'live', label: 'Live',
                },
            ],
        },
    ]
 */

TableControls.defaultProps = {
    columns: [],
    excluded: [],
    onColumnChange: () => {},
    title: '',
    hasSearch: true,
    filters: [],
};

export default TableControls;
