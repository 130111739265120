import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';

import {
    FileCopy as CopyIcon,
} from '@material-ui/icons';

import {
    Typography,
} from '../../../components/Wrappers';

import Select from '../../../commons/select/Select';

import {
    defaultDisplayMode,
    defaultCalendarDisplayView,
    displayModes,
    calendarViews,
    withMode,
    withView,
} from '../utils';

import { useStyles } from './styles';
import DefaultCode, {
    defaultApiKey,
    bodyCode,
    instruction1,
    instruction2,
} from './utils';

const CONFIGURATIONS = [
  {
    value: 'data-ecal-btn-code',
    key: 'btn-code',
    label: 'Button code',
},
{
    value: 'data-ecal-no-styling',
    key: 'no-styling',
    label: 'Button without styling',
  },
];
function ButtonCodeWebsite (props) {
    const {
        widgetId,
        apiKey,
        options,
        buttonData,
    } = props;

    const classes = useStyles();
    const [config, setConfig] = useState(CONFIGURATIONS[0].value);
    const [widgetCode, setWidgetCode] = useState(bodyCode.replace('{WIDGET_ID}', widgetId));
    const [headCode, setHeadCode] = useState(DefaultCode.replace('{APIKEY_CODE_CODE}', apiKey));
    const [mode, setMode] = useState(defaultDisplayMode);
    const [view, setView] = useState(defaultCalendarDisplayView);

    useEffect(() => {
        const code = bodyCode.replace('{WIDGET_ID}', widgetId).replace('{CONFIG}', config || '');
        setWidgetCode(withMode(code, mode));
    }, [widgetId, config]);

    useEffect(() => {
        const code = DefaultCode.replace('{APIKEY_CODE_CODE}', apiKey);
        setHeadCode(code);
    }, [apiKey]);

    useEffect(() => {
        if (!isEmpty(buttonData) && buttonData.embed) {
           console.log('Button Data: ', buttonData);
           setWidgetCode(withMode(buttonData.embed.html, mode));
           setHeadCode(buttonData.embed.js);
        }
    }, [buttonData]);

    useEffect(() => {
        if (options.type === 'eventDisplay' && mode === 'calendar') {
            const wcode = withMode(buttonData.embed.html, mode);
            setWidgetCode(withView(wcode, view));
        } else {
            setWidgetCode(withMode(buttonData.embed.html, mode));
        }
    }, [mode]);

    useEffect(() => {
        if (options.type === 'eventDisplay' && mode === 'calendar') {
            const wcode = withMode(buttonData.embed.html, mode);
            setWidgetCode(withView(wcode, view));
        }
    }, [view]);

    function getStep2Instruction () {
        if (options.type === 'eventDisplay') {
            return instruction2.replace('{WidgetType}', 'Event Display');
        }
        return instruction2.replace('{WidgetType}', 'Button');
    }

    return (
        <Grid className={classNames([classes.buttonCodeContent, classes.topSpace])}>

          { options.showRoles && (
              <Grid data-intercom-target="website-code-style">
                <Select
                    id='config'
                    label='config'
                    name= 'config'
                    value={config}
                    labelHeading= 'Configuration'
                    placeHolder= 'Configuration'
                    handleChange={(e, data) => {
                        setConfig(e.target.value);
                    }}
                    dataForDropdown={CONFIGURATIONS}
                    helperText='Please choose an option'
                    inputProps={{
                        'data-intercom-target': 'website-code-style-select',
                    }}
                />
            </Grid>
          )}

          { options.showMode && (
              <Grid data-intercom-target="website-code-mode">
                <Select
                    id='config'
                    label='config'
                    name= 'config'
                    value={mode}
                    labelHeading= 'Mode'
                    placeHolder= 'Mode'
                    marginBottom={20}
                    handleChange={(e, data) => {
                        setMode(e.target.value);
                    }}

                    dataForDropdown={displayModes}
                    helperText='Please choose a display mode'
                    inputProps={{
                        'data-intercom-target': 'website-code-mode',
                    }}
                />
            </Grid>

          )}

        { (options.type === 'eventDisplay' && mode === 'calendar') && (
            <Grid data-intercom-target="website-code-view">
                <Select
                    id='view'
                    label='view'
                    name='view'
                    value={view}
                    labelHeading='View'
                    placeHolder='View'
                    marginBottom={0}
                    handleChange={(e, data) => {
                        setView(e.target.value);
                    }}

                    dataForDropdown={calendarViews}
                    helperText='Please choose a default view on loading of Calendar display'
                    inputProps={{
                        'data-intercom-target': 'website-code-view',
                    }}
                />
            </Grid>
        )}

            <Grid
                className={classNames([
                    classes.dialogSection,
                    classes.topSpace,
                    classes.bottomSpace,
                    classes.leftAligned,
                ])}>
                <Typography
                    variant={'sub2'}
                >
                    {instruction1}
                </Typography>
            </Grid>

            <Grid
                className={classNames([
                    classes.dialogSection,
                    classes.topSpace,
                    classes.bottomSpace,
                    classes.leftAligned,
                    classes.buttonCodeBlock,
                ])}
                data-intercom-target="website-code-script"
                >

                <Grid
                    className={classes.iconSpace}
                >
                    <Tooltip title="Copy code to clipboard.">
                        <IconButton
                            color={'primary'}
                            onClick= { (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(headCode);
                            }}
                            className={classes.buttonCodeIcon}
                        >
                            <CopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Typography
                    variant={'sub2'}
                >
                    {headCode}
                </Typography>

            </Grid>

            <Grid
                className={classNames([
                    classes.dialogSection,
                    classes.topSpace,
                    classes.bottomSpace,
                    classes.leftAligned,
                ])}
                >
                <Typography
                    variant={'sub2'}
                >
                    {getStep2Instruction()}
                </Typography>
            </Grid>

            <Grid
                className={classNames([
                    classes.dialogSection,
                    classes.topSpace,
                    classes.bottomSpace,
                    classes.leftAligned,
                    classes.buttonCodeBlock,
                ])}
                data-intercom-target="website-code-tag"
                >

                <Grid className={classes.iconSpace}>
                    <Tooltip title="Copy code to clipboard.">
                        <IconButton
                            color={'primary'}
                            onClick= { (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(widgetCode);
                            }}
                            className={classes.buttonCodeIcon}
                        >
                            <CopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Typography
                    variant={'sub2'}
                >
                    {widgetCode}
                </Typography>

            </Grid>

        </Grid>
    );
}

ButtonCodeWebsite.propTypes = {
    socialMediaLink: PropTypes.string,
    widgetId: PropTypes.string,
    apiKey: PropTypes.string,
    options: PropTypes.object,
    buttonData: PropTypes.object,
};

ButtonCodeWebsite.defaultProps = {
    socialMediaLink: '',
    widgetId: '6178af6a3caeaf000701d1af',
    apiKey: defaultApiKey,
    options: {
        showRoles: true,
        showMode: false,
    },
    buttonData: {},
};

export default ButtonCodeWebsite;
