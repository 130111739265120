import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getCountsWithComma } from '../../../../commons/util/utilities';
import { Options } from './graphOptions';
import SkeletonLoader from '../../../../components/SkeletonLoader';

export default function Charts (props) {
  const [values, setValues] = useState([]);
  const [options, setOptions] = useState(Options);

  const calendarTypes = props.data;

  const capitalizeFirstChar = (text) => text[0]?.toUpperCase() + text.slice(1);

  const getCategories = (data) => data.map(({ type }) => (
    (type === 'ics_other' && 'ICS') ||
    (type === 'windowslive' && 'Windows Live') ||
    (!type && 'Other') ||
    capitalizeFirstChar(type)
  ));
  const getValues = (data) => data.map(({ count }) => count);

  useEffect(() => {
    if (calendarTypes?.length) {
      const sortedData = [...calendarTypes].sort((a, b) => b.count - a.count);
      const newSortedData = JSON.stringify(sortedData);
      const formattedData = getCountsWithComma(JSON.parse(newSortedData));
      setOptions({
        ...options,
        xaxis: { categories: getCategories(sortedData) },
        dataLabels: { formatter: (val, series) => formattedData[series.dataPointIndex].count },
        tooltip: { y: { formatter: (val, series) => formattedData[series.dataPointIndex].count } },
      });
      setValues([{ data: getValues(sortedData) }]);
     }
  }, [calendarTypes]);

  return (
    <>
      { props.isLoading && !calendarTypes?.length
? (
        <SkeletonLoader />
      )
: (
      <ReactApexChart
        options={options}
        series={values}
        type='bar'
        height='350'
      />
      )}
    </>
  );
}
