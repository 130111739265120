import { isEmpty } from 'lodash';
import { getFeatures } from '../conf/feature';
import moment from 'moment';
import { PlanTypes, chargifyPlanIds } from './constants';

// import { history } from 'react-router-dom';
export { Roles, PlanTypes } from './constants';

export function randomStr (length) {
    return Math.random().toString(36).substr(2, length);
}

export function getEnvInfo () {
    const storeNameSpace = 'ecalAdminEnv';
    const now = new Date().getTime();
    const infoJson = localStorage.getItem(storeNameSpace);
    const info = {};
    if (!infoJson) {
        info.updated = now;
        info.user = {};
        info.page = {};
        localStorage.setItem(storeNameSpace, JSON.stringify(info));
        return info;
    }
    return JSON.parse(infoJson);
}

export function setEnvInfo (info) {
    const storeNameSpace = 'ecalAdminEnv';
    info.updated = new Date().getTime();
    localStorage.setItem(storeNameSpace, JSON.stringify(info));
}

export function setSharedAuth (data) {
    const nameSpace = 'ecal-v4-shared-auth';
    const storedData = getSharedAuth();
    const newData = {
        ...storedData,
        ...data,
    };
    localStorage.setItem(nameSpace, JSON.stringify(newData));
}

export function getSharedAuth () {
    const nameSpace = 'ecal-v4-shared-auth';
    const content = localStorage.getItem(nameSpace);
    if (!content) {
        const newContent = {};
        localStorage.setItem(nameSpace, JSON.stringify(newContent));
        return newContent;
    }
    return JSON.parse(content);
}

export function setEnvSettings (settings) {
    const storeNameSpace = 'ecalAdminEnvSettings';
    settings.updated = new Date().getTime();
    sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
}

export function getEnvSettings () {
    const storeNameSpace = 'ecalAdminEnvSettings';
    const now = new Date().getTime();
    const settingsJson = sessionStorage.getItem(storeNameSpace);
    const settings = {};
    if (!settingsJson) {
        settings.updated = now;
        sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
        return settings;
    }
    return JSON.parse(settingsJson);
}

export function storeSessionData (data) {
    const storename = 'ecal-admin-v4-persist-1';
    const current = loadSessionData();
    const reduced = {
        ...current,
        ...data,
        timestamp: new Date().getTime(),
    };
    sessionStorage.setItem(storename, JSON.stringify(reduced));
}

export function loadSessionData () {
    const storename = 'ecal-admin-v4-persist-1';
    const now = new Date().getTime();
    const dataJson = sessionStorage.getItem(storename);
    const data = {};
    if (!dataJson) {
        data.timestamp = now;
        sessionStorage.setItem(storename, JSON.stringify(data));
        return data;
    }
    return JSON.parse(dataJson);
}

export function clearEnvSettings () {
    const storeNameSpace = 'ecalAdminEnvSettings';
    const settings = {
        updated: new Date().getTime(),
    };
    sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
}

export function randomString (length = 10) {
    return Math.random().toString(26).substr(2, length);
}

export default {
    randomStr,
    getEnvInfo,
    setEnvInfo,
    randomString,
    setEnvSettings,
    getEnvSettings,
    clearEnvSettings,
};

export function getClientTimezone () {
    if (Intl) {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return '';
};

export function aToB (a) {
    let b; let c; let d; const e = {}; let f = 0; let g = 0; let h = ''; const i = String.fromCharCode; const j = a.length;
    for (b = 0; b < 64; b++) {
        e['ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.charAt(b)] = b;
    }
    for (c = 0; j > c; c++) for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8;) ((d = 255 & f >>> (g -= 8)) || j - 2 > c) && (h += i(d));
    return h;
}

export function dataURItoFileBlob (dataURI) {
    const byteString = aToB(dataURI.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    const buffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // New Code
    return new Blob([buffer], { type: mimeString });
}

export function imageFileFromDataURI (dataURI, fileName = 'image.png', type = 'img/png') {
    let file = null;
    file = new File(
        [dataURItoFileBlob(dataURI)],
        fileName,
        {
            type,
        }
    );

    /* for IE
    const imgBlob = new Blob([dataURItoFileBlob(dataURI)], { type });
        imgBlob.fileName = fileName;
        imgBlob.lastModifiedDate = new Date();
        file = Object.assign(imgBlob, { type });
        file = imgBlob;
        */
    return file;
}

export function getURLPathname (url) {
    if (!url) {
        return '';
    }
    const urlObj = new URL(url);
    return urlObj.pathname.substring(0);
}

export function fixLogoPath (path) {
    if (!path) {
        return '';
    }
    const features = getFeatures();
    const pattern = /(http:)|(https:)/gi;
    if (!pattern.test(path)) {
        return features.assetsRoot + path;
    }
    return path;
}

export function logInfo (...args) {
    const env = process.env.REACT_APP_ENV_VAR;
    if (env === 'prod') {
        return;
    }
    console.log('');
    console.log('');
    console.log(...args);
}

export function logWarn (...args) {
    const env = process.env.REACT_APP_ENV_VAR;
    if (env === 'prod') {
        return;
    }
    console.warn(...args);
}

export function logError (...args) {
    const env = process.env.REACT_APP_ENV_VAR;
    if (env === 'prod') {
        return;
    }
    console.error(...args);
}

export function logClear () {
    if (!console) {
        return;
    }

    if (!window.consoleAPI) {
        if (typeof console._commandLineAPI !== 'undefined') {
            window.consoleAPI = console._commandLineAPI; // chrome
        } else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
            window.consoleAPI = console._inspectorCommandLineAPI; // Safari
        } else if (typeof console.clear !== 'undefined') {
            window.consoleAPI = console;
        }
    }
    window.consoleAPI.clear();
}

export function getURLElements () {
        const location = window.location;
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const path = window.location.pathname;
        return {
            params,
            path,
        };
}

export function scrollToTop () {
    // const root = document.getElementById('root');
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
}

export function getSubdomain () {
    const domain = /:\/\/([^/]+)/.exec(window.location.href)[1];
    let subdomain = domain.split('.')[0];
    if (subdomain !== domain) {
        subdomain = subdomain.replace('stage-', '');
        subdomain = subdomain.replace('test-', '');
        // has sub domain
        return subdomain;
    }
    return '';
}

export function removeSubdomainTemporarily () {
    const domain = /:\/\/([^/]+)/.exec(window.location.href)[1];
    const loc = window.location;
    console.log('Locaiton: ', loc);
    const subdomain = domain.split('.')[0];
    if (subdomain !== domain) {
        // has sub domain
        const host = loc.host.replace(`${subdomain}.`, '');
        const url = `https://${host}${loc.pathname}`;

        window.history.pushState(
            null,
            'Admin ECAL',
            url
        );
        // window.history.replaceState({}, 'Admin ECAL', url);
    }
}

export function urlParams (url) {
    const pstr = url.split('?')[1];
    const pArr = pstr.split('&');
    const params = {};
    for (let i = 0; i < pArr.length; i++) {
        const pair = pArr[i].split('=');
        const key = pair[0];
        let val = '';
        if (pair[1]) {
            val = pair[1];
        }
        params[key] = val;
    }
    return params;
}

export function daysDiff (targetDate) {
    logInfo('daysDiff()', targetDate);
    const now = moment();
    const tdate = moment(targetDate);
    return now.diff(tdate, 'days');
}

export function isFreeTrialExpired (params) {
    const features = getFeatures();

    if (!features.billing_v4?.active) {
        return false;
    }

    const {
        publisher,
        role,
        whiteLabelSettings,
    } = params;

    if (isEmpty(publisher) ||
        isEmpty(publisher.planName)
    ) {
        return false;
    }

    if (role.superAdmin ||
        whiteLabelSettings.labelName !== 'ecal') {
        return false;
    }

    const plan = publisher.planName.toLowerCase();
    if (isFreeTrial(plan)) {
        const daysPassed = daysDiff(publisher.dateExpiry); //
        if (daysPassed > 1) {
            return true;
        }
    }
    return false;
}

export function isFreeTrial (plan) {
     // note enable free for testing purposes only...
    if (!plan) {
        return false;
    }
     const p = plan.toLowerCase();
    if (p.indexOf(PlanTypes.FREE_TRIAL) !== -1 ||
        // p.indexOf(PlanTypes.FREE) !== -1 ||
        p.indexOf(PlanTypes.FREE_TRIAL_2) !== -1
    ) {
        return true;
    }
    return false;
}

export function isFreePlan (plan) {
    // note enable free for testing purposes only...
   if (!plan) {
       return false;
   }
    const p = plan.toLowerCase();
   if (p === PlanTypes.FREE) {
       return true;
   }

   if (p.indexOf(PlanTypes.FREE) !== -1) {
        return true;
   }
   return false;
}

export function isEnterprise (planData) {
    if (isEmpty(planData) || isEmpty(planData.code)) {
        return false;
    }
    if (planData.code.toLowerCase().indexOf('enterprise') !== -1) {
        return true;
    }
    return false;
}

/*
 * Will be defaulted to Free and By Pass Plan Setup...
 */
export function isSelectedPlanAvailable (plan, plans) {
    logInfo('isSelectedPlanAvailable: ', plan);
    logInfo('plans: ', plans);
    if (!plan && isEmpty(plans)) {
        return false;
    }

    for (let i = 0; i < plans.length; i++) {
        const p = plans[i];
        if (p.code === plan) {
            return true;
        }
    }
    return false;
}

export function colorPickerValueToHex (value) {
    if (Array.isArray(value.raw)) {
       return `#${value.hex}`;
    }
    if (value.raw.indexOf('#') !== -1) {
        return value.raw;
    }
    return value.raw;
}

const DEFAULT_CURRENCY = 'au';

export function isSetupPlanPage (env) {
    logInfo('isSetupPlanPage() ', env.settings);
    if (!isEmpty(env.settings) &&
        !isEmpty(env.settings.path)) {
        if (env.settings.path.indexOf('/setup-plan') !== -1) {
            return true;
        }
    }
    return false;
}

export function getCurrency (env, code) {
    if (isSetupPlanPage(env)) {
        const settings = env.getEnvSettings();
        if (settings && settings.currency) {
            logInfo('getCurrency() -> returning URL query currency.');
            return settings.currency;
        }

        if (settings && !isEmpty(settings?.plan)) {
            logInfo('getCurrency() -> returning URL query plan prefix currency.');
            const ccy = settings.plan.split('-')[0];
            if (ccy.toLowerCase() !== 'free' && !isEmpty(ccy) && ccy.length <= 3) {
                return ccy;
            }
        }
    }

    const publisher = env.userStore.publisher;
    logInfo('getCurrency() -> publisher ? ', publisher);
    if (publisher && publisher.subscriptionPlanCurrency) {
        logInfo('getCurrency() -> returning subscriptionPlanCurrency');
        return publisher.subscriptionPlanCurrency;
    }

    if (!code) {
        logInfo('getCurrency() -> returning DEFAULT CURRENCY');
        return DEFAULT_CURRENCY;
    }

    if (code.indexOf('free') !== -1) {
        logInfo('getCurrency() -> returning DEFAULT CURRENCY because FREE ACCOUNT');
        return DEFAULT_CURRENCY;
    }

    const codeCurrency = code.split('-')[0];

    if (codeCurrency.length < 2) {
        logInfo('getCurrency() -> returning code DEFAULT CURRENCY code Currency is short');
        return DEFAULT_CURRENCY;
    }

    logInfo('getCurrency() -> returning code  Code Currency from prefix');
    return codeCurrency;
}

export function isPremiumPlan (plan) {
    if (isEmpty(plan)) {
        return false;
    }
    const p = plan.toLowerCase();
    if (p.indexOf(PlanTypes.ENTERPRISE) !== -1 ||
        p.indexOf(PlanTypes.PROFESSIONAL) !== -1 ||
        p.indexOf(PlanTypes.PRO) !== -1 ||
        p.indexOf(PlanTypes.SOCIAL) !== -1 ||
        p.indexOf(PlanTypes.EXPERT) !== -1

    ) {
        return true;
    }
    return false;
}

export function isChargifyAccount (planId) {
    let isChargify = false;
    chargifyPlanIds.forEach((plan) => {
        if (!isChargify && plan === planId) {
            isChargify = true;
        }
    });
    logInfo('isCharigfyAccount() Plan Id is -> ', planId);
    logInfo('isCharigfyAccount() -> ', isChargify);
    return isChargify;
}

export function sortByPropASC (list, prop) {
    return list.sort((a, b) => {
        return a[prop] - b[prop];
    });
}

export function sortByPropDESC (list, prop) {
    return list.sort((a, b) => {
        return b[prop] - a[prop];
    });
}

export function toDateString (date) {
    if (!date) {
        return '';
    }
    return moment(date).format('yyyy-MM-DD');
}

export function toDate (dateStr) {
    if (!dateStr) {
        return null;
    }
    return moment(dateStr).toDate();
}

export function toExpiryDate (dateStr) {
    // add 30 days from date
    const date = toDate(dateStr);
    // logInfo('toExpiryDate() origin -> ', dateStr);
    Date.parse(dateStr);
    const xDate = moment(date).add(30, 'days');
    const xdStr = toDateString(xDate);
    // logInfo(' the Expiry Date String -> ', xdStr);
    return xdStr;
}

export function toDateCreated (dateStr) {
    // subtract 30 days from date

    const date = toDate(dateStr);
    // logInfo('toDateCreated() dateStr -> ', dateStr);
    Date.parse(dateStr);
    const xDate = moment(date).subtract(30, 'days');
    const xdStr = toDateString(xDate);
    // logInfo(' the Created Date String -> ', xdStr);
    return xdStr;
}

export function trackIntercom ({ event, data, label = 'Profile Updated' }) {
    logInfo('trackIntercomEvent Event -> ', event);
    logInfo('trackIntercomEvent Data -> ', data);
    window.Intercom(
        'trackEvent',
        label,
        {
            action: event,
            ...data,
        }
    );
}

// this can be reused by any store
export function setLocalStoreData (name, data) {
    const storedData = getLocalStoreData(name);
    const newData = {
        ...storedData,
        ...data,
    };
    localStorage.setItem(name, JSON.stringify(newData));
}

export function getLocalStoreData (name) {
    const content = localStorage.getItem(name);
    if (!content) {
        const newContent = {};
        localStorage.setItem(name, JSON.stringify(newContent));
        return newContent;
    }
    return JSON.parse(content);
}

export function getAWSMaketplace () {
    return getLocalStoreData('ecal-v4-aws-marketplace');
}

export function setAWSMaketplace (data) {
    setLocalStoreData('ecal-v4-aws-marketplace', data);
}
