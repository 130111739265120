import { useEffect, useState } from 'react';

function useCustomFieldDialog (props) {
    const {
        mode,
    } = props;
    const [openCustomField, setOpenCustomField] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [title, setTitle] = useState('Add Custom Field');
    useEffect(() => {
        if (mode === 'add') {
            setTitle('Add Custom Field');
        } else {
            setTitle('Edit Custom Field');
        }
    }, [mode]);

    return {
        openCustomField,
        setOpenCustomField,
        title,
        deleteOpen,
        setDeleteOpen,
    };
}

export default useCustomFieldDialog;
