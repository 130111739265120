
import { logInfo } from '../../../utils';
import defaultEcal from './defaultEcal';
import funUnlimited from './funUnlimited';
import superAdmin from './superAdmin';
import enterprise from './enterprise';
import prodEvensHelix from './prod.events.helixleisure.net';

const sidebarBySettings = {
    ecal: defaultEcal,
    enterprise: enterprise,
    funUnlimited: funUnlimited,
    prodEvensHelix: prodEvensHelix,
    superAdmin: superAdmin,
};

export function getSidebarStructureData (name, env) {
    if (env) {
        let plan = env.userStore?.publisher?.planName;
        if (plan) {
            plan = plan.toLowerCase();
            if (plan.indexOf('enterprise') !== -1) {
                return enterprise;
            }
        }
    }
    logInfo('getSidebarStructureData(): ', name);
    if (sidebarBySettings[name]) {
        // logInfo('getSidebarStructureData() for publisher', name);
        return sidebarBySettings[name];
    }
    // logInfo('getSidebarStructureData() for ECAL');
    return sidebarBySettings.ecal;
}

export function isGettingStartedOpen (env) {
    const url = window.location.href;

    if (url.indexOf('?product_tour_id') !== -1) {
        if (env) {
            env.onTour = true;
        }
    }

    logInfo('components.Sidebar.clients.isGettingsStartedOpen(): ', url);
    if (url.indexOf('admin/home/summary?product_tour_id=339837') !== -1) {
       return true;
    }

    return false;
}
