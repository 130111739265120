
import { makeStyles } from '@material-ui/core/styles';
import EcalLogo from '../../commons/images/brandImage/ECAL_Logo.svg';

const styles = (theme) => ({

    container: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
      },

      logotypeContainer: {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: '#12012C',
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      logotypeImage: {
        width: '100%',
        // height: '100vh',
      },

      logotypeText: {
        color: 'white',
        fontWeight: 500,
        fontSize: 84,
        [theme.breakpoints.down('md')]: {
          fontSize: 48,
        },
      },
      welcomeText: {
        fontWeight: '300!important',
      },

      buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: '24px',
      },

      messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxHeight: '320px',
      },

      messageTitle: {
        marginBottom: '24px',
      },

      formContainer: {
        width: '40%',
        height: '100%',
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          width: '50%',
          overflow: 'visible',
        },
      },
      form: {
        width: 410,
      },

      formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
      },

      forgetButton: {
        textTransform: 'none',
        fontWeight: 400,
      },

      copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: 'nowrap',
        position: 'fixed',
        bottom: '0',
        opacity: '0.7',
        [theme.breakpoints.up('md')]: {
          // bottom: theme.spacing(2),
        },
      },

      logoImageContainer: {
        width: '100%',
        height: '90px',
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 20px 10px 0px',
      },
      logoImage: {
        backgroundSize: '150px',
        backgroundImage: `url(${EcalLogo})`,
        width: '150px',
        height: '30px',
      },
});

export default styles;
export const useStyles = makeStyles(styles);
