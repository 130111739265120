import React, { useState, useEffect } from 'react';
import moment from 'moment';
// import { Grid } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { Options } from './graphOptions';
import { getCountsWithComma } from '../../../../commons/util/utilities';
import SkeletonLoader from '../../../../components/SkeletonLoader';

// components
// import Widget from '../../../components/Widget/Widget';

const values = {
  series: [
    {
      name: 'Click Throughs',
      type: 'column',
      data: new Array(30).fill(0),
    }, {
      name: 'Event Impressions',
      type: 'area',
      data: new Array(30).fill(0),
    }, {
      name: 'Reminder Impressions',
      type: 'line',
      data: new Array(30).fill(1),
    },
  ],
};

export default function Charts ({
  reminderImpressions,
  eventImpressions,
  analyticsClicks,
  isLoading,
}) {
  const [data, setData] = useState(values.series);
  const [options, setOptions] = useState(Options);

  const getDates = (list) => list?.map(({ date }) => moment(date).format('DD MMM'));
  const getDataFromList = (list) => list?.map(data => data.count);

  const getFormattedData = (data) => {
    const stringifiedData = JSON.stringify(data);
    return getCountsWithComma(JSON.parse(stringifiedData));
  };

  const getTooltipFormatter = (data) => ({ formatter: (val, series) => data[series.dataPointIndex]?.count });

  useEffect(() => {
    const newData = [...data];
    if (reminderImpressions?.length && analyticsClicks?.length && eventImpressions?.length) {
      newData[0].data = getDataFromList(analyticsClicks);
      newData[1].data = getDataFromList(eventImpressions);
      newData[2].data = getDataFromList(reminderImpressions);

      const formattedAnalyticsClicks = getFormattedData(analyticsClicks);
      const formattedEventImpressions = getFormattedData(eventImpressions);
      const formattedReminderImpressions = getFormattedData(reminderImpressions);

      setOptions({
        ...options,
        labels: getDates(reminderImpressions),
        tooltip: {
          y: [
            getTooltipFormatter(formattedAnalyticsClicks),
            getTooltipFormatter(formattedEventImpressions),
            getTooltipFormatter(formattedReminderImpressions),
          ],
        },
      });
      setData(newData);
    }
  }, [reminderImpressions, analyticsClicks, eventImpressions]);

  return (
    <div style={{ minHeight: '200px' }}>
      { isLoading
? (
        <SkeletonLoader />
      )
 : (
        <ReactApexChart
          options={options}
          series={data}
          type='line'
          height='350'
        />
      )}
    </div>
  );
}
