import React from 'react';

import {
    Box,
} from '@material-ui/core';

import { Typography } from '../../../components/Wrappers';

// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
//    CreateOutlined as CreateIcon,
    DateRange,
} from '@material-ui/icons';

/*
 * Note:
 * id field in the column matches ff:
 *      the row field or data field from api response
 *      customCell renderers
 */
export const columns = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'ref',
         numeric: true,
        disablePadding: false,
        label: 'Reference',
    },

    {
        id: 'message',
        numeric: true,
        disablePadding: false,
        label: 'Message Text',
        cellClassName: 'messageCell',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

/* Custom Cells */

function wideCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.wideCell}
                variant={'body2'}
            >
                {row[column.id]}
            </Typography>
    );
}

function widerCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.widerCell}
                variant={'body2'}
            >
                {row[column.id]}
            </Typography>
    );
}

function longCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.longCell}
                variant={'body2'}
            >
                {row[column.id]}
            </Typography>
    );
}

export const customCells = {
    'Logged': wideCell,
    'First Name': wideCell,
    'Last Name': wideCell,

    'Schedules': widerCell,
    'Opt-in-1-Text': longCell,
    'Opt-in-2-Text': longCell,
    'Opt-in-3-Text': longCell,
    'Opt-in-4-Text': longCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <DateRange fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
