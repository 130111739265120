import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
// import { logInfo } from '../../../utils';
import { ErrorActionSkipList } from './error_map';

const MockedErrorsType = {
    INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
    INSUFFICIENT_ACCESS_PRIVILEGE: 'INSUFFICIENT_ACCESS_PRIVILEGE',
    PUBLISHER_INACTIVE: 'PUBLISHER_INACTIVE',
    UNHANDLED_GENERIC_ERROR: 'UNHANDLED_GENERIC_ERROR',
    NULL: 'null',
};

export function mockedErrors (type) {
    const mocked = {
        [MockedErrorsType.INVALID_ACCESS_TOKEN]: () => ({
            error: 'INVALID_ACCESS_TOKEN',
        }),

        [MockedErrorsType.INSUFFICIENT_ACCESS_PRIVILEGE]: () => ({
            error: 'INSUFFICIENT_ACCESS_PRIVILEGE',
            reason: 'INSUFFICIENT_ACCESS_PRIVILEGE',
        }),

        [MockedErrorsType.PUBLISHER_INACTIVE]: () => ({
            error: 'UNPROCESSABLE_ENTITY',
            message: 'publisher account is inactive, please contact ECAL support',
        }),

        [MockedErrorsType.NULL]: () => (null),

        [MockedErrorsType.UNHANDLED_GENERIC_ERROR]: () => ({
            error: 'UNHANDLED_GENERIC_ERROR',
            message: 'This is undhandled errors template, all unhandled errors will be rendered as dialog.',
        }),

        DEFAULT_MOCK: () => ({
            error: 'INTERNAL_SERVER_ERROR',
            reason: 'INTERNAL_SERVER_ERROR',
        }),
    };
    function getMockedError () {
        if (typeof mocked[type] === 'function') {
            return mocked[type]();
        }
        return mocked.DEFAULT_MOCK();
    }
    return {
      data: {
          errors: [getMockedError()],
      },
  };
}

export function processErrors (res, gqlAction) {
    /*
    console.log('');
    console.log('');
    console.log('');
    console.log('processErrors()', res);
    console.log('GQL Action:  ', gqlAction);
    */

    if (gqlAction && !isEmpty(res?.data?.data?.[gqlAction]?.error)) {
        // GQL with proper action...
        return {
            hasError: true,
            errors: [res?.data?.data?.[gqlAction]?.error], // *USE GQL Error that is aligned to the message..
        };
    }

    if (!isEmpty(res?.data?.errors)) {
        /* GQL */

        return {
            hasError: true,
            errors: res.data.errors,
        };
    }

    return {
        hasError: false,
        errors: null,
    };
}

function mockErrorInfo () {
    const env = window.envStore;
    if (env.settings.mockedError === 'true') {
        return mockedErrors(MockedErrorsType.UNHANDLED_GENERIC_ERROR);
    }
    return null;
}

export function dispatchErrors ({ store, response, action }) {
    /*
    console.log('');
    console.log('');
    console.log('dispatchErrors() -> Checking ACTION: ', action);
    console.log('dispatchErrors() -> Checkign RESPONSE: ', response);
    */
    store.genericErrors = {}; // set generic error to empty before error processing begins..
    if (ErrorActionSkipList[action]) {
        // logInfo('dispatchErrors() Skipping error ', action);
        return false;
    }

    const gqlAction = window.envStore.errorStore.gqlAction || '';
    const mocked = mockErrorInfo();
    const {
        hasError,
        errors,
    } = processErrors(mocked || response, gqlAction);
    window.envStore.errorStore.gqlAction = '';

    /*
    logInfo('ErrorStore.utils.dispatchErrors(): action ', action);
    logInfo('ErrorStore.utils.dispatchErrors() hasErrors: ', hasError);
    logInfo('ErrorStore.utils.dispatchErrors() errors: ', errors);
    */

    if (hasError) {
        store.errors = errors;
        store.observed.errors[action] = uuid();
        return true;
    }
    return false;
}

export function validateInputError (input, errorFuncs, touched = null) {
    const inputErrors = {};
    for (const func in errorFuncs) {
        if (typeof errorFuncs[func] === 'function') {
            if (touched === null || touched === undefined || touched[func]) {
                // only validate input that has been touched
                inputErrors[func] = errorFuncs[func](input);
                if (!inputErrors[func]) {
                    delete inputErrors[func];
                }
            }
        }
    }
    return inputErrors;
}
