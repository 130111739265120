import React from 'react';

import { observer } from 'mobx-react';

import {
    Grid,
    IconButton,
    InputAdornment,
//    Tooltip,
//    Button,
    TextField as Input,
    TablePagination,
} from '@material-ui/core';

import {
    Search as SearchIcon,
    FilterList as FilterIcon,
    DateRange as CalendarIcon,
} from '@material-ui/icons';

// import Widget from '../../components/Widget';
import { Typography } from '../../components/Wrappers';
import TableContainer from '../../commons/ECALTable/TableContainer';
import { useStyles } from './home.styles';

import {
    columns,
    customCells,
    options,
} from './tableSettings';

import useSearchFilters from './filters/useSearchFilters';
import useDateFilters from './dateFilters/useDateFilters';
import { logInfo } from '../../utils';
import useModuleList from './useModuleList';

function SuperAdminHome (props) {
    const classes = useStyles();

    const {
        renderFilters,
        showFilters,
        iconRef,
        filters,
        updateFilters,
    } = useSearchFilters({
        applyFilters: () => {
            search(searchValue);
        },
    });

    const {
        iconDateRef,
        renderDateFilters,
        showDateFilters,
    } = useDateFilters({
        filters,
        setFilters: updateFilters,
        applyFilters: () => {
            console.log('filters -> ', filters);
            search(searchValue);
        },
    });

    const {
        progress,
        listData,
        visibleColumns,
        searchValue,
        setSearchValue,
        handleKeyDown,
        search,
        updateStatus,
        editPublisher,
        renderStatusDialog,
        pageInfo,
        paginate,
    } = useModuleList({
        columns,
        filters,
    });

    return (
        <React.Fragment>

        <Grid
            className={classes.tableContainer}
        >
            <Grid className={classes.tableTop}>
                <Typography variant="h5">Search Publishers </Typography>
                <Typography variant='body2'>
                    Please search for the publishers..
                </Typography>
            </Grid>
            <Grid className={classes.controlFields}>
                <Input
                    id="searchField"
                    className={classes.searchField}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        classes: {
                        underline: classes.InputUnderline,
                        input: classes.Input,
                        },

                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="Search by publisher by name or email"
                                onClick={() => {
                                    logInfo('testing search...', searchValue);
                                    search(searchValue);
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    margin="normal"
                    placeholder="Search Publisher by name/email"
                    type="email"
                    fullWidth
                    />

                    <Grid className={classes.filterButton}>
                        <IconButton
                                ref={iconDateRef}
                                onClick={() => {
                                    showDateFilters();
                                }}
                            >
                                <CalendarIcon />
                        </IconButton>

                        <IconButton
                                ref={iconRef}
                                onClick={() => {
                                    showFilters();
                                }}
                            >
                                <FilterIcon />
                        </IconButton>
                    </Grid>

            </Grid>

            <TableContainer
                hasCheck={false}
                classes={classes}
                tableData={listData}
                columns={visibleColumns}
                customCells={customCells}
                tableTitle='Manage Publishers'
                moduleType='publishers'
                showProgress={progress.buttons}
                className={classes.buttonsTable}
                options={options}
                isInsideWidget={false}
                rowsPerPage={pageInfo.itemsPerPage}

                onChangeStatus={(row) => {
                    updateStatus(row);
                }}

                onEdit={(row) => {
                    logInfo('home.onEdit() ', row);
                    editPublisher(row);
                }}

                />

            { pageInfo.showPagingation && (
                 <Grid className={classes.tablePagination}>
                    <TablePagination

                        rowsPerPageOptions={[
                            10,
                        ]}

                        page={pageInfo.page}
                        rowsPerPage={pageInfo.itemsPerPage}
                        count={pageInfo.totalCount}

                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}-${to} of ${count} `;
                        }}

                        onChangePage={(_, page) => {
                            paginate(page);
                        }}

                        />
                </Grid>
            )}

        </Grid>
        { renderFilters() }
        { renderDateFilters()}
        { renderStatusDialog() }
        </React.Fragment>
    );
}

export default observer(SuperAdminHome);
