import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
    Grid,
    TextField,
} from '@material-ui/core';

import { Typography } from '../../../components/Wrappers';

import Dialog from '../../../commons/dialog/Dialog';
import { useStyles } from './styles';

function DeactivateDialog (props) {
    const {
        onClose,
        onConfirm,
        open,
        appName,

        title,
        message,
        okLabel,
    } = props;

    const classes = useStyles();

    const [name, setName] = useState('');
    const [error, setError] = useState({
        name: '',
    });

    return (<Dialog
        openDialog={open}
        buttonSecondaryFlag={true}
        buttonPrimary={okLabel}
        buttonSecondary="Cancel"
        handleAgree={() => {
            if (appName.toLowerCase() === name.toLowerCase()) {
                if (typeof onConfirm === 'function') {
                    onConfirm();
                    setName('');
                }
            } else {
                setError({
                    name: 'This should match the API Key Name.',
                });
            }
        }}

        closeModal={() => {
            onClose(false);
        }}
        title={title}
        dialogContextText={''}
        className={classes.dialogContent}
      >
          <Grid>

                <Grid className={classes.deactivateText}>
                    <Typography >
                        {message}
                    </Typography>
                </Grid>

                <TextField
                    InputProps={{
                        className: classes.deactivateName,
                    }}
                    onChange={(e) => {
                        setError({
                            name: '',
                        });

                        setName(e.target.value);
                    }}
                    id="name"
                    label="Name"
                    name="name"

                    value={name}
                    variant="outlined"
                    style={{ marginBottom: 32 }}
                    helperText={error.name ? error.name : 'To confirm, type the key name.'}
                    error={error.name}
                />
          </Grid>
    </Dialog>);
}

DeactivateDialog.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    secretKey: PropTypes.string,
    open: PropTypes.bool,
    appName: PropTypes.string,

    title: PropTypes.string,
    message: PropTypes.string,
    okLabel: PropTypes.string,
};

DeactivateDialog.defaultProps = {
    title: 'Deactivate API Key',
    message: 'Are you sure you want to deactivate your ECAL key? Doing so will make any displays using this key inactive.',
    okLabel: 'Deactivate',
    onClose: () => {},
    onConfirm: () => {},
    appName: '',
    open: false,
};

export default DeactivateDialog;
