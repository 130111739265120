import { useState, useEffect } from 'react';
import useConfirmationDialog from '../../commons/dialog/useConfirmationDialog';
import { useUserStore, useEnvStore } from '../../store/models';
import useNavigation from '../hooks/useNavigation';
import useEnvHistory from '../hooks/useEnvHistory';
import useAuthError from '../../store/models/user/errors/useAuthError';
import { loadSessionData, logInfo } from '../../utils';

import usePasswordValidation from '../hooks/usePasswordValidation';

function usePasswordChange (props) {
    const {
        defaultPasswordData,
    } = props;

    useNavigation();
    useEnvHistory();

    const userStore = useUserStore();
    const envStore = useEnvStore();

    /* state */

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(defaultPasswordData);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRetypePassword, setShowRetypePassword] = useState(false);

    const { changePasswordError } = usePasswordValidation({ changePasswordInput: data });

    const [dialogAttr] = useState({
        title: '',
        message: '',
    });

    const {
      renderDialog,
      openDialog,
    } = useConfirmationDialog({
        onConfirm: () => {

        },
        showSecondaryButton: false,
        title: dialogAttr.title,
        message: dialogAttr.message,
    });

    const {
        renderErrorDialog,
        errors,
    } = useAuthError({
        shouldRedirect: false,
    });

    useEffect(() => {
        const session = loadSessionData();
        logInfo('session() ', session);
        userStore.createCognitoSession();
        // chance to redirect with session.user.
    }, []);

    useEffect(() => {
        if (userStore.observed.changePasswordOk) {
            setIsLoading(false);
            openDialog('Change password success', 'Success! Your password has been updated.');
            setData({
                oldPassword: '',
                newPassword: '',
                rePass: '',
            });
        }
    }, [userStore.observed.changePasswordOk]);

    useEffect(() => {
        setIsLoading(false);
    }, [errors]);

    useEffect(() => {
        if (!data.oldPassword || !data.rePass || !data.newPassword) {
            setSaveDisabled(true);
            return;
        }

        if (data.rePass === data.newPassword) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }, [data]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }

    function savePassword () {
        setIsLoading(true);
        userStore.requiredChangePasssword({
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        });
    }

    return {
        userStore,
        envStore,
        data,
        setData,

        isLoading,
        setIsLoading,
        saveDisabled,
        renderErrorDialog,
        renderConfirmDialog: renderDialog,
        handleChange,

        showOldPassword,
        showNewPassword,
        showRetypePassword,

        setShowOldPassword,
        setShowNewPassword,
        setShowRetypePassword,

        savePassword,

        changePasswordError,
    };
}

export default usePasswordChange;
