
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    dialogContainer: {
        width: '360px',
        height: '240px',
        overflow: 'hidden',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center',
    },
      content: {
        margin: '24px',
        height: '200px',
        width: '310px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
      },

      welcomeText: {
        fontWeight: '300!important',
        width: '80%',
      },

      bottomSpace: {
        marginBottom: '16px',
      },

      buttonSpace: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
        marginTop: '32px',
        width: '100%',
      },

      loginLoader: {
        marginLeft: theme.spacing(4),
      },

      textButton: {
        textTransform: 'none',
        fontWeight: 400,
      },

});

export default styles;
export const useStyles = makeStyles(styles);
