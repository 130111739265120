export const Options = {
  chart: {
    id: 'Engagement',
    height: 350,
    type: 'line',
    stacked: false,
  },
  stroke: {
    width: [0, 2, 2],
    curve: 'smooth',
    // curve: 'straight',
    dashArray: [0, 0, 5],
  },
  plotOptions: {
    bar: {
      columnWidth: '50%',
    },
  },
  colors: ['#00a7e0', '#FFDEC8', '#ee7624'],
  fill: {
      // type: 'gradient',
      type: ['gradient', 'solid', 'solid'],
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [],
    },
  },
  // fill: {
  //   colors: ['#00a7e0', '#ee7624', '#F1914F'],
  //   opacity: [0.85, 0.25, 1],
  //   gradient: {
  //     inverseColors: false,
  //     shade: 'light',
  //     type: 'vertical',
  //     opacityFrom: 0.85,
  //     opacityTo: 0.25,
  //     stops: [0, 80, 90, 100],
  //   },
  // },
  // labels: ['27 Oct', '28 Oct', '29 Oct', '30 Oct', '31 Oct', '01 Nov', '02 Nov', '03 Nov', '04 Nov', '05 Nov', '06 Nov', '07 Nov', '08 Nov', '09 Nov', '10 Nov', '11 Nov', '12 Nov', '13 Nov', '14 Nov', '15 Nov', '16 Nov', '17 Nov', '18 Nov', '19 Nov', '20 Nov', '21 Nov', '22 Nov', '23 Nov', '24 Nov', '25 Nov',
  // ],
  markers: {
    size: 0,
  },
  // xaxis: {
  //   type: 'datetime',
  // },
  yaxis: [
    {
      axisTicks: {
        show: true,
      },
      seriesName: 'Click Throughs',
      axisBorder: {
        show: true,
        color: '#00a7e0',
      },
      labels: {
        style: {
          colors: '#00a7e0',
        },
      },
      title: {
        text: 'Click Throughs',
        style: {
          color: '#00a7e0',
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    {
      seriesName: 'Reminder Impressions',
      opposite: true,
      showAlways: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#ee7624',
      },
      labels: {
        style: {
          colors: '#ee7624',
        },
      },
      title: {
        text: 'Impressions',
        style: {
          color: '#ee7624',
        },
      },
    },
    {
      seriesName: 'Event Impression',
      opposite: true,
      show: false,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#F1914F',
      },
      labels: {
        style: {
          colors: '#F1914F',
        },
      },
      title: {
        text: 'Reminder Impressions',
        style: {
          color: '#ee7624',
        },
      },
    },
  ],
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (y) {
        if (typeof y !== 'undefined') {
          return y.toFixed(0) + '';
        }
        return y;
      },
    },
  },
};

// export const Options = {
//     chart: {
//       height: 350,
//       type: 'line',
//       stacked: true,
//     },
//     stroke: {
//       width: [0, 2, 5],
//       curve: 'smooth',
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: '50%',
//       },
//     },
//     fill: {
//       opacity: [0.85, 0.25, 1],
//       gradient: {
//         inverseColors: false,
//         shade: 'light',
//         type: 'vertical',
//         opacityFrom: 0.85,
//         opacityTo: 0.55,
//         stops: [0, 100, 100, 100],
//       },
//     },
//     labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
//       '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003',
//     ],
//     markers: {
//       size: 0,
//     },
//     xaxis: {
//       type: 'datetime',
//     },
//     yaxis: {
//       title: {
//         text: 'Points',
//       },
//       min: 0,
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       y: {
//         formatter: function (y) {
//           if (typeof y !== 'undefined') {
//             return y.toFixed(0) + ' points';
//           }
//           return y;
//         },
//       },
//     },
//   };
