import { useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { logInfo } from '../../utils';
// import { removeSubdomainTemporarily } from '../../utils';

function useOpenSignIn (props) {
  const {
    whiteLabelSettings,
    autoLogin = '',
  } = props;

  function signInWithGoogle () {
    if (whiteLabelSettings.labelName === 'ecal') {
      Auth.federatedSignIn({ provider: 'Google' });
    } else {
      logInfo('Login With White Label Provider: ', whiteLabelSettings?.provider);
      Auth.federatedSignIn({ provider: whiteLabelSettings?.provider });
    }
  };

  function listenHub () {
      Hub.listen('auth', ({ payload: { event, data } }) => {
          console.log('Auth Recieve Updates: ', event);
          switch (event) {
            case 'signIn':
            case 'cognitoHostedUI':
              console.log('Sign: Data: ', data);
              break;
            case 'signOut':
              console.log('Data: ', data);
              break;
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
              console.log('Sign in failure', data);
              break;
          }
        });
  }

  function shouldAutoLogin () {
    logInfo('useOpenSignIn().shouldAutoLogin()', autoLogin);
    if (autoLogin === whiteLabelSettings.autoLoginValue) {
      signInWithGoogle();
    }
  }
  useEffect(() => {
      listenHub();
      shouldAutoLogin();
  }, []);

  useEffect(() => {
    shouldAutoLogin();
  }, [autoLogin]);

  return {
      signInWithGoogle,
  };
}

export default useOpenSignIn;
