import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import {
    Dialog,
    Grid,
    IconButton,
} from '@material-ui/core';

import {
    Close as CloseIcon,
} from '@material-ui/icons';

import { useStyles } from './styles';
import UpgradeInput from './upgradeInput';
import ChargifyForm from './billingChargifyForm';

import ButtonProgress from '../../commons/ButtonProgress';
import { Button, Link, Typography } from '../../components/Wrappers';
import useBillingFormInput from '../billing/useBillingFormInput';
import { isFreeTrial, getURLElements } from '../../utils';
import useStep from '../billing/useStep';
function BillingDialog (props) {
    const {
        open,
        onClose,
    } = props;

    const contentRef = useRef();
    const {
        nextLabel,
        backLabel,
        stepTitle,
        step,
        next,
        back,
    } = useStep({
        titles: [
            'Upgrade Add Ons',
            'Add Ons Summary',
        ],
    });
    const classes = useStyles({ step });

    const billingInputProps = useBillingFormInput({
        step,
        onClose,
        isAddOnUpgrade: true,
    });

    const {
        submitChargify,
        renderErrorDialog,
        isLoading,
        submitDisabled,
        input,
        chargifyInput,
        renderAuthDialog,
    } = billingInputProps;

    function getNextLabel () {
        const { path } = getURLElements();
        if (step === 2 &&
            path.indexOf('/setup-plan') !== -1) {
            return 'Sign Up';
        }

        if (isFreeTrial(input.planName)) {
            return 'Upgrade';
        }
        return nextLabel;
    }

    function resetScroll () {
        if (contentRef.current) {
            const div = document.getElementById('billingDialogContent');
            div.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    }
    function executeNext () {
        resetScroll();
        next();
    }

    function isSubmitDisabled () {
        if (!isEmpty(chargifyInput.address)) {
            return false;
        }
        return submitDisabled;
    }

    function renderBackButton () {
        if (step === 1) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Button
                disabled={step === 1}
                onClick= { (e) => {
                    e.preventDefault();
                    // onClose();
                    resetScroll();
                    back();
                }}
                variant={'outlined'}
                color={'primary'}
            >
                <Link
                    color='#fff'>
                    {backLabel}
                </Link>
            </Button>

        );
    }

    return (
        <React.Fragment>
        <Dialog
            open={open}
            disableEnforceFocus
            >

                <Grid
                    container
                    className={classes.dialogContent}>

                    <Grid className={classes.topPanel}>
                        <Grid>
                            <Typography
                                className={classNames([])}
                                variant={'h5'}
                                weight={'medium'}
                            >
                                    {stepTitle}
                            </Typography>
                        </Grid>

                        <IconButton
                            color={'primary'}
                            className={classes.closeIcon}
                            onClick= { (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClose();
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Grid>

                    <Grid
                        className={classes.contentContainer}
                        ref={contentRef}
                        id='billingDialogContent'
                    >

                        { step === 1 && (
                            <UpgradeInput
                                billingInputProps={billingInputProps}
                            />
                        )}

                        { step === 2 && (
                            <ChargifyForm
                                billingInputProps={billingInputProps}
                            />
                        )}

                    </Grid>

                    <Grid className={classes.buttons} >

                            {renderBackButton()}
                            <ButtonProgress
                                disabled={isSubmitDisabled()}
                                showProgress={isLoading}
                                label={getNextLabel()}
                                onClick={(e) => {
                                    if (step < 2) {
                                       executeNext();
                                    } else if (step === 2) {
                                        submitChargify();
                                    }
                                }}
                            />

                    </Grid>
                </Grid>
        </Dialog>
        { renderErrorDialog() }
        { renderAuthDialog() }
        </React.Fragment>
    );
}

BillingDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

BillingDialog.defaultProps = {
    open: false,
    onClose: () => {},
};

export default observer(BillingDialog);
