import { makeObservable, observable, action } from 'mobx';
import { useStores } from '../useStores';
import { randomString } from '../../../utils';
import {
    /* Events Related Actions */
    getEvents,
    renderEvents,
    updateEventPage,
    searchOrGetEvents,
    searchEvents,
    upsertEvents,
    bulkUpdateEvents,
    bulkUpdateEventsStatus,
    makeEventsLive,
    makeEventsDraft,
    deleteEvents,
    bulkDeleteEvents,
    getEventsFromSelection,
    getEventsFromSelectionForUpdate,
    eventDataFromSelectedItem,
    duplicateEventDataFromSelectedItem,
    uploadEventBanner,
} from './actions';

import { importEvents, saveImportedEvents } from './actions_import';

import { loadEventsForEdit, saveEventsForEdit } from './actions_persist';
export default class EventsStore {
    version = {};
    stores = {};
    env = null;

    observed = {
        importedEvents: null,
        assets: {},
    };

    assets = {};
    importedEvents = [];
    importedEventsWarnings = {};
    importedEventsErrors = {};

    events = [];
    eventsPage = 0;
    eventRowsPerPage = 10;
    eventsTotalCount = 0;
    eventIsLoading = false;

    notificationText = '';
    notificationKey = 0;
    filterText = '';
    selectedScheduleName = '';
    selectedScheduleId = '';
    eventEditTarget = null;

    eventSearchText = null;
    eventSearchPrev = null;
    eventFilter = '';
    // scheduleEditTarget = null;

    events_progressBar = false;
    notificationConfirmation (value) {
        this.notificationText = value;
        this.previousNotificationKey = this.notificationKey;
        this.notificationKey = randomString(20);
    }

    constructor () {
        makeObservable(this, {
            events: observable,
            eventIsLoading: observable,
            eventsPage: observable,
            eventsTotalCount: observable,
            observed: observable,
            getEvents: action,
        });
    }

    /* Events Related Actions */
    bulkUpdateEvents = bulkUpdateEvents;
    bulkUpdateEventsStatus = bulkUpdateEventsStatus;
    makeEventsLive = makeEventsLive;
    makeEventsDraft = makeEventsDraft;
    getEventsFromSelectionForUpdate = getEventsFromSelectionForUpdate;
    getEvents = getEvents;
    renderEvents = renderEvents;
    upsertEvents = upsertEvents;
    updateEventPage = updateEventPage;
    searchOrGetEvents = searchOrGetEvents;
    deleteEvents = deleteEvents;
    bulkDeleteEvents = bulkDeleteEvents;
    searchEvents = searchEvents;
    getEventsFromSelection = getEventsFromSelection;

    importEvents = importEvents;
    saveImportedEvents = saveImportedEvents;
    uploadEventBanner = uploadEventBanner;
    /* Persist  Action */
    loadEventsForEdit = loadEventsForEdit;
    saveEventsForEdit = saveEventsForEdit;
    eventDataFromSelectedItem = eventDataFromSelectedItem;
    duplicateEventDataFromSelectedItem = duplicateEventDataFromSelectedItem;
}

export function useEventsStore () {
    const { eventsStore } = useStores();
    return eventsStore;
}
