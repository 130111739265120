import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
// import { useHistory } from 'react-router';
import {
    useAPIKeyStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import useApiKeyErrors from '../../../store/models/apikey/errors/useApiKeyErrors';

const GENERATE = 'generate';

function useModuleInput (props) {
    function getMode () {
        return GENERATE;
    }
    const [mode] = useState(getMode());

    const [input, setInput] = useState({
        id: 0,
        name: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const {
        errors,
        hasError,
        hasInputError,
    } = useApiKeyErrors({
        input,
    });

    const apikeysStore = useAPIKeyStore();

    function initStore () {
        setIsLoading(true);
        if (mode !== GENERATE) {
            apikeysStore.loadEditData();
            initWithEditData();
        }
        apikeysStore.getAPIKeys();
    }

    function initWithEditData () {
        setInput({
            ...apikeysStore.editData,
        });
    }

    useEffect(() => {
        setIsLoading(false);
        setProgress(false);
    }, [errors]);

    useEffect(() => {
        setIsLoading(false);
        setProgress(false);
    }, []);

    useEffect(() => {
        setIsLoading(false);
        if (apikeysStore.apikeys && apikeysStore.apikeys.length >= 2) {
            setMessageOpen(true);
        }
    }, [apikeysStore.observed.apikeys]);

    useStoreModuleDependency({ initStore });

    function updateInput (newInput) {
        setInput(newInput);
    }

    function generateKey () {
        const params = cloneDeep(input);
        apikeysStore.generateAPIKey(params);
    }

    return {
        input,
        updateInput,

        setIsLoading,
        generateKey,
        hasError,
        isLoading,
        progress,
        errors,
        hasInputError,

        messageOpen,
        setMessageOpen,
    };
}

export default useModuleInput;
