
export const DesignSections = {
    LOGO: 'Logo',
    HEADER: 'Header',
    BUTTONS: 'Buttons',
    HEIGHT: 'Height',
    SETTINGS: 'Settings',
    CUSTOM: 'Custom',
};

export function debounce (func, params) {
    console.log('debouncing....');
    clearTimeout(window.debounceTimeout);
    function delay () {
        if (typeof func === 'function') {
            console.log('willl update after debounce.....');
            console.log('Params:', params);
            func(params);
        }
    }
    window.debounceTimeout = setTimeout(delay, 500);
}
