import { useHistory } from 'react-router';

function useButtonNavigation () {
    const history = useHistory();

    const handleAddEvent = (e) => {
        e.preventDefault();
        history.push('/admin/displays/add/event');
    };

    const handleAddButton = (e) => {
        e.preventDefault();
        history.push('/admin/displays/add/button');
    };

    const handleAddAd = (e) => {
        e.preventDefault();
        history.push('/admin/displays/add/ad');
    };

    const handleAddDisplay = (e) => {
        e.preventDefault();
        history.push('/admin/displays/add');
    };

    return {
        handleAddEvent,
        handleAddButton,
        handleAddAd,
        handleAddDisplay,
    };
}
export default useButtonNavigation;
