import { useEffect } from 'react';

import { useEnvStore } from '../../store/models';
import { logInfo } from '../../utils/';

function useProductTour (props = {}) {
    const {
        isStateDependent = false,
        observableState,
        isDataReadyForTheTour = () => (true),
    } = props;

    const env = useEnvStore();

    function getSettings () {
        const location = window.location;
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const path = window.location.pathname;

        env.settings = {
            ...params,
            path,
            forVerification: path.indexOf('verifyAccount') !== -1,
        };
        return env.settings;
    }

    useEffect(() => {
        const settings = getSettings();
        if (env.observed.intercomBoot &&
            settings &&
            settings.product_tour_id &&
            !isStateDependent) {
                const tourId = Number(settings.product_tour_id);
                logInfo('Will Start Product Tour for non list dependent -> Tour Id: ', tourId);
                window.Intercom('startTour', tourId);
                env.productTourStarted = true;
        }
    }, []);

    useEffect(() => {
        const settings = getSettings();
        logInfo('useProductTour() -> with State Dependency -> ', observableState);
        if (isStateDependent && isDataReadyForTheTour()) {
            const tourId = Number(settings.product_tour_id);
            if (tourId) {
                logInfo('Will Start Product Tour for with State Dependency -> Tour Id: ', tourId);
                window.Intercom('startTour', tourId);
                env.productTourStarted = true;
            }
        }
    }, [observableState]);

    useEffect(() => {
        const settings = env.settings;
        if (env.observed.intercomBoot && settings && !isStateDependent) {
          if (!env.productTourStarted && settings.product_tour_id) {
            const tourId = Number(settings.product_tour_id);
            logInfo('Will Start Product Tour -> Tour Id: ', tourId);
            window.Intercom('startTour', tourId);
            env.productTourStarted = true;
          }
        }
      }, [env.observed.intercomBoot]);

      return {

      };
}

export default useProductTour;
