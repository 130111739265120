import {
    get,
    post,
    put,
    deleteCall,
    endpoints,
} from '../../api';

export function apiGetAPIKeys (options) {
    return get(
        endpoints().apikey,
        options
    );
}

export function apiAddAPIKey ({ body, options }) {
    return post(
        endpoints().apikey,
        body,
        options
    );
}

export function apiEditAPIKey ({ body, options }) {
    return put(
        endpoints().apikey + '/' + body.id,
        body,
        options
    );
}

export function apiDeleteAPIKey ({ body, options }) {
    return deleteCall(
        endpoints().apikey + '/' + body.id,
        options
    );
}
