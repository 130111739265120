import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Button, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
// import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
// import Link from '@material-ui/core/Link';
// import TableCell from '@material-ui/core/TableCell';
import CustomToolbarSelect from './CustomToolbarSelect';
import CustomFooter from './CustomFooter';
import { Typography, Chip } from '../../components/Wrappers/Wrappers';
import Dialog from '../dialog/Dialog';
import useStyles from './styles';
import {
  CreateOutlined as EditIcon,
  FileCopy as DuplicateIcon,
  DeleteOutlined as DeleteIcon,
  Sync as SyncIcon,
  AccountTreeOutlined as AccountTreeIcon,
} from '@material-ui/icons';
import { moduleNoDataText } from '../util/utilities';

// add SYNC icon for events
function isFeed (dataSource, data) {
  let eventData = null;
  const id = data.rowData[6];
  for (let i = 0; i < dataSource.length; i++) {
    if (id === dataSource[i].id) {
      eventData = dataSource[i];
    }
  }
  const dataInfo = eventData.data;

  if (dataInfo.reference) {
    return true;
  }
  return false;
}

// Check if feed driven events included while bulk deleting events
function hasFeed (dataSource, data) {
  // console.log('hasFeed -> dataSource', dataSource.length);
  // console.log('hasFeed -> rowIndex ', data.data);
  for (let i = 0; i < data.data.length; i++) {
    const rowIndex = data.data[i].dataIndex;
    const eventReference = dataSource[rowIndex].data.reference;
    if (eventReference) {
      return true;
    }
  }

  return false;
}

const DEFAULT_BULK_DIALOG_TITLE = 'Delete selected items';
const DEFAULT_BULK_DIALOG_DESCRIPTION = 'Feed driven event cannot be deleted manually from your admin. Please contact the support team via Live Chat or your Success Manager directly.';
const CHILD_DELETE_BULK_DIALOG_DESCRIPTION = 'Child event cannot be deleted from the parent account. Please login to the child account to perform this action.';
const CHILD_UPDATE_BULK_DIALOG_TITLE = 'Update selected items';
const CHILD_UPDATE_BULK_DIALOG_DESCRIPTION = 'Child event cannot be updated from the parent account. Please login to the child account to perform this action.';

export default function Tables (props) {
  const {
    onPageChange,
    currentPage,
    totalCount,
    onSearch,
    // onFilter,
    showLoading,
    onActionIconClick,
    onEditEvent,
    onDuplicateEvent,
    onDeleteEvent,
    onSetItemsLive,
    onSetItemsDraft,
    onDeleteItems,
    onFilter,
    // checkIfFeedManagedEvent,
    scheduleEditTargetIsChild,
  } = props;
  const classes = useStyles();
  // const [isActionsMenu, setActionsMenu] = React.useState(false);
  // const [loadPopup, setLoadPopup] = React.useState(false);
  // const [categories, setCategories] = React.useState([]);
  // const [actionsButtonRefid, setActionsButtonRefid] = React.useState(null);
  let searchTimeout = null;
  const [loading, setLoading] = useState(showLoading);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [bulkDialogOpen, setBulkOpen] = useState(false);
  const [bulkDialogTitle, setBulkDialogTitle] = useState(DEFAULT_BULK_DIALOG_TITLE);
  const [bulkDialogDescription, setBulkDialogDescription] = useState(DEFAULT_BULK_DIALOG_DESCRIPTION);
  const [dataToDelete, setDataToDelete] = useState(null);

  useEffect(() => {
    setLoading(showLoading);
  }, [showLoading]);
  // const handleMoreClick = () => {
  //   setLoadPopup(true);
  // };

  // const handleAgree = () => {
  //   setLoadPopup(false);
  // };
  function renderFeedIcon (value, tableMeta, updateValue) {
      if (isFeed(props.tableData, tableMeta) || scheduleEditTargetIsChild) {
      return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
           }}>
              <Chip
              label={ !value ? 'LIVE' : 'DRAFT'}
              key={value++}
              style={{
              color: '#fff',
              height: 20,
              width: 65,
              backgroundColor: !value ? '#75bd43' : '#f50057',
              fontSize: 11,
              fontWeight: 'bold',
              }}
            />
            {isFeed(props.tableData, tableMeta) && <Tooltip title={'Managed by Feed/API'}>
              <SyncIcon
                style={{
                  marginLeft: '3px',
                  width: '24px',
                  height: '24px',
                }}
              />
            </Tooltip>}

            {scheduleEditTargetIsChild && <Tooltip title={'Managed by a child account'}>
              <AccountTreeIcon
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '24px',
                  height: '24px',
                  marginLeft: '3px',
                  color: '#0000008a',
                }}
              />
          </Tooltip>}
          </div>
      );
    };

    return (
      <Chip
        label={ !value ? 'LIVE' : 'DRAFT'}
        key={value++}
        style={{
        color: '#fff',
        height: 20,
        width: 65,
        backgroundColor: !value ? '#75bd43' : '#f50057',
        fontSize: 11,
        fontWeight: 'bold',
        }}
      />
    );
  }

  const columns = [
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ['Draft', 'Live'],
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          renderFeedIcon(value, tableMeta, updateValue)
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      width: '30%',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'location',
      label: 'Location',
      width: '20%',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'start',
      label: 'Starts From',
      width: '15%',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'end',
      label: 'Finishes At',
      width: '15%',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'reminders',
      label: 'Reminders',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => {
          return value;
            // if (value) {
            //   const dateTimeAgo = moment(value).fromNow();
            //   return dateTimeAgo;
            // } else {
            //   return null;
            // }
        },
      },
    },
    {
       /* NOTE: by settting the name as id  we can get the id of event */
       name: 'id',
      label: 'Actions',
      width: '2%',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ width: '59px' }}>
            <Tooltip title={'Edit Event'}>
              <IconButton
                  data-intercom-target={'edit-event'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (typeof onEditEvent === 'function') {
                      onActionIconClick(value, tableMeta);
                      onEditEvent(value, tableMeta);
                  }
                  }}
                  style={{ marginRight: '4px', padding: 0 }}
              >
                <EditIcon style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Copy Event'}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (typeof onDuplicateEvent === 'function') {
                    onActionIconClick(value, tableMeta);
                    onDuplicateEvent(value, tableMeta);
                  }
                }}
                style={{ marginRight: '4px', padding: 0 }}
              >
                <DuplicateIcon style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Delete Event'}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (typeof onDeleteEvent === 'function') {
                    onActionIconClick(value, tableMeta);
                    onDeleteEvent(value, tableMeta);
                }
                }}
                style={{ padding: 0 }}
              >
                <DeleteIcon style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
        </div>
        ),
      },
    },
  ];
  const options = {
    filter: true,
    selectableRows: true,
    download: false,
    print: false,
    filterType: 'checkbox',
    searchPlaceholder: 'Search Events beginning with',
    textLabels: {
        body: {
            noMatch: loading
            ? <React.Fragment>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
              </React.Fragment>
                : <Typography variant={'body2'}>{moduleNoDataText('events')}</Typography>,
        },
    },
    // responsive: 'stacked',
    // rowsPerPage: 10,
    customToolbarSelect: (selectedRows) => {
      return (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        onSetItemsDraft={(data) => {
          if (scheduleEditTargetIsChild) {
            setBulkDialogTitle(CHILD_UPDATE_BULK_DIALOG_TITLE);
            setBulkDialogDescription(CHILD_UPDATE_BULK_DIALOG_DESCRIPTION);
            setBulkOpen(true);
            return;
          }

          onSetItemsDraft(data);
        }}
        onSetItemsLive={(data) => {
          if (scheduleEditTargetIsChild) {
            setBulkDialogTitle(CHILD_UPDATE_BULK_DIALOG_TITLE);
            setBulkDialogDescription(CHILD_UPDATE_BULK_DIALOG_DESCRIPTION);
            setBulkOpen(true);
            return;
          }

          onSetItemsLive(data);
        }}
        onDeleteItems={(data) => handleDeleteDialog(data)}
        />
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomFooter
          count={totalCount}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={onPageChange}
          textLabels={textLabels}
        />
      );
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <Button variant='contained' onClick={() => {
            console.log('apply filters: ', currentFilterList);
            const drafts = currentFilterList[0];
            if (drafts.length === 1) {
              if (drafts[0].toLowerCase() === 'draft') {
                onFilter(true);
              } else {
                onFilter(false);
              }
            } else {
              onFilter('');
            }
          }}>Apply Filter</Button>
        </div>
      );
    },
    serverSide: true,
    onSearchChange: (searchText) => {
      // search debounce to optimize backend calls
      if (searchTimeout) clearTimeout(searchTimeout);
      function doSearch () {
        console.log('');
        console.log('DO Search : ', searchText);
        onSearch(searchText);
      }
      searchTimeout = setTimeout(doSearch, 400);
    },
  };

  const handleDeleteDialog = (data) => {
    if (scheduleEditTargetIsChild) {
      setBulkDialogTitle(DEFAULT_BULK_DIALOG_TITLE);
      setBulkDialogDescription(CHILD_DELETE_BULK_DIALOG_DESCRIPTION);
      setBulkOpen(true);
      return;
    }

    if (hasFeed(props.tableData, data)) {
      setBulkDialogTitle(DEFAULT_BULK_DIALOG_TITLE);
      setBulkDialogDescription(DEFAULT_BULK_DIALOG_DESCRIPTION);
      setBulkOpen(true);
      return;
    }

    setDataToDelete(data);
    setDeleteOpen(true);
  };

  const getMuiTheme = createTheme({
    typography: {
      fontFamily: [
        'GothamRounded',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    overrides: {
      MUIDataTableFilter: {
        root: {
          overflow: 'hidden',
        },
        checkboxFormControlLabel: {
          marginLeft: 0,
        },
      },
      MuiGridListTile: {
        root: {
          width: '100% !important',
        },
      },
      MuiGrid: {
        container: {
          justifyContent: 'space-between',
        },
      },
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {/* {loadPopup
          ? <Dialog
              openDialog={loadPopup}
              title={'Categories'}
              buttonPrimary={'Ok'}
              buttonSecondaryFlag={false}
              handleAgree={handleAgree}
              dialogContextText={categories} />
          : null} */}
          <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title={props.scheduleEditTargetName }
              data={props.tableData}
              tableLayout={'fixed'}
              className={classes.tableContainer}
              columns={columns}
              options={options}
            />
        </MuiThemeProvider>
        </Grid>
        <Dialog
          openDialog={deleteOpen}
          handleAgree={() => {
            onDeleteItems(dataToDelete);
            setDeleteOpen(false);
          }}
          closeModal={() => {
            setDeleteOpen(false);
          }}
          title="Delete selected items"
          dialogContextText="Are you sure you want to delete the selected item(s)?"
        />
         <Dialog
          openDialog={bulkDialogOpen}
          buttonPrimary={'OK'}
          buttonPrimaryFlag={true}
          buttonSecondaryFlag={false}
          handleAgree={() => {
            setBulkOpen(false);
          }}
          closeModal={() => {
            setBulkOpen(false);
          }}
          title={bulkDialogTitle}
          dialogContextText={bulkDialogDescription}
        />
      </Grid>
    </>
  );
}
