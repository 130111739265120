import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    dialogContent: {
        minWidth: '600px',
        minHeight: '400px',
        padding: '32px',
    },

    contentContainer: {
        marginTop: '24px',
        maxWidth: '536px',
        minHeight: '460px',
        maxHeight: '460px',
        overflowY: 'auto',
        overflowX: 'hidden',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },

    planFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: '552px',
    },

    chargifyFields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '536px',
    },

    formContent: {
        width: '100%',
        maxWidth: '536px', // 456px

        '& iframe': {
            width: '100%',
            maxWidth: '536px',
            '& .cfy-field--number': {
               // maxWidth: '420px',
            },
        },
    },

    chargifyCVV: {
        display: 'flex',
        flexDirection: 'row',
        width: '60px',
        maxWidth: '60px',
        maxHeight: '34px',

        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #ddd',
        whiteSpace: 'nowrap',

        '&::placeholder': {
            // textOverflow: 'ellipsis !important',
            color: '#CCCCCC',
            fontWeight: 'bold',
        },
    },

    planFeatures: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '16px',
        minWidth: '552px',
        fontWeight: '500 !important',
    },

    planAddOnsTitle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '16px',
        marginBottom: '16px',
        minWidth: '552px',
        fontWeight: '500 !important',
    },

    topPanel: {
        display: 'flex',
        width: '100%',
        maxHeight: '32px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    closeIcon: {
        width: '32px',
        height: '32px',
    },

    planInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '552px',
    },

    bullet: {
        lineHeight: '20px',
        fontSize: '14px',
        weight: 'regular',
        marginBottom: '8px',
    },

    bottomSpace: {
        marginBottom: '24px',
    },

    planDescription: {
        width: 'calc(100% - 10px)',
    },

    addOnField: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '56px',
        width: '100%',
        maxWidth: '536px',
        borderRadius: '4px',

        border: '1px solid rgba(0,0,0,0.3)',
        marginBottom: '12px',
        padding: '8px',
    },

    addOnFieldInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '8px',
    },

    addOnName: {
        fontSize: '16px',
    },

    addOnDesc: {
        fontSize: '12px',
        color: '#0000008A',
        weight: 'medium',
    },

    addOnFieldControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minWidth: '120px',
    },

    buttons: ({ step }) => {
        function getJustifyContent () {
            if (step === 1) {
                return 'flex-end';
            } else if (step === 2) {
                return 'space-between';
            }
            return 'space-between';
        }

        return {
            display: 'flex',
            width: '100%',
            maxWidth: '536px',
            alignItems: 'flex-end',
            height: '60px',
            justifyContent: getJustifyContent(),
            marginBottom: '48px',
        };
    },

    formInput: {
        width: '100%',
        maxWidth: '536px',
        marginBottom: '16px',
    },

    summary: {
        marginBottom: '32px',
    },

    summaryEntry: (props) => {
        const {
            highlighted = false,
            hasBorder = true,
        } = props;

        const style = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '32px',
            color: highlighted ? '#076b22' : '#0000008A',
            marginTop: highlighted ? '8px' : '8px',
        };

        if (hasBorder) {
            style.paddingBottom = '8px';
            style.borderBottom = '1px dashed #00000042';
        }
        return style;
    },

    summaryEntryText: (props) => {
        const {
            highlighted = false,
        } = props;
        return {
            color: highlighted ? '#076b22' : '#000000',
            fontSize: highlighted ? '18px' : '14px',
            fontWeight: highlighted ? 'medium' : '',
        };
    },

    summaryEntryPrice: ({ highlighted }) => {
        const style = {
            fontWeight: highlighted ? 'normal' : 'light',
            fontSize: highlighted ? '18px' : '14px',
        };
        if (!highlighted) {
            style.color = '#0000008A';
        }
        return style;
    },

    summaryAddOns: {
        display: 'flex',
        flexDirection: 'column',
        // marginTop: '14px',
    },

    summaryAddOnsTitle: {
        fontSize: '12px',
        fontWeight: 'medium',
        // color: '#0000008A',
        color: '#000000',
    },

    addOnsFieldPrice: {
        marginLeft: '8px',
        fontSize: '12px',
        fontWeight: '100',
    },

    addOnsFieldPriceValue: {
        fontSize: '12px',
        fontWeight: '200',
        marginRight: '4px',
    },

    linkPointer: {
        cursor: 'pointer',
    },
});

export default styles;
export const useStyles = makeStyles(styles);

export const chargifyStyles = {
    // to style an iframe, use the iframe's container selector
    '#chargify-form': {
        border: '1px dashed #ffc0cb57',
    },

    // `field` is the container for each field
    field: {
        backgroundColor: 'orange',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: '5px',
    },

    // `input` is the input HTML element
    input: {
        backgroundColor: '#e6e6e6',
        paddingTop: '2px',
        paddingBottom: '1px',
        placeholder: { color: '#eee' },
    },

    // `label` is the label container
    label: {
        backgroundColor: 'lightblue',
        paddingTop: '2px',
        paddingBottom: '1px',
    },

    // `message` is the invalid message container
    message: {
        backgroundColor: 'red',
        color: 'white',
        paddingTop: '2px',
        paddingBottom: '1px',
    },
};
