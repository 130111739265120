import { useState, useEffect } from 'react';

import {
    useSchedulesStore,
    useButtonsStore,
} from '../../../../store/models';
import useStoreModuleDependency from '../../../hooks/useStoreModuleDependency';
import useRemoteSearch from '../../../../commons/ecal/selectsearch/useRemoteSearch';

function useScheduleData (props) {
    const schedulesStore = useSchedulesStore();
    const buttonsStore = useButtonsStore();
    const {
        dataSource,
        onDataChange,
        updateSavable,
        onTouch,
        isDisplaySetting = false,
    } = props;

    async function getPreselectedSchedules () {
        if (dataSource.display?.schedules && dataSource.display?.schedules.length > 0) {
            const selected = await schedulesStore.getSchedulesByIds(dataSource.display.schedules);
            updatePreselectedProvider(selected);
            // console.log('getPreselectedSchedules(): ', selected);
        }
    }

    function decodeFromSource () {
        const data = {
            schedules: dataSource.display.schedules,
            categories: dataSource.display.categories,
            categoryInfo: dataSource.display.categoryInfo || schedulesStore.getCategoryInfo(dataSource.display.categories),
            text: dataSource.display.text,
            scheduleOption: dataSource.display.scheduleOption ? dataSource.display.scheduleOption : '1',
            isAutoSubscribe: dataSource.display.isAutoSubscribe,
            /* add additional fields here */
        };

        if (
            data.categories &&
            data.categories.length <= 0 &&
            dataSource?.categoryInfo &&
            dataSource?.categoryInfo?.length > 0
            ) {
            data.categories = dataSource?.categoryInfo?.map((info) => info.id);
        }

        // console.log('useScheduleData.decodeFromSource(): ', data);
        // console.log('data Source:', dataSource);
        return data;
    }

    const [scheduleData, setScheduleData] = useState({});
    const [schedulesToSearch, setSchedulesToSearch] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setScheduleData(decodeFromSource());
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
       updateSavableWithScheduleData();
    }, [scheduleData]);

    useEffect(() => {
        if (buttonsStore.buttonSchedules) {
            const newButtonSchedules = buttonsStore.buttonSchedules;
            // console.log('useScheduleData().useEffect(buttonSchedules)', newButtonSchedules);
            setSchedulesToSearch([
                ...newButtonSchedules,
            ]);
            updateProvider([
                ...newButtonSchedules,
            ]);
        }
    }, [buttonsStore.observed.buttonSchedules]);

    useEffect(() => {
        // console.log('useScheduleData().useEffect().schedulesStore.scheduleIsLoading', schedulesStore.scheduleIsLoading);
        if (schedulesStore.scheduleIsLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [schedulesStore.scheduleIsLoading]);

    useEffect(() => {
        setScheduleData(decodeFromSource());
    }, [dataSource]);

    function updateSavableWithScheduleData () {
        const display = {
            ...dataSource.display,
            categories: scheduleData.categories,
            scheduleOption: scheduleData.scheduleOption,
            isAutoSubscribe: scheduleData.isAutoSubscribe,
            timezone: dataSource.display.timezone || 'Australia/Melbourne',
        };

        if (scheduleData.scheduleOption === 1) {
            display.categoryInfo = [];
            display.schedules = [];
        }

        if (scheduleData.scheduleOption === 2) {
            display.categories = scheduleData.categories;
            display.categoryInfo = scheduleData.categoryInfo;
            display.schedules = [];
        }

        if (scheduleData.scheduleOption === 3) {
            display.categoryInfo = [];
            display.schedules = scheduleData.schedules;
        }

        // console.log('updateSavableWithScheduleData(): ', display);
        updateSavable({
            ...dataSource,
            display,
        });
    }

    /* do data computation before updating the input states */
    /* Or update the input Source for Saving without triggering state change things */
    function updateScheduleData (data) {
        if (typeof onTouch === 'function') {
            onTouch({ schedules: true });
        }
        setScheduleData(data);
    }

    useStoreModuleDependency({ initStore });
    function initStore () {
        schedulesStore.getSchedulesForSubscribersFilter();
        getPreselectedSchedules();
    }

    useStoreModuleDependency({ initStore });

    const {
        dataProvider: schedules,
        updateProvider,
        setSelected: setRemoteSelected,
        updatePreselectedProvider,
    } = useRemoteSearch({
        isDisplaySetting,
    });

    function updateScheduleSelection (data) {
        const schedules = [
            ...data,
        ];

        setRemoteSelected(schedules);

        updateScheduleData({
            ...scheduleData,
            schedules,
        });
    }

    async function searchSchedule (search) {
        const searchedSchedules = await schedulesStore.searchSchedules({
            name: search,
            categoryName: search,
            draftStatus: '',
            renderSearchResult: false,
            schedulePage: 0,
            scheduleRowsPerPage: 300,
        });
        // console.log('Search Schedules: ', searchedSchedules);
        updateProvider(searchedSchedules);
    }

    return {
        scheduleData,
        schedulesToSearch,
        updateScheduleData,

        updateScheduleSelection,
        schedules,
        searchSchedule,
        showProgress: loading,
    };
}

export default useScheduleData;

/*
display{
    text
    # options from the Schedules tab
    # scheduleOption options:
    #   1 - show all schedules
    #   2 - include categoryInfo
    #   3 - show selected schedules
    #   4 - show no schedules
    scheduleOption
    categories # required if scheduleOption = 2
    schedules # required if scheduleOption = 3
    isAutoSubscribe
}
*/
