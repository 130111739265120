import dropDown from './dropDownCustomField';
import datePicker from './datepickerCustomField';
import text from './textCustomField';
import parentalConsent from './parentalConsentCustomField';

export const customFieldDataSchema = {
    name: '',
    value: [],
    type: 'dropdown',
    visible: false,
    required: true,
    index: -1,
};

export const customFieldTypes = [
    {
        value: 'dropdown',
        label: 'Dropdown',
    },

    {
        value: 'datepicker',
        label: 'Date Picker',
    },

    {
        value: 'text',
        label: 'Free Text',
    },

    {
        value: 'parentalconsent',
        label: 'Parental Consent',
    },
];

export const dynamicForms = {
    dropdown: dropDown,
    datepicker: datePicker,
    text: text,
    parentalconsent: parentalConsent,
};

export const defaultValues = {
    dropdown: {
        name: 'Please select from the dropdown.',
        value: [],
    },

    text: {
        name: 'Enter text',
        value: [],
    },

    parentalconsent: {
        name: 'Parental Consent',
        value: ['Are you over the age of 16?'],
    },

    datepicker: {
        name: 'Please select a date?',
        value: [],
    },

};

export function defaultValueForType (type) {
    if (defaultValues[type]) {
        return defaultValues[type];
    }
    return defaultValues.dropdown;
}
