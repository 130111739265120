import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
} from '@material-ui/core';

// styles
import { useStyles } from './signUp.styles';

import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

// components
import ConfirmSignUpForm from './confirmForm';
import { useUserStore } from '../../store/models';
import useEnvHistory from '../hooks/useEnvHistory';
import useAuthError from '../../store/models/user/errors/useAuthError';

function ConfirmSignUp (props) {
  const classes = useStyles();
  const userStore = useUserStore();

  useEnvHistory();

  const [resendIsLoading, setResendIsLoading] = useState(false);
  const [verifyIsLoading, setVerifyIsLoading] = useState(false);
  function enableButtonState () {
    setVerifyIsLoading(false);
    setResendIsLoading(false);
  }

  function shouldRedirect (err) {
    console.log('Should Redirect: ', err);
    enableButtonState();
    if (err.errCode === 'AUTHENTICATION FAILED') {
      return false;
    }
    if (err.errCode !== 'RESEND_VERIFICATION_CODE') {
      return true;
    }
    return false;
  }

  const {
    renderErrorDialog,
    errors,
  } = useAuthError({
    shouldRedirect,
  });

  useEffect(() => {
    enableButtonState();
  }, [errors]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={EcalLogoImage} alt="logo" className={classes.logotypeImage} />
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid item container className={classes.logoImageContainer}>
              <div id='logoImage' className={classes.logoImage}></div>
            </Grid>

            <ConfirmSignUpForm
                onConfirmSignUp={(code) => {
                    setVerifyIsLoading(true);
                    userStore.confirmSignUp({ code });
                }}

                onResendCode={() => {
                  setResendIsLoading(true);
                  userStore.resendSignupCode();
                }}
                verifyIsLoading={verifyIsLoading}
                resendIsLoading={resendIsLoading}
            />
            { renderErrorDialog() }
          </React.Fragment>

        </div>
      </div>
    </Grid>
  );
}

export default observer(ConfirmSignUp);
