import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
} from '@material-ui/core';
import { Typography, Link } from '../../components/Wrappers';
import Select from '../../commons/select/Select';
// import Select from '@material-ui/core/Select';

import { useStyles } from './styles';
import { BillingCoupons } from '../../utils/constants';

/* Reusable plan selection field */
function PlansSelection (props) {
    const {
        plans,
        selectedPlan,
        onSelectPlan,
        coupon,
        title = 'Upgrade Plan',
        planName,
    } = props;

    const classes = useStyles();

    function openSAASTerms () {
        window.open('https://ecal.com/license-agreement/', '_blank');
    }

    function renderCoupon () {
        if (planName.toLowerCase().indexOf('free') !== -1) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        if (coupon === BillingCoupons.ITSONUS23) {
            return (<span
                className={classes.couponCode}
            >
                {'(First Month Free)'}
            </span>);
        }

        if (coupon === BillingCoupons.SETMEUP23) {
            return (<span
                className={classes.couponCode}
            >
                {'- Receive Free Account Set-up'}
            </span>);
        }

        if (coupon === BillingCoupons.LETSDOTHIS23 &&
            planName.indexOf('Professional') !== -1) {
            return (<span
                className={classes.couponCode}
            >
                {'- Receive 25% Off  Your Licence Fee'}
            </span>);
        }

        return (
            <React.Fragment>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Grid
                container
                className={classes.planInfoContainer}
            >
                <Typography
                    className={classNames([classes.bottomSpace])}
                    weight={'medium'}
                    variant={'h5'}>
                   {title}
                    { renderCoupon() }
                </Typography>

                <Select
                        value={selectedPlan}
                        label= 'plan'
                        name= 'plan'
                        id= 'plan'
                        labelHeading= 'Select Plan'
                        handleChange={(event) => {
                            onSelectPlan(event.target.value);
                        }}
                        dataForDropdown={plans}
                        marginBottom={24}
                    />

                <Typography
                    variant={'body2'}
                    weight={'light'}
                    style={{ marginBottom: 24 }}
                >
                    {/* <strong>View/edit billing details: </strong>{!enterprisePlan ? <Link>click here</Link> : <Link>contact us</Link>} */}
                    <strong>Online SaaS Terms (Agreement): </strong> View the latest ECAL SaaS Terms <u><Link className={classes.linkPointer} onClick={openSAASTerms} >here</Link></u>.
                </Typography>
            </Grid>
        </React.Fragment>
    );
}

PlansSelection.propTypes = {
    plans: PropTypes.array,
    selectedPlan: PropTypes.any,
    onSelectPlan: PropTypes.func,
    title: PropTypes.string,
    coupon: PropTypes.string,
    planName: PropTypes.string,
};

PlansSelection.defaultProps = {
    selectedPlan: {},
    plans: [],
    onSelectPlan: () => {},
    title: 'Upgrade Plan',
    coupon: '',
    planName: '',
};

export default PlansSelection;
