import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    statusButton: {
      minWidth: '82px',
    },
    tableRow: {
      '& td': {
        padding: '6px',
        // fontSize: 0,
      },
    },
    tableCell: {
      padding: '6px',
      textAlign: 'left',
    },

    noData: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '50px',
      marginBottom: '32px',
      color: '#525252',
    },

}));
