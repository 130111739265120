import { validateInputError } from '../../error/utils';

function validateText (input) {
    if (!input.text || input.text.length === 0) {
        return 'Label text is required';
    }
    return undefined;
}

const validations = {
    text: validateText,
};

export function inputValidator (input) {
    return validateInputError(input, validations);
}
