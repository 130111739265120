import { useState, useEffect } from 'react';

import {
    onTableCellStateChange,
    rowDataToCustomField,
    customFieldToRowData,
    upsertRow,
    deleteRow,
} from './tableStateUtils';

import {
    defaultWithSource, serializeForSave,
} from './tableSourceSerializer';

function useSubscriberData (props) {
    const {
        dataSource,
        updateSavable,
        onDataChange,
    } = props;

    const [tableData, setTableData] = useState(defaultWithSource(dataSource));
    const [mode, setMode] = useState('add');
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [customFieldDataSource, setCustomFieldDataSource] = useState({});

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        const subscriberData = serializeForSave(tableData);
        updateSavable({
            ...dataSource,
            subscriberData,
        });
    }, [tableData]);

    useEffect(() => {
        /* merge Data Source with Default Data */
        console.log('useSubscriberData()useEffect().dataSource: ', dataSource);
        const merged = defaultWithSource(dataSource);
        setTableData(merged);
    }, [dataSource]);

    function onTableStateChange (params) {
        onTableCellStateChange(params, tableData, setTableData);
    }

    function updateCustomFieldSource (row) {
        if (row) {
            setCustomFieldDataSource(rowDataToCustomField(row));
        } else {
            setCustomFieldDataSource(null);
        }
    }

    function upsertCustomField (customFieldData) {
        const row = customFieldToRowData(customFieldData);
        setTableData(upsertRow(row, tableData));
    }

    function deleteCustomField () {
        setTableData(deleteRow(deleteTarget, tableData));
    }

    return {
        tableData,
        onTableStateChange,
        customFieldDataSource,
        updateCustomFieldSource,
        mode,
        setMode,
        upsertCustomField,
        deleteCustomField,
        setDeleteTarget,
    };
}

export default useSubscriberData;
