import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';

import {
    Typography,
    Link,
} from '../../../components/Wrappers/Wrappers';
import Button from '../../../commons/ButtonProgress';

import useBillingDialog from '../../billing/useBillingDialog';
import useUpgradeAddOnDialog from '../../addonsUpgrade/useUpgradeAddonDialog';
import useFeatures from '../../hooks/useFeatures';
import useBillingPlan from '../../billing/useBillingPlan';
import PlansSelection from '../../billing/plansSelection';
import { useStyles } from '../profile.styles';
import useWhiteLabel from '../../../store/models/styles/useWhiteLabel';
import { useBillingStore } from '../../../store/models';
import {
    // logInfo,
    isEnterprise,
    isFreeTrial,
    isFreePlan,
} from '../../../utils';
import AddOn from './addOns';
import { BillingCoupons } from '../../../utils/constants';
function Billing (props) {
    const {
        user,
        publisher,
        // onDataChange,
        // onSave,
    } = props;

    const billingStore = useBillingStore();

    const {
        features,
    } = useFeatures();
    const [data, setData] = useState(user);

 //   const [saveEnable, setSaveEnable] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [freeTrialPlan, setFreeTrial] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [enterprisePlan, setEnterpriseTrial] = useState(false);
    const classes = useStyles();

    const {
        plans,
        selectedPlan,
        updateSelectedPlan,
        publisherPlan,
        freePlan,
        upgradeFree,
        isUpgrading,
        upgradeDisabled,
        coupon,
        selectedPlanData,
    } = useBillingPlan();

    const {
        whiteLabelSettings,
    } = useWhiteLabel();

    const {
        openDialog,
        renderBillingDialog,
    } = useBillingDialog();

    const {
        openDialog: openAddonUpgrade,
        renderDialog: renderAddonUpgrade,
    } = useUpgradeAddOnDialog();

    useEffect(() => {
        if (coupon === BillingCoupons.EXPERTPLS) {
            // open intercom
            window.Intercom('showMessages');
        }
    }, [coupon]);

    function handleChange (e) {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        // setSaveEnable(true);
    }

    function handleUpgradeClick () {
        if (whiteLabelSettings.labelName === 'ecal') {
            window.Intercom('showMessages');
        } else {
            window.open(whiteLabelSettings.supportContact, '_blank');
        }
    }

    function openSAASTerms () {
        window.open('https://ecal.com/license-agreement/', '_blank');
    }

    function upgrade () {
        if (freePlan) {
            upgradeFree();
        } else {
            openDialog();
        }
    }

    function isWhiteLabel () {
        if (whiteLabelSettings.labelName !== 'ecal') {
            return true;
        }
        return false;
    }

    function getSubscriptionLimit () {
        if (isEnterprise(publisherPlan)) {
            return '∞';
        }
        return publisher.subscriberLimit || '15,000';
    }

    function shouldRenderBilling () {
        if (!isProviderUpgradable(publisherPlan?.provider)) {
            return false;
        }
        if (isEnterprise(publisherPlan)) {
            return false;
        }
        if (isWhiteLabel()) {
            return false;
        }
        return features.billingActive;
    }

    function shouldRenderBillingAlt () {
        if (publisherPlan?.provider === 'aws') {
            return false;
        }

        if (!isProviderUpgradable(publisherPlan?.provider)) {
            return true;
        }
        return !features.billingActive;
    }

    function shouldRenderAWSBillingInfo () {
        if (publisherPlan?.provider === 'aws') {
            return true;
        }
        return false;
    }

    function isProviderUpgradable (provider) {
        if (provider === 'ecal' || provider === 'chargify') {
            return true;
        }
        return false;
    }

    function renderAddOns () {
        if (isWhiteLabel()) {
            return (<React.Fragment> </React.Fragment>);
        }

        if (!shouldRenderBilling()) {
            return (<React.Fragment> </React.Fragment>);
        }

        if (isFreeTrial(publisherPlan.name) || isFreePlan(publisherPlan.name)) {
            return (<React.Fragment> </React.Fragment>);
        }
        /*
        if (isEmpty(publisherPlan) || publisherPlan?.addOns?.length <= 0) {
            return (<React.Fragment> </React.Fragment>);
        }
        */
        function renderAddOnItems () {
            if (isEmpty(publisherPlan) || publisherPlan?.addOns?.length <= 0) {
                return (<React.Fragment> </React.Fragment>);
            }
            return publisherPlan.addOns.map((item, i) => {
                return (
                    <AddOn
                        key={`addon-${i}`}
                        data={item}
                    />
                );
            });
        }
        return (
            <Grid className={classes.addOnsSpace}>
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 16 }}
                >
                    Add Ons
                </Typography>
                { renderAddOnItems() }

                <Grid
                    className={classes.addOnsUpgrade}
                    >
                    <Typography
                        variant={'body2'}
                        weight={'light'}
                    >
                        <b>Need more?</b> Add extra services, or more users, to your plan!
                    </Typography>
                    <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                billingStore.selectPlanFromID(publisherPlan.id, true);
                                openAddonUpgrade();
                            }}
                            showProgress={isUpgrading}
                            label="Add"
                        />
                </Grid>
            </Grid>
        );
    }

    function renderUpgradePlanTitle () {
        return 'Upgrade Plan';
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                        <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Billing & Plan
                                </Typography>
                                <TextField
                                    id="currentPlan"
                                    label="Current Plan"
                                    onChange={handleChange}
                                    name="currentPlan"
                                    value={publisher.planName || 'Community'}
                                    variant="outlined"
                                    style={{ marginBottom: 35 }}
                                    disabled={true}
                                    helperText="Your current plan"
                                />
                                {freeTrialPlan
                                ? <TextField
                                    id="trialExpiration"
                                    label="Trial expires on"
                                    onChange={handleChange}
                                    name="trialExpiration"
                                    value={data.trialExpiration || '22 June 2021'}
                                    variant="outlined"
                                    style={{ marginBottom: 57 }}
                                    disabled={true}
                                />
                                : null}
                                {freeTrialPlan
                                ? <TextField
                                    id="daysRemaining"
                                    label="Days remaining"
                                    onChange={handleChange}
                                    name="daysRemaining"
                                    value={data.daysRemaining || '27'}
                                    variant="outlined"
                                    style={{ marginBottom: 57 }}
                                    disabled={true}
                                />
                                : null}
                                { !freeTrialPlan && !enterprisePlan
                                ? <TextField
                                    id="subscriptionLimit"
                                    label="User Subscription Limit"
                                    onChange={handleChange}
                                    name="subscriptionLimit"
                                    value={getSubscriptionLimit()}
                                    variant="outlined"
                                    style={{ marginBottom: 24 }}
                                    disabled={true}
                                />
                                : null}
                                {/* hiding the button for this go live. */}

                                { renderAddOns() }

                                { shouldRenderBilling() && (

                                    <Grid>
                                            <PlansSelection
                                                planName={selectedPlanData?.name}
                                                coupon={coupon}
                                                title={renderUpgradePlanTitle()}
                                                plans={plans}
                                                selectedPlan={selectedPlan}
                                                onSelectPlan={(p) => updateSelectedPlan(p)}
                                            />
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-end'}
                                                marginBottom={'35px'}
                                            >

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => upgrade()}

                                                disabled={upgradeDisabled}
                                                showProgress={isUpgrading}
                                                label="Upgrade"
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                { shouldRenderAWSBillingInfo() && (
                                    <Typography
                                        variant={'body2'}
                                        weight={'light'}
                                        style={{ marginBottom: 12 }}
                                    >
                                        You are on an AWS Marketplace Contract.<br/>
                                        <strong>Please contact </strong><u><Link className={classes.linkPointer} onClick={handleUpgradeClick} >{whiteLabelSettings.supportLabel}</Link></u> to upgrade or make changes to your plan.
                                    </Typography>
                                )}

                                { shouldRenderBillingAlt() && (
                                    <Typography
                                        variant={'body2'}
                                        weight={'light'}
                                        style={{ marginBottom: 12 }}
                                    >
                                        <strong>Please contact </strong><u><Link className={classes.linkPointer} onClick={handleUpgradeClick} >{whiteLabelSettings.supportLabel}</Link></u> to upgrade or make changes to your plan.
                                    </Typography>
                                )}

                                { shouldRenderBillingAlt() && (
                                    <Typography
                                        variant={'body2'}
                                        weight={'light'}
                                        style={{ marginBottom: 30 }}
                                    >
                                        {/* <strong>View/edit billing details: </strong>{!enterprisePlan ? <Link>click here</Link> : <Link>contact us</Link>} */}
                                        <strong>Online SaaS Terms (Agreement): </strong> View the latest ECAL SaaS Terms <u><Link className={classes.linkPointer} onClick={openSAASTerms} >here</Link></u>.
                                    </Typography>
                                )}

                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            { renderBillingDialog() }
            { renderAddonUpgrade() }
        </React.Fragment>
    );
}

Billing.propTypes = {
    label: PropTypes.string,
};

Billing.defaultProps = {
    label: '',
};

export default observer(Billing);
