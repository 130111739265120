/*
 * tableSourceSerializer helps on converting json data to the table state.
 * It helps translate entries  based from defaultButton.json schema
 * and map it to tabular data that serve as the table state in subscriber data.
 */
import { serializeForParentConsent } from '../../utils';
import { fieldRowMap } from './tableStateUtils';

export const tableData = [
    {
        field: 'email',

        visibleType: 'always',
        visibleValue: false,

        fieldType: 'text',
        fieldValue: 'Email',

        requiredType: 'none',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'name',
        visibleType: 'toggle',
        visibleValue: false,

        fieldType: 'select', /* dropdown */
        fieldValue: 'firstName',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'mobile',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Mobile',

        requiredType: 'check',
        requiredValue: true,

        defaultValueType: 'countrySelect',
        defaultValueValue: '',
    },

    {
        field: 'ageGroup',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Age Group',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'country',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Country',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'countrySelect',
        defaultValueValue: 'ph',
    },

    {
        field: 'gender',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Gender',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'zip',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Zip',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'dob',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Date of Birth',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'none',
        defaultValueValue: '',
    },

    {
        field: 'requiredAge',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Date of Birth (minimum age required)',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'ageSelect',
        defaultValueValue: '',
    },

    {
        field: 'parentalConsent',
        visibleType: 'toggle',
        visibleValue: true,

        fieldType: 'text',
        fieldValue: 'Parental Consent',

        requiredType: 'check',
        requiredValue: false,

        defaultValueType: 'ageSelect',
        defaultValueValue: '',
    },

];

/* rowValues function will serialize the registrationFields value to match the required setup */

/*
    remember:
    0 is disabled
    1 is enabled+required
    2 is enabled
*/

function rowValues (value) {
    if (value === 0 || value === false) {
        /*
         * value = 0
         * switch is not toggle
         * required is not check.
         */
        return {
            visibleValue: 0,
            requiredValue: 0,
        };
    } else if (value === 1 || value === true) {
        /*
         * value = 1
         * switch is toggled
         * required is checked.
         */
        return {
            visibleValue: 1,
            requiredValue: 1,
        };
    }

    /*
     * value = 2
     * switch is toggled.
     * required is not check.
     */

    return {
        visibleValue: 1,
        requiredValue: 0,
    };
}

export function defaultWithSource (dataSource) {
    let newTableData = [
        ...tableData,
    ];
    const len = Object.keys(dataSource).length;
    if (len <= 0) {
        return newTableData;
    }
    const registrationFields = dataSource.subscriberData.registrationFields;
    // const customFields = dataSource.subscriberData.customFields;

    newTableData[0].value = registrationFields.email;
    /*
     * Rather than Dynamic assignment or generic function,
     * We created specific serialization function for each field,
     * to know what is being mapped in the table data.
     */
    newTableData = setNameField(newTableData, registrationFields);
    newTableData = setMobileField(newTableData, registrationFields);
    newTableData = setAgeGroupField(newTableData, registrationFields);
    newTableData = setCountryField(newTableData, registrationFields);
    newTableData = setGenderField(newTableData, registrationFields);
    newTableData = setZipField(newTableData, registrationFields);
    newTableData = setDOBField(newTableData, registrationFields);
    newTableData = setDOBWithMinAgeField(newTableData, registrationFields);
    newTableData = setParentalConsentField(newTableData, registrationFields);

    const customFields = dataSource.subscriberData.customFields;
    newTableData = setCustomFields(newTableData, customFields);

    return newTableData;
}

/*
 * This section will map registrationFields to the table Data.
 */

/*
registrationFields: {
    country: 0
    defaultCountry: "in"
    dob: 2
    email: null
    firstName: 0
    fullName: 0
    gender: 0
    mobile: 0
    parentConsentAgeEnabled: false
    parentConsentAgeValue: 19
    requiredAgeEnabled: true
    requiredAgeValue: 16

}
*/

function setNameField (source, regFields) {
    const newTableData = [...source];

    if (regFields.fullName >= 1) {
        newTableData[1] = {
            ...newTableData[1],
            fieldValue: 'fullName',
            ...rowValues(regFields.fullName),
        };
    } else {
        newTableData[1] = {
            ...newTableData[1],
            fieldValue: 'firstName',
            ...rowValues(regFields.firstName),
        };
    }

    return newTableData;
}

function setMobileField (source, regFields) {
    const newTableData = [...source];
    newTableData[2] = {
        ...newTableData[2],
        defaultValueValue: regFields.defaultCountry,
        ...rowValues(regFields.mobile),
    };
    return newTableData;
}

function setAgeGroupField (source, regFields) {
    const newTableData = [...source];
    newTableData[3] = {
        ...newTableData[3],
        defaultValueValue: regFields.ageGroup,
        ...rowValues(regFields.ageGroup),
    };
    return newTableData;
}

function setCountryField (source, regFields) {
    const newTableData = [...source];
    newTableData[4] = {
        ...newTableData[4],
        defaultValueValue: regFields.defaultCountry,
        ...rowValues(regFields.country),
    };
    return newTableData;
}

function setGenderField (source, regFields) {
    const newTableData = [...source];
    newTableData[5] = {
        ...newTableData[5],
        ...rowValues(regFields.gender),
    };
    return newTableData;
}

function setZipField (source, regFields) {
    const newTableData = [...source];
    newTableData[6] = {
        ...newTableData[6],
        ...rowValues(regFields.zip),
    };
    return newTableData;
}

function setDOBField (source, regFields) {
    const newTableData = [...source];
    newTableData[7] = {
        ...newTableData[7],
        ...rowValues(regFields.dob),
    };
    return newTableData;
}

function setDOBWithMinAgeField (source, regFields) {
    const newTableData = [...source];
    newTableData[8] = {
        ...newTableData[8],
        defaultValueValue: regFields.requiredAgeValue,
        ...rowValues(regFields.requiredAgeEnabled),
    };
    return newTableData;
}

function setParentalConsentField (source, regFields) {
    const newTableData = [...source];
    newTableData[9] = {
        ...newTableData[9],
        defaultValueValue: regFields.parentConsentAgeValue,
        ...rowValues(regFields.parentConsentAgeEnabled),
    };
    return newTableData;
}

function setCustomFields (source, customFields) {
    const startIndex = source.length;

    const cfieldData = customFields.map((customField, index) => {
        const newFieldData = {
            field: 'customField',
            customFieldType: customField.type,
            customFieldValues: customField.value,
            customFieldIndex: startIndex + index,

            visibleType: 'toggle',
            visibleValue: customField.visible,
            fieldType: 'text',
            fieldValue: customField.name,

            requiredType: 'check',
            requiredValue: customField.required,

            defaultValueType: 'customField',
            defaultValueValue: '',
        };

        /*
        if (customField.type === 'dropdown' && typeof Array.isArray(customField.value)) {
            newFieldData.customFieldValues = customField.value.join('\n');
        }
        */

        return newFieldData;
    });

    return [
        ...source,
        ...cfieldData,
    ];
}

function serializeRowValue (tableData, index) {
    const row = tableData[index];
    if (row.visibleValue && row.requiredValue) {
        return 1;
    }

    if (row.visibleValue && !row.requiredValue) {
        return 2;
    }

    return 0;
}

function customFieldData (rowdata) {
    return {
        type: rowdata.customFieldType,
        name: rowdata.fieldValue,
        value: rowdata.customFieldValues,
        visible: rowdata.visibleValue,
        required: rowdata.requiredValue,
    };
}

/*
 * This will convert tabledata to savables
 */
export function serializeForSave (tableData) {
    const registrationFields = {

        email: null,
        firstName: 0,
        fullName: 0,
        mobile: serializeRowValue(tableData, fieldRowMap.mobile),
        ageGroup: serializeRowValue(tableData, fieldRowMap.ageGroup),
        country: serializeRowValue(tableData, fieldRowMap.country),
        defaultCountry: tableData[fieldRowMap.country].defaultValueValue,
        gender: serializeRowValue(tableData, fieldRowMap.gender),
        zip: serializeRowValue(tableData, fieldRowMap.zip),
        dob: serializeRowValue(tableData, fieldRowMap.dob),

        requiredAgeEnabled: serializeRowValue(tableData, fieldRowMap.requiredAge),
        requiredAgeValue: tableData[fieldRowMap.requiredAge].defaultValueValue,

        parentConsentAgeEnabled: serializeForParentConsent(serializeRowValue(tableData, fieldRowMap.parentalConsent)),
        parentConsentAgeValue: tableData[fieldRowMap.parentalConsent].defaultValueValue,
    };

    registrationFields[tableData[1].fieldValue] = serializeRowValue(tableData, fieldRowMap.name);

    const customFields = tableData.reduce((fields, row, index) => {
        if (index <= fieldRowMap.parentalConsent) {
            return fields;
        }
        return [
            ...fields,
            customFieldData(row),
        ];
    }, []);

    return {
        registrationFields,
        customFields,
    };
}
