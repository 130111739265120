import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStyles from './styles';
import { Typography } from '../../components/Wrappers/Wrappers';

import useWhiteLabel from '../../store/models/styles/useWhiteLabel';
function Footer (props) {
  const {
  publisher = {},
} = props;
const {
  whiteLabelSettings,
} = useWhiteLabel();

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#0c0644');
  const classes = useStyles({ headerBackgroundColor, whiteLabelSettings });

  useEffect(() => {
    if (whiteLabelSettings.labelName === 'ecal') {
      if (publisher.planName === 'Enterprise') {
        setHeaderBackgroundColor('#6F2FCD');
      } else {
        setHeaderBackgroundColor(whiteLabelSettings.footerColor);
      }
    } else {
      setHeaderBackgroundColor(whiteLabelSettings.footerColor);
    }
  }, [publisher.planName]);

  return (
    <footer className={classes.body}>
      <Typography variant={'body2'} color="light">
        { whiteLabelSettings.copyrightText }
      </Typography>
    </footer>
  );
}

export default observer(Footer);
