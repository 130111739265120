import React, { useState, useRef, useEffect } from 'react';
import Filter from './index';

function useDateFilters (props) {
    const {
        filters,
        setFilters,
        applyFilters,
    } = props;

    const iconDateRef = useRef();
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (iconDateRef.current) {
            setAnchor(iconDateRef.current);
        }
    }, [iconDateRef]);

    function updateFilters (data) {
        setFilters(data);
    }

    function renderDateFilters () {
        return (
            <Filter
                anchorEl={anchor}
                open={open}
                setFiltersOpen={setOpen}
                updateFilters={updateFilters}
                filters={filters}
                onApplyFilters={(data) => {
                    applyFilters(data);
                    setOpen(false);
                }}
            />
        );
    }

    function showDateFilters () {
        setOpen(true);
    }

    function closeDateFilters () {
        setOpen(false);
    }

    return {
        iconDateRef,
        renderDateFilters,
        showDateFilters,
        closeDateFilters,
    };
}

export default useDateFilters;
