import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function useOptinsErrors (props) {
    const {
        input,
    } = props;

    const store = useErrorStore();

    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        hook: 'useOptinsErrors',
        input,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.getOptins,
            store.observed.errors.addOptin,
            store.observed.errors.editOptin,
            store.observed.errors.deleteOptin,
        ],
    });

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useOptinsErrors() Error Updates...', errors);
    }, [errors]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default useOptinsErrors;
