export function eventsQuery (vars) {
  return {
    query: `query ExportEvents($input: GetEventsInput, $sort: OrderInput, $page: Int ) {

            getEvents( getEventsInput:$input, eventSortInput: $sort,  page: $page, limit: 10 ) {
                totalCount
                edges {
                  node {
                    id
                    type
                    scheduleId
                    publisherId
                    publisherOrgId
                    name
                    data{
                      details
                      location
                      sponsoredMessage
                      reference
                      referenceType
                      banner
                      primaryLink
                      secondaryLink
                      shortURL
                      draft
                      focusX
                      focusY
                    }
                    time {
                      startDate
                      endDate
                      startTime
                      endTime
                      startGMT
                      endGMT
                      allDay
                      timezone
                    }
                    alertData {
                      alert1
                      alert2
                    }
                    socialData {
                      
                      facebook
                      twitter
                      instagram

                      youtube
                      vimeo
                      podcast
                    
                      linkedin
                      blog
                      tikTok
                    
                      iphone
                      ipad
                      android

                      snapchat
                      twitch
                      discord

                      linktree
                     
                    }
                    quickLinks {
                      name
                      url
                    }
                    isChild
                  }
                  cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
    variables: vars,
  };
}

export function queryCreateEvent (vars) {
  return {
    query: `mutation events($events: CreateEventInput){

      createEvent (createEventInput:$events) {
            totalCount
            edges {
              node {
                id
                modified
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
    variables: vars,
  };
}

export function queryUpdateEvents (vars) {
  return {
    query: `mutation events($events: [UpdateEventInput]){

        updateEvents (updateEventsInput:$events) {
            totalCount
            edges {
              node {
                updated
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
    variables: vars,
  };
}

export function queryUpdateEventsStatus (vars) {
  return {
    query: `mutation events($events: [UpdateEventsStatusInput]){

      updateEventsStatus (updateEventsStatusInput:$events) {
            totalCount
            edges {
              node {
                updated
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
    variables: vars,
  };
}

export function queryBulkDeleteEvents (vars) {
  return {
    query: `mutation deleteEvents($events: DeleteEventsInput){
        deleteEvents (deleteEventsInput:$events) {
            totalCount
            edges {
              node {
                id
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
    variables: vars,
  };
}

export function queryBulkCreateEvents (vars) {
  return {
    query: `mutation events($i: [CreateEventInput]){
        createEvents (createEventsInput:$i) {
            totalCount
            edges {
              node {
                created
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
    variables: vars,
  };
}
