import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';
import { v4 as uuid } from 'uuid';

import {
   Close,
   ExpandMore,
   ExpandLess,
} from '@material-ui/icons';

import PopMenu from './popmenu';

import useSelectSearch from './useSelectSearch';

function SelectSearch (props) {
    const {
        selected: selectedSource,
        helperText,
        placeHolder,
        placeHolder2,
        dataProvider: dataSource,
        onSelect,
        isRemoteSearch,
        onRemoteSearch,
        showProgress,
    } = props;

    const {
        baseRef,
        editRef,

        menuAnchor,
        menuOpen,
        menuWidth,
        setMenuOpen,

        handleDelete,
        handleSelect,
        handleSearch,
        dataProvider,
        selected,
    } = useSelectSearch({
        dataProvider: dataSource,
        selected: selectedSource,
        mode: 'local',
        onSelect,
        isRemoteSearch,
        onRemoteSearch,
    });

    const classes = useStyles({
        placeHolder: selected.length > 0 ? placeHolder2 : placeHolder,
    });

    function getValueLabel (value) {
        const result = dataSource.filter((data) => (data.value === value));
        if (!result || result.length <= 0) {
            return '';
        }
        return result[0].label;
    }

    function renderValues () {
        return selected.map((value) => {
            return (
            <span
                className={classes.inSpan} key={uuid()}
                >
                    <span className={classes.truncate}>{ getValueLabel(value) }</span>
                    <Close
                        className={classes.spanIcon}
                        fontSize={'inherit'}
                        onClick={() => {
                            handleDelete(value);
                        }}
                />
            </span>);
        });
    }

    function renderExpand () {
        if (menuOpen) {
            return <ExpandLess
                className={classes.expandIcon}
                fontSize={'small'}
                onClick={() => {
                    setMenuOpen(false);
                }}
            />;
        }
        return <ExpandMore
            className={classes.expandIcon}
            fontSize={'small'}
            onClick={() => {
                console.log('hide menu');
                setMenuOpen(true);
            }}
        />;
    }

    return (
        <React.Fragment>
            <Grid className={classes.componentBase}>
                {/*
                <span className={classes.legend}>
                    {label}
                </span>
                */}
                <Grid
                    className={classes.root}
                    ref={baseRef}
                    onClick={() => {
                        if (editRef.current) {
                            editRef.current.focus();
                        }
                    }}
                >
                    <div
                        className={classes.base}
                    >
                        {renderValues()}
                        <div
                            ref={editRef}
                            contentEditable
                            className={classes.editable}
                            onInput={(e) => {
                                handleSearch(e.target.innerHTML);
                                e.stopPropagation();
                            }}
                            placeholder={placeHolder}
                        >

                        </div>
                    </div>
                    {renderExpand()}
                </Grid>
                <Grid
                    className={classes.helperText}
                >
                    {helperText}
                </Grid>
            </Grid>
                <PopMenu
                    anchorEl={menuAnchor}
                    open={menuOpen}
                    width={menuWidth}
                    dataProvider={dataProvider}
                    selected={selected}
                    onClose={() => setMenuOpen(false)}
                    onSelect={(data) => {
                        handleSelect(data);
                    }}
                    showProgress={showProgress}
                    onDelete={(value) => handleDelete(value)}
                />
        </React.Fragment>

    );
}

SelectSearch.propTypes = {
      helperText: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.array,
      dataProvider: PropTypes.array,
      onSelect: PropTypes.func,
      placeHolder: PropTypes.string,
      placeHolder2: PropTypes.string,
      isRemoteSearch: PropTypes.bool,
      onRemoteSearch: PropTypes.func,
      showProgress: PropTypes.bool,
};

SelectSearch.defaultProps = {
    helperText: 'Search and select options',
    label: 'Select or Search',
    placeHolder: 'Select or search entry',
    placeHolder2: 'search for more',
    dataProvider: [
        { value: 'feature-set', label: 'Feature Sets' },
        { value: 'multi-select', label: 'Multi Select' },
        { value: 'searchable', label: 'Searchable' },
        { value: 'responsive', label: 'Responsive' },
        { value: 'manual delete', label: 'Manual Deletion' },
    ],
    selected: [],
    onSelect: () => {},
    isRemoteSearch: false,
    onRemoteSearch: () => {},
    showProgress: true,
};

export default SelectSearch;
