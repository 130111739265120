
export function groupAndSortClicks (clicks) {
    const grouped = {};

    for (let i = 0; i < clicks.length; i++) {
        const item = clicks[i];
        let name = item.link_name;
        if (name === '') {
            name = 'Undefined';
        }

        if (!grouped[name]) {
            grouped[name] = {
                parent: true,
                open: false,
                count: item.count,
                link_name: name,
                url: '',
            };

            grouped[name].links = [{
                ...item,
                link_name: '',
            }];
        } else {
            grouped[name].count = grouped[name].count + item.count;
            grouped[name].links.push({ ...item, link_name: '' });
        }
    };

    const clicksList = Object.values(grouped);
    clicksList.sort((item1, item2) => (item2.count - item1.count)); // descending sort

    // sort the list links
    for (let i = 0; i < clicksList.length; i++) {
        clicksList[i].links.sort((item1, item2) => (item2.count - item1.count));
    }

    return clicksList;
}
