import { useEffect, useState } from 'react';
import { useErrorStore } from './';

function useErrorNotification (props) {
    const errorStore = useErrorStore();

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const err = errorStore.getWebhookError;
        if (err.title && err.message) {
            setTitle(err.title);
            setMessage(err.message);
            setHasError(true);
        } else {
            setHasError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorStore.getWebhookError]);

    // render error ...
    useEffect(() => {
        if (errorStore.appErrorKey) {
            setTitle(errorStore.title);
            setMessage(errorStore.message);
            if (errorStore.title && errorStore.message) {
                setHasError(true);
            } else {
                setHasError(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorStore.appErrorKey]);

    return {
       title,
       message,
       hasError,
    };
}

export default useErrorNotification;
