
export function queryGetPlanDetails (vars) {
  return {
    query: `query  ($i: GetPlanDetailsInput) {
      getPlanDetails ( 
          getPlanDetailsInput: $i
      )
     {
          edges {
              node {
                  id
                  name
                  code
                  description
                  price
                  addOns {
                      id
                      name
                      handle
                      description
                      price
                      type
                      recurring
                  }
              }
              cursor
          }
          pageInfo {
              endCursor
              hasNextPage
          }
          totalCount
      } 
  }`,
    variables: vars,
  };
}

export function queryGetCurrentPlan (vars) {
  return {
    query: `query {
      getCurrentPlan {
          edges {
              node {
                  id
                  code
                  name
                  description
                  price
                  status
                  provider
                  addOns {
                      id
                      name
                      description
                      price # not being returned at the moment
                      type
                      quantity
                      handle
                  }
              }
              cursor
          }
          pageInfo {
              endCursor
              hasNextPage
          }
          totalCount
      } 
  }`,
  variables: vars,
  };
}

export function queryCreateSubscription (vars) {
    return {
        query: `mutation Billing($i: CreateSubscriptionInput!){
  
            createSubscription (createSubscriptionInput:$i) {
              totalCount
              edges {
                node {
                    subscriptionId
                    customerId

                    chargifyResult {
                      errors
                      description
                      actionLink
                      gatewayPaymentId
                   }
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            } 
        }
        `,
        variables: vars,
    };
  }

export function queryFinalize3DSecure (vars) {
  return {
      query: `mutation Billing($i: CreateSubscriptionInput!){

          finalize3DSecureSubscription (createSubscriptionInput:$i) {
            totalCount
            edges {
              node {
                  subscriptionId
                  customerId

                  chargifyResult {
                    errors
                    description
                    actionLink
                  }
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}

export function queryUpdateSubscription (vars) {
  return {
      query: `mutation Billing($i: CreateSubscriptionInput!){

        upgradeSubscription (upgradeSubscriptionInput:$i) {
            totalCount
            edges {
              node {
                  subscriptionId
                  customerId
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          } 
      }
      `,
      variables: vars,
  };
}
