import { isEmpty } from 'lodash';
import { validateInputError } from '../../error/utils';

function validateEmpty (input, field) {
    const map = {
        cvv: 'CVV is required',
        address: 'Address is required',
        state: 'State is required',
        city: 'City is required',
        country: 'Country is required',
        zip: 'Zip is required',
    };

    if (isEmpty(input[field])) {
        return map[field];
    }

    return '';
}

const validations = {
    cvv: (input) => validateEmpty(input, 'cvv'),
    address: (input) => validateEmpty(input, 'address'),
    city: (input) => validateEmpty(input, 'city'),
    state: (input) => validateEmpty(input, 'state'),
    country: (input) => validateEmpty(input, 'country'),
    zip: (input) => validateEmpty(input, 'zip'),
};

export function inputValidator (input, touched = {}) {
    const inputError = validateInputError(input, validations, touched);
    return inputError;
}
