import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { Typography } from '../../../../components/Wrappers';
import Checkbox from '../../../../commons/Checkbox';

import useStyles from './styles';

const SETTINGS_INSTRUCTION = 'By selecting \'Show All Events\', there will be an \'All Events\' filter added to your display, so it\'s easy to view all of your events (recommended).';

function InputSettings (props) {
    const {
        designData,
        setDesignData,
    } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}
            >
                <Grid className={classes.sectionContent} >
                    <Grid className={classes.sectionDescription}>
                        <Typography variant='sub1'>
                            {SETTINGS_INSTRUCTION}
                        </Typography>
                    </Grid>
                    <Grid className={classes.input}>
                        <Checkbox
                            checked={designData.showAllEvents}
                            onChange={e => {
                                setDesignData({
                                    ...designData,
                                    showAllEvents: !designData.showAllEvents,
                                });
                            }}
                            variant="primary"
                            label="Show All Events"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

InputSettings.propTypes = {
    setDesignData: PropTypes.func,
    designData: PropTypes.any,
};

InputSettings.defaultProps = {
    setDesignData: () => {},
    designData: {},
};

export default InputSettings;
