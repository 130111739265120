import { useEffect, useState } from 'react';
import { useDashboardStore } from '../../../../store/models';

/*
 * This hook is responsible on the data rendered by the pop table
 */
function usePoplinkTable (props) {
    const {
        rowsPerPage,
        tab = 0,
        defaultPageIndex = 0,
    } = props;

    const dashboardStore = useDashboardStore();
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(defaultPageIndex);
    const [totalRows, setTotalRows] = useState(0);
    const [source, setSource] = useState([]);
    const [parentRowsPerPage] = useState(rowsPerPage);
    const [computedRowsPerPage, setComputedRowsPerPage] = useState(rowsPerPage);
    const [showPagination, setShowPagination] = useState(false);

    useEffect(() => {
        console.log('dashboardStore.clicksss? ', dashboardStore.clicks);
        if (dashboardStore.clicks) {
            setSource(dashboardStore.clicks);
            setTotalRows(dashboardStore.clicks.length);

            const { rows, totalVisibleRows } = computeRenderableRows(dashboardStore.clicks, page);
            console.log('Setting Table Data Rows: ', rows);
            setTableData(rows);
            setComputedRowsPerPage(totalVisibleRows);
        } else {
          setTotalRows(0);
        }
    }, [dashboardStore.observed.clicks]);

    useEffect(() => {
        if (tab === 0) {
            updatePage(0);
        }

        if (tab === 1) {
            setTableData(dashboardStore.urlClicks);
            setComputedRowsPerPage(rowsPerPage);
        }
    }, [tab]);

    useEffect(() => {
        if (totalRows > parentRowsPerPage) {
            setShowPagination(true);
        } else {
            setShowPagination(false);
        }
    }, [totalRows, computedRowsPerPage]);

    function toggleRow (target) {
        // don't toggle
        if (!target.parent) return;
        toggleSource(target);
    }

    /* this will toggle the source to open a row */
    function toggleSource (target) {
        const newSource = [];
        for (let i = 0; i < source.length; i++) {
            const row = source[i];
            if (!row) continue;
            if (row.link_name === target.link_name) {
                newSource.push({
                    ...row,
                    open: !row.open,
                });
            } else {
                newSource.push({ ...row });
            }
        }
        setSource(newSource);

        const { rows, totalVisibleRows } = computeRenderableRows(newSource, page);
        setTableData(rows);
        setComputedRowsPerPage(totalVisibleRows);
    }

    function computeRenderableRows (dataSource, pageIndex) {
        const rows = [];
        let visibleRows = parentRowsPerPage;

        const start = pageIndex * parentRowsPerPage;
        let end = start + parentRowsPerPage;
        /*
        console.log('Page Index: ', pageIndex);
        console.log('parentRowsPerPage: ', parentRowsPerPage);
        console.log('Row Start at: ', start);
        console.log('Row End at: ', end);
        */
        if (end < totalRows) {
            end = totalRows;
        }

        if (end > totalRows && totalRows !== 0) {
            end = totalRows;
        }

        if (dataSource.length <= 0) {
            return {
                rows,
                visibleRows,
            };
        }

        // console.log('is Row End totalRow?: ', end);
        for (let i = start; i < end; i++) {
            const row = dataSource[i];
            if (!row) continue;
            rows.push({ ...row });
            if (row.open) {
                visibleRows += row.links.length;
                rows.push(...row.links);
            }
        }

        return {
            rows,
            totalVisibleRows: visibleRows,
        };
    }

    function updatePage (newPage) {
        const { rows, totalVisibleRows } = computeRenderableRows(source, newPage);
        setTableData(rows);
        setComputedRowsPerPage(totalVisibleRows);

        setPage(newPage);
    }

    function paginationInfo () {
        const displayPage = page + 1;
        const start = (page * parentRowsPerPage) + 1;
        let end = (page + 1) * parentRowsPerPage;
        if (end > totalRows) {
            end = totalRows;
        }
        return 'Page ' + displayPage + ' : ' + start + ' - ' + end + ' of ' + totalRows;
    }

    return {
        tableData,
        toggleRow,
        computedRowsPerPage,
        page,
        updatePage,
        parentRowsPerPage,
        totalRows,
        showPagination,
        paginationInfo,
    };
}

export default usePoplinkTable;
