import React from 'react';

import {
    Box,
} from '@material-ui/core';

import { Typography } from '../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
    CreateOutlined as CreateIcon,
    DateRange,
} from '@material-ui/icons';

import language from '../../../commons/util/Language.json';

/*
 * Note:
 * id field in the column matches ff:
 *      the row field or data field from api response
 *      customCell renderers
 */
export const columns = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'ref',
         numeric: true,
        disablePadding: false,
        label: 'Reference',
    },

    {
        id: 'message',
        numeric: true,
        disablePadding: false,
        label: 'Message Text',
        cellClassName: 'messageCell',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'ACTIONS',
    },
];

/* Custom Cells */

function actionCell ({ index, row, column, props }) {
    const {
        onEdit,
        onDelete,
        classes,
    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
            <IconButton
                color={'primary'}
                onClick= { (e) => {
                    e.preventDefault();
                    onEdit(row);
                }}
                className={classes.actionCellIcon}
            >
                <CreateIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    onDelete(row);
                }}
                color={'primary'}
                className={classes.actionCellIcon}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
}

function nameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    function renderName () {
        if (row.language) {
            const lang = getLanguage(row.language);
            return lang.label + ' ' + row.name;
        }
        return 'Default ' + row.name;
    }
    return (
            <Typography
                className={classes.nameCell}
                variant={'body2'}
            >
                {renderName()}
            </Typography>
    );
}

function messageCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.messageText}
                variant={'body2'}
            >
                {row.message}
            </Typography>
    );
}

function getLanguage (value) {
    return language.filter((item) => (item.value === value))[0];
}
export const customCells = {
    name: nameCell,
    actions: actionCell,
    message: messageCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <DateRange fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
