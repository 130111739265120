import React from 'react';
import { observer } from 'mobx-react';

import { useHistory } from 'react-router';

import {
  Grid,
  Box,
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Select from '../../../commons/select/Select';
// import ErrorContainer from '../../../commons/errorContainer/Error';

import ProgressBar from '../../../commons/progress/Progress';
import { Button, Typography, Link } from '../../../components/Wrappers';
import ButtonProgress from '../../../commons/ButtonProgress';

import language from '../../../commons/util/Language.json';
import { useStyles } from './styles';
import useOptinsInput from './useOptinsInput';
import useModuleErrorDialog from '../../../store/models/error/useModuleErrorDialog';
import Checkbox from '../../../commons/Checkbox';

const OptinsForm = (props) => {
    const {
        location,
    } = props;

    const {
        errors,
        isLoading,
        setIsLoading,
        progress,
        input,
        setInput,
        upsertOptins,
    } = useOptinsInput({ location });

    const history = useHistory();
    const classes = useStyles();

    const { renderErrorDialog } = useModuleErrorDialog({ errors });

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div>
                        <Grid item justify={'center'} container>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                width={600}
                                className={classes.boxStyling}
                            >
                                <Typography
                                    variant={'h5'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30 }}
                                >
                                    Opt-ins
                                </Typography>
                                { progress
                                    ? <ProgressBar />
                                    : <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={'100%'}>

                                        <TextField
                                            inputProps={{
                                                className: classes.messageInput,
                                            }}
                                            id="message"
                                            label="Opt-in Message"
                                            multiline
                                            rows="4"
                                            onChange={(e) => {
                                                setInput({
                                                    ...input,
                                                    message: e.target.value,
                                                });
                                            }}
                                            name="message"
                                            value={input.message}
                                            variant="outlined"
                                            style={{ marginBottom: 32 }}
                                            helperText="Enter opt-in message text"
                                        />

                                        <Select
                                            id='language'
                                            label= 'language'
                                            name= 'language'
                                            value={input.language}
                                            labelHeading= 'Language'
                                            placeHolder= 'Select a language for this opt-in'
                                            handleChange={(e, data) => {
                                                setInput({
                                                    ...input,
                                                    language: data.props.value,
                                                });
                                            }}

                                            dataForDropdown={language}
                                            helperText={
                                                'Select a language for this opt-in'
                                            }
                                        />

                                        <TextField
                                            id="reference"
                                            label="Opt-in Reference"
                                            onChange={(e) => {
                                                setInput({
                                                    ...input,
                                                    ref: e.target.value,
                                                });
                                            }}
                                            name="reference"
                                            value={input.ref}
                                            variant="outlined"
                                            style={{ marginBottom: 35 }}
                                            helperText="Enter an opt-in reference (eg: Terms, Mktg)"
                                        />
                                        <Box
                                            width="90%"
                                            mb={2}
                                        >
                                            <Checkbox
                                                checked={!input.optional}
                                                defaultChecked={!input.optional}
                                                onChange={e => {
                                                    setInput({
                                                        ...input,
                                                        optional: !e.target.checked,
                                                    });
                                                }}
                                                variant="primary"
                                                label=" Required? Tick this box if the user must accept this opt-in to subscribe."
                                            />
                                        </Box>

                                        <Box
                                            width="90%"
                                            marginTop={0}
                                            marginBottom={4}
                                        >
                                            <Checkbox
                                                checked={input.isSelected}
                                                defaultChecked={input.isSelected}
                                                onChange={e => {
                                                    setInput({
                                                        ...input,
                                                        isSelected: e.target.checked,
                                                    });
                                                }}
                                                variant="primary"
                                                label=" Pre-ticked? Tick this box if you want this opt-in pre-ticked by default."
                                            />
                                        </Box>

                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                        >
                                                <Button
                                                    onClick= { (e) => {
                                                        e.preventDefault();
                                                        history.push('/admin/marketing/optins');
                                                    }}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                >
                                                    <Link
                                                        color='#fff'>
                                                    close
                                                    </Link>
                                                </Button>

                                                <ButtonProgress
                                                    showProgress={isLoading}
                                                    label="Save"
                                                    onClick={(e) => {
                                                        setIsLoading(true);
                                                        upsertOptins();
                                                    }}
                                                />
                                        </Box>

                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            { renderErrorDialog() }
        </React.Fragment>
    );
};

export default observer(OptinsForm);
