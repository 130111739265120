import { useEffect, useState } from 'react';
import { useErrorStore } from '../../store/models';
import Validations, { validate } from '../../utils/validations';
import useAssetsErrors from '../../store/models/user/errors/useAssetsErrors';
import useAuthError from '../../store/models/user/errors/useAuthError';
import useErrorListener from '../../store/models/error/useErrorListener';
import useStateRef from '../hooks/useStateRef';
function useLoginError (props) {
    const {
        loginInput,
        setIsLoading,
    } = props;
    const errStore = useErrorStore();
    const [touched, setTouched] = useState({});
    const [_loginInput, setLoginInput] = useState(loginInput);
    const [loginDisabled, setLoginDisabled, loginDisabledRef] = useStateRef(true);
    const [localError, setLocalError] = useState({});

    const {
        errors: assetsErrors,
    } = useAssetsErrors();

    const {
        errors: authErrors,
    } = useAuthError();

    const {
        errorCancelled,
    } = useErrorListener();

    const [loginError, setLoginError] = useState({
        email: '',
        password: '',
    });

    function validateByChange () {
        const err = {
            email: validateEmail(),
            password: validatePassword(),
        };

        console.log('Login Input: ', _loginInput);
        if (err.email ||
            err.password ||
            !_loginInput.email ||
            !_loginInput.password
        ) {
            setLoginDisabled(true);
            window.loginDisabled = true;
        } else {
            setLoginDisabled(false);
            window.loginDisabled = false;
        }
        setLoginError(err);
    }

    function validateEmail () {
        if (!touched.email) {
            return '';
        }
        return validate({
            rules: { ...Validations.email },
            messages: {
                required: 'Email is required.',
                minLength: 'Email mininum length is 5',
                maxLength: 'Email maximum length is 256',
                pattern: 'Email format is incorrect, please follow email format john.jacob@domain.com',
            },
            value: _loginInput.email,
        });
    }

    function validatePassword () {
       return '';
    }

    function touchInputs () {
        const t = { ...touched };
        for (const p in loginInput) {
            if (loginInput[p] && t[p] !== true) {
                t[p] = true;
            }
        }
        setTouched(t);
    }

    useEffect(() => {
        touchInputs();
        setLoginInput(loginInput);
    }, [loginInput]);

    useEffect(() => {
        validateByChange();
    }, [_loginInput]);

    useEffect(() => {
        if (errStore.authError.logIn) {
            setLoginError({
                email: errStore.authError.logIn.email,
                password: errStore.authError.logIn.password,
            });
        }
    }, [errStore.authError.logIn]);

    useEffect(() => {
        setIsLoading(false);
    }, [errorCancelled]);

    useEffect(() => {
        setLocalError(assetsErrors);
    }, [assetsErrors]);

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('auth errors updated: ', authErrors);
        if (authErrors) {
            setLocalError(authErrors);
        }
    }, [authErrors]);

    return {
        loginError,
        loginDisabled,
        loginDisabledRef,
        errors: localError,
    };
}

export default useLoginError;
