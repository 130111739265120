import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
    Grid,
    Box,
    TextField,
} from '@material-ui/core';
import {
    Add as AddIcon,
} from '@material-ui/icons';
import {
    Typography,
    Button,
} from '../../../components/Wrappers/Wrappers';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from '../../../commons/dialog/Dialog';
import ButtonProgress from '../../../commons/ButtonProgress';
// import LinksTable from '../../../commons/tables/EventLinksTable';
// import DataGrid from '../../../commons/dataGrid/DataGrid';
import LinksTable from './linksTable';
import useStyles from './styles';
import useQuickLinksError from './linksTable/useQuickLinksError';
import useConfirmationDialog from '../../../commons/dialog/useConfirmationDialog';

const Links = (props) => {
    const {
        pageTitle,
        input,
        setInput,
        updateEvents,
        isManagedByFeed,
        isManagedByChild,
        isLoading,
        hasValidationFieldError,
    } = props;

    const classes = useStyles();

    function sanitizedQuicklinks () {
        if (input.quickLinks.length <= 0) {
            return [];
        }

        const filtered = input.quickLinks.filter((link) => {
            if (link.name || link.url || link.id) {
                return true;
            }
            return false;
        });
        return filtered.map((item, i) => ({ ...item, ordinal: i }));
    }

    const [quickLinksData, setQuickLinksData] = useState(sanitizedQuicklinks());
    const [linksPopup, setLinksPopup] = useState(false);
    const [quickLinkMode, setQuickLinkMode] = useState('');
    const [activeQuickLink, setActiveQuickLink] = useState({});
    const [quicklinkTitle, setQuickLinkTitle] = useState('Add Quick Link');

    const {
        error,
        linkConfirmDisabled,
        disableConfirm,
        resetTouch,
    } = useQuickLinksError({ activeQuickLink });

    useEffect(() => {
        setInput({
            ...input,
            quickLinks: quickLinksData.map(link => ({
                ...link,
                ordinal: undefined,
            })),
        });
    }, [quickLinksData]);

    const {
        renderDialog: renderExceedDialog,
        openDialog: openConfimDialog,
    } = useConfirmationDialog({
        title: 'Quick Links Exceeded',
        message: 'You can only create a maximum of 10 quick links per event.',
        confirmLabel: 'Ok',
        showSecondaryButton: false,
        onConfirm: () => {},
    });

    function handleAdd () {
        setQuickLinkTitle('Add Quick Link');
        if (quickLinksData.length < 10) {
            setQuickLinkMode('add');
            setActiveQuickLink({
                name: '',
                url: '',
                ordinal: quickLinksData.length,
            });
            setLinksPopup(true);
        } else {
            openConfimDialog();
        }
    }

    const handleEditLink = (data) => {
        console.log('data', data);
        setActiveQuickLink(data);
        setQuickLinkMode('edit');
        setLinksPopup(true);
    };

    function handleSaveAfterEdit () {
        if (quickLinkMode === 'add') {
            addNewQuickLink();
        } else if (quickLinkMode === 'edit') {
            updateQuickLinkList();
        }
        disableConfirm();
        resetTouch();
        setLinksPopup(false);
    };

    function updateQuickLinkList () {
        const newQuickLink = quickLinksData.map((link) => {
            if (link.ordinal === activeQuickLink.ordinal) {
                return { ...activeQuickLink };
            }
            return { ...link };
        });
        setQuickLinksData(newQuickLink);
    }

    function addNewQuickLink () {
        setQuickLinksData([
            ...quickLinksData,
            activeQuickLink,
        ]);
    }

    const handleDeleteLink = (selectedRow) => {
        // fixing
       let skipValidation = false;
        const newQuickLink = quickLinksData.reduce((prev, current, index, links) => {
            const link = links[index];
            if (skipValidation || link.ordinal !== selectedRow.ordinal) {
                return [
                    ...prev,
                    {
                        ...link,
                        ordinal: index,
                    },
                ];
            }
            skipValidation = true;
            return prev;
        }, []);

        /*
        const newQuickLink = quickLinksData.map(link => {
            if (link.ordinal === selectedRow.ordinal) {
                link.name = '';
                link.url = '';
            }
            return { ...link };
        });
        */

        setQuickLinksData(newQuickLink);
    };

    return (
        <React.Fragment>
            <Grid item className={classes.mainContainer} justify={'center'} container>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={800}
                    className={classes.boxStyling}
                >
                    <Box display={'flex'} justifyContent={'space-between'} style={{ width: 'calc(100%)' }}>
                        <Typography
                            variant={'h5'}
                            weight={'medium'}
                            style={{ marginBottom: 30 }}
                            data-intercom-target='event-quicklink-title'
                        >
                            {pageTitle} Quick Links
                        </Typography>
                        <Box display={'flex'}
                            flexDirection={'column'}
                            ml={1}>
                        <Button variant={'contained'} color={'success'}
                                onClick= {handleAdd}
                                disabled={isManagedByFeed || isManagedByChild}
                                data-intercom-target='event-add-quicklink'
                            >
                            <Box mr={1} display={'flex'}>
                                <AddIcon />
                            </Box>
                            Add
                        </Button>
                        </Box>
                    </Box>

                    <Typography
                        // variant={'body1'}
                        // weight={'medium'}
                        block
                        style={{ marginBottom: 30 }}
                    >
                        These links appear in the details of your calendar event, to drive engagement and response. e.g.: Buy Tickets, Book Now!, Latest Video, Online Shop etc.
                    </Typography>

                    <LinksTable
                        handleEdit={(v) => {
                            setQuickLinkTitle('Edit Quick Link');
                            handleEditLink(v);
                        }}
                        handleDelete={handleDeleteLink}
                        linksData={quickLinksData}
                        isManagedByChild={isManagedByChild}
                    />
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        style={{ marginTop: 30 }}
                    >
                        <ButtonProgress
                            showProgress={isLoading}
                            label='Save'
                            disabled={isManagedByFeed || hasValidationFieldError || isManagedByChild}
                            onClick={() => {
                                console.log('Save event');
                                updateEvents();
                            }}
                            dataIntercomTarget='event-save-button'
                        />
                    </Box>
                </Box>
                <Dialog
                    openDialog={linksPopup}
                    handleAgree={() => {
                        handleSaveAfterEdit();
                    }}
                    buttonPrimary={'Confirm'}
                    buttonSecondary={'Cancel'}
                    primaryDisableFlag={linkConfirmDisabled}
                    closeModal={() => {
                        resetTouch();
                        disableConfirm();
                        setLinksPopup(false);
                    }}
                    title={quicklinkTitle}
                >
                    <Box
                        display={'flex'}
                        flexDirection='column'
                        width={'300px'}
                        >
                        <TextField
                            id='name'
                            label='Link name'
                            name='name'
                            value={activeQuickLink.name}
                            onChange={(e) => {
                                setActiveQuickLink({
                                    ...activeQuickLink,
                                    name: e.target.value,
                                });
                            }}
                            variant='outlined'
                            style={{ marginTop: 10, marginBottom: 35 }}
                            error={error.name}
                            helperText={error.name || '*Please enter link name.'}
                        />
                        <TextField
                            id='url'
                            label='Destination URL'
                            name='url'
                            value={activeQuickLink.url}
                            onChange={(e) => {
                                let url = e.target.value;
                                if (url) {
                                    url = url.trim();
                                }
                                setActiveQuickLink({
                                    ...activeQuickLink,
                                    url,
                                });
                            }}
                            variant='outlined'
                            style={{ marginBottom: 20 }}
                            error={error.url}
                            helperText={error.url || '*Please enter destination url.'}
                        />
                    </Box>

                </Dialog>
            </Grid>
            { renderExceedDialog() }
        </React.Fragment>
    );
};

Links.propsTypes = {
    input: PropTypes.object,
    setInput: PropTypes.func,
    updateEvents: PropTypes.func,
    pageTitle: PropTypes.string,
    isManagedByFeed: PropTypes.bool,
    isManagedByChild: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasValidationFieldError: PropTypes.bool,
};

Links.defaultProps = {
    input: {},
    setInput: () => {},
    updateEvents: () => {},
    pageTitle: '',
    isManagedByFeed: false,
    isManagedByChild: false,
    isLoading: false,
    hasValidationFieldError: false,
};

export default Links;
