import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Grid,
    Typography,
    // Tab,
    // Box,
} from '@material-ui/core';
import SelectSearch from '../../../commons/ecal/singleselectsearch';
import Select from '../../../commons/select/Select';
import FileSelect from '../../../commons/ecal/fileSelect';
import { useStyles } from './styles';
import { ignoreRowData } from './utils';
function ImportUploadCSV (props) {
    const {
        readSpreadsheet,
        dataProvider,
        input,
        setInput,
    } = props;
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container className={classes.stepContentRoot}>

                <Grid className={classNames([classes.input, classes.inputMargin])}>
                    <Typography
                        variant='body2'
                        weight='medium'
                        className={classes.uploadLabel}
                    >
                        Upload Event Worksheet
                    </Typography>

                    <FileSelect
                        id="message"
                        width='100%'
                        accept=".csv,.xlsx"
                        label=""
                        type="file"
                        value={input.fileName}
                        onFileSelect={(files) => {
                            readSpreadsheet(files);
                        }}
                        name="message"
                        variant="outlined"
                        helperText={'Upload Spreadsheet File'}
                    />
                </Grid>

                <Grid className={classNames([classes.input, classes.inputMargin])}>
                    <SelectSearch
                        showLabelAlways={true}
                        label={'Select Timezone'}
                        selected={input.timezone ? input.timezone : ['-']}
                        onSelect={(data) => {
                            setInput({
                                ...input,
                                timezone: data,
                            });
                        }}
                        labelHeading=''
                        helperText='Select Timezone'
                        placeHolder='Select timezone for this event'
                        dataProvider={dataProvider.timezones}
                    />
                </Grid>

                <Grid className={classNames([classes.input, classes.inputMargin])}>
                    <Select
                        marginBottom={'0px'}
                        id='dateFormat'
                        name='Select Date Format'
                        value={input.dateFormat}
                        labelHeading= ''
                        placeHolder='Select Date Format'
                        helperText='Select Date Format'
                        handleChange={(e, data) => {
                            setInput({
                                ...input,
                                dateFormat: data.props.value,
                            });
                        }}

                        dataForDropdown={dataProvider.dateFormats}
                    />
                </Grid>

                <Grid className={classNames([classes.input])}>
                    <Select
                        marginBottom={'0px'}
                        id='dateFormat'
                        name='Ignore first row'
                        value={input.ignoreFirstRow}
                        labelHeading= ''
                        placeHolder='Ignore First Row'
                        helperText='Ignore First Row'
                        handleChange={(e, data) => {
                            setInput({
                                ...input,
                                ignoreFirstRow: data.props.value,
                            });
                        }}

                        dataForDropdown={ignoreRowData}
                    />
                </Grid>

            </Grid>
        </React.Fragment>

    );
};

ImportUploadCSV.propTypes = {
    dataProvider: PropTypes.any,
    input: PropTypes.any,
    setInput: PropTypes.func,
    readSpreadsheet: PropTypes.func,
};

ImportUploadCSV.defaultProps = {
    dataProvider: {},
    input: {},
    setInput: () => {},
    readSpreadsheet: () => {},
};

export default ImportUploadCSV;
