import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { columns } from './tableColumnsStandard';
import { errorsMap } from './errorMapping';

import {
    Grid,
    Typography,
    Tooltip,
   // Box,
} from '@material-ui/core';

export const validationIssue = {
    warnings: {
        '0': ['Event Name*', 'Start Date*', 'End Time*'],
    },

    errors: {
        '2': ['Start Time*'],
        '3': ['Location*', 'Start Date*', 'End Time*'],
    },
};

function hasIssue (source, index, column) {
    const issues = source[index];
    if (issues && issues.length > 0) {
        return (issues.includes(column));
    }
    return false;
}

function hasColor (hasError, hasWarning, classes) {
    if (hasError) {
        return classes.cellError;
    }

    if (hasWarning) {
        return classes.cellWarn;
    }

    return classes.cellNormal;
}

function dynamicCell ({ row, column, props, rowIndex }) {
    const {
        classes,
        warnings,
        errors,
    } = props;

    const hasWarning = hasIssue(warnings, rowIndex, column.id);
    const hasError = hasIssue(errors, rowIndex, column.id);
    const colorStyle = hasColor(hasError, hasWarning, classes);

    function getTipInfo () {
        const msg = errorsMap[column.id];
        if (hasError) {
            return msg || 'No error message yet.';
        }

        if (hasWarning) {
            return msg || 'No error message yet.';
        }

        return '';
    }

    function renderText () {
        if (column.id === 'allDay') {
            if (row[column.id]) {
                return 'Yes';
            }
            return 'No';
        }
        return row[column.id];
    }

    return (
        <Grid
            className={classes.defaultCell}
        >
            {(hasError || hasWarning) && (
                 <Tooltip title={getTipInfo()} arrow>
                    <Typography
                        className={classNames([classes.resultCellText, classes.cellPointer, colorStyle])}
                        variant={'body2'}
                    >
                        { renderText() }
                    </Typography>
                </Tooltip>
            )}

            {(!hasError && !hasWarning) && (
                    <Typography
                        className={classNames([classes.resultCellText, colorStyle])}
                        variant={'body2'}
                    >
                        { renderText() }
                    </Typography>
            )}
        </Grid>
    );
}

export function columnsFromHeaders (headers) {
    return headers.map(label => ({
        id: label,
        label: label,
        disablePadding: false,
        numeric: false,
        customCell: dynamicCell,
    }));
}

export function standardTableColumns () {
    return columns.map(item => ({
        ...item,
        disablePadding: false,
        numeric: false,
        customCell: dynamicCell,
    }));
}

export const customCells = {};

function rowCustomClass ({ props, rowIndex }) {
    const {
        classes,
        warnings,
        errors,
    } = props;

    if (!isEmpty(errors[rowIndex])) {
        return classes.rowError;
    }

    if (!isEmpty(warnings[rowIndex])) {
        return classes.rowWarn;
    }

    return undefined;
}

export const options = {
    titleIcon: null,
    rowCustomClass,
};
