import { useState, useEffect } from 'react';

import { isPremiumPlan } from '../../utils';
import { useUserStore } from '../../store/models';

function usePremiumPlan () {
    const [isPremium, setIsPremium] = useState(false);
    const userStore = useUserStore();

    useEffect(() => {
        if (userStore.publisher) {
            const isPremium = isPremiumPlan(userStore.publisher.planName);
            setIsPremium(isPremium);
        }
    }, [userStore.observed.publisher]);

    return {
        isPremium,
    };
}

export default usePremiumPlan;
