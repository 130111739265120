import { useEffect } from 'react';
import useErrors from '../../error/useErrors';
import { useErrorStore } from '../../error';
import { serviceErrorSerializer } from './serviceErrors';
import { inputValidator } from './inputErrors';

/*
 * This serve as a wrapper for useErrors hook to configure the result of useErrors hook.
 */
function useCustomLabelErrors (props) {
    const {
        input,
    } = props;

    const store = useErrorStore();

    const {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    } = useErrors({
        input,
        hook: 'useCustomLabelErrors',
        useTouched: true,
        inputValidator,
        serviceErrorSerializer,
        errorObservables: [
            store.observed.errors.getLabels,
            store.observed.errors.addLabel,
            store.observed.errors.editLabel,
            store.observed.errors.deleteLabel,
        ],
    });

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useCustomLabelErrors() Input is Updated...', input);
    }, [input]);

    useEffect(() => {
        console.log('');
        console.log('');
        console.log('');
        console.log('useCustomLabelErrors() Error Updates...', errors);
    }, [errors]);

    /*
     * This response will be consumed by the UI components...
     */
    return {
        errors,
        hasError,
        hasInputError,
        isGenericError,
    };
}

export default useCustomLabelErrors;
