
import React, { useState } from 'react';
import UserInputDialog from './UserInputDialog';

import { usePublishersStore } from '../../../store/models';
function useUserInputDialog (props = {}) {
    const {
        open: defaultOpen = false,
    } = props;

    const publishersStore = usePublishersStore();

    const [open, setOpen] = useState(defaultOpen);
    const [mode, setMode] = useState('add');
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        password: '',
        email: '',
    });

    function handleSave (data) {
        if (mode === 'add') {
            publishersStore.createPublisherUser({
                publisher: publishersStore.activePublisher,
                newUser: data,
            });
        } else {
            console.log('Handle Save and DO UPDATE....');
            // do the update here....
            publishersStore.updatePublisherUsers({
                publisher: publishersStore.activePublisher,
                publisherUser: data,
            });
        }
    }

    function renderUserInputDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <UserInputDialog
                open={open}

                userData={userData}
                mode={mode}
                onClose={() => {
                  setOpen(false);
                }}

                onSave={(data) => {
                    handleSave(data);
                    setOpen(false);
                }}

            />
        );
    }

    function openDialog (initialData = {}, newMode = 'add') {
        setMode(newMode);
        setUserData(initialData);
        setOpen(true);
    };

    return {
        renderUserInputDialog,
        openDialog,
    };
}

export default useUserInputDialog;
