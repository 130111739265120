import React, { useState } from 'react';
import Dialog from '../../commons/dialog/Dialog';

function useAlertDialog (props) {
    const {
        onClose = () => {},
        onConfirm = () => {},
        open: defaultOpen = false,
        title,
        message,
        confirmLabel = 'Ok',
        showSecondaryButton = true,
    } = props;

    const [open, setOpen] = useState(defaultOpen);

    function renderDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <Dialog
                openDialog={open}
                buttonSecondaryFlag={showSecondaryButton}
                buttonPrimary={confirmLabel}
                handleAgree={() => {
                    if (typeof onConfirm === 'function') {
                        onConfirm();
                        setOpen(false);
                    }
                }}

                closeModal={() => {
                    if (typeof onClose === 'function') {
                        onClose();
                        setOpen(false);
                    }
                }}
                title={title}
                dialogContextText={message}
            />
        );
    }

    function openDialog () {
        setOpen(true);
    };

    return {
        renderDialog,
        openDialog,
    };
}

export default useAlertDialog;
