import { Auth } from 'aws-amplify';
import { AuthErrorSource } from '../error/auth_middleware';
import { v4 as uuid } from 'uuid';

import {
    SignInSignUpState,
    AuthType,
    RecoveryState,
} from '../../../utils/constants';
import { ErrorListings } from '../error/error_map';

import {
    apiChangePassword,
} from './api';
import { getTokenPayload } from '../../api/tokenUtil';

import { getFeatures } from '../../../conf/feature';
import {
    getEnvSettings,
    loadSessionData,
    logInfo,
    storeSessionData,
    isFreeTrial,
    isFreePlan,
} from '../../../utils';

export async function login ({ username, password, setupForPlan = false }) {
    this.loginAttempt = true;
    this.env.clearStoredInfo();
    this.env.signInSignUpState = { status: SignInSignUpState.VERIFYING_CREDENTIALS };
    try {
        this.loginInfo = {
            username,
            password,
        };
        const user = await Auth.signIn(username, password);
        logInfo('Login Response: ', user);
        this.env.cognitoUser = user;

        if (user && user.signInUserSession && user.signInUserSession.accessToken) {
            logInfo('login() User: ', user);
            // logInfo('Getting Cognito Token: ', user.signInUserSession.accessToken.jwtToken);
            this.env.userCognitoToken = user.signInUserSession.idToken.jwtToken;
        }
        this.env.signIn();
        if (user.preferredMFA === 'NOMFA' || user.signInUserSession) {
            this.processAuthUser(user.signInUserSession, setupForPlan);
            return;
        }

        if (user.username) {
            if (user.challengeName === 'MFA_SETUP') {
                console.log('');
                console.log('');
                console.log('');
                console.log(' Verify OTP On Login mFA SETUP?', user);
                // will only happen during the setup else get the code directly and call confirm signin
                this.cognitoUsername = user.username;
                this.user = user;
                const code = await Auth.setupTOTP(user);

                this.qrCode = 'otpauth://totp/AWSCognito:' + user.username + '?secret=' + code;// + "&issuer=" + issuer;
                this.env.signInSignUpState = {
                    status: SignInSignUpState.OTP_CONFIRMATION,
                    authType: AuthType.VERIFY_OTP,
                };
            } else {
                console.log('');
                console.log('');
                console.log('');
                console.log(' Verify OTP Non Default OTP Confirmation', user);
                if (user.challengeName === SignInSignUpState.NEW_PASSWORD_REQUIRED) {
                    storeSessionData({
                        loginInfo: {
                            user: user,
                            username: username,
                        },
                    });
                    this.env.history.push('/changePassword');
                } else {
                    this.qrCode = null;
                    this.env.signInSignUpState = {
                        status: SignInSignUpState.OTP_CONFIRMATION,
                        authType: AuthType.CONFIRM_SIGN_IN,
                    };
                }
            }
        }
    } catch (error) {
        /* Handle Signin Error */
        logInfo('User.amplifyActions.error signing in', error);
        this.env.errorStore.handleAuthError({
            error: error,
            source: AuthErrorSource.COGNITO,
            action: 'logIn',
        });

        this.env.errorStore.errors = ErrorListings.loginFailed(error);
        this.env.errorStore.observed.errors.login = uuid();
    }
}

export async function verifyOTP ({ otp }) {
    let hasError = false;
    const user = await Auth.verifyTotpToken(this.env.cognitoUser, otp)
    .catch(err => {
        logInfo('verifyOTP error happened: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }
    console.log('verifyOTP()  Response: ', user);
    this.processAuthUser(user);
}

export async function resendForOTP () {
    this.env.signInSignUpState = {
        status: SignInSignUpState.OTP_RESENDING,
        authType: AuthType.CONFIRM_SIGN_IN,
    };
    let hasError = false;
    const user = await Auth.signIn(this.loginInfo.username, this.loginInfo.password)
    .catch(err => {
        logInfo('resendForOTP: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }

    this.env.cognitoUser = user;

    this.qrCode = null;
    this.env.signInSignUpState = {
        status: SignInSignUpState.OTP_CONFIRMATION,
        authType: AuthType.CONFIRM_SIGN_IN,
    };
}

export async function confirmSignIn ({ code }) {
    console.log('confirmSignIn user: ', this.env.cognitoUser);
    this.env.signInSignUpState = {
        status: SignInSignUpState.OTP_CONFIRMING,
    };
   // const user = await Auth.confirmSignIn(this.env.cognitoUser, code, 'SMS_MFA');

    Auth.confirmSignIn(this.env.cognitoUser, code, 'SMS_MFA').then(
        (user) => {
            console.log('confirmSignIn()  Response: ', user);
            this.env.signIn();
            this.processAuthUser(user.signInUserSession);
        },
        (err) => {
            console.log('error: ', err);
            this.env.signInSignUpState = {
                error: err,
                status: SignInSignUpState.OTP_RESEND,
                authType: AuthType.CONFIRM_SIGN_IN,
            };

            this.env.errorStore.handleAuthError({
                error: err,
                source: AuthErrorSource.COGNITO,
                action: 'verifyLoginOTP',
            });
        }
    );
}

export async function changePassword ({ oldPassword, newPassword }) {
    const res = await apiChangePassword({ oldPassword, newPassword })
    .catch(err => {
        logInfo('changePassword() error happened: ', err);
        this.env.errorStore.errors = ErrorListings.incorrectPasswordInfo();
        this.env.errorStore.observed.errors.changePassword = uuid();
    });

    if (res === 'SUCCESS') {
        this.observed.changePasswordOk = uuid();
    }
}

export async function signUp (params) {
    const {
        email,
        firstName,
        lastName,
        password,

        publisherName,
        industry,
        objective,
        phoneNumber,
        timezone,
    } = params;

    this.env.storeUserInfo({
        signUp: {
            email: email,
            password: password,
        },
    });

    Auth.signUp({
        email,
        username: email,
        password,
        attributes: {
            family_name: lastName,
            given_name: firstName,
            zoneinfo: timezone,
            phone_number: phoneNumber,
            'custom:industry': industry,
            'custom:objective': objective,
            'custom:company': publisherName,
            'custom:configured': '1', /* pass this to cognito because it is configured */
        },
    })
    .then((data) => {
        this.env.signIn();
        logInfo('SignUp Complete Email Verification Sent...', data);
        this.env.signInSignUpState = {
            status: SignInSignUpState.SIGN_UP_CONFIRMATION_PHASE,
        };
        this.env.history.push('/verifyAccount');
    })
    .catch((err) => {
        logInfo('UserStore.signUperror', err);
        const { hasError } = this.env.errorStore.handleAuthError({
            error: err,
            source: AuthErrorSource.COGNITO,
            action: 'signUp',
        });

        if (!hasError) {
            // error not handled
            this.env.errorStore.errors = ErrorListings.genericAuthError(err);
            this.env.errorStore.observed.errors.authError = uuid();
        }
    });
}

export async function confirmSignUp ({ code }) {
    const storeInfo = this.env.getStoredInfo();
    let email = storeInfo?.signUp?.email || this.env.settings?.tkPayload?.email;
    if (!email) {
        email = storeInfo.recovery?.email;
    }
    const password = storeInfo?.signUp?.password;
    logInfo('Confirming Account: ', email);
    logInfo('Verify Info: ', code);
    Auth.confirmSignUp(email, code)
    .then((data) => {
        console.log('AmplifyActions.confirmSignUp() Confirm Signup Success: ', data);
        this.env.clearStoredInfo();
        const features = getFeatures();
        const envSettings = getEnvSettings();
        let setupForPlan = false;
        if (features?.billing_v4?.active &&
            envSettings &&
            envSettings?.plan
            ) {
            const plan = envSettings.plan;
            if (!isFreeTrial(plan) && !isFreePlan(plan)) {
                setupForPlan = true;
            }
        }

        // instead of auto signin redirect to login
        if (password) {
            this.login({ username: email, password: password, setupForPlan });
        } else {
            this.env.errorStore.errors = ErrorListings.userAlreadyConfirmed();
            this.env.errorStore.observed.errors.confirmSignup = uuid();
        }
    }).catch((err) => {
        logInfo('');
        logInfo('');
        logInfo('Confirmation Error: ', err);
        if (err.message === ErrorListings.message.USER_ALREADY_CONFIRMED) {
            logInfo('will render error for user already confirmed');
            this.env.errorStore.errors = ErrorListings.userAlreadyConfirmed();
            this.env.errorStore.observed.errors.confirmSignup = uuid();
            return;
        }

        this.env.errorStore.handleAuthError({
            error: err,
            source: AuthErrorSource.COGNITO,
            action: 'signUp',
        });
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
    });
}

export async function federatedSignIn (params) {
    const {
        token,
        user,
        domain,
        expiresIn,
    } = params;

    try {
        const response = await Auth.federatedSignIn(
            domain,
            {
                token,
                expires_at: expiresIn * 1000 + new Date().getTime(), // the expiration timestamp
            },
            user
        );
        console.log('Federated Signin Complete: Response:  ', response);
        const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        console.log('Auth User: ', authUser);
    } catch (err) {
        this.env.errorStore.handleAuthError({
            error: err,
            source: AuthErrorSource.COGNITO,
            action: 'federatedSignIn',
        });
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
    }
}

export async function signOut () {
    this.env.signOut();
    function navigateOut () {
        const url = window.location.href;
        if (url.indexOf('login') === -1 && url.indexOf('signup') === -1) {
             window.location = '/login';
        }
    }

    try {
        await Auth.currentAuthenticatedUser();
        await Auth.signOut();
        navigateOut();
    } catch (err) {
        logInfo('AWS Signout Error ', err);
        navigateOut();
    }
}

export async function completeUserSetup () {
    let hasError = false;
    const user = await Auth.currentAuthenticatedUser()
    .catch(err => {
        logInfo('completeUserSetup: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }

    const res = await Auth.updateUserAttributes(user, {
      'custom:configured': '1',
    })
    .catch(err => {
        logInfo(' completeUserSetup  update user attr: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }

    if (res === 'SUCCESS') {
        this.configured = true;
        logInfo('completeUserSetup Success: ', user);
        this.env.signInSignUpState = {
            status: SignInSignUpState.FEDERATED_SIGNIN_SETUP_COMPLETE,
        };
        if (user && user.signInUserSession && user.signInUserSession.idToken) {
            this.env.userCognitoToken = user.signInUserSession.idToken.jwtToken;
        }
        logInfo('user.amplifyAction().completeUserSetup() -> invoking authGet-PublisherInfo()');
        this.env.signIn();
        this.authGetPublisherInfo({ cognitoToken: this.env.userCognitoToken });
    }
}

export async function forgotPassword ({ email }) {
    const storeInfo = this.env.getStoredInfo();
    storeInfo.recovery = {
        email,
    };
    this.env.storeUserInfo(storeInfo);

    // this.env.errorStore.errors = ErrorListings.forgotPasswordUserNotFound();
    // this.env.errorStore.observed.errors.forgotPassword = uuid();
    Auth.forgotPassword(email)
    .then(() => {
        logInfo('a verification code is sent');

        this.env.recoveryState = {
            status: RecoveryState.RECOVERY_CODE_SENT,
        };
    }).catch((err) => {
        logInfo('failed with error', err);
        if (err.message === ErrorListings.message.CHANGE_PASSWORD_ATTEMPT_EXCEEDED) {
            logInfo('will render error for attempt limit exceeded');
            this.env.errorStore.errors = ErrorListings.changePassLimitExceeded();
            this.env.errorStore.observed.errors.forgotPassword = uuid();
            return;
        }

        this.env.errorStore.errors = ErrorListings.forgotPasswordUserNotFound(err);
        this.env.errorStore.observed.errors.forgotPassword = uuid();
    });
}

export async function recoverPassword ({ code, password }) {
    const storeInfo = this.env.getStoredInfo();
    const email = storeInfo.recovery.email;
    logInfo('Recovering...', email);
    Auth.forgotPasswordSubmit(email, code, password)
    .then(data => {
        console.log('Password Recovered: ');
        this.login({ username: email, password });
    })
    .catch(err => {
        logInfo(' Password Recovery Errr :', err);
        this.env.errorStore.handleAuthError({
            error: err,
            source: AuthErrorSource.COGNITO,
            action: 'recoverPassword',
        });
    });
}

function forVerification (env) {
    if (env.settings.forVerification) {
        if (!env.settings.tk) {
            return false;
        }
        const payload = getTokenPayload(env.settings.tk);
        if (!payload) {
            return false;
        }
        const p = payload.email.split('::');
        env.settings.tkPayload = {
            ...payload,
            email: p[0],
            pass: p[1],
        };
        return true;
    }
    return false;
}

export async function currentUser () {
    if (this.verifySessionExpired()) return;
    this.env.signIn();
    /*
    console.log('Will Authenticate User with Bypass.....');
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    console.log('Verifying auth User: ', user);
    */
    let session;
    try {
       session = await Auth.currentSession();
    } catch (err) {
        if (forVerification(this.env)) {
            console.log('Verification Settings:', this.env.settings);
            // return because user may need to verify...
            return;
        }
        // when this happen throw the token error...
        // this.env.history.push('/login');
        logInfo('currentUser() will dispatch SessionError from current User because auth errors');
        this.env.errorStore.dispatchSessionError();
    }

    let hasError = false;

    if (session && session.idToken) {
        const authUser = await Auth.currentAuthenticatedUser()
        .catch(err => {
            logInfo('currentUser() will dispatch SessionError from current User because of err', err);
            this.env.errorStore.dispatchSessionError();
            /*
            this.env.errorStore.errors = ErrorListings.genericAuthError(err);
            this.env.errorStore.observed.errors.authError = uuid();
            */
            hasError = true;
            console.log('Has Error()....');
        });
        logInfo('amplifyActions.currentUser() AuthUser -> ', authUser);
        if (hasError) {
            return;
        }

        const res = this.manageCognitoPayload(session.idToken.payload);
        if (res && res.hasError) {
            return;
        }

        // this.configured = false;
        if (this.configured) {
            this.env.signInSignUpState = {
                status: SignInSignUpState.IN_SESSION,
            };
        } else {
            this.env.signInSignUpState = {
                status: SignInSignUpState.SETUP_ACCOUNT,
            };
        }

        this.env.showProgress();
        this.env.userCognitoToken = session.idToken.jwtToken;
        await this.authGetPublisherInfo({ cognitoToken: session.idToken.jwtToken });
    }
}

export async function resendSignupCode () {
    const storeInfo = this.env.getStoredInfo();
    let email = storeInfo?.signUp?.email || this.env.settings?.tkPayload?.email;

    if (!email) {
        email = storeInfo.recovery?.email;
    }
    try {
        await Auth.resendSignUp(email);
        logInfo('code resent successfully');
        this.env.errorStore.errors = ErrorListings.resendCode(null);
        this.env.errorStore.observed.errors.resendSignupCode = uuid();
    } catch (err) {
        console.log('error resending code: ', err);

        this.env.errorStore.errors = ErrorListings.resendCode(err);
        this.env.errorStore.observed.errors.resendSignupCode = uuid();
    }
}

export async function updateUserAttrForProfile ({ userInfo }) {
    /*
       firstName: payload.given_name,
        lastName: payload.family_name,
    */
    let hasError = false;
    const user = await Auth.currentAuthenticatedUser()
    .catch(err => {
        logInfo('completeUserSetup: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }

    const res = await Auth.updateUserAttributes(user, {
        given_name: userInfo.firstName,
        family_name: userInfo.lastName,
    }).catch(err => {
        logInfo(' completeUserSetup  update user attr: ', err);
        this.env.errorStore.errors = ErrorListings.genericAuthError(err);
        this.env.errorStore.observed.errors.authError = uuid();
        hasError = true;
    });

    if (hasError) {
        return;
    }

    const updatedUser = {
        ...this.user,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
    };

    this.user = updatedUser;
    this.env.storeUserInfo({
        user: this.user,
    });
    this.observed.user = uuid();

    logInfo('updateUserAttrForProfile(): ', res);
}

export async function requiredChangePasssword ({ oldPassword, newPassword }) {
    const session = loadSessionData();

    try {
        logInfo('AmplifyAction.requiredChangePassword() session ', session);
        const user = session.loginInfo.user;
        const username = session.loginInfo.username;
        logInfo('AmplifyAction.requiredChangePassword() session ', session);
        const authUser = await updatePasswordAPI({
            user,
            oldPassword,
            newPassword,
            username,
        });

        logInfo('AmplifyAction.requiredChangePassword() Passworod Change Success ', authUser);
        this.processAuthUser(authUser.signInUserSession, false);
    } catch (err) {
        logInfo('AmplifyAction.requiredChangePassword() error -> ', err);
        this.env.errorStore.errors = ErrorListings.incorrectPasswordInfo();
        this.env.errorStore.observed.errors.changePassword = uuid();
    }
}

function updatePasswordAPI ({ username, oldPassword, newPassword }) {
    function execRequest (resolve, reject) {
        Auth.signIn(username, oldPassword)
        .then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // const { requiredAttributes } = user.challengeParam;
                logInfo('AmplifyActions.updatePasswordAPI() will try to update password: ', user);
                Auth.completeNewPassword(user, newPassword)
                .then(user => {
                    // at this time the user is logged in if no MFA required
                    logInfo('AmplifyActions.updatePasswordAPI() Succesfully updated the password: ', user);
                    resolve(user);
                }).catch(e => {
                    logInfo('AmplifyActions.updatePasswordAPI() Failed to update the password: ', user);
                    reject(e);
                    console.log(e);
                });
            }
        })
        .catch(err => {
            logInfo('AmplifyActions.updatePasswordApi Signin Failed() Reject.. Error -> ', err);
            reject(err);
        });
    }

    return new Promise(execRequest);
}

export async function createCognitoSession () {
    let session;
    try {
        session = await Auth.currentSession();
     } catch (err) {
        logInfo('AmplifyAction.createCognitoSession() error -> ', err);
        return;
     }

     logInfo('AmplifyAction.createCognitoSession() session acquired -> ', session);
     if (session && session.idToken) {
         const authUser = await Auth.currentAuthenticatedUser();
         logInfo('AmplifyAction.createCognitoSession() Auth error -> ', authUser);
    }
}
