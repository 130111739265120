import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    // Tooltip,
    Switch,
} from '@material-ui/core';

function SwitchCell (props) {
    const {
        row,
        tableProps,
    } = props;

    const {
        classes,
        onTableStateChange,
    } = tableProps;

    return (
        <React.Fragment>
            { row.visibleType.toLowerCase() !== 'always' && (
                <Grid className={classes.cellBase}>
                    <Switch
                        ecal-data-id={ 'toggle-button-' + row.field}
                        checked={row.visibleValue}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}

                        onChange={(e) => {
                            onTableStateChange({
                                field: row.field,
                                column: 'visible',
                                newValue: !row.visibleValue,
                                row: row,
                            });
                        }}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            )}

            { row.visibleType.toLowerCase() === 'always' && (
                <Grid>
                    Always
                </Grid>
            )}
        </React.Fragment>
    );
}

SwitchCell.propTypes = {
    tableProps: PropTypes.any,
    row: PropTypes.any,
    column: PropTypes.any,
    index: PropTypes.number,
};

SwitchCell.defaultProps = {
    onDataChange: () => {},
    row: {},
    index: 0,
    column: '',
};

export default SwitchCell;
