import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getLabels,
    addLabel,
    editLabel,
    deleteLabel,
    organizeLabels,
} from './actions';

import {
    loadLabelForEdit,
    saveLabelForEdit,
} from './actions_persist';

export default class LabelsStore {
    version = {}
    env = null;
    labels = null;
    labelForEdit = null;

    observed = {
        labels: null,
        labelsLoading: false,
    };

    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    getLabels = getLabels;
    addLabel = addLabel;
    editLabel = editLabel;
    deleteLabel = deleteLabel;
    organizeLabels = organizeLabels;

    /* Data Persist */
    loadLabelForEdit = loadLabelForEdit;
    saveLabelForEdit = saveLabelForEdit;
}

export function useLabelsStore () {
    const { labelsStore } = useStores();
    return labelsStore;
}
