import React from 'react';

import {
    Box,
} from '@material-ui/core';

import {
    Label as LabelIcon,
} from '@material-ui/icons';

import {
    statusCell,
    nameCell,
    companyCell,
    emailCell,
    planNameCell,
    actionsCell,
    pubIdCell,
} from './customCells';

export const columns = [

    {
        id: 'pubId',
        numeric: true,
        disablePadding: false,
        label: 'Publisher ID',
    },

    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'company',
        numeric: true,
        disablePadding: false,
        label: 'Company',
    },

    {
        id: 'planName',
        numeric: true,
        disablePadding: false,
        label: 'Plan',
    },

    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
        cellClassName: 'emailCell',
    },

    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: '',
    },
];

export const customCells = {
    pubId: pubIdCell,
    status: statusCell,
    name: nameCell,
    company: companyCell,
    planName: planNameCell,
    email: emailCell,
    actions: actionsCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
