import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '../../components/Wrappers';
import useStyles from './table.styles';

function TableHeadings (props) {
    const {

        order,
        orderBy,

        onRequestSort,
        headCells,
        hasCheck,

        onSelectAllClick,
        numSelected,
        rowCount,
        hasSort,
    } = props;

    const classes = useStyles();
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    function renderCell (headCell) {
        if (!hasSort) {
            return (
                <Typography
                    noWrap
                    weight={'medium'}
                    variant={'body2'}
                    >
                    {headCell.label}
                </Typography>
            );
        }

        return (
            <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
            >
                <Typography
                    noWrap
                    weight={'medium'}
                    variant={'body2'}
                >
                    {headCell.label}
                </Typography>
            </TableSortLabel>
        );
    }

    return (
        <TableHead>
            <TableRow>
                { hasCheck && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={ numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                )}
                {headCells.map(headCell => (
                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {renderCell(headCell)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
TableHeadings.propTypes = {
    hasCheck: PropTypes.bool,
    hasSort: PropTypes.bool,
    onSelectAllClick: PropTypes.bool,
    rowCount: PropTypes.number,
    numSelected: PropTypes.number,
};

TableHeadings.defaultProps = {
    hasCheck: false,
    hasSort: true,
    onSelectAllClick: () => {},
    rowCount: 0,
    numSelected: 0,
};

export default TableHeadings;
