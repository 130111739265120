import React from 'react';
import PropTypes from 'prop-types';
// import { v4 as uuid } from 'uuid';
import AddOns from './addOns';
import {
    Grid, Typography,
} from '@material-ui/core';
import { useStyles } from './styles';

function BillingAddOns (props) {
    const {
        input,
        updateInput,
        addOns,
        updateAddOnsValue,
        addOnsValues,
        billingInputProps,
        addOnsDisabled,
    } = props;
    const classes = useStyles();

    function renderAddOns () {
        function getType (data) {
            if (data.type === 'quantity') {
                if (data.handle &&
                    (data.handle.indexOf('binary') !== -1 ||
                        data.handle.indexOf('toggle') !== -1
                    )) {
                    return 'onOff';
                }
                return data.type;
            }
            return data.type;
        }

        return addOns.map((data, index) => {
            return (
                <AddOns
                    key={'addon-' + index}
                    type={getType(data)}
                    classes={classes}
                    input={input}
                    updateInput={updateInput}
                    updateAddOnsValue={updateAddOnsValue}
                    addOnsValues={addOnsValues}
                    addOnsDisabled={addOnsDisabled}
                    data={data}
                    billingInputProps={billingInputProps}
                />
            );
        });
    }

    return (
        <React.Fragment>
            <Typography
                variant={'body2'}
                className={classes.planAddOnsTitle}
            >
                Add your extras!
            </Typography>

            <Grid container className={classes.planAddOnsContainer} >
                { renderAddOns() }
            </Grid>
        </React.Fragment>
    );
}

BillingAddOns.propTypes = {
    input: PropTypes.object,
    addOns: PropTypes.array,
    addOnsValues: PropTypes.object,
    addOnsDisabled: PropTypes.object,
    updateInput: PropTypes.func,
    updateAddOnsValue: PropTypes.func,
    billingInputProps: PropTypes.any,
};

BillingAddOns.defaultProps = {
    input: {},
    addOns: [],
    updateInput: () => {},
    updateAddOnsValue: () => {},
    addOnsValues: {},
    addOnsDisabled: {},
    billingInputProps: {},
};

export default BillingAddOns;
