import React, { useState, useEffect } from 'react';
// import { Grid } from '@material-ui/core';
// import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { Options } from './graphOptions';
import { getCountsWithComma } from '../../../../commons/util/utilities';
import SkeletonLoader from '../../../../components/SkeletonLoader';

// components
// import Widget from '../../../components/Widget/Widget';

// const values = {
//   series: [
//     {
//       data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
//     },
//   ],
// };

export default function Charts (props) {
  const [values, setValues] = useState([]);
  const [options, setOptions] = useState(Options);

  const subscriptions = props.data;

  const getDates = (data) => data.map(({ date }) => date);
  const getValues = (data) => data.map(({ count }) => count);

  useEffect(() => {
    if (subscriptions) {
      const newSubscriptions = JSON.stringify(subscriptions);
      const formattedData = getCountsWithComma(JSON.parse(newSubscriptions));
      setOptions({
        ...options,
        xaxis: {
          categories: getDates(subscriptions),
          type: 'datetime',
          // min: subscriptions[0].date,
          // max: Date.now(),
          // labels: {
          //   // format: 'yyyy',
          //   datetimeFormatter: {
          //     year: 'yyyy',
          //     month: 'MMM \'yy',
          //     // day: 'dd MMM',
          //     // hour: 'HH:mm',
          //   },
          // },
        },
        tooltip: { y: { formatter: (val, series) => formattedData[series.dataPointIndex].count } },
      });
      setValues([{ data: getValues(subscriptions) }]);
    }
  }, [subscriptions]);

  return (
    <>
      {props.isLoading && !subscriptions?.length
? (
        <SkeletonLoader />
      )
 : (
        <ReactApexChart
          options={options}
          series={values}
          type='bar'
          height='350'
        />
      )}
    </>
  );
}
