import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './iconVibrantBlueSVG.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */

const DEFAULT_ICON_SIZE = 53;

const IconVibrantSVG = () => {
        const classes = useStyles();

        return (
                <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
                        viewBox='0 0 47.8 48' style={{ 'enable-background': 'new 0 0 47.8 48' }} xmlSpace='preserve'>
                        <g>
                                <circle className={classes.st0} cx='23.9' cy='24.1' r='23.4' />
                                <g>
                                        <path className={classes.st1} d='M23.9,1.2c12.6,0,22.9,10.3,22.9,22.9S36.5,47,23.9,47S1,36.7,1,24.1S11.3,1.2,23.9,1.2 M23.9,0.2
			C10.7,0.2,0,10.9,0,24.1S10.7,48,23.9,48s23.9-10.7,23.9-23.9S37.1,0.2,23.9,0.2L23.9,0.2z'/>
                                </g>
                        </g>
                        <g>
                                <path className={classes.st2} d='M22.8,17.4h-6.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h6.5
		c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.4
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C23.1,17.4,22.9,17.4,22.8,17.4z'/>
                                <path className={classes.st3} d='M34.9,14.5H18.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.4s0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h16.6
		c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6C35.4,14.6,35.2,14.5,34.9,14.5z'/>
                                <path className={classes.st4} d='M31.4,31.7H18.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h13.1
		c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C31.9,31.8,31.7,31.7,31.4,31.7z'/>
                                <path className={classes.st5} d='M22.6,25.9H16c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6
		c0.2,0.2,0.4,0.3,0.6,0.3h6.6c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4
		c0-0.1,0-0.2-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3C23.2,26.1,23.1,26,23,26C22.8,25.9,22.7,25.9,22.6,25.9z'/>
                                <path className={classes.st6} d='M24.3,20.2H11.7c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
		h12.6c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7S24.6,20.2,24.3,20.2z'/>
                                <path className={classes.st7} d='M28.7,23.1H18.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		c-0.1,0.1-0.1,0.2-0.1,0.4s0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h10.4c0.1,0,0.3,0,0.4-0.1
		c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4s0-0.2-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.2C29,23.1,28.9,23.1,28.7,23.1z'/>
                                <path className={classes.st8} d='M20.4,28.8H9.8c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3
		h10.6c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6C20.8,28.9,20.6,28.8,20.4,28.8z'/>
                        </g>
                </svg>
        );
};

IconVibrantSVG.propTypes = {
        width: PropTypes.number,
        iconSize: PropTypes.number,
};

IconVibrantSVG.defaultProps = {
        width: 20,
        iconSize: DEFAULT_ICON_SIZE,
};

export default IconVibrantSVG;
