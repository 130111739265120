import React from 'react';

import {
    Box,
} from '@material-ui/core';

import { Typography } from '../../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';
import ToggleOn from '@material-ui/icons/ToggleOn';
import ToggleOff from '@material-ui/icons/ToggleOff';

import {
    CreateOutlined as CreateIcon,
} from '@material-ui/icons';

/*
 * Note:
 * id field in the column matches ff:
 *      the row field or data field from api response
 *      customCell renderers
 */

export const columns = [
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

/* Custom Cells */
function nameCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
        <Typography
            className={classes.nameCell}
            variant={'body2'}
        >
            {row.firstName + ' ' + row.lastName}
        </Typography>
    );
}

function emailCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.text}
                variant={'body2'}
            >
                {row.email}
            </Typography>
    );
}

function actionCell ({ index, row, column, props }) {
    const {
        onEdit,
        onDelete,
        classes,
    } = props;

    function renderIcon () {
        if (row.status === 'active') {
            return (
                <Typography
                    className={classes.deleteIcon}
                    color="success">
                    <ToggleOn />
                </Typography>
            );
        }
        return (<Typography
                color="secondary"
                className={classes.deleteIcon}
                >
            <ToggleOff />
        </Typography>);
    }

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
            <IconButton
                color={'primary'}
                onClick= { (e) => {
                    e.preventDefault();
                    onEdit(row);
                }}
                ecal-label-button={'edit'}
                className={classes.actionCellIcon}
            >
                <CreateIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    onDelete(row);
                }}
                ecal-label-button={'delete'}
                className={classes.actionCellIcon}
            >
               { renderIcon() }
            </IconButton>
        </Box>
    );
}

export const customCells = {
    name: nameCell,
    eamil: emailCell,
    actions: actionCell,
};
