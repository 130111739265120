import { useState, useEffect, useRef } from 'react';

const worksheets = [
    {
        label: 'Template 1: General',
        value: 'https://d27vx6ekipa712.cloudfront.net/assets/guide/Import%20Worksheets/Worksheet_Template_General.xlsx',
    },
    {
        label: 'Template 2: Sports',
        value: 'https://d27vx6ekipa712.cloudfront.net/assets/guide/Import%20Worksheets/Worksheet_Template_Sports.xlsx',
    },

    {
        label: 'Template 3: Ticketing',
        value: 'https://d27vx6ekipa712.cloudfront.net/assets/guide/Import%20Worksheets/Worksheet_Template_Ticketing.xlsx',
    },

    {
        label: 'Template 4: Payments',
        value: 'https://d27vx6ekipa712.cloudfront.net/assets/guide/Import%20Worksheets/Worksheet_Template_Payments.xlsx',
    },

    {
        label: 'Template 5: Conferences & Events',
        value: 'https://d27vx6ekipa712.cloudfront.net/assets/guide/Import%20Worksheets/Worksheet_Template_Conferences_and_Events.xlsx',
    },
];

function useWorksheetDownload () {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menuWidth, setMenuWidth] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dataProvider] = useState(worksheets);
    const baseRef = useRef();

    useEffect(() => {
        setMenuAnchor(baseRef.current);
        setMenuWidth(baseRef.current.clientWidth);
    }, [baseRef]);

    useEffect(() => {
        console.log('menu open updated: ', menuOpen);
    }, [menuOpen]);

    function handleSelect (data) {
        const file = document.createElement('a');
        file.setAttribute('href', data.value);
        file.setAttribute('download', true);
        file.setAttribute('style', 'display:hidden; ');
        document.body.appendChild(file);
        file.click();
        document.body.removeChild(file);
    }

    return {
        baseRef,
        handleSelect,

        menuOpen,
        menuAnchor,
        menuWidth,

        setMenuOpen,
        setMenuAnchor,
        setMenuWidth,
        dataProvider,
    };
}

export default useWorksheetDownload;
