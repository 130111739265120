import {
  apiSaveWebhooks,
    apiGetWebhooks,
    apiDeleteWebhook,
    apiAddWebhook,
} from './api';
import { logInfo } from '../../../utils';

import {
    handleWebhookError,
} from '../error/webhook_middleware';

const APIErrorKeys = {
    GET_WEBHOOK: 'getWebhook',
    ADD_WEBHOOK: 'addWebhook',
    SAVE_WEBHOOK: 'saveWebhook',
    DELETE_WEBHOOK: 'deleteWebhook',
};

export async function getWebhooks () {
    this.env.errorStore.action = 'getWebhooks';
    const publisher = this.env.userStore.publisher;
    logInfo('getWebhooks():', publisher);
    this.skeletonFlag = true;

    const res = await apiGetWebhooks({ token: publisher.token, traceId: this.env.traceId });

    const { hasError } = handleWebhookError(res, APIErrorKeys.GET_WEBHOOK);

    if (hasError) {
        return;
    }
    if (res.data && res.data.data) {
        this.skeletonFlag = false;
        const data = res.data.data;
        this.webhooks = data;
    } else {
        console.log('response.error', res.data);
        this.skeletonFlag = false;
    }

    if (res.response && res.response.status === 401) {
        this.skeletonFlag = false;
    }
}
export async function saveWebhooks ({ webhookId, body }) {
    this.env.errorStore.action = 'saveWebhooks';
    const publisher = this.env.userStore.publisher;
    const res = await apiSaveWebhooks({ body, token: publisher.token, traceId: this.env.traceId, webhookId: webhookId });
    this.skeletonFlag = true;

    if (res.data && res.data.data) {
        const data = res.data.data;
        this.getWebhooks({ publisher: { token: publisher.token } });

        logInfo('Webhook saved', data);
        return data[0].id;
    } else {
        this.skeletonFlag = false;
    }

    if (res.response && res.response.status === 401) {
        this.skeletonFlag = false;
    }
}

export async function addWebhook ({ body }) {
    this.env.errorStore.action = 'addWebhook';
    const publisher = this.env.userStore.publisher;
    this.skeletonFlag = true;
    try {
        const res = await apiAddWebhook({ body, token: publisher.token, traceId: this.env.traceId });
        logInfo('Web Hook Added ? ', res);

    if (res.data && res.data.data) {
        const data = res.data.data;
        this.getWebhooks({ publisher: { token: publisher.token } });
        logInfo('webhook added', data);
        return data[0].id;
    } else {
        logInfo('else', res);
        this.skeletonFlag = false;
    }

    if (res.response && res.response.status === 401) {
        this.skeletonFlag = false;
    }
  } catch (err) {
        logInfo('catch err? ', err);
  }
}

export async function deleteWebhook ({ webhookId }) {
    const publisher = this.env.userStore.publisher;

    this.skeletonFlag = true;
    const res = await apiDeleteWebhook({ token: publisher.token, traceId: this.env.traceId, webhookId: webhookId });

    this.skeletonFlag = false;

    if (res.data && res.data.data) {
        const data = res.data.data;
        console.log('webhook deleted', data);
        this.getWebhooks({ publisher: { token: publisher.token } });
        this.skeletonFlag = false;
    } else {
        this.skeletonFlag = false;
    }

    if (res.response && res.response.status === 401) {
        this.skeletonFlag = false;
    }
}
