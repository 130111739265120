import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';

import {
   GetApp as Download,
} from '@material-ui/icons';

import PopMenu from './menu';

import useWorksheetDownload from './useWorksheetDownload';

function WorksheetDownload (props) {
    const {
        baseRef,
        menuAnchor,
        menuOpen,
        menuWidth,
        setMenuOpen,
        dataProvider,
        handleSelect,
    } = useWorksheetDownload();

    function downloadIcon () {
        return <Download
            ecal-data-id={'download-worksheet-template'}
            className={classes.expandIcon}
            fontSize={'small'}
            onClick={() => {
                console.log('hide menu');
                setMenuOpen(true);
            }}
        />;
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid className={classes.componentBase}>
              <Grid
                className={classes.root}
                ref={baseRef}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
              >
                <div className={classes.base}>Select a worksheet template</div>
                {downloadIcon()}
              </Grid>
            </Grid>
                <PopMenu
                    anchorEl={menuAnchor}
                    open={menuOpen}
                    width={menuWidth}
                    dataProvider={dataProvider}
                    onClose={() => setMenuOpen(false)}
                    onSelect={(data) => {
                        handleSelect(data);
                        setMenuOpen(false);
                    }}

                />
        </React.Fragment>

    );
}

WorksheetDownload.propTypes = {
    showLabelAlways: PropTypes.bool,
};

WorksheetDownload.defaultProps = {
    showLabelAlways: false,
};

export default WorksheetDownload;
