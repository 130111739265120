import React, { useState, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import Dialog from './Dialog';

export default function PageExitDialog ({ content, title, isBlocked }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigation = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, content, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <Dialog
          openDialog={isModalOpen}
          handleAgree={() => {
              handleConfirmNavigation();
          }}

          closeModal={() => {
            closeModal();
          }}
          title={title}
          dialogContextText={content}
          buttonPrimary= 'Ok'
          buttonSecondary= 'Cancel'
        />
      )}
    </>
  );
}
