import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Grid,
  // InputBase,
} from '@material-ui/core';

import {
  Group as SubsIcon,
  // StarsIcon as EnterpriseIcon,
  // StarIcon as GrowProIcon,
  // StarOutlineIcon as FreePlanIcon,
  // StarRate as PlanIcon,
  // Person as PersonIcon,
} from '@material-ui/icons';

import StarsIcon from '@material-ui/icons/Stars';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import useStyles from './styles';

// components
import { Typography, Avatar } from '../Wrappers/Wrappers';
import EcalMobileLogoImage from '../../commons/images/brandImage/MobileEcalLogo.png';
// import EcalLogoImage from '../../commons/images/brandImage/ECAL_logo_header.png';
import EcalProfileLogo from '../../commons/images/brandImage/ecal_icon_purple_light.svg';
// import StarIcon from '../../commons/images/icons/starticon.svg';

import ProfileMenu from './ProfileMenu';
import NotficationsMenu from './NotificationsMenu';
import MessageMenu from './MessageMenu';
import BackMenuButton from './BackMenuButton';
import { messages, notifications } from './mock';
import { useDashboardStore } from '../../store/models';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

function Header (props) {
  const {
    // greetings,
    userInfo,
    onSignOut,
    publisher,
    publisherLogo,
    subsRemaining,
    role,
  } = props;
  const {
    whiteLabelSettings,
  } = useWhiteLabel();
  const dashboardStore = useDashboardStore();
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#0c0644');
  const classes = useStyles({ headerBackgroundColor });
  // local
  const [mailMenu, setMailMenu] = useState(null);
  // const [isMailsUnread, setIsMailsUnread] = useState(true);
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  // const [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  // const [isSearchOpen, setSearchOpen] = useState(false);
  const loadDashboardData = async () => {
    await dashboardStore.getTotalSubscribers();
  };
  useEffect(() => {
    loadDashboardData();
  }, []);

  useEffect(function () {
    if (whiteLabelSettings.labelName === 'ecal') {
      if (publisher.planName === 'Enterprise') {
        setHeaderBackgroundColor('#6F2FCD');
      } else {
        setHeaderBackgroundColor('#0c0644');
      }
    } else {
      setHeaderBackgroundColor(whiteLabelSettings.headerColor);
    }
  }, [publisher.planName]);

  function company () {
    if (!publisher) {
      return '';
    }

    if (publisher.company) {
      return publisher.company;
    }

    if (publisher.orgName) {
      return publisher.orgName;
    }

    if (publisher.name) {
      return publisher.name;
    }

    return publisher.firstName + ' ' + publisher.lastName;
  }
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <BackMenuButton />
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'>
          <Box display='flex'>
            <Box
              component={Grid}
              className={classes.mobileLogoContainer}
              item
              // sm={12}
              sx={{ display: { xs: 'block', sm: 'none' } }}
            >
              <img src={EcalMobileLogoImage} alt="logo" className={classes.mobileLogotypeImage} />
            </Box>
            <Box
              component={Grid}
              className={classes.logoContainer}
              item
              // sm={12}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <img src={whiteLabelSettings.logo} alt="logo" className={classes.logotypeImage} />
            </Box>
            <div className={classes.orgNameContainer}>
            <Typography variant="h6" weight="medium" className= {classNames([
                          classes.profileLabel,
                          classes.orgName,
                      ])}
            >
              { company() }
            </Typography>
          </div>
          </Box>
          {/* <div className={classes.grow} /> */}
          <Box display='flex' flexDirection='row'>
          <Box
            display='flex'
            flexDirection='column'
            marginBottom='-18px'
            justifyContent='center'>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='end'>
              <Box
              display='flex'
              width='24px'
              height='24px'justifyContent='center'>
                {/* <img src={StarIcon} /> */}
                { publisher.planName === 'Enterprise' ? <StarsIcon fontSize='medium' /> : null}
                { publisher.planName === 'Expert' || publisher.planName === 'Pro' || publisher.planName === 'Professional' || publisher.planName === 'Grow' ? <StarIcon fontSize='medium' /> : null}
                { publisher.planName !== 'Enterprise' && publisher.planName !== 'Pro' && publisher.planName !== 'Professional' && publisher.planName !== 'Grow' && publisher.planName !== 'Expert' ? <StarOutlineIcon fontSize='medium' /> : null}
              </Box>
            </Box>
            <Typography variant={'caption'} className={classes.planSubText} >
              {publisher ? publisher.planName : ''}
            </Typography>
          </Box>
          <div className={classes.lineDividerHeader}></div>
          <Box
            display='flex'
            flexDirection='column'
            marginBottom='-18px'
            width='115'
            justifyContent='center'>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='end'>
              <SubsIcon fontSize="medium" />&nbsp;
              <Typography variant={'body2'} className={classes.subsRemText}>
                {subsRemaining === 'infinity' ? <span>&#8734;</span> : subsRemaining}
            </Typography>
            </Box>
            <Typography variant={'caption'} className={classes.planSubText} >
              Subscriptions Rem.
            </Typography>
          </Box>
          <div className={classes.lineDividerHeader}></div>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={e => setProfileMenu(e.currentTarget)}
          >
            <Avatar
              alt=""
              /* eslint-disable-next-line no-mixed-operators */
              // src={profile}
              variant="square"
              className={classes.avatarImage}
              classes={{ root: classes.headerIcon }}>
                {/* <PersonIcon /> */}
                {/* <Box className={classes.logoContainer}> */}
                <img height={24} width={24} src={publisherLogo} alt="logo" className={classes.logotypeImage} />
          {/* </Box> */}
            </Avatar>
          </IconButton>

          <div className={classes.userNameContainer}
        >
          <Typography variant={'body2'} className= {classNames([
                        classes.profileLabel,
                        classes.subsRemText,
                    ])}
          >
            {/* {company()} */}
            { userInfo.firstName} { userInfo.lastName }
          </Typography>
        </div>
        </Box>
        </Box>
            <MessageMenu
                 open={Boolean(mailMenu)}
                 anchorEl={mailMenu}
                 onClose={() => setMailMenu(null)}
                 messages={messages}
            />

            <NotficationsMenu
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              onSelectNotification={() => setNotificationsMenu(null)}
              notifications={notifications}
            />

            <ProfileMenu
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              onSignOut={() => onSignOut()}
              userInfo={userInfo}
              role={role}
            />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  greetings: PropTypes.string,
  userInfo: PropTypes.object,
  publisher: PropTypes.object,
  onSignOut: PropTypes.func,
  publisherLogo: PropTypes.any,
  role: PropTypes.any,
};

Header.defaultProps = {
  greetings: 'Hi',
  userInfo: {
    name: '',
  },
  publisher: {},
  onSignOut: () => {},
  publisherLogo: EcalProfileLogo,
  role: {},
};

export default observer(Header);
