
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from './error_map';

export const AuthErrorSource = {
    COGNITO: 'cognito',
    ECAL: 'ecal',
};

/* THIS IS HOW WE EXTEND ERROR, but in most cases we don't need to */

const errorReasons = {
    ...ErrorAPIReasons,
    /*
     * there are cases where the only way to filter an error is based from an error reason.
     * the example below is a common value of 'reason' field in a returning error object.
     */
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};

const errorCodes = {
    ...ErrorAPICode,

     /*
     * there are cases where we can only determine the error based from the code.
     * the example below is a common value of 'code' field from the error object from an api response.
     */
     INVALID_PASSWORD_EXCEPTION: 'InvalidPasswordException',
};

const errorMessages = {
    ...ErrorAPIMessages,
     /*
     * there are cases where we can only determine the error based from the message.
     * the example below is a common value of 'message' field from the error object from an api response.
     */
     JWT_TOKEN_EXPIRED: 'jwt verification failed',

};

const errorErrors = {
    ...ErrorAPIErrors,
     /*
     * there are cases where we can only determine the error based from the error.
     * the example below is a common value of 'error' field from the error object from an api response.
     */
    INVALID_INPUT_PARAMETER: 'INVALID_INPUT_PARAMETER',
};

const webhookErrorMap = {
    ...errorMap,

    /*
     * error Map Can return both object or function, in which the function will be called to return an error object,
     */
    INTERNAL_SERVER_ERROR: {
        fieldErrorMessage: 'specific error message tailored from the frontend specific frontend needs.',
    },

    JWT_TOKEN_EXPIRED: (err) => {
        /*
         * the err object carries the data result coming from the server
         */
        return {
            webhookName: 'we can map error string to a field if we want to show an error to that particular field ',
            webhookField2: err.message, /* this example shows that we can map api error message to a particular field, by assigning the err.message to the field */
            message: err.message,
        };
    },
};

export function handleWebhookError (res, action) {
    /* this is a really specific way to handle  errors */

    let data = {};
    let error = {};
    if (res.data && res.data.data) {
        data = res.data.data;
    }

    if (data.errors) {
        error = data.errors[0]; /* @Developers -> ensure that this is properly map to the error object from response */
    } else {
        return { hasError: false };
    }

    const options = {
        errorMap: webhookErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };

    const err = validateError(error, options);

    if (err !== null) {
        this.webhookError = {};
        this.webhookError[action] = err;
        return {
            hasError: true,
        };
    }

    return {
        hasError: false,
    };
}
