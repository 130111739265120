import { makeStyles } from '@material-ui/styles';
import EventPreview from '../../commons/images/event/eventPreview.png';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
  widgetContainer: {
    paddingBottom: '24px',
  },
  icon: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& .MuiSvgIcon-root': {
        marginRight: 5,
    },
  },
  boxStyling: {
    backgroundColor: '#ffffff',
    padding: 24,
    '& .rct-node-icon': {
      color: '#0c0644!important',
    },
  },

  mainContainer: {
    // width: 'auto',
    // backgroundColor: '#ffffff',
    // borderRadius: 4,
    // boxShadow: theme.customShadows.widget,
  },
  previewContainer: {
    backgroundSize: '250px',
    backgroundImage: `url(${EventPreview})`,
    width: '350px',
    backgroundRepeat: 'no-repeat',
  },

  noPadding: {
    padding: 0,
  },

  uploadSection: {
    maxWidth: '150px',
    marginRight: '24px',
  },
}));
