import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router';
import {
    useButtonsStore,
    useSchedulesStore,
    // useUserStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';
import useButtonWidgetErrors from '../../../store/models/buttons/errors/useButtonWidgetErrors';
// import useModuleError from './useModuleError';
import { useTouchContext } from '../../../app/context/TouchContext';

import defaultInput from './defaultButton.json';

const Mode = {
    ADD: 'add',
    EDIT: 'edit',
};

const DisplayTypes = {
    BUTTON: 'button',
    AD: 'ad',
};

function useModuleInput (props) {
    const {
        location,
    } = props;

    const history = useHistory();
    function getMode () {
        if (location.pathname.indexOf('edit') !== -1) {
            return Mode.EDIT;
        }
        return Mode.ADD;
    }

    function getDisplayType () {
        const path = history.location.pathname;
        if (path.indexOf('/admin/displays/add/ad') !== -1 ||
        path.indexOf('/admin/displays/ad/edit') !== -1) {
            return DisplayTypes.AD;
        }
        return DisplayTypes.BUTTON;
    }

    const [mode] = useState(getMode());
    const [displayType] = useState(getDisplayType());
    const [input, setInput] = useState({
        ...defaultInput,
        name: '',
    });
    const [options, setOptions] = useState({});
    /*
     * savable state is an state that will not be observe by the UI.
     * a replica of input state but will be updated every time the changes happened...
     * Changes on the savable state will not be rendered. for faster performance.
     * will be validated for an error..
     */
    const [savable, setSavableData] = useState({ ...defaultInput });
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [progress, setProgress] = useState({
        save: false,
    });

    const [messageOpen, setMessageOpen] = useState(false);
    const {
        errors,
        hasError,
        hasInputError,
    } = useButtonWidgetErrors({
        progress,
        setProgress,
        input: savable,
        mode,
    });

    const buttonsStore = useButtonsStore();
    const schedulesStore = useSchedulesStore();
    const { updateTouches } = useTouchContext('useButtonWidgetErrors');
    // const userStore = useUserStore();

    function initStore () {
        setProgress({ ...progress, buttons: true });
        schedulesStore.filterText = '';
        schedulesStore.getRootCategories();
        if (mode === Mode.EDIT) {
            buttonsStore.loadEditData();
            initWithEditData();
        }
    }

    function initWithEditData () {
        const initData = {
            ...buttonsStore.editData,
            type: displayType,
            style: {
                ...buttonsStore.editData.style,
                template: buttonsStore.editData.style.template ? buttonsStore.editData.style.template : 'ecal-round',
            },
        };
        console.log('initWithEditData(): ', initData);
        setInput(initData);
        setSavableData(initData);
        window.savableData = initData;
    }

    useEffect(() => {
        console.log('useModuleInput.useEffect().input :', input);
    }, [input]);

    useEffect(() => {
        setProgress({ ...progress, save: false });
      if (isEmpty(errors)) {
        if (updateTouches.width) {
            setSaveDisabled(false);
        }
      } else {
        setSaveDisabled(true);
      }
    }, [errors]);

    useEffect(() => {
        setProgress({ ...progress, save: false });
    }, [hasError]);

    useStoreModuleDependency({ initStore });

    useEffect(() => {
        window.savableData = { ...savable };
    }, [savable]);

    useEffect(() => {
        buttonsStore.assets = {}; // ensure that assets url is empty on launch..
    }, []);

    function toggleSaveForAdWidget (data) {
        if ((!data.name && data.name.length <= 0) || !data.image) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }

    function taggleSaveForButtonWidget (data) {
        if (!data.name && data.name.length <= 0) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }

    function updateSavable (data) {
        clearTimeout(window.updateSavable);
        function delay () {
            setSavableData(data);
            window.savableData = data;
            // console.log(`%c updateSavable.delay()  ${JSON.stringify(window.savableData, null, 4)}`, 'color: blue');
            if (displayType === DisplayTypes.AD) {
                toggleSaveForAdWidget(data);
                return;
            }
            taggleSaveForButtonWidget(data);
        }
        window.updateSavable = setTimeout(delay, 400); // debounce update till user input stopped in 400ms
    }

    /*
     * This will just copy the updated state to input to reflect the changes on the UIs
     * only being called during the unmounting
     */
    function copySavableToInput () {
        setInput({ ...window.savableData });
    }

    function upsertButtons () {
        const widget = {
            ...savable,
            type: displayType,
            draft: undefined,
        };

        if (mode === Mode.ADD) {
            buttonsStore.addWidget(widget, options);
        } else if (mode === Mode.EDIT) {
            buttonsStore.editWidget(widget, options);
        }
    }

    function upsert () {
        setProgress({ ...progress, save: true });
        /* Enter update/edit mechanism here */
        upsertButtons();
    }

    function updateOptions (newOpts) {
        setOptions({
            ...options,
            ...newOpts,
        });
    }

    return {
        input,
        mode,

        upsert, // call add or edit

        progress,
        setProgress,

        hasError,
        errors,
        hasInputError,

        messageOpen,
        setMessageOpen,
        saveDisabled,
        updateSavable,
        copySavableToInput,
        updateOptions,
        loadCategories: initStore,
    };
}

export default useModuleInput;
