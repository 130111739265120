import React, { useEffect, useState } from 'react';

import { useBillingStore } from '../../store/models';
import ProgressDialog from './secureDialog';

import {
    setSharedAuth,
    getSharedAuth,
    logInfo,
} from '../../utils';

function get3dSecureRedirectURL () {
    const paths = {
        local: 'http://localhost:3000/',
        prod: 'https://app.ecal.com/',
        stage: 'https://stage-app.ecal.com/',
        dev: 'http://localhost:3000/', // 'https://test-app.ecal.com/',
    };

    const base = paths[window.env] ? paths[window.env] : paths.prop;
    const redirectURL = `${base}3d.secure.redirect.html`;
    logInfo('get3dSecureRedirectURL()', redirectURL);
    return redirectURL;
}

function get3dSecureCallbackURL () {
    const paths = {
        local: 'http://localhost:3034/v4/billing/secure3DSCallback',
        prod: 'https://svc.ecal.com/v4/billing/secure3DSCallback',
        stage: 'https://stage-svc.ecal.com/v4/billing/secure3DSCallback',
        dev: 'https://test-svc.ecal.com/v4/billing/secure3DSCallback',
        // dev: 'http://localhost:3034/v4/billing/secure3DSCallback',
    };

    const url = paths[window.env] ? paths[window.env] : paths.dev.prod;
    logInfo('get3dSecureCallbackURL()', url);
    return url;
}

// 3D Secure Card Ex: 4000000000003220

function use3DSecure (props) {
    const {
        onAuthSuccess = () => {},
        onAuthFailed = () => {},
    } = props;

    const billingStore = useBillingStore();
    const [openAuth, setOpenAuth] = useState(false);

    const [actionURL, setActionURL] = useState('');

    useEffect(() => {
        logInfo('CharigyAction Link is updated....');
        if (billingStore.chargifyActionLink) {
            handle3dSecure();
        }
    }, [billingStore.observed.chargifyActionLink]);

    function handle3dSecure () {
        setOpenAuth(true);
        openActionLink();
        startAuthentication();
        verifySharedAuth();
    }

    function verifySharedAuth () {
        const sharedAuth = getSharedAuth();
        if (sharedAuth.authenticated) {
            finalizeAuthentication();
        } else {
            setTimeout(() => verifySharedAuth(), 1000);
        }
    }

    function startAuthentication () {
        setSharedAuth({
            authenticated: false,
        });
    }

    function finalizeAuthentication () {
        const sharedAuth = getSharedAuth();
        setOpenAuth(false);
        if (sharedAuth.isSuccess) {
            onAuthSuccess();
        } else {
            onAuthFailed(sharedAuth.message);
        }
    }

    function openActionLink () {
        const link = `${billingStore.chargifyActionLink}&redirect_url=${get3dSecureRedirectURL()}&callback_url=${get3dSecureCallbackURL()}`;
        logInfo('openActionLink() -> ', link);
        // window.open(link, '_blank');
        setActionURL(link);
    }

    function renderAuthDialog () {
        return (<ProgressDialog
            open={openAuth}
            url={actionURL}
        />);
    }

    return {
        renderAuthDialog,
    };
}

export default use3DSecure;
