import { useState, useEffect } from 'react';
import { logInfo } from '../../../utils';
import {
    isDateOk,
    isTimeOk,
    combine,
    stringToTime,
    toDate,
    toDateString,
    toTimeString,
} from './dateUtils';

function useDateTimeInput (props) {
    const {
        input,
        setInput,
        setHasValidationFieldError,
    } = props;

    const [startDate, setStartDate] = useState(toDate(''));
    const [endDate, setEndDate] = useState(toDate(''));
    const [startTime, setStartTime] = useState(stringToTime(''));
    const [endTime, setEndTime] = useState(stringToTime(''));

    const [isTouched, setIsTouched] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [allDay, setAllDay] = useState(false);

    useEffect(() => {
        console.log('All Day Updated...', input?.time?.allDay);
        if (input.time.allDay === true) {
            setAllDay(true);
            if (isTouched) {
                // will only be called when startDate is changed and touched..
                dateToAllDay();
            }
        } else {
            if (allDay === false) {
                validateDate();
            } else {
                setAllDay(false);
            }
        }
    }, [input?.time?.allDay]);

    useEffect(() => {
       validateDate();
    }, [startDate, endDate, startTime, endTime]);

    useEffect(() => {
        applyTimeData();
    }, [input?.time]);

    useEffect(() => {
        logInfo('All Day Updated... -> ', allDay);
        if (allDay === false) {
            validateDate();
        }
    }, [allDay]);

    function validateDate () {
        logInfo('validateDate()');
        if (isDateValid()) {
            logInfo('validateDate() Date is  Valid -> is Touched ', isTouched);
            setDateError(false);
            if (isTouched) {
                setHasValidationFieldError(false);
            }
        } else {
            logInfo('validateDate() Date is NOT Valid -> is Touched ', isTouched);
            if (isTouched) {
                setDateError(true);
                setHasValidationFieldError(true);
            }
        }
    }

    function applyTimeData () {
        logInfo('apply Time: ', input.time);
        setStartDate(toDate(input.time.startDate));
        setEndDate(toDate(input.time.endDate));
        setStartTime(stringToTime(input.time.startTime));
        setEndTime(stringToTime(input.time.endTime));
    }

    function onStartDateChange (date) {
        setIsTouched(true);
        const newInput = {
            ...input,
            time: {
                ...input.time,
                startDate: toDateString(date),
            },
        };
        logInfo('onStartDateChange(): ', newInput);
        setInput(newInput);
    }

    function onEndDateChange (date) {
        setIsTouched(true);
        const newInput = {
            ...input,
            time: {
                ...input.time,
                endDate: toDateString(date),
            },
        };
        logInfo('onEndDateChange(): ', newInput);
        setInput(newInput);
    }

    function onStartTimeChange (time) {
        setIsTouched(true);
        const newInput = {
            ...input,
            time: {
                ...input.time,
                startTime: toTimeString(time),
            },
        };
        logInfo('onStartTimeChange(): ', newInput);
        setInput(newInput);
    }

    function onEndTimeChange (time) {
        setIsTouched(true);
        const newInput = {
            ...input,
            time: {
                ...input.time,
                endTime: toTimeString(time),
            },
        };
        logInfo('onEndTimeChange(): ', newInput);
        setInput(newInput);
    }

    function dateToAllDay () {
        const newStart = new Date(startDate);
        newStart.setHours(0, 0, 0, 0);

        const newEnd = new Date(newStart);
        newEnd.setHours(23, 59, 0, 0);

        const newInput = {
            ...input,
            time: {
                ...input.time,
                startDate: toDateString(newStart),
                endDate: toDateString(newEnd),
                startTime: toTimeString(newStart),
                endTime: toTimeString(newEnd),
            },
        };

        console.log(' New Date: ', newInput);
        setInput(newInput);
    }

    function isDateValid () {
        if (allDay === true) {
            return true;
        }

        const s = combine(startDate, startTime);
        const e = combine(endDate, endTime);

        if (!isTimeOk(startDate, startTime, endDate, endTime)) {
            return false;
        }
        return isDateOk(s, e);
    }

    return {
        onStartDateChange,
        onEndDateChange,
        onStartTimeChange,
        onEndTimeChange,

        startTime,
        startDate,
        endTime,
        endDate,
        allDay,
        dateError,

        openError,
        setOpenError,
        setIsTouched,
    };
}

export default useDateTimeInput;
