import React from 'react';

import {
    Home as HomeIcon,
    Settings as SettingsIcon,
    EventNote as EventIcon,
    HelpOutline as HelpIcon,
    Queue as ButtonDisplayIcon,
    PlaylistAddCheck as MarketingIcon,
    TrendingUp as AnalyticsIcon,
    Code as DeveloperIcon,
    BuildRounded as GettingStartedIcon,
} from '@material-ui/icons';

function getStructure (params) {
    const {
        onGettingStartedClick,
        gettingStartedOpen = false,
    } = params;

    return [
        { id: 0, label: 'Home', link: '/admin/home/summary', icon: <HomeIcon /> },
        {
            id: 1,
            label: 'Schedules & Events',
            link: '/admin/schedules',
            icon: <EventIcon />,
            children: [
                {
                    label: 'Manage Schedules',
                    link: '/admin/schedules',
                },
                {
                    label: 'Add Schedule',
                    link: '/admin/schedules/add',
                },
                {
                    label: 'Import Events',
                    link: '/admin/schedules/import-events',
                },
            ],
        },
        {
            id: 2,
            label: 'Displays',
            link: '/admin/displays',
            badge: 'New',
            badgeColor: 'secondary',
            icon: <ButtonDisplayIcon />,
            children: [
                {
                    label: 'Manage Displays',
                    link: '/admin/displays',
                },
                {
                    label: 'Add Display',
                    link: '/admin/displays/add',
                },
            ],
        },
        {
            id: 3,
            label: 'Marketing',
            link: '/admin/marketing',
            badge: 'Try me',
            badgeColor: 'success',
            icon: <MarketingIcon />,
            children: [
                {
                    label: 'Opt-in Messages',
                    link: '/admin/marketing/optins',
                },
                {
                    label: 'Custom Labels',
                    link: '/admin/marketing/custom-labels',
                },
            ],
        },
        {
            id: 4,
            label: 'Data & Analytics',
            link: '/admin/analytics',
            icon: <AnalyticsIcon />,
            children: [
                {
                    label: 'Subscriber Analytics',
                    link: '/admin/analytics/subscriber-analytics',
                },
                {
                    label: 'Subscriber Data',
                    link: '/admin/analytics/subscriber-data',
                },
                {
                    label: 'Engagement Analytics',
                    link: '/admin/analytics/enagagement-analytics',
                },
            ],
        },
        {
            id: 5,
            label: 'Developers',
            link: '/admin/developers',
            icon: <DeveloperIcon />,
            children: [
                {
                    label: 'API Keys',
                    link: '/admin/developers/apiKeys',
                },
                {
                    label: 'Webhooks',
                    link: '/admin/developers/webhooks',
                },
            ],
        },
        {
            id: 6,
            label: 'Settings',
            link: '/admin/settings',
            badge: 'Try me',
            badgeColor: 'success',
            icon: <SettingsIcon />,
        },

        {
            id: 7,
            open: gettingStartedOpen,
            label: 'Getting Started',
            link: '/getting-started',
            icon: <GettingStartedIcon />,
            onLinkItemClickOverride: onGettingStartedClick,
            children: [

                {
                    label: 'How to Create Events',
                    link: '/admin/home/summary?product_tour_id=339837',
                },

                {
                    label: 'How to Create Schedules',
                    link: '/admin/home/summary?product_tour_id=339508',
                },

                {
                    label: 'How to Create Displays',
                    link: '/admin/home/summary?product_tour_id=339844',
                },

                {
                    label: 'How to Go Live',
                    link: '/admin/home/summary?product_tour_id=339861',
                },
            ],
        },

        {
            id: 8,
            label: 'Help',
            icon: <HelpIcon />,
            children: [
                {
                    label: 'Import Worksheets',
                    link: '/admin/schedules/import-events',
                },
            ],
        },
    ];
}
export default getStructure;
