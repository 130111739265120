import { useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { useBillingStore, useUserStore, useEnvStore } from '../../store/models';

import {
    logInfo,
    getURLElements,
    isFreeTrial,
    isFreePlan,
    getCurrency,
} from '../../utils';

import {
    getChargifyConfig,
    getChargifyTaxRate,
    calculatePriceSummary,
    getAddOns,
    CurrencyLabels,
    getCurrencyLabel,
    CurrencySymbols,
    getCurrencySymbol,
    getAddOnByHandleName,
    isAddUserLimitReached,
    chargifyFields,
    withOrganizedAddOns,
    getDisabledASetupAddons,
    TaxLabels,
    getTaxLabel,
} from '../../conf/chargify';

import {
    AddOnTypes,
    HandleNames,
} from '../../utils/constants';
import useBillingErrors from '../../store/models/billing/errors/useBillingErrors';
import { useTouchContext } from '../../app/context/TouchContext';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import use3DSecure from './use3DSecure';

function getCarryOverValue (handleName, type, currentPlan, newPlan) {
    const addOn = getAddOnByHandleName({
        handleName,
        addOns: currentPlan.addOns,
        currentPlanHandle: currentPlan.code,
        newPlanHandle: newPlan.code,
    });

    function getQuantity () {
        if (currentPlan.code === newPlan.code) {
            // User Case -> old plan is new plan , it means add on upgrade...
            return addOn.quantity;
        }

        const lcHandle = handleName.toLowerCase();
        if (lcHandle.indexOf(HandleNames.ADDITIONAL_USERS) !== -1 ||
        lcHandle.indexOf(HandleNames.ADD_USERS) !== -1) {
            return 0;
        }
        return addOn.quantity;
    }

    if (addOn) {
        if (addOn.type === 'quantity') {
            return getQuantity();
        } else {
            return true;
        }
    }

    if (type === 'onOff') {
        return false;
    }
    return 0;
}

function useBillingFormInput ({ step, onClose, isAddOnUpgrade = false }) {
    const envStore = useEnvStore();
    const userStore = useUserStore();
    const billingStore = useBillingStore();
    const [input, setInput] = useState({});
    const [addOns, setAddOns] = useState([]);
    const [addOnsValues, setAddOnsValues] = useState({});
    const [addOnsDisabled, setAddOnsDisabled] = useState({}); // if id is not found here it means addOn is enable

    const [untouchedAddOnsValues, setUntouchedAddOnsValues] = useState({}); // use to record the original state of addOns...

    const [pricingSummary, setPricingSummary] = useState({});

    const chargifyFormRef = useRef();
    const chargify = useRef(new window.Chargify());

    const [isLoading, setIsLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [chargifyInput, setChargifyInput] = useState({});
    const [localErrors, setLocalErrors] = useState({});
    const [setupPlanSuccess, setSetupPlanSuccess] = useState(false);
    const [currencyLabel, setCurrencyLabel] = useState(CurrencyLabels.au);
    const [currencySymbol, setCurrencySymbol] = useState(CurrencySymbols.au);
    const [taxLabel, setTaxLabel] = useState(TaxLabels.au);
    const [tax, setTaxRate] = useState('10');
    const [coupon, setCoupon] = useState(null);

    const { errors } = useBillingErrors({
        input: chargifyInput,
    });

    const {
        renderErrorDialog,
    } = useModuleErrorDialog({
        errors: localErrors,
        onCloseError: () => {
            if (setupPlanSuccess) {
                window.location.replace('/admin/home/summary');
                // billingStore.env.history.push('/admin/home/summary');
                onClose();
            } else {
                if (billingStore.coupon) {
                    window.location.replace(`/admin/settings/billing?coupon=${billingStore.coupon}`);
                } else {
                    window.location.replace('/admin/settings/billing');
                }
                onClose();
            }
        },
    });

    const {
        updateTouches,
    } = useTouchContext('useBillingErrors');

    const {
        renderAuthDialog,
    } = use3DSecure({
        onAuthSuccess: () => finalize3DSecure(),
        onAuthFailed: (msg) => showAuth3DSecureFailed(msg),
    });

    function decodeInput () {
        const targetPlan = billingStore.selectedPlanData;
        if (!targetPlan) {
            return;
        }
        const newInput = {
            ...input,
            plan: targetPlan.id,
            planName: targetPlan.name,
            ...targetPlan,
        };

        setInput(newInput);

        setAddOns(withOrganizedAddOns(targetPlan.addOns));
        const values = getDefaultValues(targetPlan);
        setAddOnsValues(values);

        const newDisableds = getDisabledASetupAddons(targetPlan.addOns, values);
        setAddOnsDisabled(newDisableds);
        calculatePrice(newInput, values);
        validateAddUserLimit();
    }

    function validateAddUserLimit (plan) {
        if (!plan) {
            return;
        }
        const ccy = getCurrency(envStore);
        const limitParams = {
            currency: ccy,
            planHandle: plan.code,
            subscriberCount: userStore.subscriberCount,
            planLimit: userStore.publisher.subscriberLimit,
        };
        isAddUserLimitReached(limitParams);
    }

    function getDefaultValues (targetPlan) {
        const values = {};
        const currentPlan = billingStore.publisherPlan;
        // target plan might be the plan selected on the
        targetPlan.addOns.forEach((v) => {
            if (isEmpty(currentPlan)) {
                if (v.type === AddOnTypes.QUANTITY) {
                    values[v.id] = 0;
                } else if (v.type === AddOnTypes.ON_OFF) {
                    values[v.id] = false;
                } else {
                    values[v.id] = 0;
                }
            } else {
               values[v.id] = getCarryOverValue(v.handle, v.type, currentPlan, targetPlan);
            }
        });
        console.log('useBillingFormInput.getDefaultValues() ', values);
        return values;
    }

    function decodeInputFreeTrial () {
        const targetPlan = billingStore.selectedPlanData;
        if (!targetPlan) {
            return;
        }
        if (isFreeTrial(targetPlan.name) && billingStore.planDetails) {
            const freePlan = billingStore.planDetails.filter((plan) => {
               if (isFreePlan(plan.name)) {
                return true;
               }
               return false;
            })[0];
            const newInput = {
                ...input,
                plan: freePlan.id,
                planName: freePlan.name,
                ...freePlan,
            };
            setInput(newInput);
        }
    }

    function updateProgressAndDisabled () {
        setIsLoading(false);
        if (step === 2) {
            if (!isEmpty(errors) || !isChargifyFieldsValid()) {
                setSubmitDisabled(true);
            } else {
                setSubmitDisabled(false);
            }
        }
    }

    function decodeClientInfo () {
        const ci = userStore.countryInfo;
         function countryState () {
            if (ci.state) {
                return ci.state;
            }
            if (ci.region) {
                return ci.region;
            }
            return '';
         }

         if (!isEmpty(userStore.countryInfo)) {
            const info = {
                country: ci.country_code,
                city: ci.city,
                zip: ci.postal,
                state: countryState(),
            };
            setChargifyInput({
                ...chargifyInput,
                ...info,
            });
         }
    }

    function isChargifyFieldsValid () {
        if (isEmpty(chargifyInput.country) ||
            isEmpty(chargifyInput.city) ||
            isEmpty(chargifyInput.state) ||
            isEmpty(chargifyInput.address) ||
            isEmpty(chargifyInput.zip)
        ) {
            return false;
        }
        return true;
    }

    function calculatePrice (inputValue, addOnsValue) {
        const ccy = getCurrency(envStore);
        const taxRate = getChargifyTaxRate(ccy, chargifyInput.country, chargifyInput.state);
        const source = { ...inputValue };
        if (isAddOnUpgrade) {
            source.price = 0;
        }
        // check the difference between addOnsVlaue and untouchedAddOnsValue.. only happend on addOn Upgrade...

        let newSummary = {};
        const options = {};

        if (billingStore.coupon) {
            options.coupon = billingStore.coupon;
        }

        if (isAddOnUpgrade) {
            options.untouchedAddOnsValues = untouchedAddOnsValues;
            newSummary = calculatePriceSummary(source, addOnsValue, taxRate, options);
        } else {
            newSummary = calculatePriceSummary(source, addOnsValue, taxRate, options);
        }

        setPricingSummary(newSummary);

        setTaxRate(taxRate);
        setCurrencyLabel(getCurrencyLabel(ccy));
        setCurrencySymbol(getCurrencySymbol(ccy));
        setTaxLabel(getTaxLabel(ccy));
    }

    function getPlanFromValue (planValue) {
        const planSource = billingStore.planDetails;
        return planSource.filter((plan) => (plan.handle === planValue))[0];
    }

    function updateInput (data) {
        const newInput = {
            ...input,
            ...data,
        };
        setInput(newInput);
        calculatePrice(newInput, addOnsValues);

        getAddOns(addOnsValues, newInput);
    }

    function selectPlan (planValue) {
        const targetPlan = getPlanFromValue(planValue);
        setAddOns(withOrganizedAddOns(targetPlan.addOns));
        const values = getDefaultValues(targetPlan);
        setAddOnsValues(values);

        const newDisableds = getDisabledASetupAddons(targetPlan.addOns, values);
        setAddOnsDisabled(newDisableds);

        updateInput({
            plan: planValue,
            planName: targetPlan.name,
            ...targetPlan,
        });
    }

    function updateAddOnsValue (addOnId, value) {
        const newValues = { ...addOnsValues };

        newValues[addOnId] = value;
        setAddOnsValues({ ...newValues });

        const newDisableds = getDisabledASetupAddons(addOns, newValues);
        setAddOnsDisabled(newDisableds);

        calculatePrice(input, newValues);
        getAddOns(newValues, input);
    }

    function submitChargify () {
        if (chargifyFormRef.current) {
            setIsLoading(true);
            chargify.current.token(
                chargifyFormRef.current,
                (token) => {
                    logInfo(' useBillingFormInput().submitChargify() Chargify Token Callback: ', token);
                    upsertSubscription(token);
                },

                (err) => {
                    setIsLoading(false);
                    showUpgradeFailed(err.errors);
                }
            );
        }
    }

    async function upsertSubscription (token) {
        const newAddOns = getAddOns(addOnsValues, input);

        billingStore.createSubscriptionParams = {
            code: input.code,
            token,
            addOns: newAddOns,
        };
        let res = {};
        if (isAddOnUpgrade) {
          res = await billingStore.updateSubscription(billingStore.createSubscriptionParams);
        } else {
          res = await billingStore.createSubscription(billingStore.createSubscriptionParams);
        }

        if (res.is3DSecure) {
            return;
        }
        await finalizeSubscription(res);
    }

    async function finalizeSubscription (params) {
        const {
            hasCardError,
            chargifyErr,
        } = params;
        const { path } = getURLElements();
        if (path.indexOf('/setup-plan') !== -1) {
            if (hasCardError) {
                showUpgradeFailed(chargifyErr);
            } else {
                showSuccessMessage();
                setIsLoading(false);
                setSetupPlanSuccess(uuid());
            }
        } else {
            if (hasCardError) {
                showUpgradeFailed(chargifyErr);
            } else {
                await billingStore.getCurrentPlan();
                setIsLoading(false);
                showSuccessMessage();
            }
        }
    }

    async function finalize3DSecure () {
        await billingStore.finalize3DSecure(billingStore.createSubscriptionParams);
        const { path } = getURLElements();
        if (path.indexOf('/setup-plan') !== -1) {
            showSuccessMessage();
            setIsLoading(false);
            setSetupPlanSuccess(uuid());
        } else {
            await billingStore.getCurrentPlan();
            setIsLoading(false);
            showSuccessMessage();
        }
    }

    function showSuccessMessage () {
        let dialogProps = {
            title: 'Plan Upgraded!',
            message: `Congratulations. Your plan is now upgraded to ${input.planName} plan.`,
        };

        if (isAddOnUpgrade) {
            dialogProps = {
                title: 'Add Ons Upgraded!',
                message: 'Congratulations. You have upgraded your add ons.',
            };
        }

        const { path } = getURLElements();
        if (path.indexOf('/setup-plan') !== -1) {
            dialogProps = {
                title: 'Signup Success!',
                message: `Congratulations. You have signup to ${input.planName} plan.`,
            };
        }
        setLocalErrors(dialogProps);
    }

    function showAuth3DSecureFailed (msg) {
        setIsLoading(false);
        const dialogProps = {
            title: '3D Secure Authentication Failed',
            message: msg,
        };
        setLocalErrors(dialogProps);
    }

    function getTitle () {
        const { path } = getURLElements();
        if (path.indexOf('/setup-plan') !== -1) {
            return 'Plan Subscription Failed';
        }
        return 'Plan Upgrade Failed';
    }

    function showUpgradeFailed (msg) {
        setIsLoading(false);
        const dialogProps = {
            title: getTitle(),
            message: msg,
        };
        setLocalErrors(dialogProps);
    }

    function updateChargifyInput (data) {
        // compute..

        setChargifyInput({
            ...chargifyInput,
            ...data,
        });

        const newlyTouched = {};
        for (const i in data) {
            newlyTouched[i] = true;
        }
        updateTouches({ ...newlyTouched });
    }

    async function upgradeFree () {
        setIsLoading(true);
        await billingStore.upgradeFree();
        setIsLoading(false);
    }

    function handleChargifyForError (err) {
        logInfo('handleChargifyForError()', err);
    }

    function initChargify (chargifyStyles) {
        const ccy = getCurrency(envStore);
        const conf = getChargifyConfig(ccy);
        chargify.current.load({
            publicKey: conf.publicKey, // 'chjs_mv9ygnrzy8zjcp4cnqszd5dc',
            serverHost: `https://${conf.endpoint}`, // 'https://ecal-au-test.chargify.com',
            selector: '#chargifyPanel',
            type: 'card',
            hideCardImage: false,
            styles: chargifyStyles,
            fields: chargifyFields,
            // threeDSecure: true,
        });
    }

    useEffect(() => {
        if (billingStore.selectedPlanData) {
            if (isEmpty(untouchedAddOnsValues)) {
                // use this as the default values un changed...
                const values = getDefaultValues(billingStore.selectedPlanData);
                console.log('useEffect() billingStore.selectedPlanData AddOn Vlaues: ', values);
                setUntouchedAddOnsValues(values);
            }
            decodeInput();
        }
    }, [billingStore.observed.selectedPlanData]);

    useEffect(() => {
        if (billingStore.planDetails) {
            decodeInputFreeTrial();
        }
    }, [billingStore.observed.planDetails]);

    useEffect(() => {
        console.log('useEffect() untouchedAddOnsValues updated -> ', untouchedAddOnsValues);
    }, [untouchedAddOnsValues]);
    useEffect(() => {
        updateProgressAndDisabled();
        calculatePrice(input, addOnsValues);
    }, [chargifyInput]);

    useEffect(() => {
        updateProgressAndDisabled();
        setLocalErrors(errors);
    }, [errors]);

    useEffect(() => {
        if (step === 2) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [step]);

    useEffect(() => {
        if (setupPlanSuccess) {
            showSuccessMessage();
        }
    }, [setupPlanSuccess]);

    useEffect(() => {
        decodeClientInfo();
    }, [userStore.countryInfo]);

    useEffect(() => {
        console.log('Setting -> useBillingPlan -> settings ', envStore.settings);
        if (envStore.settings && envStore.settings?.coupon) {
            setCoupon(envStore.settings.coupon);
            console.log('useBillingFormInput() Coupon', envStore.settings?.coupon);
        }
    }, [envStore.observed.settings]);

    useEffect(() => {
        if (billingStore.selectedPlanData) {
            decodeInput();
        }
        decodeClientInfo();

        return () => {
            decodeInput();
        };
    }, []);

    return {
        input,
        updateInput,
        addOns,
        addOnsValues,
        addOnsDisabled,
        updateAddOnsValue,
        selectPlan,
        pricingSummary,
        chargifyFormRef,
        chargify,
        submitChargify,
        upgradeFree,
        renderErrorDialog,
        chargifyInput,
        updateChargifyInput,
        isLoading,
        errors: localErrors,
        handleChargifyForError,
        submitDisabled,
        countryInfo: userStore.countryInfo,
        initChargify,
        currencyLabel,
        currencySymbol,
        taxLabel,
        taxRate: tax,
        renderAuthDialog,
        coupon,
    };
}

export default useBillingFormInput;
