import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Grid,
    TextField,
    Switch,
} from '@material-ui/core';
import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

const INSTRUCTION = 'It\'s a great idea to add this welcome message to the user\'s calendar when they subscribe.';
function WelcomeMessage (props) {
    const classes = useStyles();

    const {
       setOptionData,
       optionData,
    } = props;

    const [helpText, setHelpText] = useState('Enabled');

    useEffect(() => {
        if (optionData?.welcomeMessage?.active) {
            setHelpText('Enabled');
        } else {
            setHelpText('Disabled');
        }
    }, [optionData]);

    return (
        <React.Fragment>
          {console.log('optionData? ', optionData)}
            <Grid
                container
                item
                className={classes.sectionRoot}>
                    <Grid className={classes.sectionTitle}>
                        <Typography
                            variant='h6'
                            weight='medium'
                            >
                           Welcome Message
                        </Typography>
                    </Grid>
                    <Grid className={classes.sectionDescription}>
                        <Typography
                            block
                            >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classes.sectionContent}
                        >
                            <Box
                                className={classes.inputBox}>

                                <Grid className={classes.switchControl}>
                                    <Switch
                                        checked={optionData.welcomeMessage.active}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            setOptionData({
                                                ...optionData,
                                                welcomeMessage: {
                                                    ...optionData.welcomeMessage,
                                                    active: !optionData.welcomeMessage.active,
                                                },
                                            });
                                        }}

                                        onChange={(e) => {

                                        }}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <Typography variant='body2'>
                                        { helpText }
                                    </Typography>
                                </Grid>

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}

                                    id="message"
                                    label="Title"
                                    name="title"
                                    value={optionData.welcomeMessage.title}
                                    onChange={(e) => {
                                        setOptionData({
                                            ...optionData,
                                            welcomeMessage: {
                                                ...optionData.welcomeMessage,
                                                title: e.target.value,
                                            },
                                        });
                                    }}
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    /*
                                    helperText={ errors.text || '*Enter label text for this custom-label'}
                                    error={errors.text}
                                    */
                                />

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}

                                    id="message"
                                    label="Sub-title"
                                    onChange={(e) => {
                                        setOptionData({
                                            ...optionData,
                                            welcomeMessage: {
                                                ...optionData.welcomeMessage,
                                                subTitle: e.target.value,
                                            },
                                        });
                                    }}
                                    name="subtitle"
                                    value={optionData.welcomeMessage.subTitle}
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    /*
                                    helperText={ errors.text || '*Enter label text for this custom-label'}
                                    error={errors.text}
                                    */
                                />

                                <TextField
                                    InputProps={{
                                        className: classes.messageInput,
                                    }}

                                    id="message"
                                    label="Message"
                                    multiline
                                    rows="12"
                                    onChange={(e) => {
                                      setOptionData({
                                          ...optionData,
                                          welcomeMessage: {
                                              ...optionData.welcomeMessage,
                                              message: e.target.value,
                                          },
                                      });
                                    }}
                                    name="message"
                                    value={optionData.welcomeMessage.message}
                                    variant="outlined"

                                    /*
                                    helperText={ errors.text || '*Enter label text for this custom-label'}
                                    error={errors.text}
                                    */
                                />
                            </Box>

                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

WelcomeMessage.propTypes = {
    onChange: PropTypes.func,
    setOptionData: PropTypes.func,
    optionData: PropTypes.any,
};

WelcomeMessage.defaultProps = {
    onChange: () => {},
    setOptionData: () => {},
    optionData: {
        welcomeMessage: {
            active: true,
            title: 'Welcome to ECAL!',
            subTitle: 'Powered by ECAL.',
            message: 'Welcome message',
        },
    },
};

export default WelcomeMessage;
