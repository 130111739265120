
export function loadEditTarget () {
    this.scheduleEditTarget = this.env.getPageInfo('admin-schedules-edit');
}

export function saveEditData () {
    this.env.setPageInfo(
        'admin-schedules-edit',
        this.scheduleEditTarget
    );
}

export function scheduleDataFromSelectedItem () {
    if (!this.schedulesUnobserved) {
        return;
    }
    const id = this.scheduleEditTarget.id;
    const data = this.schedulesUnobserved.filter((item) => (item.id === id))[0];
    this.scheduleEditTarget.selectedData = data;
}

export function resetState () {
    this.assets.scheduleBanner = null;
    this.observed.assets.scheduleBanner = '';
}
