import { useState, useEffect, useRef } from 'react';

import useDesignUploads from './useDesignUploads';
import { updateWidgetSetting } from './widgetUtil';
import { logClear } from '../../../../utils';
import useCSSUpload from './useCSSUpload';
function useDesign (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
        setTabDisabled = () => {},
    } = props;

    const widgetRef = useRef();

    function decodeSource () {
        const decoded = {
            ...dataSource.style.eventDisplay,
            logo: dataSource.style.logo,
            language: dataSource.style.language,
            cssFile: dataSource.style.cssFile,
            showAllEvents: dataSource.display.showAllEvents,
            selectAllEvents: dataSource.display.selectAllEvents,
        };
        return decoded;
    }

    function encodeSavable (data) {
        return {
            ...dataSource,
            style: {
                ...dataSource.style,
                language: data.language,
                logo: data.logo,
                cssFile: data.cssFile,
                eventDisplay: {
                    ...data,
                    cssFile: undefined,
                    logo: undefined,
                    language: undefined,
                    showAllEvents: undefined,
                    selectAllEvents: undefined,
                },
            },

            display: {
                ...dataSource.display,
                showAllEvents: data.showAllEvents,
                selectAllEvents: data.selectAllEvents,
            },
        };
    }

    function getInitialState () {
        return decodeSource();
    }
    const [designData, setDesignDataState] = useState(getInitialState());

    function updateWidgetPreview (settings) {
        logClear();
        if (widgetRef.current) {
            updateWidgetSetting(settings);
        }
    }

    function initWidgetPreview () {
        updateWidgetPreview(dataSource);
    }

    useEffect(() => {
        window.onEventWidgetLaunched = () => {
            setTimeout(initWidgetPreview, 1000);
        };

        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        setDesignDataState(decodeSource());
        updateWidgetPreview(dataSource);
    }, [dataSource]);

    useEffect(() => {
        if (widgetRef.current) {
            setTimeout(initWidgetPreview, 1000);
        }
    }, [widgetRef.current]);

    useEffect(() => {

    }, [designData]);

    function setDesignData (data) {
        logClear();
        setDesignDataState(data);
        const encoded = encodeSavable(data);
        updateWidgetPreview(encoded);
        updateSavable(encoded);
    }

    const {
        uploadCSSFile,
        cssFile,
        cssFileError,
    } = useCSSUpload({
        dataSource,
        designData,
        setDesignData,
    });

    const {
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        buttonLogoError,
    } = useDesignUploads({
        designData,
        setDesignData,
        setTabDisabled,
    });

    return {
        widgetRef,
        designData,
        setDesignData,
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        updateWidgetPreview,
        buttonLogoError,

        uploadCSSFile,
        cssFile,
        cssFileError,
    };
}

export default useDesign;
