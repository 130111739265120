import {
    get,
    put,
    post,
    deleteCall,
    endpoints,
} from '../../api';

export function apiGetWebhooks (options) {
    return get(
        endpoints().getWebhooks,
        options
    );
}

export function apiSaveWebhooks ({ token, traceId, webhookId, body }) {
    return put(
        endpoints().updateWebhooks + '/' + webhookId,
        body,
        {
            token,
            traceId,
        }
    );
}

export function apiAddWebhook ({ token, traceId, body }) {
    return post(
        endpoints().updateWebhooks,
        body,
        {
            token,
            traceId,
        }
    );
}

export function apiDeleteWebhook ({ token, traceId, webhookId }) {
    return deleteCall(
        endpoints().updateWebhooks + '/' + webhookId,
        {
            token,
            traceId,
        }
    );
}
