export default {
    favicon: '/assets/embed.favicon.png',
    title: 'Admin Embed',
    labelName: 'embed',
    background: '/assets/embed.bgimage.png',
    logo: '/assets/embed.logo.png',
    footerColor: '#DB2896',
    headerColor: '#0c0644',
    copyrightText: `All Content Copyright ${new Date().getFullYear()} Embed, A Helix Leisure Company.`,
    supportContact: 'https://embed.zendesk.com/hc/en-us',
    hideIntercomChat: true, /* hide_default_launcher */
    supportLabel: 'Support',
    signInLabel: 'Sign In with',
    signInImage: '/assets/embed.png',
    provider: 'Embed',
    sidebar: 'funUnlimited',

    autoLoginValue: 'embed',
};
