import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Grid,
  // InputBase,
} from '@material-ui/core';

import useStyles from './styles';

// components
import { Typography, Avatar } from '../Wrappers/Wrappers';
import EcalMobileLogoImage from '../../commons/images/brandImage/MobileEcalLogo.png';
// import EcalLogoImage from '../../commons/images/brandImage/ECAL_logo_header.png';
import EcalProfileLogo from '../../commons/images/brandImage/ecal_icon_purple_light.svg';
// import StarIcon from '../../commons/images/icons/starticon.svg';

import ProfileMenu from './SuperAdminProfileMenu';
import NotficationsMenu from './NotificationsMenu';
import MessageMenu from './MessageMenu';
import BackMenuButton from './BackMenuButton';
import { messages, notifications } from './mock';

import useWhiteLabel from '../../store/models/styles/useWhiteLabel';

function Header (props) {
  const {
    // greetings,
    userInfo,
    onSignOut,
    publisher,
    publisherLogo,
    role,
  } = props;
  const {
    whiteLabelSettings,
  } = useWhiteLabel();

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#0c0644');
  const classes = useStyles({ headerBackgroundColor });
  // local
  const [mailMenu, setMailMenu] = useState(null);
  // const [isMailsUnread, setIsMailsUnread] = useState(true);
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  // const [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  // const [isSearchOpen, setSearchOpen] = useState(false);

  useEffect(() => {

  }, []);

  useEffect(function () {
    if (whiteLabelSettings.labelName === 'ecal') {
      if (publisher.planName === 'Enterprise') {
        setHeaderBackgroundColor('#6F2FCD');
      } else {
        setHeaderBackgroundColor('#0c0644');
      }
    } else {
      setHeaderBackgroundColor(whiteLabelSettings.headerColor);
    }
  }, [publisher.planName]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <BackMenuButton />
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          justifyContent='space-between'>

          <Box display='flex'>
            <Box
              component={Grid}
              className={classes.mobileLogoContainer}
              item
              // sm={12}
              sx={{ display: { xs: 'block', sm: 'none' } }}
            >
              <img src={EcalMobileLogoImage} alt="logo" className={classes.mobileLogotypeImage} />
            </Box>

            <Box
              component={Grid}
              className={classes.logoContainer}
              item
              // sm={12}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <img src={whiteLabelSettings.logo} alt="logo" className={classes.logotypeImage} />
            </Box>
            <div className={classes.orgNameContainer}>

            </div>
          </Box>

            <Box display='flex' flexDirection='row'>

            <IconButton
                aria-haspopup="true"
                color="inherit"
                className={classes.headerMenuButton}
                aria-controls="profile-menu"
                onClick={e => setProfileMenu(e.currentTarget)}
            >
                <Avatar
                alt=""
                /* eslint-disable-next-line no-mixed-operators */
                // src={profile}
                variant="square"
                className={classes.avatarImage}
                classes={{ root: classes.headerIcon }}>
                    {/* <PersonIcon /> */}
                    {/* <Box className={classes.logoContainer}> */}
                    <img height={24} width={24} src={publisherLogo} alt="logo" className={classes.logotypeImage} />
            {/* </Box> */}
                </Avatar>
            </IconButton>

            <div className={classes.userNameContainer}>
                <Typography variant={'body2'} className= {classNames([
                                classes.profileLabel,
                                classes.subsRemText,
                            ])}
                >
                { userInfo.firstName} { userInfo.lastName }
                </Typography>
            </div>
            </Box>
        </Box>
            <MessageMenu
                 open={Boolean(mailMenu)}
                 anchorEl={mailMenu}
                 onClose={() => setMailMenu(null)}
                 messages={messages}
            />

            <NotficationsMenu
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              onSelectNotification={() => setNotificationsMenu(null)}
              notifications={notifications}
            />

            <ProfileMenu
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              onSignOut={() => onSignOut()}
              userInfo={userInfo}
              role={role}
            />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  greetings: PropTypes.string,
  userInfo: PropTypes.object,
  publisher: PropTypes.object,
  onSignOut: PropTypes.func,
  publisherLogo: PropTypes.any,
  role: PropTypes.any,
};

Header.defaultProps = {
  greetings: 'Hi',
  userInfo: {
    name: '',
  },
  publisher: {},
  onSignOut: () => {},
  publisherLogo: EcalProfileLogo,
  role: {},
};

export default observer(Header);
