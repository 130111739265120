
export function querySearchPublishers (vars) {
  return {
    query: `query superAdminSearch($input: SuperAdminSearchInput!) {
        superAdminSearch( searchInput:$input) {
            totalCount
            edges {
                node {
                    publisherId
                    orgId
                    name
                    email
                   firstName
                   lastName
                   status
                   token
                   role
                   planName
                   planId
                   industry
                   objective
                   timezone
                   subscriberLimit
                    subscriberRemaining
                    subscriptionPlanCurrency
                    dateCreated
                    dateExpiry
                    dataRights
                    intercomUserHash
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryPublishersUpdate (vars) {
    return {
      query: `mutation superAdmin($input: SuperAdminPublishersUpdateInput!) {
        superAdminPublishersUpdate( input:$input) {
              totalCount
              edges {
                  node {
                      name
                      email
                     firstName
                     lastName
                     status
                     token
                     role
                     planName
                     industry
                     objective
                     timezone
                     subscriberLimit
                     subscriberRemaining
                     subscriptionPlanCurrency
                     dateCreated
                     dateExpiry
                  }
                  cursor
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          } 
      }
      `,
      variables: vars,
    };
}

/*
 * Input:
 * id: 123,
   orgId: 123,
   email: "",
   newEmail: "",
   cognitoPoolId: ""
 */
export function queryUpdateEmail (vars) {
    return {
      query: `mutation superAdmin($input: SuperAdminUpdateEmailInput!) {
        superAdminUpdateEmail( input:$input) {
              totalCount
              edges {
                  node {
                      name
                      email
                     firstName
                     lastName
                     status
                     token
                     role
                     planName
                     planId
                     industry
                     objective
                     timezone
                     subscriberLimit
                     subscriberRemaining
                     subscriptionPlanCurrency
                     dateCreated
                     dateExpiry
                  }
                  cursor
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
              error {
                code
                message
                type
              }
          } 
      }
      `,
      variables: vars,
    };
}

export function queryUpdatePlan (vars) {
    return {
      query: `mutation superAdmin($input: SuperAdminPlanUpdateInput!) {
        superAdminUpdatePlan( input:$input) {
              totalCount
              edges {
                  node {
                      name
                      email
                     firstName
                     lastName
                     status
                     token
                     role
                     planName
                     planId
                     industry
                     objective
                     timezone
                     subscriberLimit
                     subscriberRemaining
                     subscriptionPlanCurrency
                     dateCreated
                     dateExpiry
                  }
                  cursor
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          } 
      }
      `,
      variables: vars,
    };
}

export function queryGetPlans (vars) {
    return {
      query: `query planService($input: PlanFilterInput!) {

        getPlans (PlanFilterInput:$input) {
            totalCount
            edges {
              node {
                id
                name
                active
                subscriberLimit
                calendarLimit
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }

      }
      `,
      variables: vars,
    };
}

export function queryCreateUser (vars) {
  return {
    query: `mutation publisherUser($input: CreatePublisherUserInput!) {
      createPublisherUser( input:$input) {
            totalCount
            edges {
                node {
                  id
                  publisherId
                  orgId
                  lastName
                  firstName
                  email
                  dateCreated
                  phone
                  status
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
            error {
              code
              message
              type
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryGetPublisherUsers (vars) {
  return {
    query: `query publisherUser($input: GetPublishersUserInput!) {
      getPublisherUsers( input:$input) {
            totalCount
            edges {
                node {
                  id
                  publisherId
                  orgId
                  lastName
                  firstName
                  phone
                  status
                  email
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
            error {
              code
              message
              type
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryDeleteUsers (vars) {
  return {
    query: `mutation publisherUser($input: DeletePublisherUsersInput!) {
      deletePublisherUsers( input:$input) {
            totalCount
            edges {
                node {
                  id
                  publisherId
                  orgId
                  email
                  status
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
            error {
              code
              message
              type
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryUpdateUsers (vars) {
  return {
    query: `mutation publisherUser($input: UpdatePublisherUserInput!) {
      updatePublisherUsers( input:$input) {
            totalCount
            edges {
                node {
                  id
                  publisherId
                  orgId
                  lastName
                  firstName
                  email
                  dateCreated
                  phone
                  status
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
            error {
              code
              message
              type
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryGetPublisherById (vars) {
  return {
    query: `query superAdmin($input: SuperAdminGetPublisherInput!) {
      superAdminGetPublisher( input:$input) {
            totalCount
            edges {
                node {
                    publisherId
                    orgId
                    name
                    email
                   firstName
                   lastName
                   status
                   token
                   role
                   planName
                   planId
                   industry
                   objective
                   timezone
                   subscriberLimit
                    subscriberRemaining
                    subscriptionPlanCurrency
                    dateCreated
                    dateExpiry
                    dataRights
                    intercomUserHash
                }
                cursor
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        } 
    }
    `,
    variables: vars,
  };
}

export function queryGetCurrentPlan (vars) {
  return {
    query: `query {
      getCurrentPlan {
          edges {
              node {
                  id
                  code
                  name
                  description
                  price
                  status
                  provider
                  addOns {
                      id
                      name
                      description
                      price # not being returned at the moment
                      type
                      quantity
                      handle
                  }
              }
              cursor
          }
          pageInfo {
              endCursor
              hasNextPage
          }
          totalCount
      } 
  }`,
  variables: vars,
  };
}
