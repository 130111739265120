import { useState, useEffect } from 'react';
import { AssetType } from '../../../../utils/constants';

import { useButtonsStore, useUserStore } from '../../../../store/models';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import useImageUpload from '../../../hooks/useImageUpload';
import useImageRenderable from '../../../hooks/useImageRenderable';
// import { getClientTimezone } from '../../../../utils';

function useOptions (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
    } = props;
    const buttonsStore = useButtonsStore();
    const userStore = useUserStore();

    function timezoneData () {
        let tz = [];
        if (dataSource.display.timezone) {
            tz = [dataSource.display.timezone];
            return tz;
        }
        //  return [getClientTimezone()];
    }

    function decodeSource () {
        return {
            ...dataSource.settings,
            timezone: timezoneData(),
        };
    }

    function encodeSavable (data) {
        return {
            ...dataSource,
            settings: {
                ...data,
                timezone: undefined,
            },
            display: {
                ...dataSource.display,
                timezone: data.timezone[0],
            },
        };
    }

    const [optionData, setOptionDataState] = useState(decodeSource());

    useEffect(() => {
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        setOptionData(decodeSource());
    }, [dataSource]);

    function updatePublisherNamePlaceholder (publisher) {
        const getUpdatedValue = (data) => {
            return data.replace('[Publisher name]', publisher.company || publisher.name);
        };
        const hasValue = (data) => Object.keys(data).length;
        if (hasValue(optionData.sharing) && hasValue(publisher)) {
            const newSharingMessage = getUpdatedValue(optionData.sharing.message);
            const newTitle = getUpdatedValue(optionData.welcomeMessage.title);
            const newWelcomeMsg = getUpdatedValue(optionData.welcomeMessage.message);
            const updatedInput = {
                ...optionData,
                sharing: {
                    ...optionData.sharing,
                    message: newSharingMessage,
                },
                welcomeMessage: {
                    ...optionData.welcomeMessage,
                    title: newTitle,
                    message: newWelcomeMsg,
                },
            };

            setOptionData(updatedInput);
        }
    }
    useEffect(() => {
        console.log('useEffect() -> optionData.sharing,publisher userStore.updated...', optionData);
        const sharingMessage = optionData.sharing.message;
        const welcomeMessage = optionData.welcomeMessage.title;
        if (userStore.publisher && (sharingMessage.includes('[Publisher name]') || welcomeMessage.includes('[Publisher name]'))) {
            updatePublisherNamePlaceholder(userStore.publisher);
        }
    }, [optionData.sharing, userStore.publisher]);

    function setOptionData (data) {
        setOptionDataState(data);
        debounceUpdateSavable(data);
    }

    function debounceUpdateSavable (data) {
        clearTimeout(window.updateSaveTO);
        function save () {
            updateSavable(encodeSavable(data));
        }
        window.updateSaveTO = setTimeout(save, 500);
    }

    function setAdsLogoURL (url) {
        setOptionData({
            ...optionData,
            advertising: {
                ...optionData.advertising,
                banner: url,
            },
        });
    };

    const {
        image: adsBanner,
    } = useImageRenderable({
        imageSource: optionData.advertising.banner,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: adsLogo,
        upload: uploadAdsLogo,
        deleteImage: deleteAdsLogo,
        isUploading,
        imageError: adsLogoError,
    } = useImageUpload({
        assetType: AssetType.BUTTON_AD_UNIT,
        observableDataSource: buttonsStore.observed.assets[AssetType.BUTTON_AD_UNIT],
        consumableDataSource: buttonsStore.assets[AssetType.BUTTON_AD_UNIT],
        setStateFunc: setAdsLogoURL,
        customDefaultImage: adsBanner,
        shouldCheckFilesize: true,
        uploadMethod: 'uploadWidgetAssets',
        sizeLimit: 1024,
    });

    return {
        isUploading,
        adsLogo,
        uploadAdsLogo,
        deleteAdsLogo,
        optionData,
        setOptionData,
        adsLogoError,
    };
}

export default useOptions;
