import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import Widget from '../../../components/Widget';
import { CircularProgress, Tooltip } from '../../../components/Wrappers';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';

function DataInsights (props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    titleText = '',
    value = '',
    // isLoading,
    children,
    tooltipId,
    tooltipText,
  } = props;
    return (
        <Widget
        bodyClass={classes.mediaBlockPadding}
        title=""
      >
        <Grid container spacing={1}>
          <div className={classes.mediaIconsWrapper}>
            {tooltipId && (
              <Tooltip
                placement="top"
                color="red"
                disableFocusListener
                disableTouchListener
                title={tooltipText}
                customStyle={{
                  background: 'rgb(163 161 161 / 95%)',
                  fontSize: 13,
                  lineHeight: '1.3',
                  padding: theme.spacing(2),
                }}
              >
                <p className={classes.tooltipText} >
                  <FontAwesome name="exclamation-circle" />
                </p>
              </Tooltip>
            )}
            <div className={classes.iconWrapper}>
              {children}
            </div>
            <div className={classes.mediaIndicator}>
              { value === 'undefined' || value === null
                ? <CircularProgress style={{ marginBottom: 6, width: 20, height: 20 }} />
                : <strong className={classes.valueLabel}>{value}</strong>
              }
              <span>{titleText}</span>
            </div>
          </div>
        </Grid>
      </Widget>
    );
};

export default DataInsights;
