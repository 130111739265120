import {
    get,
    post,
    endpoints,
} from '../../api';

/*
 * URL:
 * https://test-svc.ecal.com/v4/subscribers/json?startDate=2021-01-01&endDate=2021-09-06&scheduleIds=&tz=Asia/Manila
 */
export function apiGetSubscribersData (body, options) {
    const params = 'startDate=' + body.startDate + '&endDate=' + body.endDate + '&scheduleIds=' + body.scheduleIds + '&tz=' + body.tz;
    return post(
        endpoints().subscribers + '/json?' + params,
        body,
        options
    );
}

// https://test-svc.ecal.com/v4/subscribers/csv?startDate=2021-01-01&endDate=2021-09-06&scheduleIds=&tz=Asia/Manila
export function apiGetSubscribersReport (body, options) {
    const params = 'startDate=' + body.startDate + '&endDate=' + body.endDate + '&scheduleIds=' + body.scheduleIds + '&tz=' + body.tz;
    return get(
        endpoints().subscribers + '/csv?' + params,
        options
    );
}

// https://test-svc.ecal.com/v4/analytics/subscriptions/monthly?from=2019-01-28&to=2019-02-28&tz=Australia/Melbourne
export function apiGetMonthlySubscribers (body, options) {
    const params = '?from=' + body.from + '&to=' + body.to + '&tz=' + body.tz;
    return get(
        endpoints().monthlySubscribers + params,
        options
    );
}
