import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    root: {
        display: 'flex,',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '640px',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        padding: '16px',
    },

    stepperContainer: {
        display: 'flex,',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },

    steps: {
        width: '100%',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // backgroundColor: '#FBFBFB',
        marginBottom: '16px',
        minHeight: '200px',
    },

    stepContentRoot: () => {
        return {
            width: '600px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        };
    },

    stepResultContentRoot: () => {
        return {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        };
    },

    input: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    inputMargin: {
        marginBottom: '32px',
    },

    inputTopMargin: {
        marginTop: '16px',
    },

    boxStyling: {
        backgroundColor: '#ffffff',
        padding: 24,
        borderRadius: 4,
        boxShadow: theme.customShadows.widget,
    },

    textInput: {
        width: '100%',
    },

    resultContent: {
        display: 'flex',
        width: '100%',
        minHeight: '400px',
        minWidth: '640px',
        maxHeight: '640px',
        overflow: 'scroll',
        overflowY: 'scroll',
        padding: '24px',
    },

    tableBase: {
        width: '100%',
        padding: '24px',
        minWidth: '800px',
        borderRadius: '4px',
        // backgroundColor: '#FBFBFB',
    },

    defaultCell: {
        display: 'flex',
        justifyContent: 'flex-start',
        maxHeight: '80px',
        minWidth: '200px',
        overflow: 'hidden',
    },

    resultCellText: {
        fontSize: '14px',
    },

    cellPointer: {
        cursor: 'pointer',
    },

    cellNormal: {
        color: 'rgba(0,0,0,.87)',
    },

    cellError: {
        color: '#DF1E34',
    },

    cellWarn: {
        color: '#F08923',
    },

    rowWarn: {
        backgroundColor: '#fffbf0',
    },

    rowError: {
        backgroundColor: '#ffeeeb',
    },

    errorNotificaiton: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '32px',
        paddingLeft: '16px',
        fontSize: '12px',
        backgroundColor: '#ffeeeb',
        color: '#DF1E34',
        borderRadius: '8px',
        marginBottom: '8px',
    },

    warningNotification: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        width: '100%',
        height: '32px',
        fontSize: '12px',
        backgroundColor: '#fffbf0',
        color: '#F08923',
        borderRadius: '8px',
        marginBottom: '16px',
    },

    noData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '16px',
        width: '100%',
        height: '32px',
        fontSize: '20px',
        borderRadius: '8px',
        marginBottom: '16px',
    },

    toolTipColor: {
        backgroundColor: '#DF1E34',
    },

    worksheetSubtitle: {
        display: 'flex',
        flexDirection: 'column',
        color: 'rgba(0,0,0,0.54)',
        fontSize: '12px',
        marginTop: '12px',
        marginLeft: '8px',
    },

    worksheetSubtitleLine: {
        height: '20px',
    },
});

export default styles;
export const useStyles = makeStyles(styles);
