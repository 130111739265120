import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
    st0: {
        stroke: '#FFFFFF',
        fill: 'none',
    },

    st1: {
        fill: '#FFFFFF',
    },

    st2: {
        clipPath: 'url(#SVGID_2_)',
    },

    st3: {
        fill: 'none',
    },

    st4: {
        fontFamily: 'Roboto, sans-serif',
        // fontFamily: 'Open Sans, sans-serif',
    },

    st5: {
        fontSize: '15px',
        // fontWeight: '600',
    },

}));
