import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        overflowX: 'hidden',
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    content: {
        flexGrow: 1,
        padding: 38,
        width: 'calc(100vw - 240px)',
        minHeight: '100vh',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    contentShift: {
        width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    fakeToolbar: {
        ...theme.mixins.toolbar,
    },

});

export default styles;
export const useStyles = makeStyles(styles);
