import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Country, State } from 'country-state-city';
// import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import {
    Grid,
    TextField,
} from '@material-ui/core';

import Select from '../../commons/select/Select';
import { useStyles, chargifyStyles } from './styles';

import { logInfo } from '../../utils';

function countriesForSelect () {
    let c = Country.getAllCountries();
    c = c.map((item) => ({
        value: item.isoCode,
        label: item.name,
    }));
    logInfo('Countries for Selection: ', c);
    return c;
}

export function SummaryEntry (props) {
    const {
        entryText,
        entryPrice,
        highlighted = false,
        hasBorder = true,
    } = props;

    const classes = useStyles({ highlighted, hasBorder });

    return (
        <Grid className={classes.summaryEntry}>
            <Grid className={classes.summaryEntryText}>
                { entryText }
            </Grid>
            <Grid className={classes.summaryEntryPrice}>
                { entryPrice }
            </Grid>
        </Grid>
    );
};

function BillingChargifyForm (props) {
    const {
        billingInputProps,
    } = props;

    const {
        pricingSummary,
        chargifyFormRef,
        // chargify,
        chargifyInput,
        updateChargifyInput,
        errors = {},
        initChargify,
        currencyLabel,
        taxRate,
    } = billingInputProps;

    const [countries] = useState(countriesForSelect());
    const [states, setStates] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        // logInfo('Countries in Timezone: ', moment.tz.zone(tz).countries());
        /* Chargify Load should be an action of billing */
        updateStates(chargifyInput.country);
        initChargify(chargifyStyles);
    }, []);

    function renderPricedAddOns () {
        if (pricingSummary.addOns.length <= 0) {
            return null;
        }

        return (
            <Grid className={classes.summaryAddOns}>
                {
                    pricingSummary.addOns.map((addOn) => {
                        function addOnName () {
                            // if (addOn.handle && addOn.handle.indexOf('monthly') !== -1) {
                            if (addOn.recurring) {
                                return `${addOn.name} (monthly)`;
                            }
                            return addOn.name;
                        }

                        return (
                            <SummaryEntry
                                key={uuid()}
                                entryText={ addOnName()}
                                entryPrice={ addOn.priceValue.toFixed(2) + ' ' + currencyLabel}
                            />
                        );
                    })
                }
            </Grid>
        );
    }

    function updateStates (countryCode) {
        let s = State.getStatesOfCountry(countryCode);
        s = s.map(state => ({
            value: countryCode.toLowerCase() !== 'us' ? state.name : state.isoCode,
            label: state.name,
        }));

        // logInfo('updateStates() -> Country States: ', s);
        setStates(s);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid className={classes.summary}>

                    <SummaryEntry
                        entryText={ pricingSummary.planName + ' Plan' + ' (monthly)'}
                        entryPrice={ pricingSummary.monthly.toFixed(2) + ' ' + currencyLabel }
                    />

                    {renderPricedAddOns()}

                    <SummaryEntry
                        entryText={ 'Total (without tax)'}
                        entryPrice={ pricingSummary.totalPrice.toFixed(2) + ' ' + currencyLabel }
                    />

                    <SummaryEntry
                        entryText={ `GST (${taxRate}%)`}
                        entryPrice={ pricingSummary.taxValue.toFixed(2) + ' ' + currencyLabel }
                    />

                    <SummaryEntry
                         hasBorder={false}
                        highlighted={true}
                        entryText={ 'Today\'s Total'}
                        entryPrice={ pricingSummary.grandTotal.toFixed(2) + ' ' + currencyLabel }
                    />

                    <SummaryEntry
                        entryText={ 'Total Recurring'}
                        entryPrice={ pricingSummary.grandTotalRecurring.toFixed(2) + ' ' + currencyLabel }
                    />

                </Grid>
                <Grid
                    container
                    className={classes.planFormContainer}
                    >
                        <form
                            ref={chargifyFormRef}
                            className={classes.chargifyFields}
                        >
                            <div id='chargifyPanel' className={classes.formContent}> </div>
                            <input type="hidden" data-chargify="address" value={chargifyInput.address}/>
                            <input type="hidden" data-chargify="address2" value={chargifyInput.address2}/>
                            <input type="hidden" data-chargify="city" value={chargifyInput.city}/>
                            <input type="hidden" data-chargify="state" value={chargifyInput.state}/>
                            <input type="hidden" data-chargify="country" value={chargifyInput.country}/>
                            <input type="hidden" data-chargify="zip" value={chargifyInput.zip}/>
                        </form>
                </Grid>

                <Grid className={classes.planFormContainer}>
                    <TextField
                        id="address"
                        label="Address"
                        onChange={(e) => {
                            updateChargifyInput({ address: e.target.value });
                        }}
                        name="address"
                        value={chargifyInput.address}
                        variant="outlined"

                        className={classes.formInput}
                        helperText={errors.address || 'Address'}
                        error={errors.address}
                    />

                    <TextField
                        id="address"
                        label="City"
                        onChange={(e) => {
                            updateChargifyInput({ city: e.target.value });
                        }}
                        name="city"
                        value={chargifyInput.city}
                        variant="outlined"
                        className={classes.formInput}
                        helperText={errors.city || 'City'}
                        error={errors.city}
                    />

                    <Grid className={classes.formInput} >
                        <Select
                            id='country'
                            label= 'Country'
                            name= 'country'
                            value={chargifyInput.country}
                            labelHeading= 'Country'
                            placeHolder= 'Country'
                            handleChange={(e, data) => {
                                logInfo('Select Country: ', data.props.value);
                                updateStates(data.props.value);
                                updateChargifyInput({ country: data.props.value });
                            }}
                            marginBottom={0}
                            dataForDropdown={countries}
                            helperText={errors.country || 'Country'}
                            error={errors.country}
                        />
                    </Grid>

                    <Grid className={classes.formInput} >
                        <Select
                            id='state'
                            label= 'State'
                            name= 'state'
                            value={chargifyInput.state}
                            labelHeading= 'State'
                            placeHolder= 'State'
                            handleChange={(e, data) => {
                                logInfo('Select State: ', data.props.value);
                                updateChargifyInput({ state: data.props.value });
                            }}
                            marginBottom={0}
                            dataForDropdown={states}
                            helperText={errors.state || 'State'}
                            error={errors.country}
                        />
                    </Grid>

                    <TextField
                        id="zip"
                        label="Zip"
                        onChange={(e) => {
                            updateChargifyInput({ zip: e.target.value });
                        }}
                        name="zip"
                        variant="outlined"
                        value={chargifyInput.zip}
                        helperText={errors.zip || 'Zip'}
                        error={errors.zip}
                        className={classes.formInput}
                    />

                </Grid>

            </Grid>
        </React.Fragment>
    );
}

BillingChargifyForm.propTypes = {
    billingInputProps: PropTypes.object,
};

BillingChargifyForm.defaultProps = {
    billingInputProps: {},
};

export default BillingChargifyForm;

/*
 <Grid
                         className={classes.formInput}
                    >
                          <SelectSearch
                            selected={chargifyInput.country ? [chargifyInput.country] : undefined}
                            onSelect={(data) => {
                                updateChargifyInput({ country: data[0] });
                            }}
                            helperText={''}
                            placeHolder='Select country'
                            dataProvider={countries}
                        />
                    </Grid>

 <TextField
                        id="address"
                        label="Country"
                        onChange={(e) => {
                            updateChargifyInput({ country: e.target.value });
                        }}
                        name="country"
                        value={chargifyInput.country}
                        variant="outlined"
                        helperText="Country"
                        className={classes.formInput}
                    />
*/
