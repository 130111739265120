import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = { ...ErrorAPIMessages };

const errorErrors = {
    ...ErrorAPIErrors,
     RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
     RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
};

const customErrorMap = {
    ...errorMap,

    RESOURCE_NOT_FOUND: (err) => {
        return {
            title: 'Resource not found',
            message: err.message,
            text: err.message,
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}
