import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useEnvStore } from '../../store/models';
import { useHistory } from 'react-router';
import {
    getAWSMaketplace,
    setAWSMaketplace,
} from '../../utils';

import { getCouponValue } from '../../conf/chargify';

function useEnvHistory () {
    const env = useEnvStore();
    env.history = useHistory();

    useEffect(() => {
        const location = window.location;
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        window.mode = params.mode;
        env.mode = params.mode;

        /*
        console.log('useEnvHistory() -> ', params);
        console.log('Use Location: ', location);
        console.log('Search -> ', location.search);
        */

        const path = window.location.pathname;

        env.settings = {
            ...params,
            path,
            forVerification: path.indexOf('verifyAccount') !== -1,
        };

        console.log('');
        console.log('');
        console.log('');
        console.log('');
        if (env.settings.plan) {
            env.planSettings = env.settings;
            env.setEnvSettings({ ...env.settings });
        }

        if (env.settings.coupon) {
            env.settings.coupon = getCouponValue(env.settings.coupon);
        }

        console.log('Env Settings -> ', env.settings);
        console.log('Plan -> ', env.settings.plan);
        // aws-marketplace-token

        const awsMP = getAWSMaketplace();
        if (!isEmpty(awsMP) && !isEmpty(awsMP.token)) {
            // try to retrieve token from store
            env.awsMarketplaceToken = awsMP.token;
        }

        if (env.settings['aws-marketplace-token']) {
            // OVERWRITE WHAT EVER TOKEN IS RETRIEVE EVEN ON STORE, token from query is priority and will override the store when available
            env.awsMarketplaceToken = env.settings['aws-marketplace-token'];
            const marketPlaceToken = location.search.split('aws-marketplace-token=')[1];
            env.awsMarketplaceToken = marketPlaceToken;
            console.log('Market Place Token : ', env.awsMarketplaceToken);
            setAWSMaketplace({ token: env.awsMarketplaceToken });
        }

        if (!env.settings['aws-marketplace-token'] && location.pathname.indexOf('/signup') !== -1) {
            // console.log('Force Delete AWS TOKEN ');
            setAWSMaketplace({ token: '' });
            env.awsMarketplaceToken = '';
        }

        console.log('Market Place Token : ', env.awsMarketplaceToken);

        env.observed.settings = uuid();
    }, []);

    return {};
}

export default useEnvHistory;
