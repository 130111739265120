import React from 'react';

import {
    Box,
} from '@material-ui/core';

import { Typography } from '../../../components/Wrappers';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import {
    CreateOutlined as CreateIcon,
    Label as LabelIcon,
} from '@material-ui/icons';

import language from '../../../commons/util/Language.json';
import labelTypes from '../../../commons/util/labelTypes.json';

function getLanguage (value) {
    return language.filter((item) => (item.value === value))[0];
}

function getLabel (value) {
    return labelTypes.filter((item) => (item.value === value))[0];
}

function renderLanguage (value) {
    if (value) {
        const lang = getLanguage(value);
        return lang.label;
    }
    return 'Default';
}

function renderLabel (value) {
    if (value) {
        const lang = getLabel(value);
        return lang.label;
    }
    return '';
}

/*
 * Note:
 * id field in the column matches ff:
 *      the row field or data field from api response
 *      customCell renderers
 */

export const columns = [
    {
        id: 'language',
        numeric: true,
        disablePadding: false,
        label: 'Language',
    },

    {
        id: 'label',
         numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'text',
        numeric: true,
        disablePadding: false,
        label: 'Label Text',
        cellClassName: 'textCell',
    },

    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
];

/* Custom Cells */

function languageCell ({ index, row, column, props }) {
    return (
            <Typography
                variant={'body2'}
            >
                {renderLanguage(row.language)}
            </Typography>
    );
}

function labelCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.nameCell}
                variant={'body2'}
            >
                {renderLabel(row.label)}
            </Typography>
    );
}

function labelTextCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.text}
                variant={'body2'}
            >
                {row.text}
            </Typography>
    );
}

function actionCell ({ index, row, column, props }) {
    const {
        onEdit,
        onDelete,
        classes,
    } = props;

    return (
        <Box
            display={'flex'}
            style={{
                marginLeft: -12,
            }}
            className={classes.actionCell}
        >
            <IconButton
                color={'primary'}
                onClick= { (e) => {
                    e.preventDefault();
                    onEdit(row);
                }}
                ecal-label-button={'edit'}
                className={classes.actionCellIcon}
            >
                <CreateIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    onDelete(row);
                }}
                color={'primary'}
                ecal-label-button={'delete'}
                className={classes.actionCellIcon}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
    );
}

export const customCells = {
    language: languageCell,
    label: labelCell,
    text: labelTextCell,
    actions: actionCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
