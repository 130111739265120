import React from 'react';
import PropTypes from 'prop-types';
import DatePickerBase from 'react-advanced-datetimerange-picker';

import {
    Grid,
    TextField as Input,
} from '@material-ui/core';

import useStyles from './styles';
// import { v4 as uuid } from 'uuid';

import useDatePicker from './useDatePicker';

function DatePicker (props) {
    const {
       helperText,
       placeHolder,
       onDateUpdate,
       useTwoWeeksAsDefault,
    } = props;

    const {
        start,
        end,
        onDateChange,
        ranges,
        local,
        dateDisplay,
        maxDate,
    } = useDatePicker({
        onDateUpdate,
        useTwoWeeksAsDefault,
    });

    const classes = useStyles({ dateDisplay });

    return (
        <Grid className={classes.root}>
            <DatePickerBase
                ranges={ranges}
                start={start}
                end={end}
                local={local}
                maxDate={maxDate}
                applyCallback={onDateChange}

                noMobileMode
                centerMode

                style={{
                    standaloneLayout: {
                        display: 'flex',
                        maxWidth: 'fit-content',
                    },
                }}
            >
                <Input
                    className={classes.inputStyle}
                    value={dateDisplay}
                    InputProps={{
                        readOnly: true,
                        placeholder: placeHolder,
                    }}
                    variant="outlined"
                    helperText={helperText}
                />
            </DatePickerBase>
        </Grid>
    );
}

DatePicker.propTypes = {
      helperText: PropTypes.string,
      placeHolder: PropTypes.string,
      onDateUpdate: PropTypes.func,
};

DatePicker.defaultProps = {
    placeHolder: 'Select date range',
    helperText: 'Please select date',
    onDateUpdate: () => {},
};

export default DatePicker;
