import { useState, useEffect } from 'react';

function useStep (props = {}) {
    const {
        minStep = 1,
        maxStep = 2,
        nextLabels = ['Next', 'Upgrade'],
        backLabels = ['Back', 'Back'],
        titles = ['Step 1', 'Step 2'],
    } = props;

    const [step, setStep] = useState(minStep);

    const [nextLabel, setNextLabel] = useState('');
    const [backLabel, setBackLabel] = useState('');
    const [stepTitle, setStepTitle] = useState('');

    function next () {
        if (step + 1 >= maxStep) {
            setStep(maxStep);
            return;
        }
        setStep(step + 1);
    }

    function back () {
        if (step - 1 <= minStep) {
            setStep(minStep);
            return;
        }
        setStep(step - 1);
    }

    function getLabels () {
        const index = step - 1;

        if (index >= nextLabels.length) {
            return {
                next: nextLabels[nextLabels.length - 1],
                back: backLabels[nextLabels.length - 1],
                title: titles[nextLabels.length - 1],
            };
        }

        if (index <= 0) {
            return {
                next: nextLabels[0],
                back: backLabels[0],
                title: titles[0],
            };
        }

        return {
            next: nextLabels[index],
            back: backLabels[index],
            title: titles[index],
        };
    }

    useEffect(() => {
        const { next, back, title } = getLabels();
        console.log('Next : ', next);
        console.log('Back : ', back);
        setNextLabel(next);
        setBackLabel(back);
        setStepTitle(title);
    }, [step]);

    useEffect(() => {
        return () => {
            setStep(minStep);
        };
    }, []);

    return {
        step,
        next,
        back,
        nextLabel,
        backLabel,
        stepTitle,
    };
}

export default useStep;
