import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './pillOutline.styles';
/* eslint no-tabs: ['error', { allowIndentationTabs: true }] */
const DEFAULT_ICON_SIZE = 44;
const PillOutlineSVG = (props) => {
	const classes = useStyles();
	const {
		width,
		text,
		// iconSize,
		onTextMatrixChange,
	} = props;
	const textRef = useRef();
	const [transformMatrix, setTransformMatrix] = useState('matrix(1 0 0 1 115 28.5142)');

	useEffect(() => {
        onTextMatrixChange(transformMatrix);
    }, [transformMatrix]);

	useEffect(() => {
		if (textRef.current) {
			const text = textRef.current;
			const tbox = text.getBBox();
			const newX = (118 - tbox.width / 2);
			// const newY = ((iconSize - tbox.height) / 2);
			const translation = 'matrix(1 0 0 1 ' + newX + ' 28.5142)';
			setTransformMatrix(translation);
		}
	}, [textRef.current, width, text]);
	return (
		<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
			viewBox='0 0 204 48' style={{ 'enable-background': 'new 0 0 204 48' }} xmlSpace='preserve'>
			<g>
				<path className={classes.st0} d='M23.6,47.4c-12.8,0-23.1-10.5-23.1-23.5S10.9,0.4,23.6,0.4h156.8c12.8,0,23.1,10.5,23.1,23.5
		s-10.4,23.5-23.1,23.5H23.6z'/>
				<path className={classes.st1} d='M180.4,0.9c12.5,0,22.6,10.3,22.6,23s-10.1,23-22.6,23H23.6C11.1,46.9,1,36.6,1,23.9s10.1-23,22.6-23
		L180.4,0.9 M180.4-0.1H23.6C10.6-0.1,0,10.6,0,23.9s10.6,24,23.6,24h156.8c13,0,23.6-10.8,23.6-24S193.4-0.1,180.4-0.1L180.4-0.1z'
				/>
			</g>
			<circle className={classes.st0} cx='23.7' cy='23.9' r='20' />
			<g>
				<g>
					<defs>
						<rect id='SVGID_1_' x='8.7' y='13.9' width='27' height='19' />
					</defs>
					{/* <use xlinkHref='#SVGID_1_' style={{ 'overflow': 'visible', 'fill': '#FFFFFF' }} /> */}
					<clipPath id='SVGID_2_'>
						<use xlinkHref='#SVGID_1_' style={{ 'overflow': 'visible' }} />
					</clipPath>
					<g className={classes.st2}>
						<path className={classes.st1} d='M22.7,16.8h-6.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.4
				c0,0.1,0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0.1h6.5c0.1,0,0.3,0,0.4-0.1
				c0.1,0,0.2-0.1,0.3-0.2s0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
				C22.9,16.8,22.8,16.8,22.7,16.8z'/>
						<path className={classes.st1} d='M34.8,13.9H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
				s0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h16.6c0.2,0,0.5-0.1,0.6-0.3
				c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6C35.2,14,35,13.9,34.8,13.9z'/>
						<path className={classes.st1} d='M31.3,31.1H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
				s0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h13.1c0.2,0,0.5-0.1,0.6-0.3
				c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6C31.8,31.2,31.5,31.1,31.3,31.1z'/>
						<path className={classes.st1} d='M22.5,25.3h-6.6c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3
				h6.6c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2s0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.2-0.2-0.3
				c-0.1-0.1-0.2-0.1-0.3-0.2C22.7,25.3,22.6,25.3,22.5,25.3z'/>
						<path className={classes.st1} d='M24.2,19.6H11.6c-0.2,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.7,0.3h12.6
				c0.2,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6S24.5,19.6,24.2,19.6z'/>
						<path className={classes.st1} d='M28.6,22.5H18.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
				s0,0.2,0.1,0.4s0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1h10.4c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2
				s0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4s0-0.2-0.1-0.4s-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2C28.9,22.5,28.8,22.5,28.6,22.5
				z'/>
						<path className={classes.st1} d='M20.2,28.2H9.7c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3
				h10.6c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6C20.7,28.3,20.5,28.2,20.2,28.2z'/>
					</g>
				</g>
			</g>
			<rect x='55.9' y='17.5' className={classes.st3} width='129.1' height='13.5' />
			<text ref={textRef} transform={transformMatrix} className={classNames([classes.st1, classes.st4, classes.st5])}>{props.text}</text>
		</svg>

	);
};

PillOutlineSVG.propTypes = {
	width: PropTypes.number,
	text: PropTypes.string,
	iconSize: PropTypes.number,
	onTextMatrixChange: PropTypes.func,
};

PillOutlineSVG.defaultProps = {
	width: 250,
	text: 'Sync to Calendar',
	iconSize: DEFAULT_ICON_SIZE,
	onTextMatrixChange: () => {},
};

export default PillOutlineSVG;
