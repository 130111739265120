import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

const TableSkeleton = () => {
    return (
      <Grid container style={{
        padding: '12px',
        rowGap: '6px',
      }}>
        <Skeleton variant="rectangular" width={'100%'} height={18} />
        <Skeleton variant="rectangular" width={'90%'} height={18} />
        <Skeleton variant="rectangular" width={'80%'} height={18} />
        <Skeleton variant="rectangular" width={'70%'} height={18} />
        <Skeleton variant="rectangular" width={'60%'} height={18} />
      </Grid>
    );
};

export default TableSkeleton;
