import React from 'react';
import {
    // Grid,
    Box,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function TreeSkeleton () {
    return (
        <Box
        flexDirection={'column'}
        width={'200px'}
        display='flex'>
            <Skeleton width="20%" />
            <Skeleton width="40%" />
            <Skeleton width="30%" />
            <Skeleton width="60%" />
            <Skeleton width="20%" />
            <Skeleton width="50%" />
            <Skeleton width="40%" />
            <Skeleton width="70%" />
            <Skeleton width="40%" />
        </Box>
    );
}
