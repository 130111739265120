import { useState, useEffect } from 'react';
import { useEnvStore } from '../../store/models';

function useFeatures () {
    const envStore = useEnvStore();

    function initialState () {
        const defaultState = {
            billingActive: envStore.features.billing_v4.active || false,
            getStartGuideActive: envStore.features.getStartGuide_v4.active || true,
            videoLibraryActive: envStore.features.videoLibrary_v4.active || false,
            eventDisplaySelectScheduleActive: envStore.features.event_v2_display_select_schedule_option.active || true,
            eventDisplayV2: envStore.features.event_v2_display.active || false,
            schedulesV2: envStore.features.schedules_v2.active || false,
        };
        return defaultState;
    }
    const [features, setFeatures] = useState(initialState());

    /*
     * Note: It is always better to use the consumable state on component level.
     * As can be seen on billing.
     */
    function validateFeatures () {
        setFeatures(initialState());
    }

    useEffect(() => {
        validateFeatures(); // add more validation here for other features in the future.
    }, []);

    return {
        featuresSource: {
            ...envStore.features,
        },

        features,
    };
}

export default useFeatures;
