import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';

import ImageUploader from '../../../../commons/imageUploader/ImageUploader';
import SelectSearch from '../../../../commons/ecal/singleselectsearch';
import Language from '../../../../commons/util/Language.json';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';

function languageWithAutoDetect () {
    return Language.reduce((current, lang, index) => {
        if (index === 0) {
            return [{
                value: 'auto',
                label: 'Auto-Detect',
            }];
        }
        return [
            ...current,
            lang,
        ];
    }, []);
}

const AUTO_DETECT_TEXT = 'Select \'Auto Detect\' option and your button display will appear in the user\'s detected language.';

function Logo (props) {
    const classes = useStyles();

    const {
        designData,
        setDesignData,
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,
        buttonLogoError,
    } = props;

    return (
        <React.Fragment>
                <Grid
                    container
                    item
                    className={classes.sectionRoot}>

                        <Grid className={classes.sectionContent}>
                            <Grid className={classes.input}>
                                <Grid
                                    container
                                    item
                                    className={classes.logoSpace}
                                    >
                                        <Grid className={classes.logoTitle}>
                                            <Typography
                                                    variant={'body1'}
                                                    // weight='medium'
                                                >
                                                    Logo
                                            </Typography>
                                        </Grid>

                                        <ImageUploader
                                            layoutDesign={'row'}
                                            hasCreate={true}
                                            hasDelete={true}
                                            imageData={buttonLogo}
                                            createLabel={'Upload'}
                                            imageHeight={'auto'}
                                            onImageSelected={(files) => {
                                                uploadButtonLogo(files);
                                            }}
                                            onImageDelete={() => {
                                              console.log('event ads logo img deleted?');
                                              deleteButtonLogo();
                                            }}
                                        />
                                        { buttonLogoError &&
                                          (
                                            <Grid>
                                              <Typography
                                                variant={'caption'}
                                                weight={'medium'}
                                                style={{ marginBottom: 30, color: 'red' }}
                                              >
                                                {buttonLogoError}
                                              </Typography>
                                            </Grid>
                                          )
                                        }
                                </Grid>

                            </Grid>

                            <Grid className={classes.input}>
                                <Grid
                                    container
                                    item
                                    className={classes.logoSpace}
                                    >
                                        <Grid className={classes.logoTitle}>
                                            <Typography
                                                    variant={'body1'}
                                                    // weight='medium'
                                                >
                                                    Language
                                            </Typography>
                                        </Grid>

                                        <SelectSearch
                                            dataIntercomTarget='event-display-language'
                                            selected={designData.language ? [designData.language] : undefined}
                                            onSelect={(data) => {
                                                setDesignData({
                                                    ...designData,
                                                    language: data[0],
                                                });
                                            }}
                                            helperText={(
                                                <Typography
                                                    variant={'sub1'}
                                                >
                                                    <b> Auto-detection! </b>
                                                    { AUTO_DETECT_TEXT }
                                                </Typography>
                                            )}
                                            placeHolder='Default Language'
                                            dataProvider={languageWithAutoDetect()}
                                        />

                                </Grid>

                            </Grid>

                        </Grid>

                </Grid>
        </React.Fragment>
    );
};

Logo.propTypes = {
    setStyleData: PropTypes.func,
    uploadButtonLogo: PropTypes.func,
    deleteButtonLogo: PropTypes.func,
    styleData: PropTypes.object,

    buttonLogo: PropTypes.any,
    imageError: PropTypes.bool,
};

Logo.defaultProps = {
    setStyleData: () => {},
    uploadButtonLogo: () => {},
    deleteButtonLogo: () => {},
    styleData: {},
    buttonLogo: null,
    imageError: false,
};

export default Logo;
