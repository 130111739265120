import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import {
    useSchedulesStore,
    // useUserStore,
    // useErrorStore,
} from '../../../store/models/index';

function useCategoryInput () {
    // const {
    // } = props;

    // const userStore = useUserStore();
    // const errStore = useErrorStore();
    const schStore = useSchedulesStore();
    const [checked, setChecked] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [menu, setMenu] = useState({
        anchor: null,
        open: false,
    });

    useEffect(() => {
        if (schStore.categoryData && schStore.categoryData.checked) {
            console.log('checked is updated with: ', schStore.categoryChecked);
            setChecked(cloneDeep(schStore.categoryChecked));
        }
    }, [schStore.categoryData.checked]);

    useEffect(() => {
        if (schStore.categoryData && schStore.categoryData.nodes) {
            setNodes(cloneDeep(schStore.categoryNodes));
        }
    }, [schStore.categoryData.nodes]);

    useEffect(() => {
        if (schStore.categoryData && schStore.categoryData.nodesFiltered) {
            setNodes(cloneDeep(schStore.categoryNodesFiltered));
        }
    }, [schStore.categoryData.nodesFiltered]);

    useEffect(() => {
        if (schStore.categoryData && schStore.categoryData.expanded) {
            setExpanded(cloneDeep(schStore.categoryExpanded));
        }
    }, [schStore.categoryData.expanded]);

    function onFilterChange (e) {
        schStore.filterText = e.target.value;
        schStore.filterTree();
    };

    return {
        checked,
        setChecked,
        nodes,
        setNodes,
        expanded,
        setExpanded,
        selectedCategory,
        setSelectedCategory,
        openAddDialog,
        setOpenAddDialog,
        openDeleteDialog,
        setOpenDeleteDialog,
        menu,
        setMenu,
        onFilterChange,
    };
}

export default useCategoryInput;
