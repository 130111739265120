import React from 'react';
import { legacyFormat } from '../../../commons/util/date';

import {
    Box,
    Grid,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import { Button, Link, Typography } from '../../../components/Wrappers';

import {
    Lock,
    Label as LabelIcon,
    Delete,
} from '@material-ui/icons';

export const columns = [
    {
        id: 'appName',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },

    {
        id: 'id',
         numeric: true,
        disablePadding: false,
        label: 'Key',
        cellClassName: 'keyCell',
    },

    {
        id: 'secretIcon',
        numeric: true,
        disablePadding: false,
        label: 'Secret',
        cellClassName: 'secretIconCellCol',
    },

    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        cellClassName: 'statusCell',

        cellLabelFunc: ({ row, props }) => {
            const {
                classes,
            } = props;

            if (row.status.toLowerCase() === 'active') {
                return (<span
                            className={classes.statusGreen}>
                            {row.status}
                        </span>);
            }

            return (<span
                className={classes.statusRed}>
                {row.status}
            </span>);
        },
    },

    {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'Created On',
        cellClassName: 'createdCell',
        cellLabelFunc: ({ row }) => {
            const date = new Date(row.created);
            return legacyFormat(date);
        },
    },

    {
        id: 'deactivate',
        numeric: true,
        disablePadding: false,
        label: '',
    },
];

/* Custom Cells */

function nameCell ({ index, row, column, props }) {
    return (
            <Typography
                variant={'body2'}
            >
                {row.appName}
            </Typography>
    );
}

function secretCell ({ index, row, column, props }) {
    const {
        classes,
    } = props;

    return (
            <Typography
                className={classes.secretCell}
                variant={'body2'}
            >
                {row.secret}
            </Typography>
    );
}

function secretIconCell ({ index, row, column, props }) {
    const {
        classes,
        onShowSecretKey,
    } = props;

    return (
        <Grid
            className={classes.secretIconCell}
        >
             <IconButton
                color={'primary'}
                onClick= { (e) => {
                    e.preventDefault();
                    if (typeof onShowSecretKey === 'function') {
                         onShowSecretKey(row);
                    }
                }}
                className={classes.secretIcon}
            >
                <Lock
                    fontSize={'small'}
                />
            </IconButton>
        </Grid>
    );
}

function deactivateCell ({ index, row, column, props }) {
    const {
        onDeactivate,
        onActivate,
        onDelete,
        classes,
        showDeactivate,
    } = props;

    function renderDeactivate () {
        if (showDeactivate === false) {
            return;
        }
        return (
            <Button
                onClick= { (e) => {
                    e.preventDefault();
                    if (typeof onDeactivate === 'function') {
                        onDeactivate(row);
                    }
                }}
                variant={'link'}
                color={'primary'}
            >
                <Link > DEACTIVATE </Link>
            </Button>
        );
    }

    function renderActivate () {
        return (
            <Grid
                className={classes.activateCell}
                >
                <Button
                    onClick= { (e) => {
                        e.preventDefault();
                        if (typeof onActivate === 'function') {
                            onActivate(row);
                        }
                    }}
                    variant={'link'}
                    color={'primary'}
                >
                    <Link > ACTIVATE </Link>
                </Button>

                <IconButton
                    color={'primary'}
                    onClick= { (e) => {
                        e.preventDefault();
                        if (typeof onDelete === 'function') {
                            onDelete(row);
                        }
                    }}
                    className={classes.deleteIcon}
                >
                <Delete
                    fontSize={'small'}
                />
            </IconButton>
            </Grid>
        );
    }

    function renderCell () {
        if (row.status === 'active') {
            return renderDeactivate();
        }
        return renderActivate();
    }

    return (
        <Grid
            className={classes.actionCell}
        >
           { renderCell()}
        </Grid>
    );
}

export const customCells = {
    appName: nameCell,
    secret: secretCell,
    secretIcon: secretIconCell,
    deactivate: deactivateCell,
};

function titleIcon (props) {
    return (
        <Box ml={2} mt={'4px'} mr={3} >
            <LabelIcon fontSize={'default'} />
        </Box>
    );
}

export const options = {
    titleIcon: titleIcon,
};
