import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
    Grid,
    Tab,
    // Box,
} from '@material-ui/core';

import {
    EditAttributes as ButtonDesignIcon,
    EventAvailable as SchedulesIcon,
    Brush as PopUpIcon,
    HowToReg as SubscriberDataIcon,
    Dehaze as OptionsIcon,
} from '@material-ui/icons';
import Tabs from '../../../commons/tabs';
import ButtonDesign from './buttonDesign';
import Schedules from './schedules/Schedules';
import PopupDesign from './popupDesign';
import SubscriberData from './subscriberData/';
import Options from './options';

import useModuleInput from './useModuleInput';

function Inputs (props) {
    const {
        location,
    } = props;

    const {
        input,
        mode,
        updateSavable,
        copySavableToInput,
        saveDisabled,
        progress,
        upsert,
        errors,
        hasError,
        hasInputError,
        updateOptions,
        loadCategories,
    } = useModuleInput({
        location,
    });

    const [tab, setTab] = useState(0);
    const [lastDataLoad, setLastDataLoad] = useState(moment().calendar());

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Tabs
                    onTabChange={(newTab) => {
                        console.log('Tab ', newTab);
                        setTab(newTab);
                    }}
                >
                    <Tab
                        label="Button Design"
                        icon={<ButtonDesignIcon />}
                        data-intercom-target='display-input-button-design'
                        // classes={{ wrapper: classes.icon }}
                    />

                    <Tab
                        label="Schedules"
                        icon={<SchedulesIcon />}
                        // classes={{ wrapper: classes.icon }}
                        data-intercom-target='display-input-schedules'
                    />
                    <Tab
                        label="Pop-up Design"
                        icon={<PopUpIcon />}
                        data-intercom-target='display-input-popup-design'
                        // classes={{ wrapper: classes.icon }}
                    />
                    <Tab
                        label="Subscriber Data"
                        icon={<SubscriberDataIcon />}
                        data-intercom-target='display-input-subscriber-data'
                        // classes={{ wrapper: classes.icon }}
                    />
                    <Tab
                        label="Options"
                        icon={<OptionsIcon />}
                        data-intercom-target='display-input-options'
                        // classes={{ wrapper: classes.icon }}
                    />
                </Tabs>
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid item justify={'center'} container>
                            <ButtonDesign
                                location={location}
                                dataSource={input}
                                mode={mode}
                                /* will be notified on button Design Unmount... */
                                updateSavable={updateSavable}
                                updateOptions={updateOptions}
                                onDataChange={(data) => {
                                    copySavableToInput();
                                }}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                                errors={errors}
                                hasError={hasError}
                                hasInputError={hasInputError}
                                />
                        </Grid>
                    )}

                    { tab === 1 && (
                        <Grid item justify={'center'} container>
                            <Schedules
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={(data) => {
                                    copySavableToInput();
                                }}
                                loadCategories={loadCategories}
                                lastDataLoad={lastDataLoad}
                                setLastDataLoad={setLastDataLoad}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                            />
                        </Grid>
                    )}

                    { tab === 2 && (
                        <Grid item justify={'center'} container>
                            <PopupDesign
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={() => {
                                    copySavableToInput();
                                }}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                            />
                        </Grid>
                    )}

                    { tab === 3 && (
                        <Grid item justify={'center'} container>
                            <SubscriberData
                                dataSource={input}
                                updateSavable={updateSavable}
                                onDataChange={() => {
                                    console.log('');
                                    console.log('');
                                    console.log('unmounting() SubscriberData');
                                    copySavableToInput();
                                }}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                            />
                        </Grid>
                    )}

                    { tab === 4 && (
                        <Grid item justify={'center'} container>
                            <Options
                                dataSource={input}
                                updateSavable={updateSavable}
                                /* will only be notified on unmount(tab change) so that no wasteful processing */
                                onDataChange={() => {
                                    console.log('');
                                    console.log('');
                                    console.log('unmounting() Options');
                                    copySavableToInput();
                                }}
                                saveDisabled={saveDisabled}
                                onSave={() => {
                                    upsert();
                                }}
                                progress={progress}
                                errors={errors}
                                hasError={hasError}
                                hasInputError={hasInputError}
                            />
                        </Grid>
                    )}

                </Grid>
            </Grid>

        </React.Fragment>

    );
};

export default observer(Inputs);
