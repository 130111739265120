import React, { useState, useEffect } from 'react';
import { scrollToTop, logInfo } from '../../../utils';
import {
    useButtonsStore,
    useAPIKeyStore,
} from '../../../store/models';

import useStoreModuleDependency from '../../hooks/useStoreModuleDependency';

import { useHistory } from 'react-router';
import EventPreviewDialog from './eventPreviewDialog';

function useModuleList (props) {
    const {
        columns,
    } = props;
    const history = useHistory();
    const [visibleColumns, setVisibleColumns] = useState(columns);
    const apikeyStore = useAPIKeyStore();
    const buttonsStore = useButtonsStore();
    const [listData, setListData] = useState([]);

    const [previewSettings, setPreviewSettings] = useState({
        apiKey: '',
        data: {},
        mode: 'calendar',
    });

    const [progress, setProgress] = useState({
        buttons: false,
    });

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(false);
    const [pageInfo, setPageInfo] = useState(buttonsStore.pageInfo);
    const [selectedRows, setSelectedRows] = useState([]);

    const [eventPreviewData, setEventPreviewData] = useState({});
    const [openEventPreview, setOpenEventPreview] = useState(false);

    useEffect(() => {
        if (apikeyStore.apikeys && apikeyStore.apikeys.length > 0) {
            // setActiveAPIKey(apikeyStore.apikeys[0].id);
            setPreviewSettings({
                ...previewSettings,
                apiKey: apikeyStore.apikeys[0].id,
            });
        }
    }, [apikeyStore.observed.apikeys]);

    useEffect(() => {
        if (buttonsStore.buttons) {
            setListData(buttonsStore.buttons);
            setPageInfo({
                ...pageInfo,
                ...buttonsStore.pageInfo,
            });
        }
        setProgress({ ...progress, buttons: false });
    }, [buttonsStore.observed.buttons]);

    function initStore () {
        setProgress({ ...progress, buttons: true });
        apikeyStore.getAPIKeys();
        const type = 'event';
        buttonsStore.getButtons(type);
        scrollToTop();
    }

    useStoreModuleDependency({ initStore });

    function updateEditData (data) {
        buttonsStore.saveEditData(data);
    }

    function updatePageInfo (info) {
        buttonsStore.pageInfo = {
            ...pageInfo,
            ...info,
        };
        setPageInfo({
            ...buttonsStore.pageInfo,
        });
        setProgress({
            ...progress,
            buttons: true,
        });
        const type = 'event';
        buttonsStore.getButtons(type);
    }

    function saveEventDisplayData (data) {
        console.log('eventDisplay.useModuleList().saveEditData()', data);
        buttonsStore.saveEventDisplayData(data);
        history.push('/admin/displays/event/edit/' + data.id);
    }

    function makeDraft () {
        setProgress({
            ...progress,
            buttons: true,
        });
         buttonsStore.makeWidgetsDraft(selectedRows, 'event');
    }

    function makeLive () {
        setProgress({
            ...progress,
            buttons: true,
        });
        buttonsStore.makeWidgetsLive(selectedRows, 'event');
    }

    function deleteSelection () {
        setProgress({
            ...progress,
            buttons: true,
        });
        console.log('deleteSelection():', selectedRows);
        buttonsStore.deleteWidgets(selectedRows, 'event');
    }

    function addEvent () {
        history.push('/admin/displays/add/event');
    }

    function deleteSingle () {
        buttonsStore.deleteWidgets([deleteTarget.id], 'event');
    }

    function showEventPreview (data, mode) {
        logInfo('showEventPreview() mode: ', mode);
        setPreviewSettings({
            ...previewSettings,
            data: { ...data },
            mode: mode,
        });
        setEventPreviewData(data?.settings?.directLinks?.socialMediaDirectLink);
        setOpenEventPreview(true);
  }

    function renderEventPreviewDialog () {
      return (
        <EventPreviewDialog
            previewSettings={previewSettings}
            eventPreviewIframeSrc={eventPreviewData}
            open={openEventPreview}
            onClose={() => {
                setOpenEventPreview(false);
            }}
        />
      );
    }

    return {
        listData,
        progress,
        updateEditData,

        deleteOpen,
        deleteTarget,
        setDeleteOpen,
        setDeleteTarget,
        pageInfo,
        updatePageInfo,

        selectedRows,
        setSelectedRows,

        visibleColumns,
        setVisibleColumns,
        saveEventDisplayData,
        makeDraft,
        makeLive,
        deleteSelection,
        addEvent,
        deleteSingle,
        showEventPreview,
        renderEventPreviewDialog,
    };
}

export default useModuleList;
