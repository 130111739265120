import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Grid,
    TextField,
    Switch,
} from '@material-ui/core';
import {
    Typography,
    // Button,
} from '../../../../components/Wrappers';
// import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './styles';
import useDebounceTextUpdates from '../../../hooks/useDebounceTextUpdates';

const INSTRUCTION = 'Customise the message, handle and URL you would like users to share when they click the "Share" button after subscribing.';

function Sharing (props) {
    const classes = useStyles();

    const {
        optionData,
        setOptionData,
    } = props;

    const [helpText, setHelpText] = useState('Enabled');

    useEffect(() => {
        if (optionData?.sharing?.active) {
            setHelpText('Enabled');
        } else {
            setHelpText('Disabled');
        }
    }, [optionData]);

    const {
        value: messageURL,
        onTextChange: handleMessageURL,
    } = useDebounceTextUpdates({
        textSource: optionData?.sharing?.url,
        onStateUpdate: (v) => {
            setOptionData({
                ...optionData,
                sharing: {
                    ...optionData.sharing,
                    url: v,
                },
            });
        },
    });

    const {
        value: message,
        onTextChange: handleMessage,
    } = useDebounceTextUpdates({
        textSource: optionData?.sharing?.message,
        onStateUpdate: (v) => {
            setOptionData({
                ...optionData,
                sharing: {
                    ...optionData.sharing,
                    message: v,
                },
            });
        },
    });

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRootNoBottomPad}>
                    <Grid className={classes.sectionTitle}>
                        <Typography
                            variant='h6'
                            weight='medium'
                            >
                           Sharing
                        </Typography>
                    </Grid>
                    <Grid className={classes.sectionDescription}>
                        <Typography block >
                                {INSTRUCTION}
                        </Typography>
                    </Grid>

                    <Grid className={classes.switchControl}>
                    <Switch
                        checked={optionData.sharing.active}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setOptionData({
                            ...optionData,
                            sharing: {
                              ...optionData.sharing,
                              active: !optionData.sharing.active,
                            },
                          });
                        }}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                        <Typography variant='body2'>
                            { helpText }
                        </Typography>
                    </Grid>

                    <Grid
                        justify={'center'}
                        className={classes.sectionContent}
                        >
                            <Box
                                className={classes.inputBox}>

                                <TextField
                                    InputProps={{
                                        className: classes.messageInput,
                                    }}

                                    id="sharing"
                                    label="Sharing"
                                    multiline
                                    rows="7"

                                    value={message}
                                    onChange={(e) => {
                                        handleMessage(e.target.value);
                                    }}

                                    name="sharing"
                                    variant="outlined"

                                    /*
                                    helperText={ errors.text || '*Enter label text for this custom-label'}
                                    error={errors.text}
                                    */
                                    style={{ marginBottom: 32 }}
                                />

                                <TextField
                                    InputProps={{
                                        className: classes.textInput,
                                    }}

                                    id="message"
                                    label="Share Message URL"

                                    value={messageURL}
                                    onChange={(e) => {
                                        handleMessageURL(e.target.value);
                                    }}

                                    name="shareURL"
                                    variant="outlined"
                                    style={{ marginBottom: 32 }}
                                    /*
                                    helperText={ errors.text || '*Enter label text for this custom-label'}
                                    error={errors.text}
                                    */
                                />
                            </Box>

                    </Grid>
            </Grid>
        </React.Fragment>
    );
};

Sharing.propTypes = {
    setOptionData: PropTypes.func,
    optionData: PropTypes.any,
};

Sharing.defaultProps = {
    setOptionData: () => {},
    optionData: {},
};

export default Sharing;
