import { validateInputError } from '../../error/utils';

function validateName (input) {
    if (!input.name || input.name.length === 0) {
        return 'Name is required';
    }
    return '';
}

const validations = {
    name: validateName,
};

export function inputValidator (input) {
    return validateInputError(input, validations);
}
