import React from 'react';

import {
    Menu,
    MenuItem,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import Notification from '../Notification/Notification';

// styles
import useStyles from './styles';

function NotficationsMenu (props) {
    const {
        onClose,
        onSelectNotification,
        anchorEl,
        open,
        notifications,
    } = props;
    const classes = useStyles();

    return (
        <Menu
            id="notifications-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={() => onClose()}
            className={classes.headerMenu}
            disableAutoFocusItem
        >
        {notifications.map(notification => (
          <MenuItem
            key={notification.id}
            onClick={() => onSelectNotification()}
            className={classes.headerMenuItem}
          >
            <Notification {...notification} typographyVariant="inherit" />
          </MenuItem>
        ))}
      </Menu>
    );
}

NotficationsMenu.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSelectNotification: PropTypes.func,
    anchorEl: PropTypes.any,
    notifications: PropTypes.array,
};

NotficationsMenu.default = {
    open: false,
    onClose: () => {},
    onSelectNotification: () => {},
    anchorEl: null,
    notifications: [],
};

export default NotficationsMenu;
