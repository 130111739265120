import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { logInfo } from '../../../utils';
import {
    Grid,
    Tab,
    // Box,
} from '@material-ui/core';

import {
    ViewDay as ButtonDisplaysIcon,
    ViewCarousel as AdDisplaysIcon,
    ViewList as EventDisplaysIcon,
} from '@material-ui/icons';
import Tabs from '../../../commons/tabs';
import ButtonDisplays from '../buttonsList';
import AdDisplays from '../adDisplayList';
import EventDisplays from '../eventsDisplayList';
import useTabByPath from '../../hooks/useTabByPath';
import useProductTour from '../../hooks/useProductTour';
import { useButtonsStore, useEnvStore } from '../../../store/models';
import { Badge } from '../../../components/Wrappers';

function ManageDisplays (props) {
    const envStore = useEnvStore();
    const {
        tab,
        setTab,
    } = useTabByPath({
        mapping: {
            '/admin/displays/button': 0,
            '/admin/displays/ad': 1,
            '/admin/displays/event': 2,
        },
        parentPath: 'admin/displays',
        onTour: envStore.onTour,
    });
    const buttonsStore = useButtonsStore();
    const [tabDisabled, setTabDisabled] = useState(true);
    useEffect(() => {
        // always clear the buttons list initially...
        buttonsStore.buttons = [];
    }, []);

    useProductTour({
        isDataReadyForTheTour: () => {
            logInfo('manageDisplay.isDateReadyForTheTour()', buttonsStore.buttons);
             // this function is necessary to know whether to start tour or not based on readiness of data dependency
            if (!isEmpty(buttonsStore.buttons)) {
                return true;
            }
            return false;
        },
        isStateDependent: true,
        observableState: buttonsStore.observed.buttons,
    });

    useEffect(() => {
        if (buttonsStore.observed.buttons) {
            setTabDisabled(false);
        }
    }, [buttonsStore.observed.buttons]);

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Tabs
                    tabIndex={tab}
                    onTabChange={(newTab) => {
                        if (tab !== newTab) {
                            setTabDisabled(true);
                            envStore.abortAllRequests();
                            setTab(newTab);
                        }
                    }}
                >
                    <Tab
                        data-intercom-target="button-display-tab"
                        label="Button Displays"
                        icon={<ButtonDisplaysIcon />}
                        disabled={tabDisabled}
                    />

                    <Tab
                        data-intercom-target="ad-display-tab"
                        label="Ad Displays"
                        icon={<AdDisplaysIcon />}
                        disabled={tabDisabled}
                    />

                    <Tab
                        data-intercom-target="event-display-tab"
                        label="Event Displays"
                        icon={<EventDisplaysIcon />}
                        disabled={tabDisabled}
                    />

                    <Badge
                        style={{
                            right: '20px',
                            top: '10px',
                        }}
                        badgeContent={'New'}
                        color={'secondary'}
                    />
                </Tabs>
                <Grid item xs={12}>

                    { tab === 0 && (
                        <Grid item justify={'center'} container>
                            <ButtonDisplays />
                        </Grid>
                    )}

                    { tab === 1 && (
                        <Grid item justify={'center'} container>
                            <AdDisplays />
                        </Grid>
                    )}

                    { tab === 2 && (
                        <Grid item justify={'center'} container>
                            <EventDisplays />
                        </Grid>
                    )}
                </Grid>
            </Grid>

        </React.Fragment>

    );
};

export default observer(ManageDisplays);
