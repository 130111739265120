import { validateError } from './error_map';

export function handlePublisherError ({ response: res, action, type }) {
    if (res &&
        res.data &&
        res.data.errors &&
        res.data.errors.length > 0) {
        const errors = res.data.errors;
        const err = validateError(errors[0], { action, type });
        console.log('handlePublisherError() HasError:True: ', err);
        if (err !== null) {
            this.publisherError = {};
            this.publisherError[action] = err;
        }

        return {
            hasError: true,
        };
    }

    return {
        hasError: false,
    };
}
