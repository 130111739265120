import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
    Grid,
    Box,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import {
    Typography,
} from '../../../components/Wrappers/Wrappers';
// import ScheduleImage from '../../../commons/images/schedule/placeholder.png';
import ImageUploader from '../../../commons/imageUploader/ImageUploader';
import ButtonProgress from '../../../commons/ButtonProgress';
import Select from '../../../commons/select/Select';
import useStyles from '../schedule.styles';

const EntryForm = (props) => {
    const {
        name,
        setName,
        reference,
        setReference,
        saveSchedule,
        title,
        errors,
        imageError,
        uploadImage,
        logoURL,
        logoUploadProgress = false,
        deleteLogo,
        isLoading,
        logoImageError,
        setIsDirty,
        saveDisabled,
        schedulesV2,
        scheduleTypes,
        updateInput,
        input,

        genres,
        subGenres,
        showSubGenres,

        isChild,
    } = props;
    const classes = useStyles();

    const renderError = (error) => (
      <Typography
          variant={'caption'}
          weight={'medium'}
          style={{ marginBottom: 30, color: 'red' }}
      >
          {error}
      </Typography>
    );

    return (
        <Grid item className={classes.mainContainer} justify={'center'} container>
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={600}
                className={classes.boxStyling}
            >
                <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 30 }}
                >
                    { title }
                </Typography>
                {isChild && <Typography block>
                    This schedule is managed by a child account.
                </Typography>}
                <Typography
                    // variant={'body1'}
                    // weight={'medium'}
                    block
                    style={{ marginBottom: 30 }}
                    disabled={isChild}
                >
                    Your events are organised into schedules. Users subscribe to these to keep up-to-date with your events.
                </Typography>

                <TextField
                    id="schedule-name"
                    label="Name"
                    // onChange={handleChange}
                    name="scheduleName"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    error={errors.name}
                    helperText={ errors.name || 'Please enter a schedule name, as you want it to appear on your display'}
                    disabled={isChild}
                />

                { schedulesV2 && (
                    <Grid
                        style={{ marginBottom: 30 }}
                    >
                        <Select
                            marginBottom={'0px'}
                            id='age'
                            name='age'
                            value={input.type}
                            labelHeading= 'Schedule Types'
                            placeHolder='Schedule Type'
                            handleChange={(e, data) => {
                                console.log(' value: ', data.props.value);
                                updateInput({
                                    type: data.props.value,
                                });
                            }}

                            dataForDropdown={scheduleTypes}
                            helperText={'Select the schedule type.'}
                            disabled={isChild}
                        />
                    </Grid>
                )}

                <TextField
                    id="reference"
                    label="Calendar Reference"
                    // onChange={handleChange}
                    name="reference"
                    value={reference}
                    onChange={(e) => {
                        setReference(e.target.value);
                    }}
                    variant="outlined"
                    style={{ marginBottom: 35 }}
                    helperText="Optional. This is used by developers when connecting to an API feed, eg: {seasonID}-{eventID}"
                    disabled={isChild}
                />

                <Grid
                    style={{ marginBottom: 30 }}
                    data-intercom-target={'schedule-category-genre'}
                >
                    <Select
                        marginBottom={'0px'}
                        id='genre'
                        name='genre'
                        value={input.genre}
                        labelHeading= 'Genre'
                        placeHolder='Genre'
                        handleChange={(e, data) => {
                            console.log(' value: ', data.props.value);
                            updateInput({
                                genre: data.props.value,
                                subGenre: '',
                            });
                        }}
                        dataForDropdown={genres}
                        error={errors.genre}
                        helperText={errors.genre || 'Please select the genre that best suits the events'}
                        disabled={isChild}
                    />
                </Grid>

                { showSubGenres && (
                    <Grid
                        style={{ marginBottom: 30 }}
                    >
                        <Select
                            marginBottom={'0px'}
                            id='sub-genre'
                            name='subGenre'
                            value={input.subGenre}
                            labelHeading= 'Sub-Genre'
                            placeHolder='Sub-Genre'
                            handleChange={(e, data) => {
                                console.log(' value: ', data.props.value);
                                updateInput({
                                    subGenre: data.props.value,
                                });
                            }}
                            dataForDropdown={subGenres}
                            error={errors.subGenre}
                            helperText={errors.subGenre || 'Please select a sub-genre'}
                            disabled={isChild}
                        />
                    </Grid>
                )}

                <Box
                    display={'flex'}
                    // height={150}
                    marginBottom={'35px'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    width={'100%'}
                    border={'1px solid lightgrey'}
                    padding={'20px'}
                    // display={'flex'}
                    // justifyContent={'space-between'}
                    borderRadius={'4px'}
                >
                    <>
                    <Grid item justify={'center'} alignItems={'center'} container>

                        <Grid container item xs={4}>
                            <Grid
                                item
                                xs={12}
                                className={classes.uploadSection}
                                >

                                <Typography
                                        variant={'body1'}
                                        // weight={'300'}
                                        style={{ marginBottom: 10 }}
                                    >
                                        Add Logo
                                </Typography>
                                <ImageUploader
                                    isUploading={logoUploadProgress}
                                    imageData={logoURL /* user.calendarLogo */}
                                    onImageSelected={(files) => {
                                        uploadImage(files);
                                        setIsDirty(true);
                                    }}
                                    onImageDelete={(files) => {
                                        deleteLogo();
                                        setIsDirty(false);
                                    }}
                                    isChild={isChild}
                                />
                            </Grid>

                        </Grid>

                        <Grid item xs={8}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 30,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant={'body2'}
                                    weight={'light'}
                                    style={{ marginBottom: 30, marginTop: 70 }}
                                >
                                    Help users identify with the schedule.<br />
                                    Browse or drag and drop an image file to use as your schedule logo.
                                    Upload a square image, (.png, .jpg, .gif), aspect ratio 1:1.
                                </Typography>
                            </Grid>
                            {(logoImageError && renderError(logoImageError)) || (imageError && renderError(imageError)) }
                        </Grid>
                    </Grid>

                    </>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                  <ButtonProgress
                    dataIntercomTarget='schedule-save'
                    disabled={isChild ? true : saveDisabled}
                    showProgress={isLoading}
                    label='Save'
                    onClick={saveSchedule}
                  />
                </Box>
            </Box>
        </Grid>
    );
};

EntryForm.propTypes = {
    deleteLogo: PropTypes.func,
    uploadImage: PropTypes.func,
    isLoading: PropTypes.bool,
    saveDisabled: PropTypes.bool,
    schedulesV2: PropTypes.bool,
    scheduleTypes: PropTypes.array,
    genres: PropTypes.array,
    subGenres: PropTypes.array,
    showSubGenres: PropTypes.bool,
    updateInput: PropTypes.func,
    input: PropTypes.any,
};

EntryForm.defaultProps = {
    deleteLogo: () => {},
    uploadImage: () => {},
    isLoading: false,
    saveDisabled: false,
    schedulesV2: false,
    scheduleTypes: [],
    genres: [],
    subGenres: [],
    showSubGenres: false,
    updateInput: () => {},
    input: {},
};

export default observer(EntryForm);
