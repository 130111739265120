import { validateInputError } from '../../error/utils';

function validateName (input) {
    return '';
}

const validations = {
    name: validateName,
};

export function inputValidator (input, touched = {}) {
    const inputError = validateInputError(input, validations, touched);
    console.log('Input Error: ', inputError);
    return {};
}
