import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    widgetBody: {
      position: 'initial !important',
    },
    widgetContainer: {
      paddingBottom: '8px',
      position: 'relative',
    },
    timezoneText: {
      textAlign: 'right',
      // marginBottom: '4px',
      fontSize: '10px !important',
    },
    subscriptionCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#536DFE44',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    engagementsCircle: {
      height: '35px',
      width: '35px',
      backgroundColor: '#3CD4A044',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    engagementContainer: {
      position: 'relative',
      '& span.apexcharts-legend-marker[rel="3"]': {
        color: 'rgb(255, 255, 255)!important',
        borderWidth: '1px!important',
        borderColor: 'rgb(238, 118, 36)!important',
        borderStyle: 'dashed!important',
        background: 'rgb(255, 255, 255)!important',
      },
    },

    linkNameContent: {
      display: 'flex',
      flexDirection: 'row',
    },

    tableURLCell: {
      display: 'flex',
      minWidth: '400px',
      maxWidth: '480px',
      overflow: 'hidden',
    },
    mapContainer: {
      '& .mapboxgl-control-container': {
        display: 'none',
      },
    },
    loader: {
      position: 'absolute',
      inset: 0,
      margin: 'auto',
    },
});

export default styles;
export const useStyles = makeStyles(styles);
