import { useState, useEffect } from 'react';
import { useErrorStore } from './';

function useErrorListener () {
    const errorStore = useErrorStore();
    const [errorCancelled, setErrorCancelled] = useState(false);

    useEffect(() => {
        if (errorStore.observed.errorCancel) {
            setErrorCancelled(errorStore.observed.errorCancel);
        }
    }, [errorStore.observed.errorCancel]);

    function cancelError () {
        errorStore.cancelError();
    }

    function waitForError () {
        setErrorCancelled(false);
    }

    return {
        cancelError,
        waitForError,
        errorCancelled,
    };
}

export default useErrorListener;
