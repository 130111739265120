import { validateInputError } from '../../error/utils';
import { getSubGenreList } from '../../../../../src/pages/schedules/manualEntry/GenreUtil';
import { isEmpty } from 'lodash';

function validateName (input) {
    if (!input.name || input.name.length === 0) {
        return 'Name is required';
    }
    return '';
}

function validateGenre (input) {
    if (!input.genre || input.genre.length === 0) {
        return 'Genre is required';
    }
    return '';
}

function validateSubGenre (input) {
    const hasSubGenreList = getSubGenreList(input.genre);
    if (isEmpty(hasSubGenreList)) {
        return;
    }

    if (!input.subGenre || input.subGenre.length === 0) {
        return 'Sub-Genre is required';
    }
    return '';
}

const validations = {
    name: validateName,
    genre: validateGenre,
    subGenre: validateSubGenre,
};

export function inputValidator (input) {
    return validateInputError(input, validations);
}
