import { isEmpty } from 'lodash';
import {
    validateError,
    ErrorAPICode,
    ErrorAPIReasons,
    ErrorAPIMessages,
    ErrorAPIErrors,
    errorMap,
} from '../../error/error_map';

const errorReasons = { ...ErrorAPIReasons };
const errorCodes = { ...ErrorAPICode };
const errorMessages = { ...ErrorAPIMessages };

const errorErrors = {
    ...ErrorAPIErrors,
     RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
};

const customErrorMap = {
    ...errorMap,

    RESOURCE_ALREADY_EXISTS: (err) => {
        return {
            title: 'Duplicate custom labels',
            message: err.message,
            label: err.message,
        };
    },

    INVALID_INPUT_PARAMETER: (err) => {
        return {
            title: 'Invalid input',
            message: err.message,
            text: err.message,
        };
    },
};

export function serviceErrorSerializer (errors) {
    if (isEmpty(errors)) {
        return {};
    }
    const options = {
        errorMap: customErrorMap,
        reasons: errorReasons,
        codes: errorCodes,
        messages: errorMessages,
        errors: errorErrors,
    };
    const error = validateError(errors[0], options);
    return error;
}
