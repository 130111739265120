import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Dialog from '../../commons/dialog/Dialog';
import { useErrorStore } from '../../store/models/error';

function useErrorDialog (props) {
    const {
        errors,
        onCloseError = () => {},
    } = props;
    const errStore = useErrorStore();
    const [errorOpen, setErrorOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    useEffect(() => {
        console.log('Error Open? ', errorOpen);
        setErrorOpen(false);
    }, []);

    useEffect(() => {
        console.log('');
        console.log('errors: ', errors);
        console.log('Error Open? ', errorOpen);
        if (isEmpty(errors)) {
            setTitle('');
            setMessage('');
            setErrorOpen(false);
            return;
        }

        if (!isEmpty(errors)) {
            setTitle(errors.title);
            setMessage(errors.message);
            setErrorOpen(true);
        }
    }, [errors]);

    function renderErrorDialog () {
        return (
            <Dialog
                openDialog={errorOpen}
                buttonSecondaryFlag={false}
                buttonPrimary={'Ok'}
                showSubtitle={true}
                subtitle={'Ref.ID: ' + errStore.env.traceId}
                handleAgree={() => {
                    setErrorOpen(false);
                    if (typeof onCloseError === 'function') {
                        onCloseError();
                    }
                }}

                closeModal={() => {
                    setErrorOpen(false);
                }}
                title={title}
                dialogContextText={message}
            />
        );
    }

    return {
        title,
        message,
        errorOpen,
        setErrorOpen,
        renderErrorDialog,
    };
}

export default useErrorDialog;
