import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    Tabs,
} from '@material-ui/core';

import Widget from '../../components/Widget';

// import { useStyles } from '../profile.styles';

function ScheduleTabs (props) {
    const {
        onTabChange,
        children,
        tabIndex = 0,
        shouldChangeTab,
    } = props;
    const [tab, setTab] = useState(tabIndex);
    // const classes = useStyles();

    function handleTabChange (event, newValue) {
        if (!shouldChangeTab()) {
            return;
        }
        setTab(newValue);
        onTabChange(newValue);
    }

    useEffect(() => {
        setTab(tabIndex);
    }, [tabIndex]);

    return (
        <Grid item xs={12}>
            <Widget>
                <Box display={'flex'} justifyContent={'center'}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="Tabs"
                    >
                        {children}
                    </Tabs>
                </Box>
            </Widget>
        </Grid>
    );
}

ScheduleTabs.propTypes = {
    onTabChange: PropTypes.func,
    shouldChangeTab: PropTypes.func,
};

ScheduleTabs.defaultProps = {
    onTabChange: () => {},
    shouldChangeTab: () => true,
};

export default ScheduleTabs;
