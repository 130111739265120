import moment from 'moment';

export const defaultEvent = {
    id: 0,
    name: '',
    location: '',
    start: '',
    end: '',
    startDateTime: new Date(),
    endDateTime: moment(new Date()).add(15, 'minutes'),

    time: {
        allDay: false,
        timezone: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
    },
    quickLinks: [],
    data: {
        banner: '',
        details: '',
        draft: true,
        focusX: '0',
        focusY: '0',
        location: '',
        primaryLink: '',
        secondaryLink: '',
        shortURL: '',
        reference: '',
        referenceType: '',
        sponsoredMessage: '',
    },
    alertData: {
        alert1: '',
        alert2: '',
    },
    socialData: {
        facebook: '',
        instagram: '',
        twitter: '',

        youtube: '',
        vimeo: '',
        podcast: '',

        linkedin: '',
        blog: '',
        tikTok: '',

        iphone: '',
        ipad: '',
        android: '',

        snapchat: '',
        twitch: '',
        discord: '',

        linktree: '',
    },
    ref: '',
};

export function defaultEventData () {
    /*
     *  Please do not delete this note, this is for all the developers now and in the future.
     *  It is always a good implementation to separate Time state from date state
     *  This prevents data corruption on state change.
     *  It means when time or date is updated, it will not affect one or the other.
     *
     */

    const d = new Date();
    const endTime = new Date(d);
    endTime.setHours(23, 0, 0, 0);
    const sd = moment(d);
    const ed = moment(endTime);

    const defaultData = {
        ...defaultEvent,

        alertData: {
            alert1: '-',
            alert2: '-',
        },

        time: {
            ...defaultEvent.time,
            startDate: sd.format('yyyy-MM-DD'),
            startTime: sd.format('HH:mm'),
            endDate: ed.format('yyyy-MM-DD'),
            endTime: ed.format('HH:mm'),
        },
    };

    console.log('');
    console.log('');
    console.log('eventData.defaultEventData(): will return... ', defaultData);

    return { ...defaultData };
}
