import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Button, Checkbox, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '@material-ui/core/Link';
// import TableCell from '@material-ui/core/TableCell';
import CustomToolbarSelect from './CustomToolbarSelect';
import CustomFooter from './CustomFooter';
import { Typography, Chip } from '../../components/Wrappers/Wrappers';
import Dialog from '../dialog/Dialog';
import useStyles from './styles';
import {
  Settings as SettingsIcon,
  CreateOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  AccountTreeOutlined as AccountTreeIcon,
} from '@material-ui/icons';
import { moduleNoDataText } from '../util/utilities';

// Check if Child schedules included while bulk deleting schedules
function hasChild (tableData, selectedData) {
  // the selectedData param only returns each cell's position index, so the loop is necessary
  for (let i = 0; i < selectedData.data.length; i++) {
    const rowIndex = selectedData.data[i].dataIndex;
    const isChild = tableData[rowIndex].isChild;
    if (isChild) {
      return true;
    }
  }

  return false;
}

const CustomCheckbox = (props) => {
  console.log('');
  console.log('rendering checkbox: ', props);
  const newProps = Object.assign({}, props);
  newProps.color = props['data-description'] === 'row-select' ? 'secondary' : 'primary';
  return (<Checkbox {...newProps} />);
};

function capitalize (string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Tables (props) {
  const {
    onPageChange,
    currentPage,
    totalCount,
    onSearch,
    onFilter,
    showLoading,
    onActionIconClick,
    onEditSchedule,
    onManageEventsClick,
    onDeleteSchedule,
    onSetItemsLive,
    onSetItemsDraft,
    onDeleteItems,
    tableData,
    intercomTargetManageEvents = 'schedule-manage-events',
    statusFilter = '',
  } = props;
  const classes = useStyles();
  // const [isActionsMenu, setActionsMenu] = React.useState(false);
  const [loadPopup, setLoadPopup] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  // const [actionsButtonRefid, setActionsButtonRefid] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(showLoading);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [isChildDelete, setIsChildDelete] = useState(false);
  const [isChildUpdate, setIsChildUpdate] = useState(false);
  const [popupTile, setPopupTile] = useState('Delete Schedule');

  let searchTimeout = null;

  useEffect(() => {
    setLoading(showLoading);
  }, [showLoading]);

  const handleMoreClick = (categoryCollection) => {
    setCategories(categoryCollection);
    setLoadPopup(true);
  };

  const handleAgree = () => {
    setLoadPopup(false);
  };

  const defaultStatusCol = {
    name: 'draft',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      filterList: statusFilter === '' ? ['Draft', 'Live'] : [statusFilter],
      filterOptions: {
        names: ['Draft', 'Live'],
      },
      setCellProps: () => ({ style: { width: '85px', paddingRight: '0px' } }),
      /*
        Draft = 1, Live = 0, Draft == true, Live = false
      */
      customBodyRender: (value, tableMeta, updateValue) => {
        // renderChildIcon(childSchedules, value, tableMeta, updateValue)
        return (<Chip
          label={ !value ? 'LIVE' : 'DRAFT'}
          key={value++}
          style={{
            color: '#fff',
            height: 20,
            width: 65,
            backgroundColor: !value ? '#75bd43' : '#f50057',
            fontSize: 11,
            fontWeight: 'bold',
          }}
        />);
      },
    },
  };

  const isChildCol = {
    name: 'isChild',
    label: ' ',
    options: {
      filter: false,
      sort: false,
      setCellProps: () => ({ style: { minWidth: '60px', paddingLeft: '0px' } }),
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (<Tooltip title={'Managed by a child account'}>
              <AccountTreeIcon
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '24px',
                  height: '24px',
                  marginLeft: '',
                  color: '#0000008a',
                }}
              />
          </Tooltip>);
        }
      },
    },
  };

  const defaultColumns = [
    isChildCol,
    {
      name: 'name',
      label: 'Name',
      width: '30%',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'eventCount',
      label: 'Events',
      width: '5%',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'categoryNames',
      label: 'Categories',
      width: '15%',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value.length === 0) {
            return 'No Category';
          }

          if (value.length > 3) {
            const categoryList = [];
            const items = value.slice(0, 3);
            categoryList.push(...items);
            const categoryCollection = value.join(', ');
            return <>{categoryList.join(', ')} <br></br> <Link color='primary' onClick={() => handleMoreClick(categoryCollection)}>(+{value.length - 3})</Link></>;
          }
          return value.join(', ');
        },
      },
    },
    {
      name: 'reference',
      label: 'Reference',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'modified',
      label: 'Updated',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
            if (value) {
              if (moment(value).isBefore('2010-01-01', 'year')) {
                return '';
              }
              const dateTimeAgo = moment(value).fromNow();
              return dateTimeAgo;
            } else {
              return null;
            }
        },
      },
    },
    {
      /* NOTE: by settting the name as id  we can get the id of schedules */
      name: 'id',
      label: 'Actions',
      width: '2%',
      options: {
        filter: false,
        sort: false,
        // display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{ width: '59px' }}
          >
            <Tooltip title={'Manage Events'}>
              <IconButton
                  // className={classes.actionsIcon}
                  data-intercom-target={intercomTargetManageEvents}
                  aria-owns='actions-menu'
                  aria-haspopup='true'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (typeof onManageEventsClick === 'function') {
                      onActionIconClick(value, tableMeta);
                      onManageEventsClick(value, tableMeta);
                  }
                  }}
                  style={{ marginRight: '4px', padding: 0 }}
                  // buttonRef={setActionsButtonRefid}
              >
                <SettingsIcon style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Edit Schedule'}>
              <IconButton
                data-intercom-target={'edit-schedule'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (typeof onEditSchedule === 'function') {
                    onActionIconClick(value, tableMeta);
                    onEditSchedule(value, tableMeta);
                  }
                }}
                style={{ marginRight: '4px', padding: 0 }}
              >
                <EditIcon style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Delete Schedule'}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  // tableMeta.rowData[1] represents the isChild value in this row
                  if ((typeof tableMeta.rowData[1] !== 'undefined') && tableMeta.rowData[1]) {
                    setPopupTile('Delete Schedule');
                    setIsChildDelete(true);
                    return;
                  }

                  if (typeof onDeleteSchedule === 'function') {
                    onActionIconClick(value, tableMeta);
                    onDeleteSchedule(value, tableMeta);
                }
                }}
                style={{ padding: 0 }}
              >
                  <DeleteIcon
                  style={{ color: '#0c0644', width: '17px', height: '17px' }} />
              </IconButton>
            </Tooltip>
        </div>
        );
              },
      },
    },
  ];

  const [columns, setColumns] = useState([defaultStatusCol, ...defaultColumns]);

  useEffect(() => {
    selectStatusInsideFilter();
  }, [statusFilter]);

  /*
   * THE ONLY WAY TO UPDATE TABLE PROPS IS TO UPDATE THE WHOLE COLUMN LIST as STATE.
   * This table is so hacky... this can be done straight-forward with normal tables that we have..
   *  REFACTOR IN THE FUTURE, use the Table Component..
   */

  function selectStatusInsideFilter () {
    const statCol = {
      ...defaultStatusCol,
    };

    const capitalizedStatus = capitalize(statusFilter);
    if (!statusFilter) {
      statCol.options.filterList = ['Draft', 'Live'];
    } else {
      statCol.options.filterList = [capitalizedStatus];
    }

    const newColumns = [
      statCol,
      ...defaultColumns,
    ];

    setColumns(newColumns);
  }

  const options = {
    filter: true,
    selectableRows: true,
    download: false,
    print: false,
    // responsive: 'standard',
    filterType: 'checkbox',
    searchPlaceholder: 'Search Schedules/Categories beginning with',
    textLabels: {
        body: {
            noMatch: loading
            ? <React.Fragment>
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
                <Skeleton width='100%' />
              </React.Fragment>
                : <Typography variant={'body2'}>{moduleNoDataText('schedules')}</Typography>, // 'No Schedule Data',
        },
    },

    customToolbarSelect: (selectedRows) => {
      return (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          onSetItemsDraft={(data) => {
            if (hasChild(tableData, data)) {
              setIsChildUpdate(true);
              return;
            }

            onSetItemsDraft(data);
          }}
          onSetItemsLive={(data) => {
            if (hasChild(tableData, data)) {
              setIsChildUpdate(true);
              return;
            }
            onSetItemsLive(data);
          }}
          onDeleteItems={(data) => {
            if (hasChild(tableData, data)) {
              setPopupTile('Delete Seleted Items');
              setIsChildDelete(true);
              return;
            }
            handleDeleteDialog(data);
          }}
      />);
    },

    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomFooter
          count={totalCount}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={onPageChange}
          textLabels={textLabels}
        />
      );
    },

    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <Button variant='contained' onClick={() => {
            console.log('apply filters: ', currentFilterList);
            const drafts = currentFilterList[0];
            if (drafts.length === 1) {
              onFilter(drafts[0].toLowerCase());
            } else {
              onFilter('');
            }
          }}>Apply Filter</Button>
        </div>
      );
    },

    onFilterConfirm: (filterList) => {
      console.log('onFilterConfirm');
      console.dir(filterList);
    },

    onFilterChange: (column, filterList, type) => {
      console.log('on filter change -> ', column);
      console.log('on filter List -> ', filterList);
    },

    serverSide: true,

    onSearchChange: (searchText) => {
      // search debounce to optimize backend calls
      if (searchTimeout) clearTimeout(searchTimeout);
      function doSearch () {
        console.log('');
        console.log('DO Search : ', searchText);
        onSearch(searchText);
      }
      searchTimeout = setTimeout(doSearch, 400);
    },

    // onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
    //   console.log('');
    //   console.log('rowSelonRowSelectionChange() ', currentRowsSelected);
    //   console.log('All Rows Selected: ', allRowsSelected);
    //   console.log('Rows Selected: ', rowsSelected);
    // },

    // onRowClick: (rowData, rowMeta) => {
    //   console.log('Row Meta: ', rowMeta);
    //   console.log('Row Click: ', rowData);
    // },

    // setRowProps: (row, dataIndex, rowIndex) => {
    //   // console.log('setting row props row data', row);
    //   return {

    //   };
    // },

    // selectableRowsOnClick: () => {
    //   console.log('Selectable Rows Click: ');
    // },
  };

  const handleDeleteDialog = (data) => {
    setDataToDelete(data);
    setDeleteOpen(true);
  };

  const getMuiTheme = createTheme({
    typography: {
      fontFamily: [
        'GothamRounded',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    overrides: {
      MuiGridListTile: {
        root: {
          width: '100% !important',
        },
      },
      MUIDataTableFilter: {
        checkboxFormControlLabel: {
          marginLeft: 0,
        },
      },
      MuiGrid: {
        container: {
          justifyContent: 'space-between',
        },
      },
    },
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {loadPopup
          ? <Dialog
              openDialog={loadPopup}
              title={'Categories'}
              buttonPrimary={'Ok'}
              buttonSecondaryFlag={false}
              handleAgree={handleAgree}
              dialogContextText={categories} />
          : null}
          <MuiThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              title='Schedules'
              data={tableData}
              className={classes.tableContainer}
              columns={columns}
              options={options}
              tableLayout={'fixed'}
              components={{
                Checkbox: CustomCheckbox,
              }}
            />
          </MuiThemeProvider>
        </Grid>
        <Dialog
          openDialog={deleteOpen}
          handleAgree={() => {
            onDeleteItems(dataToDelete);
            setDeleteOpen(false);
          }}
          closeModal={() => {
            setDeleteOpen(false);
          }}
          title="Delete selected items"
          dialogContextText="Are you sure you want to delete the selected item(s)?"
        />
        <Dialog
          openDialog={isChildDelete}
          buttonPrimary={'OK'}
          buttonPrimaryFlag={true}
          buttonSecondaryFlag={false}
          handleAgree={() => {
            setIsChildDelete(false);
          }}
          closeModal={() => {
            setIsChildDelete(false);
          }}
          title={popupTile}
          dialogContextText="Child schedule cannot be deleted from the parent account. Please login to the child account to perform this action."
        />
        <Dialog
          openDialog={isChildUpdate}
          buttonPrimary={'OK'}
          buttonPrimaryFlag={true}
          buttonSecondaryFlag={false}
          handleAgree={() => {
            setIsChildUpdate(false);
          }}
          closeModal={() => {
            setIsChildUpdate(false);
          }}
          title="Update Seleted Items"
          dialogContextText="Child schedule cannot be updated from the parent account. Please login to the child account to perform this action."
        />
      </Grid>
    </>
  );
}
