import React from 'react';

import PropTypes from 'prop-types';
import {
    Grid,
    InputAdornment,
    IconButton,
    TextField,
    Tooltip,
    CircularProgress,
} from '@material-ui/core';

import {
    FileCopy,
} from '@material-ui/icons';

import Dialog from '../../../commons/dialog/Dialog';
import { useStyles } from './styles';

function SecretkeyDialog (props) {
    const {
        onClose,
        open,
        secretKey,
        isLoading,
    } = props;

    const classes = useStyles();

    function renderAdornment () {
        if (isLoading) {
            return (
                <CircularProgress
                    size={20}
                />
            );
        }
        return (
            <Tooltip title="Copy to clipboard">
                <IconButton
                        aria-label="copy to clipboard"
                        onClick={() => {
                            navigator.clipboard.writeText(secretKey);
                        }}
                >
                    <FileCopy
                        fontSize={'small'}
                    />
            </IconButton>
        </Tooltip>
        );
    }
    return (<Dialog
        openDialog={open}
        buttonSecondaryFlag={false}
        buttonPrimary="Ok"
        handleAgree={() => {
            onClose(false);
        }}

        closeModal={() => {
            onClose(false);
        }}
        title={'API Secret Key'}
        dialogContextText={''}
        className={classes.dialogContent}
      >
          <Grid>
                <TextField
                    InputProps={{
                        className: classes.secretKeyText,

                        endAdornment: (
                            <InputAdornment position="end">
                                {renderAdornment()}
                            </InputAdornment>
                        ),
                    }}
                    id="secretKey"
                    label="Secret Key"
                    name="secretKey"
                    value={secretKey}
                    variant="outlined"
                    helperText={''}
                />
          </Grid>
    </Dialog>);
}

SecretkeyDialog.propTypes = {
    onClose: PropTypes.func,
    secretKey: PropTypes.string,
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
};

SecretkeyDialog.defaultProps = {
    secretKey: '',
    onClose: () => {},
    open: false,
    isLoading: true,
};

export default SecretkeyDialog;
