import {
    // get,
    postGQL,
    // put,
    // post,
    // deleteCall,
    endpoints,
    upload,
} from '../../api';

import {
    eventsQuery,
    queryCreateEvent,
    queryUpdateEvents,
    queryBulkDeleteEvents,
    queryBulkCreateEvents,
    queryUpdateEventsStatus,
    // querySearchEvents,
} from './queries';

export function apiGetEvents ({ draft, page, pageLength, scheduleId, token, traceId }) {
    const input = {
        scheduleId,
        'name': '',
    };

    if (draft !== undefined) {
        input.draft = draft;
    }

    return postGQL(
        endpoints().getEvents,

        eventsQuery({
            input,
            page,
            sort: {
                field: 'startDate',
                order: 'DESC',
            },

            // pageInput: {
            //     pageNumber: page,
            //     pageLength: pageLength,
            // },
        }),
        {
            token,
            traceId,
        }
    );
}

export function apiCreateEvent ({ events, token, traceId }) {
    return postGQL(
        endpoints().getEvents,
        queryCreateEvent({ events }),
        {
            token,
            traceId,
        }
    );
}

export function apiUpsertEvents ({ events, token, traceId }) {
    return postGQL(
        endpoints().getEvents,
        queryUpdateEvents({ events }),
        {
            token,
            traceId,
        }
    );
}

export function apiBulkUpdateEvents ({ events, token, traceId }) {
    return postGQL(
        endpoints().getEvents,
        queryUpdateEvents({ events }),
        {
            token,
            traceId,
        }
    );
}

export function apiBulkUpdateEventsStatus ({ events, token, traceId }) {
    return postGQL(
        endpoints().getEvents,
        queryUpdateEventsStatus({ events }),
        {
            token,
            traceId,
        }
    );
}

export function apiBulkDeleteEvents ({ eventIds, scheduleId, token, traceId }) {
    const events = {
        eventIds,
        scheduleId,
    };
    return postGQL(
        endpoints().getEvents,
        queryBulkDeleteEvents({ events }),
        {
            token,
            traceId,
        }
    );
}

export function apiSearchEvents ({ name, scheduleId, draftStatus = '', page, pageLength, token, traceId }) {
    const draft = draftStatus === '' ? {} : { draft: draftStatus };
    const input = {
        scheduleId,
        'name': name,
        ...draft,
    };

    return postGQL(
        endpoints().getEvents,
        eventsQuery({

            // searchInput: {
            //     input,
            //     status: draftStatus,
            // },
            input,
            sort: {
                field: 'startDate',
                order: 'DESC',
            },

            pageInput: {
                pageNumber: page,
                pageLength: pageLength,
            },
            page,
        }),
        {
            token,
            traceId,
        }
    );
}

export function apiImportEvent ({ data, token, traceId }) {
    return upload(
        endpoints().eventImport,
        data,
        {
            token,
            traceId,
        }
    );
}

export function apiBulkCreateEvents ({ events, token, traceId }) {
    return postGQL(
        endpoints().getEvents,
        queryBulkCreateEvents({ i: events }),
        {
            token,
            traceId,
        }
    );
}
