import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getBillingInfo,
    getPlanDetails,
    getCurrentPlan,
    createSubscription,
    finalize3DSecure,
    removeLowerTier,
    upgradeFree,
    selectPlanFromCode,
    selectPlanFromID,
    getPlanDetailsFromID,
    hasBilling,
    updateSubscription,
    createSubscriptionAWSMarketplace,
} from './actions';

export default class BillingStore {
    env = null
    observed = {};
    /*
     * currentPlan
     * is the actual reflection of the publisher plan pulled from server
     */
    currentPlan = null;
    publisherPlan = null;
    planDetails = null;
    chargifyActionLink = null;
    /*
     * is the currently selected plan on the drop down...
     * this will not affect the currentPlan unless a call to createSubscription is
     * selected plan data will be community or the first element of the plan details when free-trial is the plan.
     * selected plan data is the actual state that will be rendered on the billing Dialog
     */

    selectedPlanData = null;
    constructor () {
        makeObservable(this, {
            observed: observable,
        });
    }

    getBillingInfo = getBillingInfo;
    getPlanDetails = getPlanDetails;
    getCurrentPlan = getCurrentPlan;
    createSubscription = createSubscription;
    createSubscriptionAWSMarketplace = createSubscriptionAWSMarketplace;
    finalize3DSecure = finalize3DSecure;
    removeLowerTier = removeLowerTier;
    upgradeFree = upgradeFree;
    selectPlanFromCode = selectPlanFromCode;
    selectPlanFromID = selectPlanFromID;
    getPlanDetailsFromID = getPlanDetailsFromID;
    hasBilling = hasBilling;
    updateSubscription = updateSubscription;
}

export function useBillingStore () {
    const { billingStore } = useStores();
    return billingStore;
}
