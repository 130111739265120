
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'absolute',
        top: 0,
        left: 0,
    },

    formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
    },

    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint,
    },

    bgContainer: {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: '#12012C',
        width: '60%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '60%',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    brandBackgroundImage: {
        width: '100%',
        // height: '100vh',
    },

    /* FORMS */

    formContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
        },
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 64px)',
    },

    instruction: {
       marginBottom: '32px',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
