import { validateError } from './error_map';

export const AuthErrorSource = {
    COGNITO: 'cognito',
    ECAL: 'ecal',
};

export function handleAuthError ({ error, action, source }) {
    console.log('');
    console.log('');
    console.log('');
    console.log('handleAuthError() action: ', action);
    console.log('handleAuthError() source: ', source);
    console.log('handleAuthError() error: ', error);
    if (source === AuthErrorSource.COGNITO) {
         const err = validateError(error);
         console.log('Cognito Error Validated Mapped Error : ', err);
         if (err !== null) {
            this.authError = {};
            this.authError[action] = err;
            return {
                hasError: true,
            };
         }
    }
    return {
        hasError: false,
    };
}
