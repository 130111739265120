import { makeObservable, observable } from 'mobx';
import { useStores } from '../useStores';

import {
    getSubscribersData,
    getSubscribersReport,
    updateSchedulesFilter,
    getMonthlySubscribers,
} from './actions';

import {
    loadEditData,
    saveEditData,
} from './actions_persist';

export default class SubscribersStore {
    version = {}
    env = null;
    subscribersData = null;
    subscribersColumns = [];
    schedules = [];
    editData = null;
    monthlySubscriptions = null

    observed = {
        subscribersData: null,
        subsribersDataLoading: false,
        monthlySubsLoading: false,
        schedules: null,
    };

    constructor () {
        makeObservable(this, {
            observed: observable,
            monthlySubscriptions: observable,
        });
    }

    getSubscribersData = getSubscribersData;
    updateSchedulesFilter = updateSchedulesFilter;
    getSubscribersReport = getSubscribersReport;
    getMonthlySubscribers = getMonthlySubscribers;

    /* Data Persist */
    loadEditData = loadEditData;
    saveEditData = saveEditData;
}

export function useSubscribersStore () {
    const { subscribersStore } = useStores();
    return subscribersStore;
}
