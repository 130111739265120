// import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((p) => ({

    controlRoot: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#FFFFFF',
        minHeight: '48px',
        justifyContent: 'space-between',
        /*
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        */
    },

    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    buttonSpace: {
        marginRight: '16px',
    },

    infoText: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '250px',
        height: '100%',
        marginLeft: '16px',
        fontSize: '16px',
    },
}));

export default useStyles;
