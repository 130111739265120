import { useState, useEffect } from 'react';

function useColumnSelection (props) {
    const {
        columns: columnBase,
        fieldSelection,
        onColumnChange,
    } = props;

    function initialSelection () {
        return columnBase.map((item) => item[fieldSelection]);
    }

    const [selected, setSelected] = useState(initialSelection());
    const [selectedColumns, setSelectedColumns] = useState([]);

    function updateColumnSelection () {
        const newColumns = columnBase.reduce((cols, item) => {
            if (!selected.includes(item[fieldSelection])) {
                return cols;
            }
            return [
                ...cols,
                item,
            ];
        }, []);
        onColumnChange(newColumns);
        setSelectedColumns(newColumns);
    }

    useEffect(() => {
        updateColumnSelection();
    }, [selected]);

    return {
        selectedColumns,
        setSelected,
        selected,
    };
}

export default useColumnSelection;
