import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TouchAppIcon from '@material-ui/icons/TouchApp';
// import GroupsIcon from '@material-ui/icons/Groups';
import {
    EventNote as EventIcon,
    PlaylistAddCheck as MarketingIcon,
    // TrendingUp as AnalyticsIcon,
} from '@material-ui/icons';
import { useStyles } from './styles';
import Widget from '../../components/Widget';
import { Typography } from '../../components/Wrappers';
// import { Typography } from '../../components/Wrappers/Wrappers';
// content for next release
// import UserDetails from './userDetails';
// import UserGrowth from './insights/userGrowth';
import DataInsights from './insights/pillDataInsights';
import Map from './subscriptionMap';
import SubscriptionGraph from './charts/barGraph';
import EngagementsGraph from './charts/engagementsBarGraph';
import CalendarTypesGraph from './charts/calendarBarGraph';
import LocationTable from './charts/locationTable';
import DevicesPie from './charts/pieChart';
import PopularLinksTable from './charts/popularLinksTable';

import {
  useDashboardStore,
  useEnvStore,
} from '../../store/models';

import { getOptinPercentage } from '../../commons/util/utilities';
import useProductTour from '../hooks/useProductTour';
import usePremiumPlan from '../hooks/usePremiumPlan';
import { BillingCoupons } from '../../utils/constants';
const parseCountData = (count) => {
  let dataCount;
  dataCount = count >= 100000 ? `${parseFloat((count / 1000).toFixed()).toLocaleString('en')}K` : count?.toLocaleString('en');
  dataCount = dataCount !== '0' ? `${dataCount}` : '0';
  return dataCount;
};

const getTotalCountInArray = (list) => {
  if (Array.isArray(list)) {
    return list.reduce((total, currentVal) => total + currentVal.count, 0);
  }
  return 0;
};

const getCTRvalue = (totalClicks, totalImpressions) => totalImpressions ? `${((totalClicks / totalImpressions) * 100).toFixed(2)}%` : 0;

const TOOLTIP_TEXT = {
  monthlyActives: 'This is your estimated monthly active users. Note: If you haven’t sent an event in the last 30 days, this metric will be understated.',
  eventImpression: 'The number of events that have appeared in the calendar over the last 30 days.',
  clicks: 'The number of clicks on your links in the calendar over the last 30 days.',
};

function HomePage () {
    const dashboardStore = useDashboardStore();

    const {
      reminderImpressions,
      eventImpressions,
      analyticsClicks,
      totalSubscribers,
      optinCount,
      popularLocations,
      observed: {
        analyticsClicksDataLoading,
        eventImpresionsDataLoading,
        reminderImpresionsDataLoading,
        subscriptionsDataLoading,
        popularLocationsDataLoading,
        calendarTypesDataLoading,
        deviceTypesDataLoading,
        popularLinksDataLoading,
      },
    } = dashboardStore;
    const classes = useStyles();
    const [optinsCount, setOptinsCount] = useState('0');
    const { isPremium } = usePremiumPlan();

    async function loadDashboardData () {
      // load dashboard data one at a time.
      await dashboardStore.getLiveScheduleCount();
      await dashboardStore.getLiveEventCount();
      await dashboardStore.getClicksCount();
      await dashboardStore.getTotalSubscribers();
      await dashboardStore.getOptinCount();
      await dashboardStore.getMonthlyActives();
      await dashboardStore.getMapSubscriptionsData();
      await dashboardStore.getEventImpressions();
      await dashboardStore.getReminderImpressions();
      await dashboardStore.getAnalyticsCalendarTypes();
      await dashboardStore.getAnalyticsSubscriptions();
      await dashboardStore.getAnalyticsClicks();
      await dashboardStore.getAnalyticsPopularLocations();
      await dashboardStore.getAnalyticsDevices();
    }

    const envStore = useEnvStore();
    useProductTour();
    // useIntercomBannerClicks();

    useEffect(() => {
        loadDashboardData();
    }, []);

    useEffect(() => {
      setOptinsCount(getOptinPercentage(totalSubscribers, optinCount));
    }, [totalSubscribers, optinCount]);

    useEffect(() => {
      console.log('dashboardStore.subsMapData', dashboardStore.subsMapData);
    }, [dashboardStore.subsMapData]);

    useEffect(() => {
      console.log('homepages.index.useEffect() settings: ', envStore.settings);
      if (envStore.settings && envStore.settings?.coupon) {
          if (envStore.settings.coupon === BillingCoupons.EXPERTPLS) {
            console.log('Billings Store Coupon: ', envStore.settings.coupon);
            window.Intercom('showMessages');
          }
      }
    }, [envStore.observed.settings]);

    function renderActiveUser () {
      if (isPremium) {
        return (
          <Grid item xs={6} sm={3}>
            <DataInsights
              titleText={'Monthly Actives'}
              value={parseCountData(dashboardStore.monthlyActives)}
              tooltipId='monthlyActives'
              tooltipText={TOOLTIP_TEXT.monthlyActives}
            >
              <div className={classes.engagementsCircle}>
                <PeopleIcon />
              </div>
            </DataInsights>
          </Grid>
        );
      }
      return (<React.Fragment></React.Fragment>);
    }

    function renderPopularLocations () {
      if (isPremium) {
        return (
          <Box
                component={Grid}
                item
                sm={5}
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
                <Widget className={classes.widgetContainer} buttonFlag={false} title={'Popular Locations (30D)'} noBodyPadding disableWidgetMenu>
                    <LocationTable
                      data={popularLocations}
                      isLoading={!popularLocations || popularLocationsDataLoading}
                    />
                </Widget>
            </Box>
        );
      }
      return (<React.Fragment></React.Fragment>);
    }

    function renderSubscriptionsLocation () {
      if (isPremium) {
        return (
          <Box
                component={Grid}
                item
                sm={12}
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
                <Grid item sm={12}>
                    <Widget buttonFlag={false} title={'Subscriptions by Location (90D)'} noBodyPadding disableWidgetMenu>
                        <Box height={400} padding={'20px'} className={classes.mapContainer}>
                            {dashboardStore.subsMapData !== []
                            ? <Map data = {dashboardStore.subsMapData} />
                            : <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant={'body2'}>
                                  There are currently no subscribers.
                                </Typography>
                              </Box>}
                        </Box>
                    </Widget>
                </Grid>
            </Box>
        );
      }
      return (<React.Fragment></React.Fragment>);
    }

    function insighWidth () {
      if (isPremium) {
        return 3;
      }
      return 6;
    }

    function newSubscriptionsWidth () {
      return isPremium ? 7 : 12;
    }

    return (
      <React.Fragment>
            <Grid item xs={12}>
                <Widget
                intercomTitleTarget="performance-trend-title"
                className={classes.widgetContainer}
                // buttonText={'Add'}
                buttonFlag={false}
                title='Performance Trends'
                // handleAdd={handleAddSchedule}
                inheritHeight disableWidgetMenu>
                </Widget>
          </Grid>
          <Typography
            className={classes.timezoneText}
            size='sm'
            variant='body2'
            component='p'
            weight='light'
          >
            {Intl.DateTimeFormat().resolvedOptions().timeZone} Timezone
          </Typography>
          <Grid container spacing={4}>
            {/* content for next release */}
            {/* <Grid item xs={12} sm={4}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <UserGrowth />
                </Widget>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <UserGrowth />
                </Widget>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <UserGrowth />
                </Widget>
            </Grid> */}
            {/* <Grid item xs={12} sm={3}>
                <UserGrowth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <LocationTable />
                </Widget>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <DevicesPie />
                </Widget>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Widget buttonFlag={false} title={''} noBodyPadding disableWidgetMenu>
                    <SubscriptionGraph />
                </Widget>
            </Grid> */}
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Total Subscriptions'}
                value={parseCountData(totalSubscribers)}
              >
                <div className={classes.subscriptionCircle}>
                  <PeopleIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Live Schedules'}
                value={parseCountData(dashboardStore.liveScheduleCount)}
              >
                <div className={classes.subscriptionCircle}>
                  <EventIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Live Events'}
                value={parseCountData(dashboardStore.liveEventCount)}
              >
                <div className={classes.subscriptionCircle}>
                  <EventIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Opt-in 1'}
                value={optinsCount}
                isLoading={!totalSubscribers && !optinCount}
              >
                <div className={classes.subscriptionCircle}>
                  <MarketingIcon />
                </div>
              </DataInsights>
            </Grid>

            { renderSubscriptionsLocation() }

            <Grid item xs={12} sm={newSubscriptionsWidth()}>
                <Widget className={classes.widgetContainer} buttonFlag={false} title={'New Subscriptions (30D)'} noBodyPadding disableWidgetMenu>
                    <SubscriptionGraph
                      data={dashboardStore.subscriptions}
                      isLoading={!dashboardStore.subscriptions || subscriptionsDataLoading}
                    />
                </Widget>
            </Grid>

            { renderPopularLocations() }

            <Grid item xs={12} sm={7}>
                <Widget className={classes.widgetContainer} buttonFlag={false} title={'Calendar Types'} noBodyPadding disableWidgetMenu>
                    <CalendarTypesGraph
                      data={dashboardStore.calendarTypes?.calendarTypeCounts}
                      isLoading={!dashboardStore.calendarTypes?.calendarTypeCounts || calendarTypesDataLoading}
                    />
                </Widget>
            </Grid>
            <Box
                component={Grid}
                item
                sm={5}
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
                <Widget className={classes.widgetContainer} buttonFlag={false} title={'Devices (%)'} noBodyPadding disableWidgetMenu>
                  <DevicesPie
                    data={dashboardStore.deviceTypes?.deviceTypeCounts}
                    isLoading={!dashboardStore.deviceTypes?.deviceTypeCounts || deviceTypesDataLoading}
                  />
                </Widget>
            </Box>

            { renderActiveUser() }

            <Grid item xs={6} sm={insighWidth()}>
              <DataInsights
                titleText={'Event Impress. (30D)'}
                value={parseCountData(getTotalCountInArray(eventImpressions))}
                tooltipId='eventImpression'
                tooltipText={TOOLTIP_TEXT.eventImpression}
              >
                <div className={classes.engagementsCircle}>
                  <VisibilityIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
              <DataInsights
                titleText={'Clicks (30D)'}
                value={parseCountData(getTotalCountInArray(analyticsClicks))}
                isLoading={!analyticsClicks}
                tooltipId='clicks'
                tooltipText={TOOLTIP_TEXT.clicks}
              >
                <div className={classes.engagementsCircle}>
                  <TouchAppIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={6} sm={3}>
                <DataInsights
                titleText={'CTR (30D)'}
                value={getCTRvalue(getTotalCountInArray(analyticsClicks), getTotalCountInArray(eventImpressions))}
                isLoading={!eventImpressions && !analyticsClicks}
              >
                <div className={classes.engagementsCircle}>
                  <TouchAppIcon />
                </div>
              </DataInsights>
            </Grid>
            <Grid item xs={12}>
                <Widget className={classes.engagementContainer} buttonFlag={false} title={'Engagement (30D)'} noBodyPadding disableWidgetMenu>
                  <EngagementsGraph
                    reminderImpressions={reminderImpressions}
                    eventImpressions={eventImpressions}
                    analyticsClicks={analyticsClicks}
                    isLoading={
                      !reminderImpressions ||
                      !eventImpressions ||
                      !analyticsClicks ||
                      analyticsClicksDataLoading ||
                      eventImpresionsDataLoading ||
                      reminderImpresionsDataLoading
                    }
                  />
                </Widget>
            </Grid>
            <Grid item xs={12}>
                <Widget className={classes.widgetContainer} buttonFlag={false} title={'Popular Links (30D)'} noBodyPadding disableWidgetMenu>
                    <PopularLinksTable isLoading={popularLinksDataLoading} />
                </Widget>
            </Grid>
          </Grid>
        </React.Fragment>
    );
  }
export default observer(HomePage);
