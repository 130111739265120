import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    widgetContainer: {
        paddingBottom: '24px',
    },

    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    listHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '400px',
        backgroundColor: '#FFFFFF',
    },

    /* cell Styles */

    nameCell: {
        width: '140px',
        fontSize: '14px',
    },

    keyCell: {
        padding: '0px',
        '& .MuiTypography-root': {
            padding: '0px',
            fontSize: '12px !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    secretCell: {
        width: '50%',
        fontSize: '14px',
    },

    secretIconCellCol: {
        display: 'flex',
        justifyContent: 'flex-start',
        minHeight: '53.5px',
    },

    secretIconCell: {
        justifyContent: 'center',
        width: '32px',
        fontSize: '12px !important',
        '& .MuiButtonBase-root': {
        },
    },

    secretIcon: {
        marginLeft: '20px',
    },

    createdCell: {
        width: '140px',
        fontSize: '12px !important',
    },

    deactivateCell: {
        width: '100px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    activateCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        minWidth: '100px',
    },

    deleteIcon: {
    },

    statusCell: {
        '& p': {
            textTransform: 'uppercase !important',
            fontSize: '12px !important',
        },
    },

    statusRed: {
        fontWeight: 'bold',
        color: '#f50057',
    },

    statusGreen: {
        fontWeight: 'bold',
        color: '#74BD44',
    },

    /* Dialog Styles */

    dialogContent: {
        '& .MuiDialogContent-root': {
            padding: '8px 16px !important',
        },

        '& .MuiDialogActions-root': {
            padding: '16px !important',
        },
    },
    secretKeyText: {
        minWidth: '360px',
        width: '360px',
        fontSize: '12px',
    },

    deactivateName: {
        width: '100%',
        minWidth: '360px',
    },

    deactivateText: {
        minWidth: '360px',
        width: '360px',
        marginBottom: '24px',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
