import { cloneDeep } from 'lodash';
import {
    apiGetRootCategories,
    apiGetCategoriesByFilter,
    apiAddCategory,
    apiDeleteCategory,
} from './api';

// import { handleScheduleError } from '../error/sched_middleware';

export async function getRootCategories () {
    const publisher = this.env.userStore.publisher;
    this.loadingTree = true;
    const res = await apiGetRootCategories({
        token: publisher.token,
        traceId: this.env.traceId,
    });
    this.processRoot(res.data.data.getRootCategories.edges);
    this.isNodeRerenderReady = true;
    if (this.categoryNodes && this.categoryNodes.length) {
        this.env.startLoadCounter({ target: 'category' });
        await this.loadNodesRecursive(this.categoryNodes);
        this.env.stopLoadCounter();
    }
    this.loadingTree = false;
}

/* debounce rerender -> allow to only rerender every 1 second... */
export function debounceNodesRender () {
    const self = this;
    function renderNodes () {
       // console.log('ready for rerendering() ', self.categoryNodes);
        self.isNodeRerenderReady = true;
        self.categoryData.nodes = cloneDeep(self.categoryNodes);
    }

    if (this.isNodeRerenderReady) {
        this.isNodeRerenderReady = false;
        setTimeout(renderNodes, 200);
    }
}

export async function loadNodesRecursive (nodes, isAddUpdate = false) {
    const publisher = this.env.userStore.publisher;
    for (let i = 0; i < nodes.length; i++) {
      if (!this.loadingTree) {
        this.loadingTree = true;
      }
        const node = nodes[i];
        const filter = { parent: node.id };
        if (node.hasChildren || isAddUpdate) {
            /*
             * TODO update some store props to show who's in progress...
             */
            const res = await apiGetCategoriesByFilter({
                filter: filter,
                token: publisher.token,
                traceId: this.env.traceId,
            });

            nodes[i].children = this.sourceToTreeNodes(res.data.data.getCategoriesFilterBy.edges);
            this.debounceNodesRender();
            // this.categoryData.nodes = cloneDeep(this.categoryNodes);
            if (nodes[i].children) {
                await this.loadNodesRecursive(nodes[i].children, isAddUpdate);
            }
            // When in last node, set loader to false
            if (i >= (nodes.length - 1)) {
              this.loadingTree = false;
            }
        }
    }
}

export async function loadParentChildrenTree (parentId) {
    this.isNodeRerenderReady = true;
    this.loadingTree = true;
    const parent = this.findParentRecursive(parentId, this.categoryNodes);
    console.log('loadParentChildrenTree() parent loaded : ', parent);
    if (parent) {
        console.log('will load node recursively');
        await this.loadNodesRecursive([parent], true);
    }
    this.loadingTree = false;
}

export function findParentRecursive (parentId, nodes) {
    let target = null;
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.id === parentId) {
            return node;
        }
        if (node.children && node.children.length > 0) {
            target = this.findParentRecursive(parentId, node.children);
        }

        if (target !== null) {
            return target;
        }
    }
    return target;
}

export async function getCategoriesByFilter (filter) {
    this.loadingTree = true;
    const publisher = this.env.userStore.publisher;

    if (this.categoryExpanded.includes(filter.parent)) {
        const newExpanded = this.categoryExpanded.filter(val => !(val === filter.parent));
        this.categoryExpanded = [
            ...newExpanded,
        ];
        this.categoryData.expanded = newExpanded;
        return;
    };

    const res = await apiGetCategoriesByFilter({
        filter: filter,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('getCategoriesFilterBy(): ', res);
    this.loadingTree = false;
    if (filter.parent) {
        this.treeUpdateChildren(filter.parent, res.data.data.getCategoriesFilterBy.edges);
    }
}

export async function addCategory ({ name, parentId }) {
    this.env.errorStore.action = 'addCategory';
    this.loadingTree = true;
    const publisher = this.env.userStore.publisher;

    const res = await apiAddCategory({
        input: { name, parentId },
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Get addCategory: Response: ', res);
    console.log('Will be added to parent: : ', parentId);
    if (parentId) {
       // this.treeUpdateChildren(parentId, res.data.data.addCategory.edges);
        this.loadParentChildrenTree(parentId);
    } else {
        this.updateRoot(res.data.data.addCategory.edges);
    }
    this.loadingTree = false;
}

export async function deleteCategory ({ categoryId }) {
    this.loadingTree = true;
    const publisher = this.env.userStore.publisher;
    console.log('deleteCategory():  token is ', publisher.token);
    console.log('deleteCategory(): categoryId is ', categoryId);
    const res = await apiDeleteCategory({
        input: { categoryId },
        token: publisher.token,
        traceId: this.env.traceId,
    });

    console.log('Get Delete Category: Response: ', res);
    console.log('Will be deleted : ', categoryId);

    if (res.data.data &&
        res.data.data.deleteCategory &&
        res.data.data.deleteCategory.totalCount >= 0) {
            this.loadingTree = false;
            console.log(' about to delete -> ', categoryId);
            this.treeDeleteBranch(categoryId);
    }
}

export function getCategoryInfo (catIds) {
        const cateInfoList = [];

        if (!catIds || catIds.length <= 0) {
            return cateInfoList;
        }

        // recusrively find the category information based on id...
        function findCategoryInfo (nodes, catId) {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                if (node.id === catId) {
                    return node;
                }

                let target = null;

                if (node.children) {
                    target = findCategoryInfo(node.children, catId);
                }

                if (target !== null) {
                    return target;
                }
            }
            return null;
        }

        for (let i = 0; i < catIds.length; i++) {
            const catId = catIds[i];
            const catInfo = findCategoryInfo(this.categoryNodes, catId);
            if (catInfo !== null) {
                cateInfoList.push({
                    id: catInfo.id,
                    name: catInfo.label,
                    parent: catInfo.parent || catInfo.root,
                    root: catInfo.root,
                    path: catInfo.path,
                });
            }
        }
        return cateInfoList;
}
