import { useState, useEffect } from 'react';
import { useUserStore } from './index';
import { useDashboardStore } from '../';
import useAssetsErrors from './errors/useAssetsErrors';
import { ecalLogo } from '../../../commons/images/brandImage/ecalLogo';
// import { DEFAULT_IMAGE as ecalLogo } from '../../../commons/imageUploader/AdvertisingDefaultImage';
import useImageUpload from '../../../pages/hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../../pages/hooks/useImageRenderable';

/*
 * use this hook to use publisher and user info on any ui component.
 */

// const defaultProfileLogo = [{ data_url: ecalLogo }];

function getLocalErrors (errors) {
    if (errors.title === 'Invalid input') {
        return {
            title: 'Invalid Input',
            message: errors.message,
        };
    } else if (errors.title && errors.message) {
        return {
            title: errors.title,
            message: errors.message,
        };
    }
    return {};
}

function useUserPublisherInfo (props = {}) {
    const {
        isSuperAdmin = false,
    } = props;
    const store = useUserStore();
    const dashboardStore = useDashboardStore();

    const [user, setUser] = useState({});
    const [aws, setAWS] = useState({});
    const [publisher, setPublisher] = useState({});
    const [publisherLogo, setPublisherLogo] = useState(ecalLogo);

    const [role, setRole] = useState({
        superAdmin: false,
        groupAdmin: false,
    });

    const [_errors, setErrors] = useState({});

    const {
        errors,
    } = useAssetsErrors({ input: {} });

    const [progress, setProgress] = useState({
        user: false,
        publisher: false,
    });

    async function getSubscriberInfo () {
        if (isSuperAdmin) {
            return;
        }
        const total = await dashboardStore.getTotalSubscribers(true);
        const totalSubscribers = total;
        const subscriberRemaining = publisher.subscriberLimit - totalSubscribers;

        store.publisher = {
            ...store.publisher,
            totalSubscribers,
            subscriberRemaining,
        };

        setPublisher({
            ...store.publisher,
        });
    }

    useEffect(() => {
        store.env.shouldSignOut();
        store.getCountryInfo();
    }, []);

    useEffect(() => {
        setPublisher(store.publisher);
        setProgress({
            ...progress,
            publisher: false,
        });
        // store.getPublisherLogo();
    }, [store.observed.publisher]);

    useEffect(() => {
        setUser(store.user);
        setProgress({
            ...progress,
            user: false,
        });

        if (store.user) {
            setTimeout(getSubscriberInfo, 500);
        }
    }, [store.observed.user]);

    useEffect(() => {
        if (store.aws) {
            setAWS(store.aws);
        }
    }, [store.observed.aws]);

    useEffect(() => {
        if (errors.title === 'Asset Not Found') {
            setPublisherLogo(ecalLogo);
        }
        setErrors(getLocalErrors(errors));
    }, [errors]);

    useEffect(() => {
        if (store.publisherLogo) {
            setPublisherLogo(store.publisherLogo.content);
        }
    }, [store.observed.publisherLogo]);

    useEffect(() => {
        setRole({
            ...role,
            superAdmin: store.isSuperAdmin,
            groupAdmin: store.groupAdmin,
        });
    }, [store.observed.isSuperAdmin]);

    const {
        image: logoImage,
    } = useImageRenderable({
        imageSource: publisherLogo,
        customDefaultImage: ecalLogo,
    });

    const {
        image: publisherLogoEditable,
        upload: uploadPublisherLogo,
        imageError: publisherLogoError,
    } = useImageUpload({
        store,
        uploadMethod: 'uploadProfileLogo',
        assetType: 'publisherLogo',
        observableDataSource: store.observed.publisherLogoEditable,
        consumableDataSource: store.publisherLogoEditable,
        setStateFunc: (url) => {
            /* set the non editable publisher Log */
            setPublisherLogo(url);
        },
        customDefaultImage: logoImage,
        urlField: 'fileUrl',
        shouldCheckFilesize: true,
    });

    function deletePublisherLogo () {
        store.deletePublisherLogo();
    }

    return {
        user,
        publisher,
        progress,
        setProgress,
        publisherLogo,
        publisherLogoEditable,
        publisherLogoError,
        uploadPublisherLogo,
        deletePublisherLogo,
        aws,
        errors: _errors,
        role,
    };
}

export default useUserPublisherInfo;
